export const taxsListConstants = {
  // GET ALL TAXS LIST
  GET_TAXS_LIST_REQUEST: "GET_TAXS_LIST_REQUEST",
  GET_TAXS_LIST_SUCCESS: "GET_TAXS_LIST_SUCCESS",
  GET_TAXS_LIST_REJECTED: "GET_TAXS_LIST_REJECTED",

  GET_VAT_TAX_REQUEST: "GET_VAT_TAX_REQUEST",
  GET_VAT_TAX_SUCCESS: "GET_VAT_TAX_SUCCESS",
  GET_VAT_TAX_REJECTED: "GET_VAT_TAX_REJECTED",

  // DELETE TAX
  DELETE_TAX_REQUEST: "DELETE_TAX_REQUEST",
  DELETE_TAX_SUCCESS: "DELETE_TAX_SUCCESS",
  DELETE_TAX_REJECTED: "DELETE_TAX_REJECTED",

  // UPDATE TAX STATUS
  UPDATE_TAX_STATUS_REQUEST: "UPDATE_TAX_STATUS_REQUEST",
  UPDATE_TAX_STATUS_SUCCESS: "UPDATE_TAX_STATUS_SUCCESS",
  UPDATE_TAX_STATUS_REJECTED: "UPDATE_TAX_STATUS_REJECTED",
};
