import { Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { fetchTaxsList } from "../../../../Redux/Actions/Tax/taxList.action";
import HomeIcon from "../../../../assets/icons/home.svg";
import List from "./list/List";

import { getAllCourses } from "../../../../Services/api/CoursesList";
import { getExhibitionList } from "../../../../Services/api/Exhibition/ExhibitionProvider";
import { exportTaxList } from "../../../../Services/api/TaxList";
import { getCountries } from "../../../../Services/api/profile/profileProvider";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import classes from "./Tax.module.css";
import TaxListFilter from "./TaxListFilter";
import { filterActionsTax } from "./TaxListTableactions";
import TaxTable from "./TaxTable";

function Tax() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [currentTaxFilter, setCurrentTaxFilter] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [countries, setCountries] = useState([]);
  const [courses, setCourses] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [visitorExhibition, setVisitorExhibition] = useState([]);
  const [boothExhibition, setBoothExhibition] = useState([]);
  const [filterData, setFilterData] = useState({
    name: "",
    mechanism: "",
    start_date: "",
    end_date: "",
    parent_type: "",
    course: "",
    program: "",
    status: "",
    nationality_id: "",
  });

  const { taxsListMeta, taxsListLoading } = useSelector((state) => state.taxsListReducer);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "content_development",
      page: t("admin.label.admin_label"),
      pagePath: "#",
    },
    {
      id: `content_development_tax`,
      page: t("financial.tax.tax"),
      pagePath: "#",
      active: true,
    },
  ];
  const showFilterHandler = () => {
    setShowFilter((prev) => !prev);
  };
  const changeHandler = (e, field) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name || field]: value });
  };
  const changeHandlerNationalty = (name, value) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };

  const resetFilterHandler = () => {
    setFilterData({
      name: "",
      mechanism: "",
      start_date: "",
      end_date: "",
      parent_type: "",
      course: "",
      program: "",
      status: "",
      nationality_id: "",
    });
    setIsResetApplied(true);
  };

  const getTaxListHandler = () => {
    const filter = getFilterData();

    dispatch(
      fetchTaxsList({
        page: currentPage,
        perPage: 10,
        filter,
      })
    );
    setCurrentTaxFilter(filter);
  };

  const getFilterData = () => {
    const filter = {};
    filterData.name && (filter.name = filterData.name);
    filterData.mechanism && (filter.mechanism = filterData.mechanism);
    filterData.start_date && (filter.start_date = filterData.start_date);
    filterData.nationality_id && (filter.nationality_id = filterData.nationality_id);
    filterData.end_date && (filter.end_date = filterData.end_date);
    filterData.parent_type && (filter.parent_type = filterData.parent_type);
    filterData.status && (filter.status = filterData.status);

    if (filterData.course || filterData.program || filterData.booth || filterData.visitor) {
      switch (Number(filterData.parent_type)) {
        case 1:
          filterData.course && (filter.parent_id = filterData.course);
          break;
        case 2:
          filterData.program && (filter.parent_id = filterData.program);
          break;
        case 3:
          filterData.booth && (filter.parent_id = filterData.booth);
          break;
        case 4:
          filterData.visitor && (filter.parent_id = filterData.visitor);
          break;
        default:
          break;
      }
    }

    return filter;
  };

  const fetchData = async () => {
    try {
      const [countriesRes, coursesRes, programsRes, boothRes, visitorRes] = await Promise.all([
        getCountries(),
        getAllCourses({ paginated: 0, not_in_program: 1, is_program: 0, mode: "Paid", perPage: 1000 }),
        getAllCourses({ paginated: 0, is_program: 1, mode: "Paid", perPage: 1000 }),
        getExhibitionList({ perPage: 1000, mode: "Paid" }),
        getExhibitionList({ perPage: 1000, booth_mode: "Paid" }),
      ]);

      if (countriesRes.status === 200 && countriesRes.data.status) {
        setCountries(countriesRes.data.countries);
      }

      if (coursesRes.status === 200 && coursesRes.data.status) {
        setCourses(coursesRes.data.data.courses.data);
      }

      if (programsRes.status === 200 && programsRes.data.status) {
        setPrograms(programsRes.data.data.courses.data);
      }

      if (visitorRes.status === 200) {
        setVisitorExhibition(visitorRes.data.data.exhibitions.data);
      }

      if (boothRes.status === 200) {
        setBoothExhibition(boothRes.data.data.exhibitions.data);
      }
    } catch (error) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {error.response ? error.response.data.msg : t("Failure_in_service")}
        </span>
      );
    } finally {
      setFetching(false);
    }
  };

  const exportHandler = async (type) => {
    setIsExportLoading(true);
    try {
      await exportTaxList(currentTaxFilter, type);
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    getTaxListHandler();
    if (isResetApplied) {
      setIsResetApplied(false);
    }
  }, [currentPage, isResetApplied]);

  return (
    <div className={classes["tax-list"]}>
      {(isExportLoading || taxsListLoading) && <SkeletonCardOverlay skeletonWidth="100" />}
      <div className="container-fluid">
        <div className={classes["tax-list__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <MainBox>
                <MainBoxHead
                  title={t("financial.tax.tax")}
                  actions={filterActionsTax({
                    // vatTax: () =>
                    //   history.push(
                    //     `${localStorage.getItem("type") == "accountant" ? "/accountant" : "/admin"}/tax/vat/${
                    //       Object.keys(vatTax).length > 0 ? `edit/${vatTax.id}` : `add`
                    //     }`
                    //   ),
                    showFilter: showFilterHandler,
                    addAction: () => {
                      history.push(
                        `${localStorage.getItem("type") == "accountant" ? "/accountant" : "/admin"}/tax/add`
                      );
                    },
                    exportPdf: () => {
                      exportHandler("pdf");
                    },
                    exportExcel: () => {
                      exportHandler("xlsx");
                    },
                  })}
                />
                {showFilter && (
                  <TaxListFilter
                    filterData={filterData}
                    changeHandler={changeHandler}
                    resetHandler={resetHandler}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    filterHandler={getTaxListHandler}
                    resetFilterHandler={resetFilterHandler}
                    courses={courses}
                    programs={programs}
                    countries={countries}
                    visitorExhibition={visitorExhibition}
                    boothExhibition={boothExhibition}
                    fetching={fetching}
                    changeHandlerNationalty={changeHandlerNationalty}
                  />
                )}

                <TaxTable />
                <Pagination
                  count={taxsListMeta?.totalPages || 0}
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  className="main-pagination"
                  onChange={(e, page) => {
                    setCurrentPage(page);
                  }}
                  page={currentPage}
                />
              </MainBox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tax;
