import React, { forwardRef } from "react";
import classNames from "classnames";

// import { Handle, Remove } from "../../Item"

import styles from "./Container.module.css";

export const Container = forwardRef(
  (
    {
      children,
      columns = 1,
      handleProps,
      horizontal = false,
      hover = false,
      onClick,
      onRemove,
      label,
      placeholder = false,
      style,
      scrollable = false,
      shadow = false,
      unstyled = false,
      ...props
    },
    ref
  ) => {
    return (
      <div
        {...props}
        ref={ref}
        className={`${classNames(
          styles.Container,
          unstyled && styles.unstyled,
          horizontal && styles.horizontal,
          // styles.hover,
          // styles.placeholder,
          scrollable && styles.scrollable,
          // styles.shadow
        )} tw-flex tw-flex-col tw-h-full tw-space-y-3`}
        style={{
          ...style,
          "--columns": columns,
        }}
      >
        {label ? (
          <div className="tw-font-bold tw-text-teal-700">{label}</div>
        ) : null}
        <div
          className={`tw-grow tw-bg-gray-50 tw-border tw-border-gray-200 tw-rounded tw-py-4`}
        >
          {placeholder ? (
            children
          ) : (
            <ul
              className=" tw-space-y-4 tw-m-0"
              style={{
                height: `calc(${columns * 4}rem - 1rem)`,
              }}
            >
              {children}
            </ul>
          )}
        </div>
      </div>
    );
  }
);
