import { Formik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { CourseContext } from "../../../../Services/api/courses/courseContext";
import { finalPrice, getCourseDetails } from "../../../../Services/api/courses/courseProvider";
import { BasicModal } from "../../../Shared/Components/Modal/BasicModal";
import TrainingCoursePaymentModal from "../../../TrainingCourses/TrainingCourseDetails/TrainingCoursePaymentModal/TrainingCoursePaymentModal/TrainingCoursePaymentModal";
import CourseDetailes from "../CourseDetailes/CourseDetailes";

function GiftCourse(props) {
  require("./GiftCourse.css");
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();

  const courseDetailsContext = useContext(CourseContext);
  const paymentModalRef = useRef();

  const [formattedAssignees, setFormattedAssignees] = useState([]);
  const [assignees, setAssignees] = useState([]);
  const [prices, setPrices] = useState();
  const [discountPrice, setDiscountPrice] = useState("");

  const initialUserRowData = {
    name: "",
    email: "",
  };

  const reformatAssignees = async () => {
    let filteredAssignees = [];
    await assignees.forEach((item) => {
      const filteredAssigneesItem = {};
      filteredAssigneesItem.user = item.user;
      filteredAssignees.push(filteredAssigneesItem);
    });
    await setFormattedAssignees(filteredAssignees);
  };

  useEffect(() => {
    if (assignees && assignees.length > 0) {
      reformatAssignees();
    }
  }, []);

  useEffect(() => {
    getCourseDetails(id)
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          if (res.data.course && res.data.course.mode && res.data.course.mode === "Free") {
            history.push(`/${res.data.course.is_program ? "training-programs" : "training-courses"}/${id}/details`);
          }
          courseDetailsContext.setCourseDetails(res.data.course);
          courseDetailsContext.setPurchaseStatus(res.data.purchase_status);
          courseDetailsContext.setIsLoading(false);
        }
      })
      .catch((err) => {});
  }, []);

  const modalHandler = () => {
    paymentModalRef.current.showModal();
  };

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [giftDataPayload, setGiftDataPayload] = useState({});

  const formatDate = (d) => {
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  return (
    <>
      <BasicModal ref={paymentModalRef}>
        <TrainingCoursePaymentModal
          paymentModalRef={paymentModalRef}
          courseDetailsContext={courseDetailsContext}
          giftDataPayload={giftDataPayload}
          price={prices}
          discountPrice={discountPrice}
        />
      </BasicModal>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="top-label">{t("giftCourse.label.gift_for_friend")}</div>
            <div className="hint-label">{t("giftCourse.label.hint_label")}</div>
            <div className="gift-container">
              <div className="gift-card">
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <Formik
                    initialValues={{
                      course_id: parseInt(id),
                      purchase_type: "course",
                      gift_discount: "",
                      sent_at: "",
                      message: "",
                      coupon: "",
                      email: localStorage.getItem("email"),
                      gift_receivers:
                        formattedAssignees.length === 0
                          ? [
                              {
                                ...initialUserRowData,
                              },
                            ]
                          : formattedAssignees,
                    }}
                    onSubmit={async (values, { setSubmitting, setErrors }) => {
                      let errorObj = {};
                      const giftPayload = {
                        ...values,
                      };

                      setDiscountPrice(giftPayload.gift_discount);

                      giftPayload.sent_at = formatDate(new Date(values.sent_at));

                      try {
                        let response = await finalPrice(giftPayload);
                        if (response.status === 200 && response.data.status) {
                          setPrices(response.data.response);
                          setGiftDataPayload(giftPayload);
                          modalHandler();
                        } else {
                          alert("Failure");
                        }
                      } catch (err) {
                        {
                          err.response.data.msg &&
                            toast.error(
                              <span style={{ fontSize: 13, fontWeight: "bold" }}>
                                {err.response.data && err.response.data.msg}
                              </span>
                            );
                        }
                        if (err.response.data.errors && err.response.data.errors.sent_at) {
                          errorObj.sent_at = err.response.data.errors.sent_at;
                        }
                        if (Object.keys(errorObj).length !== 0) {
                          setErrors(errorObj);
                        }
                      }
                    }}
                    validateOnChange={hasSubmitted}
                    validate={async (values) => {
                      setHasSubmitted(true);
                      const errors = {};
                      const userErrors = [];

                      await values.gift_receivers.forEach((item, index) => {
                        const errorInstance = {};
                        if (!item.email) {
                          errorInstance.email = t("crud.errors.required");
                        }

                        if (!validateEmail(item.email)) {
                          errorInstance.email = t("email_format");
                        }

                        if (!item.name) {
                          errorInstance.name = t("crud.errors.required");
                        }

                        if (Object.keys(errorInstance).length !== 0) {
                          userErrors[index] = errorInstance;
                        }
                      });

                      if (userErrors.length > 0) {
                        errors.gift_receivers = userErrors;
                      }
                      if (!values.sent_at) {
                        errors.sent_at = t("crud.errors.required");
                      }

                      if (values.gift_receivers.length == 0) {
                        errors.newUser = t("crud.errors.required");
                      }

                      if (!values.gift_discount) {
                        errors.gift_discount = t("crud.errors.required");
                      }

                      return errors;
                    }}
                  >
                    {({ values, errors, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
                      <form onSubmit={handleSubmit} className="gift-form">
                        {values.gift_receivers &&
                          values.gift_receivers.map((item, index) => {
                            return (
                              <div className="card-info-email-name" key={index}>
                                <div
                                  style={{
                                    position: "absolute",
                                    height: 20,
                                    width: 20,
                                    borderRadius: 0,
                                    backgroundColor: "red",
                                    left: document.body.dir == "rtl" ? 0 : "auto",
                                    right: document.body.dir == "ltr" ? 0 : "auto",
                                    top: 10,
                                    marginLeft: document.body.dir == "rtl" ? 10 : 0,
                                    marginRight: document.body.dir == "ltr" ? 10 : 0,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    let currentUsers = values.gift_receivers;
                                    currentUsers.splice(index, 1);
                                    setFieldValue("gift_receivers", currentUsers);
                                  }}
                                >
                                  <i className="fas fa-times" style={{ color: "white", fontSize: 12 }}></i>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <div
                                    style={{
                                      flex: "1 1 0%",
                                      display: "flex",
                                      width: "100%",
                                      border: "1px solid #eee",
                                      justifyContent: "space-between",
                                      borderRadius: "5px",
                                      padding: "20px 20px 0",
                                    }}
                                    className="gift-card-info__fields"
                                  >
                                    <div
                                      style={{
                                        width: "47%",
                                      }}
                                    >
                                      <label htmlFor="allowed_file_extensions" className="gift-input-label">
                                        {t("giftCourse.placeholders.friend_name")}
                                      </label>
                                      <input
                                        id={`name[${index}]`}
                                        type="text"
                                        name={`name[${index}]`}
                                        className="gift-input"
                                        onChange={(item) => {
                                          setFieldValue(`gift_receivers[${index}].name`, item.target.value);
                                        }}
                                        placeholder={t("giftCourse.placeholders.friend_name")}
                                        value={values.gift_receivers[index].name}
                                      />

                                      <p className={"form-input-error-space"} style={{ margin: " 5px 10px" }}>
                                        {errors.gift_receivers &&
                                        errors.gift_receivers.length > 0 &&
                                        errors.gift_receivers[index]?.name
                                          ? errors.gift_receivers[index].name
                                          : null}
                                      </p>
                                    </div>

                                    <div
                                      style={{
                                        width: "47%",
                                      }}
                                    >
                                      <label htmlFor="allowed_file_extensions" className="gift-input-label">
                                        {t("giftCourse.label.friend_email")}
                                      </label>
                                      <input
                                        id={`email[${index}]`}
                                        type="email"
                                        name={`email[${index}]`}
                                        className="gift-input"
                                        onChange={(item) => {
                                          setFieldValue(`gift_receivers[${index}].email`, item.target.value);
                                        }}
                                        placeholder={t("giftCourse.label.friend_email")}
                                        value={values.gift_receivers[index].email}
                                      />

                                      <p className={"form-input-error-space"} style={{ margin: " 5px 10px" }}>
                                        {errors.gift_receivers &&
                                        errors.gift_receivers.length > 0 &&
                                        errors.gift_receivers[index]?.email
                                          ? errors.gift_receivers[index].email
                                          : null}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginBottom: 30,
                          }}
                        >
                          <button
                            type="button"
                            className="add-new-user"
                            disabled={isSubmitting}
                            style={{
                              marginRight: document.body.dir == "rtl" ? 10 : 0,
                              marginLeft: document.body.dir == "ltr" ? 10 : 0,
                            }}
                            onClick={() => {
                              let currentUsers = values.gift_receivers;
                              currentUsers.push({ ...initialUserRowData });
                              setFieldValue("gift_receivers", currentUsers);
                            }}
                          >
                            <i
                              className="fa fa-plus"
                              style={{
                                marginRight: document.body.dir == "rtl" ? 0 : 10,
                                marginLeft: document.body.dir == "ltr" ? 0 : 10,
                              }}
                            ></i>
                            {t("giftCourse.values.add_new_friend")}
                          </button>
                          <p className={"form-input-error-space"} style={{ margin: " 5px 10px" }}>
                            {errors.newUser ? errors.newUser : null}
                          </p>
                        </div>

                        <div>
                          <label htmlFor="gift_discount" className="gift-input-label">
                            {t("giftCourse.label.per_dis")}
                          </label>
                          <input
                            id="gift_discount"
                            type="number"
                            min={1}
                            max={100}
                            step={1}
                            name="gift_discount"
                            className="gift-input"
                            onChange={handleChange}
                            placeholder={t("giftCourse.placeholders.per_dis")}
                            value={values.gift_discount}
                          />
                          <p className={"form-input-error-space"} style={{ margin: " 5px 10px" }}>
                            {errors.gift_discount ? errors.gift_discount : null}
                          </p>
                        </div>

                        <div>
                          <label htmlFor="sent_at" className="gift-input-label">
                            {t("giftCourse.label.gift_date")}
                          </label>
                          <DatePicker
                            selected={values.sent_at}
                            onChange={(sent_at) => setFieldValue("sent_at", sent_at)}
                            className="gift-input"
                            minDate={new Date()}
                            dateFormat={"yyyy-MM-dd"}
                            placeholderText={t("giftCourse.placeholders.gift_date")}
                          />
                          <p className={"form-input-error-space"} style={{ margin: " 5px 10px" }}>
                            {errors.sent_at ? errors.sent_at : null}
                          </p>
                        </div>

                        <div>
                          <label htmlFor="message" className="gift-input-label">
                            {t("giftCourse.label.your_msg")} {t("giftCourse.label.option")}
                          </label>
                          <input
                            id="message"
                            type="text"
                            name="message"
                            className="gift-input"
                            onChange={handleChange}
                            placeholder={t("giftCourse.placeholders.your_msg")}
                            value={values.message}
                          />
                          <p className={"form-input-error-space"} style={{ margin: " 5px 10px" }}></p>
                        </div>

                        <div className="gift-btn">
                          <button type="submit" className="gift-button" disabled={isSubmitting}>
                            {t("giftCourse.values.approve")}
                          </button>

                          <div
                            className="btn"
                            onClick={(e) => {
                              e.stopPropagation();
                              history.goBack();
                            }}
                          >
                            {t("giftCourse.values.back")}
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
              <CourseDetailes />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GiftCourse;
