import React from "react";
import {useTranslation} from "react-i18next";

function ExamAnswersQuestionHeader(props) {
    const {t} = useTranslation();
    return(
        <div style={{
            width:"100%",
            marginBottom:"20px"
        }} className={"tajah-form-row-element-v2 tajah-question-info-section"}>
            <div style={{
                width:"95%"
            }}  className={"tajah-form-row tajah-question-info-section-row"}>
                <div className={"tajah-form-row-element-v2"}>
                    <div className={"tajah-form-row-element-holder"}>
                        <label htmlFor="title" className="admin_add_label">
                            {t("trainer.quiz.crud.question_title")}
                        </label>
                        <input
                            value={props.title}
                            className="admin_filter_input"
                            disabled={true}
                        />
                    </div>
                </div>

                <div className={"tajah-form-row-element-v2"}>
                    <div className={"tajah-form-row-element-holder"}>
                        <label htmlFor="title" className="admin_add_label">
                            {t("trainer.quiz.crud.grade")}
                        </label>
                        <input
                            value={props.grade}
                            className="admin_filter_input"
                            disabled={true}
                        />
                    </div>
                </div>
                </div>
                <div style={{
                    width:"100%"
                }} className={"tajah-form-row"}>
                <div style={{
                    width:"100%"
                }} className={"tajah-form-row-element-v2"}>
                    <div className={"tajah-form-row-element-holder"}>
                        <label htmlFor="title" className="admin_add_label">
                            {t("trainer.quiz.crud.feedback")}
                        </label>
                        <input
                            value={props.feedback}
                            className="admin_filter_input"
                            disabled={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ExamAnswersQuestionHeader
