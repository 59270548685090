import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateSystemUserStatus } from "../../../Redux/Actions/systemUsers/systemUsersList";
import Cupes from "../../../assets/icons/cupes.png";
import { Lang } from "../../../utils";
import MainTable from "../../Shared/Components/MainTable/MainTable";
import TableActionsColumn from "../../Shared/Components/TableActionsColumn/TableActionsColumn";

import classes from "./SponsersList.module.css";

const SponsersListTable = ({ sortAction, rolesEditHandle }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { systemUsersList, systemUsersListLoading } = useSelector(
    (state) => state.systemUsersListReducer
  );

  const { t, i18n } = useTranslation();

  const utcToLocal = (utcDate) => {
    return moment.utc(utcDate).local().format("hh:mm A");
  };

  const updateSystemUserStatusHandler = (id) => {
    dispatch(
      updateSystemUserStatus({
        id,
      })
    );
  };

  const TaxActionsList = [
    {
      id: "Exhibition",
      icon: <img src={Cupes} style={{ height: 30, width: 25 }} alt="" />,
      action: (id) => {
        history.push(`/admin/sponser-exhibition/${id}`);
      },
      tooltip: t("Exhibition.Exhibition"),
    },
  ];

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData }) => <>{rowData?.id}</>,
    },
    {
      id: "name",
      name: t("system_users.name"),
      key: i18n.language === Lang.AR ? "name_ar " : "name_en",
      cell: ({ rowData }) => (
        <div className={classes["system-users-list__table-name"]}>
          {rowData?.name || "-"}
        </div>
      ),
      sortable: false,
    },
    {
      id: "email",
      name: t("system_users.email"),
      key: "email",
      cell: ({ rowData }) => (
        <div className={classes["system-users-list__table-email__wrapper"]}>
          <a
            className={classes["system-users-list__table-email"]}
            href={`mailto: ${rowData?.email}`}
          >
            {rowData?.email}
          </a>
        </div>
      ),
      sortable: false,
    },

    {
      id: "phone",
      name: t("system_users.phone"),
      key: "phone",
      cell: ({ rowData }) => (
        <div style={{ direction: "ltr" }}>{rowData?.phone || "-"}</div>
      ),
      sortable: false,
    },
    {
      id: "id_number",
      name: t("system_users.id_number"),
      key: "id_number",
      cell: ({ rowData }) => <>{rowData?.id_number || "-"}</>,
      sortable: false,
    },
    // {
    //   id: "gender",
    //   name: t("system_users.gender"),
    //   key: "gender",
    //   cell: ({ rowData }) => (
    //     <>{rowData?.gender ? displayGender(rowData.gender) : "-"}</>
    //   ),
    //   sortable: false,
    // },
    {
      id: "nationality",
      name: t("system_users.nationality"),
      key: "nationality",
      cell: ({ rowData }) => <>{rowData?.nationality?.name || "-"}</>,
      sortable: false,
    },
    {
      id: "country",
      name: t("system_users.country"),
      key: "country",
      cell: ({ rowData }) => <>{rowData?.country?.name || "-"}</>,
      sortable: false,
    },
    {
      id: "register_date",
      name: t("system_users.register_date"),
      key: "created_at",
      cell: ({ rowData }) => (
        <>
          {moment(rowData.created_at).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData.created_at)}
        </>
      ),
      sortable: true,
    },
    {
      id: "exhibitions_count",
      name: t("exhibitions_count"),
      key: "exhibitions_count",
      cell: ({ rowData }) => <>{rowData?.exhibitions_count || "-"}</>,
      sortable: false,
    },
    {
      id: "actions",
      name: t("general.actions"),
      key: "actions",
      cell: ({ rowData }) => (
        <TableActionsColumn actions={TaxActionsList} id={rowData.id} />
      ),
    },

    {
      id: "status",
      name: t("system_users.status"),
      key: "status",
      cell: ({ rowData }) =>
        rowData.status == 1 ? t("general.active") : t("general.inactive"),
      sortable: false,
    },
  ];
  return (
    <MainTable
      columns={columns}
      data={systemUsersList || []}
      isLoading={systemUsersListLoading}
      sortAction={sortAction}
    />
  );
};

export default SponsersListTable;
