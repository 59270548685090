import { useTranslation } from "react-i18next";
import calendericon from "../../../../assets/icons/calendarpng.png";
import seaticon from "../../../../assets/icons/chairpng.png";
import CustomButton from "../CustomButton/CustomButton";
import Title from "../Title/Title";
import classes from "./courseCard.module.css";
import moment from "moment";
import CalenderIcon from "../../../../assets/icons/calenderHome.svg";
import SeatsIcon from "../../../../assets/icons/seatHome.svg";
import CashIcon from "../../../../assets/icons/Cash.svg";
import ArrowOrange from "../../../../assets/icons/ArrowOrange.svg";
import { useState } from "react";

const CourseCard = ({
  img,
  rightimgbadge,
  rightcolorbadge,
  leftimgbadge,
  leftcolorbadge,
  badgelabel,
  badgecolor,
  title,
  price,
  offer = false,
  date,
  seat,
  hour,
  levelbadge,
  levelcolor,
  handleSubmit,
  expired,
  isClosed,
  btn,
  level,
  userdata = false,
  userimg,
  username,
  userrate,
  isCurrentCourses,
  progressValue = 0,
  watchValue = 0,
  mode,
  briefDesc,
  trainers,
}) => {
  const { t } = useTranslation();
  const [isHovering, setIsHovering] = useState(false);
  function handleMouseOver() {
    setIsHovering(true);
  }
  function handleMouseOut() {
    setIsHovering(false);
  }

  const levelclass =
    level === "1"
      ? classes["badge-green"]
      : level === "2"
      ? classes["badge-yellow"]
      : level === "3"
      ? classes["badge-black"]
      : "";

  return (
    <div
      className={`${classes["card"]} ${isHovering && classes.hovering}`}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <div className={classes["header-content"]}>
        <div className={classes["img-container"]}>
          <div className={classes["img-badge"]}>
            {isCurrentCourses || isClosed ? null : (
              <>
                {/* <div className={classes["price-container"]}>
                  {offer ? (
                    <span className={classes["old-price"]}>
                      {price} {t("trainer.rs")}
                    </span>
                  ) : null}
                  <label className={classes.price}>
                    {mode === "Paid" ? (
                      <>
                        {offer ? null : (
                          <p className={classes["price-label"]}>
                            {t("general.price")}
                          </p>
                        )}
                        <span className={classes["price-number"]}>
                          {offer
                            ? Number(offer.mechanism) === 1
                              ? Number(price) - Number(offer.value)
                              : Number(price) -
                                (Number(price) * Number(offer.value)) / 100
                            : price}
                        </span>{" "}
                        <span className={classes.cur}>{t("trainer.rs")}</span>
                      </>
                    ) : (
                      <>
                        <p className={classes["price-label"]}>
                          {t("general.free")}
                        </p>
                        <span className={classes["price-number"]}>
                          0.00{" "}
                          <span className={classes.cur}>{t("trainer.rs")}</span>
                        </span>
                      </>
                    )}
                  </label>
                </div> */}
                {offer && (
                  <div className={classes["offer-badge"]}>
                    <span className={classes["offer-badge-off"]}>
                      {t("general.off")}
                    </span>
                    <span className={classes["offer-badge-number"]}>
                      {offer.value +
                        " " +
                        (Number(offer.mechanism) === 1 ? t("general.rs") : "%")}
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
          <img src={img} alt="course" className={classes.img} />
        </div>
        {/* <div className={classes["title-price-container"]}>
          <Title title={title} fontSize={20} titleColor="#046c77" />
          <div className={classes["desc-container"]}>{briefDesc}</div>
        </div> */}
        <div className={classes.cardContantNew}>
          <div>
            <div className={classes["name"]}>
              <Title
                title={title.length > 51 ? `${title.slice(0, 51)} ...` : title}
                fontSize={15}
                titleColor={"#488B85"}
              />
            </div>
            <div className={classes.sapretor}></div>
            <div className={classes.job_title}>
              <p>
                {briefDesc?.length > 85
                  ? `${briefDesc?.slice(0, 85)} ...`
                  : briefDesc}
              </p>
            </div>
          </div>
          <div>
            <div className={classes.num_courses}>
              <div className={classes.numContainer}>
                <img
                  src={CalenderIcon}
                  alt=""
                  style={{ width: 18, height: 18 }}
                />
                {moment(date).format("DD-MM-YYYY")}
              </div>
              <span>|</span>
              <div className={classes.numContainer}>
                <img src={SeatsIcon} alt="" style={{ width: 18, height: 18 }} />
                {seat}&nbsp;
                {t("seats")}
              </div>
              <span>|</span>
              <div className={classes.numContainer}>
                <img src={CashIcon} alt="" style={{ width: 18, height: 18 }} />
                {price}&nbsp;
                {t("rs")}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes["bottom-content"]}>
        {isClosed ? (
          <p style={{ color: "#046c77" }}>{t("course_completed")}</p>
        ) : (
          <>
            {/* <div className={classes["date-seat-hour"]}>
              <span className={classes.iconscontainer}>
                <img
                  src={calendericon}
                  alt=""
                  style={{
                    width: "16px",
                  }}
                />{" "}
                {date}
              </span>
              <span className={classes.iconscontainer}>
                <img
                  src={seaticon}
                  alt=""
                  style={{
                    width: "18px",
                  }}
                />{" "}
                {seat} {t("general.seat_available")}
              </span>
            </div> */}
            {isCurrentCourses ? (
              <div className={classes.progressMain}>
                <div className={classes["progress-container"]}>
                  <h3 style={{ color: "#6d6d6d" }}>
                    {t("achivementProgress")}
                  </h3>
                  <div className={classes["progress"]}>
                    <div
                      className={classes["progress-bar"]}
                      role="progressbar"
                      style={{
                        width: `${progressValue}%`,
                      }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    />
                  </div>
                  <p style={{ color: "#6d6d6d" }}>{progressValue}%</p>
                </div>
                <div className={classes["progress-container"]}>
                  <h3 style={{ color: "#6d6d6d" }}>
                    {t("watchingProgressTwo")}
                  </h3>
                  <div className={classes["progress"]}>
                    <div
                      className={classes["watch-progress-bar"]}
                      role="progressbar"
                      style={{
                        width: `${watchValue}%`,
                      }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    />
                  </div>
                  <p style={{ color: "#6d6d6d" }}>{watchValue}%</p>
                </div>
              </div>
            ) : (
              <>
                {/* trainers && (
                <div className={classes["user-container"]}>
                  {trainers.map(
                    (trainer, index) =>
                      index < 4 && (
                        <img
                          className={classes["user-img"]}
                          alt="trainer img"
                          src={trainer.img}
                          key={index}
                        />
                      )
                  )}
                  {trainers.length > 4 && (
                    <div className={classes["user-img-more"]}>
                      <p>+{trainers.length - 4}</p>
                    </div>
                  )}
                </div>
              ) */}
              </>
            )}
          </>
        )}
        <div className={classes.levelbadge}>
          <p style={{ color: "#6d6d6d", fontSize: "14px" }}>
            {levelbadge && `${levelbadge} - `}
            {hour && `${hour} ${t("general.hours")}`}
          </p>
        </div>
        <div className={classes.btnCont}>
          <CustomButton
            value={btn}
            icon={ArrowOrange}
            iconClass={classes.iconClass}
            colors={"#133535"}
            // type={"button"}
            disable={expired}
            classes={classes.btn}
            action={() => handleSubmit()}
          />
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
