import { useTranslation } from "react-i18next";
import FilterBox from "../../../Shared/Components/FilterBox/FilterBox";

import { useEffect } from "react";
const RefundSettingFilter = ({
  filterData,
  changeHandler,
  resetHandler,
  currentPage,
  setCurrentPage,
  filterHandler,
  resetFilterHandler,
  courses,
  programs,
  visitorExhibition,
  boothExhibition,
  type,
}) => {
  const { t, i18n } = useTranslation();

  const mechanismOptions = [
    {
      id: 1,
      value: 1,
      label: t("coupon.label.co_value"),
    },
    {
      id: 2,
      value: 2,
      label: t("coupon.label.per_value"),
    },
  ];
  const createdByOptions = [
    {
      id: 1,
      value: 1,
      label: t("selection.role.admin"),
    },
    {
      id: 2,
      value: 2,
      label: t("selection.role.financial_manager"),
    },
  ];

  const parentTypeOptions = [
    {
      label: t("general.course"),
      id: 1,
      value: 1,
    },
    {
      label: t("general.program"),
      id: 2,
      value: 2,
    },
  ];

  const statusOptions = [
    {
      label: t("general.active"),
      id: 1,
      value: 1,
    },
    {
      label: t("general.inactive"),
      id: 2,
      value: 0,
    },
  ];

  const filterFields = [
    {
      id: "refund_title",
      component: "input",
      label: t("financial.refund.refund_requset_title"),
      type: "text",
      placeholder: t("financial.refund.refund_requset_title"),
      name: "refund_title",
      value: filterData.refund_title,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "mechanism",
      label: t("coupon.label.machinsim"),
      component: "select",
      placeholder: t("coupon.label.machinsim"),
      initialValue: t("coupon.label.machinsim"),
      name: "mechanism",
      value: filterData.mechanism,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: mechanismOptions || [],
    },
    {
      id: "start_date",
      label: t("general.search_start_date"),
      component: "datePicker",
      placeholder: t("general.search_start_date"),
      name: "start_date",
      value: filterData.start_date,
      classes: "col-12 col-md-6 col-xl-2",
      onchange: changeHandler,
    },
    {
      id: "end_date",
      label: t("general.search_end_date"),
      component: "datePicker",
      placeholder: t("general.search_end_date"),
      name: "end_date",
      value: filterData.end_date,
      classes: "col-12 col-md-6 col-xl-2",
      onchange: changeHandler,
    },
    ...(type === "trainee"
      ? [
          {
            id: "created_by",
            label: t("general.created_by"),
            component: "select",
            placeholder: t("general.created_by"),
            initialValue: t("general.created_by"),
            name: "created_by",
            value: filterData.created_by,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            options: createdByOptions || [],
          },
          {
            id: "parent_type",
            label: t("coupon.label.select_type"),
            component: "select",
            placeholder: t("coupon.label.select_type"),
            initialValue: t("coupon.label.select_type"),
            name: "parent_type",
            value: filterData.parent_type,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            options: parentTypeOptions || [],
          },
        ]
      : []),
    ...(filterData.parent_type == 1
      ? [
          {
            id: "course",
            label: t("coupon.label.select_course"),
            component: "select",
            placeholder: t("coupon.label.select_course"),
            initialValue: t("coupon.label.select_course"),
            name: "course",
            value: filterData.course,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            options: courses?.length
              ? courses?.map((course) => ({
                  id: course.id,
                  value: course.id,
                  label: course.name,
                }))
              : [],
          },
        ]
      : []),
    ...(filterData.parent_type == 2
      ? [
          {
            id: "program",
            label: t("coupon.label.select_program"),
            component: "select",
            placeholder: t("coupon.label.select_program"),
            initialValue: t("coupon.label.select_program"),
            name: "program",
            value: filterData.program,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            options: programs?.length
              ? programs?.map((program) => ({
                  id: program.id,
                  value: program.id,
                  label: program.name,
                }))
              : [],
          },
        ]
      : []),
    ...(filterData.parent_type == 3
      ? [
          {
            id: "booth",
            label: t("crud.placeholders.select.exhibition"),
            component: "select",
            placeholder: t("crud.placeholders.select.exhibition"),
            initialValue: t("crud.placeholders.select.exhibition"),
            name: "booth",
            value: filterData.booth,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            options: boothExhibition?.length
              ? boothExhibition?.map((booth) => ({
                  id: booth.id,
                  value: booth.id,
                  label: booth.name,
                }))
              : [],
          },
        ]
      : []),
    ...(filterData.parent_type == 4
      ? [
          {
            id: "visitor",
            label: t("crud.placeholders.select.exhibition"),
            component: "select",
            placeholder: t("crud.placeholders.select.exhibition"),
            initialValue: t("crud.placeholders.select.exhibition"),
            name: "visitor",
            value: filterData.visitor,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            options: visitorExhibition?.length
              ? visitorExhibition?.map((visitor) => ({
                  id: visitor.id,
                  value: visitor.id,
                  label: visitor.name,
                }))
              : [],
          },
        ]
      : []),

    {
      id: "status",
      label: t("general.status"),
      component: "select",
      placeholder: t("general.status"),
      initialValue: t("general.status"),
      name: "status",
      value: filterData.status,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: statusOptions || [],
    },
    {
      id: "reset",
      component: "button",
      type: "button",
      name: "action",
      value: t("general.reset"),
      classes: "col-12 col-md-6 col-xl-2 border-button",
      action: () => {
        resetFilterHandler();
      },
      backgroundColor: "transparent",
      color: "#046c77",
      borderColor: "#046c77",
    },
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("general.search"),
      classes: "col-12 col-md-6  col-xl-2",
      action: () => {
        if (currentPage != 1) {
          setCurrentPage(1);
        } else {
          filterHandler();
        }
      },
    },
  ];

  return <FilterBox fields={filterFields} />;
};

export default RefundSettingFilter;
