import FilterIcon from "../../../../assets/icons/filter.svg";
import PdfIcon from "../../../../assets/icons/pdf.svg";
import AddIcon from "../../../../assets/icons/plus.svg";
import XlsIcon from "../../../../assets/icons/xls.svg";

export const actionsList = ({ showFilter, addExhibition, exportPdf, exportExcel }) => {
  const actionsList = [
    {
      id: "filter",
      icon: <img src={FilterIcon} alt="" />,
      action: showFilter,
    },
    {
      id: "add",
      icon: <img src={AddIcon} alt="" />,
      theme: "add",
      action: addExhibition,
    },
  ];
  return actionsList;
};
