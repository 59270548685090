import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import { TrainerAddExamContext } from "../../../../../../Providers/TrainerAddExamProvider";
import { addQuiz, getQuestionBankItems } from "../../../../../../Services/api/exams/ExamsProvider";
import SkeletonCardOverlay from "../../../../../Shared/Components/Spinner/SkeletonCardOverlay";

function QuestionBankPanel(props) {
    require("./QuestionBank.css");
    const {t} = useTranslation();
    const { id, quizable_id, exam_id } = useParams();
    const history = useHistory();

    const trainerAddExamContext = useContext(TrainerAddExamContext);

    const [questions, setQuestions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    const fetchQuestions = () => {
        const params = {};
        if (trainerAddExamContext.editable) {
            params.quiz_id = exam_id
        }
        getQuestionBankItems(id, params).then(res => {
            if (res.status == 200 || res.status == 201) {
                if (trainerAddExamContext.editable) {
                    const selectedItems = [];
                    res.data.response.questions.forEach(item => {
                        if (item.is_selected) {
                            selectedItems.push({id: item.id});
                        }
                    })
                    trainerAddExamContext.setSelectedQuestionBankQuestions(selectedItems);
                }
                setQuestions(res.data.response.questions);
            } else {
                toast.error(
                    <span style={{ fontSize: 13, fontWeight: "bold" }}>
                        {t("failed_fetching")}
                    </span>
                );
            }
            setIsLoading(false);
        }).catch(err => {
            toast.error(
                <span style={{ fontSize: 13, fontWeight: "bold" }}>
                    {t("failed_fetching")}
                </span>
            );
            setIsLoading(false);
        })
    }

    const submitExam = () => {
        setIsSubmitting(true);
        setIsUpdating(true);
        if (!trainerAddExamContext.editable && !trainerAddExamContext.selectedQuestionBankQuestions.length) {
            toast.error(
                <span style={{ fontSize: 13, fontWeight: "bold" }}>
                    You have to select at least one question
                </span>
            );
            setIsSubmitting(false);
            setIsUpdating(false);
        } else {
            if (trainerAddExamContext.editable) {
                trainerAddExamContext.submitExamEdits(null,exam_id).then(result => {
                    trainerAddExamContext.fetchExamData(exam_id).then(quizData => {
                        toast.success(
                            <span style={{ fontSize: 13, fontWeight: "bold" }}>
                                {t('trainer.quiz.edited_successfully')}
                            </span>
                        )
                        trainerAddExamContext.setAddedQuestions(quizData.added_questions);
                        setIsSubmitting(false);
                        setIsUpdating(false);
                    } ,err => {
                        setIsSubmitting(false);
                        setIsUpdating(false);
                    });
                }, error => {
                    setIsSubmitting(false);
                    setIsUpdating(false);
                })
            } else {
                const questionBankItems = [];
                let order = 1;
                trainerAddExamContext.selectedQuestionBankQuestions.forEach(item => {
                    questionBankItems.push({
                        question_id: item.id,
                        order: order
                    })
                    order++;
                })
                const examPayload = trainerAddExamContext.examPayload;
                examPayload.questions_bank = questionBankItems;
                
                setIsUpdating(true);
                addQuiz(examPayload).then(res => {
                    toast.success(
                        <span style={{ fontSize: 13, fontWeight: "bold" }}>
                            {t('trainer.quiz.added_successfully')}
                        </span>
                    )
                    
                    setIsUpdating(false);
                    setIsSubmitting(false);
                    history.push("/trainer/course/"+id+"/"+trainerAddExamContext.quizable+"/"+quizable_id+"/assessments");
                }).catch(err => {
                    
                    setIsSubmitting(false);
                    setIsUpdating(false);
                    if (err.response.data
                        && err.response.data.errors
                        && typeof err.response.data.errors === 'object'
                        && !Array.isArray(err.response.data.errors)
                        && err.response.data.errors !== null) {
                        Object.keys(err.response.data.errors).forEach((errorItemKey) => {
                            if (Array.isArray(err.response.data.errors[errorItemKey])) {
                                toast.error(
                                    <span style={{ fontSize: 13, fontWeight: "bold" }}>
                                    {err.response.data.errors[errorItemKey][0]}
                                </span>
                                );
                            }
                        });
                    }
                })
            }
        }
    }

    useEffect(() => {
        fetchQuestions();
    }, [])
    
    return (
        <>
            {isUpdating ?<SkeletonCardOverlay skeletonWidth="100"/>:<div></div>}
            <div className={"question-canvas-for-question-bank-in-exam"}>
                <Paper variant={"outlined"}
                       sx={{
                           width: '100%',
                           height: isLoading ? 300 : 'auto',
                           borderWidth:0
                       }}>
                    {
                        isLoading ?
                            <div className={"tajah-question-progress"}>
                                <CircularProgress />
                            </div> :
                            <div className={"tajah-form-container"} style={{
                                boxShadow: "none",
                                borderRadius: 0
                            }}>
                                <div className={"tajah-pre-table-header"}>
                                    <span>{t("trainer.quiz.question_bank")}</span>
                                    <button
                                        className={"btn btn-success"}
                                        style={{
                                            backgroundColor: "#006d77",
                                            borderColor: "#006d77",
                                            display:'flex',
                                            flexDirection:'row',
                                            borderRadius:10,
                                            alignItems:"center"
                                        }}
                                        onClick={() => {
                                            setIsLoading(true);
                                            fetchQuestions();
                                        }}
                                    >
                                        <span style={{color:'white'}}>{t("trainer.quiz.refresh_questions")}</span>
                                    </button>
                                </div>
                                <table className="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">#</th>
                                        <th scope="col">{t("trainer.quiz.crud.question_title")}</th>
                                        <th scope="col">{t("trainer.quiz.crud.question_type")}</th>
                                        <th scope="col">{t("trainer.quiz.crud.input.grade")}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {questions.map((item) => {
                                        return (
                                            <tr key={item.id}>
                                                <td>
                                                    <Checkbox
                                                        checked={trainerAddExamContext.selectedQuestionBankQuestions.find(i => i.id == item.id) != null ? true : false}
                                                        onChange={(elem) => {
                                                            const currentSelectedQuestionBankQuestions = trainerAddExamContext.selectedQuestionBankQuestions.filter( i=> i.id != item.id);

                                                            if (elem.target.checked) {
                                                                currentSelectedQuestionBankQuestions.push({id: item.id})
                                                            }
                                                            trainerAddExamContext.setSelectedQuestionBankQuestions(currentSelectedQuestionBankQuestions);
                                                        }}
                                                    />
                                                </td>
                                                <td>{item.id}</td>
                                                <td>{item.title}</td>
                                                <td>{document.body.dir == 'rtl' ? item.type.name_en : item.type.name_ar}</td>
                                                <td>{
                                                    <input 
                                                        disabled={trainerAddExamContext.selectedQuestionBankQuestions.find(i => i.id == item.id) != null ? false : true} 
                                                        placeholder={item.degree}
                                                        onChange={(elem) => {
                                                            let currentSelectedQuestionBankQuestions = [...trainerAddExamContext.selectedQuestionBankQuestions];

                                                            var index = currentSelectedQuestionBankQuestions.findIndex(i => i.id == item.id);
                                                            currentSelectedQuestionBankQuestions[index].degree = elem.target.value;
                                                            trainerAddExamContext.setSelectedQuestionBankQuestions(currentSelectedQuestionBankQuestions);
                                                        }}
                                                        className="admin_filter_input" />}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                            </div>
                    }
                    {
                        !isLoading?
                        <div className={"tajah-question-bank-submission-buttons"}>
                            <button
                                type="submit"
                                className="admin_add_button"
                                disabled={isSubmitting}
                                onClick={() => {
                                    submitExam();
                                }}
                            >
                                {t("crud.placeholders.save")}
                            </button>

                            <button
                                type="button"
                                className="admin_add_button"
                                disabled={isSubmitting}
                                style={{
                                    backgroundColor: '#CDCDCD',
                                    color: '#000000',
                                    marginRight: document.body.dir == 'rtl' ? 10 : 0,
                                    marginLeft: document.body.dir == 'ltr' ? 10 : 0
                                }}
                                onClick={() => {
                                    trainerAddExamContext.setActivePanel(0);
                                }}
                            >
                                {t("crud.placeholders.previous")}
                            </button>
                        </div> : null
                    }
                </Paper>
            </div>
        </>
    )
}

export default QuestionBankPanel;
