import {Formik} from "formik";
import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {AuthContext} from "../../../Services/api/auth/AuthContext";
import i18n from "../../../i18n/i18n";
import {loginSso} from "../../../Services/api/auth/AuthProvider";
import {useHistory} from "react-router";
// import CircularProgress from '@mui/material/CircularProgress';
// import Box from '@mui/material/Box';
// import LinearProgress from '@mui/material/LinearProgress';
import {CircularProgress} from '@material-ui/core';

function Sso(props) {
  require("./Sso.css");
  const { t } = useTranslation();
  document.body.dir = i18n.dir();
  const history = useHistory();
  const authContext = useContext(AuthContext);

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [error,setError] = useState("")
  const [loading,setLoading] = useState(false)
  function validateEmail(email) {
    let em = /\S+@\S+\.\S+/;
    return em.test(email);
  }

  return (
    <>
      <div
        className="sso-popup"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <div className="sso-header" style={{justifyContent: "flex-end"}}>
          <i
            className="fas fa-times tajah-modal-dismiss"
            onClick={() => props.ssoModalRef.current.dismissModal()}
          ></i>
        </div>
        <div className="sso-form">
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                setLoading(true)
                loginSso(values.email, values.password)
                  .then((res) => {
                    if (res.data.user.accessToken) {
                      let roles = [];
                      res.data.user.roles.forEach((item) => {
                        roles.push(item.name);
                      });
                      localStorage.setItem("roles", JSON.stringify(roles));
                      localStorage.setItem("token", res.data.user.accessToken);
                      localStorage.setItem("name_ar", res.data.user.name_ar);
                      localStorage.setItem("name_en", res.data.user.name_en);
                      localStorage.setItem("email", res.data.user.email);
                      localStorage.setItem("is_admin", res.data.user.is_admin);
                      localStorage.setItem("img", res.data.user.img);
                      localStorage.setItem("userId", res.data.user.id);

                      authContext.setAuth({ token: res.data.user.accessToken });
                      authContext.setRoles(JSON.stringify(roles));
                      setLoading(false)
                      // props.ssoModalRef.current.dismissModal();
                      if (res.data.user.is_admin === true) {
                        history.push("/admin");
                        window.location.reload();
                      } else {
                        window.location.href = process.env.REACT_APP_FRONT_BASE_URL+"/edit-profile";
                      }
                    }
                  })
                  .catch((error) => {
                    setLoading(false)
                    if(error == "Error: Request failed with status code 422"){
                      setError(
                          <div className="error_login">
                              {t("error_msg_sso")}
                          </div>
                      );
                    }
                  });
                setSubmitting(false);
              } catch (err) {
                setSubmitting(false);
              }
            }}
            validateOnChange={hasSubmitted}
            validate={(values) => {
              setHasSubmitted(true);
              const errors = {};
              const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

              if (!(values.password.length > 6)) {
                errors.password = t("8char");
              }

              if (!values.email) {
                errors.email = t("crud.errors.required");
              }

              if (regex.test(values.email) === false) {
                errors.email = t("error_msg_shape_email");
              }

              if (!values.password) {
                errors.password = t("crud.errors.required");
              }

              return errors;
            }}
          >
            {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit} className="sso-card">
                <div className="mt-3">
                  <label htmlFor="email" className="sso-input-label">
                    <span className="">{t("userNumber_email")}:</span>
                  </label>
                  <input
                    name="email"
                    id="email"
                    className="sso-input"
                    autoFocus
                    type="text"
                    value={values.email}
                    onChange={handleChange}
                  />
                  <p className={"form-input-error-space"}>
                    {errors.email ? errors.email : null}
                  </p>
                </div>

                <div className="">
                  <label htmlFor="password" className="sso-input-label">
                    <span className="">{t("password")}:</span>
                  </label>

                  <input
                    name="password"
                    id="password"
                    type="password"
                    className="sso-input"
                    value={values.password}
                    onChange={handleChange}
                  />
                  <p className={"form-input-error-space"}>
                    {errors.password ? errors.password : null}
                  </p>
                </div>

                {error ? error : null}

                <button
                  type="submit"
                  className="sso-btn"
                  disabled={isSubmitting}
                >
                  {loading ? (
                    <div style={{ display: 'flex' }}>
                      <CircularProgress color="white" />
                    </div>
                  ):(
                  <div className="">{t("EnterMyAccount")}</div>
                  )}
                </button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default Sso;
