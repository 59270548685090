import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  DOC_XLSX,
  acceptExcel,
  fromKbToMb,
  validateExcel,
} from "../../../../Services/Validator";
import { getListType } from "../../../../Services/api/TypeFile/TypeFileProvider";
import CloseIcon from "../../../../assets/icons/remove-red.svg";
import CustomButton from "../../../Shared/Components/CustomButton/CustomButton";
import InputFile from "../../../Shared/Components/InputFile/InputFile";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import classes from "./addUsersByExcelModal.module.css";

const AddUsersByExcelModal = ({
  onClose,
  onConfirm,
  downloadExcelTemplate,
  addingUserExel,
}) => {
  const { t } = useTranslation();
  const { addUsersByExcelLoading } = useSelector(
    (state) => state.systemUsersListReducer
  );

  const [hasSubmittedLesson, setHasSubmittedLesson] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listTypeFile, setListTypeFile] = useState([]);

  const getListTypeFile = () => {
    const myPromise = new Promise((resolve, reject) => {
      getListType().then(
        (res) => {
          setListTypeFile(res?.data?.data);
          resolve();
        },
        (error) => {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {t("programs.failed_loading")}
            </span>
          );
          reject();
        }
      );
    });

    return myPromise;
  };

  useEffect(() => {
    getListTypeFile();
  }, []);

  const getTypeFile = (searchType) => {
    return listTypeFile.filter((file) => file.type === searchType);
  };
  console.log('listTypeFile :', getTypeFile(DOC_XLSX)[0]?.max_size);

  const validateFile = (file) => {
    return validateExcel(file, getTypeFile(DOC_XLSX)[0]?.max_size);
  };

  return addingUserExel ? (
    <SkeletonCardOverlay skeletonWidth="100" />
  ) : (
    <div className={classes.addUsersByExcel}>
      <div className={classes.addUsersByExcel_header}>
        <h2 className={classes.addUsersByExcel_title}>
          {t("add_users_by_excel")}
        </h2>
        <div className={classes.addUsersByExcel_close} onClick={onClose}>
          <img src={CloseIcon} alt="close" />
        </div>
      </div>
      {loading || addUsersByExcelLoading ? <SkeletonCardOverlay /> : null}
      <Formik
        initialValues={{
          file: "",
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            setSubmitting(true);
            let data = { file: values.file.file };
            await onConfirm(data);
          } catch (err) {
            toast.error(
              <span style={{ fontSize: 13, fontWeight: "bold" }}>
                {err.response.data.msg}
              </span>
            );
          }
        }}
        validateOnChange={hasSubmittedLesson}
        validate={(values) => {
          const errors = {};
          if (!values.file) {
            errors.file = t("crud.errors.required");
          }

          setHasSubmittedLesson(true);
          return errors;
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          setFieldValue,
          handleSubmit,
          isSubmitting,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              <div className="form-fields">
                <div
                  className={`${classes["addUsersByExcel__form__field"]} col-12`}
                >
                  <InputFile
                    id="excel"
                    name="file"
                    type="document"
                    documentWithoutTitle={true}
                    placeholder={""}
                    setFieldValue={setFieldValue}
                    accept={acceptExcel()}
                    validate={validateFile}
                    label={t(`upload_excel`)}
                    maxSize={`${t("upload_excel_size")} ${fromKbToMb(
                      getTypeFile(DOC_XLSX)[0]?.max_size
                    )} ${t("megabyte")}`}
                    error={errors?.file}
                    value={values.file}
                    note={`${t("upload_excel_size")} ${fromKbToMb(
                      getTypeFile(DOC_XLSX)[0]?.max_size
                    )} ${t("booth.megabyte")}`}
                    disabled={addUsersByExcelLoading || loading}
                  />
                </div>
              </div>
              <div className={classes["addUsersByExcel-actions"]}>
                <CustomButton
                  value={t("download_excel_template")}
                  colors="#036c77"
                  variant="outlined"
                  type="button"
                  disable={isSubmitting}
                  loading={isSubmitting}
                  action={async () => {
                    setLoading(true);
                    await downloadExcelTemplate();
                    setLoading(false);
                  }}
                  classes={classes["addUsersByExcel-actions-btn"]}
                />
                <CustomButton
                  value={t("submit")}
                  colors="#036c77"
                  type="submit"
                  disable={isSubmitting}
                  loading={isSubmitting}
                  classes={classes["addUsersByExcel-actions-btn"]}
                />
              </div>
            </form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default AddUsersByExcelModal;
