import React from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./BoothTable.css";
import classes from "./bootDetails.module.css";

const BootDetailsCarouselVertical = ({ webinars }) => {
  require("./BoothTable.css");
  const { t, i18n } = useTranslation();

  const newLinks = webinars?.map((link) => {
    return {
      id: link.id,
      link:
        (link.link && link.link.startsWith("http")) || (link.link && link.link?.startsWith("https"))
          ? link.link
          : `https://${link.link}`,
      linkPreview: link.name,
    };
  });

  const CustomArrows = (props) => (
    <div className="custom-arrows">
      <button className="custom-arrow top" onClick={props.onClickPrev}>
        <span>&#9650;</span>
      </button>
      <button className="custom-arrow bottom" onClick={props.onClickNext}>
        <span>&#9660;</span>
      </button>
    </div>
  );

  const settings = {
    dots: true,
    infinite: true,
    vertical: true,
    verticalSwiping: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    customPaging: () => <div className="custom-dot"></div>,
    appendDots: (dots) => <ul>{dots}</ul>,
    customPrevNext: CustomArrows,
  };

  return (
    <div className={classes["py-60"]}>
      <div className="vertical-carousel-container">
        <h3 className="carousel-title">{t("Exhibition.webinars")}</h3>
        {newLinks?.length >= 3 ? (
          <Slider {...settings}>
            {newLinks.map((link) => (
              <LinkPreview link={link} />
            ))}
          </Slider>
        ) : (
          newLinks.map((link) => <LinkPreview link={link} />)
        )}
      </div>
    </div>
  );
};

const LinkPreview = ({ link }) => {
  const onClickHandler = () => {
    window.open(link.link, "_blank");
  };
  return (
    <div key={link.id} className={classes["carousel-slide"]} onClick={onClickHandler}>
      {link.linkPreview}
    </div>
  );
};

export default BootDetailsCarouselVertical;
