import env from "../Environment";
import connector from "../Handler";

export function getPreferences() {
    const myPromise = new Promise((resolve, reject) => {
        try {
            connector
                .get(env.get_preferences)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        } catch (error) {
            reject(error);
        }
    });

    return myPromise;
}

export function setPreferences(payload) {
    const myPromise = new Promise((resolve, reject) => {
        try {
            connector
                .post(env.set_preferences, payload)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        } catch (error) {
            reject(error);
        }
    });

    return myPromise;
}
