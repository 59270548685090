import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { WithContext as ReactTags } from "react-tag-input";
import {
  editCourse,
  getAllCourses,
  getCourseInfo,
  listCourseCategories,
  listCourseDepartments,
} from "../../../../Services/api/courses/courseProvider";
import i18n from "../../../../i18n/i18n";
import { Lang } from "../../../../utils";
import { useHistory, useParams } from "react-router";
import ear from "../../../../assets/image/eardoc.png";
import browsePhoto from "../../../../assets/image/photo.png";
import DatePicker from "react-datepicker";
import { NavLink } from "react-router-dom";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import { toast } from "react-toastify";
import UploadAdapter from "../../../../CKEditor/Adapter/UploadAdapter";
import { Hint } from "../../../../Components";

const KeyCodes = {
  enter: 13,
};
const delimiters = [KeyCodes.enter];

function EditCourse() {
  require("./edit.css");
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const inputFile = useRef(null);

  const [courseData, setCourseData] = useState([]);
  const [error, setErrors] = useState("");
  const [fileName, setFileName] = useState("");

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [categories, setCategories] = useState([]);
  const [hasFetchedCategories, setHasFetchedCategories] = useState(false);

  const [departments, setDepartments] = useState([]);
  const [tags, setTags] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [levels, setLevels] = useState([
    { id: 1, level_ar: "مبتدىء", level_en: "beginner" },
    { id: 2, level_ar: "متوسط", level_en: "medium" },
    { id: 3, level_ar: "متقدم", level_en: "professional" },
  ]);

  const [hasFetchedDepartments, setHasFetchedDepartments] = useState(false);
  const [allCourses, setAllCourses] = useState([]);

  const [toBeShownImage, setToBeShownImage] = useState(null);

  const [radioButtonState, setRadioButtonState] = useState("1");

  const [htlmEditor, setHtlmEditor] = useState("");
  const [htmlEditorText, setHtmlEditorText] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [mode, setMode] = useState(courseData.mode);

  useEffect(() => {
    getCourseInfo(id)
      .then((res) => {
        if (res.data.course.trainer.id != localStorage.getItem("userId")) {
          history.push("/private-profile");
          toast.error(
            <span style={{ fontSize: 14 }}>{t("trainer_edit_course")}</span>
          );
        }
        setCourseData(res.data.course);
        setMode(res.data.course.mode);
        setHtmlEditorText(res.data.course.text);
        setToBeShownImage(res.data.course.img);
        setRadioButtonState(res.data.course.status === "Published" ? "1" : "2");
        let arr = [];
        res.data.course.tags.forEach((tag) => {
          let obj = { id: tag, text: tag };
          arr.push(obj);
        });
        setTags(arr);
        setIsLoading(false);
      })
      .catch((err) => {});

    listCourseCategories()
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setHasFetchedCategories(true);
          setCategories(res.data.data);
        }
      })
      .catch((err) => {});

    listCourseDepartments()
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setHasFetchedDepartments(true);
          setDepartments(res.data.data);
        }
      })
      .catch((err) => {});

    getAllCourses()
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setAllCourses(res.data.courses);
        }
      })
      .catch((err) => {});
  }, []);

  const formatDate = (d) => {
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const formatTime = (t) => {
    let hours = "" + t.getHours(),
      minutes = "" + t.getMinutes(),
      seconds = "" + t.getSeconds();

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return [hours, minutes, seconds].join(":");
  };

  const isValidURL = (url) => {
    var res = url.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    return res !== null;
  };

  const handleRadioMode = (e) => {
    setMode(e.target.value);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row bg-container">
          <div className="col-12 bg-container">
            <div className="training_first_label mt-4">
              <NavLink to="/">{t("Home")}</NavLink>
              <p style={{ padding: "0 5px" }}>/</p>
              {t("editcourse")}
            </div>
            {isLoading ? (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "70%",
                    height: "100vh",
                  }}
                >
                  <SkeletonCard />
                </div>
              </div>
            ) : (
              <>
                <div
                  className="personal_info_label"
                  style={{
                    margin: "10px 100px",
                  }}
                >
                  <div className="personal_info_top">{t("editcourse")}</div>
                  <div className="personal_info_bottom">
                    {t("afterediting")}
                  </div>
                </div>
                <div className="personal_info_card_st">
                  <div className="personal_info_card">
                    <Formik
                      initialValues={{
                        name: courseData.name == null ? "" : courseData.name,
                        description:
                          courseData.description == null
                            ? ""
                            : courseData.description,
                        marketing_description:
                          courseData.marketing_description == ""
                            ? ""
                            : courseData.marketing_description,
                        code: courseData.code == null ? "" : courseData.code,
                        section_id:
                          courseData.department_id == null
                            ? ""
                            : courseData.department_id,
                        category_id:
                          courseData.category_id == null
                            ? ""
                            : courseData.category_id,
                        division:
                          courseData.division == null
                            ? ""
                            : courseData.division,
                        image: courseData.img == null ? "" : courseData.img,
                        video: courseData.video == null ? "" : courseData.video,
                        language:
                          courseData.language == null
                            ? ""
                            : courseData.language,
                        participators:
                          courseData.participators == null
                            ? ""
                            : courseData.participators,
                        prerequisited_course_id:
                          courseData.prerequisited_course_id == null
                            ? ""
                            : courseData.prerequisited_course_id,
                        status: courseData.status === "Published" ? "1" : "2",
                        start_date:
                          courseData.start_date == null
                            ? ""
                            : Date.parse(courseData.start_date),
                        end_date:
                          courseData.end_date == null
                            ? ""
                            : Date.parse(courseData.end_date),
                        start_register_date:
                          courseData.start_register_date == null
                            ? ""
                            : Date.parse(courseData.start_register_date),
                        end_register_date:
                          courseData.end_register_date == null
                            ? ""
                            : Date.parse(courseData.end_register_date),
                        price: courseData.price == null ? "" : courseData.price,
                        text: htmlEditorText == null ? "" : htmlEditorText,
                        file: "",
                        level: courseData.level == null ? "" : courseData.level,
                        tags: tags,
                        number_hours:
                          courseData.number_hours == null
                            ? ""
                            : courseData.number_hours,
                      }}
                      onSubmit={async (values, { setSubmitting }) => {
                        try {
                          let formData = new FormData();
                          Object.keys(values).forEach((field) => {
                            if (field != "tags") {
                              formData.append(field, values[field]);
                            }
                          });

                          if (values.tags.length != 0) {
                            values.tags.forEach((tag) => {
                              formData.append("tags[]", tag.id);
                            });
                          }

                          formData.append("page", "page");
                          formData.append("cover", "cover");
                          formData.append(
                            "status",
                            values.status == "1" ? "Published" : "Unpublished"
                          );
                          formData.append(
                            "start_date",
                            formatDate(new Date(values.start_date)) +
                              " " +
                              formatTime(new Date(values.start_date))
                          );
                          formData.append(
                            "end_date",
                            formatDate(new Date(values.end_date)) +
                              " " +
                              formatTime(new Date(values.end_date))
                          );
                          formData.append(
                            "start_register_date",
                            formatDate(new Date(values.start_register_date)) +
                              " " +
                              formatTime(new Date(values.start_register_date))
                          );
                          formData.append(
                            "end_register_date",
                            formatDate(new Date(values.end_register_date)) +
                              " " +
                              formatTime(new Date(values.end_register_date))
                          );
                          formData.append(
                            "mode",
                            mode == "Paid" ? "Paid" : "Free"
                          );
                          if (mode === "Free") {
                            formData.append("price", "0.00");
                          }
                          let response = await editCourse(id, formData);
                          setSubmitting(false);
                          if (response.status === 200 && response.data.status) {
                            history.push("/private-profile");
                          }
                        } catch (err) {
                          toast.error(
                            <span style={{ fontSize: 13, fontWeight: "bold" }}>
                              {typeof err === "string" ? (
                                <span
                                  style={{
                                    fontSize: 12,
                                    fontWeight: "500",
                                    display: "block",
                                  }}
                                >
                                  {err}
                                </span>
                              ) : err.response.data ? (
                                <>
                                  <span
                                    style={{
                                      fontSize: 13,
                                      fontWeight: "bold",
                                      display: "block",
                                    }}
                                  >
                                    {err.response.data.msg}
                                  </span>
                                  <ul>
                                    {Object.keys(err.response.data.errors).map(
                                      (key) => {
                                        return (
                                          <li>
                                            <span style={{ fontSize: 11 }}>
                                              {err.response.data.errors[key]}
                                            </span>
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                </>
                              ) : (
                                t("Failure_in_service")
                              )}
                            </span>
                          );
                        }
                      }}
                      enableReinitialize
                      validateOnChange={hasSubmitted}
                      validate={(values) => {
                        setHasSubmitted(true);
                        const errors = {};
                        if (!values.name) {
                          errors.name = t("crud.errors.required");
                        }

                        if (!values.image) {
                          errors.image = t("crud.errors.required");
                        }

                        if (!values.description) {
                          errors.description = t("crud.errors.required");
                        }

                        if (!values.code) {
                          errors.code = t("crud.errors.required");
                        }

                        if (!values.section_id) {
                          errors.section_id = t("crud.errors.required");
                        }

                        if (!values.category_id) {
                          errors.category_id = t("crud.errors.required");
                        }

                        if (!values.division) {
                          errors.division = t("crud.errors.required");
                        }

                        if (!values.number_hours) {
                          errors.number_hours = t("crud.errors.required");
                        }

                        if (!isValidURL(values.video)) {
                          errors.video = t("crud.errors.required");
                        }

                        if (!values.language) {
                          errors.language = t("crud.errors.required");
                        }

                        return errors;
                      }}
                    >
                      {({
                        values,
                        errors,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        handleBlur,
                        setFieldValue,
                      }) => (
                        <>
                          <div className="perosnal_text_img">
                            {t("coursephoto")}
                          </div>
                          <div className="course_img">
                            <div className="">
                              <div className="">
                                <div className="">
                                  <img
                                    src={toBeShownImage ? toBeShownImage : ear}
                                    alt=""
                                    className="course_dimage"
                                  />
                                </div>
                                <div
                                  className="course_browse"
                                  onClick={() => {
                                    if (inputFile) {
                                      inputFile.current.click();
                                    }
                                  }}
                                >
                                  <img
                                    src={browsePhoto}
                                    alt=""
                                    className="personal_browse_img"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="personal_img_text">
                              {t("choosepng")}
                            </div>
                          </div>
                          <p className={"form-input-error-space"}>
                            {errors.image ? errors.image : null}
                          </p>
                          <input
                            id="image"
                            name="file"
                            type="file"
                            ref={inputFile}
                            style={{ display: "none" }}
                            onChange={(event) => {
                              let file = event.target.files[0];
                              if (file.name.match(/\.(jpg|jpeg|png|gif)$/i)) {
                                let reader = new FileReader();
                                reader.readAsDataURL(file);
                                reader.onload = () => {
                                  setFieldValue("image", event.target.files[0]);
                                  setToBeShownImage(reader.result);
                                };
                                reader.onerror = function (error) {};
                              } else {
                                toast.error(
                                  <span style={{ fontSize: 14 }}>
                                    صيغة الملف خطأ
                                  </span>
                                );
                              }
                            }}
                          />
                          <div className={"personal_form"}>
                            <form
                              onSubmit={handleSubmit}
                              className="admin_add_form"
                            >
                              <div>
                                <label
                                  htmlFor="name"
                                  className="personal_label"
                                >
                                  {t("coursename") + ":"}
                                </label>
                                <input
                                  id="name"
                                  type="text"
                                  name="name"
                                  maxLength={100}
                                  className="personal_input_in"
                                  onChange={handleChange}
                                  value={values.name}
                                />
                                <Hint
                                  hint={t(
                                    "hint.course.addCourse.length_name_course"
                                  )}
                                  color="#03292B"
                                  fontSize={12}
                                />
                                <p className={"form-input-error-space"}>
                                  {errors.name ? errors.name : null}
                                </p>
                              </div>
                              <div>
                                <label
                                  htmlFor="code"
                                  className="personal_label"
                                >
                                  {t("coursecode") + ":"}
                                </label>
                                <input
                                  id="code"
                                  type="text"
                                  name="code"
                                  className="personal_input_in"
                                  onChange={handleChange}
                                  value={values.code}
                                />
                                <p className={"form-input-error-space"}>
                                  {errors.code ? errors.code : null}
                                </p>
                              </div>

                              <div>
                                <label
                                  htmlFor="division"
                                  className="personal_label"
                                >
                                  {t("coursedivision") + ":"}
                                </label>
                                <input
                                  id="division"
                                  name="division"
                                  type="text"
                                  value={values.division}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className="personal_input_in"
                                />
                                <p className={"form-input-error-space"}>
                                  {errors.division ? errors.division : null}
                                </p>
                              </div>

                              <div>
                                <label
                                  htmlFor="section_id"
                                  className="personal_label"
                                >
                                  {t("selectselection") + ":"}
                                </label>
                                <select
                                  id="section_id"
                                  name="section_id"
                                  value={values.section_id}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className="personal_input_in"
                                >
                                  <option></option>
                                  {departments &&
                                    departments.map((item) => {
                                      return (
                                        <option key={item.id} value={item.id}>
                                          {i18n.language === Lang.AR
                                            ? item.name_ar
                                            : item.name_en}
                                        </option>
                                      );
                                    })}
                                </select>
                                <p className={"form-input-error-space"}>
                                  {errors.section_id ? errors.section_id : null}
                                </p>
                              </div>

                              <div>
                                <label
                                  htmlFor="category_id"
                                  className="personal_label"
                                >
                                  {t("classification") + ":"}
                                </label>
                                <select
                                  id="category_id"
                                  name="category_id"
                                  value={values.category_id}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className="personal_input_in"
                                >
                                  <option></option>
                                  {categories.map((item) => {
                                    return (
                                      <option key={item.id} value={item.id}>
                                        {i18n.language === Lang.AR
                                          ? item.name_ar
                                          : item.name_en}
                                      </option>
                                    );
                                  })}
                                </select>
                                <p className={"form-input-error-space"}>
                                  {errors.category_id
                                    ? errors.category_id
                                    : null}
                                </p>
                              </div>

                              <div>
                                <label
                                  htmlFor="level"
                                  className="personal_label"
                                >
                                  {t("level") + ":"}
                                </label>
                                <select
                                  id="level"
                                  name="level"
                                  value={values.level}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className="personal_input_in"
                                >
                                  <option></option>
                                  {levels.map((item) => {
                                    return (
                                      <option key={item.id} value={item.id}>
                                        {i18n.language === Lang.AR
                                          ? item.level_ar
                                          : item.level_en}
                                      </option>
                                    );
                                  })}
                                </select>
                                <p className={"form-input-error-space"}>
                                  {errors.level ? errors.level : null}
                                </p>
                              </div>
                              <div>
                                <label
                                  htmlFor="number_hours"
                                  className="personal_label"
                                >
                                  {t("numberHours") + ":"}
                                </label>
                                <input
                                  id="number_hours"
                                  name="number_hours"
                                  type="number"
                                  min={1}
                                  value={values.number_hours}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className="personal_input_in"
                                />
                                <p className={"form-input-error-space"}>
                                  {errors.number_hours
                                    ? errors.number_hours
                                    : null}
                                </p>
                              </div>
                              <div>
                                <label
                                  htmlFor="description"
                                  className="personal_label"
                                >
                                  {t("brief") + ":"}
                                </label>
                                <input
                                  id="marketing_description"
                                  type="text"
                                  name="marketing_description"
                                  className="personal_input_in"
                                  onChange={handleChange}
                                  placeholder={t(
                                    "crud.placeholders.description"
                                  )}
                                  value={values.marketing_description}
                                />
                                <p className={"form-input-error-space"}>
                                  {errors.description
                                    ? errors.description
                                    : null}
                                </p>
                              </div>

                              <div>
                                <label
                                  htmlFor="description"
                                  className="personal_label"
                                >
                                  {t("description")}
                                </label>
                                <input
                                  id="description"
                                  type="text"
                                  name="description"
                                  className="personal_input_in"
                                  onChange={handleChange}
                                  placeholder={t(
                                    "crud.placeholders.description"
                                  )}
                                  value={values.description}
                                />
                                <p className={"form-input-error-space"}>
                                  {errors.description
                                    ? errors.description
                                    : null}
                                </p>
                              </div>
                              <div>
                                <label
                                  htmlFor="file"
                                  className="personal_label"
                                >
                                  {t("trainer.course.edit.file")}
                                </label>
                                <input
                                  type="file"
                                  name="file"
                                  id="file"
                                  className="inputfile"
                                  accept=".doc,.docx,application/pdf"
                                  onChange={(event) => {
                                    let file = event.target.files[0];
                                    let reader = new FileReader();
                                    reader.readAsDataURL(file);
                                    reader.onload = () => {
                                      setFieldValue(
                                        "file",
                                        event.target.files[0]
                                      );
                                      setFileName(event.target.files[0].name);
                                    };
                                    reader.onerror = function (error) {};
                                  }}
                                />
                                <label for="file" className="labelFile">
                                  {fileName != ""
                                    ? fileName
                                    : courseData.file
                                    ? courseData.file.substring(
                                        courseData.file.lastIndexOf("/") + 1,
                                        courseData.file.length
                                      )
                                    : t("chooseFile")}
                                  {"     "}
                                  <i
                                    className="fa fa-file-upload"
                                    style={{ fontSize: 25 }}
                                  ></i>
                                </label>
                                <p className={"form-input-error-space"}>
                                  {errors.file ? errors.file : null}
                                </p>
                              </div>
                              <div>
                                <label
                                  htmlFor="text"
                                  className="personal_label"
                                >
                                  {t("courseContent")}
                                </label>
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={values.text}
                                  onInit={(editor) => {
                                    setHtlmEditor(editor.getData());
                                  }}
                                  onReady={(editor) => {
                                    setHtlmEditor(editor.getData());
                                    editor.plugins.get(
                                      "FileRepository"
                                    ).createUploadAdapter = function (loader) {
                                      return new UploadAdapter(loader);
                                    };
                                  }}
                                  config={{
                                    simpleUpload: {
                                      uploadUrl:
                                        "http://tajah-tebx.test/api/ckeditor/image-upload",
                                      withCredentials: true,

                                      // Headers sent along with the XMLHttpRequest to the upload server.
                                      headers: {
                                        // 'X-CSRF-TOKEN': 'CSRF-Token',
                                        Authorization: `Bearer ${localStorage.getItem(
                                          "token"
                                        )}`,
                                      },
                                    },
                                  }}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setFieldValue("text", data);
                                  }}
                                  onBlur={(event, editor) => {}}
                                  onFocus={(event, editor) => {}}
                                />
                                <p className={"form-input-error-space"}>
                                  {errors.text ? errors.text : null}
                                </p>
                              </div>

                              <div>
                                <label
                                  htmlFor="linkto"
                                  className="personal_label"
                                >
                                  {t("linkto")}
                                </label>
                                <input
                                  id="video"
                                  type="text"
                                  name="video"
                                  className="personal_input_in"
                                  onChange={handleChange}
                                  value={values.video}
                                />
                                <p className={"form-input-error-space"}>
                                  {errors.video ? errors.video : null}
                                </p>
                              </div>

                              <div>
                                <label
                                  htmlFor="courselanguage"
                                  className="personal_label"
                                >
                                  {t("courselanguage")}
                                </label>
                                <select
                                  id="language"
                                  name="language"
                                  value={values.language}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className="personal_input_in"
                                >
                                  <option></option>
                                  <option value="ar">{t("arabic")}</option>
                                  <option value="en">{t("english")}</option>
                                </select>
                                <p className={"form-input-error-space"}>
                                  {errors.language ? errors.language : null}
                                </p>
                              </div>

                              <div>
                                <label
                                  htmlFor="participators"
                                  className="personal_label"
                                >
                                  {t("maximum")}
                                </label>
                                <input
                                  id="participators"
                                  type="number"
                                  name="participators"
                                  className="personal_input_in"
                                  onChange={handleChange}
                                  value={values.participators}
                                  min={0}
                                />
                                <p className={"form-input-error-space"}>
                                  {errors.participators
                                    ? errors.participators
                                    : null}
                                </p>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  width: "100%",
                                  justifyContent: "space-between",
                                }}
                                className="edit-course__form-group"
                              >
                                <div
                                  style={{
                                    width: "40%",
                                  }}
                                >
                                  <label
                                    htmlFor="start_date"
                                    className="personal_label"
                                  >
                                    {t("crud.placeholders.course_start_date")}
                                  </label>
                                  <DatePicker
                                    selected={values.start_date}
                                    onChange={(date) =>
                                      setFieldValue("start_date", date)
                                    }
                                    // disabled={courseData.used}
                                    showTimeSelect
                                    placeholderText={t(
                                      "crud.placeholders.course_start_date"
                                    )}
                                    className="admin_add_input personal_input_in"
                                    showTime={{ use12Hours: true }}
                                    dateFormat={"yyyy-MM-dd hh:mm:ss a"}
                                  />
                                  <p className={"form-input-error-space"}>
                                    {errors.start_date
                                      ? errors.start_date
                                      : null}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "40%",
                                  }}
                                >
                                  <label
                                    htmlFor="end_date"
                                    className="personal_label"
                                  >
                                    {t("crud.placeholders.course_end_date")}
                                  </label>
                                  <DatePicker
                                    selected={values.end_date}
                                    onChange={(date) =>
                                      setFieldValue("end_date", date)
                                    }
                                    // disabled={courseData.used}
                                    showTimeSelect
                                    placeholderText={t(
                                      "crud.placeholders.course_end_date"
                                    )}
                                    className="admin_add_input personal_input_in"
                                    showTime={{ use12Hours: true }}
                                    dateFormat={"yyyy-MM-dd hh:mm:ss a"}
                                  />
                                  <p className={"form-input-error-space"}>
                                    {errors.end_date ? errors.end_date : null}
                                  </p>
                                </div>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  width: "100%",
                                  justifyContent: "space-between",
                                }}
                                className="edit-course__form-group"
                              >
                                <div
                                  style={{
                                    width: "40%",
                                  }}
                                >
                                  <label
                                    htmlFor="start_register_date"
                                    className="personal_label"
                                  >
                                    {t(
                                      "crud.placeholders.course_start_register"
                                    )}
                                  </label>
                                  <DatePicker
                                    selected={values.start_register_date}
                                    onChange={(date) =>
                                      setFieldValue("start_register_date", date)
                                    }
                                    // disabled={courseData.used}
                                    showTimeSelect
                                    placeholderText={t(
                                      "crud.placeholders.course_start_register"
                                    )}
                                    className="admin_add_input personal_input_in"
                                    showTime={{ use12Hours: true }}
                                    dateFormat={"yyyy-MM-dd hh:mm:ss a"}
                                  />
                                  <p className={"form-input-error-space"}>
                                    {errors.start_register_date
                                      ? errors.start_register_date
                                      : null}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "40%",
                                  }}
                                >
                                  <label
                                    htmlFor="end_register_date"
                                    className="personal_label"
                                  >
                                    {t("crud.placeholders.course_end_register")}
                                  </label>
                                  <DatePicker
                                    selected={values.end_register_date}
                                    onChange={(date) =>
                                      setFieldValue("end_register_date", date)
                                    }
                                    // disabled={courseData.used}
                                    showTimeSelect
                                    placeholderText={t(
                                      "crud.placeholders.course_end_register"
                                    )}
                                    className="admin_add_input personal_input_in"
                                    showTime={{ use12Hours: true }}
                                    dateFormat={"yyyy-MM-dd hh:mm:ss a"}
                                  />
                                  <p className={"form-input-error-space"}>
                                    {errors.end_register_date
                                      ? errors.end_register_date
                                      : null}
                                  </p>
                                </div>
                              </div>

                              <div>
                                <label
                                  htmlFor="prerequisited_course_id"
                                  className="personal_label"
                                >
                                  {t("prerequsiite")}
                                </label>
                                <select
                                  id="prerequisited_course_id"
                                  name="prerequisited_course_id"
                                  value={values.prerequisited_course_id}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className="personal_input_in"
                                >
                                  <option></option>
                                  {allCourses.map((item) => {
                                    return (
                                      <option key={item.id} value={item.id}>
                                        {item.name}
                                      </option>
                                    );
                                  })}
                                </select>
                                <p className={"form-input-error-space"}>
                                  {errors.prerequisited_course_id
                                    ? errors.prerequisited_course_id
                                    : null}
                                </p>
                              </div>

                              <div>
                                <label
                                  htmlFor="tags"
                                  className="personal_label"
                                >
                                  {t("coursetags")}
                                </label>

                                <ReactTags
                                  tags={values.tags}
                                  suggestions={suggestions}
                                  handleDelete={(i) => {
                                    let myTags = values.tags;
                                    myTags.splice(i, 1);
                                    setFieldValue("tags", myTags);
                                  }}
                                  handleAddition={(tag) => {
                                    const myTags = values.tags;
                                    myTags.push(tag);
                                    setFieldValue("tags", myTags);
                                  }}
                                  delimiters={delimiters}
                                  placeholder={t("placeholderTags")}
                                  className="personal_input_in"
                                />
                                <p className={"form-input-error-space"}>
                                  {errors.tags ? errors.tags : null}
                                </p>
                              </div>

                              <div>
                                <label
                                  htmlFor="mode"
                                  className="personal_label"
                                >
                                  {t("coursepricestatus")}
                                </label>

                                <div className="admin_coupon_radio">
                                  <div className="">
                                    <input
                                      className="form-check-input"
                                      style={{
                                        marginLeft: "0px",
                                      }}
                                      type="radio"
                                      name="modeRadioOptions"
                                      id="modeRadio1"
                                      value="Paid"
                                      checked={mode == "Paid"}
                                      onChange={handleRadioMode}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="modeRadio1"
                                    >
                                      {t("paid")}
                                    </label>
                                  </div>
                                  <div className="">
                                    <input
                                      className="form-check-input"
                                      style={{
                                        marginLeft: "0px",
                                      }}
                                      type="radio"
                                      name="modeRadioOptions"
                                      id="modeRadio2"
                                      value="Free"
                                      checked={mode == "Free"}
                                      onChange={handleRadioMode}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="modeRadio2"
                                    >
                                      {t("free")}
                                    </label>
                                  </div>
                                </div>
                              </div>

                              {mode == "Paid" ? (
                                <div>
                                  <label
                                    htmlFor="price"
                                    className="personal_label"
                                  >
                                    {t("courseprice")}
                                  </label>
                                  <input
                                    id="price"
                                    type="number"
                                    min={0}
                                    name="price"
                                    className="personal_input_in"
                                    onChange={handleChange}
                                    value={values.price}
                                  />
                                  <p className={"form-input-error-space"}>
                                    {errors.price ? errors.price : null}
                                  </p>
                                </div>
                              ) : null}

                              <div>
                                <label
                                  htmlFor="coursestatus"
                                  className="personal_label"
                                >
                                  {t("coursestatus")}
                                </label>

                                <div className="admin_coupon_radio">
                                  <div className="">
                                    <input
                                      className="form-check-input"
                                      style={{
                                        marginLeft: "0px",
                                      }}
                                      type="radio"
                                      name="inlineRadioOptions"
                                      id="inlineRadio1"
                                      value="1"
                                      checked={values.status == "1"}
                                      onChange={() => {
                                        setFieldValue("status", "1");
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="inlineRadio1"
                                    >
                                      {t("apparent")}
                                    </label>
                                  </div>
                                  <div className="">
                                    <input
                                      className="form-check-input"
                                      style={{
                                        marginLeft: "0px",
                                      }}
                                      type="radio"
                                      name="inlineRadioOptions"
                                      id="inlineRadio2"
                                      value="2"
                                      checked={values.status == "2"}
                                      onChange={() => {
                                        setFieldValue("status", "2");
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="inlineRadio2"
                                    >
                                      {t("hidden")}
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div>
                                <button
                                  type="submit"
                                  className="admin_add_button"
                                  disabled={
                                    !hasFetchedCategories ||
                                    !hasFetchedDepartments ||
                                    isSubmitting
                                  }
                                >
                                  {t("editcourse")}
                                </button>
                              </div>
                            </form>
                          </div>
                        </>
                      )}
                    </Formik>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default EditCourse;
