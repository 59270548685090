import env from "../Environment";
import connector from "../Handler";
import URLBuilder from "../UrlBuilder";

export function fetchProgram(id = null) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(id ? URLBuilder(env.specific_program, { id: id }) : env.program)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function fetchProgramAvailable() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.program, {
          params: {
            current: "home",
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function fetchAllProgram() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.program, {})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function programsPayments(id, mine = false) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.programs_payments, { id: id }), {
          params: mine
            ? {
                mine: true,
              }
            : {},
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function updateProgram(id, formData) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.update_program, { id: id }), formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function addProgram(formData) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.add_program, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function deleteProgram(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.delete_program, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

// export function downloadInvoice(id) {
// 	const myPromise = new Promise((resolve, reject) => {
// 		try {
// 			connector
// 				.get(URLBuilder(env.download_invoice, { id: id }), { responseType: "blob" })
// 				.then((res) => {
// 					resolve(res);
// 				})
// 				.catch((err) => {
// 					reject(err);
// 				});
// 		} catch (error) {
// 			reject(error);
// 		}
// 	});

// 	return myPromise;
// }
export function downloadInvoice(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.download_invoice, { id: id }), {
          responseType: "blob",
        })
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Invoice" + "." + "pdf"); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
