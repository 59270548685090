import React from "react";
import "react-circular-progressbar/dist/styles.css";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import add from "../../../assets/image/+.png";
import eye from "../../../assets/image/eye.png";

function CourseOutlineHeader() {
  const { t } = useTranslation();

  return (
    <div className="header_profile header_outline">
      <div className="container-fluid profile_wrapper">
        <div className="show_certificate_grid">
          <div className="profile_dhead">
            <div className="profile_dname">
              <div className="profile_dtop">مخطط الدورة التدريبية</div>
            </div>
          </div>

          <div className="profile_btn">
            <NavLink to="" className="course_outline_btn add_view">
              <img src={eye} alt="" className="add_icon" />
              مشاهدة حية
            </NavLink>
            <NavLink to="" className="course_outline_btn add_outline">
              <img src={add} alt="" className="add_icon" />
              أضف فصل جديد
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseOutlineHeader;
