import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

const RejectBoothModal = ({ onChange, close, action, error, isLoading }) => {
  const { t, i18n } = useTranslation();
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        close();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  return (
    <div className="reject-booth-modal">
      <div className="reject-booth-modal__wrapper" ref={modalRef}>
        <div className="reject-booth-modal__header">
          <h3>{t("booth.confirm_reject")}</h3>
        </div>
        <div className="reject-booth-modal__content">
          <label htmlFor="description">{t("booth.reject_reason")}</label>
          <textarea
            id="description"
            name="description"
            rows="7"
            className="tajah-bordered-text-area"
            onChange={onChange}
            placeholder={t("booth.reject_reason")}
          />
          <p className={"form-input-error-space"}>{error ? error : null}</p>
        </div>
        <div className="reject-booth-modal__actions">
          <button
            type="button"
            style={{
              padding: "4px",
              borderRadius: "6px",
              display: "inline-block",
            }}
            className="admin_add_button btn_zoom  booth-booking__actions-confirm-reject"
            onClick={() => {
              action();
            }}
            disabled={error || isLoading ? true : false}
          >
            {t("booth.reject")}
          </button>
          <button
            style={{
              padding: "4px",
              borderRadius: "6px",
              display: "inline-block",
            }}
            className="admin_add_button btn_zoom  booth-booking__actions-back"
            type="button"
            onClick={() => {
              close();
            }}
          >
            {t("booth.cancel")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RejectBoothModal;
