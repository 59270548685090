import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { fetchProgramAvailable } from "../../../Services/api/program/programContext";
import { ProgramContext } from "../../../Services/api/program/programProvider";
import icon from "../../../assets/image/Icon.png";
import noResult from "../../../assets/image/Noresult.png";
import ProgramCard from "../../Programs/ProgramCard/ProgramCard";
import TrainingCourseSpinnerCard from "../../TrainingCourses/TrainingCoursesLayout/TrainingCourseSpinnerCard/TrainingCourseSpinnerCard";
import "./program.css";

function Programs() {
    require("./program.css");
    const {t} = useTranslation();
    const programContext = useContext(ProgramContext);
    const courseSpinner = [];

    for (let i = 0; i < 3; i++) {
        courseSpinner.push(<TrainingCourseSpinnerCard key={i}/>);
    }

    // useEffect(() => {
    //     fetchProgram()
    //         .then((res) => {
    //             if (res.status && res.status == 200 && res.data.status) {
    //                 programContext.setPrograms(res.data.data.programs);
    //                 programContext.setIsContentLoaded(false);
    //             }
    //         })
    //         .catch((err) => {
    //             
    //             
    //         });
    // }, [])

    useEffect(() => {
        fetchProgramAvailable()
            .then((res) => {
                if (res.status && res.status == 200 && res.data.status) {
                    programContext.setPrograms(res.data.data.programs);
                    programContext.setIsContentLoaded(false);
                }
            })
            .catch((err) => {
                
                
            });
    }, [])

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="program">
                        <div className="head_box">
                            <div className="head_lable">{t("program")}</div>
                            <NavLink to="/all-program" className="text-dark">
                                <div className="head_view">
                                    <div>{t("ViewAll")}</div>
                                    <div className="arrow_seeall">
                                        <img src={icon} alt="" className="arrow_icon"/>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="card_grid">
                            {programContext.isContentLoaded ? (
                                courseSpinner
                            ) : programContext.programs && programContext.programs.length == 0 ? (
                                <span
                                    className="course-details"
                                    style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    marginBottom: "50px"
                                    }}
                                >
                                    <img src={noResult} alt="" />
                                    <h5>{t("no_record")}</h5>
                                </span>
                            ) : (
                                programContext.programs && programContext.programs.slice(0, 6).map((program) => (
                                    <>
                                        <ProgramCard key={program.id} programInfo={program}/>
                                    </>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Programs;
