import { useTranslation } from "react-i18next";
import FilterBox from "../../Shared/Components/FilterBox/FilterBox";

import { useEffect, useState } from "react";
const DetialedReportFilter = ({
  filterData,
  changeHandler,
  resetHandler,
  currentPage,
  setCurrentPage,
  filterHandler,
  resetFilterHandler,
  isAll,
}) => {
  const { t, i18n } = useTranslation();
  const [countries, setCountries] = useState([]);
  const includeAchivementPerOptions = [
    {
      id: 1,
      value: 1,
      label: t("yes"),
    },
    {
      id: 2,
      value: 0,
      label: t("no"),
    },
  ];

  const includeViewPerOptions = [
    {
      label: t("yes"),
      id: 1,
      value: 1,
    },
    {
      label: t("no"),
      id: 2,
      value: 0,
    },
  ];

  const viewsOptions = [
    {
      label: t("top_views"),
      id: 1,
      value: 1,
    },
    {
      label: t("buttom_views"),
      id: 2,
      value: 0,
    },
  ];

  const filterFields = [
    {
      id: "name",
      component: "input",
      label: t("financial.tax.name"),
      type: "text",
      placeholder: t("financial.tax.name"),
      name: "name",
      value: filterData.name,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "type",
      component: "input",
      label: t("type"),
      type: "text",
      placeholder: t("type"),
      name: "type",
      value: filterData.type,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "views_sort",
      label: t("views_sort"),
      component: "select",
      placeholder: t("views_sort"),
      initialValue: t("views_sort"),
      name: "views_sort",
      value: filterData.views_sort,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: viewsOptions || [],
    },
    {
      id: "include_view_per",
      label: t("include_view_per"),
      component: "select",
      placeholder: t("include_view_per"),
      initialValue: t("include_view_per"),
      name: "include_view_per",
      value: filterData.include_view_per,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: includeViewPerOptions || [],
    },

    {
      id: "include_achivement_per",
      label: t("include_achivement_per"),
      component: "select",
      placeholder: t("include_achivement_per"),
      initialValue: t("include_achivement_per"),
      name: "include_achivement_per",
      value: filterData.include_achivement_per,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: includeAchivementPerOptions || [],
    },
    ...(isAll
      ? [
          {
            id: "main_chapter",
            component: "input",
            label: t("main_chapter"),
            type: "text",
            placeholder: t("main_chapter"),
            name: "main_chapter",
            value: filterData.main_chapter,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            onReset: resetHandler,
          },
          {
            id: "subChapter",
            component: "input",
            label: t("subChapter"),
            type: "text",
            placeholder: t("subChapter"),
            name: "subChapter",
            value: filterData.subChapter,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            onReset: resetHandler,
          },
          {
            id: "lesson",
            component: "input",
            label: t("lesson"),
            type: "text",
            placeholder: t("lesson"),
            name: "lesson",
            value: filterData.lesson,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            onReset: resetHandler,
          },
        ]
      : []),

    {
      id: "reset",
      component: "button",
      type: "button",
      name: "action",
      value: t("general.reset"),
      classes: "col-12 col-md-6 col-xl-2 border-button",
      action: () => {
        resetFilterHandler();
      },
      backgroundColor: "transparent",
      color: "#046c77",
      borderColor: "#046c77",
    },
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("general.search"),
      classes: "col-12 col-md-6  col-xl-2",
      action: () => {
        if (currentPage != 1) {
          setCurrentPage(1);
        } else {
          filterHandler();
        }
      },
    },
  ];

  return <FilterBox fields={filterFields} />;
};

export default DetialedReportFilter;
