import React from "react";
import TrainersCCHeader from "./trainersCCHeader/trainersCCHeader";
import Footer from "../Shared/Components/footer/footer";
import {useTranslation} from "react-i18next";
import CurrentCourses from "./currentCouses/currentCouses";
import CompletedCourses from "./completedCourses/completedCourses";
import AddOverview from "./addOverview/addOverview";

function TrainersCreateCourses() {
  const { t } = useTranslation();

  return (
    <div>
      <TrainersCCHeader />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 profiles">
            <ul className="nav nav-tabs profile ">
              <li className="active links pri_profile">
                <a data-toggle="tab" href="#personalInfo">
                  {t("privatepro")}
                </a>
              </li>
              <li className="links pri_profile margin_tabs">
                <a data-toggle="tab" href="#accountDe">
                  {t("overview")}
                </a>
              </li>
              <li className="links pri_profile ">
                <a data-toggle="tab" href="#accountCr">
                  {t("statsandfigures")}
                </a>
              </li>
            </ul>
            <div className="profile_click_here">{t("clickhere")}</div>

            <div className="tab-content">
              <div id="personalInfo" className="tab-pane active">
                <div className="card-body">
                  <>
                    <CurrentCourses />
                    <CompletedCourses />
                  </>
                </div>
              </div>
              <div id="accountDe" className="tab-pane fade">
                <div className="card-body">
                  <>
                    <AddOverview />
                  </>
                </div>
              </div>
              <div id="accountCr" className="tab-pane fade">
                <div className="card-body">
                  <>
                    <div className="card-body"></div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default TrainersCreateCourses;
