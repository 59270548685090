import classes from "./ToggleWithLabels.module.css";
import { useTranslation } from "react-i18next";

const ToggleWithLabels = ({ action, status = 1 }) => {
  const { t } = useTranslation();

  return (
    <button
      type="button"
      onClick={action}
      className={`tw-relative tw-w-24 tw-py-2 tw-px-1 tw-rounded-full tw-flex tw-items-center tw-justify-between ${
        status == "1"
          ? "tw-bg-teal-800 tw-text-white tw-pr-7"
          : "tw-bg-gray-200 !tw-text-gray-700 tw-pl-7"
      } transition-all`}
    >
      <div
        className={`tw-text-xs tw-white-space-nowrap tw-grow tw-text-center ${classes["mb-4"]}`}
      >
        {status == "1" ? t("Exhibition.active") : t("Exhibition.inactive")}
      </div>
      <div
        className={`tw-absolute tw-top-1/2 -tw-translate-y-1/2 ${
          status == "0" ? "tw-left-2" : "tw-right-2"
        } tw-h-5 tw-w-5 tw-bg-white tw-shadow tw-rounded-full transition-transform`}
      ></div>
    </button>
  );
};

export default ToggleWithLabels;
