import { systemUsersListConstant } from "../../Constants/systemUsers/systemUsersList";

const initialState = {
  systemUsersList: [],
  systemUsersListMeta: {},
  systemRolesList: [],
  systemGenders: [],
  systemCountries: [],
  // systemMedicalFields: [],
  systemSpecialties: [],
  systemUsersListLoading: false,
  systemUsersListError: null,
  systemUsersListSuccess: false,

  updateSystemUserStatusLoading: false,
  updateSystemUserStatusError: null,
  updateSystemUserStatusSuccess: false,

  addUsersByExcelLoading: false,
  addUsersByExcelError: null,
  addUsersByExcelSuccess: false,
};

const systemUsersListReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET SYSTEM USERS LIST
    case systemUsersListConstant.GET_SYSTEM_USERS_LIST_REQUEST:
      return {
        ...state,
        systemUsersListLoading: true,
      };
    case systemUsersListConstant.GET_SYSTEM_USERS_LIST_SUCCESS:
      return {
        ...state,
        systemUsersListLoading: false,
        systemUsersListSuccess: true,
        systemUsersList: action.payload.users.data,
        systemUsersListMeta: action.payload.users.meta,
        systemRolesList: action.payload.roles,
        systemGenders: action.payload.genders,
        systemCountries: action.payload.countries,
        // systemMedicalFields: action.payload.medicalFields,
        systemSpecialties: action.payload.specialties,
      };
    case systemUsersListConstant.GET_SYSTEM_USERS_LIST_REJECTED:
      return {
        ...state,
        systemUsersListLoading: false,
        systemUsersListError: action.payload,
        systemUsersListSuccess: false,
      };

    // UPDATE SYSTEM USER STATUS
    case systemUsersListConstant.UPDATE_SYSTEM_USER_STATUS_REQUEST:
      return {
        ...state,
        updateSystemUserStatusLoading: true,
      };
    case systemUsersListConstant.UPDATE_SYSTEM_USER_STATUS_SUCCESS:
      return {
        ...state,
        updateSystemUserStatusLoading: false,
        updateSystemUserStatusSuccess: true,
        updateSystemUserStatusError: null,
        systemUsersList: state.systemUsersList.map((systemUser) => {
          if (systemUser.id === action.payload.id) {
            return {
              ...systemUser,
              status: !systemUser.status,
            };
          }
          return systemUser;
        }),
      };
    case systemUsersListConstant.UPDATE_SYSTEM_USER_STATUS_REJECTED:
      return {
        ...state,
        updateSystemUserStatusLoading: false,
        updateSystemUserStatusError: action.payload,
        updateSystemUserStatusSuccess: false,
      };

    // ADD USERS BY EXCEL
    case systemUsersListConstant.ADD_USERS_BY_EXCEL_REQUEST:
      return {
        ...state,
        addUsersByExcelLoading: true,
      };

    case systemUsersListConstant.ADD_USERS_BY_EXCEL_SUCCESS:
      return {
        ...state,
        addUsersByExcelLoading: false,
        addUsersByExcelSuccess: true,
        addUsersByExcelError: null,
      };

    case systemUsersListConstant.ADD_USERS_BY_EXCEL_REJECTED:
      return {
        ...state,
        addUsersByExcelLoading: false,
        addUsersByExcelError: action.payload,
        addUsersByExcelSuccess: false,
      };

    default:
      return state;
  }
};

export default systemUsersListReducer;
