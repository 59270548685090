import React from "react";

function Hint(props) {
  return (
    <p
      style={{
        color: props.color,
        fontSize: props.fontSize,
        fontWeight: props.fontWeight,
      }}
    >
      {props.hideAsterisk == true ? "" : "*"}
      {props.hint}
    </p>
  );
}

export default Hint;
