import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Lang } from '../../../../utils';
import FilterBox from '../../../Shared/Components/FilterBox/FilterBox';
import classes from './departmentsList.module.css';

const DepartmentsListFilter = ({filterData,changeHandler,resetHandler,resetFilterHandler,currentPage,setCurrentPage,filterHandler}) => {
  const { t, i18n } = useTranslation();
	const { specialtiesList } = useSelector((state) => state.specialtiesListReducer);
  const filterFields = [
		{
			id: "name",
			component: "input",
			label: t("department_list.name"),
			type: "text",
			placeholder: t("department_list.name"),
			name: "name",
			value: filterData.name,
			classes: "col-12 col-md-6 col-xl-4",
			onchange: changeHandler,
			onReset: resetHandler,
		},
		{
			id: "description",
			component: "input",
			label: t("department_list.description"),
			type: "text",
			placeholder: t("department_list.description"),
			name: "description",
			value: filterData.description,
			classes: "col-12 col-md-6 col-xl-4",
			onchange: changeHandler,
			onReset: resetHandler,
		},
		{
			id: "specialty",
			label: t("courses_list.specialty"),
			component: "select",
			placeholder: t("courses_list.specialty"),
			initialValue: t("courses_list.specialty"),
			name: "specialty",
			value: filterData.specialty,
			classes: "col-12 col-md-6 col-xl-4",
			onchange: changeHandler,
			options: specialtiesList?.length
				? specialtiesList?.map((trainer) => ({
						id: trainer.id,
						value: trainer.id,
						label: i18n.language === Lang.AR ? trainer.name_ar : trainer.name_en,
				  }))
				: [],
		},

		{
			id: "created_at",
			label: t("general.created_at"),
			component: "datePicker",
			placeholder: t("general.created_at"),
			name: "created_at",
			value: filterData.created_at,
			classes: "col-12 col-md-6 col-xl-4",
			onchange: changeHandler,
		},
	

		{
			id: "action",
			component: "button",
			type: "button",
			name: "action",
			value: t("general.reset"),
			classes: "col-12 col-md-6 col-xl-2 border-button",
			action: () => {
				resetFilterHandler();
			},
			backgroundColor: "transparent",
			color: "#046c77",
			borderColor: "#046c77",
		},
		{
			id: "action",
			component: "button",
			type: "button",
			name: "action",
			value: t("general.search"),
			classes: "col-12 col-md-6  col-xl-2",
			action: () => {
				if (currentPage != 1) {
					setCurrentPage(1);
				} else {
					filterHandler();
				}
			},
		},
	];
  return (
    <FilterBox fields={filterFields} />

  )
}

export default DepartmentsListFilter