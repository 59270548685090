import {useTranslation} from "react-i18next";
import React from "react";
import noResult from "../../assets/image/Noresult.png";

function TraineeLessonAnalysis(props) {
  require("./TraineeLessonAnalysis.css");
  const { t } = useTranslation();

  return (
    <>
      <div
        className={"tajah-basic-modal-md"}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <div className={"tajah-basic-modal-header"}>
          <h4>{props.title}</h4>
          <div>
            <i
              className="fas fa-times tajah-modal-dismiss"
              onClick={() => {
                props.modalRef.current.dismissModal();
              }}
            ></i>
          </div>
        </div>

        <div className={"tajah-basic-modal-body"}>
          <div
            className="table-responsive"
            style={{
              width: "90%",
            }}
          >
            {props.data.length == 0 ? (
              <span
                className="course-details"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginBottom: "50px"
                }}
              >
                <img src={noResult} alt="" />
                <h5>{t("no_record")}</h5>
              </span>
            ) : (
              <table className="table table-bordered">
                <thead>
                  <tr style={{ textAlign: "center" }}>
                    <th scope="col">#</th>
                    <th scope="col">{t("title")}</th>
                    <th scope="col">{t("is_completed")}</th>
                  </tr>
                </thead>
                <tbody>
                  {props.data.map((d, index) => (
                    <tr style={{ textAlign: "center" }} key={index}>
                      <td scope="row">{index + 1}</td>
                      <td>{d.title}</td>
                      <td>{d.is_completed ? t("true") : t("false")}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default TraineeLessonAnalysis;
