import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import {
  addActivityComment,
  deleteActivityComment,
  deleteChapterComment,
  fetchActivityCommentsList,
  fetchChapterCommentsList,
  likeActivityComment,
  likeChapterComment,
  unlikeActivityComment,
  unlikeChapterComment,
  updateIsBannedActivityComment,
  updateIsBannedChapterComment,
  updateIsPublishedActivityComment,
  updateIsPublishedChapterComment,
} from "../../../../Redux/Actions/activityComment/activityComment.action";
import toLocalTimezone from "../../../../Services/Timezone";
import { reteActivityComment } from "../../../../Services/api/courses/courseProvider";
import ChatActive from "../../../../assets/icons/chatActive.png";
import CommentActive from "../../../../assets/icons/commentActive.png";
import { ReactComponent as DocumentIcon } from "../../../../assets/icons/document.svg";
import { ReactComponent as DownloadIcon } from "../../../../assets/icons/download.svg";
import Like from "../../../../assets/icons/like.png";
import LikeActive from "../../../../assets/icons/likeActive.png";
import PrivateReplayIcon from "../../../../assets/image/encryption.png";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import DropDown from "../../../Shared/Components/dorpdown/Dropdown";
import ActivityRepliesBox from "../NewLesson/Content/Activities/List/ActivityRepliesBox";
import CommentForm from "../NewLesson/Content/Activities/List/CommentForm";
import CommentRating from "../NewLesson/Content/Activities/List/CommentRating";
import classes from "./discBoard.module.css";

export default function DiscBoardCommentBox({
  item,
  isAdmin,
  isTrainer,
  isManager,
  role,
  discussionId,
  isClosed,
  isDisabled,
  timeStatus,
  type,
  sortable,
}) {
  const [activeButton, setActiveButton] = useState(0);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { courseId, id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState(null);

  const { unlikeActivityCommentLoading, likeActivityCommentLoading } = useSelector(
    (state) => state.activityCommentsListReducer
  );

  const [width, setWidth] = useState(0);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWidth]);

  const addReplayToComment = (payload) => {
    dispatch(addActivityComment(payload, getCommentsListHandler));
    // getCommentsListHandler();
  };

  const getCommentsListHandler = () => {
    type === "lesson"
      ? dispatch(
          fetchActivityCommentsList({
            id: id,
            courseId: courseId,
            order: sortable ? sortable.value : null,
            // page: currentPage,
            // perPage: 10,
            // filter,
          })
        )
      : dispatch(
          fetchChapterCommentsList({
            id: id,
            courseId: courseId,
            order: sortable ? sortable.value : null,
            // page: currentPage,
            // perPage: 10,
            // filter,
          })
        );
  };
  const downloadActivityFile = (url, name) => {
    setIsLoading(true);
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = name;
        link.click();
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDeleteComment = () => {
    dispatch(
      type === "lesson"
        ? deleteActivityComment(item.id)
        : deleteChapterComment(item.id, null, null, getCommentsListHandler)
    );
  };
  const handleLikeComment = () => {
    if (!likeActivityCommentLoading) {
      dispatch(type === "lesson" ? likeActivityComment(item.id) : likeChapterComment(item.id, getCommentsListHandler));
    }
  };
  const handleUnlikeComment = () => {
    if (!unlikeActivityCommentLoading) {
      dispatch(
        type === "lesson" ? unlikeActivityComment(item.id) : unlikeChapterComment(item.id, getCommentsListHandler)
      );
    }
  };

  const handlePublishComment = () => {
    dispatch(
      type === "lesson"
        ? updateIsPublishedActivityComment(item.id)
        : updateIsPublishedChapterComment(item.id, null, null, getCommentsListHandler)
    );
  };
  const handleBanComment = () => {
    dispatch(
      type === "lesson"
        ? updateIsBannedActivityComment(item.id)
        : updateIsBannedChapterComment(item.id, null, null, getCommentsListHandler)
    );
  };
  const imgextensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg", "tiff", "webp"];
  const commentDropdownOptions = [
    ...(!isTrainer
      ? [
          {
            label: t("discussionBoard.delete_comment"),
            onClick: () => handleDeleteComment(),
          },
        ]
      : []),
    {
      label: t("discussionBoard.ban_comment"),
      onClick: () => handleBanComment(),
    },
    {
      label: t("discussionBoard.publish_comment"),
      onClick: () => handlePublishComment(),
    },
  ];

  return (
    <div className="tw-border tw-border-gray-200 tw-rounded tw-py-4 tw-space-y-4 border">
      {isLoading && <SkeletonCardOverlay />}
      <div className="tw-inline-flex tw-items-center tw-justify-between tw-w-full tw-px-4">
        <div className="tw-inline-flex tw-items-center tw-justify-center tw-space-s-4">
          <div
            className="tw-bg-white-200 tw-mx-auto tw-rounded-full tw-h-12 tw-aspect-square tw-bg-cover tw-bg-no-repeat tw-bg-center tw-shadow-lg"
            style={{
              backgroundImage: `url(${item.user.image})`,
            }}
          ></div>
          <div className="tw-py-2">
            <div className=" tw-text-center tw-text-start" style={{ color: "#24b3b9" }}>
              {i18n.language === "ar" ? item.user.name_ar : item.user.name_en}
              <br />
              <span style={{ fontSize: "14px" }} className="tw-text-gray-400">
                {item.user.email}
              </span>
            </div>
            <div className="tw-text-sm tw-text-gray-400 tw-text-center tw-text-start">
              {`${moment(toLocalTimezone(item?.created_at)).local(true).format("DD/MM/YYYY")} ${t("the_hour")}
                    ${moment(toLocalTimezone(item?.created_at)).local(true).format("hh:mm A")}`}
            </div>
          </div>
        </div>
        {/* <div
          className="tw-cursor-pointer tw-w-6 tw-h-6"
        >
          <img src={DeleteIcon} alt="" className="tw-w-full tw-h-full" />
        </div> */}
        {(isAdmin || isTrainer || isManager) && (
          <div className={classes.Status}>
            {item.is_published === true ? (
              <div className={classes["booth-status-container"]}>
                <div className={`${classes["booth-status-box"]} ${classes["booth-status-box-published"]}`}>
                  {t("published")}
                </div>
              </div>
            ) : item.is_banned === true ? (
              <div className={classes["booth-status-container"]}>
                <div className={`${classes["booth-status-box"]} ${classes["booth-status-box-banned"]}`}>
                  {t("banned")}
                </div>
              </div>
            ) : (
              <div className={classes["booth-status-container"]}>
                <div className={`${classes["booth-status-box"]} ${classes["booth-status-box-waiting"]}`}>
                  {t("waiting_publish")}
                </div>
              </div>
            )}
            <DropDown options={commentDropdownOptions} />
          </div>
        )}
      </div>
      <div
        className=" tw-text-sm tw-px-4 ticket_view-replies-body "
        style={{ wordBreak: "break-word" }}
        dangerouslySetInnerHTML={{
          __html: item.comment,
        }}
      />
      <div className="tw-px-4">
        {Object.keys(item.attachment ?? {}).length ? (
          imgextensions.includes(item.attachment.name.split(".").slice(-1)[0]) ? (
            <>
              <div
                className="tw-flex tw-flex-col  tw-items-center tw-bg-gray-100 tw-rounded tw-p-2"
                style={{ width: "25%" }}
              >
                <div className="tw-flex tw-items-center tw-space-s-2">
                  <div className="tw-p-2.5  tw-bg-gray-200">
                    <img src={item.attachment.url} alt="" className="" style={{ height: 120, width: 120 }} />
                  </div>
                </div>
                <div className="tw-flex tw-justify-center">
                  <div>{item.attachment.name}</div>
                  <button
                    type="button"
                    onClick={() => {
                      downloadActivityFile(item.id, item.attachment.name);
                    }}
                  >
                    <DownloadIcon className="tw-h-4 tw-w-4 tw-mx-2 tw-stroke-teal-600" />
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="tw-inline-flex tw-items-center tw-space-s-8  tw-bg-gray-100 tw-rounded tw-p-2">
                <div className="tw-flex tw-items-center tw-space-s-2">
                  <div className="tw-p-2.5 tw-rounded-full tw-bg-gray-200">
                    <DocumentIcon className="tw-h-4 tw-w-4" />
                  </div>

                  <div>{item.attachment.name}</div>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      downloadActivityFile(item.id, item.attachment.name);
                    }}
                  >
                    <DownloadIcon className="tw-h-4 tw-w-4 tw-mx-2 tw-stroke-teal-600" />
                  </button>
                </div>
              </div>
            </>
          )
        ) : (
          <></>
        )}
      </div>

      {isDisabled || timeStatus === "Finished" ? (
        <div className={classes["replays_container_disable"]}>
          <div className={classes["replays_button"]}>
            <button
              style={{ display: "flex" }}
              onClick={() => {
                if (activeButton === 2) {
                  setActiveButton(null);
                } else {
                  setActiveButton(2);
                }
                setUserId(null);
              }}
            >
              <div className={classes["comment_buttons"]}>
                <img src={ChatActive} alt="" className={classes["button_img"]} />
                <span> {t("replaies")}</span>
                <span>{`(${item.replies.length})`}</span>
              </div>
            </button>
          </div>
          <div className={classes["replays_button"]}>
            <CommentRating rating={item.rate} disabled={true} />
          </div>
        </div>
      ) : (
        <>
          <div className={classes["replays_container"]}>
            <div className={classes["replays_button"]}>
              {item.is_liked ? (
                <button
                  style={{ display: "flex" }}
                  onClick={() => handleUnlikeComment()}
                  disabled={
                    role === "partner" || isDisabled || timeStatus === "Finished" || isClosed
                      ? true
                      : false || unlikeActivityCommentLoading
                  }
                >
                  <div className={classes["comment_buttons"]}>
                    <span>{item.likes}</span>
                    <img src={LikeActive} alt="" className={classes["button_img"]} />
                    <span>{t("like")}</span>
                  </div>
                </button>
              ) : (
                <button
                  style={{ display: "flex" }}
                  onClick={() => handleLikeComment()}
                  disabled={
                    role === "partner" || isDisabled || timeStatus === "Finished" || isClosed
                      ? true
                      : false || likeActivityCommentLoading
                  }
                >
                  <div className={classes["comment_buttons"]}>
                    <span>{item.likes}</span>
                    <img src={Like} alt="" className={classes["button_img"]} />
                    <span>{t("like")}</span>
                  </div>
                </button>
              )}
            </div>
            <div className={classes["button_split_div"]} />
            <div className={classes["replays_button"]}>
              <button
                style={{ display: "flex" }}
                onClick={() => {
                  setActiveButton(1);
                  setUserId(null);
                }}
                disabled={role === "partner" || isDisabled || timeStatus === "Finished" || isClosed ? true : false}
              >
                <div className={classes["comment_buttons"]}>
                  <img src={CommentActive} alt="" className={classes["button_img"]} />
                  <span>{t("add_replay")}</span>
                </div>
              </button>
            </div>
            {(role === "trainer" || role === "manager" || role === "admin") && (
              <>
                <div className={classes["button_split_div"]} />
                <div className={classes["replays_button"]}>
                  <button
                    style={{ display: "flex" }}
                    onClick={() => {
                      setActiveButton(4);
                      setUserId(item.user.id);
                    }}
                    disabled={role === "partner" || isDisabled || timeStatus === "Finished" || isClosed ? true : false}
                  >
                    <div className={classes["comment_buttons"]}>
                      <img src={PrivateReplayIcon} alt="" className={classes["button_img"]} />
                      <span>&nbsp; {t("add_replay_private")}</span>
                    </div>
                  </button>
                </div>
              </>
            )}
            <div className={classes["button_split_div"]} />
            <div className={classes["replays_button"]}>
              <button
                style={{ display: "flex" }}
                onClick={() => {
                  if (activeButton === 2) {
                    setActiveButton(null);
                  } else {
                    setActiveButton(2);
                  }
                  setUserId(null);
                }}
              >
                <div className={classes["comment_buttons"]}>
                  <img src={ChatActive} alt="" className={classes["button_img"]} />
                  <span> {t("replaies")}</span>
                  <span>{`(${item.replies.length})`}</span>
                </div>
              </button>
            </div>
            <div className={classes["button_split_div"]} />
            {width > 735 && (
              <div className={classes["replays_button"]}>
                <CommentRating
                  rating={item.rate}
                  disabled={["admin", "manager", "trainer", "partner"].includes(role)}
                  onChange={
                    ["admin", "manager", "trainer", "partner"].includes(role)
                      ? null
                      : async (stars, resetStars) => {
                          try {
                            const res = await reteActivityComment({
                              rateable_id: item.id,
                              stars: stars,
                            });
                            getCommentsListHandler();
                          } catch (err) {
                            toast.error(<span style={{ fontSize: 15 }}>{err.response?.data?.msg}</span>);
                            resetStars();
                          }
                        }
                  }
                />
              </div>
            )}
          </div>
          {width < 735 && (
            <div
              className={classes["replays_button"]}
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginTop: "15px",
              }}
            >
              <CommentRating
                rating={item.rate}
                disabled={["admin", "manager", "trainer", "partner"].includes(role)}
                onChange={
                  ["admin", "manager", "trainer", "partner"].includes(role)
                    ? null
                    : async (stars, resetStars) => {
                        try {
                          const res = await reteActivityComment({
                            rateable_id: item.id,
                            stars: stars,
                          });
                          getCommentsListHandler();
                        } catch (err) {
                          toast.error(<span style={{ fontSize: 15 }}>{err.response?.data?.msg}</span>);
                          resetStars();
                        }
                      }
                }
              />
            </div>
          )}
        </>
      )}

      {activeButton === 1 && (
        <CommentForm
          handleClose={() => setActiveButton(0)}
          addCommentHandler={addReplayToComment}
          commentId={item.id}
          discussionId={discussionId}
          userId={userId}
        />
      )}
      {activeButton === 4 && (
        <CommentForm
          handleClose={() => setActiveButton(0)}
          addCommentHandler={addReplayToComment}
          commentId={item.id}
          discussionId={discussionId}
          userId={userId}
        />
      )}
      {activeButton === 2 &&
        item.replies.length > 0 &&
        item.replies.map((replay) =>
          isAdmin || isManager || isTrainer ? (
            <ActivityRepliesBox
              isChapter={type !== "lesson" ? true : false}
              item={replay}
              id={item.id}
              isAdmin={isAdmin}
              isTrainer={isTrainer}
              isManager={isManager}
              commentId={replay.id}
              discussionId={discussionId}
              isDisabled={isDisabled}
              timeStatus={timeStatus}
              isClosed={isClosed}
              reqId={id}
              sortable={sortable}
              courseId={courseId}
              type={type}
            />
          ) : replay.is_published ? (
            <ActivityRepliesBox
              isChapter={type !== "lesson" ? true : false}
              item={replay}
              id={item.id}
              commentId={replay.id}
              isAdmin={isAdmin}
              isTrainer={isTrainer}
              isManager={isManager}
              discussionId={discussionId}
              isDisabled={isDisabled}
              timeStatus={timeStatus}
              isClosed={isClosed}
              reqId={id}
              sortable={sortable}
              courseId={courseId}
              type={type}
            />
          ) : (
            <></>
          )
        )}
    </div>
  );
}
