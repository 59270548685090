import { taxsListConstants } from "../../Constants/Tax/taxList.constants";

const initialState = {
  taxsList: [],
  vatTax: {},
  vatTaxLoading: false,
  taxsListMeta: {},
  taxsListLoading: false,
  taxsListError: null,
  taxsListSuccess: false,

  updateTaxStatusLoading: false,
  updateTaxStatusError: null,
  updateTaxStatusSuccess: false,

  deleteTaxLoading: false,
  deleteTaxError: null,
  deleteTaxSuccess: false,
};

const taxsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case taxsListConstants.GET_TAXS_LIST_REQUEST:
      return {
        ...state,
        taxsListLoading: true,
      };
    case taxsListConstants.GET_TAXS_LIST_SUCCESS:
      return {
        ...state,
        taxsListLoading: false,
        taxsListSuccess: true,
        taxsList: action.payload.taxs.data,
        taxsListMeta: action.payload.taxs.meta,
      };
    case taxsListConstants.GET_TAXS_LIST_REJECTED:
      return {
        ...state,
        taxsListLoading: false,
        taxsListError: action.payload,
        taxsListSuccess: false,
      };

    // GET_VAT_TAX_REQUEST
    case taxsListConstants.GET_VAT_TAX_REQUEST:
      return {
        ...state,
        vatTaxLoading: true,
      };
    case taxsListConstants.GET_VAT_TAX_SUCCESS:
      return {
        ...state,
        vatTaxLoading: false,
        vatTax: action.payload.vatTax,
      };
    case taxsListConstants.GET_VAT_TAX_REJECTED:
      return {
        ...state,
        vatTaxLoading: false,
        vatTaxError: action.payload,
      };

    // DELETE TAX
    case taxsListConstants.DELETE_TAX_REQUEST:
      return {
        ...state,
        deleteTaxLoading: true,
      };

    case taxsListConstants.DELETE_TAX_SUCCESS:
      return {
        ...state,
        deleteTaxLoading: false,
        deleteTaxSuccess: true,
        deleteTaxError: null,

        taxsList: state.taxsList.filter((tax) => tax.id !== action.payload.id),
      };

    case taxsListConstants.DELETE_TAX_REJECTED:
      return {
        ...state,
        deleteTaxLoading: false,
        deleteTaxError: action.payload,
        deleteTaxSuccess: false,
      };

    // UPDATE TAX STATUS
    case taxsListConstants.UPDATE_TAX_STATUS_REQUEST:
      return {
        ...state,
        updateTaxStatusLoading: true,
      };

    case taxsListConstants.UPDATE_TAX_STATUS_SUCCESS:
      return {
        ...state,
        updateTaxStatusLoading: false,
        updateTaxStatusSuccess: true,
        updateTaxStatusError: null,

        taxsList: state.taxsList.map((tax) => {
          if (tax.id === action.payload.id) {
            return {
              ...tax,
              status: !tax.status,
            };
          }
          return tax;
        }),
      };

    case taxsListConstants.UPDATE_TAX_STATUS_REJECTED:
      return {
        ...state,
        updateTaxStatusLoading: false,
        updateTaxStatusError: action.payload,
        updateTaxStatusSuccess: false,
      };

    default:
      return state;
  }
};

export default taxsListReducer;
