import { ErrorMessage, Field, Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { setQuestionGrade } from "../../../../../Services/api/exams/ExamsProvider";
import { ReactComponent as CloseIcon } from "../../../../../assets/icons/crose.svg";
import { SunEditorText } from "../../../../Shared/Components";

export default function CorrectionModal({ answer, traineeId, examId, onClose, onSuccess, evaluationList = [] }) {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="tw-flex tw-flex-wrap tw-items-center tw-justify-center md:tw-py-8 tw-h-full tw-w-full">
      <div
        className={`tw-bg-white tw-shadow tw-rounded tw-w-full tw-max-h-full tw-divide-y inner-scrollbar`}
        style={{ overflowY: "overlay" }}
      >
        <div className="tw-flex tw-items-center tw-justify-between tw-px-8 tw-py-4">
          <div className="tw-text-xl tw-text-teal-700">{t("answerCriteria.table_title")}</div>
          <button type="button" onClick={onClose}>
            <CloseIcon className="tw-h-6 tw-w-6" />
          </button>
        </div>
        <Formik
          initialValues={{
            feedback: answer?.feedback ?? "",
            standards: answer?.standards ? answer.standards : [],
          }}
          onSubmit={async (values) => {
            let payload = {
              trainee_id: traineeId,
              answer_id: answer.id,
              feedback: values.feedback,
              standards: values.standards.map((i) => i.id),
            };
            await setQuestionGrade(examId, payload);
            onSuccess();
          }}
          validate={(values) => {
            setHasSubmitted(true);
            let errors = {};

            return errors;
          }}
          validateOnChange={hasSubmitted}
        >
          {({
            setFieldValue,
            setValues,
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="tw-px-8 tw-py-3 tw-space-y-4">
                <div className="tw-mb-4 tw-divide-y tw-divide-gray-300">
                  {evaluationList.map((standard) => (
                    <button
                      type="button"
                      onClick={() =>
                        setFieldValue(
                          "standards",
                          values.standards.find((item) => item.id == standard.id)
                            ? values.standards.filter((item) => item.id != standard.id)
                            : [...values.standards, standard]
                        )
                      }
                      className="tw-w-full tw-rounded tw-p-2 tw-flex tw-space-s-2 tw-items-center"
                    >
                      <div className="tw-w-3 tw-h-3 tw-border tw-border-gray-300 tw-rounded tw-flex tw-items-center tw-justify-center tw-shrink-0">
                        {values.standards.find((item) => item.id == standard.id) ? (
                          <div className="tw-w-2 tw-h-2 tw-rounded-sm tw-bg-teal-400"></div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="tw-grow tw-flex tw-items-center tw-justify-between">
                        <div className="tw-text-gray-400">{standard.title}</div>
                        <div className="tw-text-teal-500 tw-text-sm">{standard.weight}%</div>
                      </div>
                    </button>
                  ))}
                </div>
                {/* feedback field */}
                <div>
                  <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("trainer.quiz.crud.feedback")}</div>
                  <Field
                    as="textarea"
                    name={`feedback`}
                    className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500 tw-whitespace-pre-wrap tw-h-24"
                    placeholder={t("trainer.quiz.crud.feedback")}
                  />
                  <ErrorMessage name={`feedback`} component="div" className="tw-text-xs tw-text-red-700 tw-h-4" />
                </div>
                <div className="tw-flex tw-items-center tw-justify-between tw-space-s-4">
                  <div className="tw-text-teal-500 tw-font-bold">
                    {values.standards.reduce((pre, next) => pre + parseInt(next.weight), 0)}%
                  </div>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="tw-bg-teal-700 tw-py-2 tw-px-16 tw-rounded tw-text-white disabled:tw-bg-teal-700/50"
                  >
                    {t("ok")}
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}
