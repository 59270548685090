import {CourseProvider} from "../../../../Services/api/courses/courseContext";
import GiftCourse from "../GiftCourse/GiftCourse";

function GiftCourseWrapper() {
    return (
        <>
            <CourseProvider>
                <GiftCourse />
            </CourseProvider>
        </>
    )
}

export default GiftCourseWrapper;
