import Rating from "@mui/material/Rating";
import React, { useEffect, useRef, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import IconTooltips from "../../../Components/tooltips/IconsTooltip";
import BoothRatesModal from "../../../Modals/exhibition/BoothRatesModal";
import { getBoothRatings, getExhibitionBooths } from "../../../Services/api/Exhibition/ExhibitionProvider";
import { BasicModal } from "../../Shared/Components/Modal/BasicModal";
import SkeletonCard from "../../Shared/Components/Spinner/SkeletonCard";

export default function BoothsList() {
  require("./exhibition.css");
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const boothRateModalRef = useRef();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [booths, setBooths] = useState([]);
  const [exhibitionName, setExhibitionName] = useState([]);
  const [boothId, setBoothId] = useState("");
  const [ratings, setRatings] = useState([]);

  useEffect(() => {
    listBooths(id);
  }, []);

  const listBooths = async (id) => {
    try {
      let res = await getExhibitionBooths(id);
      if (res.status == 200) {
        setIsLoading(false);
        res.data.statistics.forEach((statistic) => {
          if (parseInt(statistic.id) == id) {
            setExhibitionName(statistic.name);
            setBooths(statistic.booths);
          }
        });
      }
    } catch (err) {
      
    }
  };

  const getRates = async (id) => {
    try {
      let res = await getBoothRatings(id);
      if (res.status == 201) {
        setIsLoading(false);
        setRatings(res.data.ratings);
      }
    } catch (err) {
      
    }
  };

  const showBoothRates = (id) => {
    getRates(id);
    setBoothId(id);
    boothRateModalRef.current.showModal();
  };

  return (
    <div>
      <BasicModal ref={boothRateModalRef}>
        <BoothRatesModal
          label={t("booth.rates")}
          title={t("booth.rates")}
          body={ratings}
          boothId={boothId}
          boothRateModalRef={boothRateModalRef}
          showBoothRates={showBoothRates}
        />
      </BasicModal>
      <div className="container-fluid profile_wrapper">
        <div className="admin_label">
          <p style={{ padding: "0 5px" }}>/</p>
          <NavLink to="/">{t("Home")}</NavLink>
          <p style={{ padding: "0 5px" }}>/</p>
          <NavLink to="/admin/exhibitions-list">{t("Exhibition.Exhibition")}</NavLink>
          <p style={{ padding: "0 5px" }}>/</p>
          {exhibitionName}
        </div>
      </div>
      <div className="container-fluid profile_wrapper">
        <div className={isLoading ? null : "table_bank_tran"}>
          <h3 className="table_header">{t("booth.booth")}</h3>
          {isLoading ? (
            <div
              style={{
                height: "65vh",
                width: "100%",
              }}
            >
              <SkeletonCard />
            </div>
          ) : (
            <div className="admin_input_sponsor">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">{t("booth.name")}</th>
                    <th scope="col">{t("booth.sponser")}</th>
                    <th scope="col">{t("booth.Description")}</th>
                    <th scope="col">{t("booth.visitCount")}</th>
                    <th scope="col">{t("booth.rate")}</th>
                    <th scope="col">{t("booth.size")}</th>
                    <th scope="col">{t("booth.logo")}</th>
                    <th scope="col">{t("booth.rates")}</th>
                  </tr>
                </thead>
                <tbody style={{ overflowWrap: "anywhere" }}>
                  {booths.map((item, index) => {
                    return (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{item.name}</td>
                        <td>{item.sponser}</td>
                        <td style={{ width: "30%" }}>
                          <div
                            style={{
                              width: "100%",
                              height: "200px",
                              margin: 0,
                              padding: 0,
                              overflow: "auto",
                            }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.description,
                              }}
                            ></div>
                          </div>
                        </td>
                        <td>{item.visits_count}</td>
                        <td>
                          <Rating name="simple-controlled" value={item.ratings_count} readOnly />
                        </td>
                        <td>{item.size}</td>
                        <td>
                          <img src={`${item.logo}`} alt="" className="trainer-img" />
                        </td>
                        <td>
                          <button
                            style={{
                              backgroundColor: "white",
                              borderWidth: 0,
                            }}
                          >
                            <IconTooltips
                              title={t("Exhibition.statistics")}
                              content={
                                <i
                                  style={{
                                    fontSize: 20,
                                    color: "black",
                                  }}
                                  className="fal fa-poll"
                                  onClick={() => showBoothRates(item.id)}
                                ></i>
                              }
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
