import React from "react";
import Select from "react-select";
import "./selectWithSearch.css";
import classes from "./selectWithSearch.module.css";
const SelectWithSearch = ({
  label,
  labelColor,
  subLabel,
  name,
  onchange,
  options,
  initialValue,
  error,
  required,
  value,
  disabled = false,
  noLabel,
  placeholder,
}) => {
  return (
    <div className={classes["select-field"]}>
      {noLabel ? (
        <></>
      ) : (
        <label htmlFor={name}>
          <span style={{ color: labelColor ? labelColor : "black" }}>
            {label}
          </span>
          {subLabel && <span style={{ color: "#777" }}> {subLabel}</span>}
          {required && <span style={{ color: "red" }}>*</span>}
        </label>
      )}

      <Select
        name={name}
        onChange={onchange}
        value={value}
        isDisabled={disabled}
        className={classes["select"]}
        options={options}
        defaultValue={initialValue}
        placeholder={placeholder}
      />
      {
        <p
          className={`form-input-error-space ${classes["select-field-error"]}`}
        >
          {error && error}
        </p>
      }
    </div>
  );
};

export default SelectWithSearch;
