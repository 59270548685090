import React from "react";
import CreateExhibition from "./CreateExhibition/CreateExhibition";
import { createExhibition } from "../../../Services/api/Exhibition/ExhibitionProvider";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";

const CreateExhibitionPage = ({ role }) => {
  const history = useHistory();
  const { courseId, lessonId } = useParams();
  const submitHandler = async (formData) => {
    try {
      const response = await createExhibition(formData);
      if (response.status === 201 && response.data.status) {
        if (courseId && lessonId) {
          history.push(`/${role}/course/${courseId}/lesson/${lessonId}`);
        }
        {
          history.push(`/${role}/exhibitions-list`);
        }

        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {response.data.msg ? response.data.msg : "success"}
          </span>,
          {
            autoClose: 1000,
          }
        );
      } else {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {response.data.msg ? response.data.msg : "Failure in service"}
          </span>
        );
      }
    } catch (err) {
      toast.error(
        <>
          <span
            style={{
              fontSize: 13,
              fontWeight: "bold",
              display: "block",
              paddingBottom: "10px",
            }}
          >
            {err.response.data.msg}
          </span>
          <ul>
            {Object.keys(err.response.data.errors).map((key) => {
              return (
                <li>
                  <span style={{ fontSize: 11 }}>
                    {err.response.data.errors[key]}
                  </span>
                </li>
              );
            })}
          </ul>
        </>
      );
    }
  };

  return (
    <CreateExhibition
      lessonId={lessonId}
      submitHandler={submitHandler}
      type="create"
    />
  );
};

export default CreateExhibitionPage;
