import React, { useState } from "react";
import RemoveRedIcon from "../../../../assets/icons/remove-red.svg";
import classes from "./tags.module.css";

const Tags = ({ label, name, placeholder, setFieldValue, value, error, required, disabled }) => {
  const [tags, setTags] = useState(value?.length ? value : []);

  const handleAdd = (e) => {
    if (e.target.value === "" || tags.includes(e.target.value)) return;
    setTags([...tags, e.target.value]);
    setFieldValue(name, [...tags, e.target.value]);
    e.target.value = "";
  };

  const handleRemove = (index) => {
    const newTags = [...tags];
    newTags.splice(index, 1);
    setTags(newTags);
    setFieldValue(name, newTags);
  };

  return (
    <div className={classes["tags"]}>
      <label htmlFor={name} className={classes["tags-label"]}>
        {label}
        {required && <span style={{ color: "red" }}>*</span>}
      </label>
      <div className={classes["tags__container__content"]}>
        <div className={classes["tags__container"]}>
          <input
            type="text"
            name={name}
            id={name}
            placeholder={placeholder}
            onBlur={(e) => {
              handleAdd(e);
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleAdd(e);
              }
            }}
            disabled={disabled}
          />
        </div>
        {tags && !!tags.length && (
          <div className={classes["tags__previews"]}>
            {tags?.map((tag, index) => (
              <div className={classes["tags__previews__item"]} key={index}>
                <p className={classes["tags__previews__item__name"]}>{tag}</p>
                {!disabled && (
                  <div
                    className={classes["tags__previews__item-remove"]}
                    onClick={() => {
                      handleRemove(index);
                    }}
                  >
                    <img src={RemoveRedIcon} alt="" />
                  </div>
                )}
              </div>
            ))}
          </div>
        )}

        {error && (
          <p className={`form-input-error-space ${classes["input-file-error"]}`} style={{ paddingTop: "10px " }}>
            {error}
          </p>
        )}
      </div>
    </div>
  );
};

export default Tags;
