import { exhibitionConstant } from "../../Constants/exhibition/exhibition.constant";

const initialState = {
  sponsorsLoading: false,
  sponsorsError: null,
  sponsors: [],
  visitorsLoading: false,
  visitorsError: null,
  visitors: [],
  visitorsMeta: null,
  boothsLoading: false,
  boothsError: null,
  booths: [],
  exhibitionLoading: false,
  exhibitionError: null,
  exhibition: {},
  exhibitionTaxs: {},
  refundSettings: null,
  exhibitionDashboard: {},
  boothsMeta: null,
  visitLoading: false,
  visit: {},
  visitToCart: {},
  payAddBoothResponse: {},
  error: null,
  phoneCode: "",

  payExhibitionVisiterResponse: {},
  payExhibitionVisiterError: null,
  payExhibitionVisiterLoading: false,

  payExhibitionBoothResponse: {},
  payExhibitionBoothError: null,
  payExhibitionBoothLoading: false,

  approveVisitorLoading: false,
  approveVisitorError: null,
  approveVisitorMsg: null,
  approveVisitor: false,

  rejectVisitorLoading: false,
  rejectVisitorError: null,
  rejectVisitorMsg: null,
  rejectVisitor: false,

  rateExhibitionError: null,
};

const exhibitionReducer = (state = initialState, action) => {
  switch (action.type) {
    case exhibitionConstant.GET_PHONE_CODE:
      return {
        ...state,
        phoneCode: action.payload,
      };
    case exhibitionConstant.GET_EXHIBITION_VISIT_REQUEST:
      return {
        ...state,
        visitLoading: true,
      };
    case exhibitionConstant.GET_EXHIBITION_VISIT_SUCCESS:
      return {
        ...state,
        visitLoading: true,
        visit: action.payload,
      };
    case exhibitionConstant.GET_EXHIBITION_VISIT_TO_CART_REQUEST:
      return {
        ...state,
        visitLoading: true,
      };
    case exhibitionConstant.GET_EXHIBITION_VISIT_TO_CART_SUCCESS:
      return {
        ...state,
        visitLoading: true,
        visitToCart: action.payload,
      };

    case exhibitionConstant.PAY_ADD_BOOTH_WITH_BANK_TRANSFER_REQUEST:
      return {
        ...state,
        visitLoading: true,
      };

    case exhibitionConstant.PAY_ADD_BOOTH_WITH_BANK_TRANSFER_SUCCESS:
      return {
        ...state,
        visitLoading: false,
        payAddBoothResponse: action.payload,
      };

    case exhibitionConstant.PAY_ADD_BOOTH_WITH_BANK_TRANSFER_REJECTED:
      return {
        ...state,
        visitLoading: false,
        error: action.payload,
      };

    // payExhibitionVisiter
    case exhibitionConstant.PAY_EXHIBITION_VISITER_REQUEST:
      return {
        ...state,
        payExhibitionVisiterLoading: true,
      };

    case exhibitionConstant.PAY_EXHIBITION_VISITER_SUCCESS:
      return {
        ...state,
        payExhibitionVisiterLoading: false,
        payExhibitionVisiterResponse: action.payload,
      };

    case exhibitionConstant.PAY_EXHIBITION_VISITER_REJECTED:
      return {
        ...state,
        payExhibitionVisiterLoading: false,
        error: action.payload,
      };

    // payExhibitionBooth
    case exhibitionConstant.PAY_EXHIBITION_BOOTH_REQUEST:
      return {
        ...state,
        payExhibitionBoothLoading: true,
      };

    case exhibitionConstant.PAY_EXHIBITION_BOOTH_SUCCESS:
      return {
        ...state,
        payExhibitionBoothLoading: false,
        payExhibitionBoothResponse: action.payload,
      };

    case exhibitionConstant.PAY_EXHIBITION_BOOTH_REJECTED:
      return {
        ...state,
        payExhibitionBoothLoading: false,
        error: action.payload,
      };

    // get exhibition Sponsors
    case exhibitionConstant.GET_EXHIBITION_SPONSORS_REQUEST:
      return {
        ...state,
        sponsorsLoading: true,
      };
    case exhibitionConstant.GET_EXHIBITION_SPONSORS_SUCCESS:
      return {
        ...state,
        sponsorsLoading: false,
        sponsors: action.payload,
      };

    case exhibitionConstant.GET_EXHIBITION_SPONSORS_REJECTED:
      return {
        ...state,
        sponsorsLoading: false,
        sponsorsError: action.payload,
      };

    // get exhibition Booths
    case exhibitionConstant.GET_EXHIBITION_BOOTHS_LIST_REQUEST:
      return {
        ...state,
        boothsLoading: true,
      };
    case exhibitionConstant.GET_EXHIBITION_BOOTHS_LIST_SUCCESS:
      return {
        ...state,
        boothsLoading: false,
        booths: action.payload.data,
        boothsMeta: action.payload.meta,
      };

    case exhibitionConstant.GET_EXHIBITION_BOOTHS_LIST_REJECTED:
      return {
        ...state,
        boothsLoading: false,
        boothsError: action.payload,
      };

    // get exhibition Visitors
    case exhibitionConstant.GET_EXHIBITION_VISITORS_REQUEST:
      return {
        ...state,
        visitorsLoading: true,
      };
    case exhibitionConstant.GET_EXHIBITION_VISITORS_SUCCESS:
      return {
        ...state,
        visitorsLoading: false,
        visitors: action.payload.data,
        visitorsMeta: action.payload.meta,
      };

    case exhibitionConstant.GET_EXHIBITION_VISITORS_REJECTED:
      return {
        ...state,
        visitorsLoading: false,
        visitorsError: action.payload,
      };

    // update booth status
    case exhibitionConstant.UPDATE_BOOTH_STATUS_REQUEST:
      return {
        ...state,
      };
    case exhibitionConstant.UPDATE_BOOTH_STATUS_SUCCESS:
      return {
        ...state,
        booths: state.booths.map((booth) => {
          if (booth.id === action.payload.id) {
            booth.status = !booth.status;
          }
          return booth;
        }),
      };

    // GET exhibition by id
    case exhibitionConstant.GET_EXHIBITION_BY_ID_REQUEST:
      return {
        ...state,
        exhibitionLoading: true,
      };

    case exhibitionConstant.GET_EXHIBITION_BY_ID_SUCCESS:
      return {
        ...state,
        exhibitionLoading: false,
        exhibition: action.payload.exhibition,
        exhibitionTaxs: action.payload.taxes,
        refundSettings:
          action.payload.exhibition.refund_settings.length > 0
            ? action.payload.exhibition.refund_settings.find(
                (item) => item.refundable_type === "exhibition_visitor"
              )
            : null,
        exhibitionDashboard: action.payload.dashboard,
      };

    case exhibitionConstant.GET_EXHIBITION_BY_ID_REJECTED:
      return {
        ...state,
        exhibitionLoading: false,
        exhibitionError: action.payload,
      };

    case exhibitionConstant.UPDATE_BOOTH_STATUS_REJECTED:
      return {
        ...state,
        boothsError: action.payload,
      };

    // approve exhibition by visitor
    case exhibitionConstant.APPROVE_EXHIBITION_BY_VISITOR_REQUEST:
      return {
        ...state,
        approveVisitorLoading: true,
        approveVisitor: false,
      };
    case exhibitionConstant.APPROVE_EXHIBITION_BY_VISITOR_SUCCESS:
      return {
        ...state,
        approveVisitorLoading: false,
        approveVisitorMsg: action.payload.msg,
        approveVisitor: true,
        visitors: state.visitors.filter(
          (visitor) => visitor.id !== action.payload.id
        ),
      };

    case exhibitionConstant.APPROVE_EXHIBITION_BY_VISITOR_REJECTED:
      return {
        ...state,
        approveVisitorLoading: false,
        approveVisitorError: action.payload,
      };

    // reject exhibition by visitor
    case exhibitionConstant.REJECT_EXHIBITION_BY_VISITOR_REQUEST:
      return {
        ...state,
        rejectVisitorLoading: true,
        rejectVisitor: false,
      };
    case exhibitionConstant.REJECT_EXHIBITION_BY_VISITOR_SUCCESS:
      return {
        ...state,
        rejectVisitorLoading: false,
        rejectVisitorMsg: action.payload.msg,
        rejectVisitor: true,
        visitors: state.visitors.filter(
          (visitor) => visitor.id !== action.payload.id
        ),
      };

    case exhibitionConstant.REJECT_EXHIBITION_BY_VISITOR_REJECTED:
      return {
        ...state,
        rejectVisitorLoading: false,
        rejectVisitorError: action.payload,
      };

    case exhibitionConstant.RATE_EXHIBITION_SUCCESS:
      return {
        ...state,
        rateExhibitionError: false,
        exhibition: {
          ...state.exhibition,
          rate_avg: action.payload.exhibition.rate_avg,
          ratings_count: action.payload.exhibition.ratings_count,
        },
      };

    case exhibitionConstant.RATE_EXHIBITION_REJECTED:
      return {
        ...state,
        rateExhibitionError: true,
      };

    case exhibitionConstant.UPDATE_EXHIBITION_USER_AFTER_PAYMENT_REQUEST:
      return {
        ...state,
        visitors: state.visitors.filter(
          (visitor) => visitor.id !== action.payload.id
        ),
      };

    default:
      return state;
  }
};

export default exhibitionReducer;
