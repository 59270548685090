import React from "react";
import { Box, CircularProgress } from "@material-ui/core";

const ContentLoadingSpinner = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress color="white" size={25} />
    </Box>
  );
};

export default ContentLoadingSpinner;
