import env from "./Environment";
import connector from "./Handler";
import URLBuilder from "./UrlBuilder";
export function exportCourseAnalysis(
  export_type,
  courseId,
  secondCourseId = null
) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(
          URLBuilder(
            secondCourseId
              ? env.get_two_course_analysis
              : env.get_course_analysis,
            { courseId, secondCourseId }
          ),
          {
            responseType: "blob",
            params: {
              export: export_type,
            },
          }
        )
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              "Course Analysis" + "." + export_type
            ); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function exportQuizAnalysis(id, quizId, export_type) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(
          URLBuilder(env.get_quiz_analysis, { courseId: id, quizId: quizId }),
          {
            responseType: "blob",
            params: {
              export: export_type,
            },
          }
        )
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Quizs analysis" + "." + export_type); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function exportTraineeAnalysis(id, traineeId, export_type) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(
          URLBuilder(
            traineeId ? env.get_trainee_analysis_by_id : env.get_trainee_analysis,
            {
              courseId: id,
              traineeId: traineeId,
            }
          ),
          {
            responseType: "blob",
            params: {
              export: export_type,
            },
          }
        )
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              "trainee analysis" + "." + export_type
            ); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
