import React, { useState } from "react";

export const AuthContext = React.createContext();

export function AuthProvider(props) {
  const [auth, setAuth] = useState({ token: localStorage.getItem("token") || null });
  const [isPerformingAuthenticationOperation, setIsPerformingAuthenticationOperation] = useState(false);
  const [inAdminPanel, setInAdminPanel] = useState(false);
  const [roles, setRoles] = useState(JSON.parse(localStorage.getItem("roles")) || []);

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        roles,
        setRoles,
        inAdminPanel,
        setInAdminPanel,
        isPerformingAuthenticationOperation,
        setIsPerformingAuthenticationOperation,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}
