import connector from "../../../Services/api/Handler";
import URLBuilder from "../../../Services/api/UrlBuilder";
import { faqConstant } from "../../Constants/faq/faq.constant";
import env from "../../../Services/api/Environment";
import { toast } from "react-toastify";

export function getFaqGeneralList() {
  return (dispatch) => {
    dispatch({
      type: faqConstant.START_LOADING,
    });
    connector
      .get(env.faq_general_categories_list)
      .then((res) => {
        dispatch({
          type: faqConstant.GET_FAQ_GENERAL_CATEGORIES_LIST,
          payload: { response: res, data: res.data.data },
        });
        dispatch({
          type: faqConstant.STOP_LOADING,
        });
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.message}
          </span>
        );
        dispatch({
          type: faqConstant.STOP_LOADING,
        });
      });
  };
}

export function getFaqCategoryListWithoutLoading() {
  return (dispatch) => {
    connector
      .get(env.faq_category)
      .then((res) => {
        dispatch({
          type: faqConstant.GET_FAQ_CATEGORY_LIST,
          payload: { response: res, data: res.data.data },
        });
        dispatch({
          type: faqConstant.STOP_LOADING,
        });
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.message}
          </span>
        );
      });
  };
}

export function getFaqCategoryList() {
  return (dispatch) => {
    dispatch({
      type: faqConstant.START_LOADING,
    });

    connector
      .get(env.faq_category)
      .then((res) => {
        dispatch({
          type: faqConstant.GET_FAQ_CATEGORY_LIST,
          payload: { response: res, data: res.data.data },
        });
        dispatch({
          type: faqConstant.STOP_LOADING,
        });
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.message}
          </span>
        );
        dispatch({
          type: faqConstant.STOP_LOADING,
        });
      });
  };
}

export function deleteFaqCategory(id) {
  return (dispatch) => {
    dispatch({
      type: faqConstant.START_LOADING,
    });

    connector
      .delete(URLBuilder(env.faq_category_id, { id: id }))
      .then((res) => {
        dispatch({
          type: faqConstant.GET_FAQ_CATEGORY_DELETE,
          payload: res,
        });
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {res.data.msg ? res.data.msg : "success"}
          </span>,
          {
            onClose: () => {},
            autoClose: 1000,
          }
        );
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.message || err.response.data.msg}
          </span>
        );
        dispatch({
          type: faqConstant.STOP_LOADING,
        });
      });
    dispatch({
      type: faqConstant.STOP_LOADING,
    });
  };
}

export const addFaqCategory = ({ title_en, title_ar }) => {
  return async (dispatch) => {
    dispatch({
      type: faqConstant.START_LOADING,
    });

    await connector
      .post(env.faq_category, {
        title_en: title_en,
        title_ar: title_ar,
      })
      .then((res) => {
        dispatch({
          type: faqConstant.GET_FAQ_CATEGORY_ADD,
          payload: res,
        });
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {res.data.msg ? res.data.msg : "success"}
          </span>,
          {
            onClose: () => {},
            autoClose: 1000,
          }
        );
      })
      .catch((err) => {
        toast.error(
          <>
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {err.response.data.message || err.response.data.msg}
            </span>
            {err.response.data.errors && (
              <ul>
                {Object.keys(err.response.data.errors).map((key) => {
                  return (
                    <li>
                      <span style={{ fontSize: 11 }}>
                        {err.response.data.errors[key]}
                      </span>
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        );
        dispatch({
          type: faqConstant.STOP_LOADING,
        });
      })
      .finally(() =>
        dispatch({
          type: faqConstant.STOP_LOADING,
        })
      );
  };
};

export const changeStatusFaqCategory = (id) => {
  return async (dispatch) => {
    await connector
      .post(URLBuilder(env.faq_category_change_status, { id: id }))
      .then((res) => {
        dispatch({
          type: faqConstant.GET_FAQ_CATEGORY_STATUS_CHANGE,
          payload: res,
        });
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {res.data.msg ? res.data.msg : "success"}
          </span>,
          {
            onClose: () => {},
            autoClose: 1000,
          }
        );
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.message || err.response.data.msg}
          </span>
        );
        dispatch({
          type: faqConstant.STOP_LOADING,
        });
      });
  };
};

export function editFaqCategory(id, payload) {
  return (dispatch) => {
    dispatch({
      type: faqConstant.START_LOADING,
    });

    connector
      .put(URLBuilder(env.faq_category_id, { id: id }), payload, {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        dispatch({
          type: faqConstant.GET_FAQ_CATEGORY_EDIT,
          payload: res,
        });
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {res.data.msg ? res.data.msg : "success"}
          </span>,
          {
            onClose: () => {},
            autoClose: 1000,
          }
        );
      })
      .catch((err) => {
        toast.error(
          <>
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {err.response.data.message || err.response.data.msg}
            </span>
            {err.response.data.errors && (
              <ul>
                {Object.keys(err.response.data.errors).map((key) => {
                  return (
                    <li>
                      <span style={{ fontSize: 11 }}>
                        {err.response.data.errors[key]}
                      </span>
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        );
        dispatch({
          type: faqConstant.STOP_LOADING,
        });
      });
    dispatch({
      type: faqConstant.STOP_LOADING,
    });
  };
}

export function showFaqCategory(id) {
  return (dispatch) => {
    connector
      .get(URLBuilder(env.faq_category_id, { id: id }))
      .then((res) => {
        dispatch({
          type: faqConstant.GET_FAQ_CATEGORY_SHOW,
          payload: res,
        });
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.message || err.response.data.msg}
          </span>
        );
        dispatch({
          type: faqConstant.STOP_LOADING,
        });
      });
  };
}

export const reOrderFaqCategory = (id, to) => {
  return async (dispatch) => {
    await connector
      .post(URLBuilder(env.faq_category_re_order, { id: id }), {
        order: to,
      })
      .then((res) => {
        dispatch({
          type: faqConstant.GET_FAQ_CATEGORY_RE_ORDER,
          payload: res,
        });
      });
  };
};

// QUESTIONS
export const addFaqQuestions = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: faqConstant.START_LOADING,
    });

    await connector
      .post(env.faq_question, payload)
      .then((res) => {
        dispatch({
          type: faqConstant.GET_FAQ_QUESTION_ADD,
          payload: res,
        });
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {res.data.msg ? res.data.msg : "success"}
          </span>,
          {
            onClose: () => {},
            autoClose: 1000,
          }
        );
      })
      .catch((err) => {
        toast.error(
          <>
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {err.response.data.message || err.response.data.msg}
            </span>
            {err.response.data.errors && (
              <ul>
                {Object.keys(err.response.data.errors).map((key) => {
                  return (
                    <li>
                      <span style={{ fontSize: 11 }}>
                        {err.response.data.errors[key]}
                      </span>
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        );
        dispatch({
          type: faqConstant.STOP_LOADING,
        });
      });
    dispatch({
      type: faqConstant.STOP_LOADING,
    });
  };
};

export function deleteFaqQuestion(id) {
  return (dispatch) => {
    dispatch({
      type: faqConstant.START_LOADING,
    });

    connector
      .delete(URLBuilder(env.faq_question_id, { id: id }))
      .then((res) => {
        dispatch({
          type: faqConstant.GET_FAQ_QUESTION_DELETE,
          payload: res,
        });
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {res.data.msg ? res.data.msg : "success"}
          </span>,
          {
            onClose: () => {},
            autoClose: 1000,
          }
        );
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.message || err.response.data.msg}
          </span>
        );
        dispatch({
          type: faqConstant.STOP_LOADING,
        });
      });
    dispatch({
      type: faqConstant.STOP_LOADING,
    });
  };
}

export const changeStatusFaqQuestion = (id) => {
  return async (dispatch) => {
    await connector
      .post(URLBuilder(env.faq_question_change_status, { id: id }))
      .then((res) => {
        dispatch({
          type: faqConstant.GET_FAQ_QUESTION_STATUS_CHANGE,
          payload: res,
        });
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {res.data.msg ? res.data.msg : "success"}
          </span>,
          {
            onClose: () => {},
            autoClose: 1000,
          }
        );
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.message || err.response.data.msg}
          </span>
        );
      });
  };
};

export function editFaqQuestion(id, payload) {
  return (dispatch) => {
    dispatch({
      type: faqConstant.START_LOADING,
    });

    connector
      .put(URLBuilder(env.faq_question_id, { id: id }), payload, {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        dispatch({
          type: faqConstant.GET_FAQ_QUESTION_EDIT,
          payload: res,
        });
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {res.data.msg ? res.data.msg : "success"}
          </span>,
          {
            onClose: () => {},
            autoClose: 1000,
          }
        );
      })
      .catch((err) => {
        toast.error(
          <>
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {err.response.data.message || err.response.data.msg}
            </span>
            {err.response.data.errors && (
              <ul>
                {Object.keys(err.response.data.errors).map((key) => {
                  return (
                    <li>
                      <span style={{ fontSize: 11 }}>
                        {err.response.data.errors[key]}
                      </span>
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        );
        dispatch({
          type: faqConstant.STOP_LOADING,
        });
      });
    dispatch({
      type: faqConstant.STOP_LOADING,
    });
  };
}

export function showFaqQuestion(id) {
  return (dispatch) => {
    connector
      .get(URLBuilder(env.faq_question_id, { id: id }))
      .then((res) => {
        dispatch({
          type: faqConstant.GET_FAQ_QUESTION_SHOW,
          payload: res,
        });
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.message || err.response.data.msg}
          </span>
        );
        dispatch({
          type: faqConstant.STOP_LOADING,
        });
      });
  };
}

export const reOrderFaqQuestion = (id, to) => {
  return async (dispatch) => {
    await connector
      .post(URLBuilder(env.faq_question_re_order, { id: id }), {
        order: to,
      })
      .then((res) => {
        dispatch({
          type: faqConstant.GET_FAQ_QUESTION_RE_ORDER,
          payload: res,
        });
      });
  };
};
