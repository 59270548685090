import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {CourseContext} from "../../../../Services/api/courses/courseContext";

function CourseDetailes(props) {
    require("./CourseDetailes.css")
    const { t } = useTranslation();
    const courseDetailsContext = useContext(CourseContext);

    return (
        <div className="course-detailes">
            <div className="select-label">{t("giftCourse.label.selected_course")}</div>
            <div className="course">
                <img src={courseDetailsContext.courseDetails?.img} alt="" />
                <div className="course-name">{courseDetailsContext.courseDetails?.name}</div>
            </div>
            <div className="course-price">
                <span>
                    {t("giftCourse.label.overall")} : {" "}
                </span>
                {t("giftCourse.label.price")} : {courseDetailsContext.courseDetails?.price} {t("Rial")}
            </div>
            <div className="price-hint">
                {t("giftCourse.label.price_hint")}
            </div>
        </div>
    );
}

export default CourseDetailes;
