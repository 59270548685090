import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import { CourseContext } from "../../../../Services/api/courses/courseContext";
import { addToCart, freeCourseRegister, } from "../../../../Services/api/courses/courseProvider";

function TrainingCourseSubscriptionButton(props) {
  require("./TrainingCourseSubscriptionButton.css");
  const courseDetailsContext = useContext(CourseContext);
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();

  const handleRegistration = async (event) => {
    event.preventDefault();

    try {
      let addToCartResponse = await addToCart(id);
      if (addToCartResponse.status === 201 && addToCartResponse.data.status) {
        await freeCourseRegister(addToCartResponse.data.cart.id,'free');
        history.push("/training-courses/" + id + "/profile");
      }
    } catch (error) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("modal.trainer.course.messages.title")}
        </span>
      );
      
    }
  };
  return (
    <>
      {courseDetailsContext.courseDetails.mode == "Free" ? (
        <div className="training_btn training-details">
          <button
            className="training_inside_btn"
            type="button"
            onClick={handleRegistration}
          >
            {t("Startthecoursenow")}
          </button>
        </div>
      ) : (
        <div className="training_btn training-details">
          {courseDetailsContext.purchaseStatus == "notpaid" || courseDetailsContext.purchaseStatus == "canceled" || courseDetailsContext.purchaseStatus == "refund" ? (
          <button
          className="training_inside_btn"
          type="button"
          onClick={props.modalHandler}
        >
          {t("PayToStartTheCourse")}
        </button>
          ): courseDetailsContext.purchaseStatus == "waiting" ? (
            <button
            className="training_inside_btn"
            type="button"
            onClick={props.modalHandler}
            disabled={courseDetailsContext.purchaseStatus}
          >
            {"تم الدفع بانتظار الموافقة علي الحوالة"}
          </button>
          ): (
            <button
            className="training_inside_btn"
            type="button"
            onClick={()=>history.push("/training-courses/" + id + "/profile")}
          >
            {t("Startthecoursenow")}
          </button>
          )}
        </div>
      )}
    </>
  );
}

export default TrainingCourseSubscriptionButton;
