import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import { TrainingCourseProfileContext } from "../../../../../Services/api/toutorials/TrainingCourseProfileContext";

import SurveysModal from "../../../../../Modals/surveyModal/SurveysModal";
import { getSurveysForLessonOrCourse } from "../../../../../Services/api/survey/SurveyProvider";
import { BasicModal } from "../../../../Shared/Components/Modal/BasicModal";
import { IconsTooltip } from "./../../../../../Components";

function TrainingCourseSyllabusContentRowLesson(props) {
    require("./TrainingCourseSyllabusContentRowLesson.css");
    const { id } = useParams();
    const { t } = useTranslation();
    const surveysModalRef = useRef();
    const trainingCourseProfileContext = useContext(TrainingCourseProfileContext);
    const [opened, setOpened] = useState(false);
    const [lessonId, setLessonId] = useState("");
    const [survies, setSurvies] = useState([]);

    const history = useHistory();
    useEffect(() => {
        for (let i = 0; i < props.subChapter.lessons.length; i++) {
            props.subChapter.lessons[i].number = i + 1;
        }
    }, [props.subChapter.lessons]);


    const getSurveysForLesson=(lessonId)=>{
        setSurvies([]);
        getSurveysForLessonOrCourse(null,lessonId)
            .then((res) => {
                if (res.status && res.status == 200 && res.data.status) {
                    setSurvies(res.data.surveys);
                }
            })
            .catch((err) => {
                
                
            });
    }

    return (
        <>
            <div className="training-course-syllabus-section-container-row-lesson">
                <BasicModal ref={surveysModalRef}>
                    <SurveysModal
                        label={t("survey.surviesLabel")}
                        title={t("survey.surviesLabel")}
                        body={survies}
                        courseId={id}
                        surveysModalRef={surveysModalRef}
                        accept={()=>surveysModalRef.current.dismissModal()}
                    />
                </BasicModal>
                <div
                    className={
                        "training-course-syllabus-section-container-row-lesson-header " +
                        (opened ? "opened" : "closed")
                    }
                    onClick={() => {
                        setOpened(!opened);
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    >
            <span style={{ fontSize: 16 }}>
              {t("lecture")} {props.subChapter.number} :
            </span>
                        <span style={{ fontSize: 16, padding: "0px 5px 0px 5px" }}>
              {props.subChapter.name}
            </span>
                    </div>

                    <i
                        className={
                            "fas rotate-icon training-course-syllabus-section-container-row-lesson-icon " +
                            (opened
                                ? "fa-angle-down"
                                : document.body.dir == "ltr"
                                    ? "fa-angle-right"
                                    : "fa-angle-left")
                        }
                    ></i>
                </div>
                {props.subChapter.lessons.map((item, index) => {
                    return (
                        <div
                            key={index}
                            className={
                                "training-course-syllabus-section-container-row-lesson-content " +
                                (opened ? "opened" : "closed")
                            }
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}
                            >
                <span style={{ fontSize: 14 }}>
                  {t("lesson")} {item.number} :
                </span>
                                <span style={{ fontSize: 14, padding: "0px 5px 0px 5px" }}>
                  {item.title}
                </span>
                            </div>

                            <div className="training-course-syllabus-section-container-row-lesson-icon">
                                <IconsTooltip
                                    content={
                                        <i
                                            className={`fas rotate-icon fa-check-circle training-course-lesson-icons ${
                                                item.progress.progress > 0
                                                    ? "course-check-mark-circle"
                                                    : "course-check-mark-circle-default"
                                            }`}
                                        />
                                    }
                                    title={
                                        item.progress.progress > 0
                                            ? t("trainer.course.lesson.iconCheck")
                                            : t("trainer.course.lesson.iconNotCheck")
                                    }
                                />
                                {/* <IconsTooltip
                                    content={
                                        <i
                                            className={
                                                "fas rotate-icon fa-users-class training-course-lesson-icons course-users-group"
                                            }
                                        />
                                    }
                                    title={t("trainer.course.lesson.iconDiscussion")}
                                /> */}
                                <IconsTooltip
                                    content={
                                        <i
                                            className={`fas rotate-icon fa-play-circle training-course-lesson-icons ${
                                                item.restricted == false
                                                    ? "course-play-content"
                                                    : item.restricted == true
                                                        ? "course-play-content-default"
                                                        : "course-play-content"
                                            }`}
                                            onClick={(e) => {
                                                if (item.restricted == false) {
                                                    history.push({
                                                        pathname: `/training-courses/${id}/lesson/${item.id}`,
                                                        state: {
                                                            lessonTitle: item.title,
                                                            subChapter: props.subChapter.name,
                                                            idCourse: id,
                                                        },
                                                    });
                                                } else if (item.restricted == true) {
                                                    toast.success(
                                                        t("trainer.course.lesson.iconVideoClosed")
                                                    );
                                                } else {
                                                    toast.success(
                                                        t("trainer.course.lesson.iconVideoClosed")
                                                    );
                                                }
                                            }}
                                        />
                                    }
                                    title={
                                        item.restricted == false
                                            ? t("trainer.course.lesson.iconVideo")
                                            : item.restricted == true
                                                ? t("trainer.course.lesson.iconVideoClosed")
                                                : t("trainer.course.lesson.iconVideo")
                                    }
                                />
                                {item.quiz_list.length > 0 ?
                                    <IconsTooltip
                                        content={
                                            <i
                                                className={
                                                    "fas rotate-icon fa-chalkboard training-course-lesson-icons course-users-group"
                                                }
                                                onClick={(e) => {
                                                    history.push({pathname:`/trainees/course/${id}/lesson/${item.id}/exams`,
                                                        state: {
                                                            quizes: item.quiz_list
                                                        },
                                                    });
                                                }}
                                            />
                                        }
                                        title={t("trainer.course.lesson.exams")}
                                    /> : null}
                                <IconsTooltip
                                    content={
                                        <i
                                            className={
                                                "fas rotate-icon fa-poll training-course-lesson-icons course-users-group"
                                            }
                                            onClick={()=>{
                                                setLessonId(item.id)
                                                getSurveysForLesson(item.id)

                                                surveysModalRef.current.showModal();
                                            }}
                                        />
                                    }
                                    title={t("trainer.course.surveyTitle")}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
}

export default TrainingCourseSyllabusContentRowLesson;
