import routesMain from "./RoutesMain";

let HandlePaths = routesMain;
let courses_faq_service = JSON.parse(
  localStorage.getItem("system_preferences") ? localStorage.getItem("system_preferences") : "{}"
).courses_faq_service;
let support_tickets_faq_service = JSON.parse(
  localStorage.getItem("system_preferences") ? localStorage.getItem("system_preferences") : "{}"
).support_tickets_faq_service;

// if(!courses_faq_service){
//     HandlePaths.map( (route, index) => {
//         if(route.path == '/faq/faq-list'){
//             HandlePaths.splice(index,1)
//             return HandlePaths;
//         }
//     })
// }

const trainer_checked = () => {
  HandlePaths.filter((route) => route.path === "/faq/edit/:id").forEach((x) =>
    HandlePaths.splice(HandlePaths.indexOf(x), 1)
  );
  HandlePaths.filter((route) => route.path === "/faq/add/:id").forEach((x) => {
    HandlePaths.splice(HandlePaths.indexOf(x), 1);
    return HandlePaths;
  });
};

if (support_tickets_faq_service) {
  if (JSON.parse(localStorage.getItem("roles")) && JSON.parse(localStorage.getItem("roles"))[0] != "trainer") {
    trainer_checked();
  }
} else {
  trainer_checked();
}

// // restrict routes for admins only
// if(! JSON.parse(localStorage.getItem("is_admin"))){
//     HandlePaths.filter(route => route.path ===  '/livechat').forEach(x => HandlePaths.splice(HandlePaths.indexOf(x), 1));
// }

export default HandlePaths;
