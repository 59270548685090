import React, { useEffect, useRef, useState } from "react";
import { answerLessonActivity } from "../../../../../Services/api/courses/courseProvider";
import { ReactComponent as YoutubeIcon } from "../../../../../assets/icons/youtube.svg";
import { toast } from "react-toastify";

export default function H5pQuestionView({ question, setFieldValue }) {
  const iframeRef = useRef(null);

  function saveRes(event) {
    if (event.origin !== process.env.REACT_APP_SERVER_PATH.replace(/\/$/, "")) {
      return;
    }
    if (event.data.statement?.result) {
      setFieldValue("answer", event.data.statement.result.score.scaled);
    }
  }

  useEffect(() => {
    if (question.media?.path) {
      window.addEventListener("message", saveRes);
      return () => window.removeEventListener("message", saveRes);
    }
  }, []);

  return (
    <>
      {question.media?.path ? (
        <iframe
          ref={iframeRef}
          title={"H5P"}
          id={"iframeH5PQuestion" + question.id}
          src={`${process.env.REACT_APP_SERVER_PATH}h5p/player?path=${question.media?.path}`}
          key={question.id}
          width={"100%"}
          height={"644px"}
          allow="fullscreen"
        ></iframe>
      ) : (
        <button
          type="button"
          disabled={!question.media?.path}
          className="tw-border-2 tw-border-gray-200 tw-bg-gray-50 tw-w-1/2 tw-aspect-square tw-flex tw-items-center tw-justify-center"
        >
          <YoutubeIcon
            className={`tw-w-16 tw-h-16 ${
              question.media?.path ? "tw-stroke-teal-600" : "tw-stroke-gray-400"
            }`}
          />
        </button>
      )}
    </>
  );
}
