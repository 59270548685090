import SearchIcon from "@material-ui/icons/Search";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router";
import { NavLink } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  assignTrainees,
  getTrainees,
  getTraineesReg,
} from "../../../../Services/api/toutorials/assignToCourseProvider";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";

function TraineeList(props) {
  require("./traineeList.css");
  const { t } = useTranslation();
  const { id } = useParams();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [popUp, setPopup] = useState(false);
  const [trainees, setTrainees] = useState([]);
  const [newTrainee, setNewTrainee] = useState([]);
  const [registeredTrainee, setRegisteredTrainee] = useState([]);
  const [meta, setMeta] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);

  const getTraineeApi = () => {
    setIsUpdating(true);
    getTrainees(id)
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setTrainees(res.data.response);
          setLoading(false);
          setIsUpdating(false);
        }
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.msg}</span>);
      });
  };

  const getRegTrainer = (params = null) => {
    setIsUpdating(true);
    getTraineesReg(id, params)
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setRegisteredTrainee(res.data.data.trainees);
          setMeta(res.data.data.meta);
          setLoading(false);
          setIsUpdating(false);
        }
      })
      .catch((err) => {
        
      });
  };

  useEffect(() => {
    getRegTrainer();
  }, []);

  useEffect(() => {
    getTraineeApi();
  }, [popUp]);

  const handlePageClick = (event) => {
    const toBePagination = {};
    toBePagination.page = event.selected + 1;

    getRegTrainer(toBePagination);
  };

  return (
    <>
      {popUp && (
        <>
          <div
            className="backdrop"
            onClick={() => {
              setPopup(false);
            }}
          ></div>
          <div className="container-modal">
            <div className="assign-trainee-modal">
              <div className="tajah-basic-modal-header">
                <div className="header-modal">
                  <div className="">{t("assginCourse.label.assign_course")}</div>
                </div>
                <i
                  className="fas fa-times tajah-modal-dismiss"
                  onClick={() => {
                    setPopup(false);
                  }}
                ></i>
              </div>

              <Formik
                initialValues={{
                  trainees: newTrainee == null ? [] : newTrainee,
                }}
                enableReinitialize={true}
                onSubmit={async (values, { setSubmitting }) => {
                  setSubmitting(true);
                  setPopup(false);
                  setLoading(true);
                  assignTrainees(id, values)
                    .then((res) => {
                      getRegTrainer();
                      setLoading(false);
                      setSubmitting(false);
                    })
                    .catch((err) => {
                      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.data.msg}</span>);
                      setLoading(false);
                      setSubmitting(false);
                    });
                }}
                validateOnChange={hasSubmitted}
                validate={(values) => {
                  setHasSubmitted(true);
                  const errors = {};

                  if (values.trainees.length == 0) {
                    errors.trainees = t("crud.errors.required");
                  }

                  return errors;
                }}
              >
                {({ values, errors, handleSubmit, isSubmitting }) => (
                  <form onSubmit={handleSubmit} className="admin_add_form">
                    <div className="body-modal mt-5">
                      <div className="mb-2">{t("assginCourse.label.assign_trainee")}</div>
                      <div className="">
                        <Select
                          isMulti
                          name="user"
                          options={trainees}
                          getOptionLabel={(trainee) => trainee.name + "      " + "(" + trainee.email + ")"}
                          getOptionValue={(trainee) => trainee.id}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={(trainees) => {
                            if (trainees.length == 0) {
                              setNewTrainee([]);
                            } else {
                              setNewTrainee(trainees.map((trainee) => parseInt(trainee.id)));
                            }
                          }}
                          placeholder={t("assginCourse.placeholders.trainee_placeholders")}
                        />

                        <p className={"form-input-error-space"}>{errors.trainees ? errors.trainees : null}</p>
                      </div>
                    </div>

                    <div className="btn-assign-action mt-3">
                      <button type="submit" className="btn btn-assign" disabled={isSubmitting}>
                        {t("assginCourse.label.release")}
                      </button>
                      <button
                        className="btn mx-4"
                        onClick={() => {
                          setPopup(false);
                        }}
                      >
                        {t("back")}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </>
      )}
      <div className="container-fluid">
        <div className="admin_flex">
          <div className="admin_label">
            <NavLink to="/admin">{t("admin.label.admin_label")}</NavLink>
            <p style={{ padding: "0 5px" }}>/</p>
            {t("assginCourse.label.assign_course")}
          </div>
        </div>
        {isUpdating ? <SkeletonCardOverlay skeletonWidth="100" /> : <div></div>}

        {loading ? (
          <div
            style={{
              height: "65vh",
            }}
          >
            <SkeletonCard />
          </div>
        ) : (
          <div className="admin_card">
            <div className="table_header">
              <div className="">{t("assginCourse.label.assign_course")}</div>
              <div className="search_input">
                <SearchIcon className="icon" />
                <input
                  id="filter"
                  name="filter"
                  type="text"
                  value={filter}
                  placeholder={t("block.placeholders.name")}
                  onChange={(event) => setFilter(event.target.value)}
                />
              </div>
              <div className="">
                <button
                  className="btn btn-assign"
                  onClick={(e) => {
                    e.stopPropagation();
                    setPopup(true);
                  }}
                >
                  <i
                    className="fa fa-plus"
                    style={{
                      margin: "0 5px",
                      fontSize: "16px",
                    }}
                  ></i>
                  {t("assginCourse.label.add")}
                </button>
              </div>
            </div>
            <div
              className="table-responsive"
              style={{
                width: "90%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">{t("assginCourse.label.img")}</th>
                    <th scope="col">{t("assginCourse.label.name")}</th>
                    <th scope="col">{t("assginCourse.label.email")}</th>
                  </tr>
                </thead>
                <tbody>
                  {registeredTrainee
                    .filter((f) => f.name.includes(filter) || filter === "")
                    .map((t, index) => (
                      <tr key={t.id}>
                        <td className="trainer-name">{index + 1}</td>
                        <td>
                          <img src={`${t.image}`} alt="" className="trainer-img" />
                        </td>
                        <td className="trainer-name">{t.name}</td>
                        <td className="trainer-email">{t.email}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <ReactPaginate
                nextLabel={t("next")}
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={meta.totalPages}
                previousLabel={t("previous")}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default TraineeList;
