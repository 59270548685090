import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import IconTooltips from "../../../Components/tooltips/IconsTooltip";
import { getExhibitionBoothsBooking } from "../../../Services/api/Exhibition/ExhibitionProvider";
import SkeletonCard from "../../Shared/Components/Spinner/SkeletonCard";

const BoothsBookingList = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [boothsBookingList, setBoothsBookingList] = useState([]);

  const getBoothsBookingList = async () => {
    try {
      const response = await getExhibitionBoothsBooking(id);
      if (response.status == 200) {
        setIsLoading(false);
        setBoothsBookingList(response.data.booths);
      }
    } catch (error) {
      
    }
  };

  useEffect(() => {
    getBoothsBookingList();
  }, []);

  const handleBoothStatus = (status) => {
    return status == 2 ? t("booth.approved") : status == 1 ? t("booth.canceled") : t("booth.pending");
  };

  return (
    <div className="exhibition-Bookings">
      <div className="container-fluid profile_wrapper">
        <div className="admin_label">
          <p style={{ padding: "0 5px" }}>/</p>
          <NavLink to="/">{t("Home")}</NavLink>
          <p style={{ padding: "0 5px" }}>/</p>
          <NavLink to="/admin/exhibitions-list">{t("Exhibition.Exhibition")}</NavLink>
          <p style={{ padding: "0 5px" }}>/</p>
        </div>
      </div>

      <div className="container-fluid profile_wrapper">
        <div className={isLoading ? null : "table_bank_tran"}>
          <h3 className="table_header">{t("booth.booth")}</h3>
          {isLoading ? (
            <div
              style={{
                height: "65vh",
                width: "100%",
              }}
            >
              <SkeletonCard />
            </div>
          ) : (
            <div className="admin_input_sponsor">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">{t("booth.name")}</th>
                    <th scope="col">{t("booth.sponser")}</th>
                    <th scope="col">{t("booth.size")}</th>
                    <th scope="col">{t("booth.price")}</th>
                    <th scope="col">{t("booth.status")}</th>
                    <th scope="col">{t("booth.details")}</th>
                  </tr>
                </thead>
                <tbody style={{ overflowWrap: "anywhere" }}>
                  {boothsBookingList.map((item, index) => {
                    return (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{item.name}</td>
                        <td>{item.user.name_ar}</td>

                        <td>{item.size}</td>
                        <td>{item.price}</td>
                        <td>{handleBoothStatus(item.status)}</td>
                        <td>
                          <button
                            style={{
                              backgroundColor: "white",
                              borderWidth: 0,
                              margin: "0 auto",
                            }}
                            onClick={() => {
                              history.push(`/admin/exhibition/booths-booking/${item.id}`);
                            }}
                          >
                            <IconTooltips
                              title={t("booth.details")}
                              content={
                                <i
                                  style={{
                                    fontSize: 20,
                                    color: "black",
                                  }}
                                  className="fal fa-table"
                                ></i>
                              }
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BoothsBookingList;
