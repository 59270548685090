import {useTranslation} from "react-i18next";

function MainSection() {
  require("./mainSection.css");
  const { t } = useTranslation();

  return (
    <div className="main_section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="section_wrapper">
              <div className="section_inner">
                <div className="section_header">{t("hellotrainee")}</div>

                <div className="section_desc">{t("traineedesc")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainSection;
