import {useTranslation} from "react-i18next";
import React from "react";
import {useHistory} from "react-router";
import i18n from "./../../i18n/i18n";
import {Lang} from "./../../utils";
import Rating from '@mui/material/Rating';

function BoothRatesModal(props) {
  require("./exhibition.css");
  const { t } = useTranslation();
  const history = useHistory()

    return (
        <>
            <div
                className={"tajah-basic-modal-md"}
                onClick={(event) => {
                    event.stopPropagation();
                }}
            >
                <div className={"tajah-basic-modal-header"}>
                    <h4>{props.title}</h4>
                    <div>
                        <i
                            className="fas fa-times tajah-modal-dismiss"
                            onClick={() => {
                                props.boothRateModalRef.current.dismissModal();
                            }}
                        ></i>
                    </div>
                </div>

                <div className={"tajah-basic-modal-body"}>
                <div className="table-responsive" style={{
                    width: '90%'
                  }}>
                  {props.body.length == 0 ? (
                    <p style={{textAlign:'center'}}>{t('booth.notFoundRates')}</p>
                  ):(
                    <table className="table table-bordered">
                      <thead>
                      <tr style={{textAlign:'center'}}>
                        <th scope="col">#</th>
                        <th scope="col">{t("booth.user")}</th>
                        <th scope="col"> {t("booth.descriptionRate")}</th>
                        <th scope="col"> {t("booth.rate")}</th>
                      </tr>
                      </thead>
                      <tbody>
                      {props.body.map((booth,index) => {
                        return (
                            <tr style={{textAlign:'center'}} key={booth.id}>
                              <td scope="row">{index + 1}</td>
                              <td>{i18n.language == Lang.AR ? booth.user.name_ar : booth.user.name_en}</td>
                              <td>{booth.description}</td>
                              <td>
                              <Rating
                                  name="simple-controlled"
                                  value={booth.stars}
                                  readOnly
                                  />
                              </td>
                            </tr>
                        );
                      })}
                      </tbody>
                    </table>
                  )}
                  </div>
                </div>
            </div>
        </>
    );
}

export default BoothRatesModal;
