import { useTranslation } from "react-i18next";
import HomeIcon from "../../../assets/icons/home.svg";
import classes from "./bankTransfers.module.css";

import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { exportBankTransferList } from "../../../Services/api/BankTransfer";
import { getPayments } from "../../../Services/api/toutorials/PaymentsProvaider";
import { ReactComponent as ReopenIcon } from "../../../assets/icons/reopen.svg";
import Breadcrumb from "../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../Shared/Components/MainBoxHead/MainBoxHead";

import BankTransfersFilter from "./BankTransfersFilter";

import BankTransfersTable from "./BankTransfersTable";

import { getAllCourses } from "../../../Services/api/CoursesList";
import SkeletonCardOverlay from "../../Shared/Components/Spinner/SkeletonCardOverlay";
import { filterActions } from "./BankTransferActions";

const PeymentRequestsCoursesPrograms = ({ isProgram, role }) => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();

  const [showFilter, setShowFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [dashboard, setDashboard] = useState({});
  const [activeTab, setActiveTab] = useState(1);
  const [bankTransfers, setBankTransfers] = useState([]);
  const [bankTransferMeta, setBankTransferMeta] = useState({});
  const [courses, setCourses] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [filterData, setFilterData] = useState({
    payer_name: "",
    payer_email: "",
    payable_type: "",
    role: "",
    payable_name: "",
    payable_serial_number: "",
    payable_code: "",
  });

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "content_development",
      page: t("admin.label.admin_label"),
      pagePath: "#",
    },
    {
      id: "financial_movement",
      page: t("sidebar.label.financial_movement"),
      pagePath: `/${role}/financial-movement`,
    },
    {
      id: `content_development_bank`,
      page: t("bankTransfers"),
      active: true,
    },
  ];

  const showFilterHandler = () => {
    setShowFilter((prev) => !prev);
  };
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
  };

  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };
  const resetFilterHandler = () => {
    setFilterData({
      payer_name: "",
      payer_email: "",
      payable_type: "",
      role: "",
      payable_name: "",
      payable_serial_number: "",
      payable_code: "",
    });
    setIsResetApplied(true);
  };

  const getFilterData = () => {
    const filter = {};
    filterData.payer_name && (filter.payer_name = filterData.payer_name);
    filterData.payer_email && (filter.payer_email = filterData.payer_email);
    filterData.payable_type && (filter.payable_type = filterData.payable_type);
    filterData.role && (filter.role = filterData.role);
    filterData.payable_name && (filter.payable_name = filterData.payable_name);
    filterData.payable_serial_number && (filter.payable_serial_number = filterData.payable_serial_number);
    filterData.payable_code && (filter.payable_code = filterData.payable_code);

    filter.status = activeTab === 1 ? "Waiting" : activeTab === 2 ? "Approved" : activeTab === 3 ? "Rejected" : "";
    filter.payment_method = "Bank";
    return filter;
  };

  const getBankTransfers = () => {
    setIsLoading(true);
    const filter = getFilterData();

    getPayments({ page: currentPage, perPage: 10, filter })
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setBankTransfers(res.data.data.payments);
          setDashboard(res.data.data.dashboard);
          setBankTransferMeta(res.data.data.meta);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.msg}</span>);
        setIsLoading(false);
      });
  };

  const getAllCoursesProgramsHandler = async ({ is_program = false }) => {
    getAllCourses({
      paginated: 0,
      ...(is_program ? { is_program: 1 } : {}),
      ...(!is_program ? { not_in_program: 1, is_program: 0 } : {}),
      mode: "Paid",
      perPage: 1000,
    })
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          if (is_program) {
            setPrograms(res?.data?.data?.courses?.data || []);
          } else {
            setCourses(res?.data?.data?.courses?.data || []);
          }
        }
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.msg}</span>);
      });
  };
  useEffect(() => {
    getAllCoursesProgramsHandler({
      is_program: false,
    });
    getAllCoursesProgramsHandler({
      is_program: true,
    });
  }, []);

  useEffect(() => {
    getBankTransfers();
    if (isResetApplied) {
      setIsResetApplied(false);
    }
  }, [currentPage, isResetApplied, activeTab]);

  const exportHandler = async (type) => {
    setIsExportLoading(true);
    try {
      const filter = getFilterData();
      await exportBankTransferList(filter, type);

      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
    }
  };

  const ApprovedSum = (status, type) => {
    let sum = 0;
    if (type) {
      bankTransfers
        .filter((item) => item.status === status && item.payable_type === type)
        .forEach((item) => (sum += Number(item.paid_amount)));
    } else {
      bankTransfers.filter((item) => item.status === status).forEach((item) => (sum += Number(item.paid_amount)));
    }
    return sum;
  };

  const statistics = [
    ...(activeTab === 1
      ? [
          {
            label: t("financial.bank_transfers.transfer_waiting"),
            value: dashboard.total,
            color: "#046C77",
            icon: (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.86 67.86">
                <path
                  id="check-circle_4_"
                  data-name="check-circle (4)"
                  d="M51.5,25.725a2.825,2.825,0,0,1-.04,4L38.946,42.008a11.251,11.251,0,0,1-15.766.062L17.81,36.792a2.827,2.827,0,0,1,3.964-4.032l5.352,5.262a5.618,5.618,0,0,0,7.863-.051L47.5,25.688a2.825,2.825,0,0,1,4,.037ZM67.86,33.93A33.93,33.93,0,1,1,33.93,0,33.968,33.968,0,0,1,67.86,33.93Zm-5.655,0A28.275,28.275,0,1,0,33.93,62.205,28.307,28.307,0,0,0,62.205,33.93Z"
                  fill="#fff"
                />
              </svg>
            ),
          },
          {
            label: t("financial.bank_transfers.transfer_approved_courses"),
            value: dashboard.course_total,
            color: "#046C77dd",
            icon: (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.86 67.86">
                <path
                  id="add_1_"
                  data-name="add (1)"
                  d="M33.93,0A33.93,33.93,0,1,0,67.86,33.93,33.93,33.93,0,0,0,33.93,0Zm0,62.205A28.275,28.275,0,1,1,62.205,33.93,28.275,28.275,0,0,1,33.93,62.205ZM48.068,33.93a2.828,2.828,0,0,1-2.828,2.828H36.758V45.24a2.828,2.828,0,1,1-5.655,0V36.758H22.62a2.828,2.828,0,0,1,0-5.655H31.1V22.62a2.828,2.828,0,1,1,5.655,0V31.1H45.24A2.828,2.828,0,0,1,48.068,33.93Z"
                  fill="#fff"
                />
              </svg>
            ),
          },
          {
            label: t("financial.bank_transfers.transfer_approved_programs"),
            value: dashboard.program_total,
            color: "#046C77cc",
            icon: (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.857 67.863">
                <path
                  id="user-pen"
                  d="M25.448,33.93A16.965,16.965,0,1,0,8.483,16.965,16.982,16.982,0,0,0,25.448,33.93Zm0-28.275a11.31,11.31,0,1,1-11.31,11.31A11.321,11.321,0,0,1,25.448,5.655ZM30.4,45.862A19.815,19.815,0,0,0,5.655,65.033a2.828,2.828,0,1,1-5.655,0A25.465,25.465,0,0,1,31.81,40.388,2.828,2.828,0,0,1,30.4,45.865ZM65.375,33.588a8.684,8.684,0,0,0-12,0L34.414,52.552a11.242,11.242,0,0,0-3.314,8v4.484a2.829,2.829,0,0,0,2.828,2.828h4.484a11.235,11.235,0,0,0,8-3.314L65.372,45.585a8.485,8.485,0,0,0,0-12Zm-4,8L42.407,60.548a5.607,5.607,0,0,1-4,1.657H36.752V60.548a5.617,5.617,0,0,1,1.657-4L57.376,37.586a2.893,2.893,0,0,1,4,0,2.826,2.826,0,0,1,0,4Z"
                  fill="#fff"
                />
              </svg>
            ),
          },
          {
            label: t("financial.bank_transfers.transfer_approved_exhibition_sponser"),
            value: dashboard.exhibition_booth_total,
            color: "#046C77bb",
            icon: <ReopenIcon className="tw-h-full tw-w-full tw-fill-white" />,
          },
          {
            label: t("financial.bank_transfers.transfer_approved_exhibition_users"),
            value: dashboard.exhibition_visit_total,
            color: "#046C77aa",
            icon: <ReopenIcon className="tw-h-full tw-w-full tw-fill-white" />,
          },
          {
            label: t("financial.bank_transfers.transfer_approved_deposit"),
            value: dashboard.wallet_total,
            color: "#046C77aa",
            icon: <ReopenIcon className="tw-h-full tw-w-full tw-fill-white" />,
          },
        ]
      : []),
    ...(activeTab === 2
      ? [
          {
            label: t("financial.bank_transfers.transfer_approved"),
            value: `${dashboard.total} - ${dashboard.total_amount} ${t("rs")}`,
            color: "#046C77",
            icon: (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.86 67.86">
                <path
                  id="check-circle_4_"
                  data-name="check-circle (4)"
                  d="M51.5,25.725a2.825,2.825,0,0,1-.04,4L38.946,42.008a11.251,11.251,0,0,1-15.766.062L17.81,36.792a2.827,2.827,0,0,1,3.964-4.032l5.352,5.262a5.618,5.618,0,0,0,7.863-.051L47.5,25.688a2.825,2.825,0,0,1,4,.037ZM67.86,33.93A33.93,33.93,0,1,1,33.93,0,33.968,33.968,0,0,1,67.86,33.93Zm-5.655,0A28.275,28.275,0,1,0,33.93,62.205,28.307,28.307,0,0,0,62.205,33.93Z"
                  fill="#fff"
                />
              </svg>
            ),
          },
          {
            label: t("financial.bank_transfers.transfer_accepted_courses"),
            value: `${dashboard.course_total} - ${dashboard.course_total_amount} ${t("rs")}`,
            color: "#046C77dd",
            icon: (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.86 67.86">
                <path
                  id="add_1_"
                  data-name="add (1)"
                  d="M33.93,0A33.93,33.93,0,1,0,67.86,33.93,33.93,33.93,0,0,0,33.93,0Zm0,62.205A28.275,28.275,0,1,1,62.205,33.93,28.275,28.275,0,0,1,33.93,62.205ZM48.068,33.93a2.828,2.828,0,0,1-2.828,2.828H36.758V45.24a2.828,2.828,0,1,1-5.655,0V36.758H22.62a2.828,2.828,0,0,1,0-5.655H31.1V22.62a2.828,2.828,0,1,1,5.655,0V31.1H45.24A2.828,2.828,0,0,1,48.068,33.93Z"
                  fill="#fff"
                />
              </svg>
            ),
          },
          {
            label: t("financial.bank_transfers.transfer_accepted_programs"),
            value: `${dashboard.program_total} - ${dashboard.program_total_amount} ${t("rs")}`,
            color: "#046C77cc",
            icon: (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.857 67.863">
                <path
                  id="user-pen"
                  d="M25.448,33.93A16.965,16.965,0,1,0,8.483,16.965,16.982,16.982,0,0,0,25.448,33.93Zm0-28.275a11.31,11.31,0,1,1-11.31,11.31A11.321,11.321,0,0,1,25.448,5.655ZM30.4,45.862A19.815,19.815,0,0,0,5.655,65.033a2.828,2.828,0,1,1-5.655,0A25.465,25.465,0,0,1,31.81,40.388,2.828,2.828,0,0,1,30.4,45.865ZM65.375,33.588a8.684,8.684,0,0,0-12,0L34.414,52.552a11.242,11.242,0,0,0-3.314,8v4.484a2.829,2.829,0,0,0,2.828,2.828h4.484a11.235,11.235,0,0,0,8-3.314L65.372,45.585a8.485,8.485,0,0,0,0-12Zm-4,8L42.407,60.548a5.607,5.607,0,0,1-4,1.657H36.752V60.548a5.617,5.617,0,0,1,1.657-4L57.376,37.586a2.893,2.893,0,0,1,4,0,2.826,2.826,0,0,1,0,4Z"
                  fill="#fff"
                />
              </svg>
            ),
          },
          {
            label: t("financial.bank_transfers.transfer_accepted_exhibition_sponser"),
            value: `${dashboard.exhibition_booth_total} - ${dashboard.exhibition_booth_total_amount} ${t("rs")}`,
            color: "#046C77bb",
            icon: <ReopenIcon className="tw-h-full tw-w-full tw-fill-white" />,
          },
          {
            label: t("financial.bank_transfers.transfer_accepted_exhibition_users"),
            value: `${dashboard.exhibition_visit_total} - ${dashboard.exhibition_visit_total_amount} ${t("rs")}`,
            color: "#046C77aa",
            icon: <ReopenIcon className="tw-h-full tw-w-full tw-fill-white" />,
          },
          {
            label: t("wallet.label.depositamounts"),
            value: dashboard.wallet_total_amount,
            color: "#046C77aa",
            icon: <ReopenIcon className="tw-h-full tw-w-full tw-fill-white" />,
          },
        ]
      : []),
    ...(activeTab === 3
      ? [
          {
            label: t("financial.bank_transfers.transfer_rejected"),
            value: `${dashboard.total}`,
            color: "#046C77",
            icon: (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.86 67.86">
                <path
                  id="check-circle_4_"
                  data-name="check-circle (4)"
                  d="M51.5,25.725a2.825,2.825,0,0,1-.04,4L38.946,42.008a11.251,11.251,0,0,1-15.766.062L17.81,36.792a2.827,2.827,0,0,1,3.964-4.032l5.352,5.262a5.618,5.618,0,0,0,7.863-.051L47.5,25.688a2.825,2.825,0,0,1,4,.037ZM67.86,33.93A33.93,33.93,0,1,1,33.93,0,33.968,33.968,0,0,1,67.86,33.93Zm-5.655,0A28.275,28.275,0,1,0,33.93,62.205,28.307,28.307,0,0,0,62.205,33.93Z"
                  fill="#fff"
                />
              </svg>
            ),
          },
          {
            label: t("financial.bank_transfers.transfer_rejected_courses"),
            value: `${dashboard.course_total}`,
            color: "#046C77dd",
            icon: (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.86 67.86">
                <path
                  id="add_1_"
                  data-name="add (1)"
                  d="M33.93,0A33.93,33.93,0,1,0,67.86,33.93,33.93,33.93,0,0,0,33.93,0Zm0,62.205A28.275,28.275,0,1,1,62.205,33.93,28.275,28.275,0,0,1,33.93,62.205ZM48.068,33.93a2.828,2.828,0,0,1-2.828,2.828H36.758V45.24a2.828,2.828,0,1,1-5.655,0V36.758H22.62a2.828,2.828,0,0,1,0-5.655H31.1V22.62a2.828,2.828,0,1,1,5.655,0V31.1H45.24A2.828,2.828,0,0,1,48.068,33.93Z"
                  fill="#fff"
                />
              </svg>
            ),
          },
          {
            label: t("financial.bank_transfers.transfer_rejected_programs"),
            value: `${dashboard.program_total}`,
            color: "#046C77cc",
            icon: (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.857 67.863">
                <path
                  id="user-pen"
                  d="M25.448,33.93A16.965,16.965,0,1,0,8.483,16.965,16.982,16.982,0,0,0,25.448,33.93Zm0-28.275a11.31,11.31,0,1,1-11.31,11.31A11.321,11.321,0,0,1,25.448,5.655ZM30.4,45.862A19.815,19.815,0,0,0,5.655,65.033a2.828,2.828,0,1,1-5.655,0A25.465,25.465,0,0,1,31.81,40.388,2.828,2.828,0,0,1,30.4,45.865ZM65.375,33.588a8.684,8.684,0,0,0-12,0L34.414,52.552a11.242,11.242,0,0,0-3.314,8v4.484a2.829,2.829,0,0,0,2.828,2.828h4.484a11.235,11.235,0,0,0,8-3.314L65.372,45.585a8.485,8.485,0,0,0,0-12Zm-4,8L42.407,60.548a5.607,5.607,0,0,1-4,1.657H36.752V60.548a5.617,5.617,0,0,1,1.657-4L57.376,37.586a2.893,2.893,0,0,1,4,0,2.826,2.826,0,0,1,0,4Z"
                  fill="#fff"
                />
              </svg>
            ),
          },
          {
            label: t("financial.bank_transfers.transfer_rejected_exhibition_sponser"),
            value: `${dashboard.exhibition_booth_total}`,
            color: "#046C77bb",
            icon: <ReopenIcon className="tw-h-full tw-w-full tw-fill-white" />,
          },
          {
            label: t("financial.bank_transfers.transfer_rejected_exhibition_users"),
            value: `${dashboard.exhibition_visit_total}`,
            color: "#046C77aa",
            icon: <ReopenIcon className="tw-h-full tw-w-full tw-fill-white" />,
          },
          {
            label: t("financial.bank_transfers.transfer_rejected_deposit"),
            value: dashboard.wallet_total,
            color: "#046C77aa",
            icon: <ReopenIcon className="tw-h-full tw-w-full tw-fill-white" />,
          },
        ]
      : []),
  ];

  return (
    <div className={classes["departments-trainees"]}>
      {(isExportLoading || loading) && <SkeletonCardOverlay skeletonWidth="100" />}
      <div className="container-fluid">
        <div className={classes["departments-trainees__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          {/* statistics */}
          <div className="row">
            <div className="col-12">
              <MainBox>
                <div className="tw-text-teal-700 tw-text-2xl">{t("general.statistics")}</div>
                <div className={classes["statics_container"]}>
                  {statistics.map((item) => (
                    <div
                      className="tw-flex tw-items-center tw-space-s-4 tw-p-4 tw-text-white tw-rounded"
                      style={{ backgroundColor: item.color }}
                    >
                      <div className="tw-h-10 tw-w-10">{item.icon}</div>
                      <div>
                        <div className="tw-text-xl">{item.value}</div>
                        <div className="tw-text-sm">{item.label}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </MainBox>
            </div>
          </div>
          {/* ///////////////// */}
          <div className="row">
            <div className="col-12">
              <MainBox>
                <MainBoxHead
                  title={t("bankTransfer")}
                  actions={filterActions({
                    showFilter: showFilterHandler,
                    addAction: () => {},
                    exportPdf: () => {
                      exportHandler("pdf");
                    },
                    exportExcel: () => {
                      exportHandler("xlsx");
                    },
                  })}
                />
                {showFilter && (
                  <BankTransfersFilter
                    filterData={filterData}
                    changeHandler={changeHandler}
                    resetHandler={resetHandler}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    filterHandler={getBankTransfers} //////////////////
                    resetFilterHandler={resetFilterHandler}
                    activeTab={activeTab}
                    courses={courses}
                    programs={programs}
                  />
                )}

                <BankTransfersTable
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                  setFilterData={setFilterData}
                  bankTransfers={bankTransfers}
                  getBankTransfers={getBankTransfers}
                  setLoading={setLoading}
                  isLoading={isLoading}
                />

                <Pagination
                  count={bankTransferMeta.totalPages || 0}
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  className="main-pagination"
                  page={currentPage}
                  onChange={(e, page) => {
                    setCurrentPage(page);
                  }}
                />
              </MainBox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PeymentRequestsCoursesPrograms;
