import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import DevelopmentIcon from "../../../../assets/icons/userCardIcon2.svg";
import UsersIcon from "../../../../assets/icons/userCardIcon1.svg";

import classes from "./userCard.module.css";

import ArrowOrange from "../../../../assets/icons/ArrowOrange.svg";
import { useHistory } from "react-router-dom";
import CustomButton from "../CustomButton/CustomButton";
import StarsRating from "../StarsRating/StarsRating";
import Title from "../Title/Title";

const UserCard = ({ img, name, rate, trainer }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [isHovering, setIsHovering] = useState(false);
  function handleMouseOver() {
    setIsHovering(true);
  }
  function handleMouseOut() {
    setIsHovering(false);
  }

  return (
    <div
      className={`${classes["user-card"]} ${isHovering && classes.hovering}`}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <div className={classes["card-content"]}>
        <div className={classes.content}>
          <div className={classes.imgCont}>
            <img src={img} alt={name} className={classes.img} />
          </div>
          <div className={classes.cardContantNew}>
            <div className={classes["flip-star"]}>
              <StarsRating
                rating={rate || 0}
                cutStyle={classes["star-font-size"]}
                isTraniresPage={true}
              />
            </div>
            <div className={classes["name"]}>
              <Title
                title={name?.length > 21 ? `${name?.slice(0, 21)} ...` : name}
                fontSize={18}
                titleColor={"#012327"}
              />
            </div>

            <div className={classes.num_courses}>
              <div className={classes.numContainer}>
                {`${
                  i18n.language == "ar"
                    ? trainer?.job_title_ar?.slice(0, 19) || ""
                    : trainer?.job_title_en?.slice(0, 19) || ""
                }`}
              </div>
              <span>|</span>
              <div className={classes.numContainer}>
                <img src={UsersIcon} alt="" style={{ width: 20, height: 20 }} />
                {trainer.countCourses}
              </div>
              <span>|</span>
              <div className={classes.numContainer}>
                <img
                  src={DevelopmentIcon}
                  alt=""
                  style={{ width: 20, height: 20 }}
                />
                {trainer.countUsers}
              </div>
            </div>
            <div className={classes.sapretor}></div>
            <div className={classes.btnCont}>
              <CustomButton
                value={t("see__")}
                icon={ArrowOrange}
                iconClass={classes.iconClass}
                colors="#133535"
                classes={classes.aboutButton}
                action={() =>
                  history.push(`/trainers/trainer-details/${trainer.id}`)
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCard;
