import { Formik } from "formik";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import CustomButton from "../../Modules/Shared/Components/CustomButton/CustomButton";
import { importLessonContent } from "../../Services/api/courses/courseProvider";
import CancelIcon from "../../assets/icons/remove-red.svg";

function UploadModal({ title, dismissModal, type, itemId, fetchCourse }) {
  require("./uploadModal.css");
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const inputFile = useRef(null);
  const [file, setFile] = useState("");

  return (
    <>
      <div
        className="upload-modal"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <div className="tajah-basic-modal-header">
          <h2 className="upload-modal-title">{title}</h2>
          <div
            onClick={() => {
              dismissModal();
            }}
          >
            <img src={CancelIcon} alt="" />
          </div>
        </div>
        <Formik
          initialValues={{
            content: "",
            parent_id: itemId,
            is_sub_chapter: type === "subChapters" ? 1 : 0,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            // try {
            let formData = new FormData();
            Object.keys(values).forEach((field) => {
              formData.append(field, values[field]);
            });
            importLessonContent(type, formData)
              .then((res) => {
                fetchCourse();
                if (res.status === 200 && res.data.status) {
                  toast.success(
                    <span style={{ fontSize: 13, fontWeight: "bold" }}>
                      {t("modal.trainer.course.chapter.messages.title")}
                    </span>
                  );
                  dismissModal();
                }
              })
              .catch((err) => {
                toast.error(
                  <>
                    <span style={{ fontSize: 13, fontWeight: "bold", display: "block" }}>{err.data.msg}</span>
                    {err.data.errors && (
                      <ul>
                        {Object.keys(err.data.errors).map((key) => {
                          return (
                            <li>
                              <span style={{ fontSize: 11 }}>{err.data.errors[key]}</span>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </>
                );
              });
          }}
          enableReinitialize
          validate={(values) => {
            const errors = {};
            if (!values.content) {
              errors.name = t("crud.errors.required");
            }
            return errors;
          }}
        >
          {({ values, errors, handleChange, handleSubmit, isSubmitting, handleBlur, setFieldValue }) => (
            <form onSubmit={handleSubmit} className="upload-modal-form">
              <div className="containerContentFile">
                <input
                  type="file"
                  name="content"
                  id="content"
                  className="inputfile"
                  accept={
                    type === "subChapters"
                      ? "application/x-zip-compressed"
                      : ".doc,.docx,application/x-zip-compressed,application/pdf"
                  }
                  onChange={(event) => {
                    let file = event.target.files[0];
                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                      setFieldValue("content", event.target.files[0]);
                      setFile(event.target.files[0].name);
                    };
                    reader.onerror = function (error) {};
                  }}
                />
                <label for="content" className="labelFile">
                  {file === "" ? t("chooseFile") : file}
                  {type === "subChapters" ? "(.zip)" : ""}
                  <i className="fa fa-file-upload" style={{ fontSize: 25 }}></i>
                </label>
                <p className={"form-input-error-space"}>{errors.content ? errors.content : null}</p>
              </div>
              <div className="containerButtonFile">
                <CustomButton
                  value={t("modal.trainer.course.chapter.modal.button")}
                  colors="#036c77"
                  type="submit"
                  disable={isSubmitting}
                  loading={isSubmitting}
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default UploadModal;
