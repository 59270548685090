import React from "react";
import "react-circular-progressbar/dist/styles.css";
import {useTranslation} from "react-i18next";
import facebook from "../../../../../assets/image/facebook.png";
import twitter from "../../../../../assets/image/twitter.png";
import linkedin from "../../../../../assets/image/linkedin.png";

function HeaderProfile() {
  require("./headerProfile.css");
  const { t } = useTranslation();

  return (
    <div className="header_profile">
      <div className="container-fluid profile_wrapper">
        <div className="profile_grid">
          <div className="profile_dhead">
            <div className="profile_dimage"></div>
            <div className="profile_dname">
              <div className="profile_dtop">{t("hakem")}</div>
              <div className="profile_dbottom">
                <div className="profile_head_soical mt-2">
                  <div className="">
                    <img
                      src={facebook}
                      alt="FB"
                      className="profile_soical_img"
                    />
                  </div>
                  <div>
                    <img
                      src={twitter}
                      alt="TW"
                      className="profile_soical_img pt-1"
                    />
                  </div>
                  <div>
                    <img
                      src={linkedin}
                      alt="LIN"
                      className="profile_soical_img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderProfile;
