import { useTranslation } from "react-i18next";
import Breadcrumb from "../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../Shared/Components/MainBox/MainBox";
import HomeIcon from "../../../assets/icons/home.svg";
import classes from "./welcomeMessage.module.css";
import Title from "../../Shared/Components/Title/Title";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CustomButton from "../../Shared/Components/CustomButton/CustomButton";
import "./welcomeMessage.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getlivechatSettings,
  postLivechatStandardMessages,
  resetLivechat,
} from "../../../Redux/Actions/livechat/livechat.action";
import { useHistory } from "react-router";
import LoadingSpinner from "../../Shared/Components/LoadingSpinner/LoadingSpinner";

export default function WelcomeMessage() {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { livechat_standard_messages, livechat_setting } = useSelector(
    (state) => state.livechatReducer
  );
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [settingRes, setSettingRes] = useState({});
  const [loading, setLoading] = useState(true);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "chat",
      page: t("chat.title"),
      pagePath: "/admin/livechat",
    },

    {
      id: "chat1",
      page: t("chat.wlcmsg"),
      active: true,
    },
  ];

  useEffect(() => {
    dispatch(getlivechatSettings());
  }, []);

  useEffect(() => {
    if (livechat_setting.status === 200) {
      setSettingRes(livechat_setting.data.setting.settings);
      setLoading(false);
    }
  }, [livechat_setting]);

  useEffect(() => {
    if (livechat_standard_messages.status) {
      setHasSubmitted(false);
      history.push("/admin/livechat");
      dispatch(resetLivechat());
    }
  }, [livechat_standard_messages]);

  return (
    <div className={`container-fluid`}>
      <div className="row">
        <div className={`col-12 ${classes["m-50"]}`}>
          <div className={classes["breadcrumbs"]}>
            <Breadcrumb list={breadcrumbList} />
          </div>

          <MainBox>
            {loading ? (
              <div className={classes["loading__container"]}>
                <LoadingSpinner />
              </div>
            ) : (
              <Formik
                initialValues={{
                  chat_welcome_message_ar: settingRes.chat_welcome_message_ar,
                  chat_welcome_message_en: settingRes.chat_welcome_message_en,
                  chat_auto_reply_message_ar:
                    settingRes.chat_auto_reply_message_ar,
                  chat_auto_reply_message_en:
                    settingRes.chat_auto_reply_message_en,
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  setSubmitting(true);
                  try {
                    dispatch(postLivechatStandardMessages(values));
                  } catch (err) {
                    toast.error(
                      <>
                        <span
                          style={{
                            fontSize: 13,
                            fontWeight: "bold",
                            display: "block",
                            paddingBottom: "10px",
                          }}
                        >
                          {err.response.data.msg}
                        </span>
                        <ul>
                          {Object.keys(err.response.data.errors).map((key) => {
                            return (
                              <li>
                                <span style={{ fontSize: 11 }}>
                                  {err.response.data.errors[key]}
                                </span>
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    );
                  }
                  setSubmitting(false);
                }}
                validate={(values) => {
                  setHasSubmitted(true);
                  const errors = {};

                  if (!values.chat_welcome_message_ar) {
                    errors.chat_welcome_message_ar = t(
                      "Exhibition.errors.required"
                    );
                  }

                  if (!values.chat_welcome_message_en) {
                    errors.chat_welcome_message_en = t(
                      "Exhibition.errors.required"
                    );
                  }

                  if (!values.chat_auto_reply_message_en) {
                    errors.chat_auto_reply_message_en = t(
                      "Exhibition.errors.required"
                    );
                  }

                  if (!values.chat_auto_reply_message_ar) {
                    errors.chat_auto_reply_message_ar = t(
                      "Exhibition.errors.required"
                    );
                  }

                  if (Object.keys(errors).length === 0) {
                    setHasSubmitted(true);
                  } else {
                    setHasSubmitted(false);
                  }

                  return errors;
                }}
                validateOnChange={hasSubmitted}
              >
                {({ errors, handleSubmit, values, setFieldValue }) => (
                  <form onSubmit={handleSubmit}>
                    <Title title={t("chat.wlcmsg")} fontSize={24} />

                    <div className={classes["m-20"]}>
                      <div className={classes["d-flex-input"]}>
                        <label className={classes["input-label"]}>
                          {t("chat.arabic")}
                        </label>
                      </div>
                      <CKEditor
                        editor={ClassicEditor}
                        data={values.chat_welcome_message_ar}
                        onReady={(editor) => {}}
                        config={{
                          toolbar: ["bold", "italic"],
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setFieldValue("chat_welcome_message_ar", data);
                        }}
                      />
                      {errors.chat_welcome_message_ar && (
                        <p
                          className={`form-input-error-space ${classes["input-field-error"]}`}
                        >
                          {errors.chat_welcome_message_ar}
                        </p>
                      )}
                    </div>

                    <div className={classes["m-20"]}>
                      <div className={classes["d-flex-input"]}>
                        <label className={classes["input-label"]}>
                          {t("chat.english")}
                        </label>
                      </div>
                      <CKEditor
                        editor={ClassicEditor}
                        data={values.chat_welcome_message_en}
                        onReady={(editor) => {}}
                        placeholder="test test"
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setFieldValue("chat_welcome_message_en", data);
                        }}
                        config={{
                          toolbar: ["bold", "italic"],
                        }}
                      />
                      {errors.chat_welcome_message_en && (
                        <p
                          className={`form-input-error-space ${classes["input-field-error"]}`}
                        >
                          {errors.chat_welcome_message_en}
                        </p>
                      )}
                    </div>

                    <div className={`dashes-lines ${classes["m-20"]}`}></div>

                    <Title title={t("chat.msgautoreply")} fontSize={24} />

                    <div className={classes["m-20"]}>
                      <div className={classes["d-flex-input"]}>
                        <label className={classes["input-label"]}>
                          {t("chat.arabic")}
                        </label>
                      </div>
                      <CKEditor
                        editor={ClassicEditor}
                        data={values.chat_auto_reply_message_ar}
                        onReady={(editor) => {}}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setFieldValue("chat_auto_reply_message_ar", data);
                        }}
                        config={{
                          toolbar: ["bold", "italic"],
                        }}
                      />
                      {errors.chat_auto_reply_message_ar && (
                        <p
                          className={`form-input-error-space ${classes["input-field-error"]}`}
                        >
                          {errors.chat_auto_reply_message_ar}
                        </p>
                      )}
                    </div>

                    <div className={classes["m-20"]}>
                      <div className={classes["d-flex-input"]}>
                        <label className={classes["input-label"]}>
                          {t("chat.english")}
                        </label>
                      </div>
                      <CKEditor
                        editor={ClassicEditor}
                        data={values.chat_auto_reply_message_en}
                        onReady={(editor) => {}}
                        placeholder="test test"
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setFieldValue("chat_auto_reply_message_en", data);
                        }}
                        config={{
                          toolbar: ["bold", "italic"],
                        }}
                      />
                      {errors.chat_auto_reply_message_en && (
                        <p
                          className={`form-input-error-space ${classes["input-field-error"]}`}
                        >
                          {errors.chat_auto_reply_message_en}
                        </p>
                      )}
                    </div>

                    <div
                      className={`${classes["modal-button"]} modal-buttons tw-mt-5`}
                    >
                      <CustomButton
                        value={t("FAQ.done")}
                        colors={"#036c77"}
                        type={"button"}
                        iconClass={"mt-2"}
                        action={handleSubmit}
                        classes={"button-width"}
                        loading={hasSubmitted}
                        disable={hasSubmitted}
                      />
                    </div>
                  </form>
                )}
              </Formik>
            )}
          </MainBox>
        </div>
      </div>
    </div>
  );
}
