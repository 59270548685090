import { Box, CircularProgress } from "@material-ui/core";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { Formik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import Checkbox from "../../Modules/Shared/Components/Checkbox/Checkbox";
import CustomButton from "../../Modules/Shared/Components/CustomButton/CustomButton";
import Input from "../../Modules/Shared/Components/Input/Input";
import MainDatePicker from "../../Modules/Shared/Components/MainDatePicker/MainDatePicker";
import Select from "../../Modules/Shared/Components/Select/Select";
import SkeletonCardOverlay from "../../Modules/Shared/Components/Spinner/SkeletonCardOverlay";
import { LessonContentContext } from "../../Providers/LessonContentProvider";
import { toServerTime } from "../../Services/Timezone";
import {
  ListTypes,
  addZoomSession,
  updateMeeting,
} from "../../Services/api/Zoom/ZoomProvider";
import DeleteIcon from "../../assets/icons/delete.svg";
import { getLocaleTime } from "../../utils/utcToLocal";
import classes from "./AddSession.module.css";

function AddSessionToLessonModal(props) {
  require("./AddSessionToLessonModal.css");
  const lessonContentContext = useContext(LessonContentContext);

  const { t, i18n } = useTranslation();
  const { id } = useParams();

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isLocalSubmitting, setIsLocalSubmitting] = useState(false);
  const [hoursDatePicker, setHoursDatePicker] = useState(null);
  const [minutesDatePicker, setMinutesDatePicker] = useState(null);
  const [hoursDatePickerEnd, setHoursDatePickerEnd] = useState(null);
  const [minutesDatePickerEnd, setMinutesDatePickerEnd] = useState(null);
  const [typesOptions, setTypesOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    currentDate();
    executeOnLoad();
  }, []);

  async function executeOnLoad() {
    let response = await ListTypes();
    if (response.status && response.status === 200) {
      setTypesOptions(
        response.data.data.map((item) => ({
          label: i18n.language === "en" ? item.name.en : item.name.ar,
          value: item.id,
          id: item.id,
        }))
      );
    }
  }

  const formatDate = (d) => {
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const formatTime = (t) => {
    let hours = "" + t.getHours(),
      minutes = "" + t.getMinutes(),
      second = "00";

    hours = hours === "0" ? "00" : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return [hours, minutes, second].join(":");
  };

  const currentDate = (datePicker) => {
    let HoursDate = new Date().getHours();
    let MinutesDate = new Date().getMinutes();

    if (datePicker === "startDate") {
      setHoursDatePicker(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setMinutesDatePicker(MinutesDate >= 30 ? 0 : MinutesDate);
    } else if (datePicker === "endDate") {
      setHoursDatePickerEnd(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setMinutesDatePickerEnd(MinutesDate >= 30 ? 0 : MinutesDate);
    } else {
      setHoursDatePicker(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setMinutesDatePicker(MinutesDate >= 30 ? 0 : MinutesDate);
      setHoursDatePickerEnd(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setMinutesDatePickerEnd(MinutesDate >= 30 ? 0 : MinutesDate);
    }
    setLoading(false);
  };

  useEffect(() => {
    currentDate();
  }, []);

  return (
    <>
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              height: "100vh",
            }}
          >
            <SkeletonCardOverlay />
          </div>
        </div>
      ) : (
        <div
          className="add_Session_modal"
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div className="add_Session_modal_header">
            <h2 className="add_Session_modal_title">{props.title}</h2>
            <div
              className="add_Session_modal_close"
              onClick={props.dismissModal}
            >
              <img src={DeleteIcon} alt="close" />
            </div>
          </div>
          <Formik
            initialValues={{
              type_id: props?.data?.meeting_type || "1",
              topic: props?.data?.meeting_agenda || "",
              // restrict_number: props?.data?.max_registrants || "",
              percentage: props?.data?.percentage || "",
              duration: props?.data?.duration || "",
              reminder_sent:
                props?.data?.reminder_time && props?.data?.reminder_timing_type
                  ? "1"
                  : "0",
              start_time: props?.data?.start_time
                ? new Date(getLocaleTime(new Date(props?.data?.start_time)))
                : setHours(
                    setMinutes(new Date(), minutesDatePicker),
                    hoursDatePicker
                  ),
              time: props?.data?.reminder_time || "",
              timing_type: props?.data?.reminder_timing_type || "minutes",
              include_achivement_per: props?.data?.include_achivement_per
                ? props?.data?.include_achivement_per
                : "0",
              waiting_room: props?.data?.waiting_room
                ? props?.data?.waiting_room
                : "0",
              include_view_per: props?.data?.include_view_per
                ? props?.data?.include_view_per
                : "0",
              // password: props?.data?.password || "",
              title: props?.data?.title || "",
              end: props?.data?.end_time
                ? new Date(getLocaleTime(new Date(props?.data?.end_time)))
                : setHours(
                    setMinutes(new Date(), minutesDatePickerEnd),
                    hoursDatePickerEnd
                  ),
            }}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                setSubmitting(true);
                setLoading(true);
                setIsLocalSubmitting(true);
                let dataToBeUploaded = {
                  agenda: values.topic,
                  start: "",
                  ...values,
                };

                dataToBeUploaded.start_time = toServerTime(
                  formatDate(dataToBeUploaded.start_time) +
                    " " +
                    formatTime(dataToBeUploaded.start_time)
                );
                dataToBeUploaded.start = dataToBeUploaded.start_time;
                dataToBeUploaded.end = toServerTime(
                  formatDate(dataToBeUploaded.end) +
                    " " +
                    formatTime(dataToBeUploaded.end)
                );

                if (dataToBeUploaded.reminder_sent == "0") {
                  delete dataToBeUploaded.reminder_sent;
                  delete dataToBeUploaded.time;
                  delete dataToBeUploaded.timing_type;
                }
                delete dataToBeUploaded.reminder_sent;

                if (Number(dataToBeUploaded.type_id) === 1) {
                  dataToBeUploaded.title = dataToBeUploaded.topic;
                  delete dataToBeUploaded.start;
                  delete dataToBeUploaded.end;
                }

                if (Number(dataToBeUploaded.type_id) === 3) {
                  delete dataToBeUploaded.percentage;
                  dataToBeUploaded.include_achivement_per = 0;
                  dataToBeUploaded.waiting_room = 1;
                }

                if (dataToBeUploaded.type_id) {
                  dataToBeUploaded.type_id = parseInt(dataToBeUploaded.type_id);
                }

                if (
                  props.data &&
                  moment(
                    getLocaleTime(new Date(props.data.start_time))
                  ).isBefore(new Date())
                ) {
                  delete dataToBeUploaded.type_id;
                  delete dataToBeUploaded.start_time;
                  delete dataToBeUploaded.start;
                  delete dataToBeUploaded.duration;
                  delete dataToBeUploaded.percentage;
                  delete dataToBeUploaded.end;
                  delete dataToBeUploaded.reminder_sent;
                  delete dataToBeUploaded.time;
                  delete dataToBeUploaded.timing_type;
                  delete dataToBeUploaded.waiting_room;
                }

                const response = props.data
                  ? await updateMeeting(props.data.id, dataToBeUploaded)
                  : await addZoomSession(id, dataToBeUploaded);
                await lessonContentContext.fetchLessonContent(id);
                props.dismissModal();
                if (
                  response.status === 201 ||
                  (response.status === 200 && response.data.status)
                ) {
                  setLoading(false);
                } else {
                  toast.error(
                    <span style={{ fontSize: 13, fontWeight: "bold" }}>
                      {response.data.msg
                        ? response.data.msg
                        : "Failure in service"}
                    </span>
                  );
                  setIsLocalSubmitting(false);
                  setLoading(false);
                }
              } catch (err) {
                setIsLocalSubmitting(false);
                setLoading(false);
                toast.error(
                  <>
                    <span
                      style={{
                        fontSize: 13,
                        fontWeight: "bold",
                        display: "block",
                        paddingBottom: "10px",
                      }}
                    >
                      {err.response.data.msg}
                    </span>
                    <ul>
                      {Object.keys(err.response.data.errors).map((key) => {
                        return (
                          <li>
                            <span style={{ fontSize: 11 }}>
                              {err.response.data.errors[key]}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </>
                );
              }
            }}
            validateOnChange={hasSubmitted}
            validate={(values) => {
              setHasSubmitted(true);
              values.duration =
                values.type_id && Number(values.type_id) !== 1
                  ? Math.round(
                      (new Date(values.end).getTime() -
                        new Date(values.start_time).getTime()) /
                        60000
                    )
                  : values.duration;

              const errors = {};
              if (!values.type_id) {
                errors.type_id = t("crud.errors.required");
              }
              if (!values.topic) {
                errors.topic = t("crud.errors.required");
              }
              if (values.type_id && Number(values.type_id) !== 3) {
                if (!values.percentage) {
                  errors.percentage = t("crud.errors.required");
                }
              }

              if (
                moment(getLocaleTime(new Date(values.start_time))).isBefore(
                  new Date()
                )
              ) {
                if (!values.duration) {
                  errors.duration = t("crud.errors.required");
                }
              }

              if (values.percentage < 0 || values.percentage > 100) {
                errors.percentage = t("crud.errors.percentage");
              }

              if (values.duration && values.duration < 30) {
                errors.duration = t("zoom.error_duration_session");
              }
              if (!values.start_time) {
                errors.start_time = t("crud.errors.required");
              }
              if (values.reminder_sent === "on") {
                if (!values.time) {
                  errors.time = t("crud.errors.required");
                }
                if (!values.timing_type) {
                  errors.timing_type = t("crud.errors.required");
                }
                if (!values.time) {
                  errors.time = t("crud.errors.required");
                }
              }
              if (values.type_id && Number(values.type_id) !== 1) {
                if (!values.title) {
                  errors.title = t("crud.errors.required");
                }
                if (!values.end) {
                  errors.end = t("crud.errors.required");
                }
              }
              return errors;
            }}
          >
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
              isSubmitting,
              handleBlur,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit} className="add_Session_modal_form">
                <div className="add_Session_modal_form_fields">
                  <div className={"col-md-12"}>
                    <Select
                      name="type_id"
                      label={t("zoom.Name_training_course")}
                      onchange={handleChange}
                      options={typesOptions}
                      error={errors.type_id}
                      value={values.type_id}
                      initialValue={t("zoom.Name_training_course")}
                      required
                      disabled={
                        props.data &&
                        moment(
                          getLocaleTime(new Date(props.data.start_time))
                        ).isBefore(new Date())
                      }
                    />
                  </div>
                  <div className="col-md-12">
                    <div style={{ display: "flex", gap: "20px" }}>
                      {values.type_id && Number(values.type_id) === 3 ? null : (
                        <Checkbox
                          name="include_achivement_per"
                          label={t("include_achivement_per")}
                          onChange={(event) => {
                            setFieldValue(
                              "include_achivement_per",
                              event.target.checked ? 1 : 0
                            );
                          }}
                          checked={Number(values.include_achivement_per) === 1}
                          error={errors.include_achivement_per}
                          value={values.include_achivement_per}
                        />
                      )}
                      <Checkbox
                        name="include_view_per"
                        label={t("include_view_per")}
                        onChange={(event) => {
                          setFieldValue(
                            "include_view_per",
                            event.target.checked ? 1 : 0
                          );
                        }}
                        checked={Number(values.include_view_per) === 1}
                        error={errors.include_view_per}
                        value={values.include_view_per}
                      />
                      <Checkbox
                        name="waiting_room"
                        label={t("waiting_room")}
                        onChange={(event) => {
                          setFieldValue(
                            "waiting_room",
                            event.target.checked ? 1 : 0
                          );
                        }}
                        checked={
                          Number(values.type_id) === 3 ||
                          Number(values.waiting_room) === 1
                        }
                        error={errors.waiting_room}
                        value={values.waiting_room}
                        disabled={Number(values.type_id) === 3}
                      />
                    </div>
                  </div>
                  {values.type_id && Number(values.type_id) !== 1 ? (
                    <>
                      <div className="col-md-6">
                        <Input
                          type="text"
                          placeholder={t("title")}
                          label={t("title")}
                          name="title"
                          onChange={handleChange}
                          value={values.title}
                          error={errors.title}
                          required
                          disabled={
                            props.data &&
                            moment(
                              getLocaleTime(new Date(props.data.start_time))
                            ).isBefore(new Date()) &&
                            values.type_id &&
                            Number(values.type_id) === 2
                          }
                        />
                      </div>
                      {/* <div className="col-md-6">
                        <Input
                          type="password"
                          placeholder={t("password")}
                          label={t("password")}
                          name="password"
                          onChange={handleChange}
                          value={values.password}
                          error={errors.password}
                          required
                          isPassword
                          autoComplete="new-password"
                          note={t("password_msg")}
                        />
                      </div> */}
                    </>
                  ) : null}
                  <div className="col-md-6">
                    <Input
                      type="text"
                      placeholder={t("zoom.topic_session")}
                      label={t("zoom.topic_session")}
                      name="topic"
                      onChange={handleChange}
                      value={values.topic}
                      error={errors.topic}
                      required
                      disabled={
                        props.data &&
                        moment(
                          getLocaleTime(new Date(props.data.start_time))
                        ).isBefore(new Date()) &&
                        values.type_id &&
                        Number(values.type_id) === 2
                      }
                    />
                  </div>
                  {/* <div className="col-md-6">
                  <Input
                    type="number"
                    placeholder={t("zoom.number_registere")}
                    label={t("zoom.number_registere")}
                    name="restrict_number"
                    onChange={handleChange}
                    value={values.restrict_number}
                    error={errors.restrict_number}
                    required
                  />
                </div> */}
                  {values.type_id && Number(values.type_id) === 3 ? null : (
                    <div
                      className={`col-md-${
                        values.type_id && Number(values.type_id) === 2
                          ? "12"
                          : "6"
                      }`}
                    >
                      <Input
                        type="number"
                        placeholder={t("zoom.achivement_percentage")}
                        label={t("zoom.achivement_percentage")}
                        name="percentage"
                        onChange={handleChange}
                        value={values.percentage}
                        error={errors.percentage}
                        required
                        disabled={
                          props.data &&
                          moment(
                            getLocaleTime(new Date(props.data.start_time))
                          ).isBefore(new Date())
                        }
                      />
                    </div>
                  )}
                  <div
                    className={
                      values.type_id && Number(values.type_id) !== 1
                        ? "col-md-5"
                        : "col-md-6"
                    }
                  >
                    <MainDatePicker
                      label={t("zoom.start_date")}
                      name="start_time"
                      onChange={(date) => {
                        setFieldValue("start_time", date);
                        if (
                          values.end &&
                          new Date(values.end).getTime() <
                            new Date(date).getTime()
                        ) {
                          setFieldValue("end", date);
                        }
                        let CurrentDate = new Date();
                        let CurrentDateFormate =
                          CurrentDate.toISOString().split("T")[0];
                        let DataPickerValue = date.toISOString().split("T")[0];
                        if (
                          new Date(DataPickerValue).getTime() >
                          new Date(CurrentDateFormate).getTime()
                        ) {
                          setHoursDatePicker(0);
                          setMinutesDatePicker(0);
                        } else {
                          currentDate("startDate");
                        }
                      }}
                      minDate={new Date()}
                      minTime={setHours(
                        setMinutes(new Date(), minutesDatePicker),
                        hoursDatePicker
                      )}
                      maxTime={setHours(setMinutes(new Date(), 30), 23)}
                      value={values.start_time}
                      error={errors.start_time}
                      required
                      readOnly={
                        props.data &&
                        moment(
                          getLocaleTime(new Date(props.data.start_time))
                        ).isBefore(new Date())
                      }
                    />
                  </div>
                  {values.type_id && Number(values.type_id) !== 1 ? (
                    <div className="col-md-5">
                      <MainDatePicker
                        label={t("end_date")}
                        name="end"
                        onChange={(date) => {
                          setFieldValue("end", date);

                          let CurrentDate = new Date();
                          let CurrentDateFormate =
                            CurrentDate.toISOString().split("T")[0];
                          let DataPickerValue = date
                            .toISOString()
                            .split("T")[0];
                          if (
                            new Date(DataPickerValue).getTime() >
                            new Date(CurrentDateFormate).getTime()
                          ) {
                            setHoursDatePickerEnd(0);
                            setMinutesDatePickerEnd(0);
                          } else {
                            currentDate("endDate");
                          }
                        }}
                        minDate={new Date(values.start_time)}
                        minTime={setHours(
                          setMinutes(new Date(), minutesDatePickerEnd),
                          hoursDatePickerEnd
                        )}
                        maxTime={setHours(setMinutes(new Date(), 30), 23)}
                        value={values.end}
                        error={errors.end}
                        required
                        readOnly={
                          props.data &&
                          moment(
                            getLocaleTime(new Date(props.data.start_time))
                          ).isBefore(new Date())
                        }
                      />
                    </div>
                  ) : null}
                  <div
                    className={
                      values.type_id && Number(values.type_id) !== 1
                        ? "col-md-2"
                        : "col-md-6"
                    }
                  >
                    <Input
                      type="number"
                      placeholder={t("zoom.duration_session")}
                      label={t("zoom.duration_session")}
                      name="duration"
                      onChange={handleChange}
                      value={
                        values.type_id && Number(values.type_id) === 1
                          ? values.duration
                          : values.end && values.start_time
                          ? Math.round(
                              (new Date(values.end).getTime() -
                                new Date(values.start_time).getTime()) /
                                60000
                            )
                          : values.duration
                      }
                      disabled={
                        (values.type_id && Number(values.type_id) !== 1) ||
                        (props.data &&
                          moment(
                            getLocaleTime(new Date(props.data.start_time))
                          ).isBefore(new Date()))
                      }
                      error={errors.duration}
                      required
                    />
                  </div>
                  <div className="col-md-12">
                    <Checkbox
                      label={t("zoom.minutes_before_appointment")}
                      name="reminder_sent"
                      onChange={(e) => {
                        setFieldValue(
                          "reminder_sent",
                          e.target.checked ? "1" : "0"
                        );
                      }}
                      error={errors.reminder_sent}
                      checked={values.reminder_sent == "1"}
                      required
                      // defaultValue= if there is props.data then true
                      defaultValue={props.data ? true : false}
                      disabled={
                        props.data &&
                        moment(
                          getLocaleTime(new Date(props.data.start_time))
                        ).isBefore(new Date())
                      }
                    />
                  </div>
                  {values.reminder_sent === "1" ? (
                    <>
                      <div className="col-md-6">
                        <Select
                          name="timing_type"
                          label={t("zoom.timing_type")}
                          onchange={handleChange}
                          options={[
                            { label: t("days"), value: "days", id: 0 },
                            { label: t("hours"), value: "hours", id: 1 },
                            { label: t("minutes"), value: "minutes", id: 2 },
                          ]}
                          error={errors.timing_type}
                          value={values.timing_type}
                          defaultValue="minutes"
                          required
                          disabled={
                            props.data &&
                            moment(
                              getLocaleTime(new Date(props.data.start_time))
                            ).isBefore(new Date())
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <Input
                          type="number"
                          placeholder={t("zoom.time")}
                          label={t("zoom.time")}
                          name="time"
                          onChange={handleChange}
                          value={values.time}
                          error={errors.time}
                          required
                          disabled={
                            props.data &&
                            moment(
                              getLocaleTime(new Date(props.data.start_time))
                            ).isBefore(new Date())
                          }
                        />
                      </div>
                    </>
                  ) : null}
                </div>

                {/* <div className="add_Session_actions">
                <CustomButton
                  type="submit"
                  value={t("sendNow")}
                  loading={isLocalSubmitting}
                  disable={isSubmitting}
                  colors="#036c77"
                />
              </div> */}
                <div className="add_Session_actions">
                  <div
                    className={`${classes["create_tax__form__field-actions"]} col-12`}
                  >
                    <button
                      className={
                        classes["create_tax__form__field-actions-submit"]
                      }
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <Box sx={{ display: "flex" }}>
                          <CircularProgress color="white" size={30} />
                        </Box>
                      ) : (
                        t("crud.placeholders.submit")
                      )}
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
}

export default AddSessionToLessonModal;
