function TrainerSpinnerCard() {
  require("./trainerSpinnerCard.css");
  return (
    <>
      <div className="trainers_card">
        <div
          className="skeleton-box"
          style={{ width: "100%", height: "100%" }}
        ></div>
      </div>
    </>
  );
}

export default TrainerSpinnerCard;
