import { Formik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import UploadModal from "../../../../../Modals/uploadModal/UploadModal";
import { CourseProfileContext } from "../../../../../Services/api/courses/Trainer/CourseProfileContext";

import {
  addChapter,
  deleteChapter,
  editChapter,
  exportLessonContent,
} from "../../../../../Services/api/courses/courseProvider";
import WarningIcon from "../../../../../assets/icons/warning.svg";
import AddNewItem from "../../../../Shared/Components/AddNewItem/AddNewItem";
import CourseDetailsHeader from "../../../../Shared/Components/CourseDetailsHeader/CourseDetailsHeader";
import CustomButton from "../../../../Shared/Components/CustomButton/CustomButton";
import Input from "../../../../Shared/Components/Input/Input";
import { BasicModal } from "../../../../Shared/Components/Modal/BasicModal";
import SharedModal from "../../../../Shared/Components/SharedModal/SharedModal";
import SubChapterRow from "../SubChapterRow/SubChapterRow";
import { ChapterRowActions } from "./chapterRowActions";

function ChapterRow(props) {
  require("./chapterRow.css");
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const deleteModalRef = useRef();
  const uploadModalRef = useRef();

  const [opened, setOpened] = useState(false);
  const [isLabelEditable, setIsLabelEditable] = useState(false);
  const [toBeChangedText, setToBeChangedText] = useState("");
  const [isChangingText, setIsChangingText] = useState(false);
  const [hasSubmittedSubChapter, setHasSubmittedSubChapter] = useState(false);
  const [isAddingSubChapter, setIsAddingSubChapter] = useState(false);
  const courseProfileContext = useContext(CourseProfileContext);

  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const editChapterLocal = () => {
    const myPromise = new Promise(async (resolve, reject) => {
      setIsChangingText(true);
      if (toBeChangedText != props.chapter.name) {
        editChapter(props.chapter.id, {
          name: toBeChangedText,
          course_id: courseProfileContext.courseID,
          _method: "put",
        })
          .then(async (res) => {
            if (res.status && res.status == 200 && res.data.status) {
              await props.fetchCourse();
            }
            setIsChangingText(false);
            resolve();
          })
          .catch((err) => {
            setIsChangingText(false);
            resolve();
          });
      } else {
        setIsChangingText(false);
        resolve();
      }
    });

    return myPromise;
  };

  const addSubChapterLocally = () => {
    let chapters = [];
    courseProfileContext.chapters.forEach((item) => {
      if (item.id == props.chapter.id) {
        let subChapters = item.sub_chapters;
        subChapters.push({
          emptyField: true,
        });
        item.sub_chapters = subChapters;
      }

      chapters.push(item);
    });

    courseProfileContext.setChapters(chapters);
  };

  const removeSubChapterLocally = (index) => {
    let chapters = [];
    courseProfileContext.chapters.forEach((item, idx) => {
      if (item.id == props.chapter.id) {
        let subChapters = item.sub_chapters.filter((item, idx) => {
          return index != idx;
        });
        item.sub_chapters = subChapters;
      }

      chapters.push(item);
    });

    courseProfileContext.setChapters(chapters);
  };

  const downloadFile = () => {
    exportLessonContent("subChapters", props.chapter.name, "zip", props.chapter.id, courseProfileContext);
    toast.success(
      <span style={{ fontSize: 13, fontWeight: "bold" }}>
        {t("modal.trainer.course.chapter.messages.titleDownload")}
      </span>
    );
  };

  const openUploadModal = () => {
    setShowUploadModal(true);
    uploadModalRef.current.showModal();
  };

  const closeUploadModal = () => {
    setShowUploadModal(false);
    uploadModalRef.current.dismissModal();
  };

  const openDeleteModal = () => {
    setShowDeleteModal(true);
    deleteModalRef.current.showModal();
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    deleteModalRef.current.dismissModal();
  };

  const removeChapter = () => {
    deleteChapter(props.chapter.id)
      .then(() => {
        props.fetchCourse();
        closeDeleteModal();

        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {t("modal.trainer.course.chapter.delete.successDeleted")}
          </span>
        );
      })
      .catch((err) => {
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {t("modal.trainer.course.chapter.delete.successDeleted")}
          </span>
        );
      });
  };

  return (
    <>
      <div className={`chapter-row-container ${opened ? "chapter-row-container-opened" : ""}`}>
        <BasicModal ref={uploadModalRef}>
          {showUploadModal && (
            <UploadModal
              title={t("modal.trainer.course.chapter.modal.title")}
              dismissModal={closeUploadModal}
              type={"subChapters"}
              itemId={props.chapter.id}
              fetchCourse={props.fetchCourse}
            />
          )}
        </BasicModal>
        <BasicModal ref={deleteModalRef}>
          {showDeleteModal && (
            <SharedModal
              icon={WarningIcon}
              title={t("modal.trainer.course.chapter.delete.title")}
              name={props.chapter.name}
              confirmMsg={t("modal.trainer.course.chapter.delete.body")}
              confirmText={t("modal.trainer.course.chapter.delete.delete_aprove")}
              cancelText={t("modal.common.cancel")}
              onConfirm={() => {
                removeChapter();
                closeUploadModal();
              }}
              onCancel={closeDeleteModal}
            />
          )}
        </BasicModal>
        <CourseDetailsHeader
          title={props.chapter.name}
          actions={ChapterRowActions({
            [props.role === "partner" ? "" : "downloadAction"]: downloadFile,
            [props.role === "partner" ? "" : isLabelEditable ? "saveAction" : "editAction"]: async () => {
              if (isLabelEditable) {
                await editChapterLocal();
                setIsLabelEditable(false);
                setToBeChangedText("");
              } else {
                setIsLabelEditable(true);
                setToBeChangedText(props.chapter.name);
              }
            },
            [props.role === "partner" ? "" : "deleteAction"]: openDeleteModal,
            usersAction: () => {
              history.push({
                pathname: `/${props.role ? `${props.role}/` : ""}course/${id}/discussion-board/${props.chapter.id}`,
                state: { id: id, role: props.role },
              });
            },
            assignmentsAction: () => {
              history.push(`/${props.role}/course/${id}/chapter/${props.chapter.id}/assessments`);
            },
          })}
          isLabelEditable={isLabelEditable}
          onChange={(event) => {
            setToBeChangedText(event.target.value);
          }}
          value={toBeChangedText}
          disabled={isChangingText}
          setOpened={setOpened}
          opened={opened}
        />

        {props.chapter.sub_chapters.map((item, index) => {
          if (item.emptyField) {
            return (
              <Formik
                initialValues={{
                  name: "",
                  course_id: parseInt(courseProfileContext.courseID),
                  parent_id: props.chapter.id,
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  try {
                    setSubmitting(true);
                    setHasSubmittedSubChapter(false);
                    let response = await addChapter(values);
                    if (response.status === 201 && response.data.status) {
                      await props.fetchCourse();
                    } else {
                      toast.error(
                        <span style={{ fontSize: 13, fontWeight: "bold" }}>
                          {response.data && response.data.msg ? response.data.msg : "Failure in service"}
                        </span>
                      );
                    }
                    setSubmitting(false);
                    setIsAddingSubChapter(false);
                  } catch (err) {
                    setSubmitting(false);
                    setIsAddingSubChapter(false);
                    toast.error(
                      <span style={{ fontSize: 13, fontWeight: "bold" }}>
                        {err.response.data && err.response.data.msg ? err.response.data.msg : "Failure in service"}
                      </span>
                    );
                  }
                }}
                validateOnChange={hasSubmittedSubChapter}
                validate={(values) => {
                  const errors = {};
                  if (!values.name) {
                    errors.name = t("crud.errors.required");
                  }

                  return errors;
                }}
              >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                  <>
                    <form onSubmit={handleSubmit} className="course-details-form">
                      <Input
                        name="name"
                        type="text"
                        label={t("crud.placeholders.name")}
                        placeholder={t("crud.placeholders.name")}
                        value={values.name}
                        onChange={handleChange}
                        error={errors.name}
                        required
                      />
                      <div className="course-details-actions">
                        <CustomButton
                          value={t("AddNewSubChapter")}
                          colors="#036c77"
                          type="submit"
                          disable={isSubmitting}
                          loading={isSubmitting}
                        />
                        <CustomButton
                          value={t("crud.placeholders.cancel")}
                          colors="#036c77"
                          variant="outlined"
                          type="button"
                          disable={isSubmitting}
                          loading={isSubmitting}
                          action={() => {
                            removeSubChapterLocally(index);
                            setIsAddingSubChapter(false);
                          }}
                        />
                      </div>
                    </form>
                  </>
                )}
              </Formik>
            );
          } else {
            return (
              opened && (
                <SubChapterRow
                  key={index}
                  subChapter={item}
                  index={index}
                  subChapters={props.chapter.sub_chapters}
                  allLessons={props.allLessons}
                  removeSubChapterLocally={removeSubChapterLocally}
                  isAdmin={props.isAdmin}
                  role={props.role}
                  fetchCourse={props.fetchCourse}
                />
              )
            );
          }
        })}
        {opened ? (
          props.role !== "partner" ? (
            <div className="add-new-item-container">
              <AddNewItem
                title={t("AddNewSubChapter")}
                actions={[
                  {
                    label: t("AddNewSubChapter"),
                    onClick: () => {
                      setIsAddingSubChapter(true);
                      addSubChapterLocally();
                    },
                    disabled: isAddingSubChapter,
                  },
                  {
                    label: t("modal.trainer.course.chapter.hover.titleUploadSubChapter"),
                    onClick: openUploadModal,
                    disabled: isAddingSubChapter,
                  },
                ]}
              />
            </div>
          ) : null
        ) : null}
      </div>
    </>
  );
}

export default ChapterRow;
