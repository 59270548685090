import React, {createContext, useState} from "react";

const ChatContext = createContext();

function ChatProvider(props) {
  const [message, setMessage] = useState({});
  const [user, setUser] = useState({});

  return (
    <ChatContext.Provider value={{ message, setMessage, user, setUser }}>
      {props.children}
    </ChatContext.Provider>
  );
}

export { ChatContext, ChatProvider };
