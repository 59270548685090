import { peymentRequestsConstants } from "../../Constants/peymentRequest/peymentRequest.constants";

const initialState = {
  peymentRequests: [],
  peymentRequestsMeta: {},
  peymentRequestsLoading: false,
  peymentRequestsError: null,
  peymentRequestsSuccess: false,

  updatePeymentRequestStatusLoading: false,
  updatePeymentRequestStatusError: null,
  updatePeymentRequestStatusSuccess: false,

  deletePeymentRequestLoading: false,
  deletePeymentRequestError: null,
  deletePeymentRequestSuccess: false,
};

const peymentRequestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case peymentRequestsConstants.GET_PEYMENTREQUESTS_LIST_REQUEST:
      return {
        ...state,
        peymentRequestsLoading: true,
      };
    case peymentRequestsConstants.GET_PEYMENTREQUESTS_LIST_SUCCESS:
      return {
        ...state,
        peymentRequestsLoading: false,
        peymentRequestsSuccess: true,
        peymentRequests: action.payload.peymentRequests.data, //////
        peymentRequestsMeta: action.payload.peymentRequests.meta, /////
      };
    case peymentRequestsConstants.GET_PEYMENTREQUESTS_LIST_REJECTED:
      return {
        ...state,
        peymentRequestsLoading: false,
        peymentRequestsError: action.payload,
        peymentRequestsSuccess: false,
      };

    // DELETE PEYMENTREQUEST
    case peymentRequestsConstants.DELETE_PEYMENTREQUEST_REQUEST:
      return {
        ...state,
        deletePeymentRequestLoading: true,
      };

    case peymentRequestsConstants.DELETE_PEYMENTREQUEST_SUCCESS:
      return {
        ...state,
        deletePeymentRequestLoading: false,
        deletePeymentRequestSuccess: true,
        deletePeymentRequestError: null,

        peymentRequests: state.peymentRequests.filter(
          (peymentRequest) => peymentRequest.id !== action.payload.id
        ),
      };

    case peymentRequestsConstants.DELETE_PEYMENTREQUEST_REJECTED:
      return {
        ...state,
        deletePeymentRequestLoading: false,
        deletePeymentRequestError: action.payload,
        deletePeymentRequestSuccess: false,
      };

    // UPDATE PEYMENTREQUEST STATUS
    case peymentRequestsConstants.UPDATE_PEYMENTREQUEST_STATUS_REQUEST:
      return {
        ...state,
        updatePeymentRequestStatusLoading: true,
      };

    case peymentRequestsConstants.UPDATE_PEYMENTREQUEST_STATUS_SUCCESS:
      return {
        ...state,
        updatePeymentRequestStatusLoading: false,
        updatePeymentRequestStatusSuccess: true,
        updatePeymentRequestStatusError: null,

        peymentRequests: state.peymentRequests.map((peymentRequest) => {
          if (peymentRequest.id === action.payload.id) {
            return {
              ...peymentRequest,
              status: !peymentRequest.status,
            };
          }
          return peymentRequest;
        }),
      };

    case peymentRequestsConstants.UPDATE_PEYMENTREQUEST_STATUS_REJECTED:
      return {
        ...state,
        updatePeymentRequestStatusLoading: false,
        updatePeymentRequestStatusError: action.payload,
        updatePeymentRequestStatusSuccess: false,
      };

    default:
      return state;
  }
};

export default peymentRequestsReducer;
