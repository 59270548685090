import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { TrainingCourseProfileContext } from "../../../../Services/api/toutorials/TrainingCourseProfileContext";
import SkeletonSpinnerOverlay from "../../../Shared/Components/Spinner/SkeletonSpinnerOverlay";

function TrainingCourseTabLayout(props) {
  require("./TrainingCourseProfileTabLayout.css");
  const { t } = useTranslation();
  const trainingCourseProfileContext = useContext(TrainingCourseProfileContext);

  const [tabs, setTabs] = useState(["syllabus", "aboutTrainer", "discussion"]);

  const [selectedTab, setSelectedTab] = useState(0);

  const onTabItemClick = (index) => {
    trainingCourseProfileContext.setTab(index);
    setSelectedTab(index);
  };

  return (
    <div className="training-course-tab-layout-border-bottom">
      {tabs.map((item, index) => {
        return (
          <div
            key={index}
            onClick={() => {
              onTabItemClick(index);
            }}
            className={`${
              index == selectedTab
                ? "training-course-tab-layout-tab training-course-tab-layout-tab-active"
                : "training-course-tab-layout-tab"
            }${index === 1 || index === 2 ? " hide-tap" : ""}`}
          >
            {t(item)}
          </div>
        );
      })}
    </div>
  );
}

export default TrainingCourseTabLayout;
