import { useTheme } from "@mui/material";

const PopUpClose = ({ width, height, fill }) => {
	const theme = useTheme();
	return (
		<svg
			width={width || "24"}
			height={height || "24"}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 22 22"
		>
			<g id="x-circle_2_" data-name="x-circle (2)" transform="translate(-1 -1)">
				<circle
					id="Ellipse_23"
					data-name="Ellipse 23"
					cx="10"
					cy="10"
					r="10"
					transform="translate(2 2)"
					fill="none"
					stroke={fill || theme.palette.error.main}
					stroke-linecap="round"
					stroke-linejoin="round"
					stroke-width="2"
				></circle>
				<line
					id="Line_35"
					data-name="Line 35"
					x1="6"
					y2="6"
					transform="translate(9 9)"
					fill="none"
					stroke={fill || theme.palette.error.main}
					stroke-linecap="round"
					stroke-linejoin="round"
					stroke-width="2"
				></line>
				<line
					id="Line_36"
					data-name="Line 36"
					x2="6"
					y2="6"
					transform="translate(9 9)"
					fill="none"
					stroke={fill || theme.palette.error.main}
					stroke-linecap="round"
					stroke-linejoin="round"
					stroke-width="2"
				></line>
			</g>
		</svg>
	);
};

export default PopUpClose;
