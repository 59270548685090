import React from "react";
import { useTranslation } from "react-i18next";
import logo from "../../../../assets/icons/LoadingLogo.svg";

function SkeletonCardOverlay(props) {
  const { t } = useTranslation();
  require("./SkeletonCard.css");

  return (
    <>
      <div
        style={{
          position: "fixed",
          width: "100%",
          height: props.height ? props.height : "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.7)",
          zIndex: 999999999,
          flexDirection: "column",
          top: props.top ? props.top : 0,
          left: 0,
          // borderRadius: props.borderRadius ? props.borderRadius : props.borderRadius == 0 ? 0 : 20
        }}
      >
        <div className="loading-fade">
          <img src={logo} alt="" style={{ height: "110px", width: "110px" }} />
          <h5
            style={{
              color: "#FFF",
            }}
          >
            {t("Loading")} ...
          </h5>
        </div>
      </div>
    </>
  );
}

export default SkeletonCardOverlay;
