import { partnersListConstant } from "../../Constants/PartnersTebx/partnersTebx.constent";

const initialState = {
  partnersList: [],
  partnersListMeta: {},
  partnersRolesList: [],
  partnerGenders: [],
  partnerCountries: [],
  // systemMedicalFields: [],
  partnerSpecialties: [],
  partnersListLoading: false,
  partnersListError: null,
  partnersListSuccess: false,

  updatePartnerStatusLoading: false,
  updatePartnerStatusError: null,
  updatePartnerStatusSuccess: false,
};

const partnersListReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET SYSTEM USERS LIST
    case partnersListConstant.GET_PARTNERS_LIST_REQUEST:
      return {
        ...state,
        partnersListLoading: true,
      };
    case partnersListConstant.GET_PARTNERS_LIST_SUCCESS:
      return {
        ...state,
        partnersListLoading: false,
        partnersListSuccess: true,
        partnersList: action.payload.users.data,
        partnersListMeta: action.payload.users.meta,
        partnersRolesList: action.payload.roles,
        partnerGenders: action.payload.genders,
        partnerCountries: action.payload.countries,
        // systemMedicalFields: action.payload.medicalFields,
        partnerSpecialties: action.payload.specialties,
      };
    case partnersListConstant.GET_PARTNERS_LIST_REJECTED:
      return {
        ...state,
        partnersListLoading: false,
        partnersListError: action.payload,
        partnersListSuccess: false,
      };

    // UPDATE SYSTEM USER STATUS
    case partnersListConstant.UPDATE_PARTNER_STATUS_REQUEST:
      return {
        ...state,
        updatePartnerStatusLoading: true,
      };
    case partnersListConstant.UPDATE_PARTNER_STATUS_SUCCESS:
      return {
        ...state,
        updatePartnerStatusLoading: false,
        updatePartnerStatusSuccess: true,
        updatePartnerStatusError: null,
        partnersList: state.partnersList.map((systemUser) => {
          if (systemUser.id === action.payload.id) {
            return {
              ...systemUser,
              status: !systemUser.status,
            };
          }
          return systemUser;
        }),
      };
    case partnersListConstant.UPDATE_PARTNER_STATUS_REJECTED:
      return {
        ...state,
        updatePartnerStatusLoading: false,
        updatePartnerStatusError: action.payload,
        updatePartnerStatusSuccess: false,
      };

    default:
      return state;
  }
};

export default partnersListReducer;
