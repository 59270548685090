import { advertisementsListConstants } from "../../Constants/Advertisements/advertisements.constants";

const initialState = {
  advertisementsList: [],
  advertisementsListMeta: {},
  advertisementsListLoading: false,
  advertisementsListError: null,
  advertisementsListSuccess: false,

  activeAdvertisementLoading: false,
  activeAdvertisementError: null,
  activeAdvertisementSuccess: false,

  deleteAdvertisementLoading: false,
  deleteAdvertisementSuccess: false,
  deleteAdvertisementError: null,
};

const advertisementsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case advertisementsListConstants.GET_ADVERTISEMENTS_LIST_REQUEST:
      return {
        ...state,
        advertisementsListLoading: true,
      };
    case advertisementsListConstants.GET_ADVERTISEMENTS_LIST_SUCCESS:
      return {
        ...state,
        advertisementsListLoading: false,
        advertisementsListSuccess: true,
        advertisementsList: action.payload.advertisements.data,
        advertisementsListMeta: action.payload.advertisements.meta,
      };
    case advertisementsListConstants.GET_ADVERTISEMENTS_LIST_REJECTED:
      return {
        ...state,
        advertisementsListLoading: false,
        advertisementsListError: action.payload,
        advertisementsListSuccess: false,
      };

    //  ACTIVE Advertisement
    case advertisementsListConstants.ACTIVE_ADVERTISEMENT_REQUEST:
      return {
        ...state,
        activeAdvertisementLoading: true,
      };

    case advertisementsListConstants.ACTIVE_ADVERTISEMENT_SUCCESS:
      return {
        ...state,
        activeAdvertisementLoading: false,
        activeAdvertisementSuccess: true,
        activeAdvertisementError: null,

        advertisementsList: state.advertisementsList.map((advertisement) =>
          advertisement.id === action.payload.id
            ? { ...advertisement, status: "active" }
            : { ...advertisement }
        ),
      };

    case advertisementsListConstants.ACTIVE_ADVERTISEMENT_REJECTED:
      return {
        ...state,
        activeAdvertisementLoading: false,
        activeAdvertisementError: action.payload,
        activeAdvertisementSuccess: false,
      };
    //  DEACTIVE Advertisement
    case advertisementsListConstants.DEACTIVE_ADVERTISEMENT_REQUEST:
      return {
        ...state,
        activeAdvertisementLoading: true,
      };

    case advertisementsListConstants.DEACTIVE_ADVERTISEMENT_SUCCESS:
      return {
        ...state,
        activeAdvertisementLoading: false,
        activeAdvertisementSuccess: true,
        activeAdvertisementError: null,

        advertisementsList: state.advertisementsList.map((advertisement) =>
          advertisement.id === action.payload.id
            ? { ...advertisement, status: "inactive" }
            : { ...advertisement }
        ),
      };

    case advertisementsListConstants.DEACTIVE_ADVERTISEMENT_REJECTED:
      return {
        ...state,
        activeAdvertisementLoading: false,
        activeAdvertisementError: action.payload,
        activeAdvertisementSuccess: false,
      };
    //  DELETE_ Advertisement
    case advertisementsListConstants.DELETE_ADVERTISEMENT_REQUEST:
      return {
        ...state,
        deleteAdvertisementLoading: true,
      };

    case advertisementsListConstants.DELETE_ADVERTISEMENT_SUCCESS:
      return {
        ...state,
        deleteAdvertisementLoading: false,
        deleteAdvertisementSuccess: true,
        deleteAdvertisementError: null,

        advertisementsList: state.advertisementsList.filter(
          (advertisement) => advertisement.id !== action.payload.id
        ),
      };

    case advertisementsListConstants.DELETE_ADVERTISEMENT_REJECTED:
      return {
        ...state,
        deleteAdvertisementLoading: false,
        deleteAdvertisementError: action.payload,
        deleteAdvertisementSuccess: false,
      };

    default:
      return state;
  }
};

export default advertisementsListReducer;
