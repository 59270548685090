import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { NavLink } from "react-router-dom";
import QuestionTypeEnum from "../../../../../Enums/QuestionTypeEnum";
import { getTraineeExamAnswers } from "../../../../../Services/api/exams/ExamsProvider";
import DragAndDropQuestionAnswer from "../DragAndDropQuestionAnswer/DragAndDropQuestionAnswer";
import EssayQuestionAnswer from "../EssayQuestionAnswer/EssayQuestionAnswer";
import H5PQuestionAnswer from "../H5PQuestionAnswer/H5PQuestionAnswer";
import HotSpotQuestionAnswer from "../HotSpotQuestionAnswer/HotSpotQuestionAnswer";
import MultipleSelectQuestionAnswer from "../MultipleSelectQuestionAnswer/MultipleSelectQuestionAnswer";
import OrderQuestionAnswer from "../OrderQuestionAnswer/OrderQuestionAnswer";
import ScormQuestionAnswer from "../ScormQuestionAnswer/ScormQuestionAnswer";
import SingularSelectQuestionAnswer from "../SingularSelectQuestionAnswer/SingularSelectQuestionAnswer";
import TextQuestionAnswer from "../TextQuestionAnswer/TextQuestionAnswer";
import XAPIQuestionAnswer from "../XAPIQuestionAnswer/XAPIQuestionAnswer";

function TraineeAnswerList(props) {
    require("./TraineeAnswerList.css");

    const {t} = useTranslation();
    const {id, quizable_id, exam_id, trainee_id, response_id} = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [answers, setAnswers] = useState([]);

    const localGetTraineeExamAnswers = async () => {
        try {
            let res = await getTraineeExamAnswers(exam_id, trainee_id, response_id);
            setAnswers(res.data.response[0].answers);
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        localGetTraineeExamAnswers();
    }, [])

    return (
        <>
            <div className={"trainee-exam-answers-canvas"}>
                <div style={{
                    width: '90%',
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flexDirection: "row"
                }}>
                    <div className="admin_label" style={{marginBottom: 30}}>
                        <NavLink
                            to={`/trainer/course/${id}/${props.quizable}/${quizable_id}/assessments`}>{t("trainer.quiz.exams")}</NavLink>
                        <p style={{padding: "0 5px"}}>/</p>
                        <NavLink
                            to={`/trainer/course/${id}/${props.quizable}/${quizable_id}/exam/${exam_id}/grades`}>{t("trainer.quiz.trainee_exam_grades")}</NavLink>
                        <p style={{padding: "0 5px"}}>/</p>
                        {t("trainer.quiz.trainee_answers")}
                    </div>
                </div>
                <Paper variant={"outlined"}
                       sx={{
                           width: '90%',
                           boxShadow: '2px 2px 10px #ddd',
                           height: isLoading ? 500 : 'auto'
                       }}>
                    {
                        isLoading ?
                            <div className={"tajah-question-progress"}>
                                <CircularProgress/>
                            </div> :
                            <div className={"tajah-form-container"} style={{
                                boxShadow: "none",
                                borderRadius: 0
                            }}>
                                {
                                    answers.map(item => {
                                        if (item.question.type_id === QuestionTypeEnum.checkbox) {
                                            return (
                                                <>
                                                    <MultipleSelectQuestionAnswer
                                                        item={item}
                                                    />
                                                    <hr/>
                                                </>
                                            )
                                        } else if (item.question.type_id === QuestionTypeEnum.radio_button) {
                                            return (
                                                <>
                                                    <SingularSelectQuestionAnswer
                                                        item={item}
                                                    />
                                                    <hr/>
                                                </>
                                            )
                                        } else if (item.question.type_id === QuestionTypeEnum.scorm) {
                                            return (
                                                <>
                                                    <ScormQuestionAnswer
                                                        item={item}
                                                    />
                                                    <hr/>
                                                </>
                                            )
                                        } else if (item.question.type_id === QuestionTypeEnum.hotspot) {
                                            return (
                                                <>
                                                    <HotSpotQuestionAnswer
                                                        item={item}
                                                    />
                                                    <hr/>
                                                </>
                                            )
                                        } else if (item.question.type_id === QuestionTypeEnum.h5p) {
                                            return (
                                                <>
                                                    <H5PQuestionAnswer
                                                        item={item}
                                                    />
                                                    <hr/>
                                                </>
                                            )
                                        } else if (item.question.type_id === QuestionTypeEnum.xapi) {
                                            return (
                                                <>
                                                    <XAPIQuestionAnswer
                                                        item={item}
                                                    />
                                                    <hr/>
                                                </>
                                            )
                                        } else if (item.question.type_id === QuestionTypeEnum.drag_and_drop) {
                                            return (
                                                <>
                                                    <DragAndDropQuestionAnswer
                                                        item={item}
                                                    />
                                                    <hr/>
                                                </>
                                            )
                                        } else if (item.question.type_id === QuestionTypeEnum.ordering) {
                                            return (
                                                <>
                                                    <OrderQuestionAnswer
                                                        item={item}
                                                    />
                                                    <hr/>
                                                </>
                                            )
                                        } else if (item.question.type_id === QuestionTypeEnum.text) {
                                            return (
                                                <>
                                                    <TextQuestionAnswer
                                                        item={item}
                                                    />
                                                    <hr/>
                                                </>
                                            )
                                        } else if (item.question.type_id === QuestionTypeEnum.essay_question) {
                                            return (
                                                <>
                                                    <EssayQuestionAnswer
                                                        item={item}
                                                    />
                                                    <hr/>
                                                </>
                                            )
                                        }
                                    })
                                }
                            </div>
                    }
                </Paper>
            </div>
        </>
    )
}

export default TraineeAnswerList;
