import { useTranslation } from "react-i18next";
import FilterBox from "../../../Shared/Components/FilterBox/FilterBox";

import { useEffect, useState } from "react";
const ReceivedGiftFilter = ({
  filterData,
  changeHandler,
  resetHandler,
  currentPage,
  setCurrentPage,
  filterHandler,
  resetFilterHandler,
  courses,
  programs,
  changeHandlerNationalty,
}) => {
  const { t, i18n } = useTranslation();
  const [countries, setCountries] = useState([]);

  const parentTypeOptions = [
    {
      label: t("coupon.label.course"),
      id: 1,
      value: 1,
    },
    {
      label: t("coupon.label.program"),
      id: 2,
      value: 2,
    },
  ];

  const filterFields = [
    {
      id: "parent_type",
      label: t("coupon.label.select_course_or_program"),
      component: "select",
      placeholder: t("coupon.label.select_course_or_program"),
      initialValue: t("coupon.label.select_course_or_program"),
      name: "parent_type",
      value: filterData.parent_type,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: parentTypeOptions || [],
    },

    ...(filterData.parent_type == 1
      ? [
          {
            id: "course_id",
            label: t("coupon.label.select_course"),
            component: "select",
            placeholder: t("coupon.label.select_course"),
            initialValue: t("coupon.label.select_course"),
            name: "course_id",
            value: filterData.course_id,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            options: courses?.length
              ? courses?.map((course) => ({
                  id: course.id,
                  value: course.id,
                  label: course.name,
                }))
              : [],
          },
        ]
      : []),
    ...(filterData.parent_type == 2
      ? [
          {
            id: "course_id",
            label: t("coupon.label.select_program"),
            component: "select",
            placeholder: t("coupon.label.select_program"),
            initialValue: t("coupon.label.select_program"),
            name: "course_id",
            value: filterData.course_id,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            options: programs?.length
              ? programs?.map((program) => ({
                  id: program.id,
                  value: program.id,
                  label: program.name,
                }))
              : [],
          },
        ]
      : []),
    {
      id: "sender_name",
      label: t("gifted_from"),
      component: "input",
      placeholder: t("gifted_from"),
      name: "sender_name",
      value: filterData.sender_name,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },

    {
      id: "reset",
      component: "button",
      type: "button",
      name: "action",
      value: t("general.reset"),
      classes: "col-12 col-md-6 col-xl-2 border-button",
      action: () => {
        resetFilterHandler();
      },
      backgroundColor: "transparent",
      color: "#046c77",
      borderColor: "#046c77",
    },
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("general.search"),
      classes: "col-12 col-md-6  col-xl-2",
      action: () => {
        if (currentPage != 1) {
          setCurrentPage(1);
        } else {
          filterHandler();
        }
      },
    },
  ];

  return <FilterBox fields={filterFields} />;
};

export default ReceivedGiftFilter;
