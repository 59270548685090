import React, { forwardRef } from "react";
import classNames from "classnames";

import styles from "./Draggable.module.css";

export const Draggable = forwardRef(function Draggable(
  {
    axis,
    dragOverlay,
    dragging,
    handle,
    link,
    listeners,
    transform,
    style,
    buttonStyle,
    ...props
  },
  ref
) {
  return (
    <div
      className={`${classNames(
        styles.Draggable,
        dragOverlay && styles.dragOverlay,
        dragging && styles.dragging,
        handle && styles.handle
      )} tw-aspect-[4/3]`}
      style={{
        ...style,
        "--translate-x": `${transform?.x ?? 0}px`,
        "--translate-y": `${transform?.y ?? 0}px`,
      }}
    >
      <div
        {...props}
        aria-label="Draggable"
        data-cypress="draggable-item"
        {...(handle ? {} : listeners)}
        tabIndex={handle ? -1 : undefined}
        ref={ref}
        // style={buttonStyle}
      >
        <img
          src={link}
          className="tw-w-full tw-aspect-[4/3] tw-shrink-0 tw-rounded tw-object-contain"
        />
      </div>
    </div>
  );
});
