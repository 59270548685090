import React, {createContext, useState} from "react";

const ProfileInfoContext = createContext();

function AdminUserProvider(props) {
  const [profileUser, setProfileUser] = useState({});
  const [userPercentage, setUserPercentage] = useState(0);
  const [loading, setLoading] = useState(true);

  return (
    <ProfileInfoContext.Provider
      value={{
        profileUser,
        setProfileUser,
        userPercentage,
        setUserPercentage,
        loading,
        setLoading,
      }}
    >
      {props.children}
    </ProfileInfoContext.Provider>
  );
}

export { ProfileInfoContext, AdminUserProvider };
