import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  addProgramPayment,
  fetchCoursesList,
  fetchProgramPaymentList,
} from "../../../../Redux/Actions/courses/coursesList.action";
import { exportProgramPaymentsList } from "../../../../Services/api/ProgramPaymentList";
import { getCourseInfo } from "../../../../Services/api/courses/courseProvider";
import HomeIcon from "../../../../assets/icons/home.svg";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import { BasicModal } from "../../../Shared/Components/Modal/BasicModal";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import ProgramPaymentListTable from "./ProgramPaymentListTable";
import ProgramPaymentModal from "./ProgramPaymentModal";
import classes from "./programPaymentList.module.css";
import { filterActions } from "./programPaymentListActions";

const ProgramPaymentList = ({ isAdmin }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const role = JSON.parse(localStorage.getItem("roles"))[0];
  const { id } = useParams();
  const programPaymentModalRef = useRef();
  const { programPaymentsListLoading, programPaymentsList, addProgramPaymentSuccess, updateProgramPaymentSuccess } =
    useSelector((state) => state.coursesListReducer);

  const [isExportLoading, setIsExportLoading] = useState(false);
  const [showProgramPaymentModal, setShowProgramPaymentModal] = useState(false);
  const [programInfo, setProgramInfo] = useState({});
  const [paymentsAmount, setPaymentsAmount] = useState(0);

  useEffect(() => {
    dispatch(fetchProgramPaymentList(id));
    dispatch(
      fetchCoursesList({
        programId: id,
        role,
      })
    );
    getCourseInfo(id)
      .then((res) => {
        setProgramInfo(res.data.course);
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
      });
  }, []);

  useEffect(() => {
    let total = 0;
    programPaymentsList.forEach((payment) => {
      total += Number(payment.amount);
    });
    setPaymentsAmount(total);
  }, [programPaymentsList]);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "content_development",
      page: t("sidebar.label.content_development"),
      pagePath: "#",
    },
    {
      id: "programs",
      page: t(`sidebar.label.program`),
      pagePath: "/admin/program/list",
    },
    {
      id: "program_name",
      page: programInfo?.name,
      pagePath: "#",
    },
    {
      id: "program_payment_list",
      page: t(`trainer.program.payment_list`),
      active: true,
    },
  ];

  const exportHandler = async (type) => {
    setIsExportLoading(true);
    try {
      await exportProgramPaymentsList(type, id);
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
    }
  };

  const createProgramPaymentHandler = ({ data }) => {
    dispatch(addProgramPayment(data));
    setShowProgramPaymentModal(false);
    programPaymentModalRef.current.dismissModal();
  };

  const openProgramPaymentModalHandler = () => {
    if (
      programInfo?.status === "Draft" ||
      (programInfo?.status === "Published" && (programInfo?.number_of_users === 0 || programInfo?.is_started === false))
    ) {
      setShowProgramPaymentModal(true);
      programPaymentModalRef.current.showModal();
    } else {
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("courses_list.cannot_add_payment")}</span>);
    }
  };

  useEffect(() => {
    if (addProgramPaymentSuccess || updateProgramPaymentSuccess) {
      dispatch(fetchProgramPaymentList(id));
    }
  }, [addProgramPaymentSuccess, updateProgramPaymentSuccess]);

  return (
    <div className={classes["program__payment__list"]}>
      {isExportLoading && <SkeletonCardOverlay skeletonWidth="100" />}
      <div className="container-fluid">
        <div className={classes["program__payment__list__container"]}>
          {programPaymentsListLoading ? (
            <SkeletonCardOverlay />
          ) : (
            <>
              <div className="row">
                <div className="col-12">
                  <Breadcrumb list={breadcrumbList} />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <MainBox>
                    <MainBoxHead
                      title={t("trainer.program.payment_list")}
                      actions={filterActions({
                        programPaymentsReport: () => {
                          history.push(
                            `/${
                              localStorage.getItem("type") === "accountant" ? "accountant" : "admin"
                            }/program/${id}/payments-report`
                          );
                        },
                        traineePayments: () => {
                          history.push({
                            pathname: `${role === "admin" ? "/admin" : ""}/program/${id}/trainees-payments`,
                            state: { role },
                          });
                        },
                        // addAction: () => openProgramPaymentModalHandler(),
                        addAction: () => {
                          if (Number(programInfo?.price) - Number(paymentsAmount) > 0) {
                            openProgramPaymentModalHandler();
                          } else {
                            toast.error(
                              <span style={{ fontSize: 13, fontWeight: "bold" }}>
                                {t("courses_list.cannot_add_payment_amount")}
                              </span>
                            );
                          }
                        },
                        exportPdf: () => exportHandler("pdf"),
                        exportExcel: () => exportHandler("xlsx"),
                      })}
                    />
                    <ProgramPaymentListTable
                      isAdmin={isAdmin}
                      programId={id}
                      programInfo={programInfo}
                      paymentsAmount={paymentsAmount}
                    />
                  </MainBox>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <BasicModal ref={programPaymentModalRef}>
        {showProgramPaymentModal && (
          <ProgramPaymentModal
            programId={id}
            closeModal={() => {
              setShowProgramPaymentModal(false);
              programPaymentModalRef.current.dismissModal();
            }}
            submitHandler={createProgramPaymentHandler}
            paymentsAmount={paymentsAmount}
            programPrice={programInfo?.price}
            firstPayment={programPaymentsList.length === 0}
            startRegisterDate={programInfo?.start_register_date}
            endRegisterDate={programInfo?.end_register_date}
          />
        )}
      </BasicModal>
    </div>
  );
};

export default ProgramPaymentList;
