import React, { useContext, useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import { AccountantContext } from "./../../Modules/Shared/Components/accountant/AccountantContext";
import { getSponsors } from "./../../Services/api/sponsor/SponsorProvider";

import SkeletonCard from "../Shared/Components/Spinner/SkeletonCard";
import Trainees from "./addTrainee/Trainees";
import "./sponsor.css";

export default function Sponsors(props) {
  const sponsorsContext = useContext(AccountantContext);
  const { t } = useTranslation();
  const history = useHistory();
  const [traineeModal, setTraineeModal] = useState(false);
  const [traineeId, setTraineeId] = useState("");
  const [sponsors, setSponsors] = useState([]);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getSponsors()
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setSponsors(res.data.data);
          setLoading(false)
        }
      })
      .catch((err) => {
        
      });
  }, []);

  const openTraineesModal = (id) => {
    setTraineeId(id);
    setTraineeModal(true);
  };

  const closePopup = () => {
    setLoading(true)
    getSponsors()
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setSponsors(res.data.data);
          setLoading(false)
        }
      })
      .catch((err) => {
        
      });
    setTraineeModal(false);
  }

  return (
    <div>
      {traineeModal && (
        <div style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          alignItems: "100%"
        }}>
          <div
            className="backdrop"
            style={{
              position: "fixed",
            }}
            onClick={() => {
              setTraineeModal(false);
            }}
          />
          <Trainees traineeId={traineeId} onClose={closePopup} />
        </div>
      )}
      <div className="header_profile">
        <div className="container-fluid profile_wrapper">
          <div className="profile_grid">
            <div className="profile_dhead">
              <h3>{t("sponsor.MyTitle")}</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid profile_wrapper">
        <div className="admin_label">
          <NavLink to="/admin">{t("Home")}</NavLink>
          <p style={{ padding: "0 5px" }}>/</p>
          {t("sponsor.MyTitle")}
        </div>
      </div>
      {loading ? <div className="container-fluid"
        style={{
          height: "65vh",
          marginBottom: "60px"
        }}
      >
        <SkeletonCard />
      </div> :
        <div className="container-fluid profile_wrapper" style={{ marginBottom: "60px" }}>
          <div className="table_bank_tran">
            <h3 className="table_header"> {t("sponsor.MyTitle")}</h3>
            <div
              className="table-responsive"
              style={{
                width: "90%",
              }}
            >
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">{t("sponsor.sponsor")}</th>
                    <th scope="col">{t("sponsor.Type")}</th>
                    <th scope="col">{t("sponsor.Date")}</th>
                    <th scope="col">{t("sponsor.Course_Program")}</th>
                    <th scope="col">{t("sponsor.Number")}</th>
                    <th scope="col">{t("sponsor.trainees.title")}</th>
                    <th scope="col">{t("sponsor.add.trainee")}</th>
                  </tr>
                </thead>
                <tbody>
                  {sponsors.map((sponsor, index) => (
                    <tr key={index}>
                      <td>{sponsor.name_en}</td>
                      <td>{sponsor.program == null ? "Course" : "Program"}</td>
                      <td>{sponsor.accreditation_at}</td>
                      <td>
                        {sponsor.program == null
                          ? sponsor.course.name
                          : sponsor.program.title}
                      </td>
                      <td>{sponsor.actual_trainee_count}</td>
                      <td>
                        <div className="release_icon">
                          <button
                            className={"btn btn-success"}
                            style={{
                              backgroundColor: "#006d77",
                              borderColor: "#006d77",
                            }}
                            onClick={() => openTraineesModal(sponsor.id)}
                          >
                            {t("sponsor.trainees.show")}
                          </button>
                        </div>
                      </td>
                      <td>
                        <div className="release_icon">
                          <button
                            className={"btn btn-success"}
                            style={{
                              backgroundColor: "#006d77",
                              borderColor: "#006d77",
                            }}
                            onClick={() => {
                              sponsorsContext.setSponsor(sponsor);
                              history.push(
                                "/accountant/sponsor/addtraineebyemail"
                              );
                            }}
                          >
                            {t("sponsor.add.trainee")}
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>}
    </div>
  );
}
