import { Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import SkeletonCard from "../Shared/Components/Spinner/SkeletonCard";
import { AccountantContext } from "./../../Modules/Shared/Components/accountant/AccountantContext";
import { addSponsor, assignTrainees, getSponsorsData, } from "./../../Services/api/sponsor/SponsorProvider";
import ModeSponsor from "./ModeSponsor";
import "./sponsor.css";

export default function AddSponsor(props) {
  const sponsorsContext = useContext(AccountantContext);
  const { t } = useTranslation();
  const history = useHistory();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [addModal, setAddModal] = useState(false);
  const [courses, setCourses] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [sponsors, setSponsors] = useState([]);
  const [trainees, setTrainees] = useState([]);
  const [programId, setProgramId] = useState("");
  const [partnerId, setPartnerId] = useState("");
  const [selectedTrainees, setSelectedTrainees] = useState([]);
  const [values, setValues] = useState("");
  const [formSponsors, setFormSponsors] = useState({});

  const [cares, setTypeCare] = useState([
    { id: "program", name_en: "Program Care", name_ar: "رعاية برنامج" },
    { id: "course", name_en: "Course Care", name_ar: "رعاية مساق" },
  ]);
  const [type, setType] = useState("");
  useEffect(() => {
    sponsorsContext.setFormSponsors(sponsorsContext.formSponsors);
    sponsorsContext.setSponsor(sponsorsContext.sponsor);
    getSponsorsData()
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setCourses(res.data.response.courses);
          setPrograms(res.data.response.programs);
          setSponsors(res.data.response.partners);
        }
      })
      .catch((err) => {
        
      });
    let trainees = JSON.parse(localStorage.getItem("trainees"));
    if (trainees == null) {
    } else {
      for (let i = 0; i < trainees.length; i++) {
        if (typeof trainees[i] == "string") {
          let obj = { email: trainees[i] };
          trainees.push(obj);
        }
      }
      let traineesArr = [];
      for (let i = 0; i < trainees.length; i++) {
        if (typeof trainees[i] !== "string") {
          traineesArr.push(trainees[i]);
        }
      }
      setTrainees(traineesArr);
      let ids = [];
      for (let i = 0; i < trainees.length; i++) {
        if (typeof trainees[i].id != undefined) {
          ids.push(trainees[i].id);
        }
      }
      ids.filter((item) => item !== undefined);
      let emails = [];
      for (let i = 0; i < trainees.length; i++) {
        if (typeof trainees[i] == "string") {
          emails.push(trainees[i]);
        }
      }
      let fullyArr = ids.filter((item) => item !== undefined).concat(emails);
      setSelectedTrainees(fullyArr);
    }
  }, []);

  const formatDate = (d) => {
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };


  const openModalAdd = () => {
    sponsorsContext.setFormSponsors(sponsorsContext.formSponsors);
    sponsorsContext.setSponsor(sponsorsContext.sponsor);
    setAddModal(true);
  };
  setTimeout(() => {
    setIsLoading(false);
  }, 500);

  const removeTraineeFromList = (trainee, index) => {
    trainees.splice(index, 1);
    localStorage.setItem("trainees", JSON.stringify(trainees));
    setTrainees(JSON.parse(localStorage.getItem("trainees")));
    setSelectedTrainees(trainees.map((item) => (
      item.id
    )));
  };


  const assignTraineeForSponsor = () => {
    let data = {
      partner_id: sponsorsContext.sponsor.id,
      trainees: selectedTrainees,
    };
    assignTrainees(data)
      .then((res) => {
        toast.success(
          <span style={{ fontSize: 14 }}>
            {t("sponsor.messages.addTrainees")}
          </span>
        );
        localStorage.removeItem("trainees");
        history.push("/accountant/sponsors")
        window.location.reload();
      })
      .catch((err) => {
        
      });
  };

  return (
    <div>
      {addModal && (
        <>
          <div
            className="backdrop"
            style={{
              position: "fixed",
            }}
            onClick={() => {
              setAddModal(false);
            }}
          />
          <ModeSponsor type={type} programId={programId} />
        </>
      )}
      <div className="header_profile">
        <div className="container-fluid profile_wrapper">
          <div className="profile_grid">
            <div className="profile_dhead">
              <h3>{t("sponsor.add.title")}</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid profile_wrapper">
        <div className="admin_label">
          <NavLink to="/admin">{t("Home")}</NavLink>
          <p style={{ padding: "0 5px" }}>/</p>
          {t("sponsor.add.title")}
        </div>
      </div>
      <div className="container-fluid profile_wrapper">
        <div className="table_bank_tran">
          <h3 className="table_header">
            {t("sponsor.add.title")}
          </h3>

          {isLoading ? (
            <div
              style={{
                height: "65vh",
              }}
            >
              <SkeletonCard />
            </div>
          ) : (
            <div className="admin_input_sponsor">
              <Formik
                initialValues={{
                  user_id:
                    sponsorsContext.formSponsors.user_id == null
                      ? ""
                      : sponsorsContext.formSponsors.user_id,
                  type:
                    sponsorsContext.formSponsors.type == null
                      ? "course"
                      : sponsorsContext.formSponsors.type,
                  accreditation_at:
                    sponsorsContext.formSponsors.accreditation_at == null
                      ? ""
                      : sponsorsContext.formSponsors.accreditation_at,
                  trainee_count:
                    sponsorsContext.formSponsors.trainee_count == null
                      ? ""
                      : sponsorsContext.formSponsors.trainee_count,
                  trainees: selectedTrainees,
                  program_id:
                    sponsorsContext.formSponsors.program_id == null
                      ? ""
                      : sponsorsContext.formSponsors.program_id,
                  course_id:
                    sponsorsContext.formSponsors.course_id == null
                      ? ""
                      : sponsorsContext.formSponsors.course_id,
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  let dataToBeUploaded = {
                    ...values,
                  };
                  if (dataToBeUploaded.type == "program") {
                    delete dataToBeUploaded.course_id;
                  } else {
                    delete dataToBeUploaded.program_id;
                  }

                  dataToBeUploaded.accreditation_at = formatDate((values.accreditation_at))
                  dataToBeUploaded.user_id = +values.user_id
                  dataToBeUploaded.course_id = +values.course_id
                  dataToBeUploaded.trainees = selectedTrainees

                  sponsorsContext.setFormSponsors(values);
                  sponsorsContext.setFormSponsors(sponsorsContext.formSponsors);
                  if (addModal == true) {
                  } else {
                    let response = await addSponsor(dataToBeUploaded);
                    if (JSON.parse(localStorage.getItem("trainees")) == null) {
                    } else {
                      history.push("/accountant/sponsors")
                      localStorage.removeItem("trainees");
                      setTrainees(JSON.parse(localStorage.getItem("trainees")));
                      toast.success(
                        <span style={{ fontSize: 14 }}>
                          {t("sponsor.messages.addTrainees")}
                        </span>
                      );
                    }
                  }
                }}
                validateOnChange={hasSubmitted}
                validate={(values) => {
                  setHasSubmitted(true);
                  const errors = {}
                  if (!values.user_id){
                    errors.user_id = t("crud.errors.required");
                  }
                  if (!values.accreditation_at){
                    errors.accreditation_at = t("crud.errors.required");
                  }
                  if (!values.type){
                    errors.type = t("crud.errors.required");
                  }
                  if (values.type == "course" && values.course_id == 0){
                    errors.type_id = t("crud.errors.required");
                  }
                  if (values.type == "program" && values.program_id == 0){
                    errors.type_id = t("crud.errors.required");
                  }
                  if (!values.trainee_count){
                    errors.trainee_count = t("crud.errors.required");
                  }
                  return errors
                }}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  handleBlur,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit} className="admin_add_form">
                    <div className="row">
                      {sponsorsContext.sponsor == null ? <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-4">
                            <label
                              htmlFor="user_id"
                              className="admin_add_label"
                            >
                              {t("sponsor.add.Sponsor_partner")}
                            </label>
                            <select
                              name=""
                              id="user_id"
                              className="admin_add_input"
                              value={values.user_id}
                              onChange={handleChange}
                            >
                              <option></option>
                              {sponsors.map((sponsor, index) => (
                                <option key={sponsor.id} value={sponsor.id}>
                                  {sponsor.name_en}
                                </option>
                              ))}
                            </select>
                            <p className={"form-input-error-space"}>
                              {errors.user_id ? errors.user_id : null}
                            </p>
                          </div>

                          <div className="col-md-4">
                            <label
                              htmlFor="accreditation_at"
                              className="admin_add_label"
                            >
                              {t("sponsor.add.date_approval_care")}
                            </label>
                            <DatePicker
                              selected={values.accreditation_at}
                              onChange={(date) => {
                                setFieldValue("accreditation_at", date);
                              }}
                              className="admin_add_input"
                              dateFormat={"yyyy-MM-dd"}
                              placeholderText={t(
                                "sponsor.add.date_approval_care"
                              )}
                              minDate={new Date()}
                            />
                            <p className={"form-input-error-space"}>
                              {errors.accreditation_at
                                ? errors.accreditation_at
                                : null}
                            </p>
                          </div>
                        </div>
                      </div> : null}
                      {sponsorsContext.sponsor == null ?
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-4">
                              <label htmlFor="type" className="admin_add_label">
                                {t("sponsor.add.type_care")}
                              </label>
                              <select
                                name="type"
                                id="type"
                                className="admin_add_input"
                                value={values.type}
                                onChange={(e) => {
                                  setType(e.target.value);
                                  setFieldValue("type", e.target.value);
                                }}
                              >
                                <option></option>
                                {cares.map((care, index) => (
                                  <option value={care.id} key={care.id}>
                                    {care.name_ar}
                                  </option>
                                ))}
                              </select>
                              <p className={"form-input-error-space"}>
                                {errors.type ? errors.type : null}
                              </p>
                            </div>
                            <div className="col-md-4">
                              <label htmlFor="name" className="admin_add_label">
                                {t("sponsor.add.course_name")}
                              </label>
                              <select
                                name="program_id"
                                id="program_id"
                                className="admin_add_input"
                                value={type == "program" ? values.program_id : values.course_id}
                                onChange={(e) => {
                                  setProgramId(e.target.value);
                                  if (type == "program") {
                                    setFieldValue("program_id", e.target.value);
                                  } else {
                                    setFieldValue("course_id", e.target.value);
                                  }
                                }}
                              >
                                <option value={0}></option>
                                {type == "program" ? (
                                  <>
                                    {programs.map((program, index) => (
                                      <option key={program.id} value={program.id}>
                                        {program.name}
                                      </option>
                                    ))}
                                  </>
                                ) : (
                                  <>
                                    {courses.map((course, index) => (
                                      <option key={course.id} value={course.id}>
                                        {course.name}
                                      </option>
                                    ))}
                                  </>
                                )}
                              </select>
                              <p className={"form-input-error-space"}>
                                {errors.type_id ? errors.type_id : null}
                              </p>
                            </div>
                          </div>
                        </div> : null}
                        {sponsorsContext.sponsor == null ?
                      <div className="col-md-4">
                        <label htmlFor="name" className="admin_add_label">
                          {t("sponsor.add.number_trainees")}
                        </label>
                        <input
                          type="number"
                          name="trainee_count"
                          className="admin_add_input"
                          onChange={handleChange}
                          value={values.trainee_count}
                        />
                        <p className={"form-input-error-space"}>
                          {errors.trainee_count ? errors.trainee_count : null}
                        </p>
                      </div> : null}
                      <div className="col-md-12 list_of_trainer">
                        <div className="row">
                          <div className="col-md-6">
                            <h4>{t("sponsor.add.List_trainees")}</h4>
                          </div>
                          <div className="col-md-6 btn_flex">
                            <button
                              className={"btn btn-success"}
                              style={{
                                backgroundColor: "#006d77",
                                borderColor: "#006d77",
                              }}
                              onClick={() => openModalAdd()}
                            >
                              {t("sponsor.add.trainee")}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        className="table-responsive"
                        style={{
                          width: "100%",
                        }}
                      >
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th scope="col">{t("sponsor.add.name")}</th>
                              <th scope="col">{t("sponsor.add.E_Mail")}</th>
                              <th scope="col">{t("sponsor.add.Action")}</th>
                            </tr>
                          </thead>
                          {trainees && (
                            <tbody>
                              {trainees.map((trainee, index) => (
                                <tr key={index}>
                                  <td>{trainee.name_en}</td>
                                  <td>{trainee.email}</td>
                                  <td>
                                    <div className="release_icon">
                                      <i
                                        className="fas fa-trash-alt font_awesome_edit cursor-pointer"
                                        onClick={() =>
                                          removeTraineeFromList(trainee, index)
                                        }
                                      ></i>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          )}
                        </table>
                      </div>
                      {sponsorsContext.sponsor == null ? (
                        <div className="col-md-3">
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="admin_add_button"
                          >
                            {t("sponsor.add.save")}
                          </button>
                        </div>
                      ) : (
                        <div className="col-md-3">
                          <button
                            onClick={assignTraineeForSponsor}
                            className="admin_add_button"
                          >
                            {t("sponsor.trainee.assign")}
                          </button>
                        </div>
                      )}
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
