import React from "react";
import Categories from "./NewHome/Categories/Categories";
import HomeFooter from "./NewHome/HomeFooter/HomeFooter";
import LatestCourses from "./NewHome/LatestCourses/LatestCourses";
import NewHomeAbout from "./NewHome/NewHomeAbout/NewHomeAbout";
import NewHomeExplore from "./NewHome/NewHomeExplore/NewHomeExplore";
import NewHomeFeatures from "./NewHome/NewHomeFeatures/NewHomeFeatures";
import NewHomeSearch from "./NewHome/NewHomeSearch/NewHomeSearch";
import NewTrainers from "./NewHome/NewTrainers/NewTrainers";
import CarosalHomePage from "./CarosalHomePage/CarosalHomePage";
import AboutUsSection from "./AboutSection/AboutUsSection";

const newHomePage = () => {
  return (
    <>
      <CarosalHomePage />
      {/* <NewHomeSearch /> */}
      <AboutUsSection />
      <LatestCourses />
      {/* <NewHomeAbout /> */}
      {/* <NewHomeFeatures /> */}
      <NewTrainers />
      <Categories />
      <NewHomeExplore />
      {/* <HomeFooter /> */}
    </>
  );
};

export default newHomePage;
