import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import Countdown from "react-countdown";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  answerExam,
  getExam,
  joinExam,
} from "../../../Services/api/Trainee/TraineeProvider";
import { openExternalLinkApi } from "../../../Services/api/exams/ExamsProvider";
import ArrowIcon from "../../../assets/icons/arrow.png";
import { ReactComponent as CalendarIcon } from "../../../assets/icons/calendar-icon.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/crose.svg";
import { Lang } from "../../../utils";
import QuestionTypes from "../../Admin/Assessments/QuestionTypes";
import SkeletonCard from "../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../Shared/Components/Spinner/SkeletonCardOverlay";
import classes from "./ListExams.module.css";

function CheckIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 142.307 139.981"
      className={className}
    >
      <g
        id="check-circle_2_"
        data-name="check-circle (2)"
        transform="translate(4.013 4.082)"
      >
        <path
          id="Path_213"
          data-name="Path 213"
          d="M129.809,60.059v5.879a63.9,63.9,0,1,1-37.9-58.409"
          transform="translate(0 0)"
          fill="none"
          stroke="#046c77"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="12"
        />
        <path
          id="Path_214"
          data-name="Path 214"
          d="M92.076,4l-63.9,63.968L9,48.8"
          transform="translate(37.733 10.814)"
          fill="none"
          stroke="#046c77"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="12"
        />
      </g>
    </svg>
  );
}

function Exam({ quizable }) {
  require("./exams.css");
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { courseId, quizableId, id } = useParams();
  const fromRef = useRef();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [examContent, setExamContent] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [countDownDate, setCountDownDate] = useState(null);
  const [join, setJoin] = useState({});
  const [sendTimeout, setSendTimeout] = useState(null);
  const [canBack, setCanBack] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [unComplitedQuestions, setUnComplitedQuestions] = useState([]);
  const [step, setStep] = useState(0);

  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  useEffect(() => {
    getExam(id)
      .then(async (res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setExamContent(res.data.quiz);
          setStep(1);
        }
      })
      .catch((err) => {
        history.push(`/training-courses/${courseId}/content`);
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response?.data?.msg}
          </span>
        );
      });
    return () => {
      clearTimeout();
    };
  }, []);

  useEffect(() => {
    if (step == 2 && !isLoaded) modalHandler(examContent.period);
  }, [step]);

  const modalHandler = (examPeriod = 1) => {
    toast.success(
      <span style={{ fontSize: 13, fontWeight: "bold" }}>
        {t("good_luck")} - {t("remaining_attempts")} (
        {examContent.answer_attempts - examContent.my_answered_count - 1})
      </span>
    );
    // noBackRef.current.dismissModal();
    const period = examPeriod * 60000;
    setCountDownDate(Date.now() + period);
    setSendTimeout(
      setTimeout(() => {
        fromRef.current?.handleSubmit();
      }, period)
    );
    setIsLoaded(true);
  };

  // const breadcrumbList = [
  //   {
  //     id: "home",
  //     page: <img src={HomeIcon} alt="" />,
  //     pagePath: "/",
  //   },
  //   {
  //     id: t("Courseoutline"),
  //     page: t("Courseoutline"),
  //     pagePath:
  //       quizable == "chapter"
  //         ? `/training-courses/${courseId}/content`
  //         : `/training-courses/${courseId}/content/${location.state.chapterId}/${location.state.supChapterId}/lessons`,
  //   },
  //   {
  //     id: t("trainer.quiz.exams"),
  //     page: t("trainer.quiz.exams"),
  //     pagePath: `/trainees/course/${courseId}/${quizable}/${quizableId}/exams`,
  //   },
  //   ...(examContent?.id
  //     ? [
  //         {
  //           id: examContent?.id,
  //           page: examContent?.title,
  //           active: true,
  //         },
  //       ]
  //     : []),
  // ];

  const towDig = (num) =>
    num.toLocaleString("en-US", {
      minimumIntegerDigits: num < 100 ? 2 : null,
      useGrouping: false,
    });

  const openExternalLink = async () => {
    try {
      let response = await openExternalLinkApi();
      if (response.status == 200) {
        window.open(response.data.url, "_blank");
      }
    } catch (e) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };

  function joinToExam() {
    setIsUpdating(true);
    joinExam(id)
      .then(async (res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setExamContent(res.data.quiz);
          setJoin(res.data.join);
          setQuestions(
            res.data.quiz.random == "1"
              ? shuffle(res.data.quiz.questions)
              : res.data.quiz.questions
          );
          setCanBack(res.data.quiz.can_back == "1" ? true : false);
          setStep(2);
          setIsUpdating(false);
        }
      })
      .catch((err) => {
        setIsUpdating(false);
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.msg
              ? err.response.data.msg
              : "Failure in service"}
          </span>
        );
      });
  }

  return (
    <div className="container-fluid">
      {/* <div className="row">
        <div className="col-12 sm:tw-px-2 tw-py-8">
          <Breadcrumb list={breadcrumbList} />
        </div>
      </div> */}

      {isUpdating ? <SkeletonCardOverlay skeletonWidth="100" /> : <div></div>}
      {step > 0 && examContent && examContent != null ? (
        <>
          {step == 1 ? (
            <div className="tw-bg-white tw-shadow tw-rounded tw-p-8 tw-space-y-10 tw-mt-8">
              <div className={classes["back"]} onClick={() => history.goBack()}>
                <div
                  className={`${classes["back-icon"]} ${
                    i18n.language === "en" ? "ltr:tw-rotate-180" : ""
                  }`}
                >
                  <img src={ArrowIcon} alt="back" />
                </div>
                <div className={classes["back-text"]}>{t("back")}</div>
              </div>
              <div className="md:tw-flex tw-items-center tw-justify-between">
                <div className="md:tw-flex tw-items-center tw-justify-center tw-space-s-4">
                  <div className="tw-space-y-2 tw-py-2">
                    <div className="sm:tw-flex tw-items-center tw-space-s-2">
                      <div className="tw-text-lg sm:tw-text-3xl tw-text-teal-700 tw-text-center">
                        {examContent.title}
                      </div>
                    </div>
                    <div className="tw-space-y-2 sm:tw-flex sm:tw-items-center sm:tw-space-s-4 sm:tw-space-y-0 tw-text-teal-500">
                      <div className="tw-flex tw-items-center tw-justify-center sm:tw-justify-start tw-space-s-2">
                        <CalendarIcon className="tw-h-4" />
                        <div>{`${new Date(
                          examContent.start_date
                        ).toLocaleDateString()} ${t("to")} ${new Date(
                          examContent.end_date
                        ).toLocaleDateString()}`}</div>
                      </div>
                      <div className="tw-text-teal-700">
                        {t("attempts_number")}: {examContent.answer_attempts} /{" "}
                        {examContent.my_answered_count}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tw-space-y-3">
                <div className="tw-text-lg tw-font-bold tw-text-teal-700">
                  {t("quiz_description")}
                </div>
                <div
                  className="tw-text-gray-500"
                  dangerouslySetInnerHTML={{
                    __html: examContent.description,
                  }}
                />
              </div>
              <div>
                <button
                  type="button"
                  onClick={
                    examContent.monitored ? openExternalLink : joinToExam
                  }
                  className="tw-bg-teal-700 tw-py-3 tw-px-16 tw-rounded tw-text-white"
                >
                  {t("start_quiz")}
                </button>
              </div>
            </div>
          ) : step == 2 || step == 3 ? (
            <Formik
              innerRef={(f) => (fromRef.current = f)}
              initialValues={{
                quiz_id: id,
                join_id: join.id,
                questions: questions.map((question) => ({
                  id: question.id,
                  answers: [],
                })),
              }}
              onSubmit={async (values, { setErrors }) => {
                let data = {
                  ...values,
                  questions: values.questions.map((v) => ({
                    ...v,
                    answers: v.answers.length ? v.answers : null,
                  })),
                };
                try {
                  clearTimeout(sendTimeout);
                  const res = await answerExam(data);

                  if (res.status && res.status == 200 && res.data.status) {
                    toast.success(
                      <span style={{ fontSize: 13, fontWeight: "bold" }}>
                        شكرا لاتمامك الاختبار
                      </span>
                    );
                    setStep(4);
                  } else {
                    throw res;
                  }
                } catch (error) {
                  history.push(`/training-courses/${courseId}/content`);
                  toast.error(
                    <span style={{ fontSize: 13, fontWeight: "bold" }}>
                      لم نتمكن من تسجيل اجاباتك
                    </span>
                  );
                }
              }}
              validate={(values) => {
                if (countDownDate <= Date.now()) return {};
                setHasSubmitted(true);
                const errors = {};

                let questionsErrors = [];
                values.questions.forEach((question, index) => {
                  if (!question.answers.length && step == 2) {
                    questionsErrors[index] = t("crud.errors.required");
                  }

                  let answersErrors = [];
                  question.answers.map((answer, answerIndex) => {
                    let error;
                    if (!answer.answer && answer.answer !== 0) {
                      error = t("crud.errors.required");
                    }

                    if (error) {
                      answersErrors[answerIndex] = {
                        answer: error,
                      };
                    }
                  });
                  if (answersErrors.length) {
                    questionsErrors[index] = { answers: answersErrors };
                  }
                });

                if (questionsErrors.length) {
                  errors.questions = questionsErrors;
                }
                return errors;
              }}
              validateOnChange={hasSubmitted}
            >
              {({
                setFieldValue,
                setValues,
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                handlevalidate,
                isSubmitting,
                resetForm,
                submitForm,
                validateForm,
              }) => (
                <form onSubmit={handleSubmit}>
                  {step == 2 ? (
                    <div className="tw-bg-white tw-shadow tw-rounded tw-p-8 tw-space-y-8 tw-mt-8">
                      <div
                        className={classes["back"]}
                        onClick={() => history.goBack()}
                      >
                        <div
                          className={`${classes["back-icon"]} ${
                            i18n.language === "en" ? "ltr:tw-rotate-180" : ""
                          }`}
                        >
                          <img src={ArrowIcon} alt="back" />
                        </div>
                        <div className={classes["back-text"]}>{t("back")}</div>
                      </div>
                      <div className="tw-flex tw-items-center tw-justify-between">
                        <div className="tw-flex tw-flex-wrap tw-items-center tw-space-s-2">
                          <div className="tw-flex tw-flex-wrap tw-items-center tw-space-s-2 tw-text-lg sm:tw-text-2xl tw-font-bold tw-text-teal-600">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: questions[questionIndex].title,
                              }}
                            />
                            <div>{`(${
                              questions[questionIndex].type[
                                i18n.language == Lang.AR ? "name_ar" : "name_en"
                              ]
                            })`}</div>
                          </div>
                        </div>
                        <div className="tw-py-2 tw-px-4 tw-text-lg sm:tw-text-2xl tw-font-bold tw-text-teal-600 tw-rounded-full tw-border-2 tw-border-gray-300">
                          {countDownDate ? (
                            <Countdown
                              date={countDownDate}
                              renderer={(props) => (
                                <div>
                                  {towDig(props.hours)}:{towDig(props.minutes)}:
                                  {towDig(props.seconds)}
                                </div>
                              )}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <div className="tw-overflow-x-auto inner-scrollbar tw-pb-4">
                        {/* {questions.map((question, index) => (
                      <div
                        key={index}
                        className={questionIndex == index ? "" : "tw-hidden"}
                      > */}
                        <QuizQuestion
                          key={questionIndex}
                          questionIndex={questionIndex}
                          question={questions[questionIndex]}
                          values={values.questions[questionIndex]}
                          errors={errors}
                          setFieldValue={setFieldValue}
                        />
                        {/* </div>
                    ))} */}
                      </div>

                      <div className="tw-flex tw-items-center tw-justify-between">
                        <div className="tw-flex tw-items-center tw-space-s-6">
                          <button
                            disabled={isSubmitting}
                            type="button"
                            onClick={() => {
                              return questionIndex < questions.length - 1 &&
                                (!unComplitedQuestions.length ||
                                  unComplitedQuestions.at(-1) != questionIndex)
                                ? setQuestionIndex(
                                    unComplitedQuestions.length &&
                                      unComplitedQuestions.find(
                                        (i) => i > questionIndex
                                      )
                                      ? unComplitedQuestions.find(
                                          (i) => i > questionIndex
                                        )
                                      : questionIndex + 1
                                  )
                                : validateForm(values).then((errors) => {
                                    if (errors?.questions?.length) {
                                      let arr = [];
                                      errors.questions.forEach(
                                        (element, index) => {
                                          arr.push(index);
                                        }
                                      );

                                      setUnComplitedQuestions(arr);
                                      setStep(3);
                                    } else {
                                      handleSubmit();
                                    }
                                  });
                            }}
                            className="tw-bg-teal-700 disabled:tw-bg-teal-700/50 tw-py-3 tw-px-16 tw-rounded tw-text-white"
                          >
                            {questionIndex < questions.length - 1 &&
                            (!unComplitedQuestions.length ||
                              unComplitedQuestions.at(-1) != questionIndex)
                              ? t("next")
                              : t("submit")}
                          </button>
                          <div className="tw-flex tw-items-center tw-space-s-2">
                            <div className="tw-font-bold">
                              {questionIndex + 1}
                            </div>
                            <div className="tw-text-gray-400">/</div>
                            <div className="tw-text-gray-400">
                              {questions.length}
                            </div>
                          </div>
                        </div>
                        {canBack &&
                        questionIndex > 0 &&
                        (!unComplitedQuestions.length ||
                          unComplitedQuestions.find(
                            (i) => i < questionIndex
                          )) ? (
                          <button
                            type="button"
                            onClick={() =>
                              setQuestionIndex(
                                unComplitedQuestions.length
                                  ? [...unComplitedQuestions]
                                      .reverse()
                                      .find((i) => i < questionIndex)
                                  : questionIndex - 1
                              )
                            }
                            className="tw-flex tw-items-center tw-space-s-3 tw-text-teal-500 tw-border-2 tw-border-teal-500 tw-py-1 tw-w-40 tw-justify-center tw-rounded"
                          >
                            <div className="tw-py-3 rtl:tw-rotate-180">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 16.162 16.99"
                                className="tw-h-3 tw-w-3"
                              >
                                <g
                                  id="arrow-down_6_"
                                  data-name="arrow-down (6)"
                                  transform="matrix(-0.017, 1, -1, -0.017, 20.353, -3.236)"
                                >
                                  <line
                                    id="Line_42"
                                    data-name="Line 42"
                                    y2="14"
                                    transform="translate(12 5)"
                                    fill="none"
                                    stroke="#24b3b9"
                                    strokeLinecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                  />
                                  <path
                                    id="Path_215"
                                    data-name="Path 215"
                                    d="M19,12l-7,7L5,12"
                                    fill="none"
                                    stroke="#24b3b9"
                                    strokeLinecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                  />
                                </g>
                              </svg>
                            </div>
                            <div>{t("previous")}</div>
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="tw-bg-white tw-shadow tw-rounded tw-p-8 tw-py-8 tw-flex tw-flex-col tw-items-center tw-justify-center tw-space-y-8 tw-mt-8">
                      <div className="tw-h-20 tw-aspect-square">
                        <CloseIcon className="tw-h-20 tw-w-20" />
                      </div>
                      <div className="tw-text-xl tw-font-bold tw-text-red-600">
                        {t(
                          "You have not answered all of the following questions"
                        )}
                      </div>
                      <div className="tw-flex tw-items-center tw-mx-auto tw-space-s-4 tw-py-4 tw-max-w-full tw-overflow-x-auto inner-scrollbar">
                        {unComplitedQuestions.map((qi) => (
                          <button
                            key={qi}
                            type="button"
                            onClick={() => {
                              setQuestionIndex(qi);
                              setStep(2);
                            }}
                            className="tw-flex tw-items-center tw-justify-center tw-h-10 tw-aspect-square tw-p-2 tw-rounded-lg tw-bg-white tw-border tw-border-gray-300 tw-text-gray-600"
                          >
                            {qi + 1}
                          </button>
                        ))}
                      </div>
                      <div className="tw-flex tw-items-center tw-justify-center tw-space-s-4">
                        <button
                          type="button"
                          disabled={isSubmitting}
                          onClick={() => handleSubmit()}
                          className="tw-bg-teal-700 disabled:tw-bg-teal-700/50 tw-py-3 tw-w-40 tw-rounded tw-text-white"
                        >
                          {t("submit")}
                        </button>
                        <button
                          type="button"
                          disabled={isSubmitting}
                          onClick={() => {
                            setQuestionIndex(unComplitedQuestions[0]);
                            setStep(2);
                          }}
                          className="tw-border-2 tw-border-teal-600 tw-text-center disabled:tw-border-teal-600/50 tw-py-3 tw-px-16 tw-rounded tw-text-teal-600 disabled:tw-text-teal-600/50 enabled:hover:tw-bg-teal-600 enabled:hover:!tw-text-white"
                        >
                          {t("back")}
                        </button>
                      </div>
                    </div>
                  )}
                </form>
              )}
            </Formik>
          ) : (
            <div className="tw-bg-white tw-shadow tw-rounded tw-p-8 tw-flex tw-flex-col tw-items-center tw-justify-center tw-space-y-8 tw-mt-8">
              <div className="tw-h-20 tw-aspect-square">
                <CheckIcon />
              </div>
              <div className="tw-text-xl tw-font-bold tw-text-teal-700">
                {t("trainee.quiz.default_thanks_message")}
              </div>
              <div>
                <div
                  onClick={() => history.goBack()}
                  className="tw-bg-teal-700 tw-py-3 tw-w-40 tw-rounded tw-text-center tw-text-white hover:!tw-text-white cursor-pointer"
                >
                  {t("back")}
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div
          style={{
            height: "65vh",
            width: "100%",
          }}
        >
          <SkeletonCard />
        </div>
      )}
    </div>
  );
}

function QuizQuestion({
  questionIndex,
  question,
  values,
  errors,
  setFieldValue,
}) {
  const questionType = QuestionTypes.find(
    (item) => question.type_id == item.type
  );

  return (
    <div className="tw-space-y-4">
      <div
        className="tw-max-w-full"
        dangerouslySetInnerHTML={{ __html: question.text }}
      ></div>
      <questionType.viewComponent
        questionIndex={questionIndex}
        question={question}
        values={values}
        errors={errors}
        start={true}
        setFieldValue={setFieldValue}
      />
    </div>
  );
}

export default Exam;
