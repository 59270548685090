import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import IconsTooltip from "../../../Components/tooltips/IconsTooltip";
import DeleteModal from "../../../Modals/DeleteModal/DeleteModal";
import { deleteDepartment, listCourseDepartments } from "../../../Services/api/courses/courseProvider";
import deleteIcon from "../../../assets/icons/delete.svg";
import editIcon from "../../../assets/icons/pen.svg";
import userIcon from "../../../assets/icons/user.svg";
import { BasicModal } from "../../Shared/Components/Modal/BasicModal";
import SkeletonCard from "../../Shared/Components/Spinner/SkeletonCard";

function ListDepartments() {
  require("./department.css");
  const { t } = useTranslation();
  const history = useHistory();
  const [departments, setDepartments] = useState([]);
  const [departmentId, setDepartmentId] = useState("");
  const [loading, setLoading] = useState(true);

  const fetchAllDepartment = () => {
    return new Promise((resolve, reject) => {
      listCourseDepartments()
        .then((res) => {
          if (res.status && res.status == 200 && res.data.status) {
            setDepartments(res.data.data);
            setLoading(false);
            resolve();
          } else {
            reject("Error");
          }
        })
        .catch((err) => {
          
        });
    });
  };

  useEffect(() => {
    fetchAllDepartment();
  }, []);

  const editHandler = (id) => {
    history.push(`/admin/department/edit/${id}`);
  };

  const assignUsersToDepartment = (id) => {
    history.push(`/admin/department/${id}/assign/users`);
  };

  const deleteHandler = (id) => {
    deleteModalRef.current.showModal();
    setDepartmentId(id);
  };

  const deleteRow = async (id) => {
    try {
      await deleteDepartment(id);
      await fetchAllDepartment();
      deleteModalRef.current.dismissModal();
    } catch (err) {
      deleteModalRef.current.dismissModal();
      alert(err.response.data.msg);
    }
  };

  const modalHandler = () => deleteModalRef.current.dismissModal();
  const deleteModalRef = useRef();
  return (
    <>
      <BasicModal ref={deleteModalRef}>
        <DeleteModal
          label={t("coupon.label.delete_aprove")}
          title={t("modal.department.delete.title")}
          deleteMsg={t("modal.department.delete.body")}
          refuse={modalHandler}
          accept={() => deleteRow(departmentId)}
          deleteModalRef={deleteModalRef}
        />
      </BasicModal>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="admin_label">
              <NavLink to="/admin">{t("admin.label.admin_label")}</NavLink>
              <p style={{ padding: "0 5px" }}>/</p>
              {t("admin.label.list_department")}
            </div>
            {loading == false ? (
              <div className="table_bank_tran">
                <div className="admin_label_card">
                  <div className="">{t("departments")}</div>
                  <div>
                    <button
                      className={"btn btn-success"}
                      style={{
                        backgroundColor: "#006d77",
                        borderColor: "#006d77",
                        display: "flex",
                        flexDirection: "row",
                        borderRadius: 10,
                      }}
                      onClick={() => {
                        history.push("/admin/department/add");
                      }}
                    >
                      <i style={{ color: "white", fontSize: 15 }} className="far fa-plus"></i>
                      <span style={{ color: "white" }}>{t("addDepartment")}</span>
                    </button>
                  </div>
                </div>
                <div
                  className="table-responsive"
                  style={{
                    width: "90%",
                  }}
                >
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">{t("crud.list.name_ar")}</th>
                        <th scope="col">{t("crud.list.name_en")}</th>
                        <th scope="col">{t("crud.list.description_ar")}</th>
                        <th scope="col">{t("crud.list.description_en")}</th>
                        <th scope="col">{t("crud.list.release")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {departments?.departments?.data.map((item) => {
                        return (
                          <tr key={item.id}>
                            <th scope="row">{item.id}</th>
                            <td>{item.name_ar}</td>
                            <td>{item.name_en}</td>
                            <td>{item.description_ar}</td>
                            <td>{item.description_en}</td>
                            <td>
                              <div className="release_icon">
                                <IconsTooltip
                                  title={t("modal.survey.buttons.users")}
                                  content={
                                    <img
                                      style={{ cursor: "pointer" }}
                                      onClick={() => assignUsersToDepartment(item.id)}
                                      src={userIcon}
                                      alt=""
                                    />
                                  }
                                />
                                <IconsTooltip
                                  title={t("modal.survey.buttons.edit")}
                                  content={
                                    <img
                                      style={{ cursor: "pointer", marginRight: 20, marginLeft: 20 }}
                                      onClick={() => editHandler(item.id)}
                                      src={editIcon}
                                      alt=""
                                    />
                                  }
                                />

                                <IconsTooltip
                                  title={t("modal.survey.buttons.delete")}
                                  content={
                                    <img
                                      style={{ cursor: "pointer" }}
                                      onClick={() => deleteHandler(item.id)}
                                      src={deleteIcon}
                                      alt=""
                                    />
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div
                className="mt-5"
                style={{
                  height: "65vh",
                }}
              >
                <SkeletonCard />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ListDepartments;
