import { Rating } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import { IconsTooltip } from "../../../../Components";
import { getTrainersRate } from "../../../../Services/api/Roles/RolesProvider";
import i18n from "../../../../i18n/i18n";
import { Lang } from "../../../../utils";
import { BasicModal } from "./../../../Shared/Components/Modal/BasicModal";
import SkeletonCard from "./../../../Shared/Components/Spinner/SkeletonCard";
import TrainerModal from "./TrainerModal";

function Trainer() {
  require("./trainer.css");
  const { t } = useTranslation();
  const history = useHistory();
  const [trainersRate, setTrainersRate] = useState([]);
  const [ratings, setRatings] = useState([]);
  const [courses, setCourses] = useState([]);
  const [trainers, setTrainers] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchAllTrainersRate = (trainerId = null) => {
    return new Promise((resolve, reject) => {
      getTrainersRate(trainerId)
        .then((res) => {
          if (res.status && res.status == 201 && res.data.status) {
            setTrainersRate(res.data.trainers);
            setLoading(false);
            resolve();
          } else {
            reject("Error");
          }
        })
        .catch((err) => {
          
        });
    });
  };


  useEffect(()=>{
      fetchAllTrainersRate(null)
  },[])

  const handleDetailsModal = (item) => {
    setTrainers(item)
    trainerRateModalRef.current.showModal();
  };

  const trainerRateModalRef = useRef();

  return (
    <>
      <BasicModal ref={trainerRateModalRef}>
                    <TrainerModal
                        label={t("rate.label.rates")}
                        title={t("rate.label.rates")}
                        courses={trainers}
                        trainerRateModalRef={trainerRateModalRef}
                        handleReportsModal={handleDetailsModal}
                    />
      </BasicModal>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="admin_label">
              <NavLink to="/admin">{t("admin.label.admin_label")}</NavLink>
              <p style={{ padding: "0 5px" }}>/</p>
              {t("sidebar.label.trainer_ratings")}
            </div>
            {loading == false ? (
              <div className="table_bank_tran">
                <div className="admin_label_card">
                  <div className="">{t("sidebar.label.trainer_ratings")}</div>
                </div>
                <div className="table-responsive" style={{
                  width: "90%"
                }}>
                {trainersRate.length == 0 ? (
                    <p style={{textAlign:'center'}}>{t('report.label.degree.notFound')}</p>
                  ):(
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">{t("rate.label.img")}</th>
                        <th scope="col">{t("rate.label.name")}</th>
                        <th scope="col">{t("rate.label.rate")}</th>
                        <th scope="col">{t("rate.label.edit")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {trainersRate.map((item,index) => {
                        return (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>
                            <img
                              src={`${item.img}`}
                              alt=""
                              className="trainer-img"
                            />
                          </td>
                          <td className="trainer-name">{i18n.language == Lang.AR ? item.name_ar : item.name_en}</td>
                          <td>
                              <Rating
                              value={item.rateing}
                              readOnly
                              />
                         </td>
                            <td>
                              <div className="release_icon">
                            <IconsTooltip
                              title={t('modal.rate.buttons.data')}
                              content={<i
                                className="fal fa-eye"
                                onClick={()=>handleDetailsModal(item)}
                                style={{marginLeft:5,marginRight:5,backgroundColor:'white',
                                borderWidth:0,fontSize:20,color:'black',cursor:'pointer'}}
                            ></i>}
                            />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  )}
                </div>
              </div>
            ) : (
              <div
                className="mt-5"
                style={{
                  height: "65vh",
                }}
              >
                <SkeletonCard />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Trainer;
