import { useTranslation } from "react-i18next";
import FilterIcon from "../../../../assets/icons/filter.svg";
import PdfIcon from "../../../../assets/icons/pdf.svg";
import AddIcon from "../../../../assets/icons/plus.svg";
import XlsIcon from "../../../../assets/icons/xls.svg";

export const filterActions = ({ showFilter, exportPdf, exportExcel, addAction, viewAllDepartmentsLabel ,viewAllDepartmentsAction }) => {

  const actionsList = [
    {
      id: "type",
      type: "button",
      label: viewAllDepartmentsLabel,
      color: "#8599B6",
      action: viewAllDepartmentsAction,
    },
    {
      id: "filter",
      icon: <img src={FilterIcon} alt="" />,
      action: showFilter,
    },
    {
      id: "add",
      icon: <img src={AddIcon} alt="" />,
      theme: "add",
      action: addAction,
    },
    {
      id: "pdf",
      icon: <img src={PdfIcon} alt="" />,
      theme: "pdf",
      action: exportPdf,
    },
    {
      id: "xls",
      icon: <img src={XlsIcon} alt="" />,
      theme: "xls",
      action: exportExcel,
    },
 
  ];
  return actionsList;
};
