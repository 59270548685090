import React from "react";
import {NavLink} from "react-router-dom";
import logo from "../../../assets/image/logo.png";
import hour from "../../../assets/image/hour.png";
import {useTranslation} from "react-i18next";

function ProgramCard(props) {
  require("./ProgramCard.css")
  const { t } = useTranslation();

  return (
    <div className="program_main_card">
      <div
        className="card_img"
        style={
          props.programInfo.cover
            ? { backgroundImage: "url(" + props.programInfo.cover + ")" }
            : {}
        }
      >
        <div className="courses_name">
          <div className="logo_hover">
            <img src={logo} alt="" className="logo_hover_width" />
          </div>
          <div className="">
            {props.programInfo.title}
          </div>
        </div>
      </div>

      <div className="card_price">
        {t("price")} : {props.programInfo.price} {t("Rial")}
      </div>

      <div className="card_hour">
        <div className="">
          {`${props.programInfo.courses.length} ${t("Trainingcourses")}`}
        </div>
        <div className="card_center">
          <img src={hour} alt="" className="img_hour" />
          <div className="">
            {t("hour") + " " + props.programInfo.hours_no}
          </div>
        </div>
      </div>
      <div className="courses_card_button">
        {localStorage.getItem('token') == null ? (
         <NavLink
           to={`/login`}
           className="courses_card_button_in"
           >
           <button className="courses_card_button_in_link"> {t("startnow")} </button>
         </NavLink>
        ):(
          <NavLink
          to={`/program/${props.programInfo.id}`}
          className="courses_card_button_in"
        >
          <button className="courses_card_button_in_link"> {t("startnow")} </button>
        </NavLink>
        )}
      </div>
    </div>
  );
}

export default ProgramCard;
