import React from "react";
import {AboutUsProvider} from "../../Services/api/toutorials/aboutusContext";
import About from "./About";

function AboutUs(props) {
  return (
    <AboutUsProvider>
      <About />
    </AboutUsProvider>
  );
}

export default AboutUs;
