import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import { CourseContext } from "../../../../../Services/api/courses/courseContext";
import { joinFreeCourse } from "../../../../../Services/api/courses/courseProvider";
import Lang from "../../../../../assets/icons/languege.png";
import Level from "../../../../../assets/icons/level.png";
import ArrowIcon from "../../../../../assets/icons/right-arrow-white.svg";
import Seat from "../../../../../assets/icons/seatWhite.png";
import warning from "../../../../../assets/icons/warning-green.svg";
import { getLocaleTime } from "../../../../../utils/utcToLocal";
import CustomButton from "../../../../Shared/Components/CustomButton/CustomButton";
import { BasicModal } from "../../../../Shared/Components/Modal/BasicModal";
import SharedModal from "../../../../Shared/Components/SharedModal/SharedModal";
import SkeletonCardOverlay from "../../../../Shared/Components/Spinner/SkeletonCardOverlay";
import StarsRating from "../../../../Shared/Components/StarsRating/StarsRating";
import Title from "../../../../Shared/Components/Title/Title";
import WarningModal from "../../../../Shared/Components/WarningModal/WarningModal";
import classes from "./trainingCourseContent.module.css";

export default function TrainingCourseContentHeader({ setSubscription, userSCGHS, inProgram, backPath }) {
  const { t } = useTranslation();
  const history = useHistory();
  const { id, programID } = useParams();
  const token = localStorage.getItem("token");
  const roles = JSON.parse(localStorage.getItem("roles"));
  const freeCourseModalRef = useRef();
  const redirectToLoginModalRef = useRef();
  const courseDetailsContext = useContext(CourseContext);
  const course = courseDetailsContext.courseDetails;
  const [showFreeCourseModal, setShowFreeCourseModal] = useState(false);
  const [showRedirectToLoginModal, setShowRedirectToLoginModal] = useState(false);
  const [isUserJoined, setIsUserJoined] = useState(false);
  const [cannotRegisterBadge, setCannotRegisterBadge] = useState(false);
  const [rejectedBadge, setRejectedBadge] = useState(false);
  const [loading, setLoading] = useState(false);

  const [width, setWidth] = useState(0);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWidth]);

  useEffect(() => {
    if (course.payment_status === "Rejected") {
      setRejectedBadge(t("payment_rejected_message"));
    }
    if (!isUserJoined) {
      if (course.trainee_status === false && (course.is_joined_free || course.is_assigned || course.is_purchased)) {
        setCannotRegisterBadge(t(`trainee_blocked_${course.is_program ? "program" : "course"}`));
      } else if (course.is_refunded) {
        setCannotRegisterBadge(t("payment_refunded_message"));
      } else if (course.payment_status === "Waiting") {
        setCannotRegisterBadge(t("waiting_to_accept_message"));
      } else if (course.is_scfhsv && !userSCGHS) {
        setCannotRegisterBadge(t("scfhsv_message"));
      } else if (
        course.payment_status === "approved" &&
        moment(new Date()).isBefore(getLocaleTime(new Date(course.start_date)))
      ) {
        setCannotRegisterBadge(t(`${course.is_program ? "program" : "course"}_not_started`));
      } else if (
        course.start_register_date &&
        moment(getLocaleTime(new Date(course.start_register_date))).isAfter(moment())
      ) {
        setCannotRegisterBadge(t("registration_not_started"));
      } else if (
        course.end_register_date &&
        moment(getLocaleTime(new Date(course.end_register_date))).isBefore(moment())
      ) {
        setCannotRegisterBadge(t("registration_ended"));
      } else if (course.participators - course.number_of_users <= 0) {
        setCannotRegisterBadge(t("no_seats_available"));
      } else if (!course.registable) {
        setCannotRegisterBadge(t("prerequest_msg"));
      } else {
        setCannotRegisterBadge(false);
      }
    } else {
      setCannotRegisterBadge(false);
    }
  }, [course, userSCGHS, isUserJoined]);

  useEffect(() => {
    setIsUserJoined(
      (course.is_joined_free || course.is_assigned || course.is_purchased || course.is_gifted) &&
        (course.mode === "Paid" && (course.is_purchased || course.is_gifted)
          ? course.payment_status === "Approved"
          : true) &&
        course.trainee_status === true &&
        (course.is_refunded && !course.is_assigned
          ? course.refund_status === "Refused" || course.refund_status === "Waiting"
            ? true
            : false
          : true)
    );
  }, [course]);
  // console.log(isUserJoined);

  const displayLevel = (level) => {
    if (level === 1) {
      return t("levels.beginner");
    } else if (level === 2) {
      return t("levels.medium");
    } else if (level === 3) {
      return t("levels.advanced");
    }
  };

  const displayLanguage = (language) => {
    if (language === "ar") {
      return t("arabic");
    } else if (language === "en") {
      return t("english");
    }
  };

  const boxesList = [
    {
      id: 1,
      data: `${displayLevel(Number(course.level))} - ${course.number_hours} ${t("hours")}`,
      icon: Level,
    },
    ...(!inProgram
      ? [
          {
            id: 2,
            data: `${course.participators - course.number_of_users} ${t("general.seat_available")} / ${
              course.participators
            }`,
            icon: Seat,
          },
        ]
      : []),
    ...(!course.language
      ? []
      : [
          {
            id: 3,
            data: displayLanguage(course.language),
            icon: Lang,
          },
        ]),
  ];

  const joinFreeCourseAPI = () => {
    setLoading(true);
    joinFreeCourse(course.id, course.is_program ? "program" : "course")
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setSubscription(true);
          closeFreeCourseModal();
          toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t(res.data.msg)}</span>);
        }
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.msg}</span>);
        closeFreeCourseModal();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const openFreeCourseModal = () => {
    setShowFreeCourseModal(true);
    freeCourseModalRef.current.showModal();
  };

  const closeFreeCourseModal = () => {
    setShowFreeCourseModal(false);
    freeCourseModalRef.current.dismissModal();
  };

  const openRedirectToLoginModal = () => {
    setShowRedirectToLoginModal(true);
    redirectToLoginModalRef.current.showModal();
  };

  const closeRedirectToLoginModal = () => {
    setShowRedirectToLoginModal(false);
    redirectToLoginModalRef.current.dismissModal();
  };

  return (
    <div className={classes.headerContainer}>
      {loading && <SkeletonCardOverlay />}
      <div
        className={classes["training-course-back"]}
        onClick={() => {
          if (backPath == "training-courses") {
            history.push(`/training-courses`);
          } else if (backPath === "content") {
            history.push(`/training-courses/${id}/content`);
          } else if (backPath === "training-programs") {
            history.push(`/training-programs`);
          } else if (backPath === "program") {
            history.push(`/training-programs/${programID}/details`);
          } else if (backPath === "received-coupons") {
            history.push(`/private-profile/received-coupons`);
          } else if (backPath === "received-coupons-programCourses") {
            history.push(`/private-profile/received-coupons/program/${programID}/details`);
          }
        }}
      >
        <div className={classes["training-course-back-icon"]}>
          <img src={ArrowIcon} alt="back" />
        </div>
        <div className={classes["training-course-back-text"]}>{t("back")}</div>
      </div>
      <div className={`container-fluid ${classes["headerDitalsWrapper"]}`}>
        <div className={`row ${classes.rowFlex}`}>
          <div className={classes.headerContent}>
            <div className={classes.headerImage}>
              <img src={course.img} alt="..." className={classes.image} />
            </div>
            <div className={classes["header-container"]}>
              <div>
                <div style={{ display: "flex", gap: 10, marginTop: 20 }}>
                  {inProgram || isUserJoined ? (
                    <div style={{ height: "60px" }}></div>
                  ) : (
                    <>
                      {course?.offers && course.offers[0] ? (
                        <div className={classes["offer-badge"]}>
                          <span className={classes["offer-badge-off"]}>{t("general.off")}</span>
                          <span className={classes["offer-badge-number"]}>
                            {course.offers[0].value +
                              " " +
                              (Number(course.offers[0].mechanism) === 1 ? t("general.rs") : "%")}
                          </span>
                        </div>
                      ) : null}
                      <div className={classes["price-container"]}>
                        {course.mode === "Paid" ? (
                          <p className={classes["price_title"]}>{t("giftCourse.label.price")}</p>
                        ) : null}
                        {course?.offers?.length > 0 && course.offers[0].user_can_use_now ? (
                          <span className={classes["old-price"]}>
                            {course.price} {t("trainer.rs")}
                          </span>
                        ) : null}
                        <label className={classes.price}>
                          {course.mode === "Paid" ? (
                            <>
                              <span className={classes["price-number"]}>
                                {course?.offers[0]
                                  ? Number(course.offers[0].mechanism) === 1
                                    ? Number(course.price) - Number(course.offers[0].value)
                                    : Number(course.price) -
                                      (Number(course.price) * Number(course.offers[0].value)) / 100
                                  : course.price}
                              </span>{" "}
                              <span className={classes.cur}>{t("trainer.rs")}</span>
                            </>
                          ) : (
                            <div style={{ display: "flex", gap: 5 }}>
                              <p className={classes["price-label"]}>{t("general.free")}</p>
                              <span className={classes["price-number"]}>
                                0.00 <span className={classes.cur}>{t("trainer.rs")}</span>
                              </span>
                            </div>
                          )}
                        </label>
                      </div>
                    </>
                  )}
                  {inProgram
                    ? (cannotRegisterBadge && (
                        <div className={classes["cannot-register-badge"]}>
                          <span>{cannotRegisterBadge}</span>
                        </div>
                      ),
                      rejectedBadge && (
                        <div className={classes["cannot-register-badge"]}>
                          <span>{rejectedBadge}</span>
                        </div>
                      ))
                    : null}
                </div>
                <div className={classes.mb40}>
                  <div className={classes.title}>
                    <Title title={course.name} titleColor="#fff" fontSize={24} />
                    <div className={classes["flip-star"]}>
                      <StarsRating rating={course.ratings_avg_stars || 0} cutStyle={classes["star-font-size"]} />
                    </div>
                  </div>
                  <div>
                    <p style={{ color: "#fff" }}>{course.brief_description}</p>
                  </div>

                  <div className={classes["calender-container-phone"]}>
                    <p>{`${moment(course.start_date).format("DD/MM")} ${t("to")} ${moment(course.end_date).format(
                      "DD/MM"
                    )}`}</p>
                  </div>
                </div>
              </div>
              <div className={classes["bottom-container"]}>
                <div className={classes["type-container"]}>
                  {boxesList.map((box, index) => (
                    <div className={classes.type} key={box.id}>
                      <img
                        src={box.icon}
                        alt=""
                        style={{
                          width: "22px",
                        }}
                      />
                      <span className={classes["d-flex"]}>{box.data}</span>
                    </div>
                  ))}
                </div>
                {!inProgram ? (
                  <div className={classes["btn-container"]}>
                    <CustomButton
                      value={
                        isUserJoined
                          ? t("show_content")
                          : course.payment_status === "Waiting"
                          ? t("waiting_approve")
                          : t("enroll")
                      }
                      colors={width < 1000 ? "#fff" : "#24b3b9"}
                      type={"button"}
                      action={() => {
                        // localStorage.setItem("prev_page", "");
                        if (isUserJoined) {
                          if (
                            moment(new Date()).isAfter(getLocaleTime(new Date(course.start_date))) &&
                            moment(new Date()).isBefore(getLocaleTime(new Date(course.end_date)))
                          ) {
                            return history.push(`/training-courses/${course.id}/content`);
                          } else if (moment(new Date()).isBefore(getLocaleTime(new Date(course.start_date)))) {
                            return toast.error(
                              <span style={{ fontSize: 13, fontWeight: "bold" }}>
                                {course.is_program ? t("program_not_started") : t("course_not_started")}
                              </span>
                            );
                          } else if (moment(new Date()).isAfter(getLocaleTime(new Date(course.end_date)))) {
                            return toast.error(
                              <span style={{ fontSize: 13, fontWeight: "bold" }}>
                                {course.is_program ? t("program_ended") : t("course_ended")}
                              </span>
                            );
                          }
                        }
                        if (!token) {
                          return openRedirectToLoginModal();
                        }
                        if (!roles?.includes("trainee")) {
                          return toast.error(
                            <span style={{ fontSize: 13, fontWeight: "bold" }}>{t("registration_not_allowed")}</span>
                          );
                        }
                        if (course.mode === "Free") {
                          openFreeCourseModal();
                        } else if (course.mode === "Paid") {
                          history.push(`/${course.is_program ? "program" : "course"}/${course.id}/payment`);
                        }
                      }}
                      disable={
                        isUserJoined && course.trainee_status ? false : !course.registable || cannotRegisterBadge
                      }
                      classes={classes.btn}
                    />
                    {course.mode === "Paid" && !course.offers[0]?.user_can_use_now && (
                      <>
                        <CustomButton
                          value={course.is_program ? t("gift_program") : t("gift_course_btn")}
                          colors={width < 1000 ? "#fff" : "#24b3b9"}
                          type={"button"}
                          classes={`${classes.btn}`}
                          disable={
                            moment(getLocaleTime(new Date(course.start_register_date))).isAfter(moment()) ||
                            moment(getLocaleTime(new Date(course.end_register_date))).isBefore(moment()) ||
                            course.participators - course.number_of_users <= 0
                          }
                          action={() => {
                            if (!token) {
                              return openRedirectToLoginModal();
                            }
                            if (course.mode === "Paid") {
                              history.push(`/${course.is_program ? "program" : "course"}/${course.id}/gift`);
                            }
                          }}
                        />
                        <CustomButton
                          value={t("gift_coupon")}
                          colors={width < 1000 ? "#fff" : "#24b3b9"}
                          type={"button"}
                          classes={`${classes.btn}`}
                          disable={
                            moment(getLocaleTime(new Date(course.start_register_date))).isAfter(moment()) ||
                            moment(getLocaleTime(new Date(course.end_register_date))).isBefore(moment()) ||
                            course.participators - course.number_of_users <= 0
                          }
                          action={() => {
                            if (!token) {
                              return openRedirectToLoginModal();
                            }
                            if (course.mode === "Paid") {
                              history.push(`/${course.is_program ? "program" : "course"}/${course.id}/gift-coupon`);
                            }
                          }}
                        />
                      </>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes["calender-container"]}>
        <p>{`${t("date")} ${t("from")} ${moment(course.start_date).format("DD/MM/YYYY")} ${t("to")} ${moment(
          course.end_date
        ).format("DD/MM/YYYY")}`}</p>
      </div>
      <BasicModal ref={freeCourseModalRef}>
        {showFreeCourseModal && (
          <SharedModal
            title={t("trainer.course.enroll")}
            name={course.name}
            cancelText={t("general.cancel")}
            confirmMsg={t("trainer.course.enroll_free_course_msg")}
            confirmText={t("enroll")}
            onConfirm={joinFreeCourseAPI}
            onCancel={closeFreeCourseModal}
          />
        )}
      </BasicModal>
      <BasicModal ref={redirectToLoginModalRef}>
        {showRedirectToLoginModal && (
          <WarningModal
            img={warning}
            label={t("general.alert")}
            description={course.is_program ? t("program_loginFirst") : t("course_loginFirst")}
            dismissText={t("general.dismiss")}
            dismissColor="#DF4B43"
            successText={t("general.login")}
            successColor="#26b3b9"
            action={() => {
              closeRedirectToLoginModal();
              history.push("/login");
            }}
            dismiss={closeRedirectToLoginModal}
          />
        )}
      </BasicModal>
    </div>
  );
}
