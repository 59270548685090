import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import SkeletonCard from "../../Shared/Components/Spinner/SkeletonCard";
import {
  deleteProgram,
  fetchProgram,
} from "../../../Services/api/program/programContext";
import { toast } from "react-toastify";
import DeleteModal from "../../../Modals/DeleteModal/DeleteModal";
import { BasicModal } from "../../Shared/Components/Modal/BasicModal";
import SkeletonCardOverlay from "../../Shared/Components/Spinner/SkeletonCardOverlay";
import IconTooltips from "../../../Components/tooltips/IconsTooltip";
import editIcon from "../../../assets/icons/pen.svg";
import deleteIcon from "../../../assets/icons/delete.svg";

function ProgramList() {
  require("./Program.css");
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [programs, setPrograms] = useState([]);
  const [toBeDeletedProgramId, setToBeDeletedProgramId] = useState(null);
  const [isPerformingBackgroundOperation, setIsPerformingBackgroundOperation] =
    useState(false);

  const getPrograms = () => {
    const myPromise = new Promise((resolve, reject) => {
      fetchProgram().then(
        (res) => {
          setPrograms(res.data.data.programs);
          setLoading(false);
          resolve();
        },
        (error) => {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {t("programs.failed_loading")}
            </span>
          );
          reject();
        }
      );
    });

    return myPromise;
  };

  useEffect(() => {
    getPrograms();
  }, []);

  useEffect(() => {
    if (!loading) {
      //Here I'm calculating the height of the head and body in order to
      //Affect the relative element in manner of height.
      //The hardcoded "40" value is for the margin between the head and the body.
      document.getElementById("content-section").style.height =
        (
          document.getElementById("content-body-section").offsetHeight +
          document.getElementById("content-header-bar").offsetHeight +
          40
        ).toString() + "px";
    }
  }, [loading]);

  const deleteRow = async (id) => {
    try {
      deleteModalRef.current.dismissModal();
      setIsPerformingBackgroundOperation(true);
      await deleteProgram(id);
      await getPrograms();
      setIsPerformingBackgroundOperation(false);
    } catch (err) {
      deleteModalRef.current.dismissModal();
      setIsPerformingBackgroundOperation(false);

      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {err.response.status !== 500
            ? err.response.data.msg
            : t("programs.failed_deleting")}
        </span>
      );
    }
  };

  const deleteHandler = async (id) => {
    await setToBeDeletedProgramId(id);
    deleteModalRef.current.showModal();
  };

  const modalHandler = () => deleteModalRef.current.dismissModal();
  const deleteModalRef = useRef();

  return (
    <>
      <BasicModal ref={deleteModalRef}>
        <DeleteModal
          label={t("coupon.label.delete_aprove")}
          title={t("modal.program.delete.title")}
          deleteMsg={t("modal.program.delete.body")}
          refuse={modalHandler}
          accept={() => deleteRow(toBeDeletedProgramId)}
          deleteModalRef={deleteModalRef}
        />
      </BasicModal>
      <div className="container-fluid" id={"content-section"}>
        <div className="row" id={"content-header-bar"}>
          <div className="col-12">
            <div className="admin_label">
              <NavLink to="/admin">{t("admin.label.admin_label")}</NavLink>
              <p style={{ padding: "0 5px" }}>/</p>
              {t("program")}
            </div>
            {loading == false ? (
              <div
                className="table_bank_tran"
                id={"content-body-section"}
                style={{
                  position: "absolute",
                }}
              >
                {isPerformingBackgroundOperation ? (
                  <SkeletonCardOverlay borderRadius={20} />
                ) : null}
                <div className="admin_label_card">
                  <div className="">{t("program")}</div>
                  <div>
                    <button
                      className={"btn btn-success"}
                      style={{
                        backgroundColor: "#006d77",
                        borderColor: "#006d77",
                        display: "flex",
                        flexDirection: "row",
                        borderRadius: 10,
                      }}
                      onClick={() => {
                        history.push("/admin/program/add");
                      }}
                    >
                      <i
                        style={{ color: "white", fontSize: 15 }}
                        className="far fa-plus"
                      ></i>
                      <span style={{ color: "white" }}>
                        {t("programs.table.addProgram")}
                      </span>
                    </button>
                  </div>
                </div>
                <div
                  className="table-responsive"
                  style={{
                    width: "90%",
                  }}
                >
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">{t("programs.table.programName")}</th>
                        <th scope="col">{t("programs.table.startDate")}</th>
                        <th scope="col">{t("programs.table.endDate")}</th>
                        <th scope="col">{t("crud.list.release")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {programs.map((program) => {
                        return (
                          <tr key={program.id}>
                            <th scope="row">{program.id}</th>
                            <td>{program.title}</td>
                            <td>{program.start_date}</td>
                            <td>{program.end_date}</td>
                            <td>
                              <div className="release_icon">
                                <IconTooltips
                                  title={t("modal.coupon.buttons.edit")}
                                  content={
                                    <img  
                                    style={{cursor:'pointer'}}
                                    onClick={() =>
                                      history.push(
                                        "/admin/program/edit/" + program.id
                                      )
                                    } src={editIcon} alt=""/>
                                  }
                                />

                                <IconTooltips
                                  title={t("modal.coupon.buttons.delete")}
                                  content={
                                    <img  style={{cursor:'pointer'}} onClick={() => deleteHandler(program.id)} src={deleteIcon} alt=""/>
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div
                className="mt-5"
                style={{
                  height: "65vh",
                }}
              >
                <SkeletonCard />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProgramList;
