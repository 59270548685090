import { Formik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import EduGoalsModal from "../../../../../Modals/EduGoalsModal/EduGoalsModal";
import { CourseProfileContext } from "../../../../../Services/api/courses/Trainer/CourseProfileContext";
import { addChapter, getCourseTree } from "../../../../../Services/api/courses/courseProvider";

import { toast } from "react-toastify";
import UploadModal from "../../../../../Modals/uploadModal/UploadModal";
import box from "../../../../../assets/image/box.png";
import AddNewItem from "../../../../Shared/Components/AddNewItem/AddNewItem";
import CustomButton from "../../../../Shared/Components/CustomButton/CustomButton";
import Input from "../../../../Shared/Components/Input/Input";
import { BasicModal } from "../../../../Shared/Components/Modal/BasicModal";
import SkeletonCardOverlay from "../../../../Shared/Components/Spinner/SkeletonCardOverlay";
import ChapterRow from "../ChapterRow/ChapterRow";

function Content(props) {
  require("./content.css");
  const { t } = useTranslation();
  const { id } = useParams();
  const uploadModalRef = useRef();

  const courseProfileContext = useContext(CourseProfileContext);
  const [isAddingCourse, setIsAddingCourse] = useState(false);
  const [hasSubmittedChapter, setHasSubmittedChapter] = useState(false);
  const [allLessons, setAllLessons] = useState([]);

  const [showUploadModal, setShowUploadModal] = useState(false);

  const fetchData = () => {
    const myPromise = new Promise((resolve, reject) => {
      getCourseTree(props.courseID)
        .then((res) => {
          if (res.status && res.status == 200 && res.data.status) {
            courseProfileContext.setChapters(res.data.response.chapters);
            courseProfileContext.setIsChaptersLoaded(true);
            courseProfileContext.setCourseInfo(res.data.response.courseInfo);
            courseProfileContext.setCourseID(props.courseID);

            let lessons = [];
            res.data.response.chapters.forEach((chapter) => {
              chapter.sub_chapters.forEach((subChapter) => {
                lessons = [...lessons, ...subChapter.lessons];
              });
            });
            setAllLessons(lessons);
          }
          setIsAddingCourse(false);
          resolve();
        })
        .catch((err) => {
          
          setIsAddingCourse(false);
          resolve();
        });
    });

    return myPromise;
  };

  const fetchCourseTree = () => {
    const myPromise = new Promise((resolve, reject) => {
      getCourseTree(courseProfileContext.courseID)
        .then((res) => {
          if (res.status && res.status == 200 && res.data.status) {
            courseProfileContext.setChapters(res.data.response.chapters);
            courseProfileContext.setIsChaptersLoaded(true);
          }
          resolve();
        })
        .catch((err) => {
          
          resolve();
        });
    });

    return myPromise;
  };

  const addChapterLocally = () => {
    let chapters = [];
    courseProfileContext.chapters.forEach((item) => {
      chapters.push(item);
    });

    chapters.push({
      emptyField: true,
    });

    courseProfileContext.setChapters(chapters);
  };

  const removeChapterLocally = (index) => {
    let chapters = [];
    courseProfileContext.chapters.forEach((item, idx) => {
      if (Number(idx) !== Number(index)) {
        chapters.push(item);
      }
    });

    courseProfileContext.setChapters(chapters);
  };

  useEffect(() => {
    courseProfileContext.setCourseID(props.courseID);
    fetchData();
    return () => {
      courseProfileContext.setChapters([]);
      courseProfileContext.setIsChaptersLoaded(false);
      setIsAddingCourse(false);
    };
  }, []);

  let currentDate = new Date(courseProfileContext.courseInfo.start_date);
  let date = currentDate.getDate();
  let month = currentDate.getMonth();
  let year = currentDate.getFullYear();
  let monthDateYear = date + "/" + (month + 1) + "/" + year;

  const openUploadModal = () => {
    setShowUploadModal(true);
    uploadModalRef.current.showModal();
  };

  const closeUploadModal = () => {
    setShowUploadModal(false);
    uploadModalRef.current.dismissModal();
  };

  return (
    <>
      {courseProfileContext.fileLoaded == true ? (
        <SkeletonCardOverlay borderRadius={10} />
      ) : (
        <div>
          <div className="training-course-header-container">
            <div className="training-course-header-title">{t("course_chapters")}</div>
            <div className="training-course-header-date">
              {t("Course_start_date")} {courseProfileContext.courseInfo && monthDateYear}
            </div>
          </div>
          <div
            className="training-course-syllabus-section-container"
            style={{
              padding: "unset",
            }}
          >
            {courseProfileContext.chapters.length == 0 ? (
              <div className="">
                <div className="new_chapter">
                  <div
                    className="header_new_chapter"
                    style={{
                      fontSize: "24px",
                      color: "gray",
                      opacity: "47%",
                    }}
                  >
                    {t("emptyRec")}
                  </div>
                  <img src={box} alt="" className="box_new_chapter" />
                </div>
              </div>
            ) : null}
            {courseProfileContext.chapters.map((item, index) => {
              if (item.emptyField) {
                return (
                  <Formik
                    initialValues={{
                      name: "",
                      course_id: courseProfileContext.courseID,
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                      try {
                        setSubmitting(true);
                        setHasSubmittedChapter(false);
                        let response = await addChapter(values);
                        if (response.status === 201 && response.data.status) {
                          await fetchData();
                        } else {
                          alert("Failure");
                        }
                        setSubmitting(false);
                      } catch (err) {
                        setSubmitting(false);
                        toast.error(
                          <>
                            {err?.response?.data?.errors ? (
                              Object.keys(err?.response?.data?.errors)?.map((key) => {
                                return <span style={{ fontSize: 12 }}>{err?.response?.data?.errors[key]} </span>;
                              })
                            ) : (
                              <span style={{ fontSize: 13, fontWeight: "bold" }}>{err?.response?.data?.msg}</span>
                            )}
                          </>
                        );
                      }
                    }}
                    validateOnChange={hasSubmittedChapter}
                    validate={(values) => {
                      const errors = {};
                      if (!values.name) {
                        errors.name = t("crud.errors.required");
                      }

                      setHasSubmittedChapter(true);
                      return errors;
                    }}
                  >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                      <>
                        <form
                          onSubmit={handleSubmit}
                          className="course-details-form"
                          style={{
                            margin: 0,
                          }}
                        >
                          <Input
                            name="name"
                            type="text"
                            label={t("crud.placeholders.name")}
                            placeholder={t("crud.placeholders.name")}
                            value={values.name}
                            onChange={handleChange}
                            error={errors.name}
                            required
                          />
                          <div className="course-details-actions">
                            <CustomButton
                              value={t("AddNewChapter")}
                              colors="#036c77"
                              type="submit"
                              disable={isSubmitting}
                            />
                            <CustomButton
                              value={t("crud.placeholders.cancel")}
                              colors="#036c77"
                              variant="outlined"
                              type="button"
                              disable={isSubmitting}
                              action={() => {
                                removeChapterLocally(index);
                                setIsAddingCourse(false);
                              }}
                            />
                          </div>
                        </form>
                      </>
                    )}
                  </Formik>
                );
              } else {
                return (
                  <ChapterRow
                    handleOpenModal={props.handleOpenModal}
                    key={index}
                    chapter={item}
                    index={index}
                    chapters={courseProfileContext.chapters}
                    allLessons={allLessons}
                    isAdmin={props.isAdmin}
                    role={props.role}
                    fetchCourse={fetchData}
                  />
                );
              }
            })}
          </div>
          {props.role !== "partner" ? (
            <AddNewItem
              title={t("AddNewChapter")}
              actions={[
                {
                  label: t("AddNewChapter"),
                  onClick: () => {
                    setIsAddingCourse(true);
                    addChapterLocally();
                  },
                  disabled: isAddingCourse,
                },
                {
                  label: t("uploadNewChapter"),
                  onClick: openUploadModal,
                  disabled: isAddingCourse,
                },
              ]}
            />
          ) : null}
        </div>
      )}
      <BasicModal ref={uploadModalRef}>
        {showUploadModal && (
          <UploadModal
            title={t("modal.trainer.course.chapter.modal.title")}
            dismissModal={closeUploadModal}
            type={"chapters"}
            itemId={id}
            fetchCourse={fetchData}
          />
        )}
      </BasicModal>
    </>
  );
}

export default Content;
