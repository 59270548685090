import React, {useEffect, useState} from "react";
import OrderQuestionDraggableItem from "../DraggableItem/DraggableItem";

function QuestionDraggableContext(props) {
    const [sourceItemId, setSourceItemId] = useState("");
    const [targetItemId, setTargetItemId] = useState("");
    const [newOptions, setNewOptions] = useState([]);
    const [initiateSorting, setInitiateSorting] = useState(false);

    useEffect(() => {
        if (sourceItemId != "" && targetItemId != "") {
            setInitiateSorting(true);
        }
    }, [sourceItemId,targetItemId])

    useEffect(() => {
        if (initiateSorting) {
            if (sourceItemId != targetItemId) {
                const tempOptions = [];
                let sourceItemIndex = 0;
                let targetItemIndex = 0;
                props.options.forEach((item,index) => {
                    if (item.id == sourceItemId) {
                        sourceItemIndex = index;
                    }else if (item.id == targetItemId) {
                        targetItemIndex = index;
                    }

                    tempOptions.push({
                        ...item
                    });
                })
                const tempItem =  tempOptions[sourceItemIndex];
                tempOptions[sourceItemIndex] = tempOptions[targetItemIndex];
                tempOptions[targetItemIndex] = tempItem;
                props.setOptions(tempOptions);
            }

            setSourceItemId("");
            setTargetItemId("");
        }
    }, [initiateSorting])

    const deleteItem = (id) => {
        let newOptions = props.options.filter((item,i)=> item.id != id)
        props.onDelete(newOptions);

    }

    return (
        <>
            {
                props.options.map((item,index) => {
                    return (
                        <OrderQuestionDraggableItem
                            key={item.id}
                            id={item.id}
                            name={item.name}
                            setSourceItemId={(id) => {
                                setSourceItemId(id);
                            }}
                            setTargetItemId={(id) => {
                                setTargetItemId(id);
                            }}
                            disableSorting={() => {
                                setInitiateSorting(false);
                            }}
                            options={props.options}
                            deleteItem={deleteItem}
                        />
                    )
                })
            }
        </>
    )
}

export default QuestionDraggableContext;