import ReviewIcon from "../../../../../assets/icons/customer-review.png";
import EduGoalsIcon from "../../../../../assets/icons/edu_goals.svg";
import ExamIcon from "../../../../../assets/icons/exam.png";
import { ReactComponent as ActivityIcon } from "../../../../../assets/icons/ActivityIcon.svg";
import chatIcon from "../../../../../assets/icons/chat1.png";
import discussionIcon from "../../../../../assets/icons/discussion.png";
import AddIcon from "../../../../../assets/icons/plus.svg";
import QuestionBankIcon from "../../../../../assets/icons/question_bank.svg";
import MessageIcon from "../../../../../assets/icons/send-2.svg";
import SurveyIcon from "../../../../../assets/icons/survey.svg";
import { ReactComponent as UserGroupIcon } from "../../../../../assets/icons/user-group.svg";

export const headerActions = ({
  t,
  addAction,
  surveyAction,
  surveyList,
  teamsList,
  eduGoalsAction,
  questionBankAction,
  quizStatement,
  DetailedReportAction,
  discussion,
  sendMessagesAction,
  activitiesStatement,
  discussionsStatement,
}) => {
  const actionsList = [
    // ...(DetailedReportAction
    //   ? [
    //       {
    //         id: "introVideo",
    //         type: "button",
    //         outline: true,
    //         style: {
    //           border: "2px solid #8599B6",
    //           borderRadius: 6,
    //           width: 173,
    //           height: 50,
    //         },
    //         label: t("detail_report"),

    //         btnTextColor: "#8599B6",
    //         action: DetailedReportAction,
    //       },
    //     ]
    //   : []),
    ...(surveyAction
      ? [
          {
            id: "survey",
            icon: <img src={ReviewIcon} alt="" width={"100%"} />,
            action: surveyAction,
            title: t("survey.surveyTitle"),
          },
        ]
      : []),
    ...(sendMessagesAction
      ? [
          {
            id: "sendMessages",
            icon: <img src={MessageIcon} alt="" />,
            theme: "add",
            action: sendMessagesAction,
            title: t("send_messages"),
          },
        ]
      : []),
    ...(addAction
      ? [
          {
            id: "add",
            icon: <img src={AddIcon} alt="" />,
            theme: "add",
            action: addAction,
            title: t("survey.add"),
          },
        ]
      : []),
    ...(surveyList
      ? [
          {
            id: "surveyList",
            icon: <img src={SurveyIcon} alt="" />,
            theme: "",
            action: surveyList,
            title: t("survey.listHeading"),
          },
        ]
      : []),

    ...(discussion
      ? [
          {
            id: "discussion",
            icon: <img src={discussionIcon} alt="" className="tw-h-5 tw-w-5" />,
            theme: "",
            action: discussion,
            title: t("trainer.course.lesson.discussions"),
          },
        ]
      : []),

    ...(teamsList
      ? [
          {
            id: "teamsList",
            icon: <UserGroupIcon className="tw-h-5 tw-w-5" />,
            theme: "",
            action: teamsList,
            title: t("coworking_spaces"),
          },
        ]
      : []),
    ...(quizStatement
      ? [
          {
            id: "statement",
            icon: <img src={ExamIcon} alt="" style={{ maxWidth: "100%" }} />,
            theme: "",
            action: quizStatement,
            title: t("course_quiz_report"),
          },
        ]
      : []),
    ...(activitiesStatement
      ? [
          {
            id: "activities_statement",
            icon: <ActivityIcon className="tw-h-5 tw-w-5" />,
            theme: "add",
            action: activitiesStatement,
            title: t("course_activities_report"),
          },
        ]
      : []),
    ...(discussionsStatement
      ? [
          {
            id: "discussions_statement",
            icon: <img src={chatIcon} alt="" className="tw-h-5 tw-w-5" />,
            theme: "",
            action: discussionsStatement,
            title: t("course_discussions_report"),
          },
        ]
      : []),

    {
      id: "eduGoals",
      icon: <img src={EduGoalsIcon} alt="" />,
      theme: "export",
      action: eduGoalsAction,
      title: t("edu.Edu_goals"),
    },
    {
      id: "questionBank",
      icon: <img src={QuestionBankIcon} alt="" />,
      theme: "file",
      title: t("questionBank"),
      action: questionBankAction,
    },
  ];
  return actionsList;
};
