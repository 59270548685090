import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { fetchProgram } from "../../../Services/api/program/programContext";
import checkProgram from "../../../assets/image/check-program.png";
import hour from "../../../assets/image/hour.png";
import lockProgram from "../../../assets/image/lock-program.png";
import ProgramHeader from "../ProgramHeader/ProgramHeader";

function Program() {
	require("./Program.css");
	const { t } = useTranslation();
	const [program, setProgram] = useState(null);
	const [isContentLoaded, setIsContentLoaded] = useState(false);
	const { id } = useParams();
	const history = useHistory();

	useEffect(() => {
		fetchProgram(id)
			.then((res) => {
				if (res.status && res.status == 200 && res.data.status) {
					let lockUpcoming = false;
					res.data.program.courses.forEach((item) => {
						if (res.data.program.is_accessable) {
							if (lockUpcoming) {
								item.lock = true;
							} else {
								if (
									(item.progress.total > 0 && item.progress.progress == 0) ||
									item.progress.progress != item.progress.total
								) {
									lockUpcoming = true;
									item.progressValue =
										item.progress.total == 0 ? "0%" : (item.progress.progress / item.progress.total) * 100 + "%";
								} else if (item.progress.progress == item.progress.total) {
									item.done = true;
								}
							}
						} else {
							item.lock = true;
						}
					});
					setProgram(res.data.program);
					setIsContentLoaded(true);
				}
			})
			.catch((err) => {
				
				
			});
	}, []);

	return (
		<>
			<ProgramHeader label={program?.title} btn={true} program={program} />
			<div className="container-fluid program__header-container">
				<div className="mt-4 ">
					{t("programs.msg.program_msg")}
					{program?.title}
				</div>
				{program &&
					program.courses.map((course) => (
						<div
							onClick={() => {
								if (!course.lock) {
									history.push(`/training-courses/${course.id}/profile`);
								}
							}}
							key={course.id}
							className={"text-dark " + (course.lock ? "program-card-locked" : "program-card-clickable")}
						>
							<div className="program_card">
								<div className="flex_card_course">
									<div
										className="program_img"
										style={{
											backgroundImage:
												"url(" +
												course.img + //TODO:
												")",
										}}
									>
										<div className="backdrop_img">
											{course.done && <img src={checkProgram} alt="" />}
											{course.lock && <img src={lockProgram} alt="" />}
											{course.progressValue && (
												<div className="progress">
													<div
														className="progress-bar"
														role="progressbar"
														style={{
															width: course.progressValue,
														}}
														aria-valuenow="25"
														aria-valuemin="0"
														aria-valuemax="100"
													/>
												</div>
											)}
										</div>
									</div>
									<div className="program_title">
										<div className="mb-3 program_font">{course.name}</div>
										<div className="">{course.description}</div>
									</div>
								</div>
								<div className="program_info">
									<div className="mb-3 ">
										{t("programs.label.by")} :{" "}
										{document.body.dir == "rtl" ? course.instructor_ar : course.instructor_en}
									</div>
									<div className="card_center">
										<img src={hour} alt="" className="img_hour" />
										<div className="">
											{" "}
											{course.number_hours} {t("hour")}{" "}
										</div>
									</div>
								</div>
							</div>
						</div>
					))}
			</div>
		</>
	);
}

export default Program;
