import moment from "moment";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { exhibitionVisit } from "../../../../../Redux/Actions/exhibition/exhibition.action";
import toLocalTimezone from "../../../../../Services/Timezone";
import deleteIcon from "../../../../../assets/icons/delete.svg";
import { ReactComponent as GalleryIcon } from "../../../../../assets/icons/gallery-icon.svg";
import MainBox from "../../../../Shared/Components/MainBox/MainBox";
import MainCarousel from "../../../../Shared/Components/MainCarousel/MainCarousel";
import { BasicModal } from "../../../../Shared/Components/Modal/BasicModal";
import StarsRating from "../../../../Shared/Components/StarsRating/StarsRating";
import Title from "../../../../Shared/Components/Title/Title";
import LessonItem from "./LessonItem";

export default function LessonExhibitionItem({ courseId, chapterId, unitId, lessonId, exhibition, onOpen, fetchData }) {
  require("./exhibition.css");
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const imgModalRef = useRef();
  const [showImgModal, setShowImgModal] = useState(false);
  const [photos, setPhotos] = useState(false);

  const openImgModal = () => {
    setShowImgModal(true);
    imgModalRef.current.showModal();
  };

  const closeImgModal = () => {
    setShowImgModal(false);
    imgModalRef.current.dismissModal();
  };

  function handlePhoto(photosData) {
    setPhotos(photosData);
  }

  const handleVisitAPI = async (id, exMode) => {
    const visitPayload = {
      name:
        localStorage.getItem("i18nextLng") === "en" ? localStorage.getItem("name_en") : localStorage.getItem("name_ar"),
      email: localStorage.getItem("email"),
      mobile: localStorage.getItem("phone"),
      exhibition_id: id,
      exhibition_type: exMode,
    };

    dispatch(exhibitionVisit(visitPayload));
  };

  const params = {
    name: t("admin.label.virtual_galleries"),
    logo: exhibition.main_image,
    title: exhibition.name,
    badge:
      (exhibition.is_active || exhibition.is_coming) && exhibition.user_status === 1
        ? {
            label: t("Exhibition.awaiting_approval"),
            color: "#B5B845",
          }
        : (exhibition.is_active || exhibition.is_coming) && exhibition.user_status === 2 && exhibition.mode !== "Free"
        ? {
            label: t("Exhibition.has_been_approved"),
            color: "#56B1B7",
          }
        : (exhibition.is_active || exhibition.is_coming) && exhibition.user_status === 3
        ? {
            label: t("Exhibition.entry_declined"),
            color: "#DF4B43",
          }
        : !exhibition.is_active && !exhibition.is_coming
        ? {
            label: t("Exhibition.ended"),
            color: "#DF4B43",
          }
        : null,
    buttons: [
      {
        label: exhibition.is_coming ? t("Exhibition.start_soon") : t("Exhibition.entry_to_the_exhibition"),
        disabled: exhibition.is_coming || exhibition.user_status === 1 || !exhibition.is_active,
        onClick: () => {
          if (exhibition.mode === "Free" || exhibition.user_status === 2) {
            if (exhibition.exhibition_visits === null) {
              handleVisitAPI(exhibition.id, exhibition.mode.toUpperCase());
            }
            localStorage.setItem("exhibition_id", exhibition.id);
            history.push(`/show-exhibition/unity-iframe/${exhibition.id}`);
          } else {
            history.push(`/exhibition-payment/visitor/${exhibition.id}`);
          }
        },
        secondary: true,
      },
      ...(exhibition.photos?.length
        ? [
            {
              label: <GalleryIcon className="tw-h-6 tw-w-6" />,
              onClick: () => {
                handlePhoto(exhibition.photos);
                openImgModal();
              },
            },
          ]
        : []),
    ],
    footer: {
      columns: [
        {
          label: t("Exhibition.entry_cost"),
          value:
            exhibition.mode === "Paid" ? `${exhibition.entrance_cost} ${t("Exhibition.rs")}` : t("Exhibition.free"),
        },
        {
          label: t("Exhibition.number_of_booths"),
          value: exhibition.booths_no,
        },
        {
          label: t("avialible_from"),
          value: moment(toLocalTimezone(exhibition.start_time)).format("YYYY-MM-DD hh:mm A"),
          rows: [
            {
              label: t("To"),
              value: moment(toLocalTimezone(exhibition.end_time)).format("YYYY-MM-DD hh:mm A"),
            },
          ],
        },
      ],
      stars: () => <StarsRating rating={exhibition.rate_avg || 0} cutStyle="star-font-size" />,
    },
  };
  return (
    <>
      <LessonItem {...params} />
      <BasicModal ref={imgModalRef}>
        {showImgModal && (
          <MainBox className="border-8 w-60">
            <div className="popup-header">
              <Title title={t("Exhibition.popupـimgـlabel")} />
              <img src={deleteIcon} alt="close" onClick={closeImgModal} className="pointer" />
            </div>

            <div className="slider-container ">
              <MainCarousel imagesList={photos} />
            </div>
          </MainBox>
        )}
      </BasicModal>
    </>
  );
}
