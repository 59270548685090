import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getUserRoles,
  userRoles,
} from "../../../Services/api/Roles/RolesProvider";
import CustomButton from "../../Shared/Components/CustomButton/CustomButton";

function EditRole(props) {
  require("./roles.css");
  const { t } = useTranslation();
  const [userRole, setUserRole] = useState([]);
  const [load, setLoad] = useState(true);
  const [checked, setChecked] = useState([]);
  const [roleSelect, setRoleSelect] = useState([]);
  const [userId, setUserId] = useState("");

  useEffect(async () => {
    let userID = await props.users.map((user) => {
      return user.id;
    });
    setUserId(...userID);
  }, []);

  useEffect(() => {
    if (userId && userId.length != 0) {
      getUserRoles(userId)
        .then((res) => {
          if (res.status && res.status == 200 && res.data.status) {
            setUserRole(res.data.response.Users.data);
          }
        })
        .catch((err) => {});
    }
  }, [userId]);

  useEffect(async () => {
    if (userRole && userRole.length != 0) {
      let usersR = await userRole.map((userR) => {
        return userR.roles;
      });

      let userRolesIds;
      await usersR.map(async (uR) => {
        userRolesIds = await uR.map((uI) => {
          return uI.id;
        });
      });
      setRoleSelect(userRolesIds);
      setLoad(false);
    }
  }, [userRole]);

  const checkHandle = (id, e) => {
    let resultArray = [];
    if (e.target.checked) {
      resultArray = roleSelect.filter((CheckedId) => CheckedId !== id);
      resultArray.push(id);
      setRoleSelect(resultArray);
    } else {
      resultArray = roleSelect.filter((CheckedId) => CheckedId !== id);
      setRoleSelect(resultArray);
    }
    setRoleSelect(resultArray);
  };

  const assignUserRoles = (id, roleSelect) => {
    props.setIsUpdating(true);
    // console.log(roleSelect);

    userRoles(id, roleSelect)
      .then((res) => {
        if (res.status == 200 && res.data.status) {
          props.fetchRoles();
          props.onClick();
          props.setIsUpdating(false);
        }
      })
      .catch((error) => {
        props.setIsUpdating(false);
      });
  };

  return (
    <div className="modal_flex_card">
      <div className="modal_card_admin">
        <div className="modal_card_title">{t("userRole")}</div>
        <div className="roles_list">
          {load === true ? (
            <div className="loader">Loading...</div>
          ) : (
            props.roles.map((role) => {
              return (
                <div className="checkbox__container" key={role.id}>
                  <>
                    <input
                      type="checkbox"
                      id={role.id}
                      name={role.name}
                      value={role.id}
                      onClick={(e) => checkHandle(role.id, e)}
                      defaultChecked={roleSelect.includes(role.id)}
                      disabled={
                        (roleSelect.includes(1) && role.name !== "admin") ||
                        (roleSelect.includes(4) && role.name !== "trainee") ||
                        (roleSelect.includes(5) &&
                          role.name !== "accountant") ||
                        (roleSelect.includes(6) && role.name !== "partner") ||
                        (roleSelect.includes(7) && role.name !== "sponser") ||
                        (roleSelect.includes(2) &&
                          role.name !== "trainer" &&
                          role.name !== "manager") ||
                        (roleSelect.includes(3) &&
                          role.name !== "trainer" &&
                          role.name !== "manager")
                      }
                    />
                    <label htmlFor={role.id}>{role.label}</label>
                  </>
                </div>
              );
            })
          )}
        </div>
        <div className="actions__container">
          <CustomButton
            value={t("save")}
            colors="#046c77"
            action={() => assignUserRoles(userId, roleSelect)}
          />
          <CustomButton
            value={t("back")}
            action={props.onClick}
            variant="outlined"
          />
        </div>
      </div>
    </div>
  );
}

export default EditRole;
