import styled from 'styled-components';
import {Link} from "react-router-dom";

export const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #ffffff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  margin: 0 auto;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

export const ButtonText = styled.span`
  display: ${props => (props.loading ? 'none' : 'block')};
  font-size: 20px;
  color: #ffffff;
`;

export const CustomButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => (props.isGray ? '#F2F2F2' : '#046C77')};
  color: #242424;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  border-radius: ${props => (props.borderRadius ? props.borderRadius : 10)}px;
  border: none;
  font-size: 24px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  position: relative;

  ${(props) =>
  props.disabled
    ? `
        background: gray;
        color: white;
      `
    : ''}
`;