import { useState } from "react";
import { useTranslation } from "react-i18next";
import RemoveIcon from "../../../../assets/icons/remove-red.svg";
import FilesDragAndDrop from "./FilesDragAndDrop";

export default function FilesDragAndDropInput({
  id = (Math.random() + 1).toString(36).substring(7),
  label,
  placeholder,
  buttonTitle,
  buttonIcon,
  accept,
  name,
  onChange,
  className,
  value,
  error,
  disabled = false,
  required = false,
  canRemove = false,
  removeAction,
}) {
  const rend = () => Math.random().toString(36);
  const [inputKey, setInputKey] = useState(rend());
  const { i18n } = useTranslation();

  function onUpload(files) {
    if (onChange) onChange(files);
  }

  return (
    <div className={className}>
      <label className="tw-block">
        <div className="tw-text-gray-600 tw-font-medium tw-block tw-mb-2">
          {label} <span className={`tw-text-red-400 ${required ? "" : "tw-hidden"}`}>*</span>
        </div>
        <div className="tw-w-full">
          <input
            id={id}
            key={inputKey}
            className="tw-hidden"
            accept={accept}
            type={"file"}
            name={name}
            disabled={disabled}
            onChange={({ target: { files } }) => {
              onUpload(files);
              setInputKey(rend());
            }}
            required={required}
          />
          <div
            className={`${
              disabled ? "tw-cursor-default tw-bg-gray-100" : "tw-cursor-pointer"
            } tw-flex tw-items-stretch tw-w-full tw-text-gray-500`}
          >
            <FilesDragAndDrop className="tw-grow tw-min-w-0 tw-relative" onUpload={(files) => onUpload(files)}>
              <div className="tw-w-full tw-text-ellipsis tw-border-[1px] !tw-border-dashed tw-border-gray-300 tw-rounded-s tw-p-2.5 tw-overflow-hidden tw-whitespace-nowrap">
                {value?.name
                  ? value.name
                  : `${placeholder} ${accept ? `(${accept?.replaceAll(".", "")?.toLocaleUpperCase()})` : ""}`}
              </div>

              {canRemove && (
                <button
                  type="button"
                  className="upload-file-remove"
                  style={{
                    position: "absolute",
                    ...(i18n.dir() === "rtl" ? { left: "10px" } : { right: "10px" }),
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                  onClick={removeAction}
                >
                  <img src={RemoveIcon} alt="" />
                </button>
              )}
            </FilesDragAndDrop>

            <div className="tw-flex tw-items-center tw-bg-teal-800 tw-text-white tw-p-2.5 tw-rounded-e tw-whitespace-nowrap">
              {buttonTitle}
            </div>
          </div>
        </div>
      </label>

      {error ? <div className="tw-text-sm tw-text-red-500">{error}</div> : null}
    </div>
  );
}
