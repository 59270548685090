import { useEffect, useRef, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import HomeIcon from "../../../../assets/icons/home.svg";

import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { ReactComponent as CalendarIcon } from "../../../../assets/icons/calendar.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/crose.svg";
import { ReactComponent as MemuIcon } from "../../../../assets/icons/menu.svg";
import { ReactComponent as PenIcon } from "../../../../assets/icons/pen.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus-square.svg";
import DeleteIcon from "../../../../assets/icons/warning.svg";
import { BasicModal } from "../../../Shared/Components/Modal/BasicModal";

import { ErrorMessage, Field, FieldArray, Formik } from "formik";
import toLocalTimezone, { toServerTime } from "../../../../Services/Timezone";
import {
  addQuiz,
  editQuiz,
  getCourseObjectives,
  getExamData,
  getQuestionBankItems,
  getQuestionTypes,
} from "../../../../Services/api/exams/ExamsProvider";
import { getAllUsers } from "../../../../Services/api/survey/SurveyProvider";
import i18n from "../../../../i18n/i18n";
import { Lang } from "../../../../utils";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import SharedModal from "../../../Shared/Components/SharedModal/SharedModal";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import QuestionTypes, { JustPreview } from "../QuestionTypes";

import moment from "moment";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import ErrorMessages from "../../../../Components/ErrorMessages";
import QuestionTypeEnum from "../../../../Enums/QuestionTypeEnum";
import { getCourseDetails } from "../../../../Services/api/courses/courseProvider";
import { SunEditorText } from "../../../Shared/Components";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import { AssessmentQuestionPreview } from "../Preview";
import QuestionBank from "./Part/QuestionBank";
import QuestionValidator from "./Part/QuestionValidator";
import QuestionWarpper from "./Part/QuestionWarpper";

export default function AssessmentForm({ quizable, role, fromTrainer = false }) {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [errs, setErrors] = useState({});
  const [showQuestionModel, setShowQuestionModel] = useState(null);
  const [showQuestionBankModel, setShowQuestionBankModel] = useState(null);
  const [questionTypes, setQuestionTypes] = useState([]);

  const [quiz, setQuiz] = useState({});
  const [previewQuestions, setPreviewQuestions] = useState({});
  const [preview, setPreview] = useState(false);
  const [trainees, setTrainees] = useState([]);
  const [bankQuestions, setBankQuestions] = useState([]);

  const [userFetching, setUserFetching] = useState(false);

  const [deleteQuestion, setDeleteQuestion] = useState(null);
  const [addQuestionIndex, setAddQuestionIndex] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [targetCourse, setTargetCourse] = useState({});
  const [showMarkConditions, setShowMarkConditions] = useState([
    {
      value: 1,
      label: i18n.language == "en" ? "After Specific Date" : "بعد تاريخ محدد",
    },
    {
      value: 2,
      label: i18n.language == "en" ? "After Quiz" : "بعد الاختبار",
    },
  ]);
  const questionModalRef = useRef();

  const history = useHistory();
  const { t } = useTranslation();

  const { id, courseId, quizableId } = useParams();

  const formikRef = useRef();

  useEffect(() => {
    fetchQuestionTypes();
    fetchQuestionBankItems();
  }, []);

  useEffect(() => {
    if (id != null && id != undefined) {
      setIsLoading(true);
      getExamData(id)
        .then((res) => {
          if (res.status && res.status == 200 && res.data.status) {
            const quiz = res.data.quiz;
            setQuiz({
              ...quiz,
              users_answered_count: parseInt(quiz.users_answered_count),
            });
            fetchUsers(getFormInitialValues(quiz));
            setIsLoading(false);
          } else {
            throw res;
          }
        })
        .catch((err) => {});
    } else {
      fetchUsers(getFormInitialValues());
      setIsLoading(false);
    }
  }, [id]);

  const fetchQuestionBankItems = () => {
    const myPromise = new Promise((resolve, reject) => {
      getQuestionBankItems(courseId, { perPage: 100, active: 1 })
        .then((res) => {
          if (res.status == 200 || res.status == 201) {
            function mapQuestion(question) {
              if (question.type_id == QuestionTypeEnum.ordering) {
                return {
                  ...question,
                  options: question.options.sort((a, b) => a.value - b.value),
                };
              } else {
                return question;
              }
            }
            setBankQuestions(res.data.data.questions.map(mapQuestion));
            resolve();
          } else {
            throw res;
          }
          setIsLoading(false);
        })
        .catch((err) => {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
          setIsLoading(false);
          reject(err);
        });
    });

    return myPromise;
  };

  const fetchQuestionTypes = () => {
    const myPromise = new Promise((resolve, reject) => {
      getQuestionTypes()
        .then((res) => {
          setQuestionTypes(res.data.types);
          resolve();
        })
        .catch((error) => {
          reject();
        });
    });
  };

  const reorderItems = (from, to, list) => {
    var unOrderedQuestions = [...list];
    var sourceItem = unOrderedQuestions.splice(from, 1)[0];
    unOrderedQuestions.splice(to, 0, sourceItem);
    return unOrderedQuestions;
  };

  useEffect(() => {
    if (questionModalRef?.current) {
      if (showQuestionModel || showQuestionBankModel || deleteQuestion) {
        questionModalRef.current.showModal();
      } else {
        questionModalRef.current.dismissModal();
      }
    }
  }, [showQuestionModel, showQuestionBankModel, deleteQuestion, questionModalRef]);

  const fetchUsers = async (values) => {
    try {
      setUserFetching(true);
      setTrainees([]);
      let response = await getAllUsers({
        search: values.search ? values.search : null,
        includes: values.except_users ? [] : values.trainees,
        course: courseId,
        roles: ["trainee"],
      });
      if (response.status == 200) {
        setTrainees(response.data.users);
        setUserFetching(false);
      } else {
        throw response;
      }
    } catch (err) {
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
      setUserFetching(false);
    }
  };

  function AddQuestion({ index, onNewQuestion, onBankQuestion }) {
    const active = addQuestionIndex == index;
    return (
      <div
        className={`tw-py-4 tw-border ${
          active ? "tw-h-40 tw-border-gray-200 tw-bg-gray-50 tw-rounded tw-space-y-8" : "tw-h-14 tw-border-white"
        }`}
      >
        <div className="tw-h-6 tw-flex tw-flex-col tw-justify-center">
          <div className="tw-relative">
            <hr className="tw-border-[1px] tw-border-dashed" />
            <button
              type="button"
              onClick={() => setAddQuestionIndex(addQuestionIndex == index ? null : index)}
              className={`tw-flex tw-items-center tw-space-s-2 tw-absolute tw-left-1/2 -tw-translate-x-1/2 tw-top-1/2 -tw-translate-y-1/2 ${
                active ? "tw-bg-gray-50" : "tw-bg-white"
              } tw-px-3 tw-text-teal-700`}
            >
              <PlusIcon className="tw-stroke-[#046c77] tw-h-4 tw-w-4 " />
              <div>{t("add_question")}</div>
            </button>
          </div>
        </div>
        {active ? (
          <div className="tw-flex tw-items-center tw-justify-center tw-space-s-6 tw-w-full tw-mb-6 tw-h-12">
            <button
              type="button"
              onClick={onBankQuestion}
              className="tw-py-2.5 tw-w-56 tw-border-2 tw-border-teal-500 tw-rounded tw-text-teal-500 hover:tw-text-white hover:tw-bg-teal-500"
            >
              {t("choose_question_from")}
            </button>
            <button
              type="button"
              onClick={onNewQuestion}
              className="tw-py-2.5 tw-w-56 tw-border-2 tw-border-teal-500 tw-rounded tw-text-teal-500 hover:tw-text-white hover:tw-bg-teal-500 "
            >
              {t("new_qusetion")}
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }

  function getFormInitialValues(quiz = {}) {
    return {
      active: quiz.active ?? true,
      title: quiz.title ?? "",
      random: quiz.random ?? false,
      can_back: quiz.can_back ?? false,
      show_mark: quiz.show_mark ?? false,
      show_answers: quiz.show_answers ?? false,
      monitored: quiz.monitored ?? false,
      include_view_per: quiz.include_view_per ?? false,
      include_achivement_per: quiz.include_achivement_per ?? false,
      show_mark_condition: quiz.show_mark_condition ?? "",
      show_mark_condition_date: quiz.show_mark_condition_date
        ? new Date(quiz.show_mark_condition_date).toDateString()
        : new Date().toDateString(),
      description: quiz.description ?? "",
      objectives: quiz.course_objectives ? quiz.course_objectives.map((objective) => objective.id) : [],
      except_users: quiz.except_users == 1 ? true : false,
      search: "",
      trainees: quiz.trainees ? quiz.trainees.map((user) => user.id) : [],
      success_rate: quiz.success_rate ?? "",
      total_grade: quiz.total_grade ?? "",
      period: quiz.period ?? "",
      answer_attempts: quiz.answer_attempts ?? "",
      start_date: quiz.start_date ? toLocalTimezone(quiz.start_date) : toLocalTimezone(new Date()),
      end_date: quiz.end_date ? toLocalTimezone(quiz.end_date) : toLocalTimezone(new Date()),
      questions: quiz.questions ? quiz.questions.map((item) => getQuestionInitialValues(item)) : [],
    };
  }

  function getQuestionInitialValues(question = {}) {
    let options = question?.options ?? [];
    if (question.type_id == QuestionTypeEnum.ordering) {
      options = options.sort((a, b) => a.value - b.value);
    }
    return {
      id: question?.id ?? "",
      type_id: question?.type_id ?? "",
      type: question?.type ?? "",
      title: question?.title?.replace(/<[^>]*>?/gm, "") ?? "",
      text: question?.text ?? "",
      media: question.media ? question.media : "",
      degree: question?.degree ?? "",
      level: question?.level ?? "",
      course_objectives: question?.course_objectives ? question.course_objectives : [],
      standards: question?.standards ? question.standards : [],

      feedback: question?.feedback ?? "",
      options: options,
      answers: [],
    };
  }

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      getCourseObjectives(courseId, inputValue ? { name: inputValue, perPage: 1000 } : { perPage: 1000 }).then(
        (res) => {
          resolve(res.data.data.objectives.data);
        }
      );
    });

  function breadcrumbList() {
    let breadcrumbList = [];

    breadcrumbList = [
      {
        id: "home",
        page: <img src={HomeIcon} alt="" />,
        pagePath: "/",
      },
    ];

    breadcrumbList = [
      ...breadcrumbList,
      // {
      //   id: t("sidebar.label.courses"),
      //   page: t("sidebar.label.courses"),
      //   pagePath: `/${role}/courses`,
      // },
      {
        id: t("Courseoutline"),
        page: t("Courseoutline"),
        pagePath: `/${role}${targetCourse?.program_id ? `/program/${targetCourse.program_id}` : ``}/course/${courseId}`,
      },
      {
        id: t("trainer.quiz.exams"),
        page: t("trainer.quiz.exams"),
        pagePath: `/${role}/course/${courseId}/${quizable}/${quizableId}/assessments`,
      },
    ];
    if (id) {
      breadcrumbList.push({
        id: id,
        page: quiz.title,
        active: true,
      });
    } else {
      breadcrumbList.push({
        id: t("TypeFile.add"),
        page: t("TypeFile.add"),
        active: true,
      });
    }
    return breadcrumbList;
  }

  const getCourseDetailsAPI = () => {
    getCourseDetails(courseId)
      .then((res) => {
        if (res.status === 200 && res.data.status) {
          setTargetCourse(res.data.course);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (courseId) {
      getCourseDetailsAPI();
    }
  }, [courseId]);

  const SortableItem = SortableElement(({ item, itemIndex, remove, values, errors, setFieldValue }) => {
    return (
      <div
        className={`${
          quiz.users_answered_count ? "tw-pb-4" : addQuestionIndex == itemIndex + 1 ? "tw-pb-40" : "tw-pb-16"
        } tw-ps-12`}
      >
        <QuestionItem
          prefix={`questions.${itemIndex}.`}
          values={values}
          errors={errors}
          setFieldValue={setFieldValue}
          setValues={(values) => setFieldValue(`questions.${itemIndex}`, values)}
          item={item}
          itemIndex={itemIndex}
          disabled={!!quiz.users_answered_count}
          questionTypes={questionTypes}
          onEdit={() => {
            setShowQuestionModel({
              questionData: item,
              disabled: !!quiz.users_answered_count,
              onSubmit: (question) => {
                setFieldValue(`questions.${itemIndex}`, question);
              },
            });
          }}
          onDelete={async () => {
            try {
              setDeleteQuestion({
                item: item,
                onApprove: async () => {
                  remove(itemIndex);
                },
              });
            } catch (error) {
              toast.error(
                <span
                  style={{
                    fontSize: 13,
                    fontWeight: "bold",
                  }}
                >
                  {t("failed_deleting")}
                </span>
              );
            }
          }}
        />
      </div>
    );
  });

  const SortableList = SortableContainer(({ questions, push, insert, remove, values, errors, setFieldValue }) => {
    return (
      <div>
        {questions.map((item, itemIndex) => (
          <div key={itemIndex} className="tw-relative">
            <div
              className={`tw-w-10 tw-h-10 tw-overflow-hidden tw-absolute tw-start-2 tw-top-0 tw-flex tw-items-center tw-text-gray-400 tw-text-lg`}
            >
              {itemIndex + 1}-
            </div>
            <SortableItem
              values={values.questions[itemIndex]}
              errors={errors}
              setFieldValue={setFieldValue}
              item={item}
              index={itemIndex}
              itemIndex={itemIndex}
              push={push}
              remove={remove}
            />
            {quiz.users_answered_count ? (
              <></>
            ) : (
              <div
                className={`tw-absolute ${
                  addQuestionIndex == itemIndex + 1 ? "tw-h-40" : "tw-h-18"
                } tw-flex tw-flex-col tw-justify-center tw-bottom-0 tw-inset-x-0`}
              >
                <AddQuestion
                  index={itemIndex + 1}
                  onNewQuestion={() =>
                    setShowQuestionModel({
                      after: itemIndex,
                      onSubmit: (question, after = questions.length - 1) => {
                        insert(after + 1, question);
                      },
                    })
                  }
                  onBankQuestion={() =>
                    setShowQuestionBankModel({
                      after: itemIndex,
                      onSubmit: (questions, after = questions.length - 1) => {
                        questions.forEach((question, index) => insert(after + index + 1, question));
                      },
                    })
                  }
                />
              </div>
            )}
          </div>
        ))}
      </div>
    );
  });

  function questionsToPreview(questions) {
    const qs = questions.map((question, index) => ({
      ...question,
      id: question.id ?? `preview-${index}`,
      type: questionTypes.find((item) => item.id == question.type_id),
      options: question.options.map((item, itemIndex) => ({
        id: item.id ?? `preview-${itemIndex}`,
        text: item.text,
        value: item.value,
        image: item.image,
      })),
    }));
    return qs;
  }

  return (
    <>
      <div className="container-fluid">
        {isUpdating ? <SkeletonCardOverlay skeletonWidth="100" /> : <div></div>}
        {isLoading || (id && !quiz.id) ? (
          <div
            style={{
              height: "65vh",
              width: "100%",
            }}
          >
            <SkeletonCard />
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-12 sm:tw-px-2 tw-py-8">
                {/*TODO*/}
                <Breadcrumb list={breadcrumbList()} />
              </div>
            </div>
            {preview ? (
              <div>
                <div>
                  <div className="tw-text-red-500 tw-py-2">
                    {t("Please save the quiz to preview all types of test questions")}
                  </div>
                </div>
                <AssessmentQuestionPreview questions={previewQuestions} role={role} preview={true} editor={true} />
                <div className="tw-flex tw-items-stretch tw-justify-end tw-space-s-4 tw-py-8">
                  <button
                    type="button"
                    onClick={() => setPreview(false)}
                    className="tw-bg-teal-700 tw-py-2 tw-px-16 tw-rounded tw-text-white"
                  >
                    {t("back")}
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className={`tw-mb-4 ${preview ? "tw-hidden" : ""}`}>
              <Formik
                ref={formikRef}
                initialValues={getFormInitialValues(quiz)}
                onSubmit={async (values, { setErrors }) => {
                  // payload preperation section
                  setIsUpdating(true);
                  var data = {};

                  data.include_view_per = values.include_view_per ? 1 : 0;
                  data.include_achivement_per = values.include_achivement_per ? 1 : 0;
                  if (!quiz.users_answered_count) {
                    data.title = values.title;
                    data.random = values.random && !values.monitored ? 1 : 0;
                    data.can_back = values.can_back && !values.monitored ? 1 : 0;
                    data.monitored = values.monitored ? 1 : 0;
                    data.description = values.description;

                    if (values.monitored) {
                      data.total_grade = values.total_grade;
                    }

                    data.course_objectives = values.objectives;

                    data.trainees = values.trainees;
                    data.except_users = values.except_users ? 1 : 0;

                    data.success_rate = values.success_rate;
                    data.period = values.period;

                    data.start_date = toServerTime(values.start_date);

                    data.questions = values.questions.map((item, index) => ({
                      ...item,
                      order: index,
                      options: item.options.map((item) => {
                        let temp = { ...item };
                        delete temp.image;
                        return temp;
                      }),
                      course_objectives: item.course_objectives.map((item) => item.id),
                      standards: item.standards.map((item) => item.id),
                    }));
                  } else {
                    data.questions = values.questions.map((item, index) => ({
                      id: item.id,
                      order: index,
                    }));
                  }

                  data.active = values.active ? 1 : 0;

                  data.end_date = toServerTime(values.end_date);
                  data.show_mark = values.show_mark && !values.monitored ? 1 : 0;
                  data.show_answers = values.show_mark && values.show_answers && !values.monitored ? 1 : 0;
                  if (values.show_mark && !values.monitored) {
                    data.show_mark_condition = values.show_mark_condition;

                    if (values.show_mark_condition == 1) {
                      data.show_mark_condition_date = moment(values.show_mark_condition_date).format("YYYY-MM-DD");
                    }
                  }

                  data.answer_attempts = values.monitored ? 1 : values.answer_attempts;

                  try {
                    var response;
                    if (!id) {
                      data.quizable_id = quizableId;
                      data.quizable_type = quizable;
                      response = await addQuiz(data);
                      setIsUpdating(false);
                      if (response.status === 201 && response.data.status) {
                        setErrors({});
                        toast.success(
                          <span style={{ fontSize: 13, fontWeight: "bold" }}>
                            {t("trainer.quiz.added_successfully")}
                          </span>,
                          {
                            autoClose: 3000,
                          }
                        );
                      } else {
                        throw response;
                      }
                    } else {
                      response = await editQuiz(data, id);
                      setIsUpdating(false);
                      if (response.status === 200 && response.data.status) {
                        setErrors({});
                        toast.success(
                          <span style={{ fontSize: 13, fontWeight: "bold" }}>
                            {t("trainer.quiz.edited_successfully")}
                          </span>,
                          {
                            autoClose: 3000,
                          }
                        );
                      } else {
                        throw response;
                      }
                    }
                    if (history.length !== 1) history.goBack();
                    else history.push(`/${role}/course/${courseId}/${quizable}/${quizableId}/assessments`);
                  } catch (err) {
                    setIsUpdating(false);
                    if (err.data?.errors) {
                      function mapError(err) {
                        if (err[0] && typeof err[0] === "string") return err[0];
                        else {
                          Object.keys(err).forEach(function (key, index) {
                            err[key] = mapError(err[key]);
                          });
                          return err;
                        }
                      }
                      const errors = mapError({ ...err.data.errors });
                      setErrors(errors);
                    }

                    toast.error(
                      <>
                        <span
                          style={{
                            fontSize: 13,
                            fontWeight: "bold",
                            display: "block",
                            paddingBottom: "10px",
                          }}
                        >
                          {err.data?.msg ?? "Failure in service"}
                        </span>
                        {err?.data?.errors && <ErrorMessages errors={err.data.errors} />}
                      </>
                    );
                  }
                }}
                validate={(values) => {
                  setHasSubmitted(true);
                  const errors = {};
                  let checkDates = true;

                  if (!quiz.users_answered_count) {
                    if (!values.start_date) {
                      checkDates = false;
                      errors.start_date = t("crud.errors.required");
                    } else if (!id && new Date(values.start_date) <= new Date()) {
                      errors.start_date = t("crud.errors.The_start_date_must_be_a_later_date");
                    }

                    if (!values.period) {
                      errors.period = t("crud.errors.required");
                    }

                    if (!values.success_rate) {
                      errors.success_rate = t("crud.errors.required");
                    } else if (parseFloat(values.success_rate) < 0 || parseFloat(values.success_rate) > 100) {
                      errors.success_rate = t("crud.errors.percentage");
                    }

                    if (!values.answer_attempts && !values.monitored) {
                      errors.answer_attempts = t("crud.errors.required");
                    }

                    if (!values.title) {
                      errors.title = t("crud.errors.required");
                    } else if (values.title.length > 5000) {
                      errors.title = t("The field text is too long");
                    }

                    if (!values.description) {
                      errors.description = t("crud.errors.required");
                    } else if (values.description.length > 5000) {
                      errors.description = t("The field text is too long");
                    }

                    if (!Array.isArray(values.objectives) || values.objectives.length == 0) {
                      errors.objectives = t("crud.errors.required");
                    }

                    if (values.questions.length && !values.monitored) {
                      let questionsErrors = [];
                      values.questions.forEach((question, index) => {
                        let quesErrors = QuestionValidator(question, t);
                        if (Object.keys(quesErrors).length) questionsErrors[index] = quesErrors;
                      });
                      if (questionsErrors.length) {
                        errors.questions = questionsErrors;
                      }
                    }
                  }

                  if (
                    values.show_mark_condition &&
                    values.show_mark_condition == 1 &&
                    !values.show_mark_condition_date
                  ) {
                    errors.show_mark_condition_date = t("crud.errors.required");
                  }

                  if (!values.end_date) {
                    checkDates = false;
                    errors.end_date = t("crud.errors.required");
                  }

                  if (checkDates && new Date(values.start_date) >= new Date(values.end_date)) {
                    errors.end_date = t("crud.errors.start_date_is_smaller_than_or_equal_end_date");
                  }

                  if (!values.questions.length && !values.monitored) {
                    errors.questions = t("crud.errors.questions_required");
                  }

                  return errors;
                }}
                validateOnChange={hasSubmitted}
              >
                {({
                  setFieldValue,
                  setValues,
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  resetForm,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <>
                      <BasicModal ref={questionModalRef}>
                        {deleteQuestion ? (
                          <SharedModal
                            icon={DeleteIcon}
                            title={t("trainer.quiz.modal.question.delete.label")}
                            name={deleteQuestion.item.name}
                            confirmMsg={t("trainer.quiz.modal.question.delete.description")}
                            confirmText={t("trainer.quiz.modal.question.delete.accept")}
                            cancelText={t("Exhibition.cancel")}
                            onCancel={() => {
                              setDeleteQuestion(null);
                            }}
                            onConfirm={() => {
                              deleteQuestion.onApprove();
                              setDeleteQuestion(null);
                            }}
                            isLoading={false}
                          />
                        ) : showQuestionModel ? (
                          <QuestionWarpper
                            onClose={() => setShowQuestionModel(null)}
                            onSubmit={(question) => {
                              showQuestionModel.onSubmit(question, showQuestionModel.after);
                              setShowQuestionModel(null);
                            }}
                            courseId={courseId}
                            questionTypes={questionTypes}
                            disabled={showQuestionModel.disabled}
                            questionData={showQuestionModel.questionData}
                          ></QuestionWarpper>
                        ) : showQuestionBankModel ? (
                          <QuestionBank
                            onClose={() => setShowQuestionBankModel(null)}
                            bankQuestions={bankQuestions}
                            onSelect={(questions) => {
                              showQuestionBankModel.onSubmit(
                                bankQuestions
                                  .filter((i) => questions.includes(i.id))
                                  .map((i) => {
                                    let item = getQuestionInitialValues(i);
                                    delete item.id;
                                    item.options.forEach((option) => {
                                      delete option.id;
                                    });
                                    return item;
                                  }),
                                showQuestionBankModel.after
                              );
                              setShowQuestionBankModel(null);
                            }}
                          />
                        ) : (
                          <></>
                        )}
                      </BasicModal>
                      <div className="tw-bg-white tw-shadow tw-rounded tw-p-8 tw-space-y-8">
                        <div className="tw-flex tw-items-center tw-justify-between">
                          <div className="tw-text-teal-500 tw-text-lg sm:tw-text-2xl">
                            {id ? t("edit_quiz") : t("add_quiz")}
                          </div>
                          <button
                            type="button"
                            onClick={() => {
                              setFieldValue("active", !values.active);
                            }}
                            className={`tw-relative tw-w-20 tw-py-2 tw-px-1 tw-rounded-full tw-flex tw-items-center tw-justify-between tw-transition-all ${
                              values.active
                                ? "tw-bg-teal-800 tw-text-white tw-pl-7"
                                : "tw-bg-gray-200 !tw-text-gray-700 tw-pr-7"
                            }`}
                          >
                            <div className="tw-text-xs tw-white-space-nowrap tw-grow tw-text-center">
                              {t(!values.active ? "Exhibition.inactive" : "Exhibition.active")}
                            </div>
                            <div
                              className={`tw-absolute tw-top-1/2 -tw-translate-y-1/2 ${
                                values.active ? "tw-left-2" : "tw-right-2"
                              } tw-h-5 tw-w-5 tw-bg-white tw-shadow tw-rounded-full`}
                            ></div>
                          </button>
                        </div>
                        <div className="tw-space-y-4">
                          <div>
                            <label className="tw-block mb-0">
                              <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("quiz_title")}</div>

                              <Field
                                name="title"
                                disabled={quiz.users_answered_count}
                                className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                                placeholder={t("quiz_title")}
                                maxLength={5000}
                              />
                            </label>
                            <ErrorMessage
                              name={`title`}
                              component="div"
                              className="tw-text-xs tw-text-red-700 tw-h-4"
                            />
                          </div>

                          <div className="sm:tw-flex tw-items-center sm:tw-space-s-4">
                            <label className="tw-flex tw-items-center tw-space-s-2 tw-mb-2">
                              <Field
                                name="random"
                                type="checkbox"
                                disabled={quiz.users_answered_count || values.monitored}
                                className="!tw-w-auto tw-border tw-border-gray-300 tw-rounded tw-text-teal-700"
                              />
                              <div className="tw-text-gray-600 tw-font-medium">{t("randomize_qusetion")}</div>
                            </label>
                            <label className="tw-flex tw-items-center tw-space-s-2 tw-mb-2">
                              <Field
                                name="can_back"
                                type="checkbox"
                                disabled={quiz.users_answered_count || values.monitored}
                                className="!tw-w-auto tw-border tw-border-gray-300 tw-rounded tw-text-teal-700"
                              />
                              <div className="tw-text-gray-600 tw-font-medium">{t("active_previos_button")}</div>
                            </label>
                            <label className="tw-flex tw-items-center tw-space-s-2 tw-mb-2">
                              <Field
                                name="include_view_per"
                                type="checkbox"
                                // disabled={quiz.users_answered_count}
                                className="!tw-w-auto tw-border tw-border-gray-300 tw-rounded tw-text-teal-700"
                              />
                              <div className="tw-text-gray-600 tw-font-medium">{t("include_view_per")}</div>
                            </label>
                            <label className="tw-flex tw-items-center tw-space-s-2 tw-mb-2">
                              <Field
                                name="include_achivement_per"
                                type="checkbox"
                                // disabled={quiz.users_answered_count}
                                className="!tw-w-auto tw-border tw-border-gray-300 tw-rounded tw-text-teal-700"
                              />
                              <div className="tw-text-gray-600 tw-font-medium">{t("include_achivement_per")}</div>
                            </label>
                            <label className="tw-flex tw-items-center tw-space-s-2 tw-mb-2">
                              <Field
                                name="show_mark"
                                type="checkbox"
                                disabled={quiz.users_answered_count || values.monitored}
                                className="!tw-w-auto tw-border tw-border-gray-300 tw-rounded tw-text-teal-700"
                              />
                              <div className="tw-text-gray-600 tw-font-medium">{t("show_grade_trianee")}</div>
                            </label>
                            {values.show_mark ? (
                              <label className="tw-flex tw-items-center tw-space-s-2 tw-mb-2">
                                <Field
                                  name="show_answers"
                                  type="checkbox"
                                  disabled={quiz.users_answered_count || values.monitored}
                                  className="!tw-w-auto tw-border tw-border-gray-300 tw-rounded tw-text-teal-700"
                                />
                                <div className="tw-text-gray-600 tw-font-medium">{t("show_answers_trianee")}</div>
                              </label>
                            ) : (
                              <></>
                            )}
                          </div>

                          {values.show_mark && !values.monitored ? (
                            <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-4">
                              <div>
                                <label className="tw-block">
                                  <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                                    {t("trainer.quiz.crud.show_mark_condition")}
                                  </div>

                                  <Select
                                    id="show_mark_condition"
                                    isClearable
                                    defaultValue={showMarkConditions.find(
                                      (item) => item.value == values.show_mark_condition
                                    )}
                                    options={showMarkConditions}
                                    onChange={(item) => {
                                      if (item) {
                                        setFieldValue("show_mark_condition", item.value);
                                      }
                                    }}
                                    placeholder={t("trainer.quiz.crud.show_mark_condition")}
                                  />
                                </label>

                                <ErrorMessage
                                  name={`show_mark_condition`}
                                  component="div"
                                  className="tw-text-xs tw-text-red-700 tw-h-4"
                                />
                              </div>
                              {values.show_mark_condition == 1 ? (
                                <div>
                                  <label className="tw-block">
                                    <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                                      {t("trainer.quiz.crud.show_mark_condition_date")}
                                    </div>

                                    <div className="tw-relative">
                                      <DatePicker
                                        popperProps={{
                                          strategy: "fixed",
                                        }}
                                        selected={Date.parse(values.show_mark_condition_date)}
                                        defaultValue={Date.parse(values.show_mark_condition_date)}
                                        onChange={(date) => setFieldValue("show_mark_condition_date", date)}
                                        placeholderText={t("trainer.quiz.crud.show_mark_condition_date")}
                                        className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                                        dateFormat={"yyyy-MM-dd"}
                                        minDate={values.start_date ? Date.parse(values.start_date) : new Date()}
                                      />
                                      <CalendarIcon className="tw-h-4 tw-w-4 tw-absolute tw-top-1/2 -tw-translate-y-1/2 tw-end-3" />
                                    </div>
                                  </label>

                                  <ErrorMessage
                                    name={`show_mark_condition_date`}
                                    component="div"
                                    className="tw-text-xs tw-text-red-700 tw-h-4"
                                  />
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          ) : (
                            <></>
                          )}

                          {/* description field */}
                          <div>
                            <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("quiz_description")}</div>

                            <SunEditorText
                              disabled={quiz.users_answered_count}
                              value={values.description}
                              setValue={(value) => {
                                setFieldValue("description", value);
                              }}
                              dirEditor={"rtl"}
                              maxLength={5000}
                            />

                            <ErrorMessage
                              name={`description`}
                              component="div"
                              className="tw-text-xs tw-text-red-700 tw-h-4"
                            />
                          </div>

                          <div className="tw-h-full">
                            <label className="tw-block tw-h-full">
                              <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                                {t("crud.placeholders.objectives")}
                              </div>
                              <AsyncSelect
                                isDisabled={quiz.users_answered_count}
                                placeholder={t("crud.placeholders.objectives")}
                                cacheOptions
                                defaultOptions
                                isClearable
                                defaultValue={quiz.course_objectives}
                                loadOptions={promiseOptions}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                isMulti
                                onChange={(items) => {
                                  setFieldValue(
                                    "objectives",
                                    items.map((item) => item.id)
                                  );
                                }}
                              />
                            </label>

                            <ErrorMessage
                              name={`objectives`}
                              component="div"
                              className="tw-text-xs tw-text-red-700 tw-h-4"
                            />
                          </div>

                          <div>
                            <div className="tw-flex tw-items-center tw-space-s-4 tw-mb-2">
                              <div className="tw-text-gray-600 tw-font-medium">{t("sponsor.traineeName")}</div>
                              <div className="tw-text-teal-400 tw-text-xs">{t("noselect_note")}</div>
                            </div>
                            <FieldArray name="trainees" disabled={quiz.users_answered_count}>
                              {({ insert, remove, push }) => (
                                <div className="tw-border tw-rounded tw-divide-y">
                                  <div className="tw-bg-gray-100 tw-p-4 md:tw-flex tw-items-center tw-justify-between">
                                    <div className="tw-flex tw-items-center tw-space-s-4">
                                      <Field
                                        disabled={quiz.users_answered_count}
                                        type="checkbox"
                                        name="except_users"
                                        onChange={() => {
                                          setValues({
                                            ...values,
                                            trainees: [],
                                            except_users: !values.except_users,
                                          });
                                        }}
                                        className={`tw-h-4 tw-w-4 tw-shrink-0 tw-appearance-none tw-border-[1px] tw-rounded tw-border-gray-300 checked:tw-bg-teal-600 checked:tw-border-white checked:tw-border-x-[3px] ${
                                          values.trainees.length
                                            ? "checked:tw-border-y-[6px]"
                                            : "checked:tw-border-y-[3px]"
                                        }`}
                                      />
                                      <div className="tw-font-semibold tw-text-gray-500">{t("select_names")}</div>
                                    </div>
                                    <div className="tw-flex tw-items-center tw-space-s-8">
                                      <Field
                                        name="search"
                                        className="tw-w-full tw-p-2.5 tw-border-s tw-bg-transparent"
                                        placeholder={t("search_by_name_or_email")}
                                        onChange={({ target: { value } }) => {
                                          setFieldValue("search", value);
                                          fetchUsers({
                                            ...values,
                                            search: value,
                                          });
                                        }}
                                      />
                                      <li className="fal fa-search tw-text-teal-500 tw-w-6"></li>
                                    </div>
                                  </div>
                                  <div className="tw-py-4 tw-pe-4">
                                    <div className="tw-divide-y tw-divide-black/5 tw-overflow-y-auto inner-scrollbar tw-max-h-[40vh] tw-pe-4">
                                      {userFetching ? (
                                        [...Array(5).keys()].map((i) => (
                                          <div
                                            key={i}
                                            className={`md:tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-8 tw-w-full tw-text-gray-500 tw-animate-pulse`}
                                          >
                                            <div className="tw-flex tw-items-center tw-space-s-4">
                                              <div
                                                className={`tw-h-4 tw-w-4 tw-shrink-0 tw-border-[1px] tw-rounded tw-border-gray-300`}
                                              ></div>
                                              <div className="tw-bg-gray-200 tw-rounded-full tw-h-3 tw-my-1.5 tw-w-40"></div>
                                            </div>
                                            <div className="tw-bg-gray-200 tw-rounded-full tw-h-3 tw-my-1.5 tw-w-60"></div>
                                          </div>
                                        ))
                                      ) : trainees.length ? (
                                        trainees.map((user) => (
                                          <button
                                            key={user.id}
                                            disabled={quiz.users_answered_count}
                                            type="button"
                                            onClick={() => {
                                              const userIndex = values.trainees?.findIndex((i) => i == user.id);
                                              if (userIndex != undefined && userIndex >= 0) remove(userIndex);
                                              else push(user.id);
                                            }}
                                            className={`md:tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-8 tw-w-full`}
                                          >
                                            <div className="tw-flex tw-items-center tw-space-s-4">
                                              <div
                                                className={`tw-h-4 tw-w-4 tw-shrink-0 tw-border-[1px] tw-rounded  ${
                                                  (values.trainees.includes(user.id) && !values.except_users) ||
                                                  (!values.trainees.includes(user.id) && values.except_users)
                                                    ? "tw-border-gray-100 tw-border-[3px] tw-bg-teal-600"
                                                    : "tw-border-gray-300"
                                                }`}
                                              ></div>
                                              <div>{user.name}</div>
                                            </div>
                                            <div>{user.email}</div>
                                          </button>
                                        ))
                                      ) : (
                                        <div className="tw-text-gray-500 tw-text-lg tw-p-8 tw-text-center">
                                          {t("there_no_matching_users")}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </FieldArray>
                            <ErrorMessage
                              name={`trainees`}
                              component="div"
                              className="tw-text-xs tw-text-red-700 tw-h-4"
                            />
                          </div>

                          <div>
                            <label className="tw-block mb-0">
                              <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("pass_percentage")}</div>
                              <Field
                                name="success_rate"
                                type="number"
                                min={1}
                                max={100}
                                disabled={quiz.users_answered_count}
                                className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                                placeholder={t("pass_percentage")}
                              />
                            </label>
                            <ErrorMessage
                              name={`success_rate`}
                              component="div"
                              className="tw-text-xs tw-text-red-700 tw-h-4"
                            />
                          </div>
                          <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-4">
                            <div>
                              <label className="tw-block">
                                <div className="tw-text-gray-600 tw-font-medium tw-block tw-mb-2">{t("time_for")}</div>
                                <div className="tw-w-full">
                                  <div className={`tw-flex tw-items-stretch tw-w-full tw-text-gray-500`}>
                                    <div className="tw-grow tw-min-w-0">
                                      <Field
                                        type="number"
                                        min={1}
                                        disabled={quiz.users_answered_count}
                                        name="period"
                                        placeholder={t("time_for")}
                                        className="tw-w-full tw-border tw-border-gray-200 tw-p-2.5 tw-text-gray-500 tw-rounded-s"
                                      />
                                    </div>
                                    <div className="tw-flex tw-font-medium tw-text-gray-500 tw-items-center tw-p-2.5 tw-rounded-e tw-whitespace-nowrap tw-border-y tw-border-e">
                                      {t("minutes")}
                                    </div>
                                  </div>
                                </div>
                              </label>

                              <ErrorMessage
                                name={`period`}
                                component="div"
                                className="tw-text-xs tw-text-red-700 tw-h-4"
                              />
                            </div>

                            <div>
                              <label className="tw-block mb-0">
                                <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("number_exam_try")}</div>

                                <Field
                                  name="answer_attempts"
                                  type="number"
                                  disabled={values.monitored}
                                  min={1}
                                  className="tw-block tw-appearance-none tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                                  placeholder={t("number_exam_try")}
                                />
                              </label>
                              <ErrorMessage
                                name={`answer_attempts`}
                                component="div"
                                className="tw-text-xs tw-text-red-700 tw-h-4"
                              />
                            </div>
                          </div>

                          <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-4">
                            <div>
                              <label className="tw-block">
                                <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("start_date")}</div>

                                <div className="tw-relative">
                                  <DatePicker
                                    popperProps={{
                                      strategy: "fixed",
                                    }}
                                    disabled={quiz.users_answered_count}
                                    selected={Date.parse(values.start_date)}
                                    defaultValue={Date.parse(values.start_date)}
                                    onChange={(date) => {
                                      setFieldValue("start_date", date);
                                      if (date > values.end_date) setFieldValue("end_date", "");
                                    }}
                                    showTimeSelect
                                    placeholderText={t("start_date")}
                                    className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                                    showTime={{ use12Hours: true }}
                                    dateFormat={"yyyy-MM-dd hh:mm:ss a"}
                                    minDate={new Date()}
                                    minTime={
                                      values.start_date &&
                                      new Date(new Date(values.start_date).toDateString()) >
                                        new Date(new Date().toDateString())
                                        ? undefined
                                        : Date.now()
                                    }
                                    maxTime={
                                      new Date(new Date(values.start_date).toDateString()) >
                                      new Date(new Date().toDateString())
                                        ? undefined
                                        : new Date().setHours(23, 59, 0, 0)
                                    }
                                  />
                                  <CalendarIcon className="tw-h-4 tw-w-4 tw-absolute tw-top-1/2 -tw-translate-y-1/2 tw-end-3" />
                                </div>
                              </label>

                              <ErrorMessage
                                name={`start_date`}
                                component="div"
                                className="tw-text-xs tw-text-red-700 tw-h-4"
                              />
                            </div>

                            <div>
                              <label className="tw-block">
                                <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("end_date")}</div>

                                <div className="tw-relative">
                                  <DatePicker
                                    popperProps={{
                                      strategy: "fixed",
                                    }}
                                    selected={Date.parse(values.end_date)}
                                    defaultValue={Date.parse(values.end_date)}
                                    onChange={(date) => setFieldValue("end_date", date)}
                                    showTimeSelect
                                    placeholderText={t("end_date")}
                                    className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                                    showTime={{ use12Hours: true }}
                                    dateFormat={"yyyy-MM-dd hh:mm:ss a"}
                                    minDate={values.start_date ? Date.parse(values.start_date) : new Date()}
                                    minTime={
                                      values.start_date
                                        ? values.end_date &&
                                          new Date(new Date(values.end_date).toDateString()) >
                                            new Date(new Date(values.start_date).toDateString())
                                          ? undefined
                                          : Date.parse(values.start_date)
                                        : values.end_date &&
                                          new Date(new Date(values.end_date).toDateString()) >
                                            new Date(new Date().toDateString())
                                        ? undefined
                                        : Date.now()
                                    }
                                    maxTime={
                                      (values.start_date &&
                                        values.end_date &&
                                        new Date(new Date(values.end_date).toDateString()) >
                                          new Date(new Date(values.start_date).toDateString())) ||
                                      (!values.start_date &&
                                        values.end_date &&
                                        new Date(new Date(values.end_date).toDateString()) >
                                          new Date(new Date().toDateString()))
                                        ? undefined
                                        : new Date().setHours(23, 59, 0, 0)
                                    }
                                  />
                                  <CalendarIcon className="tw-h-4 tw-w-4 tw-absolute tw-top-1/2 -tw-translate-y-1/2 tw-end-3" />
                                </div>
                              </label>

                              <ErrorMessage
                                name={`end_date`}
                                component="div"
                                className="tw-text-xs tw-text-red-700 tw-h-4"
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          <label className="tw-flex tw-items-center tw-space-s-2 tw-mb-2">
                            <Field
                              name="monitored"
                              type="checkbox"
                              disabled={quiz.users_answered_count}
                              className="!tw-w-auto tw-border tw-border-gray-300 tw-rounded tw-text-teal-700"
                            />
                            <div className="tw-text-gray-600 tw-font-medium">{t("monitored")}</div>
                          </label>
                        </div>
                        {values.monitored ? (
                          <div>
                            <label className="tw-block mb-0">
                              <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                                {t("trainee.quiz.total_grade")}
                              </div>
                              <Field
                                name="total_grade"
                                type="number"
                                min={1}
                                disabled={quiz.users_answered_count}
                                className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                                placeholder={t("trainee.quiz.total_grade")}
                              />
                            </label>
                            <ErrorMessage
                              name={`total_grade`}
                              component="div"
                              className="tw-text-xs tw-text-red-700 tw-h-4"
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                        {values.monitored ? (
                          <></>
                        ) : (
                          <div>
                            <div className="tw-text-teal-600 tw-text-xl tw-mb-4">{t("quiz_questions")}</div>

                            <FieldArray name="questions" disabled={quiz.users_answered_count}>
                              {({ insert, remove, push }) => (
                                <div>
                                  <div className="tw-pb-2">
                                    {quiz.users_answered_count ? (
                                      <></>
                                    ) : (
                                      <AddQuestion
                                        index={0}
                                        onNewQuestion={() =>
                                          setShowQuestionModel({
                                            onSubmit: (question, after) => {
                                              insert(0, question);
                                            },
                                          })
                                        }
                                        onBankQuestion={() => {
                                          setShowQuestionBankModel({
                                            onSubmit: (questions, after) => {
                                              questions.forEach((question, index) => insert(index, question));
                                            },
                                          });
                                        }}
                                      />
                                    )}
                                  </div>
                                  <SortableList
                                    disabled={quiz.users_answered_count}
                                    questions={values.questions}
                                    onSortEnd={({ oldIndex, newIndex }) => {
                                      setFieldValue(`questions`, reorderItems(oldIndex, newIndex, values.questions));
                                    }}
                                    values={values}
                                    errors={errors}
                                    setFieldValue={setFieldValue}
                                    remove={remove}
                                    push={push}
                                    insert={insert}
                                    disableAutoscroll
                                    useDragHandle={true}
                                    preview={preview}
                                  />
                                </div>
                              )}
                            </FieldArray>
                            {errors.questions?.length && typeof errors.questions != "string" ? (
                              <div className="tw-text-xs tw-text-red-700 tw-h-4">
                                {t("not_all_questions_are_ready")}
                              </div>
                            ) : (
                              <ErrorMessage
                                name="questions"
                                component="div"
                                className="tw-text-xs tw-text-red-700 tw-h-4"
                              />
                            )}
                          </div>
                        )}

                        <div className="tw-flex tw-items-stretch tw-justify-end tw-space-s-4">
                          <button
                            onClick={() => {
                              setPreviewQuestions(questionsToPreview(values.questions));
                              setPreview(true);
                            }}
                            type="button"
                            className="tw-flex tw-items-center tw-justify-center tw-bg-teal-700 tw-py-2 tw-px-3 tw-rounded"
                          >
                            <li className={`fal tw-w-4 tw-text-white ${preview ? "fa-eye-slash" : "fa-eye"}`} />
                          </button>
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="tw-bg-teal-700 tw-py-2 tw-px-16 tw-rounded tw-text-white disabled:tw-bg-teal-700/50"
                          >
                            {t("survey.buttons.save")}
                          </button>
                        </div>
                      </div>
                    </>
                  </form>
                )}
              </Formik>
            </div>
          </>
        )}
      </div>
    </>
  );
}

function QuestionItem({
  values,
  errors,
  setFieldValue,
  setValues,
  item,
  questionTypes,
  disabled = false,
  itemIndex,
  onEdit,
  onDelete,
}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [show, setShow] = useState(false);

  const { t } = useTranslation();
  const type = questionTypes.find((type) => type.id == item?.type_id);
  const questionType = QuestionTypes.find((type) => type.type == item?.type_id);
  const SortableHandler = SortableHandle(({}) => <MemuIcon className="tw-h-4 tw-w-4 tw-cursor-move tw-shrink-0" />);
  return (
    <div className="tw-border tw-border-gray-300 tw-rounded tw-divide-y">
      <div
        onClick={() => {
          setShow(!show);
          setIsLoaded(true);
        }}
        className="tw-flex tw-items-center tw-justify-between tw-w-full tw-p-2 tw-text-gray-500 tw-cursor-pointer"
      >
        <div className="tw-flex tw-items-center tw-grow tw-overflow-hidden tw-space-s-4">
          {disabled ? <></> : <SortableHandler />}
          <div
            className={`tw-flex tw-items-center tw-flex-wrap ${
              Array.isArray(errors.questions) &&
              errors.questions[itemIndex] &&
              Object.keys(errors.questions[itemIndex]).length
                ? "tw-text-red-500"
                : ""
            }`}
          >
            <div dangerouslySetInnerHTML={{ __html: item.title }} />{" "}
            <div className="tw-whitespace-nowrap">{`(${
              i18n.language == Lang.EN ? type?.name_en : type?.name_ar
            })`}</div>
          </div>
        </div>
        <div className="tw-flex tw-items-center tw-space-s-4">
          <button
            onClick={(e) => {
              e.stopPropagation();
              onEdit();
            }}
            type="button"
            className="tw-bg-white"
          >
            <PenIcon className="tw-h-4 tw-w-4 tw-stroke-amber-500" />
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
            type="button"
            className="tw-bg-white"
          >
            <CloseIcon className="tw-h-4" />
          </button>
        </div>
      </div>
      <div className={`tw-p-4 ${show ? "" : "tw-hidden"}`}>
        {isLoaded ? (
          <div className="tw-space-y-3">
            <div
              dangerouslySetInnerHTML={{
                __html: values.text,
              }}
            />
            <JustPreview>
              <div className="tw-overflow-x-auto inner-scrollbar tw-pb-4">
                <questionType.viewComponent
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  setValues={setValues}
                  question={{ id: itemIndex + 1, ...item }}
                  questionIndex={itemIndex}
                  revision
                />
              </div>
            </JustPreview>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
