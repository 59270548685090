import env from "../Environment";
import connector from "../Handler";
import URLBuilder from "../UrlBuilder";

export function createExhibition(payload) {
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.create_exhibition, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function editExhibition(id, payload) {
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.edit_exhibition, { id: id }), payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function exhibitionsList({ perPage, page, filter }) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.exhibitions_list, {
          params: {
            page,
            perPage,
            ...filter,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
  return myPromise;
}
export function setExhibitionsStatus({ id }) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.exhibitions_status, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function deleteExhibition({ id }) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .delete(URLBuilder(env.get_exhibition, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function deleteExhibitionPlan(lessonId, id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .delete(URLBuilder(env.delete_exhibition_plan, { lessonId, id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function addExhibitionPlan(lessonId, payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.add_exhibition_plan, { lessonId }), payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getExhibition(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_exhibition_with_dashboard, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
  return myPromise;
}
export function updateExhibition(id, payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.get_exhibition, { id: id }), payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
  return myPromise;
}

export function getExhibitionComing() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.exhibition_coming)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
  return myPromise;
}

export function getExhibitionList(params = {}) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(
          env.exhibition,
          {
            params: params,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
  return myPromise;
}

export function getExhibitionListGuest(params = {}) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.exhibition_guest_list, {
          params: params,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
  return myPromise;
}

export function ExhibitionVisit(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.exhibition_visit, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function createbooth(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.booth, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function deleteBooth(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .delete(URLBuilder(env.update_booth, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function cancelBoothForSponsor(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.cancel_booth_for_sponsor, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function cancelBoothForAdmin(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.cancel_booth_for_admin, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}


export function getExhibitionPositions(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.exhibition_positions, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
  return myPromise;
}

export function getBooth(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_booth, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
  return myPromise;
}

export function getExhibitionBooths(exhibition_id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_exhibition_booths, {
          params: { exhibition_id: exhibition_id },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
  return myPromise;
}
export function getExhibitionBoothsBooking(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_exhibition_booths_bookings, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
  return myPromise;
}
export function getExhibitionBoothsBookingDetails(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_exhibition_booths_bookings_details, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
  return myPromise;
}

export function getBoothRatings(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_booth_ratings, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
  return myPromise;
}

export function Updatebooth(id, payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.Update_booth, { id: id }), payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getStatisticsBooth(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_statistics_booths, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
  return myPromise;
}

export function addBoothToCart(boothId, paymentAmount) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.add_booth_to_cart, {
          booth_id: boothId,
          email: localStorage.getItem("email"),
          purchase_type: "booth",
          booth_amount: paymentAmount,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function payAddBoothWithBankTransfer(formData) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.cart_pay, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function approveBooth(formData) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.approve_booth, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function rejectBooth(formData) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.reject_booth, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function exportExhibition(type, params) {
  let extension = "";
  type === "pdf" ? (extension = "pdf") : (extension = "xlsx");
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.exhibitions_export, {
          responseType: "blob",
          params: {
            ...params,
            export: extension,
          },
        })
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            if (type === "pdf") {
              link.setAttribute("download", "exhibitions-list" + "." + "pdf");
            } else {
              link.setAttribute("download", "exhibitions-list" + "." + "xlsx");
            }
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function exportExhibitionBooths(id, type, params) {
  let extension = "";
  type === "pdf" ? (extension = "pdf") : (extension = "xlsx");
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(
          URLBuilder(env.exhibitions_booths_export, {
            id,
          }),
          {
            responseType: "blob",
            params: {
              ...params,
              export: extension,
            },
          }
        )
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            if (type === "pdf") {
              link.setAttribute("download", "booths-list" + "." + "pdf");
            } else {
              link.setAttribute("download", "booths-list" + "." + "xlsx");
            }
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function exportExhibitionUsers(id, type, params) {
  let extension = "";
  type === "pdf" ? (extension = "pdf") : (extension = "xlsx");
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(
          URLBuilder(env.exhibitions_users_export, {
            id,
          }),
          {
            responseType: "blob",
            params: {
              ...params,
              export: extension,
            },
          }
        )
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            if (type === "pdf") {
              link.setAttribute("download", "exhibitions-list" + "." + "pdf");
            } else {
              link.setAttribute("download", "exhibitions-list" + "." + "xlsx");
            }
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function exportProductsBooth(id, type) {
  let extension = "";
  type === "pdf" ? (extension = "pdf") : (extension = "xlsx");
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(
          URLBuilder(env.exhibitions_products_booth, {
            id,
          }),
          {
            responseType: "blob",
            params: {
              export: extension,
            },
          }
        )
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            if (type === "pdf") {
              link.setAttribute("download", "exhibitions-list" + "." + "pdf");
            } else {
              link.setAttribute("download", "exhibitions-list" + "." + "xlsx");
            }
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function exportVisitsBooth({ id, type, filter }) {
  let extension = "";
  type === "pdf" ? (extension = "pdf") : (extension = "xlsx");
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(
          URLBuilder(env.export_booth_visitors, {
            id,
          }),
          {
            responseType: "blob",
            params: {
              export: extension,
              ...filter,
            },
          }
        )
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            if (type === "pdf") {
              link.setAttribute("download", "booth-visits-list" + "." + "pdf");
            } else {
              link.setAttribute("download", "booth-visits-list" + "." + "xlsx");
            }
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function exportExhibitionsVisits({ id, type }) {
  let extension = "";
  type === "pdf" ? (extension = "pdf") : (extension = "xlsx");

  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(
          URLBuilder(env.export_exhibitions_visitors, {
            id,
          }),
          {
            responseType: "blob",
            params: {
              export: extension,
            },
          }
        )
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            if (type === "pdf") {
              link.setAttribute("download", "booth-list-report" + "." + "pdf");
            } else {
              link.setAttribute("download", "booth-list-report" + "." + "xlsx");
            }
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function exportExhibitionFinancialReports({ id, type }) {
  let extension = "";
  type === "pdf" ? (extension = "pdf") : (extension = "xlsx");

  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(
          URLBuilder(env.export_exhibitions_financial_report, {
            id,
          }),
          {
            responseType: "blob",
            params: {
              export: extension,
            },
          }
        )
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            if (type === "pdf") {
              link.setAttribute("download", "booth-list-report" + "." + "pdf");
            } else {
              link.setAttribute("download", "booth-list-report" + "." + "xlsx");
            }
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
