import {CoursesList, CoursesStd, MainSection} from "./";
import Footer from "../../Shared/Components/footer/footer";
import Tracks from "../../home/tracks/tracks";
import Trainers from "../../home/trainers/trainers";
import {TrainerProvider} from "../../../Services/api/toutorials/trainerContext";

function Home() {
  require("./homeTrainee.css");
  return (
    <>
      <MainSection />
      <CoursesStd />
      <CoursesList />
      <Tracks />
      <TrainerProvider>
        <Trainers />
      </TrainerProvider>
      <Footer />
    </>
  );
}

export default Home;
