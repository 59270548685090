import env from "./Environment";
import connector from "./Handler";

export function addActivityRate(data) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.add_activity_rate, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
