import { useState } from "react";
import { useTranslation } from "react-i18next";
// import { finanicalDashboard } from "../../../Services/api/Financial/FinancialProvider";
// import { getSurveysStatistics } from "../../../Services/api/survey/SurveyProvider";
// import BarChart from "./barChart/BarChart";
// import BarChartSurveys from "./barChart/BarChartSurveys";
import CounterNumbersListTop from "./counterNumbers/CounterNumbersListTop";
// import SimpleFloatCounter from "./counterNumbers/SimpleFloatCounter";
// import DoughnutChart from "./doughnutChart/DoughnutChart";

function Dashboard(props) {
  require("./Dashboard.css");
  const { t } = useTranslation();
  let str = props.location.pathname;
  const locationStr = str.split("/");
  const [isLoading, setIsLoading] = useState(true);
  const [counter, setCounter] = useState();
  // const [coupons, setCoupons] = useState([]);
  // const [courses, setCourses] = useState([]);
  // const [setting, setSetting] = useState(false);
  // const [countSurveys, setCountSurveys] = useState([]);
  // const [countParticipants, setCountParticipants] = useState([]);
  // const [countAnswerd, setCountAnswerd] = useState([]);
  // const [countNotAnswerd, setCountNotAnswerd] = useState([]);
  // const [doughnutChart, setDoughnutChart] = useState(
  //   localStorage.getItem("doughnutChart") ? localStorage.getItem("doughnutChart") : 0
  // );
  // const [counterNumbers, setCounterNumbers] = useState(
  //   localStorage.getItem("counterNumbers") ? localStorage.getItem("counterNumbers") : 0
  // );
  // const [barChart, setBarChart] = useState(localStorage.getItem("barChart") ? localStorage.getItem("barChart") : 0);
  // const [barChartSurveys, setBarChartSurveys] = useState(
  //   localStorage.getItem("barChartSurveys") ? localStorage.getItem("barChartSurveys") : 0
  // );

  // const fetchFinancialDashboard = () => {
  //   finanicalDashboard()
  //     .then((res) => {
  //       if (res.status && res.status == 200 && res.data.status) {
  //         setCounter(res.data.data);
  //         setCoupons(res.data.data.coupons);
  //         setCourses(res.data && res.data.data.courses, fetchSurveysStatistics());
  //         setIsLoading(false);
  //       }
  //     })
  //     .catch((err) => {});
  // };

  // const fetchSurveysStatistics = () => {
  //   getSurveysStatistics()
  //     .then((res) => {
  //       const newSurveysArr = Object.values(
  //         res.data.statistics.surveys.reduce((survey, item) => {
  //           survey[item.survey_id] = survey[item.survey_id]
  //             ? {
  //                 ...item,
  //                 total_response: item.total_responses + survey[item.survey_id].total_responses,
  //                 total_user: item.total_users + survey[item.survey_id].total_users,
  //               }
  //             : item;
  //           return survey;
  //         }, {})
  //       );

  //       let countSurveys = [];
  //       let countParticipants = [];
  //       let countAnswerd = [];
  //       let countNotAnswerd = [];
  //       if (res.status && res.status == 200 && res.data.status) {
  //         newSurveysArr.map((item, i) => {
  //           item.total_not_response =
  //             item.total_user == undefined
  //               ? item.total_users - item.total_responses
  //               : item.total_user - item.total_response;
  //           countSurveys.push(item.survey_name);
  //           countParticipants.push(item.total_user);
  //           countAnswerd.push(item.total_response);
  //           countNotAnswerd.push(item.total_not_response);
  //         });
  //         setCountParticipants(countParticipants);
  //         setCountAnswerd(countAnswerd);
  //         setCountNotAnswerd(countNotAnswerd);
  //         setCountSurveys(countSurveys);
  //         setIsLoading(false);
  //       }
  //     })
  //     .catch((err) => {});
  // };

  // useEffect(() => {
  //   // fetchFinancialDashboard();
  // }, []);

  // const counterNumbersHandel = (e) => {
  //   localStorage.setItem("counterNumbers", e.target.value);
  //   setCounterNumbers(e.target.value);
  // };

  // const barChartHandel = (e) => {
  //   localStorage.setItem("barChart", e.target.value);
  //   setBarChart(e.target.value);
  // };

  // const doughnutChartHandel = (e) => {
  //   localStorage.setItem("doughnutChart", e.target.value);
  //   setDoughnutChart(e.target.value);
  // };

  // const barChartSurveysHandel = (e) => {
  //   localStorage.setItem("barChartSurveys", e.target.value);
  //   setBarChartSurveys(e.target.value);
  // };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="admin_label-heading tw-py-8">
            {locationStr[1] == "accountant" ? t("admin.label.accountant_label") : t("admin.label.admin_label")}
          </div>
          <CounterNumbersListTop counter={counter} isLoading={isLoading} />
          {/* <>
            <div className="wrapper-chart">
              {(localStorage.getItem("counterNumbers") == 1 || localStorage.getItem("type") !== "admin") && (
                <div className="left-chart">
                  <SimpleFloatCounter counter={counter} />
                </div>
              )}
            </div>
            <div className="wrapper-chart">
              {(localStorage.getItem("doughnutChart") == 1 || localStorage.getItem("type") !== "admin") && (
                <div className="left-chart">
                  <DoughnutChart coupons={coupons} />
                </div>
              )}
            </div>
            {(localStorage.getItem("barChart") == 1 || localStorage.getItem("type") !== "admin") && (
              <div
                style={{
                  marginBottom: "50px",
                  width: "100%",
                }}
              >
                <BarChart courses={courses} />
              </div>
            )}
            {(localStorage.getItem("barChartSurveys") == 1 || localStorage.getItem("type") !== "admin") && (
              <div
                style={{
                  marginBottom: "50px",
                  width: "100%",
                }}
              >
                <BarChartSurveys
                  countSurveys={countSurveys}
                  countParticipants={countParticipants}
                  countAnswerd={countAnswerd}
                  countNotAnswerd={countNotAnswerd}
                />
              </div>
            )}
          </> */}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
