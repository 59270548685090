import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';
import SearchDateField from './SearchDateField';
const SearchRadioDate = ({ tabs, size, onDateChange, disabled,onRadioChange,dateValue,dateArrayValues,resetFieldArrayValues,activeTab,onRadioValueChange}) => {

  const displayDateField = () => {
    const tab = tabs?.find((tab) => tab?.value === activeTab)
    if (!tab) return null
    switch (tab?.type) {
      case "date":
        return (
          <SearchDateField
            value={dateValue}
            placeholder={tab?.text}
            onChange={(date) => {
              onDateChange(tab?.filter, date, tab?.type)
            }}
            disabled={disabled}
            canClear={true}
          />
        )
      case "custom-date":
        return (
          <>
            {tab?.filters?.length && tab?.filters?.map((filter) => (
              <SearchDateField
                key={filter?.filter}
                value={dateArrayValues?.find((item) => item?.filter === filter?.filter)?.value} 
                placeholder={filter?.label}
                onChange={(date) => {
                  onDateChange(filter?.filter, date, tab?.type)
                }}
                disabled={disabled}
                canClear={true}
              />
            ))}
          </>
        )
      default:
        return null
    }
  }

  return (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "1rem",
        }}
      >
        {tabs?.map((tab) => (
          <Box sx={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            padding: "0 !important"
          }}>
            <FormControlLabel key={tab?.value} value={tab?.text} control={
              <Radio
                size={size || "medium"}
                checked={activeTab === tab?.value}
                onClick={() => {
                  onRadioValueChange(tab?.value)
                  if(tab?.type === "bool") {
                    onRadioChange(tab?.filter, tab?.text)
                  } else {
                    onRadioChange(tab?.filter, "")
                    resetFieldArrayValues()
                  }
                }}
              />}
              label={tab?.text}
              sx={{
                margin: "0",
                color: "#777777",
                '& .MuiFormControlLabel-label': {
                  fontFamily: "inherit"
                },
              }}
              disabled={disabled}
            />
          </Box>
        ))}
      </RadioGroup>
      <Box
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "1rem",
        }}
      >
        {displayDateField()}
      </Box>
    </FormControl>
  )
}

export default SearchRadioDate