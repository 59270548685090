import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as StarIcon } from "../../../../../../../assets/icons/star.svg";

export default function CommentRating({ rating, onChange, disabled }) {
  const { t } = useTranslation();
  const [stars, setStars] = useState(rating);
  const [over, setOver] = useState(0);

  return (
    <div className="tw-px-4 tw-flex tw-items-center tw-space-s-2 tw-mx-auto" onMouseLeave={() => setOver(0)}>
      {[...Array(5).keys()].map((item, index) => (
        <button
          type="button"
          key={index}
          onMouseOver={disabled ? null : () => setOver(index + 1)}
          onClick={
            disabled
              ? null
              : () => {
                  setStars(index + 1);
                  onChange(index + 1, () => {
                    setStars(stars);
                  });
                }
          }
        >
          <StarIcon
            className={`tw-h-6 tw-w-6 ${disabled ? "" : "hover:tw-w-8 hover:tw-h-8 hover:-tw-m-1"} ${
              (over ? over : stars) > index ? "tw-fill-teal-700 tw-stroke-teal-700" : "tw-fill-white tw-stroke-gray-500"
            }`}
          />
        </button>
      ))}
    </div>
  );
}
