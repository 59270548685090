import { useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import DeleteIcon from "../../../assets/icons/delete.svg";
import editIcon from "../../../assets/icons/pen.svg";
import { utcToLocal } from "../../../utils/utcToLocal";
import MainTable from "../../Shared/Components/MainTable/MainTable";
import TableActionsColumn from "../../Shared/Components/TableActionsColumn/TableActionsColumn";
import SwitchToggle from "../../Shared/Components/SwitchToggle/SwitchToggle";
import SharedModal from "../../Shared/Components/SharedModal/SharedModal";
import classes from "./detailedReport.module.css";
// import { updateTaxStatus } from "../../../Redux/Actions/Tax/taxList.action";
// import { deleteTax } from "../../../Redux/Actions/Tax/taxList.action";

const DetialedReportTable = ({ isAll }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  //   const [showDeleteModal, setShowDeleteModal] = useState(false);
  //   const [taxId, setTaxId] = useState(null);
  const history = useHistory();

  const displayMode = (parent_type) => {
    if (parent_type === 1) {
      return t("rate.label.course");
    } else {
      return t("general.program");
    }
  };

  //   const TaxActionsList = [
  //     {
  //       id: "edit",
  //       icon: <img src={editIcon} alt="" />,
  //       action: (id) => {
  //         history.push(
  //           `${
  //             localStorage.getItem("type") == "accountant"
  //               ? "/accountant"
  //               : "/admin"
  //           }/tax/edit/${id}`
  //         );
  //       },
  //       tooltip: t("general.edit"),
  //     },

  //     {
  //       id: "delete",
  //       icon: <img src={DeleteIcon} alt="" />,
  //       action: (id) => {
  //         deleteHandler(id);
  //       },
  //       tooltip: t("general.delete"),
  //     },
  //   ];

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData }) => <>{rowData?.id}</>,
    },
    {
      id: "name",
      name: t("name_student"),
      key: "name",
      cell: ({ rowData }) => (
        <div className={classes["coupon-list__table-name"]}>
          {rowData?.name || "-"}
        </div>
      ),
    },
    {
      id: "type",
      name: t("type"),
      key: "type",
      cell: ({ rowData }) => <>{rowData?.type}</>,
    },
    ...(isAll
      ? [
          {
            id: "main_chapter",
            name: t("main_chapter"),
            key: "main_chapter",
            cell: ({ rowData }) => <>{rowData?.main_chapter}</>,
          },
          {
            id: "subChapter",
            name: t("subChapter"),
            key: "subChapter",
            cell: ({ rowData }) => <>{rowData?.subChapter}</>,
          },
          {
            id: "lesson",
            name: t("lesson"),
            key: "lesson",
            cell: ({ rowData }) => <>{rowData?.lesson}</>,
          },
        ]
      : []),

    {
      id: "views",
      name: t("views"),
      key: "views",
      cell: ({ rowData }) => <>{rowData?.views}</>,
    },

    {
      id: "include_view_per",
      name: t("include_view_per"),
      key: "include_view_per",
      cell: ({ rowData }) => (
        <>
          {/* <span
            className="cell-font tw-whitespace-nowrap tw-rounded-lg tw-py-1 tw-px-2"
            style={{
              color: rowData?.parent_type == 1 ? "#8599B6" : "#12C689",
              backgroundColor:
                rowData?.parent_type == 1 ? "#C1CEE222" : "#C1E2C822",
            }}
          >
            {displayMode(rowData?.include_view_per) || "-"}
          </span> */}
          {rowData?.include_view_per}
        </>
      ),
    },
    {
      id: "include_achivement_per",
      name: t("include_achivement_per"),
      key: "include_achivement_per",
      cell: ({ rowData }) => (
        <>
          {/* <span
            className="cell-font tw-whitespace-nowrap tw-rounded-lg tw-py-1 tw-px-2"
            style={{
              color: rowData?.parent_type == 1 ? "#8599B6" : "#12C689",
              backgroundColor:
                rowData?.parent_type == 1 ? "#C1CEE222" : "#C1E2C822",
            }}
          >
            {displayMode(rowData?.include_achivement_per) || "-"}
          </span> */}
          {rowData?.include_achivement_per}
        </>
      ),
    },

    {
      id: "created_at",
      name: t("general.added_date"),
      key: "created_at",
      cell: ({ rowData }) => (
        <>
          {moment(rowData?.created_at).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData?.created_at)}
        </>
      ),
    },

    // {
    //   id: "actions",
    //   name: t("general.actions"),
    //   key: "actions",
    //   cell: ({ rowData }) => (
    //     <TableActionsColumn actions={TaxActionsList} id={rowData.id} />
    //   ),
    // },
    // {
    //   id: "status",
    //   name: t("general.status"),
    //   key: "status",
    //   cell: ({ rowData }) => (
    //     <SwitchToggle
    //       status={rowData.status}
    //       id={rowData.id}
    //       onStatusChange={() => {
    //         updateCourseStatusHandler(rowData.id);
    //       }}
    //     />
    //   ),
    //   sortable: false,
    // },
  ];

  return (
    <>
      <MainTable
        columns={columns}
        data={[]} //taxsList ||
        isLoading={false} //taxsListLoading
      />
    </>
  );
};

export default DetialedReportTable;
