import React from "react";
import style from "./SharedBox.module.css";

function SharedBox({
  type,
  icon,
  paymentWay,
  width,
  selected,
  handleSelectPaymentClick,
}) {
  return (
    <div
      key={type}
      className={style["payment-box-option"]}
      onClick={() => handleSelectPaymentClick(type)}
      style={
        selected
          ? {
              backgroundColor: "#24B3B9",
            }
          : {}
      }
    >
      <img
        src={icon}
        alt="payment-icon"
        className={style["payment-way-img"]}
        style={
          selected
            ? { width: `${width}px`, filter: "brightness(0) invert(1)" }
            : { width: `${width}px` }
        }
      />
      <h4
        className={style["payment-way-txt"]}
        style={selected ? { color: "#FFFFFF" } : {}}
      >
        {paymentWay}
      </h4>
    </div>
  );
}

export default SharedBox;
