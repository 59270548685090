import { useTranslation } from "react-i18next";
import chatIcon from "../../../../../assets/icons/chat1.png";
import DeleteIcon from "../../../../../assets/icons/delete.svg";
import DownloadIcon from "../../../../../assets/icons/download2.svg";
import examIcon from "../../../../../assets/icons/exam.png";
import editIcon from "../../../../../assets/icons/pen.svg";
import CheckIcon from "../../../../../assets/icons/save.png";

export const ChapterRowActions = ({
  assignmentsAction,
  downloadAction,
  editAction,
  deleteAction,
  usersAction,
  saveAction,
}) => {
  const { t, i18n } = useTranslation();
  const actionsList = [
    ...(downloadAction
      ? [
          {
            id: "download",
            icon: <img src={DownloadIcon} alt="" />,
            action: downloadAction,
            toolTip: t("download"),
          },
        ]
      : []),
    {
      id: "users",
      icon: <img src={chatIcon} alt="" />,
      action: usersAction,
      toolTip: t("trainer.course.lesson.discussions"),
    },
    {
      id: "assignments",
      icon: <img src={examIcon} alt="" />,
      action: assignmentsAction,
      toolTip: t("exams"),
    },
    ...(editAction
      ? [
          {
            id: "edit",
            icon: <img src={editIcon} alt="" />,
            action: editAction,
            toolTip: t("general.edit"),
          },
        ]
      : []),
    ...(saveAction
      ? [
          {
            id: "save",
            icon: <img src={CheckIcon} alt="" />,
            action: saveAction,
            toolTip: t("Exhibition.save"),
          },
        ]
      : []),
    ...(deleteAction
      ? [
          {
            id: "delete",
            icon: <img src={DeleteIcon} alt="" />,
            action: deleteAction,
            toolTip: t("general.delete"),
          },
        ]
      : []),
  ];
  return actionsList;
};
