import {useTranslation} from "react-i18next";
import React from "react";
import QuestionAnswerPanel from "../QuestionAnswerPanel/QuestionAnswerPanel";

function H5PQuestionAnswer(props) {
    require("./H5PQuestionAnswer.css");
    const {t} = useTranslation();

    return (
        <>
            <QuestionAnswerPanel
                item={props.item}
            >
            </QuestionAnswerPanel>
        </>
    )
}

export default H5PQuestionAnswer;