import { Field } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebounce } from "use-debounce";

const DataTable = ({
  selectedItems,
  isLoading,
  isCourseClone,
  data,
  fields,
  searchableFields,
  searchHandler,
  isMultiple,
  selectedHandler,
  label,
  required,
  error,
  canSelectAll,
  toggleSelectAllHandler,
  title,
  disabled = false,
}) => {
  const { t, i18n } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const [debouncedSearchValue] = useDebounce(searchValue, 500);
  const [filteredData, setFilteredData] = useState(data || []);
  useEffect(() => {
    if (searchHandler) {
      searchHandler(debouncedSearchValue);
      setFilteredData(data);
    } else {
      const searchedData = data.filter((item) => {
        return Object.keys(item).some((key) => {
          if (searchableFields.includes(key) && item[key]) {
            return item[key].toString().toLowerCase().includes(debouncedSearchValue.toLowerCase());
          }
        });
      });
      setFilteredData(searchedData);
    }
  }, [debouncedSearchValue]);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  return (
    <>
      <p style={{ color: "#777777", padding: "0.5rem 0", display: "block" }}>
        {label}
        {required && <span style={{ color: "red" }}>*</span>}
      </p>
      <div className="tw-border tw-rounded tw-divide-y tw-overflow-x-auto">
        <div style={{ width: "100%", maxWidth: "100%", display: "table" }}>
          <div className="tw-bg-gray-100 tw-px-4 tw-flex tw-items-center tw-justify-between">
            <div className="tw-flex tw-items-center tw-space-s-4">
              {canSelectAll && (
                <Field
                  disabled={disabled || false}
                  type="checkbox"
                  onChange={() => {
                    toggleSelectAllHandler();
                  }}
                  className={`tw-h-4 tw-cursor-pointer tw-w-4 tw-shrink-0 tw-appearance-none tw-border-[1px] tw-rounded
                 ${
                   data.length === selectedItems.length
                     ? "tw-border-gray-100 tw-border-[3px] tw-bg-teal-600"
                     : "tw-border-gray-300"
                 }
								}
                `}
                  style={{
                    cursor: disabled ? "not-allowed" : "pointer",
                    opacity: disabled ? "0.5" : "1",
                  }}
                />
              )}
              <div className="tw-font-semibold tw-text-gray-500">{title ?? label}</div>
            </div>
            <div className="tw-flex tw-items-center tw-space-s-2" style={{ width: 310 }}>
              <Field
                name={`users-search`}
                className="tw-w-full tw-p-2.5 tw-border-s tw-bg-transparent search-field"
                placeholder={t("general.search_datatable")}
                onChange={({ target: { value } }) => {
                  setSearchValue(value);
                }}
              />
              <i className="fal fa-search tw-text-teal-500 tw-w-6"></i>
            </div>
          </div>
          {isCourseClone ? (
            <div className="tw-pe-4 tw-bg-gray-100">
              <div className="tw-bg-gray-100  tw-flex tw-items-center tw-pe-4  tw-justify-between">
                <div className="tw-flex tw-items-center tw-px-4 tw-py-2 tw-w-full">
                  <div className="tw-flex tw-items-center tw-text-center tw-px-4 tw-w-full">
                    <div>{t("general.number")}</div>
                    <div className={`tw-h-4 tw-w-4 tw-shrink-0 `}></div>
                    <div style={{ width: "300px" }}>{t("general.id")}</div>
                    <div style={i18n.language === "ar" ? { width: "290px" } : { width: "290px" }}>
                      {t("coupon.label.course_or_program")}
                    </div>
                    <div style={{ width: "200px" }}>{t("general.code")}</div>
                    <div style={{ width: "270px" }}>{t("general.serial_number")}</div>
                    <div
                      style={{
                        width: "220px",
                      }}
                    >
                      {t("general.start_date")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="tw-pe-4 tw-bg-gray-100">
              <div className="tw-bg-gray-100  tw-flex tw-items-center tw-pe-4  tw-justify-between">
                <div className="tw-flex tw-items-center tw-px-4 tw-py-2 tw-w-full">
                  {/* <div className="md:tw-flex tw-items-center tw-text-center tw-px-4 tw-w-full"> */}
                  {/* <div>{t("general.number")}</div> */}
                  <div className={`tw-h-4 tw-w-4 tw-shrink-0 `}></div>
                  <div style={{ width: "180px" }}>{t("general.id")}</div>
                  <div style={i18n.language === "ar" ? { width: "320px" } : { width: "320px" }}>
                    {t("coupon.label.course_or_program")}
                  </div>
                  <div style={{ width: "220px" }}>{t("general.code")}</div>
                  <div style={{ width: "240px" }}>{t("general.serial_number")}</div>
                  <div
                    style={{
                      width: "192px",
                    }}
                  >
                    {t("general.start_date")}
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          )}

          <div
            className="tw-py-4 tw-pe-4"
            style={{
              backgroundColor: disabled ? "#f2f2f2" : "transparent",
            }}
          >
            <div className="tw-divide-y tw-divide-black/5 tw-overflow-y-auto inner-scrollbar tw-max-h-[40vh] tw-pe-4">
              {isLoading ? (
                [...Array(5).keys()].map((i) => (
                  <div
                    key={i}
                    className={`tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-8 tw-w-full tw-text-gray-500 tw-animate-pulse`}
                  >
                    <div className="tw-flex tw-items-center tw-space-s-4">
                      <div className={`tw-h-4 tw-w-4 tw-shrink-0 tw-border-[1px] tw-rounded tw-border-gray-300`}></div>
                      <div className="tw-bg-gray-200 tw-rounded-full tw-h-3 tw-my-1.5 tw-w-40"></div>
                    </div>
                    <div className="tw-bg-gray-200 tw-rounded-full tw-h-3 tw-my-1.5 tw-w-60"></div>
                  </div>
                ))
              ) : filteredData.length ? (
                filteredData?.map((item, itemIndex) => (
                  <button
                    key={item?.id}
                    type="button"
                    onClick={() => {
                      selectedHandler(item);
                    }}
                    className={`tw-flex tw-items-center tw-px-4 tw-py-2 tw-w-full`}
                    disabled={disabled || false}
                    style={{
                      cursor: disabled ? "not-allowed" : "pointer",
                      opacity: disabled ? "0.5" : "1",
                    }}
                  >
                    <div
                      className={`tw-h-4 tw-w-4 tw-shrink-0 tw-border-[1px]  ${
                        isMultiple
                          ? selectedItems.includes(item?.id)
                            ? "tw-border-gray-100 tw-border-[3px] tw-bg-teal-600"
                            : "tw-border-gray-300"
                          : selectedItems == item?.id
                          ? "tw-border-gray-100 tw-border-[3px] tw-bg-teal-600"
                          : "tw-border-gray-300"
                      }
                    ${isMultiple ? "tw-rounded" : "tw-rounded-full"}
                  `}
                    ></div>
                    <div className={`tw-flex tw-items-center  tw-px-4 tw-w-full`}>
                      {fields.map((field, fieldIndex) => (
                        <div
                          key={field?.id}
                          // className={`${
                          //   fieldIndex === 0
                          //     ? ""
                          //     : fieldIndex === 1
                          //     ? "tw-w-full"
                          //     : "tw-w-full"
                          // }`}
                          style={
                            fieldIndex === 0
                              ? { width: "" }
                              : fieldIndex === 1
                              ? { width: "350px" }
                              : { width: "240px" }
                          }
                        >
                          {field.column({
                            rowData: item,
                            rowIndex: itemIndex,
                          })}
                        </div>
                      ))}
                    </div>
                  </button>
                ))
              ) : (
                <div className="tw-text-gray-500 tw-text-lg tw-p-8 tw-text-center">{t("no_data")}</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <p className="tw-text-red-500 tw-text-xs" style={{ height: "10px" }}>
        {error && error}
      </p>
    </>
  );
};

export default DataTable;
