import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import classes from "./switchToggle.module.css";

const SwitchToggle = ({ status, onStatusChange, withWord, disabled }) => {
  const [checked, setChecked] = React.useState(status);
  const { t } = useTranslation();

  return (
    <div className={classes[withWord ? "toggle-switch-word" : "toggle-switch"]}>
      {withWord && (
        <>
          <span className={classes["toggle-slider"]}></span>
          <span className={classes["toggle-text"]}>{t(checked ? "Exhibition.active" : "Exhibition.inactive")}</span>
        </>
      )}
      <label className={classes["toggle-switch-field"]}>
        <input
          type="checkbox"
          checked={status}
          onChange={() => setChecked((prev) => !prev)}
          onClick={() => onStatusChange()}
          disabled={disabled}
        />

        <span className={`${disabled ? classes["toggle-disabled"] : ""}`}></span>
      </label>
    </div>
  );
};

export default SwitchToggle;
