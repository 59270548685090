import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

function Verified(props) {
  require("./Verified.css");
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <div className="verified">
        <h1>{t("verification.verified.title")}</h1>
        <p>{t("verification.verified.description")}</p>
        <button onClick={() => history.push("/")}>{t("verification.verified.completeProfile")}</button>
      </div>
    </>
  );
}

export default Verified;
