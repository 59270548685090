import style from "./badge.module.css";

export default function Label({
  label,
  labelColor = "#26b3b9",
  classes,
  icon,
  onClick,
}) {
  return (
    <label
      className={`${style.label} ${classes}`}
      style={{
        background: labelColor,
      }}
      onClick={onClick}
    >
      {icon && <img src={icon} alt="icon" />}
      {label}
    </label>
  );
}
