import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { getTraineesForSponsors, unAssignTrainee, } from "./../../../Services/api/sponsor/SponsorProvider";

export default function Trainees(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const [trainees, setTrainees] = useState([]);
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    getTraineesForSponsors(props && props.traineeId)
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setTrainees(res.data.data[0].trainees);
          setLoading(false)
        }
      })
      .catch((err) => {
        
      });
  }, []);

  const unAssignTraineeForSponsor = (trainee) => {
    let data = {
      target_id: trainee.id,
    };
    unAssignTrainee(data)
      .then((res) => {
        toast.success(
          <span style={{ fontSize: 14 }}>{t("sponsor.messages.unassign")}</span>
        );
        setTrainees(trainees);
      })
      .catch((err) => {
        
      });
      props.onClose()
  };
  return (
    <div className="modal_flex_card modal_card_admin">
      <div className="" style={{
        padding: 40,
        maxHeight: 400,
      }}>
        <div
          style={{
            fontSize: "25px",
            marginBottom: "20px"
          }}
        >
          {t("sponsor.trainees.title")}
        </div>
        {loading == true ? <div className="loader">Loading...</div> :
          <div className="container-fluid profile_wrapper">
            <table className="table table-bordered">
              <thead>
                <tr>
                <th scope="col" style={{ textAlign: "center" }}>
                  {t("sponsor.trainee.name")}
                </th>
                <th scope="col" style={{ textAlign: "center" }}>
                  {t("sponsor.trainee.email")}
                </th>
                  {props.from == "sponsor" ? null : (
                    <th scope="col" style={{ textAlign: "center" }}>
                      {t("sponsor.trainee.unAssign")}
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {trainees.map((trainee, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>
                    {trainee.name_en == null ? "---" : trainee.name_en}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {trainee.email == null ? "---" : trainee.email}
                  </td>
                    {props.from == "sponsor" ? null : (
                      <td style={{ textAlign: "center" }}>
                        <div className="release_icon">
                          <button
                            className={"btn btn-success"}
                            style={{
                              backgroundColor: "#006d77",
                              borderColor: "#006d77",
                            }}
                            onClick={() => unAssignTraineeForSponsor(trainee)}
                          >
                            {t("sponsor.trainee.unAssign")}
                          </button>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>}
      </div>
    </div>
  );
}
