import {educationalGoalsConstants} from "../../Constants/educationalGoals/educationalGoals.constants";


const initialState = {
    loading: true,
    objectives: [],
    error: null,

    addObjectiveLoading: false,
    addObjectiveError: null,

    pdfLoading: false,
    pdfResponse: null,
    pdfError: null,

    xlsLoading: false,
    xlsResponse: null,
    xlsError: null,

    updateStatusLoading: false,
    updateStatusResponse: null,
    updateStatusError: null,
}

export default function educationalGoalsReducer(state = initialState, action) {
    switch (action.type) {
        // GET EDUCATIONAL GOALS
        case educationalGoalsConstants.GET_EDUCATIONAL_GOALS_REQUEST :
            return {
                ...state,
                loading: true,
            }
        case educationalGoalsConstants.GET_EDUCATIONAL_GOALS_SUCCESS:
            return {
                ...state,
                loading: false,
                objectives: action.payload
            }
        case educationalGoalsConstants.GET_EDUCATIONAL_GOALS_REJECTED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        // ADD EDUCATIONAL OBJECTIVE
        case educationalGoalsConstants.ADD_EDUCATIONAL_OBJECTIVE_REQUEST:
            return {
                ...state,
                addObjectiveLoading: true,
            }
        case educationalGoalsConstants.ADD_EDUCATIONAL_OBJECTIVE_SUCCESS:
            return {
                ...state,
                addObjectiveLoading: false,
                objectives: {
                    ...state.objectives,
                    data: [action.payload, ...state.objectives.data]
                }
            }
        case educationalGoalsConstants.ADD_EDUCATIONAL_OBJECTIVE_REJECTED:
            return {
                ...state,
                addObjectiveLoading: false,
                addObjectiveError: action.payload
            }

        // EDIT EDUCATIONAL OBJECTIVE
        case educationalGoalsConstants.EDIT_EDUCATIONAL_OBJECTIVE_REQUEST:
            return {
                ...state,
                addObjectiveLoading: true,
            }
        case educationalGoalsConstants.EDIT_EDUCATIONAL_OBJECTIVE_SUCCESS:
            return {
                ...state,
                addObjectiveLoading: false,
                objectives: {
                    ...state.objectives,
                    data: [...state.objectives.data.map(objective => {
                        if (objective.id === action.payload.id) {
                            return {
                                ...objective,
                                ...action.payload
                            }
                        }
                        return objective
                    })]
                }
            }
        case educationalGoalsConstants.EDIT_EDUCATIONAL_OBJECTIVE_REJECTED:
            return {
                ...state,
                addObjectiveLoading: false,
                addObjectiveError: action.payload
            }

        // DELETE EDUCATIONAL OBJECTIVE
        case educationalGoalsConstants.DELETE_EDUCATIONAL_OBJECTIVE_REQUEST:
            return {
                ...state,
                addObjectiveLoading: true,
            }
        case educationalGoalsConstants.DELETE_EDUCATIONAL_OBJECTIVE_SUCCESS:
            return {
                ...state,
                objectives: {
                    ...state.objectives,
                    data: [...state.objectives.data.filter(objective => objective.id !== action.payload)]
                },
                addObjectiveLoading: false,

            }
        case educationalGoalsConstants.DELETE_EDUCATIONAL_OBJECTIVE_REJECTED:
            return {
                ...state,
                addObjectiveLoading: false,
                addObjectiveError: action.payload
            }

        // UPDATE EDUCATIONAL OBJECTIVE STATUS
        case educationalGoalsConstants.UPDATE_EDUCATIONAL_OBJECTIVE_STATUS_REQUEST:
            return {
                ...state,
                updateStatusLoading: true,
            }
        case educationalGoalsConstants.UPDATE_EDUCATIONAL_OBJECTIVE_STATUS_SUCCESS:
            return {
                ...state,
                updateStatusLoading: false,
                updateStatusResponse: action.payload,
                objectives: {
                    ...state.objectives,
                    data: [...state.objectives.data.map(objective => {
                        if (objective.id === action.payload.id) {
                            return {
                                ...objective,
                                ...action.payload
                            }
                        }
                        return objective
                    })]
                }
            }
        case educationalGoalsConstants.UPDATE_EDUCATIONAL_OBJECTIVE_STATUS_REJECTED:
            return {
                ...state,
                updateStatusLoading: false,
                updateStatusError: action.payload
            }

        default:
            return state;
    }
}