import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../Services/api/auth/AuthContext";
import { AdminUserProvider } from "../../../Services/api/toutorials/profileInfoContext";
import AboutMeIcon from "../../../assets/icons/about-me.svg";
import CertificatesIcon from "../../../assets/icons/certificates.svg";
import CoursesHistoryIcon from "../../../assets/icons/courses-history.svg";
import CurrentCoursesIcon from "../../../assets/icons/current-courses.svg";
import CurrentProgramsIcon from "../../../assets/icons/current-programs.svg";
import FinancialRequestIcon from "../../../assets/icons/financial-request.svg";
import HomeIcon from "../../../assets/icons/home.svg";
import InvoicesIcon from "../../../assets/icons/invoices.svg";
import LearningAnalysisIcon from "../../../assets/icons/learning-analysis.svg";
import ProgramsHistoryIcon from "../../../assets/icons/programs-history.svg";
import ReceivedCouponsIcon from "../../../assets/icons/received-coupons.svg";
import ReceivedGiftIcon from "../../../assets/icons/received-gift.svg";
import RefundRequestsIcon from "../../../assets/icons/refund-requests.svg";
import SentCouponsIcon from "../../../assets/icons/sent-coupons.svg";
import SentGiftIcon from "../../../assets/icons/sent-gift.svg";
import WalletIcon from "../../../assets/icons/wallet-profile.svg";
import Breadcrumb from "../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../Shared/Components/MainBox/MainBox";
import Select from "../../Shared/Components/Select/Select";
import UsersProfileItemBox from "./UsersProfileItemBox";
import classes from "./usersProfile.module.css";

function PrivateProfile() {
  require("./usersProfile.css");
  const { t } = useTranslation();
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const roles = authContext.roles;
  const [activeRole, setActiveRole] = useState(roles[0]);

  const rolesOptions = roles.map((role) => {
    return { id: role, value: role, label: t(`selection.role.${role}`) };
  });

  const itemLists = [
    {
      id: "coursesHistory",
      title: t("private_profile.courses_history"),
      description: t("private_profile.courses_history"),
      onClick: () =>
        history.push(
          `${
            activeRole === "manager"
              ? "manager"
              : activeRole === "trainer"
              ? "trainer"
              : activeRole === "partner"
              ? "partner"
              : "private-profile"
          }/courses-history`
        ),
      icon: CoursesHistoryIcon,
      allowRoles: ["trainer", "manager", "trainee", "partner"],
    },
    {
      id: "currentCourses",
      title: t("private_profile.current_courses"),
      description: t("private_profile.current_courses"),
      onClick: () =>
        history.push(
          `${
            activeRole === "manager"
              ? "manager"
              : activeRole === "trainer"
              ? "trainer"
              : activeRole === "partner"
              ? "partner"
              : "private-profile"
          }/courses`
        ),
      icon: CurrentCoursesIcon,
      allowRoles: ["trainer", "manager", "trainee", "partner"],
    },
    {
      id: "programsHistory",
      title: t(
        `private_profile.${
          activeRole === "trainer" ? "courses_programs" : "programs"
        }_history`
      ),
      description: t(
        `private_profile.${
          activeRole === "trainer" ? "courses_programs" : "programs"
        }_history`
      ),
      onClick: () =>
        history.push(
          `${
            activeRole === "manager"
              ? "manager"
              : activeRole === "trainer"
              ? "trainer"
              : activeRole === "partner"
              ? "partner"
              : "private-profile"
          }/programs-history`
        ),
      icon: ProgramsHistoryIcon,
      allowRoles: ["trainer", "manager", "trainee", "partner"],
    },
    {
      id: "currentPrograms",
      title: t(
        `private_profile.current_${
          activeRole === "trainer" ? "courses_programs" : "programs"
        }`
      ),
      description: t(
        `private_profile.current_${
          activeRole === "trainer" ? "courses_programs" : "programs"
        }`
      ),
      onClick: () =>
        history.push(
          `${
            activeRole === "manager"
              ? "manager"
              : activeRole === "trainer"
              ? "trainer"
              : activeRole === "partner"
              ? "partner"
              : "private-profile"
          }/programs`
        ),
      icon: CurrentProgramsIcon,
      allowRoles: ["trainer", "manager", "trainee", "partner"],
    },
    {
      id: "certificates",
      title: t("private_profile.certificates"),
      description: t("private_profile.certificates"),
      onClick: () => history.push("/private-profile/certificates"),
      icon: CertificatesIcon,
      allowRoles: ["trainee"],
    },
    {
      id: "refundRequests",
      title: t("private_profile.refund_requests"),
      description: t("private_profile.refund_requests"),
      onClick: () => history.push("/private-profile/refund-requests"),
      icon: RefundRequestsIcon,
      allowRoles: ["trainee", "sponser"],
    },
    {
      id: "aboutMe",
      title: t("private_profile.about_me"),
      description: t("private_profile.about_me"),
      onClick: () => history.push("/private-profile/about-me"),
      icon: AboutMeIcon,
      allowRoles: ["manager", "trainer"],
    },
    {
      id: "financalRequset",
      title: t("private_profile.financial_request"),
      description: t("private_profile.financial_request"),
      onClick: () =>
        history.push(
          `${
            activeRole === "manager"
              ? "manager"
              : activeRole === "trainer"
              ? "trainer"
              : "private-profile"
          }/payments-requests`
        ),
      icon: FinancialRequestIcon,
      allowRoles: ["manager", "trainer"],
    },
    {
      id: "sentGift",
      title: t("private_profile.sent_gift"),
      description: t("private_profile.sent_gift"),
      onClick: () => history.push("/private-profile/sent-gift"),
      icon: SentGiftIcon,
      allowRoles: ["trainer", "manager", "trainee", "sponser", "partner"],
    },
    {
      id: "receivedGift",
      title: t("private_profile.received_gift"),
      description: t("private_profile.received_gift"),
      onClick: () => history.push("/private-profile/received-gift"),
      icon: ReceivedGiftIcon,
      allowRoles: ["trainer", "manager", "trainee", "sponser", "partner"],
    },
    {
      id: "sentCoupons",
      title: t("private_profile.sent_coupon_gift"),
      description: t("private_profile.sent_coupon_gift"),
      onClick: () => history.push("/private-profile/sent-coupons"),
      icon: SentCouponsIcon,
      allowRoles: [
        "trainer",
        "manager",
        "trainee",
        "sponser",
        "partner",
        "admin",
        "accountant",
      ],
    },
    {
      id: "receivedCoupons",
      title: t("private_profile.received_coupon_gift"),
      description: t("private_profile.received_coupon_gift"),
      onClick: () => history.push("/private-profile/received-coupons"),
      icon: ReceivedCouponsIcon,
      allowRoles: [
        "trainer",
        "manager",
        "trainee",
        "sponser",
        "partner",
        "admin",
        "accountant",
      ],
    },
    {
      id: "invoices",
      title: t("private_profile.invoices"),
      description: t("private_profile.invoices"),
      onClick: () => history.push("/private-profile/invoices"),
      icon: InvoicesIcon,
      allowRoles: ["trainee", "sponser"],
    },
    {
      id: "wallet",
      title: t("private_profile.wallet"),
      description: t("private_profile.wallet"),
      onClick: () => history.push("/private-profile/wallet"),
      icon: WalletIcon,
      allowRoles: [
        "trainer",
        "manager",
        "trainee",
        "sponser",
        "partner",
        "admin",
        "accountant",
      ],
    },
    {
      id: "learning-analysis",
      title: t("learning_analysis.title"),
      description: t("learning_analysis.title"),
      onClick: () =>
        history.push(
          `${
            activeRole === "manager"
              ? "manager"
              : activeRole === "trainer"
              ? "trainer"
              : activeRole === "partner"
              ? "partner"
              : "private-profile"
          }/learning-analysis`
        ),
      icon: LearningAnalysisIcon,
      allowRoles: ["trainer", "manager", "trainee", "partner"],
    },
  ];

  return (
    <AdminUserProvider>
      <div className="container-fluid min-hight-78-200">
        <div className="row">
          <div className="col-12 sm:tw-px-2 tw-py-8">
            <div className="admin_label-heading">{t("account_info")}</div>
          </div>
        </div>
        {rolesOptions.length === 1 ? (
          <></>
        ) : (
          <div className="row">
            <div className="col-12 sm:tw-px-2">
              <MainBox>
                <div className={classes["select-role"]}>
                  <Select
                    label={t("selection.role.label")}
                    labelColor="#036c77"
                    options={rolesOptions}
                    onchange={(e) => setActiveRole(e.target.value)}
                    disabled={rolesOptions.length === 1}
                    value={activeRole}
                    name="role"
                  />
                </div>
              </MainBox>
            </div>
          </div>
        )}

        <div className="row">
          <div
            className={`col-12 sm:tw-px-2 ${
              rolesOptions.length === 1 ? "" : "tw-py-8"
            }`}
          >
            <MainBox style={{ minHeight: "500px", borderRadius: "10px" }}>
              <div className="row bg-container">
                <div className={classes["profile-container"]}>
                  {itemLists.map((item) =>
                    item.allowRoles.includes(activeRole) ? (
                      <UsersProfileItemBox
                        key={item.id}
                        title={item.title}
                        description={item.description}
                        onClick={item.onClick}
                        icon={item.icon}
                      />
                    ) : null
                  )}
                </div>
              </div>
            </MainBox>
          </div>
        </div>
      </div>
    </AdminUserProvider>
  );
}

export default PrivateProfile;
