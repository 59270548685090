import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Globe from "../../../../assets/icons/globe.svg";
import Arrow from "../../../../assets/icons/ArrowDownGreen.svg";
import LoginIcon from "../../../../assets/icons/LoginIcon.svg";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";

const ITEM_HEIGHT = 48;

export default function DropDown({ options, isLanguage, isLogin }) {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [width, setWidth] = useState(0);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWidth]);

  useEffect(() => {
    document.addEventListener("scroll", (event) => {
      handleClose();
    });
    return () => {
      document.removeEventListener("scroll", (event) => {
        handleClose();
      });
    };
  }, []);

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        disableRipple
        sx={{
          "&:hover": {
            backgroundColor: "transparent", // Remove hover effect
          },
          "&::after": {
            backgroundColor: "transparent",
          },

          "&:active::after": {
            backgroundColor: "transparent",
          },
        }}
      >
        {isLanguage || isLogin ? (
          <div
            style={{
              display: "flex",
              fontSize: 14,
              gap: isLogin ? "5px" : "",
              alignItems: "center",
              color: "#006d77",
              fontWeight: "bold",
            }}
          >
            <img
              src={isLogin ? LoginIcon : Globe}
              alt=""
              style={{ width: 24, height: 24 }}
            />
            <p style={{ whiteSpace: "nowrap" }}>
              {!isLogin
                ? t("Language")
                : isLogin && width > 387
                ? t("loginDropDown")
                : ""}
            </p>
            <img
              src={Arrow}
              alt=""
              style={{ width: 8, height: 8, marginTop: 2 }}
            />
          </div>
        ) : (
          <MoreVertIcon />
        )}
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock={true}

        //  style= {
        //     maxHeight: ITEM_HEIGHT * 4.5,
        //     width: "20ch",
        //     backgroundColor: "#fff",
        //   },
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            onClick={() => {
              option.onClick();
              handleClose();
            }}
            style={{
              backgroundColor: "#fff",
              color: isLanguage || isLogin ? "#006d77" : "rgb(122, 122, 122)",
              fontFamily: "dinNextRegular",
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
