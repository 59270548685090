import { traineeCertificatesConstants } from "../../Constants/certificates/trainee-certificates.constants";

const initialState = {
  traineeCertificatesList: [],
  traineeCertificatesListMeta: {},
  traineeCertificatesListLoading: false,
  traineeCertificatesListError: null,
  traineeCertificatesListRefresh: false,
};


const traineeCertificatesReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET TRAINEE CERTIFICATES LIST
    case traineeCertificatesConstants.GET_TRAINEE_CERTIFICATES_LIST_REQUEST:
      return {
        ...state,
        traineeCertificatesListLoading: true,
        traineeCertificatesListError: null,
        traineeCertificatesListRefresh: false,
      };
    case traineeCertificatesConstants.GET_TRAINEE_CERTIFICATES_LIST_SUCCESS:
      return {
        ...state,
        traineeCertificatesList: action.payload.list,
        traineeCertificatesListMeta: action.payload.meta,
        traineeCertificatesListLoading: false,
        traineeCertificatesListError: null,
        traineeCertificatesListRefresh: false,
      };
    case traineeCertificatesConstants.GET_TRAINEE_CERTIFICATES_LIST_REJECTED:
      return {
        ...state,
        traineeCertificatesListLoading: false,
        traineeCertificatesListError: action.payload,
        traineeCertificatesListRefresh: false,
      };
      
    default:
      return state;
  }
}

export default traineeCertificatesReducer;