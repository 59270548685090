import env from "../Environment";
import connector from "../Handler";


export function getReportTrainerCourses(data) {
    const myPromise = new Promise((resolve, reject) => {
        try {
            connector
                .get(env.report_trainer_courses,{
                    params: data
                })
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        } catch (error) {
            reject(error);
        }
    });

    return myPromise;
}

export function getReportHoursTrainerCourses(data) {
    const myPromise = new Promise((resolve, reject) => {
        try {
            connector
                .get(env.report_hours_trainer_courses,{
                    params: data
                })
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        } catch (error) {
            reject(error);
        }
    });

    return myPromise;
}


export function getReportTraineeCourses(data) {
    const myPromise = new Promise((resolve, reject) => {
        try {
            connector
                .get(env.report_trainee_courses,{
                    params: data
                })
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        } catch (error) {
            reject(error);
        }
    });

    return myPromise;
}

export function getReportTraineePrograms(data) {
    const myPromise = new Promise((resolve, reject) => {
        try {
            connector
                .get(env.report_trainee_programs,{
                    params: data
                })
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        } catch (error) {
            reject(error);
        }
    });

    return myPromise;
}


export function getQuizzesTrainee(user_id,course_id) {
    const myPromise = new Promise((resolve, reject) => {
        try {
            connector
                .get(env.get_quizzes_trainee,{ params: {
                    user_id,
                    course_id,
                  }})
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        } catch (error) {
            reject(error);
        }
    });

    return myPromise;
}

export function getReportHoursTraineeCourses(data) {
    const myPromise = new Promise((resolve, reject) => {
        try {
            connector
                .get(env.report_hours_trainee_courses,{
                    params: data
                })
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        } catch (error) {
            reject(error);
        }
    });

    return myPromise;
}


export function getReportTraineeLessons(page,course_status) {
    const myPromise = new Promise((resolve, reject) => {
        try {
            connector
                .get(env.report_trainee_lessons,{ params: {
                    course_status,
                    page,
                  }})
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        } catch (error) {
            reject(error);
        }
    });

    return myPromise;
}

export function getReportTrainerLessons(page,course_status) {
    const myPromise = new Promise((resolve, reject) => {
        try {
            connector
                .get(env.report_trainer_lessons,{ params: {
                    course_status,
                    page,
                  }})
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        } catch (error) {
            reject(error);
        }
    });

    return myPromise;
}


export function exportReport(type,params) {
    let extension = 'xlsx'
    let api = ''
    type == 'trainer' ?
    api = env.report_trainer_courses : type == 'trainerHours' ?
    api = env.report_hours_trainer_courses : type == 'trainee' ?
    api = env.report_trainee_courses : type == 'traineeLessons'?
    api = env.report_trainee_lessons : type == 'trainerLessons'?
    api = env.report_trainer_lessons : type == "traineePrograms" ?
    api = env.report_trainee_programs : type == 'traineeQuizzes' ?
    api = env.get_quizzes_trainee: type == 'trainerQuizzes'?
    api = env.get_course_grades_report:
    api = env.report_hours_trainee_courses
    const myPromise = new Promise((resolve, reject) => {
      try {
        connector
            .get(api, {
              responseType: "blob",
              params: {
                ...params,
                export: extension
              }
            })
            .then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "reports" + "." + extension); //or any other extension
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
              resolve();
            }, error => {
              reject(error);
            });
      } catch (error) {
        reject(error);
      }
    });

    return myPromise;
  }


  export function getStatisticsTrainer() {
    const myPromise = new Promise((resolve, reject) => {
        try {
            connector
                .get(env.statistics_trainer)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        } catch (error) {
            reject(error);
        }
    });

    return myPromise;
}

export function getCourseGradesReport(courseId) {
    const myPromise = new Promise((resolve, reject) => {
        try {
            connector
                .get(env.get_course_grades_report,{params:{course_id:courseId}})
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        } catch (error) {
            reject(error);
        }
    });

    return myPromise;
}

export function getTotalIncomeReport(data) {
    const myPromise = new Promise((resolve, reject) => {
        try {
            connector
                .get(env.dashboard_report, {
                    params: data,
                })
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        } catch (error) {
            reject(error);
        }
    });

    return myPromise;
}

export function getCourseDueReport(data) {
    const myPromise = new Promise((resolve, reject) => {
        try {
            connector
                .get(env.dashboard_course_dues_report,{
                    params: data
                })
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        } catch (error) {
            reject(error);
        }
    });

    return myPromise;
}




