import React, { useEffect } from "react";
import { TrainerProfileProvider } from "../../../Services/api/toutorials/trainerProfileContext";
import UsersProfile from "./UsersProfile";

function PrivateProfile(props) {
  useEffect(() => {
    const transition = document.getElementsByClassName(
      "tajah-modal-background-tint"
    )[0];
    if (
      document.getElementsByClassName("tajah-modal-background-tint")[0].style
        .opacity === "1"
    )
      window.location.reload();
  }, []);
  return (
    <TrainerProfileProvider>
      <UsersProfile />
    </TrainerProfileProvider>
  );
}

export default PrivateProfile;
