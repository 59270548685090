import AddQuestion from "../../../../../Shared/Components/Trainer/Exam/Question/Add/Add";

function ExamQuestionPanel(props) {
    require("./ExamQuestion.css");

    return (
        <>
            <AddQuestion />
        </>
    )
}

export default ExamQuestionPanel;
