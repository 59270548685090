function SkeletonCard() {
  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
          margin: "10px auto",
          fontSize: "18px",
          boxShadow: "0px 0px 20px #ccc",
        }}
      >
        <div
          className="skeleton-box"
          style={{ width: "100%", height: "100%" }}
        ></div>
      </div>
    </>
  );
}

export default SkeletonCard;
