import {useTranslation} from "react-i18next";
import React from "react";
import {useHistory} from "react-router";
import {NavLink, useParams} from "react-router-dom";

function List(props) {
  require("./list.css");
  const { t } = useTranslation();
  const history = useHistory()
  const question =  history.location.state.question
  const {questionId} = useParams();
    return (
        <>
              <div className="container-fluid" id={"content-section"}>
                <div className="row" id="content-header-bar">
                    <div className="col-12">
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                        }}>
                            <div className="admin_label">
                                <NavLink to="/admin">{t("admin.label.admin_label")}</NavLink>
                                <p style={{padding: "0 5px"}}>/</p>
                                <NavLink to="/admin/survey/list">{t("survey.title")}</NavLink>
                                <p style={{padding: "0 5px"}}>/</p>
                                <NavLink to={`/admin/survey/${1}/questions`}>{t("modal.survey.buttons.questions")}</NavLink>
                                <p style={{padding: "0 5px"}}>/</p>
                                {t("modal.survey.buttons.answers")}
                            </div>

                            <div style={{
                                flex: 1,
                                flexDirection: 'row',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end'
                            }}>
                            </div>
                        </div>
                    </div>
                </div>
                  <div className="table_bank_tran">
                            <div className="tajah_card_title_container_answers">
                                <div>{t("modal.survey.buttons.answers")}</div>
                <div className="table-responsive" style={{
                    width: '100%',marginTop:'5%'
                  }}>
                  {question.length == 0 ? (
                    <p style={{textAlign:'center'}}>{t('survey.notFound')}</p>
                  ):(
                    <table className="table table-bordered">
                      <thead>
                      <tr style={{textAlign:'center'}}>
                        <th scope="col">#</th>
                        <th scope="col">{t("survey.answers.title")}</th>
                        <th scope="col"> {t("survey.answers.answer")}</th>
                      </tr>
                      </thead>
                      <tbody>
                      {question.map((answer,index) => {
                        return (
                            <tr style={{textAlign:'center'}} key={answer.id}>
                              <td scope="row">{index+1}</td>
                              <td>{answer.value}</td>
                              <td>{answer.total_responses}</td>
                            </tr>
                        );
                      })}
                      </tbody>
                    </table>
                  )}
                  </div>
                  </div>
                  </div>
                  </div>
        </>
    );
}

export default List;
