import {useTranslation} from "react-i18next";
import React from "react";
import QuestionAnswerPanel from "../QuestionAnswerPanel/QuestionAnswerPanel";
import DragAndDropSelectedAnswers
    from "../../../../Shared/Components/Trainer/Exam/Question/Answer/DragAndDropSelectedAnswers/DragAndDropSelectedAnswers";

function DragAndDropQuestionAnswer(props) {
    require("./DragAndDropQuestionAnswer.css");
    const {t} = useTranslation();

    return (
        <>
            <QuestionAnswerPanel
                item={props.item}
            >
                <div style={{
                    width: '100%',
                    display:'flex',
                    justifyContent:'center',
                    alignItems: 'center'
                }}>
                    <div style={{
                        width: '95%',
                    }}>
                        <DragAndDropSelectedAnswers
                            answers={JSON.parse(props.item.answer)}
                        />
                    </div>

                </div>
            </QuestionAnswerPanel>
        </>
    )
}

export default DragAndDropQuestionAnswer;
