export const Lang = {
  AR: "ar",
  EN: "en",
};

export const calcNumChunk = (length, size) => {
  let numChunk = Math.floor(length / size);

  if (length % size > 0) return numChunk + 1;

  return numChunk;
};

export const chunk = (target, size) => {
  return target.reduce(
    (memo, value, index) => {
      // Here it comes the only difference
      // if (index % (target.length / size) === 0 && index !== 0) memo.push([]);
      if (index % 4 === 0 && index !== 0) memo.push([]);
      memo[memo.length - 1].push(value);
      return memo;
    },
    [[]]
  );
};

export function delay(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export function hasExtension(filename, exts) {
  return new RegExp("(" + exts.join("|").replace(/\./g, "\\.") + ")$").test(filename);
}
