import PdfIcon from "../../../../assets/icons/pdf.svg";
import editIcon from "../../../../assets/icons/pen.svg";
import XlsIcon from "../../../../assets/icons/xls.svg";
import i18n from "../../../../i18n/i18n";
import { Lang } from "../../../../utils";

export const DiscActions = ({
  votes,
  participants,
  editAction,
  exportPdf,
  exportExcel,
  banAllAction,
  publishAllAction,
  deleteAction,
  disableAction,
  enableAction,
}) => {
  const actionsList = [
    ...(!votes
      ? []
      : [
          {
            id: "votes",
            type: "button",
            outline: true,
            style: {
              border: "2px solid rgb(13 148 136)",
              borderRadius: 6,
              height: 50,
            },
            label:
              i18n.language === "ar" ? "التصويت" : "votes",
            btnTextColor: "rgb(13 148 136)",
            action: votes,
          },
        ]),
    ...(!participants
      ? []
      : [
          {
            id: "participants",
            type: "button",
            outline: true,
            style: {
              border: "2px solid rgb(13 148 136)",
              borderRadius: 6,
              height: 50,
            },
            label:
              i18n.language === "ar" ? "الطلاب المشاركين" : "Participants",
            btnTextColor: "rgb(13 148 136)",
            action: participants,
          },
        ]),
    ...(!disableAction
      ? []
      : [
          {
            id: "disable",
            type: "button",
            outline: true,
            style: {
              border: "2px solid rgb(255, 147, 0)",
              borderRadius: 6,
              height: 50,
            },
            label:
              i18n.language === "ar" ? "تعطيل المحتوى " : "Disable Activity",
            btnTextColor: "rgb(255, 147, 0)",
            action: disableAction,
          },
        ]),
    ...(!enableAction
      ? []
      : [
          {
            id: "disable",
            type: "button",
            outline: true,
            style: {
              border: "2px solid rgb(255, 147, 0)",
              borderRadius: 6,
              height: 50,
            },
            label:
              i18n.language === "ar" ? "تفعيل المحتوى " : "Enable Activity",
            btnTextColor: "rgb(255, 147, 0)",
            action: enableAction,
          },
        ]),
    ...(banAllAction
      ? [
          {
            id: "introVideo",
            type: "button",
            outline: true,
            style: {
              border: "2px solid #8599B6",
              borderRadius: 6,
              width: 173,
              height: 50,
            },
            label:
              i18n.language === "ar"
                ? "حظر جميع التعليقات"
                : "Ban all comments",
            btnTextColor: "#8599B6",
            action: banAllAction,
          },
        ]
      : []),
    ...(publishAllAction
      ? [
          {
            id: "introVideo",
            type: "button",
            outline: true,
            style: {
              border: "2px solid #24b3b9",
              borderRadius: 6,
              width: 173,
              height: 50,
            },
            label:
              i18n.language === "ar"
                ? "نشر جميع التعليقات"
                : "Publish all comments",
            btnTextColor: "#24b3b9",
            action: publishAllAction,
          },
        ]
      : []),
    ...(deleteAction
      ? [
          {
            id: "deleteAction",
            type: "button",
            outline: true,
            style: {
              border: "2px solid red",
              borderRadius: 6,
              width: 173,
              height: 50,
            },
            label: i18n.language === "ar" ? "حذف المحتوى" : "Delete Content",
            btnTextColor: "red",
            action: deleteAction,
          },
        ]
      : []),
    ...(editAction
      ? [
          {
            id: "edit",
            icon: <img src={editIcon} alt="" />,
            action: editAction,
            toolTip: i18n.language === Lang.AR ? "تعديل" : "Edit",
            theme: "edit",
          },
        ]
      : []),
    ...(exportPdf
      ? [
          {
            id: "pdf",
            icon: <img src={PdfIcon} alt="" />,
            theme: "pdf",
            action: exportPdf,
          },
        ]
      : []),
    ...(exportExcel
      ? [
          {
            id: "xls",
            icon: <img src={XlsIcon} alt="" />,
            theme: "xls",
            action: exportExcel,
          },
        ]
      : []),
  ];
  return actionsList;
};
