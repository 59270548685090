import url from "./SpinnerUrls";

const spinnerUrls = castRule();

function castRule() {
  return url.map((i) => {
    i[0] =
      i[0]
        ?.split("/")
        .map((e) => (e.startsWith("$") ? "*" : e))
        .join("/") + "%";
    return i;
  });
};

function matchRule(str, rule) {
  var escapeRegex = (str) => str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
  return new RegExp(
    "^" + rule.split("*").map(escapeRegex).join(".*") + "$"
  ).test(str);
}

function matchUrls(path, method) {
  for (const index in spinnerUrls) {
    const url = spinnerUrls[index];
    if (matchRule(path + "%", url[0]) && (!url[1] || method == url[1]))
      return true;
  }
  return false;
}

export default matchUrls;
