import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { Formik } from "formik";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { toast } from "react-toastify";
import QuestionTypeEnum from "../../../../../../../Enums/QuestionTypeEnum";
import { AddQuestionToQuestionBankContext } from "../../../../../../../Providers/AddQuestionToQuestionBankProvider";
import { TrainerAddExamContext } from "../../../../../../../Providers/TrainerAddExamProvider";
import DropDownProposedAnswers from "../Answer/DropDownProposedAnswers/DropDownProposedAnswers";
import FileSection from "../FileSection/FileSection";

function SingularSelectQuestion(props) {
  require("./SingularSelectQuestion.css");

  useEffect(() => {});

  const { t } = useTranslation();
  const wrapperContext = useContext(
    props.renderedAs === "questionBank"
      ? AddQuestionToQuestionBankContext
      : TrainerAddExamContext
  );

  const levels = [
    {
      id: 1,
      name: t("trainer.quiz.crud.easy"),
    },
    {
      id: 2,
      name: t("trainer.quiz.crud.medium"),
    },
    {
      id: 3,
      name: t("trainer.quiz.crud.hard"),
    },
  ];
  return (
    <>
      <Formik
        initialValues={{
          title:
            wrapperContext.isEdit
              ? wrapperContext.question.title
              : "",
          degree:
            wrapperContext.isEdit
              ? wrapperContext.question.degree
              : "",
          level:
            wrapperContext.isEdit
              ? wrapperContext.question.level
              : "",
          feedback:
            wrapperContext.isEdit
              ? wrapperContext.question.feedback
              : "",
          options:
            wrapperContext.isEdit
              ? JSON.parse(wrapperContext.question.data)
              : [],
          media_id:
            wrapperContext.isEdit &&
            wrapperContext.question.media &&
            wrapperContext.question.media
              ? wrapperContext.question.media.id
              : "",
          course_objectives:
            wrapperContext.isEdit
              ? wrapperContext.question.course_objectives
              : [],
          optionText: "",
          optionCorrectness: false,
          setQuestionCorrectness: wrapperContext.isEdit
            ? !JSON.parse(wrapperContext.question.data).filter(
                (item) => item.is_correct
              ).length > 0
            : true,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          let payload = {
            title: values.title,
            degree: values.degree,
            level: values.level,
            options: values.options,
            media_id: values.media_id,
            feedback: values.feedback,
            course_objectives: values.course_objectives,
          };

          try {
            await props.onSubmit(payload);
            setSubmitting(false);
          } catch (err) {
            setSubmitting(false);
          }
        }}
        validateOnChange={false}
        validate={(values) => {
          const errors = {};

          if (!values.title) {
            errors.title = t("crud.errors.required");
          }

          if (!values.degree) {
            errors.degree = t("crud.errors.required");
          }

          if (!values.level) {
            errors.level = t("crud.errors.required");
          }

          if (
            !Array.isArray(values.course_objectives) ||
            values.course_objectives.length == 0
          ) {
            errors.course_objectives = t("crud.errors.required");
          }

          if (values.options.length === 0) {
            toast.error(
              <span style={{ fontSize: 13, fontWeight: "bold" }}>
                {t("trainer.quiz.crud.validation.question_bank.add.no_options")}
              </span>
            );
            errors.options = t("crud.errors.required");
          } else {
            let noRightAnswerFound = true;
            values.options.forEach((item) => {
              if (item.is_correct) {
                noRightAnswerFound = false;
              }
            });

            if (noRightAnswerFound) {
              toast.error(
                <span style={{ fontSize: 13, fontWeight: "bold" }}>
                  {t(
                    "trainer.quiz.crud.validation.question_bank.add.no_right_answer"
                  )}
                </span>
              );
              errors.options = t("crud.errors.required");
            }
          }

          return errors;
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setErrors,
          setSubmitting,
        }) => (
          <form onSubmit={handleSubmit} className="admin_add_form">
            <div className={"tajah-form-row"}>
              <div className={"tajah-form-row-element-v2"}>
                <div
                  className={"tajah-form-row-element-holder"}
                  style={{ width: "95%" }}
                >
                  <label htmlFor="title" className="admin_add_label">
                    {t("trainer.quiz.crud.question_title")}
                  </label>
                  <input
                    value={values.title}
                    onChange={(elem) =>
                      setFieldValue("title", elem.target.value)
                    }
                    placeholder={t("trainer.quiz.crud.input.question_title")}
                    className="admin_filter_input"
                  />
                  <p className={"form-input-error-space"}>
                    {errors.title ? errors.title : null}
                  </p>
                </div>
              </div>
            </div>
            <div className={"tajah-form-row"}>
              <div className={"tajah-form-row-element-v2"}>
                <div
                  className={"tajah-form-row-element-holder"}
                  style={{ width: "95%" }}
                >
                  <FileSection
                    mediaId={values.media_id}
                    setMediaId={(mediaId) => {
                      setFieldValue("media_id", mediaId);
                    }}
                    setSubmitting={setSubmitting}
                    typeId={QuestionTypeEnum.radio_button}
                  />
                </div>
              </div>
            </div>
            <div className={"tajah-form-row"}>
              <div className={"tajah-form-row-element-v2"}>
                <div
                  className={"tajah-form-row-element-holder"}
                  style={{ width: "95%" }}
                >
                  <label className="admin_add_label">
                    {t("trainer.quiz.crud.grade")}
                  </label>
                  <input
                    value={values.degree}
                    onChange={(elem) =>
                      setFieldValue("degree", elem.target.value)
                    }
                    placeholder={t("trainer.quiz.crud.input.grade")}
                    className="admin_filter_input"
                  />
                  <p className={"form-input-error-space"}>
                    {errors.degree ? errors.degree : null}
                  </p>
                </div>
              </div>
            </div>
            
            <div className={"tajah-form-row"}>
              <div className={"tajah-form-row-element-v2"}>
                <div
                  className={"tajah-form-row-element-holder"}
                  style={{ width: "95%" }}
                >
                  <label htmlFor="level" className="admin_add_label">
                    {t("trainer.quiz.crud.level")}
                  </label>
                  <Select
                    name="level"
                    id="level"
                    // isClearable={true}
                    // isMulti={true}
                    value={levels.filter((item) => values.level == item.id ? true :  false)}
                    options={levels}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    onChange={(item) => {
                      setFieldValue("level", item.id);
                    }}
                    placeholder={t("trainer.quiz.crud.select.level")}
                  />
                  <p className={"form-input-error-space"}>
                    {errors.level ? errors.level : null}
                  </p>
                </div>
              </div>
            </div>

            <div className={"tajah-form-row"}>
              <div className={"tajah-form-row-element-v2"}>
                <div
                  className={"tajah-form-row-element-holder"}
                  style={{ width: "95%" }}
                >
                  <label htmlFor="name_en" className="admin_add_label">
                    {t("trainer.quiz.crud.objectives")}
                  </label>
                  <Select
                    name="course_objectives"
                    id="course_objectives"
                    isClearable={true}
                    isMulti={true}
                    defaultValue={values.course_objectives}
                    options={wrapperContext.objectives}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    onChange={(items) => {
                      const courseObjectives = [];
                      items.forEach((item) => {
                        courseObjectives.push(item.id);
                      });
                      setFieldValue("course_objectives", courseObjectives);
                    }}
                    placeholder={t("trainer.quiz.crud.select.objectives")}
                  />
                  <p className={"form-input-error-space"}>
                    {errors.course_objectives ? errors.course_objectives : null}
                  </p>
                </div>
              </div>
            </div>

            <div className={"tajah-form-row"}>
              <div className={"tajah-form-row-element-v2"}>
                <div
                  className={"tajah-form-row-element-holder"}
                  style={{ width: "95%" }}
                >
                  <label className="admin_add_label">
                    {t("trainer.quiz.crud.negative_feedback")}
                  </label>
                  <input
                    value={values.feedback}
                    onChange={(elem) =>
                      setFieldValue("feedback", elem.target.value)
                    }
                    placeholder={t("trainer.quiz.crud.input.negative_feedback")}
                    className="admin_filter_input"
                  />
                  <p className={"form-input-error-space"}>
                    {errors.feedback ? errors.feedback : null}
                  </p>
                </div>
              </div>
            </div>

            <div className={"tajah-form-row"}>
              <div className={"tajah-form-row-element-v2"}>
                <div
                  className={"tajah-form-row-element-holder"}
                  style={{ width: "95%" }}
                >
                  <div className={"tajah-form-row tajah-form-row-qa"}>
                    <div
                      className={"tajah-form-row-element-v2"}
                      style={{ flex: 2 }}
                    >
                      <div
                        className={"tajah-form-row-element-holder"}
                        style={{ width: "100%" }}
                      >
                        <label>{t("trainer.quiz.crud.question_option")}</label>
                        <input
                          value={values.optionText}
                          onChange={(elem) =>
                            setFieldValue("optionText", elem.target.value)
                          }
                          placeholder={t(
                            "trainer.quiz.crud.input.question_option"
                          )}
                          className="admin_filter_input"
                        />
                        <p className={"form-input-error-space"}>
                          {errors.optionText ? errors.optionText : null}
                        </p>
                      </div>
                    </div>

                    <div className={"tajah-form-row-element-v2"}>
                      <div
                        className={"tajah-form-row-element-holder"}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={!values.setQuestionCorrectness}
                                checked={values.optionCorrectness}
                                onChange={(elem) => {
                                  setFieldValue(
                                    "optionCorrectness",
                                    elem.target.checked
                                  );
                                }}
                              />
                            }
                            label={t("trainer.quiz.crud.right_answer")}
                          />
                        </FormGroup>
                        <p className={"form-input-error-space"}></p>
                      </div>
                    </div>

                    <div
                      className={"tajah-form-row-element-v2"}
                      style={{ alignItems: "flex-end" }}
                    >
                      <div
                        className={"tajah-form-row-element-holder"}
                        style={{ width: "auto" }}
                      >
                        <button
                          type="button"
                          onClick={() => {
                            if (!values.optionText) {
                              let currentErrors = {};
                              if (errors) {
                                currentErrors = errors;
                              }

                              currentErrors.optionText = t(
                                "crud.errors.required"
                              );
                              setErrors(currentErrors);
                              return;
                            } else {
                              let currentErrors = {};
                              if (errors) {
                                currentErrors = errors;
                              }

                              delete currentErrors.optionText;
                              setErrors(currentErrors);
                            }

                            if (values.optionCorrectness) {
                              setFieldValue("setQuestionCorrectness", false);
                            }
                            const optionItem = {
                              name: values.optionText,
                              is_correct: values.optionCorrectness,
                            };
                            const currentOptions = values.options;
                            currentOptions.push(optionItem);
                            setFieldValue("options", currentOptions);
                            setFieldValue("optionText", "");
                            setFieldValue("optionCorrectness", false);
                          }}
                          className="admin_add_button"
                          disabled={isSubmitting}
                        >
                          {t("trainer.quiz.add_answer")}
                        </button>
                        <p></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={"tajah-form-row"}>
              <div className={"tajah-form-row-element-v2"}>
                <div
                  className={"tajah-form-row-element-holder"}
                  style={{ width: "95%" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 20,
                      flexDirection: "column",
                      border: 1,
                      width: "100%",
                    }}
                  >
                    {(Array.isArray(values.options) &&
                      values.options.length == 0) ||
                    !Array.isArray(values.options) ? (
                      <>
                        <PriorityHighIcon sx={{ color: "#000000" }} />
                        <p style={{ marginTop: 10 }}>
                          {t("trainer.quiz.alerts.nothing_selected_yet")}
                        </p>
                      </>
                    ) : (
                      <DropDownProposedAnswers
                        answers={values.options}
                        onDelete={(
                          newOptions,
                          activateIsRightQuestion = false
                        ) => {
                          setFieldValue("options", newOptions);
                          if (!values.setQuestionCorrectness) {
                            setFieldValue(
                              "setQuestionCorrectness",
                              activateIsRightQuestion
                            );
                          }
                        }}
                      />
                    )}
                  </Box>
                  <p></p>
                </div>
              </div>
            </div>
            <div className={"tajah-form-row"}>
              <div className={"tajah-form-row-element-v2"}>
                <div
                  className={"tajah-form-row-element-holder"}
                  style={{
                    width: "95%",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <button
                    type="submit"
                    className="admin_add_button"
                    disabled={isSubmitting}
                  >
                    {t("crud.placeholders.save")}
                  </button>

                  {props.renderedAs !== "questionBank" && wrapperContext.isEdit ? (
                    <button
                      type="button"
                      className="admin_add_button"
                      disabled={isSubmitting}
                      style={{
                        backgroundColor: "#dc3545",
                        color: "#fff",
                        marginRight: document.body.dir == "rtl" ? 10 : 0,
                        marginLeft: document.body.dir == "ltr" ? 10 : 0,
                      }}
                      onClick={() => {
                        wrapperContext.setIsEdit(false);
                        wrapperContext.setQuestion({});
                      }}
                    >
                      {t("crud.placeholders.cancel")}
                    </button>
                  ) : null}

                  {props.renderedAs !== "questionBank" ? (
                    <button
                      type="button"
                      className="admin_add_button"
                      disabled={isSubmitting}
                      style={{
                        backgroundColor: "#CDCDCD",
                        color: "#000000",
                        marginRight: document.body.dir == "rtl" ? 10 : 0,
                        marginLeft: document.body.dir == "ltr" ? 10 : 0,
                      }}
                      onClick={() => {
                        wrapperContext.setActivePanel(0);
                      }}
                    >
                      {t("crud.placeholders.previous")}
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}

export default SingularSelectQuestion;
