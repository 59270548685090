import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  editCourse,
  getCourseInfo,
} from "../../../../Services/api/courses/courseProvider";
import CourseForm from "../CourseForm/CourseForm";

export default function EditCourse({ isProgram, programCourses, role }) {
  const history = useHistory();
  const { id, courseId } = useParams();
  const [courseData, setCourseData] = useState(null);
  const [programData, setProgramData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCourseInfo(programCourses ? courseId : id)
      .then((res) => {
        setCourseData(res.data.course);
        programCourses &&
          getCourseInfo(id).then((response) =>
            setProgramData(response.data.course)
          );
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const submitHandler = async (formData) => {
    try {
      const response = await editCourse(
        programCourses ? courseId : id,
        formData
      );
      if (response.status === 200 && response.data.status) {
        role === "admin"
          ? history.push(
              programCourses
                ? `/admin/program/${id}/courses`
                : isProgram
                ? "/admin/program/list"
                : "/admin/courses"
            )
          : history.goBack();
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {response.data.msg ? response.data.msg : "success"}
          </span>,
          {
            autoClose: 1000,
          }
        );
      } else {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {response.data.msg ? response.data.msg : "Failure in service"}
          </span>
        );
      }
    } catch (err) {
      toast.error(
        <>
          <span
            style={{
              fontSize: 13,
              fontWeight: "bold",
              display: "block",
              paddingBottom: "10px",
            }}
          >
            {err.response.data.msg}
          </span>
          <ul>
            {Object.keys(err.response.data.errors).map((key) => {
              return (
                <li>
                  <span style={{ fontSize: 11 }}>
                    {err.response.data.errors[key]}
                  </span>
                </li>
              );
            })}
          </ul>
        </>
      );
    }
  };

  return (
    <CourseForm
      submitHandler={submitHandler}
      type={
        (programCourses
          ? programData?.status === "Draft"
          : courseData?.status === "Draft") ||
        (programCourses
          ? programData?.status === "Published" &&
            programData?.is_started === false &&
            programData?.number_of_users === 0
          : courseData?.status === "Published" &&
            courseData?.is_started === false &&
            courseData?.number_of_users === 0)
          ? "edit"
          : (
              programCourses
                ? programData?.status === "Published"
                : courseData?.status === "Published"
            )
          ? "canEdit"
          : "cannotEdit"
      }
      courseData={courseData}
      loading={loading}
      isProgram={isProgram}
      role={role}
      programCourses={programCourses}
      isEdit={true}
      programId={courseId}
      id={id}
    />
  );
}
