import {useTranslation} from "react-i18next";
import QuestionAnswerPanel from "../QuestionAnswerPanel/QuestionAnswerPanel";
import React from "react";

function TextQuestionAnswer(props) {
    require("./TextQuestionAnswer.css");

    const {t} = useTranslation();

    return (
        <>
            <QuestionAnswerPanel
                item={props.item}
            >
                <div className={"tajah-form-row"}>
                    <div className={"tajah-form-row-element-v2 tajah-question-info-section"}>
                        <div className={"tajah-form-row tajah-question-info-section-row"}>
                            <div className={"tajah-form-row-element-v2"}>
                                <div className={"tajah-form-row-element-holder"} style={{width: "95%"}}>
                                    <label htmlFor="title" className="admin_add_label">
                                        {t("trainer.quiz.crud.question_selected_option")}
                                    </label>
                                    <input
                                        value={JSON.parse(props.item.answer)}
                                        className="admin_filter_input"
                                        disabled={true}
                                    />
                                </div>
                                <div className={"tajah-form-row-element-holder"} style={{width: "95%"}}>
                                    <label htmlFor="title" className="admin_add_label">
                                        {t("trainer.quiz.crud.feedback")}
                                    </label>
                                    <input
                                        value={props.item.feedback}
                                        className="admin_filter_input"
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </QuestionAnswerPanel>
        </>
    )
}

export default TextQuestionAnswer;