import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import edit from "../../../assets/image/edit(1).png";
import {TrainerProfileContext} from "../../../Services/api/toutorials/trainerProfileContext";
import i18n from "../../../i18n/i18n";
import {Lang} from "../../../utils";
import EditOverView from "./EditOverView";
import parse from "html-react-parser";

function AddOverview(props) {
  require("./addOverview.css");
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);
  const trainerProfileContext = useContext(TrainerProfileContext);

  let description = "";
  if (
    (trainerProfileContext.trainersProfile.info &&
      trainerProfileContext.trainersProfile.info.description_ar == null) ||
    (trainerProfileContext.trainersProfile.info &&
      trainerProfileContext.trainersProfile.info.description_en == null)
  ) {
    description = <div>{t("no_record")}</div>;
  } else {
    description =
      i18n.language === Lang.AR
        ? trainerProfileContext.trainersProfile.info &&
          trainerProfileContext.trainersProfile.info.description_ar
        : trainerProfileContext.trainersProfile.info &&
          trainerProfileContext.trainersProfile.info.description_en;
  }

  let job_title = "";
  if (
    (trainerProfileContext.trainersProfile.info &&
      trainerProfileContext.trainersProfile.info.job_title_ar == null) ||
    (trainerProfileContext.trainersProfile.info &&
      trainerProfileContext.trainersProfile.info.job_title_en == null)
  ) {
    job_title = <div>{t("no_record")}</div>;
  } else {
    job_title =
      i18n.language === Lang.AR
        ? trainerProfileContext.trainersProfile.info &&
          trainerProfileContext.trainersProfile.info.job_title_ar
        : trainerProfileContext.trainersProfile.info &&
          trainerProfileContext.trainersProfile.info.job_title_en;
  }

  const editHandler = () => {
    setEdit(!edit);
  };

  return (
    <>
      {edit == false ? (
        <>
          <div
            className="head_box"
            style={{
              padding: "15px 0",
            }}
          >
            <div className="overview_add">
              <div className="overview_add_top">{t("addoverview")}</div>
              <div className="overview_add_bottom">{t("pleaseaddbr")}</div>
            </div>
          </div>

          <div className="overview_list">{parse(`${description}`)}</div>

          <div
            className="head_box"
            style={{
              padding: "15px 0",
            }}
          >
            <div className="overview_add">
              <div className="overview_add_top">{t("job_title")}</div>
            </div>
          </div>

          <div className="overview_list">{job_title}</div>

          <div
            className="overview_button_div"
            onClick={editHandler}
            style={{
              margin: "25px 0",
            }}
          >
            <button className="overview_button">{t("edit")}</button>
          </div>
        </>
      ) : (
        <>
          <EditOverView onClick={editHandler} />
        </>
      )}
    </>
  );
}

export default AddOverview;
