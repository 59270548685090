import i18n from "../../../../i18n/i18n";
import {useTranslation} from "react-i18next";
import logo from "../../../../assets/image/logo.png";
import hour from "../../../../assets/image/hour.png";
import icon from "../../../../assets/image/Icon.png";

function CoursesList() {
  require("./coursesList.css");
  document.body.dir = i18n.dir();
  const { t } = useTranslation();

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="head_box">
            <div className="head_lable">{t("candidate")}</div>

            <div className="head_view">
              <div className="">{t("ViewAll")}</div>
              <div className="arrow_seeall">
                <img src={icon} alt="" className="arrow_icon" />
              </div>
            </div>
          </div>

          <div className="courses_card_grid">
            <div className="courses_card">
              <div className="courses_card_first">
                <div className="courses_name">
                  <div className="logo_hover">
                    <img src={logo} alt="" className="logo_hover_width" />
                  </div>
                  <div className="">{t("basicCourse")}</div>
                </div>
              </div>
              <div className="courses_card_second">
                <div className="courses_card_category">{t("category")} </div>
                <div className="courses_card_profile">
                  <div className="courses_card_icon"></div>
                  <div className="courses_card_name">hakem</div>
                </div>
              </div>
              <div className="courses_card_third">
                <div className="courses_card_profile">
                  <div className=""></div>
                  <img src={hour} alt="" className="img_hour" />
                  <div className="">{t("hour")} 16</div>
                </div>
                <div className="">
                  {t("price")} : 300 {t("Rial")}
                </div>
              </div>
              <div className="courses_card_button">
                <button className="courses_card_button_in">
                  {" "}
                  {t("startnow")}{" "}
                </button>
              </div>
            </div>

            <div className="courses_card">
              <div className="courses_card_first">
                <div className="courses_name">
                  <div className="logo_hover">
                    <img src={logo} alt="" className="logo_hover_width" />
                  </div>
                  <div className="">{t("basicCourse")}</div>
                </div>
              </div>
              <div className="courses_card_second">
                <div className="courses_card_category">{t("category")} </div>
                <div className="courses_card_profile">
                  <div className="courses_card_icon"></div>
                  <div className="courses_card_name">hakem</div>
                </div>
              </div>
              <div className="courses_card_third">
                <div className="courses_card_profile">
                  <div className=""></div>
                  <img src={hour} alt="" className="img_hour" />
                  <div className="">{t("hour")} 16</div>
                </div>
                <div className="">
                  {t("price")} : 300 {t("Rial")}
                </div>
              </div>
              <div className="courses_card_button">
                <button className="courses_card_button_in">
                  {" "}
                  {t("startnow")}{" "}
                </button>
              </div>
            </div>

            <div className="courses_card">
              <div className="courses_card_first">
                <div className="courses_name">
                  <div className="logo_hover">
                    <img src={logo} alt="" className="logo_hover_width" />
                  </div>
                  <div className="">{t("basicCourse")}</div>
                </div>
              </div>
              <div className="courses_card_second">
                <div className="courses_card_category">{t("category")} </div>
                <div className="courses_card_profile">
                  <div className="courses_card_icon"></div>
                  <div className="courses_card_name">hakem</div>
                </div>
              </div>
              <div className="courses_card_third">
                <div className="courses_card_profile">
                  <div className=""></div>
                  <img src={hour} alt="" className="img_hour" />
                  <div className="">{t("hour")} 16</div>
                </div>
                <div className="">
                  {t("price")} : 300 {t("Rial")}
                </div>
              </div>
              <div className="courses_card_button">
                <button className="courses_card_button_in">
                  {" "}
                  {t("startnow")}{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CoursesList;
