import { useTranslation } from "react-i18next";
import FilterBox from "../../../Shared/Components/FilterBox/FilterBox";

const CouponsFilter = ({
  filterData,
  changeHandler,
  resetHandler,
  currentPage,
  setCurrentPage,
  filterHandler,
  resetFilterHandler,
  courses,
  programs,
  type,
}) => {
  const { t, i18n } = useTranslation();

  const mechanismOptions = [
    {
      id: 1,
      value: 2,
      label: t("coupon.label.per_value"),
    },
    {
      id: 2,
      value: 1,
      label: t("coupon.label.co_value"),
    },
  ];

  const parentTypeOptions = [
    {
      label: t("coupon.label.course"),
      id: 1,
      value: 1,
    },
    {
      label: t("coupon.label.program"),
      id: 2,
      value: 2,
    },
  ];

  const isUsedOptions = [
    {
      label: t("coupon.label.used"),
      id: 1,
      value: 1,
    },
    {
      label: t("coupon.label.not_used"),
      id: 2,
      value: 0,
    },
  ];
  const statusOptions = [
    {
      label: t("general.active"),
      id: 1,
      value: 1,
    },
    {
      label: t("general.inactive"),
      id: 2,
      value: 0,
    },
  ];

  const includeTaxOptions = [
    {
      label: t("yes"),
      id: 1,
      value: 1,
    },
    {
      label: t("no"),
      id: 0,
      value: 0,
    },
  ];

  const filterFields = [
    {
      id: "name",
      component: "input",
      label: t("coupon.label.coupon_name"),
      type: "text",
      placeholder: t("coupon.label.coupon_name"),
      name: "name",
      value: filterData.name,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    ...(type === "notGift"
      ? [
          {
            id: "mechanism",
            label: t("coupon.label.machinsim"),
            component: "select",
            placeholder: t("coupon.label.machinsim"),
            initialValue: t("coupon.label.machinsim"),
            name: "mechanism",
            value: filterData.mechanism,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            options: mechanismOptions || [],
          },
        ]
      : [
          {
            id: "coupon_email",
            component: "input",
            label: t("coupon.label.coupon_email"),
            type: "text",
            placeholder: t("coupon.label.coupon_email"),
            name: "coupon_email",
            value: filterData.coupon_email,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            onReset: resetHandler,
          },
        ]),
    {
      id: "is_used",
      label: t("coupon.label.use"),
      component: "select",
      placeholder: t("coupon.label.use"),
      initialValue: t("coupon.label.use"),
      name: "is_used",
      value: filterData.is_used,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: isUsedOptions || [],
    },
    {
      id: "start_date",
      label: t("general.search_start_date"),
      component: "datePicker",
      placeholder: t("general.search_start_date"),
      name: "start_date",
      value: filterData.start_date,
      classes: "col-12 col-md-6 col-xl-2",
      onchange: changeHandler,
    },
    {
      id: "end_date",
      label: t("general.search_end_date"),
      component: "datePicker",
      placeholder: t("general.search_end_date"),
      name: "end_date",
      value: filterData.end_date,
      classes: "col-12 col-md-6 col-xl-2",
      onchange: changeHandler,
    },
    {
      id: "parent_type",
      label: t("coupon.label.select_course_or_program"),
      component: "select",
      placeholder: t("coupon.label.select_course_or_program"),
      initialValue: t("coupon.label.select_course_or_program"),
      name: "parent_type",
      value: filterData.parent_type,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: parentTypeOptions || [],
    },

    ...(filterData.parent_type == 1
      ? [
          {
            id: "course",
            label: t("coupon.label.select_course"),
            component: "select",
            placeholder: t("coupon.label.select_course"),
            initialValue: t("coupon.label.select_course"),
            name: "course",
            value: filterData.course,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            options: courses?.length
              ? courses?.map((course) => ({
                  id: course.id,
                  value: course.id,
                  label: course.name,
                }))
              : [],
          },
        ]
      : []),
    ...(filterData.parent_type == 2
      ? [
          {
            id: "program",
            label: t("coupon.label.select_program"),
            component: "select",
            placeholder: t("coupon.label.select_program"),
            initialValue: t("coupon.label.select_program"),
            name: "program",
            value: filterData.program,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            options: programs?.length
              ? programs?.map((program) => ({
                  id: program.id,
                  value: program.id,
                  label: program.name,
                }))
              : [],
          },
        ]
      : []),
    ...(type !== "notGift"
      ? [
          {
            id: "include_tax",
            label: t("financial.label.include_tax"),
            component: "select",
            placeholder: t("financial.label.include_tax"),
            initialValue: t("financial.label.include_tax"),
            name: "include_tax",
            value: filterData.include_tax,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            options: includeTaxOptions || [],
          },
        ]
      : []),
    {
      id: "status",
      label: t("general.status"),
      component: "select",
      placeholder: t("general.status"),
      initialValue: t("general.status"),
      name: "status",
      value: filterData.status,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: statusOptions || [],
    },
    {
      id: "reset",
      component: "button",
      type: "button",
      name: "action",
      value: t("general.reset"),
      classes: "col-12 col-md-6 col-xl-2 border-button",
      action: () => {
        resetFilterHandler();
      },
      backgroundColor: "transparent",
      color: "#046c77",
      borderColor: "#046c77",
    },
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("general.search"),
      classes: "col-12 col-md-6  col-xl-2",
      action: () => {
        if (currentPage != 1) {
          setCurrentPage(1);
        } else {
          filterHandler();
        }
      },
    },
  ];
  return <FilterBox fields={filterFields} />;
};

export default CouponsFilter;
