import { Formik } from "formik";
import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import InputPhoneWithCountry from "../../../../../Components/Input/InputPhoneWithCountry";
import { CoursePaymentContext } from "../../../../../Services/api/courses/coursePaymentContext";
import {
  addToCart,
  payCourseWithBankTransfer,
} from "../../../../../Services/api/courses/courseProvider";
import video from "../../../../../assets/image/video.png";

function TrainingCoursePaymentWithBankTransfer(props) {
  require("./TrainingCoursePaymentWithBankTransfer.css");
  const { t } = useTranslation();
  const coursePaymentContext = useContext(CoursePaymentContext);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [toBeShownImage, setToBeShownImage] = useState(null);
  const inputFile = useRef(null);
  const history = useHistory();
  const [phone, setPhone] = useState("");

  const phoneHandleChange = (input) => {
    setPhone(input);
    validatePhoneNumber(input);
  };
  const validatePhoneNumber = (input_str) => {
    let phoneRe =
      /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g;
    return phoneRe.test(input_str);
  };
  return (
    <>
      <div className="modal_head">
        <div className="modal_head_label">
          <div className="">{t("safepurchase")}</div>
          <button
            type="button"
            className="close"
            onClick={() => {
              coursePaymentContext.setModal(false);
            }}
          >
            &times;
          </button>
        </div>
        <div className="modal_head_label_bottom">{t("allscr")}</div>
        <div className="modal_pay_head_card_after">
          <div className="modal_flex">
            <img
              src={props.courseDetailsContext.courseDetails.img}
              alt="..."
              className="modal_course_img_after"
            />
            <div className="modal_course_text_after">
              {props.courseDetailsContext.courseDetails.name}
            </div>
          </div>

          <div className="overall_modal">
            <div className="">
              {/*{((props.couponPrice || props.price && props.price.offer !== 0) || (props.couponPrice || props.price && props.price.tax !== 0)) && (*/}
              {/*    <div className="modal_coupon_price" style={{color:"black"}}>*/}
              {/*    {t("price")} : {props.couponPrice ? props.couponPrice.item_real_price : props.price.item_real_price}{" "}*/}
              {/*    {t("Rial")}*/}
              {/*    </div>*/}
              {/*)}*/}
              {props.couponPrice && (
                <div className="modal_coupon_price">
                  {t("price")} :{" "}
                  {props.couponPrice
                    ? props.couponPrice.real_price *
                      (props.couponPrice.purchaseDiscount / 100) *
                      props.couponPrice.numberOfPurchase
                    : props.giftDataPayload
                    ? parseFloat(props.price.to_be_paid).toFixed(2)
                    : props.courseDetailsContext?.courseDetails?.price}{" "}
                  {t("Rial")}
                </div>
              )}
              {props.couponPrice && (
                <div className="modal_coupon_price" style={{ color: "green" }}>
                  {t("dis_price")} :{" "}
                  {props.couponPrice.discount_price *
                    props.couponPrice.numberOfPurchase}{" "}
                  {t("Rial")}
                </div>
              )}
              {(props.couponPrice ||
                (props.price && props.price.paid_from_wallet !== 0)) && (
                <div className="modal_coupon_price" style={{ color: "green" }}>
                  {t("paid_from_wallet")} :{" "}
                  {props.couponPrice
                    ? parseFloat(props.couponPrice.paid_from_wallet).toFixed(2)
                    : parseFloat(props.price.paid_from_wallet).toFixed(2)}{" "}
                  {t("Rial")}
                </div>
              )}
              {(props.couponPrice ||
                (props.price && props.price.tax !== 0)) && (
                <div className="modal_coupon_price" style={{ color: "green" }}>
                  {t("tax")} :{" "}
                  {props.couponPrice
                    ? parseFloat(props.couponPrice.tax).toFixed(2)
                    : parseFloat(props.price.tax).toFixed(2)}{" "}
                  {t("Rial")}
                </div>
              )}
              {(props.couponPrice ||
                (props.price && props.price.offer !== 0)) && (
                <div className="modal_coupon_price" style={{ color: "green" }}>
                  {t("offer")} :{" "}
                  {props.couponPrice
                    ? parseFloat(props.couponPrice.offer).toFixed(2)
                    : parseFloat(props.price.offer).toFixed(2)}{" "}
                  {t("Rial")}
                </div>
              )}
              {props.couponPrice && (
                <div
                  className="modal_coupon_price"
                  style={{ fontSize: "18px" }}
                >
                  {t("total_price")} : {props.couponPrice.to_be_paid.toFixed(2)}{" "}
                  {t("Rial")}
                </div>
              )}
              {!props.couponPrice && (
                <div
                  className="modal_coupon_price"
                  style={{ fontSize: "18px" }}
                >
                  {t("total_price")} :{" "}
                  {props.couponPrice
                    ? props.couponPrice.real_price *
                      (props.couponPrice.purchaseDiscount / 100) *
                      props.couponPrice.numberOfPurchase
                    : props.giftDataPayload || props.price
                    ? parseFloat(props.price.to_be_paid).toFixed(2)
                    : props.courseDetailsContext?.courseDetails?.price}{" "}
                  {t("Rial")}
                </div>
              )}
              <div className="overall_text">{t("rialsaudi")}</div>
            </div>
            {props.courseDetailsContext.courseDetails.video && (
              <div className="modal_coupon_video">
                <img src={video} alt="..." />
                <div className="">{t("videode")}</div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="modal-body">
        <p
          style={{
            fontSize: 25,
            marginRight: "5%",
          }}
        >
          {t("paymentMethod")}
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: 10,
            marginRight: "5%",
          }}
        >
          <div>
            <i
              className={"fas rotate-icon fa-university"}
              style={{
                fontSize: 40,
                color: "#006D77",
              }}
            ></i>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              marginLeft: document.body.dir == "ltr" ? 10 : 0,
              marginRight: document.body.dir == "rtl" ? 10 : 0,
            }}
          >
            <span>{t("bankTransfer")}</span>
            <span
              style={{
                marginTop: 10,
                fontSize: 14,
                color: "#474747",
                width: "70%",
              }}
            >
              {t("bankTransferModalDescription")}
            </span>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 20,
          }}
        >
          <div
            style={{
              width: "90%",
              borderRadius: 10,
              borderWidth: 1,
              borderColor: "#C9C9C9",
              borderStyle: "solid",
              height: 100,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              padding: 10,
            }}
          >
            <div>
              <div>
                <span>{t("bankName")}:</span> <span>الراجحي</span>
              </div>
              <div>
                <span>{t("ibanNumber")}:</span>{" "}
                <span>SA8980000454608010000000</span>
              </div>
            </div>
          </div>
        </div>
        <Formik
          initialValues={{
            account_holder_name: "",
            phone_number: "",
            bank_name: "",
            iban_number: "",
            image: "",
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            values.phone_number = phone;
            let addToCartResponse = await addToCart(
              props.courseDetailsContext.courseDetails.id,
              props.coupon,
              props.giftDataPayload ? props.giftDataPayload : ""
            );
            if (
              addToCartResponse.status === 201 &&
              addToCartResponse.data.status
            ) {
              let formData = new FormData();
              Object.keys(values).forEach((field) => {
                if (field == "image") {
                  formData.append("payment_proof_img_name", values[field]);
                } else {
                  formData.append(field, values[field]);
                }
              });
              formData.append("payment_type", "bank-transfer");
              formData.append("cart_id", addToCartResponse.data.cart.id);
              if (props.giftDataPayload) {
                formData.append("course_id", props.giftDataPayload.course_id);
                formData.append(
                  "gift_discount",
                  props.giftDataPayload.gift_discount
                );
                formData.append(
                  "purchase_type",
                  props.giftDataPayload.purchase_type
                );
                formData.append(
                  "gift_receivers",
                  props.giftDataPayload.gift_receivers
                );
                formData.append("email", props.giftDataPayload.email);
                formData.append("sent_at", props.giftDataPayload.sent_at);
                formData.append("message", props.giftDataPayload.message);
                formData.append("coupon", props.coupon);
              }
              payCourseWithBankTransfer(formData)
                .then((res) => {
                  if (props.giftDataPayload) {
                    if (!props.courseDetailsContext.courseDetails.subscribed) {
                      history.push("/");
                      coursePaymentContext.setModal(false);
                    } else {
                      history.push("/");
                      coursePaymentContext.setModal(false);
                    }
                  }
                  toast.success(
                    <span style={{ fontSize: 14 }}>
                      {t("dashBoard.label.paymentSuccess")}
                    </span>,
                    {
                      onClose: () => window.location.reload(),
                      autoClose: 1000,
                    }
                  );
                  coursePaymentContext.setModal(false);
                  setSubmitting(false);
                })
                .catch((err) => {
                  toast.error(
                    <span style={{ fontSize: 13, fontWeight: "bold" }}>
                      {typeof err === "string" ? (
                        <span
                          style={{
                            fontSize: 12,
                            fontWeight: "500",
                            display: "block",
                          }}
                        >
                          {err}
                        </span>
                      ) : err.data ? (
                        <>
                          <span
                            style={{
                              fontSize: 13,
                              fontWeight: "bold",
                              display: "block",
                            }}
                          >
                            {err.data.msg}
                          </span>
                          <ul>
                            {Object.keys(err.data.errors).map((key) => {
                              return (
                                <li>
                                  <span style={{ fontSize: 11 }}>
                                    {err.data.errors[key]}
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </>
                      ) : (
                        t("Failure_in_service")
                      )}
                    </span>
                  );
                });
              // await removeFromCart(id, addToCartResponse.data.cart.id);
            }
          }}
          validate={(values) => {
            if (validatePhoneNumber(phone)) {
              values.phone_number = phone;
            }

            setHasSubmitted(true);
            const errors = {};
            if (!values.account_holder_name) {
              errors.account_holder_name = t("crud.errors.required");
            }

            if (!values.phone_number) {
              errors.phone_number = t("crud.errors.required");
            }
            if (!values.bank_name) {
              errors.bank_name = t("crud.errors.required");
            }

            if (!values.iban_number) {
              errors.iban_number = t("crud.errors.required");
            }

            if (
              values.iban_number.length < 20 ||
              values.iban_number.length > 34
            ) {
              errors.iban_number = t("dashBoard.label.iban_number");
            }

            if (!values.image) {
              errors.image = t("crud.errors.required");
            }

            return errors;
          }}
        >
          {({
            setFieldValue,
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit} className="form-payment">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 20,
                }}
              >
                <div
                  style={{
                    width: "90%",
                    display: "flex",
                    flexDirection: "row",
                  }}
                  className="form-payment__group"
                >
                  <div
                    style={{
                      flex: 1,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                      }}
                    >
                      <span>{t("accountOwnerName")}</span>
                      <span
                        style={{
                          color: "red",
                          fontSize: 20,
                          marginRight: document.body.dir == "rtl" ? 5 : 0,
                          marginLeft: document.body.dir == "rtl" ? 5 : 0,
                        }}
                      >
                        *
                      </span>
                    </div>
                    <input
                      style={{
                        border: "none",
                        borderBottomColor: "#C9C9C9",
                        borderBottomStyle: "solid",
                        borderBottomWidth: 1,
                        paddingBottom: 10,
                        fontSize: 13,
                        width: "90%",
                      }}
                      disabled={isSubmitting}
                      onChange={handleChange}
                      type={"text"}
                      placeholder={t("crud.placeholders.bank_account_name")}
                      value={values.account_holder_name}
                      name={"account_holder_name"}
                      id={"account_holder_name"}
                    />
                    <p className={"form-input-error-space"}>
                      {errors.account_holder_name
                        ? errors.account_holder_name
                        : null}
                    </p>
                  </div>
                  <div
                    style={{
                      flex: 1,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                      }}
                    >
                      <span>{t("mobileNumber")}</span>
                      <span
                        style={{
                          color: "red",
                          fontSize: 20,
                          marginRight: document.body.dir == "rtl" ? 5 : 0,
                          marginLeft: document.body.dir == "rtl" ? 5 : 0,
                        }}
                      >
                        *
                      </span>
                    </div>
                    <InputPhoneWithCountry
                      style={{
                        border: "none",
                        borderBottomColor: "#C9C9C9",
                        borderBottomStyle: "solid",
                        borderBottomWidth: 1,
                        paddingBottom: 10,
                        fontSize: 13,
                        width: "90%",
                      }}
                      initialValue={phone}
                      setContent={phoneHandleChange}
                      maxLength={15}
                      hint={"(123)123-1231 | 1231231231"}
                    />
                    <p className={"form-input-error-space"}>
                      {errors.phone_number && !phone
                        ? errors.phone_number
                        : null}
                      {phone && !validatePhoneNumber(phone)
                        ? t("validatePhone")
                        : null}
                    </p>
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 10,
                }}
              >
                <div
                  style={{
                    width: "90%",
                    display: "flex",
                    flexDirection: "row",
                  }}
                  className="form-payment__group"
                >
                  <div
                    style={{
                      flex: 1,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                      }}
                    >
                      <span>{t("bankName")}</span>
                      <span
                        style={{
                          color: "red",
                          fontSize: 20,
                          marginRight: document.body.dir == "rtl" ? 5 : 0,
                          marginLeft: document.body.dir == "rtl" ? 5 : 0,
                        }}
                      >
                        *
                      </span>
                    </div>
                    <input
                      style={{
                        border: "none",
                        borderBottomColor: "#C9C9C9",
                        borderBottomStyle: "solid",
                        borderBottomWidth: 1,
                        paddingBottom: 10,
                        fontSize: 13,
                        width: "90%",
                      }}
                      disabled={isSubmitting}
                      onChange={handleChange}
                      type={"text"}
                      placeholder={t("crud.placeholders.bank_name")}
                      value={values.bank_name}
                      name={"bank_name"}
                      id={"bank_name"}
                    />
                    <p className={"form-input-error-space"}>
                      {errors.bank_name ? errors.bank_name : null}
                    </p>
                  </div>
                  <div
                    style={{
                      flex: 1,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                      }}
                    >
                      <span>{t("ibanNumber")}</span>
                      <span
                        style={{
                          color: "red",
                          fontSize: 20,
                          marginRight: document.body.dir == "rtl" ? 5 : 0,
                          marginLeft: document.body.dir == "rtl" ? 5 : 0,
                        }}
                      >
                        *
                      </span>
                    </div>
                    <input
                      style={{
                        border: "none",
                        borderBottomColor: "#C9C9C9",
                        borderBottomStyle: "solid",
                        borderBottomWidth: 1,
                        paddingBottom: 10,
                        fontSize: 13,
                        width: "90%",
                      }}
                      disabled={isSubmitting}
                      onChange={handleChange}
                      type={"text"}
                      placeholder={t("crud.placeholders.iban_number")}
                      value={values.iban_number}
                      name={"iban_number"}
                      id={"iban_number"}
                    />
                    <p className={"form-input-error-space"}>
                      {errors.iban_number ? errors.iban_number : null}
                    </p>
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 20,
                }}
              >
                <div
                  style={{
                    width: "90%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span>{t("bankTransferPhotoUpload")}</span>
                    <span
                      style={{
                        color: "red",
                        fontSize: 20,
                        marginRight: document.body.dir == "rtl" ? 5 : 0,
                        marginLeft: document.body.dir == "rtl" ? 5 : 0,
                      }}
                    >
                      *
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: 20,
                      gap: 10,
                    }}
                    className="form-payment__photo"
                  >
                    <div
                      style={{
                        flex: 1,
                        height: 80,
                      }}
                      className="form-payment__photo-wrapper"
                    >
                      <div
                        style={{
                          height: "100%",
                          width: "100%",
                          borderRadius: 10,
                          borderStyle: "dashed",
                          borderWidth: 1,
                          borderColor: "#8F8F8F",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          position: "relative",
                        }}
                      >
                        <img
                          style={{
                            width: "auto",
                            maxHeight: 67,
                            maxWidth: "100%",
                          }}
                          src={toBeShownImage ? toBeShownImage : ""}
                          className="logo_tebx"
                        />
                        <div
                          style={{
                            cursor: "pointer",
                            position: "absolute",
                            width: 30,
                            height: 30,
                            borderRadius: 30,
                            backgroundColor: "#18B1B9",
                            top: "84%",
                            left: "4%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            if (!isSubmitting && inputFile) {
                              inputFile.current.click();
                            }
                          }}
                        >
                          <i
                            className={"fas rotate-icon fa-image"}
                            style={{
                              fontSize: 13,
                              color: "#FFF",
                            }}
                          ></i>
                        </div>
                        <input
                          id="file"
                          name="file"
                          type="file"
                          ref={inputFile}
                          style={{ display: "none" }}
                          onChange={(event) => {
                            let file = event.target.files[0];
                            let reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onload = () => {
                              setFieldValue("image", event.target.files[0]);
                              setToBeShownImage(reader.result);
                            };
                            reader.onerror = function (error) {
                              
                            };
                          }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        flex: 3,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p>{t("bankTransferPhotoUploadMessage")}</p>
                    </div>
                  </div>
                  <p
                    style={{ margin: "15px 0 0 0" }}
                    className={"form-input-error-space"}
                  >
                    {errors.image ? errors.image : null}
                  </p>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 50,
                }}
              >
                <div
                  style={{
                    width: "90%",
                  }}
                >
                  <button
                    style={{
                      width: 130,
                      height: 30,
                      borderRadius: 10,
                      backgroundColor: "#006D77",
                      color: "#FFF",
                      fontSize: 13,
                    }}
                    disabled={isSubmitting}
                  >
                    {t("sendNow")}
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default TrainingCoursePaymentWithBankTransfer;
