import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  ticketTypesEdit,
  ticketTypesUpdate,
} from "../../../../../Services/api/Tickets/TicketsProvider";
import SkeletonCard from "../../../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../../../Shared/Components/Spinner/SkeletonCardOverlay";
import "./Edit.css";

function EditTicketsType(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [statuses, setStatuses] = useState([]);
  const [fileTypes, setFileTypes] = useState([]);
  const [fieldRequirements, setFieldRequirements] = useState([]);
  const [selectedFileTypes, setSelectedFileTypes] = useState([]);
  const [data, setData] = useState([]);
  const [globalIsSubmitting, setGlobalIsSubmitting] = useState(false);
  const [subjectsAssignees, setsubjectsAssignees] = useState([]);
  const [assignees, setAssignees] = useState([]);

  const initialSubjectRowData = {
    name_ar: "",
    name_en: "",
  };

  const reformatAssignees = async () => {
    let filteredAssignees = [];
    await assignees.forEach((item) => {
      const filteredAssigneesItem = {};
      filteredAssigneesItem.id = item.id;
      filteredAssigneesItem.name_ar = item.name_ar;
      filteredAssigneesItem.name_en = item.name_en;
      filteredAssignees.push(filteredAssigneesItem);
    });
    await setsubjectsAssignees(filteredAssignees);
  };

  useEffect(() => {
    async function executeOnLoad() {
      let response = await ticketTypesEdit(id);
      if (response.status && response.status == 200) {
        setStatuses(response.data.data.statuses);
        setFieldRequirements(response.data.data.fieldRequirementRules);
        setFileTypes(response.data.data.fileTypes);
        setData(response.data.data.ticketType);
        setAssignees(response.data.data.ticketType.subjects);

        let tempSelectedFileTypes = [];
        response.data.data.fileTypes.forEach((item) => {
          if (
            response.data.data.ticketType.allowed_files?.includes(
              item.value
            )
          ) {
            tempSelectedFileTypes.push(item);
          }
        });

        setSelectedFileTypes(tempSelectedFileTypes);

        setIsLoading(false);
      }
    }

    executeOnLoad();
  }, []);

  useEffect(() => {
    if (assignees && assignees.length > 0) {
      reformatAssignees();
    }
  }, [assignees]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="admin_label">
              <NavLink to="/admin">{t("Main")}</NavLink>
              <p style={{ padding: "0 5px" }}>/</p>
              <NavLink to="/admin/tickets">
                {t("admin.label.list_tickets")}
              </NavLink>
              <p style={{ padding: "0 5px" }}>/</p>
              <NavLink to="/admin/ticket/types">
                {t("admin.label.list_ticket_types")}
              </NavLink>
              <p style={{ padding: "0 5px" }}>/</p>
              {t("admin.label.create_ticket_types")}
            </div>
            {isLoading ? (
              <div
                style={{
                  height: "65vh",
                }}
              >
                <SkeletonCard />
              </div>
            ) : (
              <div
                style={{
                  position: "relative",
                  backgroundColor: "#FFF",
                  width: "100%",
                  boxShadow: "2px 2px 10px #ddd",
                  borderRadius: 20,
                  padding: 30,
                }}
              >
                {globalIsSubmitting ? (
                  <SkeletonCardOverlay borderRadius={20} />
                ) : null}

                <Formik
                  initialValues={{
                    name_en: data.names.en,
                    name_ar: data.names.ar,
                    serial_prefix: data.serial_prefix,
                    attachment: data.attachment,
                    allowed_file_extensions: selectedFileTypes,
                    reopened_days: data.reopened_days,
                    hold_days: data.hold_days,
                    should_rate: data.should_rate,
                    allowed_file_size: data.allowed_file_size,
                    status: data.status.value,
                    subjects:
                      subjectsAssignees.length === 0
                        ? [
                          {
                            ...initialSubjectRowData,
                          },
                        ]
                        : subjectsAssignees,
                  }}
                  enableReinitialize={true}
                  onSubmit={async (values, { setSubmitting }) => {
                    try {
                      setGlobalIsSubmitting(true);
                      setSubmitting(true);
                      values.name = { ar: values.name_ar, en: values.name_en };
                      values.attachment = values.attachment.value;
                      values.allowed_file_extensions =
                        values.allowed_file_extensions.map((f) => f.value);

                      delete values.name_ar;
                      delete values.name_en;

                      let response = await ticketTypesUpdate(id, values);
                      if (response.status == 200 && response.data.status) {
                        history.push("/admin/ticket/types");
                      } else {
                        toast.error(
                          <span style={{ fontSize: 13, fontWeight: "bold" }}>
                            {t("failed_editing")}
                          </span>
                        );
                      }
                      setSubmitting(false);
                      setGlobalIsSubmitting(false);
                    } catch (err) {
                      toast.error(
                        <div>
                          <p style={{ fontSize: 13, fontWeight: "bold" }}>
                            {err.response.data.msg}
                          </p>

                          <ul>
                            {Object.keys(err.response.data.errors).map(
                              (key) => {
                                return (
                                  <li>
                                    <span style={{ fontSize: 11 }}>
                                      {err.response.data.errors[key]}
                                    </span>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      );
                      setSubmitting(false);
                      setGlobalIsSubmitting(false);
                    }
                  }}
                  validateOnChange={hasSubmitted}
                  validate={async (values) => {
                    setHasSubmitted(true);
                    const errors = {};
                    const subjectsErrors = [];

                    if (!values.name_en) {
                      errors.name_en = t("crud.errors.required");
                    }

                    if (!values.name_ar) {
                      errors.name_ar = t("crud.errors.required");
                    }

                    await values.subjects.forEach((item, index) => {
                      const errorInstance = {};
                      if (!item.name_en) {
                        errorInstance.name_en = t("crud.errors.required");
                      }

                      if (!item.name_ar) {
                        errorInstance.name_ar = t("crud.errors.required");
                      }

                      if (Object.keys(errorInstance).length !== 0) {
                        subjectsErrors[index] = errorInstance;
                      }
                    });

                    if (subjectsErrors.length > 0) {
                      errors.subjects = subjectsErrors;
                    }

                    if (!values.serial_prefix) {
                      errors.serial_prefix = t("crud.errors.required");
                    }

                    if (!values.attachment) {
                      errors.attachment = t("crud.errors.required");
                    }

                    if (
                      values.attachment.value != "not allowed" &&
                      values.allowed_file_extensions &&
                      values.allowed_file_extensions.length == 0
                    ) {
                      errors.allowed_file_extensions = t(
                        "crud.errors.required"
                      );
                    }

                    if (
                      values.attachment.value != "not allowed" &&
                      !values.allowed_file_size
                    ) {
                      errors.allowed_file_size = t("crud.errors.required");
                    }

                    if (!values.reopened_days) {
                      errors.reopened_days = t("crud.errors.required");
                    } else if (
                      values.reopened_days &&
                      values.reopened_days.toString().match(/(\D)/g)
                    ) {
                      errors.reopened_days = t("crud.errors.positiveInteger");
                    }

                    if (!values.hold_days) {
                      errors.hold_days = t("crud.errors.required");
                    } else if (
                      values.hold_days &&
                      values.hold_days.toString().match(/(\D)/g)
                    ) {
                      errors.hold_days = t("crud.errors.positiveInteger");
                    }

                    if (!values.should_rate) {
                      errors.should_rate = t("crud.errors.required");
                    }

                    if (!values.status) {
                      errors.status = t("crud.errors.required");
                    }

                    return errors;
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit} className="admin_add_form">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          style={{
                            flex: 1,
                            paddingLeft: 15,
                            paddingRight: 15,
                          }}
                        >
                          <label htmlFor="name_en" className="admin_add_label">
                            {t("crud.placeholders.name_en")}
                          </label>
                          <input
                            id="name_en"
                            type="text"
                            name="name_en"
                            className="admin_add_input"
                            onChange={handleChange}
                            placeholder={t("crud.placeholders.name_en")}
                            value={values.name_en}
                          />
                          <p className={"form-input-error-space"}>
                            {errors.name_en ? errors.name_en : null}
                          </p>
                        </div>

                        <div
                          style={{
                            flex: 1,
                            paddingLeft: 15,
                            paddingRight: 15,
                          }}
                        >
                          <label htmlFor="name_ar" className="admin_add_label">
                            {t("crud.placeholders.name_ar")}
                          </label>
                          <input
                            id="name_ar"
                            type="text"
                            name="name_ar"
                            className="admin_add_input"
                            onChange={handleChange}
                            placeholder={t("crud.placeholders.name_ar")}
                            value={values.name_ar}
                          />
                          <p className={"form-input-error-space"}>
                            {errors.name_ar ? errors.name_ar : null}
                          </p>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          style={{
                            flex: 1,
                            paddingLeft: 15,
                            paddingRight: 15,
                          }}
                        >
                          <label
                            htmlFor="serial_prefix"
                            className="admin_add_label"
                          >
                            {t("crud.placeholders.sequence_opening")}
                          </label>
                          <input
                            id="serial_prefix"
                            type="text"
                            name="serial_prefix"
                            className="admin_add_input"
                            onChange={handleChange}
                            placeholder={t(
                              "crud.placeholders.sequence_opening"
                            )}
                            value={values.serial_prefix}
                          />
                          <p className={"form-input-error-space"}>
                            {errors.serial_prefix ? errors.serial_prefix : null}
                          </p>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          style={{
                            flex: 1,
                            paddingLeft: 15,
                            paddingRight: 15,
                          }}
                        >
                          <label
                            htmlFor="attachment"
                            className="admin_add_label"
                          >
                            {t("crud.placeholders.select.file_selection")}
                          </label>
                          <Select
                            name="attachment"
                            id="attachment"
                            options={fieldRequirements}
                            getOptionLabel={(option) => option.text}
                            getOptionValue={(option) => option.value}
                            defaultValue={values.attachment}
                            onChange={(item) => {
                              setFieldValue("attachment", item);
                            }}
                            placeholder={t(
                              "crud.placeholders.select.file_selection"
                            )}
                          />
                          <p className={"form-input-error-space"}>
                            {errors.attachment ? errors.attachment : null}
                          </p>
                        </div>
                      </div>

                      {values.attachment.value != "not allowed" ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <div
                            style={{
                              flex: 1,
                              paddingLeft: 15,
                              paddingRight: 15,
                            }}
                          >
                            <label
                              htmlFor="allowed_file_extensions"
                              className="admin_add_label"
                            >
                              {t("crud.placeholders.select.file_types")}
                            </label>

                            <Select
                              name="allowed_file_extensions"
                              id="allowed_file_extensions"
                              options={fileTypes}
                              getOptionLabel={(option) => option.text}
                              getOptionValue={(option) => option.value}
                              defaultValue={values.allowed_file_extensions}
                              onChange={(items) => {
                                let selectedItems = [];
                                items.forEach((item) => {
                                  selectedItems.push(item);
                                });
                                setFieldValue(
                                  "allowed_file_extensions",
                                  selectedItems
                                );
                              }}
                              isMulti={true}
                              placeholder={t(
                                "crud.placeholders.select.file_types"
                              )}
                            />

                            <p className={"form-input-error-space"}>
                              {errors.allowed_file_extensions
                                ? errors.allowed_file_extensions
                                : null}
                            </p>
                          </div>

                          <div
                            style={{
                              flex: 1,
                              paddingLeft: 15,
                              paddingRight: 15,
                            }}
                          >
                            <label
                              htmlFor="allowed_file_size"
                              className="admin_add_label"
                            >
                              {t("crud.placeholders.select.maximum_size")}
                            </label>
                            <input
                              id="allowed_file_size"
                              type="text"
                              name="allowed_file_size"
                              className="admin_add_input"
                              onChange={handleChange}
                              placeholder={t("TypeFile.sizeType")}
                              value={values.allowed_file_size}
                            />
                            <p className={"form-input-error-space"}>
                              {errors.allowed_file_size
                                ? errors.allowed_file_size
                                : null}
                            </p>
                          </div>
                        </div>
                      ) : null}

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          style={{
                            flex: 1,
                            paddingLeft: 15,
                            paddingRight: 15,
                          }}
                        >
                          <label
                            htmlFor="reopened_days"
                            className="admin_add_label"
                          >
                            {t("crud.placeholders.allowance_days")}
                          </label>
                          <input
                            id="reopened_days"
                            type="text"
                            name="reopened_days"
                            className="admin_add_input"
                            onChange={handleChange}
                            placeholder={t("crud.placeholders.allowance_days")}
                            value={values.reopened_days}
                          />
                          <p className={"form-input-error-space"}>
                            {errors.reopened_days ? errors.reopened_days : null}
                          </p>
                        </div>

                        <div
                          style={{
                            flex: 1,
                            paddingLeft: 15,
                            paddingRight: 15,
                          }}
                        >
                          <label
                            htmlFor="hold_days"
                            className="admin_add_label"
                          >
                            {t("crud.placeholders.waiting_days")}
                          </label>
                          <input
                            id="hold_days"
                            type="text"
                            name="hold_days"
                            className="admin_add_input"
                            onChange={handleChange}
                            placeholder={t("crud.placeholders.waiting_days")}
                            value={values.hold_days}
                          />
                          <p className={"form-input-error-space"}>
                            {errors.hold_days ? errors.hold_days : null}
                          </p>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          style={{
                            flex: 1,
                            paddingLeft: 15,
                            paddingRight: 15,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <label className="admin_add_label">
                            {t("crud.placeholders.select.activation")}
                          </label>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                flex: 1,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <input
                                type="radio"
                                name="activationOptions"
                                id="active"
                                value={"active"}
                                checked={
                                  values.status == "active" ? true : false
                                }
                                onChange={(item) => {
                                  setFieldValue("status", item.target.value);
                                }}
                              />
                              <div
                                style={{
                                  marginRight:
                                    document.body.dir == "rtl" ? 10 : 0,
                                  marginLeft:
                                    document.body.dir == "rtl" ? 10 : 0,
                                }}
                              >
                                <span className="admin_add_label">
                                  {t("crud.values.active")}
                                </span>
                              </div>
                            </div>

                            <div
                              style={{
                                flex: 1,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <input
                                type="radio"
                                name="activationOptions"
                                id="inactive"
                                value={"inactive"}
                                checked={
                                  values.status == "inactive" ? true : false
                                }
                                onChange={(item) => {
                                  setFieldValue("status", item.target.value);
                                }}
                              />

                              <div
                                style={{
                                  marginRight:
                                    document.body.dir == "rtl" ? 10 : 0,
                                  marginLeft:
                                    document.body.dir == "rtl" ? 10 : 0,
                                }}
                              >
                                <span className="admin_add_label">
                                  {t("crud.values.inactive")}
                                </span>
                              </div>
                            </div>
                          </div>

                          <p className={"form-input-error-space"}>
                            {errors.status ? errors.status : null}
                          </p>
                        </div>

                        <div
                          style={{
                            flex: 1,
                            paddingLeft: 15,
                            paddingRight: 15,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <label className="admin_add_label">
                            {t("crud.placeholders.select.support_rating")}
                          </label>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                flex: 1,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <input
                                type="radio"
                                name="rationSupportOptions"
                                id="ratingSupportYes"
                                value={1}
                                checked={values.should_rate == 1 ? true : false}
                                onChange={(item) => {
                                  setFieldValue(
                                    "should_rate",
                                    item.target.value
                                  );
                                }}
                              />
                              <div
                                style={{
                                  marginRight:
                                    document.body.dir == "rtl" ? 10 : 0,
                                  marginLeft:
                                    document.body.dir == "rtl" ? 10 : 0,
                                }}
                              >
                                <span className="admin_add_label">
                                  {t("crud.values.yes")}
                                </span>
                              </div>
                            </div>

                            <div
                              style={{
                                flex: 1,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <input
                                type="radio"
                                name="rationSupportOptions"
                                id="ratingSupportNo"
                                value={0}
                                checked={values.should_rate == 0 ? true : false}
                                onChange={(item) => {
                                  setFieldValue(
                                    "should_rate",
                                    item.target.value
                                  );
                                }}
                              />

                              <div
                                style={{
                                  marginRight:
                                    document.body.dir == "rtl" ? 10 : 0,
                                  marginLeft:
                                    document.body.dir == "rtl" ? 10 : 0,
                                }}
                              >
                                <span className="admin_add_label">
                                  {t("crud.values.no")}
                                </span>
                              </div>
                            </div>
                          </div>

                          <p className={"form-input-error-space"}>
                            {errors.should_rate ? errors.should_rate : null}
                          </p>
                        </div>
                      </div>

                      {values.subjects &&
                        values.subjects.map((item, index) => {
                          return (
                            <div className="card-info-email-name" key={index}>
                              <div
                                style={{
                                  position: "absolute",
                                  height: 20,
                                  width: 20,
                                  borderRadius: 0,
                                  backgroundColor: "red",
                                  left: document.body.dir == "rtl" ? 0 : "auto",
                                  right:
                                    document.body.dir == "ltr" ? 0 : "auto",
                                  top: 10,
                                  marginLeft:
                                    document.body.dir == "rtl" ? 10 : 0,
                                  marginRight:
                                    document.body.dir == "ltr" ? 10 : 0,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  let currentUsers = values.subjects;
                                  currentUsers.splice(index, 1);
                                  setFieldValue("subjects", currentUsers);
                                }}
                              >
                                <i
                                  className="fas fa-times"
                                  style={{ color: "white", fontSize: 12 }}
                                ></i>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <div
                                  style={{
                                    flex: "1 1 0%",
                                    display: "flex",
                                    width: "100%",
                                    border: "1px solid #eee",
                                    justifyContent: "space-between",
                                    borderRadius: "5px",
                                    padding: "20px 20px 0",
                                  }}
                                  className="subject-card-info__fields"
                                >
                                  <div
                                    style={{
                                      width: "47%",
                                    }}
                                  >
                                    <label
                                      htmlFor="allowed_file_extensions"
                                      className="subject-input-label"
                                    >
                                      {t("crud.label.subject_name_ar")}
                                    </label>
                                    <input
                                      id={`name_ar[${index}]`}
                                      type="text"
                                      name={`name_ar[${index}]`}
                                      className="subject-input"
                                      onChange={(item) => {
                                        setFieldValue(
                                          `subjects[${index}].name_ar`,
                                          item.target.value
                                        );
                                      }}
                                      placeholder={t(
                                        "crud.label.subject_name_ar"
                                      )}
                                      value={values.subjects[index].name_ar}
                                    />

                                    <p
                                      className={"form-input-error-space"}
                                      style={{ margin: " 5px 10px" }}
                                    >
                                      {errors.subjects &&
                                        errors.subjects.length > 0 &&
                                        errors.subjects[index]?.name_ar
                                        ? errors.subjects[index].name_ar
                                        : null}
                                    </p>
                                  </div>

                                  <div
                                    style={{
                                      width: "47%",
                                    }}
                                  >
                                    <label
                                      htmlFor="allowed_file_extensions"
                                      className="subject-input-label"
                                    >
                                      {t("crud.label.subject_name_en")}
                                    </label>
                                    <input
                                      id={`name_en[${index}]`}
                                      type="text"
                                      name={`name_en[${index}]`}
                                      className="subject-input"
                                      onChange={(item) => {
                                        setFieldValue(
                                          `subjects[${index}].name_en`,
                                          item.target.value
                                        );
                                      }}
                                      placeholder={t(
                                        "crud.label.subject_name_en"
                                      )}
                                      value={values.subjects[index].name_en}
                                    />

                                    <p
                                      className={"form-input-error-space"}
                                      style={{ margin: " 5px 10px" }}
                                    >
                                      {errors.subjects &&
                                        errors.subjects.length > 0 &&
                                        errors.subjects[index]?.name_en
                                        ? errors.subjects[index].name_en
                                        : null}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginBottom: 30,
                        }}
                      >
                        <button
                          type="button"
                          className="add-new-user"
                          disabled={isSubmitting}
                          style={{
                            marginRight: document.body.dir == "rtl" ? 10 : 0,
                            marginLeft: document.body.dir == "ltr" ? 10 : 0,
                          }}
                          onClick={() => {
                            let currentUsers = values.subjects;
                            currentUsers.push({ ...initialSubjectRowData });
                            setFieldValue("subjects", currentUsers);
                          }}
                        >
                          <i
                            className="fa fa-plus"
                            style={{
                              marginRight: document.body.dir == "rtl" ? 0 : 10,
                              marginLeft: document.body.dir == "ltr" ? 0 : 10,
                            }}
                          ></i>
                          {t("crud.placeholders.add_new_subject")}
                        </button>
                        <p
                          className={"form-input-error-space"}
                          style={{ margin: " 5px 10px" }}
                        >
                          {errors.newUser ? errors.newUser : null}
                        </p>
                      </div>

                      <div>
                        <button
                          type="submit"
                          className="admin_add_button"
                          disabled={isSubmitting}
                        >
                          {t("crud.placeholders.submit")}
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default EditTicketsType;
