import { ReactComponent as CircleGrayIcon } from "../../../../../assets/icons/CircleGray.svg";
import { ReactComponent as GreenCircleIcon } from "../../../../../assets/icons/GreenCircle.svg";
import { ReactComponent as NotDoneIcon } from "../../../../../assets/icons/NotDone.svg";
import { ReactComponent as OrangeCircleIcon } from "../../../../../assets/icons/orangeCircle.svg";

import { useTranslation } from "react-i18next";

export default function LessonItem({
  logo,
  icon,
  color,
  name,
  title,
  description,
  badge,
  columns,
  buttons,
  footer,
}) {
  const { t } = useTranslation();
  return (
    <div className="tw-rounded-lg tw-border tw-border-gray-300 tw-divide-y tw-divide-gray-300">
      <div className="tw-rounded-t-lg tw-p-4 tw-flex tw-flex-col md:tw-flex-row tw-items-stretch tw-justify-between tw-space-y-4 md:tw-space-y-0 md:tw-space-s-4">
        <div className="tw-flex tw-items-center tw-space-s-6">
          {logo ? (
            <div
              className="tw-bg-white tw-rounded-full tw-h-14 tw-aspect-square tw-shadow tw-bg-cover"
              style={{ backgroundImage: `url(${logo})` }}
            ></div>
          ) : (
            <div
              className="tw-bg-[#5d86ab] tw-rounded-lg tw-text-2xl tw-text-white tw-h-12 tw-aspect-square tw-flex tw-items-center tw-justify-center tw-font-sans"
              style={{ color: color }}
            >
              {icon}
            </div>
          )}

          <div className="tw-space-y-2">
            <div className="tw-flex tw-items-center tw-space-s-2">
              <div className="tw-text-lg tw-font-bold">{title}</div>
              {description && <div className="tw-text-gray-400 tw-text-xs">{description}</div>}
              {badge && <div className="tw-rounded-full tw-py-1.5 tw-px-4 tw-text-sm tw-bg-teal-500 tw-text-white " style={{ backgroundColor: badge.color, color: badge.textColor }}>{badge.label}</div>}
            </div>
            <div className="tw-text-gray-400 tw-text-sm">{name}</div>
          </div>
        </div>
        {columns?.length ? (
          <div className="tw-flex tw-flex-col lg:tw-flex-row tw-items-stretch lg:tw-divide-s tw-divide-gray-300">
            {columns.map((column) => (
              <div
                className="tw-px-4 tw-flex tw-flex-col tw-items-center tw-justify-between"
                key={column.label}
              >
                <div className="tw-text-gray-500 tw-text-sm">
                  {column.label}
                </div>
                <div className="tw-font-bold">{column.value}</div>
              </div>
            ))}
          </div>
        ) : (
          <></>
        )}
        {buttons?.length ? (
          <div className="tw-flex tw-flex-col tw-space-y-4 sm:tw-flex-row sm:tw-space-s-4 sm:tw-space-y-0 tw-items-center tw-justify-center">
            {buttons.map((button) => (
              <button
                key={button.label}
                type="button"
                disabled={button.disabled}
                onClick={button.onClick}
                className={`tw-rounded-lg tw-py-2 tw-px-4 ${
                  button.secondary
                    ? "tw-border tw-border-[#3db9be] tw-text-[#3db9be]"
                    : "tw-text-white tw-bg-[#3db9be]"
                } disabled:tw-opacity-40`}
                style={{
                  ...(button.color
                    ? button.secondary
                      ? { color: button.color, borderColor: button.color }
                      : { backgroundColor: button.color }
                    : {}),
                }}
              >
                {button.label}
              </button>
            ))}
          </div>
        ) : (
          <></>
        )}
      </div>
      {footer.flags?.include_view ||
      footer.flags?.include_achivement ||
      footer.stars ||
      footer.columns?.length ? (
        <div className="tw-rounded-b-lg tw-py-3 tw-px-6 tw-bg-gray-50 tw-text-gray-400 tw-flex tw-flex-col lg:tw-flex-row tw-space-y-4 lg:tw-space-y-0 lg:tw-space-s-8 tw-justify-between tw-text-xs tw-font-bold">
          <div className="tw-flex tw-flex-col lg:tw-flex-row tw-space-y-4 lg:tw-space-y-0 lg:tw-space-s-8">
            {footer.flags?.include_view || footer.flags?.include_achivement ? (
              <div className="tw-flex tw-items-center tw-space-s-4">
                {footer.flags?.include_view ? (
                  <div className="tw-flex tw-items-center tw-space-s-2">
                    <div>{t("seen")}</div>
                    {footer.flags.is_watched ? (
                      <OrangeCircleIcon className="tw-h-4 tw-w-4" />
                    ) : (
                      <CircleGrayIcon className="tw-h-4 tw-w-4" />
                    )}
                  </div>
                ) : (
                  <></>
                )}
                {footer.flags?.include_achivement ? (
                  <div className="tw-flex tw-items-center tw-space-s-2">
                    <div>{t("acomplished")}</div>
                    {footer.flags.is_achieved ? (
                      <GreenCircleIcon className="tw-h-4 tw-w-4" />
                    ) : footer.flags.finished ? (
                      <NotDoneIcon className="tw-h-4 tw-w-4" />
                    ) : (
                      <CircleGrayIcon className="tw-h-4 tw-w-4" />
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
            {footer.columns?.length ? (
              <div className="tw-flex tw-flex-col md:tw-flex-row md:tw-items-center md:tw-space-s-4 tw-space-y-2 md:tw-space-y-0">
                {footer.columns.map((column) => (
                  <div
                    className="tw-flex tw-items-center tw-space-s-1.5"
                    key={column.label}
                  >
                    <span>{`${column.label} `}</span>
                    <span
                      className="tw-text-[#3db9be]"
                      style={{ color: column.color }}
                    >
                      {column.value}
                    </span>
                    {column.rows?.length ? (
                      column.rows.map((row, index) => (
                        <div key={index}>
                          <span>{` ${row.label}`}</span>
                          <span
                            className="tw-text-[#3db9be]"
                            style={{ color: row.color }}
                          >{` ${row.value}`}</span>
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <></>
            )}
          </div>
          {footer.stars ? (
            <footer.stars />
          ) : footer.end ? (
            <footer.end />
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
