import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { getPublicCoursesList } from "../../../../Services/api/PublicCourseListProvider";
import { exportCertificate } from "../../../../Services/api/courses/courseProvider";
import NotFoundIcon from "../../../../assets/icons/not-found.png";
import CourseCard from "../../../Shared/Components/CourseCard/CourseCard";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import { BasicModal } from "../../../Shared/Components/Modal/BasicModal";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import CertificateModal from "../../../TrainingCourses/TrainingCourseDetails/TrainingCourseContent/CertificateModal";
import classes from "./publicCoursesList.module.css";

export default function PublicCoursesList({ type, status }) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const openExportCertificateModalRef = useRef();

  const [publicCoursesList, setPublicCoursesList] = useState([]);
  const [showExportCertificateModal, setShowExportCertificateModal] =
    useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isExportLoading, setIsExportLoading] = useState(false);

  useEffect(() => {
    getPublicCoursesList({ type, status })
      .then((res) => {
        if (res.status === 200 && res.data.status) {
          setPublicCoursesList(res.data[type]);
          setLoading(false);
        }
      })
      .catch((err) => {});
  }, []);

  const displayTitle = () => {
    if (type === "courses" && status === "Published") {
      return t("private_profile.current_courses");
    } else if (type === "courses" && status === "Closed") {
      return t("private_profile.courses_history");
    } else if (type === "programs" && status === "Published") {
      return t("private_profile.current_programs");
    } else if (type === "programs" && status === "Closed") {
      return t("private_profile.programs_history");
    }
  };

  const handleOpenExportCertificateModal = (id) => {
    setSelectedCourse(id);
    setShowExportCertificateModal(true);
    openExportCertificateModalRef.current.showModal();
  };

  const handleCloseExportCertificateModal = () => {
    setShowExportCertificateModal(false);
    openExportCertificateModalRef.current.dismissModal();
  };

  const exportCertificateApi = async ({ courseId, lang }) => {
    setIsExportLoading(true);
    try {
      await exportCertificate(courseId, lang);
    } catch (e) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    } finally {
      setIsExportLoading(false);
      handleCloseExportCertificateModal();
    }
  };

  return (
    <>
      {!loading ? (
        <div className="container-fluid tw-py-8" style={{ marginTop: "2rem" }}>
          <div className="row">
            {isExportLoading && <SkeletonCardOverlay skeletonWidth="100" />}
            <MainBox
              className="border-8"
              style={{ width: "100%", minHeight: "100vh" }}
            >
              <div className={classes["courses_card_header"]}>
                <h2>{displayTitle()}</h2>
              </div>
              <div className={classes["courses_card_grid"]}>
                {publicCoursesList.length > 0 ? (
                  publicCoursesList?.map((course) => (
                    <CourseCard
                      key={course.id}
                      levelbadge={
                        t("levels.title") + " " + Number(course?.level) === 1
                          ? t("levels.beginner")
                          : Number(course?.level) === 2
                          ? t("levels.medium")
                          : t("levels.professional")
                      }
                      mode={course.mode}
                      price={course.price}
                      img={course.img}
                      title={course.name}
                      seat={course.participators - course.number_of_users}
                      briefDesc={course.brief_description}
                      date={course.end_date.split(" ")[0]}
                      hour={course.number_hours}
                      handleSubmit={() => {
                        if (course.trainee_status === false) {
                          toast.error(
                            <span style={{ fontSize: 13, fontWeight: "bold" }}>
                              {type === "courses"
                                ? t("trainee_blocked_course")
                                : t("trainee_blocked_program")}
                            </span>
                          );
                          return;
                        } else if (course.is_started) {
                          history.push(
                            `/training-courses/${course.id}/content`
                          );
                        } else {
                          toast.error(
                            <span style={{ fontSize: 13, fontWeight: "bold" }}>
                              {course.is_program
                                ? t("program_not_started")
                                : t("course_not_started")}
                            </span>
                          );
                        }
                      }}
                      progressValue={course.progress_percentage}
                      watchValue={course.watch_percentage}
                      isCurrentCourses={true}
                      btn={
                        course.is_program
                          ? t("program_continue")
                          : t("course_continue")
                      }
                    />
                  ))
                ) : (
                  <div className={classes["no_courses"]}>
                    <div className={classes["no_courses_icon"]}>
                      <img src={NotFoundIcon} alt="not found" />
                    </div>
                    <h3>{t("private_profile.no_courses")}</h3>
                  </div>
                )}
              </div>
            </MainBox>
          </div>
          <BasicModal ref={openExportCertificateModalRef}>
            {showExportCertificateModal && (
              <CertificateModal
                handleExport={exportCertificateApi}
                handleCancel={handleCloseExportCertificateModal}
                selectedCourse={selectedCourse}
              />
            )}
          </BasicModal>
        </div>
      ) : (
        <SkeletonCardOverlay />
      )}
    </>
  );
}
