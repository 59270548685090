import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { exhibitionConstant } from "../../../../Redux/Constants/exhibition/exhibition.constant";
import country from "../../../../data/countryCodes.json";
import "./phoneInput.css";
import style from "./phoneInput.module.css";

export default function PhoneInput({
  type,
  placeholder,
  nameInput,
  nameSelect,
  selectValue,
  onChange,
  label,
  error,
  min,
  value,
  readonly = false,
}) {
  const [phoneCode, setPhoneCode] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    // TODO: CALL PHONE API
  }, []);
  return (
    <div className={style["input-field"]}>
      <label htmlFor={nameInput}>{label}</label>
      <div className={style["select-input"]}>
        <Select
          name={nameSelect}
          id={nameSelect}
          options={country.country}
          value={country.country.find((c) => c.dial_code == selectValue)}
          getOptionLabel={(c) => `${c.dial_code} ${c.emoji}`}
          getOptionValue={(c) => c.dial_code}
          onChange={(item) => {
            onChange({ target: { name: nameSelect, value: item.dial_code } });
            dispatch({
              type: exhibitionConstant.GET_PHONE_CODE,
              payload: item.dial_code,
            });
          }}
          className={"phone-select"}
          styles={{
            control: (base, state) => ({
              ...base,
              maxHeight: 50,
              boxShadow: "none",
              border: "none",
              backgroundColor: "#26b3b9",
              color: "#fff !important",
              width: "100%",
              height: 50,
            }),
          }}
          placeholder={label}
          isDisabled={readonly}
        />
        <input
          type={type}
          placeholder={placeholder}
          pattern="/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im"
          name={nameInput}
          onChange={onChange}
          min={min ? min : null}
          value={value}
          readOnly={readonly}
        />
      </div>

      {
        <p className={`form-input-error-space ${style["input-field-error"]}`}>
          {error && error}
        </p>
      }
    </div>
  );
}
