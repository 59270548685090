import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import Input from "../Shared/Components/Input/Input";
import CustomButton from "../Shared/Components/CustomButton/CustomButton";
import classes from "./ChatBubbleRegisteration.module.css";
import logo from "../../assets/image/Logo Tebx.png";

function ChatBubbleRegistration(props) {
  const { t } = useTranslation();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  function getDate() {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    return `${month}/${date}/${year}`;
  }
  const [currentDate, setCurrentDate] = useState(getDate());
  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <div className={classes["chat-login-container"]}>
        <div className={classes["content-container"]}>
          <img className={classes["admin_img"]} src={logo} alt="" />
          <div>
            <label className={classes.date}>{currentDate}</label>
            <label className={classes.label}>{t("chat.loginLabel")}</label>
            <Formik
              initialValues={{
                username: "",
                email: "",
              }}
              onSubmit={async (values, { setSubmitting, setErrors }) => {
                let errorObj = {};
                try {
                  localStorage.setItem("chat_username", values.username);
                  localStorage.setItem("chat_email", values.email);
                  props.setShowChatAfterReg(true);
                  props.setIsStart(true);
                  setSubmitting(false);
                } catch (err) {
                  if (err.response.data && err.response.data.msg) {
                    errorObj.old_password = err.response.data.msg;
                  }
                  if (Object.keys(errorObj).length !== 0) {
                    setErrors(errorObj);
                  }
                }
              }}
              validateOnChange={hasSubmitted}
              validate={(values) => {
                setHasSubmitted(true);
                const errors = {};
                if (!values.username) {
                  errors.username = t("crud.errors.required");
                }
                if (!values.email) {
                  errors.email = t("crud.errors.required");
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                    values.email
                  )
                ) {
                  errors.email = "Invalid email address";
                }

                return errors;
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit} className="form">
                  <div
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    <Input
                      label={t("chat.chat_register.username")}
                      type="text"
                      name="username"
                      placeholder={t("chat.chat_register.username")}
                      onChange={handleChange}
                      error={errors.username}
                    />
                  </div>
                  <div
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    <Input
                      label={t("chat.chat_register.email")}
                      type="email"
                      name="email"
                      placeholder={t("chat.chat_register.email")}
                      onChange={handleChange}
                      error={errors.email}
                    />
                  </div>

                  <div className={classes.btn}>
                    <CustomButton
                      value={t("chat.chat_register.start_chat")}
                      colors={"#036c77"}
                      type={"button"}
                      iconClass={"mt-2"}
                      disable={isSubmitting}
                      action={handleSubmit}
                      classes={"button-width"}
                    />
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatBubbleRegistration;
