import connector from "../../../Services/api/Handler";
import URLBuilder from "../../../Services/api/UrlBuilder";
import { trainerProfileConstant } from "../../Constants/trainerProfile/trainerProfile.constant";
import env from "../../../Services/api/Environment";
import { toast } from "react-toastify";

export function getClassification() {
  return (dispatch) => {
    dispatch({
      type: trainerProfileConstant.START_LOADING,
    });

    connector
      .get(env.get_classification)
      .then((res) => {
        dispatch({
          type: trainerProfileConstant.GET_CLASSIFICATION,
          payload: res,
        });
        dispatch({
          type: trainerProfileConstant.STOP_LOADING,
        });
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.message}
          </span>
        );
        dispatch({
          type: trainerProfileConstant.STOP_LOADING,
        });
      });
  };
}

export function getTrainer() {
  return (dispatch) => {
    dispatch({
      type: trainerProfileConstant.START_LOADING,
    });

    connector
      .get(env.get_trainer)
      .then((res) => {
        dispatch({
          type: trainerProfileConstant.GET_TRAINER,
          payload: res.data,
        });
        dispatch({
          type: trainerProfileConstant.STOP_LOADING,
        });
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.message}
          </span>
        );
        dispatch({
          type: trainerProfileConstant.STOP_LOADING,
        });
      });
  };
}

export function getTrainerById(params) {
  return (dispatch) => {
    connector
      .get(env.get_trainer, {
        params: params,
      })
      .then((res) => {
        dispatch({
          type: trainerProfileConstant.GET_TRAINER,
          payload: res,
        });
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.message || err.response.data.msg}
          </span>
        );
        dispatch({
          type: trainerProfileConstant.STOP_LOADING,
        });
      });
  };
}

export function getTrainerDetailsById(id) {
  return (dispatch) => {
    dispatch({
      type: trainerProfileConstant.START_LOADING,
    });
    connector
      .get(URLBuilder(env.get_trainer_info, { id: id }))
      .then((res) => {
        dispatch({
          type: trainerProfileConstant.GET_TRAINER_DETAILS,
          payload: res,
        });
        dispatch({
          type: trainerProfileConstant.STOP_LOADING,
        });
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.message || err.response.data.msg}
          </span>
        );
        dispatch({
          type: trainerProfileConstant.STOP_LOADING,
        });
      });
  };
}

export function getCategoryById(id) {
  return (dispatch) => {
    connector
      .get(URLBuilder(env.get_trainer_category, { id: id }))
      .then((res) => {
        dispatch({
          type: trainerProfileConstant.GET_CATEGORY,
          payload: res,
        });
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.message || err.response.data.msg}
          </span>
        );
        dispatch({
          type: trainerProfileConstant.STOP_LOADING,
        });
      });
  };
}

export function getTrainerCourse(id, params) {
  return (dispatch) => {
    connector
      .get(URLBuilder(env.get_trainer_course, { id: id }), {
        params: params,
      })
      .then((res) => {
        dispatch({
          type: trainerProfileConstant.GET_TRAINER_COURSE,
          payload: res,
        });
        dispatch({
          type: trainerProfileConstant.STOP_LOADING,
        });
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.message || err.response.data.msg}
          </span>
        );
        dispatch({
          type: trainerProfileConstant.STOP_LOADING,
        });
      });
  };
}

export const resetTrainer = () => {
  return (dispatch) => {
    dispatch({ type: trainerProfileConstant.RESET_TRAINER });
  };
};
