import { exhibitionConstant } from "../../Constants/exhibition/exhibition.constant";

const initialState = {
  approvePaymentByBoothLoading: false,
  approvePaymentByBoothError: null,
  approvePaymentByBooth: false,
  approvePaymentByBoothMsg: null,

  rejectPaymentByBoothLoading: false,
  rejectPaymentByBoothError: null,
  rejectPaymentByBooth: false,
  rejectPaymentByBoothMsg: null,

  approvePaymentByVisitorLoading: false,
  approvePaymentByVisitorError: null,
  approvePaymentByVisitor: false,
  approvePaymentByVisitorMsg: null,

  rejectPaymentByVisitorLoading: false,
  rejectPaymentByVisitorError: null,
  rejectPaymentByVisitor: false,
  rejectPaymentByVisitorMsg: null,
};

const exhibitionPaymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case exhibitionConstant.APPROVE_EXHIBITION_PAYMENT_BY_BOOTH_REQUEST:
      return {
        ...state,
        approvePaymentByBoothLoading: true,
        approvePaymentByBooth: false,
        approvePaymentByBoothMsg: null,
      };

    case exhibitionConstant.APPROVE_EXHIBITION_PAYMENT_BY_BOOTH_SUCCESS:
      return {
        ...state,
        approvePaymentByBoothLoading: false,
        approvePaymentByBooth: true,
        approvePaymentByBoothMsg: action.payload,
      };

    case exhibitionConstant.APPROVE_EXHIBITION_PAYMENT_BY_BOOTH_REJECTED:
      return {
        ...state,
        approvePaymentByBoothLoading: false,
        approvePaymentByBoothError: action.payload,
      };

    case exhibitionConstant.REJECT_EXHIBITION_PAYMENT_BY_BOOTH_REQUEST:
      return {
        ...state,
        rejectPaymentByBoothLoading: true,
        rejectPaymentByBooth: false,
        rejectPaymentByBoothMsg: null,
      };

    case exhibitionConstant.REJECT_EXHIBITION_PAYMENT_BY_BOOTH_SUCCESS:
      return {
        ...state,
        rejectPaymentByBoothLoading: false,
        rejectPaymentByBooth: true,
        rejectPaymentByBoothMsg: action.payload,
      };

    case exhibitionConstant.REJECT_EXHIBITION_PAYMENT_BY_BOOTH_REJECTED:
      return {
        ...state,
        rejectPaymentByBoothLoading: false,
        rejectPaymentByBoothError: action.payload,
      };

    case exhibitionConstant.APPROVE_EXHIBITION_PAYMENT_BY_VISITOR_REQUEST:
      return {
        ...state,
        approvePaymentByVisitorLoading: true,
        approvePaymentByVisitor: false,
        approvePaymentByVisitorMsg: null,
      };

    case exhibitionConstant.APPROVE_EXHIBITION_PAYMENT_BY_VISITOR_SUCCESS:
      return {
        ...state,
        approvePaymentByVisitorLoading: false,
        approvePaymentByVisitor: true,
        approvePaymentByVisitorMsg: action.payload.msg,
      };

    case exhibitionConstant.APPROVE_EXHIBITION_PAYMENT_BY_VISITOR_REJECTED:
      return {
        ...state,
        approvePaymentByVisitorLoading: false,
        approvePaymentByVisitorError: action.payload,
      };

    case exhibitionConstant.REJECT_EXHIBITION_PAYMENT_BY_VISITOR_REQUEST:
      return {
        ...state,
        rejectPaymentByVisitorLoading: true,
        rejectPaymentByVisitor: false,
        rejectPaymentByVisitorMsg: null,
      };

    case exhibitionConstant.REJECT_EXHIBITION_PAYMENT_BY_VISITOR_SUCCESS:
      return {
        ...state,
        rejectPaymentByVisitorLoading: false,
        rejectPaymentByVisitor: true,
        rejectPaymentByVisitorMsg: action.payload,
      };

    case exhibitionConstant.REJECT_EXHIBITION_PAYMENT_BY_VISITOR_REJECTED:
      return {
        ...state,
        rejectPaymentByVisitorLoading: false,
        rejectPaymentByVisitorError: action.payload,
      };

    default:
      return state;
  }
};

export default exhibitionPaymentReducer;
