import { useTranslation } from "react-i18next";
import FilterBox from "../../Shared/Components/FilterBox/FilterBox";

const TraineeCertificatesFilter = ({
  filterData,
  changeHandler,
  resetHandler,
  currentPage,
  setCurrentPage,
  filterHandler,
  resetFilterHandler,
}) => {
  const { t, i18n } = useTranslation();

  const filterFields = [
    {
      id: "user_name",
      component: "input",
      label: t("department_trainees.trainee_name"),
      type: "text",
      placeholder: t("department_trainees.trainee_name"),
      name: "user_name",
      value: filterData.user_name,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    // user_email
    {
      id: "user_email",
      component: "input",
      label: t("department_trainees.trainee_email"),
      type: "text",
      placeholder: t("department_trainees.trainee_email"),
      name: "user_email",
      value: filterData.user_email,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "reset",
      component: "button",
      type: "button",
      name: "action",
      value: t("general.reset"),
      classes: "col-12 col-md-6 col-xl-2 border-button",
      action: () => {
        resetFilterHandler();
      },
      backgroundColor: "transparent",
      color: "#046c77",
      borderColor: "#046c77",
    },
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("general.search"),
      classes: "col-12 col-md-6  col-xl-2",
      action: () => {
        if (currentPage != 1) {
          setCurrentPage(1);
        } else {
          filterHandler();
        }
      },
    },
  ];

  return <FilterBox fields={filterFields} />;
};

export default TraineeCertificatesFilter;
