import { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { AuthContext } from "../../../Services/api/auth/AuthContext";
import {
  getUserData,
  socialSignIn,
} from "../../../Services/api/auth/AuthProvider";
import facebook from "../../../assets/icons/facebook.svg";
import gmail from "../../../assets/icons/google.svg";
import linkedin from "../../../assets/icons/linkedin.svg";
import twitter from "../../../assets/icons/twitter.svg";
import { BasicModal } from "../../Shared/Components/Modal/BasicModal";
import Sso from "../SSO/Sso";

function Social() {
  require("./social.css");
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const history = useHistory();

  const responseSocialLogin = async () => {
    const urlSearchParams = new URLSearchParams(
      window.location.search
        .replaceAll("%3D", "=")
        .replaceAll("%26", "&")
        .replaceAll(("%7C", "|"))
    );
    if (urlSearchParams.has("token")) {
      localStorage.setItem("token", urlSearchParams.get("token"));
      localStorage.setItem("tokenChat", urlSearchParams.get("token"));
      localStorage.setItem("email", urlSearchParams.get("email"));
      localStorage.setItem("img", urlSearchParams.get("img"));
      let response = await getUserData();
      let roles = [];
      response.data.user.roles.forEach((item) => {
        roles.push(item.name);
      });
      localStorage.setItem("roles", JSON.stringify(roles));
      localStorage.setItem("type", response.data.user.roles[0].name);
      localStorage.setItem("type_login", "social");
      localStorage.setItem("name_ar", response.data.user.name_ar);
      localStorage.setItem("name_en", response.data.user.name_en);
      localStorage.setItem("phone", response.data.user.phone);
      localStorage.setItem("userId", response.data.user.id);
      localStorage.setItem("is_admin", response.data.user.is_admin);
      authContext.setRoles(JSON.stringify(roles));
      authContext.setAuth({ token: urlSearchParams.get("token") });
      authContext.setIsPerformingAuthenticationOperation(false);
      const is_completed = urlSearchParams.get("is_completed");
      if (Number(is_completed) === 1) history.push("/");
      else
        window.location.href =
          process.env.REACT_APP_FRONT_BASE_URL + "/edit-profile";
    }

    if (urlSearchParams.has("status")) {
      if (urlSearchParams.get("status") === "inactive") {
        history.push("/login", { accountStatus: "inactive" });
      }
    }
  };

  useEffect(() => {
    responseSocialLogin();
  }, []);

  // const feedAuthEndpointWithSocialResponse = async (payload) => {
  //   try {
  //     let authenticationResponse = await socialAuth(payload);
  //     let roles = [];
  //     authenticationResponse.data.user.roles.forEach((item) => {
  //       roles.push(item.name);
  //     });
  //     localStorage.setItem("roles", JSON.stringify(roles));
  //     localStorage.setItem("token", authenticationResponse.data.user.accessToken);
  //     localStorage.setItem("name", authenticationResponse.data.user.name);
  //     localStorage.setItem("email", authenticationResponse.data.user.email);
  //     localStorage.setItem("img", authenticationResponse.data.user.img);
  //     localStorage.setItem("is_admin", authenticationResponse.data.user.is_admin);
  //     let is_completed = authenticationResponse.data.is_completed;
  //     authContext.setAuth({
  //       token: authenticationResponse.data.user.accessToken,
  //     });
  //     authContext.setRoles(JSON.stringify(roles));
  //     if (authenticationResponse.data.user.is_admin === true) {
  //       history.push("/admin");
  //       window.location.reload();
  //     } else {
  //       if (is_completed == 1) {
  //         history.push("/");
  //         window.location.reload();
  //       } else {
  //         history.push("/edit-profile");
  //         window.location.reload();
  //       }
  //     }
  //   } catch (err) {
  //     toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>err</span>);
  //   }
  // };

  const onClickSocial = async (e, type) => {
    if (type === "twitter") {
      window.open(`${process.env.REACT_APP_SERVER_PATH}auth/twitter`, "_self");
    } else {
      let response = await socialSignIn(type);
      window.open(response.data, "_self");
    }
  };

  const ssoModalRef = useRef();

  return (
    <div className="auth_card_social_container">
      <BasicModal ref={ssoModalRef}>
        <Sso ssoModalRef={ssoModalRef} />
      </BasicModal>
      <div className="auth_card_social">
        <p className="auth_social_title">{t("LogInWith")}</p>
        <div className="auht_social_login ">
          <div
            className="auth_linkedin"
            onClick={(e) => onClickSocial(e, "linkedin-openid")}
          >
            <img src={linkedin} alt="" className="icon_soical" />
          </div>
          {/* <div className="auth_facebook" onClick={(e) => onClickSocial(e, "facebook")}>
            <img src={facebook} alt="" className="icon_soical" />
          </div> */}
          <div
            className="auth_twitter"
            onClick={(e) => onClickSocial(e, "twitter")}
          >
            <img src={twitter} alt="" className="icon_soical" />
          </div>
          <div
            className="auth_gmail"
            onClick={(e) => onClickSocial(e, "google")}
          >
            <img src={gmail} alt="" className="icon_soical" />
          </div>
        </div>
        {window.location.pathname === "/login" ? (
          <Link to="/register" className="auth_social_label">
            {t("DontHaveAnAccount")}{" "}
            <span style={{ color: "#126d77" }}>{t("register")}</span>
          </Link>
        ) : (
          <Link to="/login" className="auth_social_label">
            {t("AlreadyHaveAnAccountLogin")}{" "}
            <span style={{ color: "#126d77" }}>{t("login")}</span>
          </Link>
        )}
      </div>
    </div>
  );
}

export default Social;
