import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, withRouter } from "react-router-dom";
import { AuthContext } from "../../../../../Services/api/auth/AuthContext";
import notification from "../../../../../assets/image/notification.png";
import profileAvatar from "../../../../../assets/image/profile-avatar.png";
import ar from "../../../../../assets/image/saudi-arabia.png";
import en from "../../../../../assets/image/united-kingdom.png";
import i18n from "../../../../../i18n/i18n";
import { Lang } from "../../../../../utils";
import Notification from "../../../../Admin/adminHeader/Notification";
import DropDown from "../../dorpdown/Dropdown";
import Arrow from "../../../../../assets/icons/ArrowDownGreen.svg";
import { useHistory } from "react-router-dom";
import UserDropDown from "./UserDropDwon/UserDropDown";

function DynamicHeaderWithOutLogin(props) {
  require("./DynamicHeaderWithoutLogin.css");
  const authContext = useContext(AuthContext);
  const history = useHistory();
  const { t } = useTranslation();
  document.body.dir = i18n.dir();
  const type_login = localStorage.getItem("type_login");

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("tokenChat");
    localStorage.removeItem("name");
    localStorage.removeItem("email");
    localStorage.removeItem("is_admin");
    localStorage.removeItem("roles");
    localStorage.removeItem("name_ar");
    localStorage.removeItem("name_en");
    localStorage.removeItem("img");
    localStorage.removeItem("phone");
    localStorage.removeItem("type_login");
    localStorage.removeItem("chatId");
    localStorage.removeItem("userId");
    localStorage.removeItem("chat_username");
    localStorage.removeItem("chat_email");
    localStorage.removeItem("type");
    localStorage.removeItem("completed_profile");

    authContext.setAuth({});
    authContext.setRoles([]);
    props.history.push("/");
    window.location.reload();
  };

  const changeLanguage = (lng) => {
    localStorage.setItem("i18nextLng", lng);
    window.location.reload();
  };
  const languageOptions = [
    {
      label: "English",
      onClick: () => changeLanguage("en"),
    },
    {
      label: "العربية",
      onClick: () => changeLanguage("ar"),
    },
  ];
  let buttonLang = <div onClick={() => changeLanguage("ar")}>AR</div>;
  if (document.body.dir === "rtl") {
    buttonLang = (
      <div className="lang_style">
        <DropDown options={languageOptions} isLanguage={true} />
      </div>
    );
  } else {
    buttonLang = (
      <div className="lang_style">
        <DropDown options={languageOptions} isLanguage={true} />
      </div>
    );
  }

  const userOptions = [
    ...(authContext.roles.includes("admin")
      ? [
          {
            label: t("admin.label.dashboard"),
            onClick: () => history.push("/admin"),
          },
        ]
      : authContext.roles.includes("accountant")
      ? [
          {
            label: t("admin.label.dashboard"),
            onClick: () => history.push("/accountant"),
          },
        ]
      : []),

    {
      label: t("edit_personal_profile"),
      onClick: () => history.push("/edit-profile"),
    },
    {
      label: t("account_info"),
      onClick: () => history.push("/private-profile"),
    },
    {
      label: t("calendar"),
      onClick: () => history.push("/calendar"),
    },

    {
      label: t("admin.label.list_tickets"),
      onClick: () =>
        history.push(
          `${
            localStorage.getItem("type") === "admin"
              ? "/admin/tickets"
              : "/tickets"
          }`
        ),
    },
    ...(props.chatAllowed
      ? [
          {
            label: t("sidebar.label.live_chat"),
            onClick: () => history.push("/livechat"),
          },
        ]
      : []),
    ...(type_login === "normal"
      ? [
          {
            label: t("accountde"),
            onClick: () => history.push("/update-password"),
          },
        ]
      : []),
    {
      label: t("logout"),
      onClick: logout,
    },
  ];

  const loginNav = (
    <>
      <Notification NotAdmin={props.NotAdmin} />
      <UserDropDown options={userOptions} />
    </>
  );

  return (
    <div className="header_dinamic">
      {buttonLang}
      {loginNav}
    </div>
  );
}

export default withRouter(DynamicHeaderWithOutLogin);

// (<div className="dropdown user-dropdown">
//   <button
//     type="button"
//     className="btn dropdown-toggle header-dropdown header_nav_link"
//     style={{
//       color: "#fff",
//       display: "flex",
//       alignItems: "center",
//       gap: 5,
//       boxShadow: "none",
//     }}
//     data-toggle="dropdown"
//   >
//     {localStorage.getItem("img") == "null" ? (
//       <img src={profileAvatar} className="admin_img" />
//     ) : (
//       <img src={localStorage.getItem("img")} alt="" className="admin_img" />
//     )}
//     <p style={{ color: "#006d77" }}>
//       {i18n.language === Lang.AR
//         ? localStorage.getItem("name_ar") !== null
//           ? localStorage.getItem("name_ar").split(" ")[0]
//           : null
//         : localStorage.getItem("name_en") != null
//         ? localStorage.getItem("name_en").split(" ")[0]
//         : null}
//     </p>
//     <img src={Arrow} alt="" style={{ width: 8, height: 8, marginTop: 4 }} />
//   </button>
//   <div className="dropdown-menu">
//     {/* todo: */}
//     {authContext.roles.includes("admin") ? (
//       <NavLink className="dropdown-item" to="/admin">
//         {t("admin.label.dashboard")}
//       </NavLink>
//     ) : authContext.roles.includes("accountant") ? (
//       <NavLink className="dropdown-item" to="/accountant">
//         {t("admin.label.dashboard")}
//       </NavLink>
//     ) : null}
//     <NavLink className="dropdown-item" to="/edit-profile">
//       {t("edit_personal_profile")}
//     </NavLink>
//     <NavLink className="dropdown-item" to="/private-profile">
//       {t("account_info")}
//     </NavLink>
//     <NavLink className="dropdown-item" to="/calendar">
//       {t("calendar")}
//     </NavLink>
//     {type_login === "normal" ? (
//       <NavLink className="dropdown-item" to="/update-password">
//         {t("accountde")}
//       </NavLink>
//     ) : null}
//     <NavLink
//       className="dropdown-item"
//       to={
//         localStorage.getItem("type") === "admin" ? "/admin/tickets" : "/tickets"
//       }
//     >
//       {t("admin.label.list_tickets")}
//     </NavLink>
//     <button className="dropdown-item" type="submit" onClick={logout}>
//       {t("logout")}
//     </button>
//   </div>
// </div>;)
