import env from "../Environment";
import connector from "../Handler";
import URLBuilder from "../UrlBuilder";


export function getFaqcourse(id,params) {      
    const myPromise = new Promise((resolve, reject) => {
        try {
            connector
            .get(URLBuilder(env.FAQ_course,{id:id}),{ 
                params: params
            })
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
        } catch (error) {
            reject(error);
        }
    });

    return myPromise;
}

export function addFaqCourse(id,payload) {      
    const myPromise = new Promise((resolve, reject) => {
        try {
            connector
            .post(URLBuilder(env.FAQ_course,{id:id}),payload)
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
        } catch (error) {
            reject(error);
        }
    });

    return myPromise;
}