import { certificatesTemplateConstants } from "../../Constants/certificates/certificates-template.constants";

const initialState = {
  certificatesList: [],
  certificatesMeta: {},
  selectedCertificate: null,
  certificatesListLoading: false,
  certificatesListError: null,
  certificatesListSuccess: false,

  updateCertificatesTemplateStatusLoading: false,
  updateCertificatesTemplateStatusError: null,
  updateCertificatesTemplateStatusSuccess: false,

  deleteCertificatesTemplateLoading: false,
  deleteCertificatesTemplateError: null,
  deleteCertificatesTemplateSuccess: false,

  assignCertificatesTemplateLoading: false,
  assignCertificatesTemplateError: null,
  assignCertificatesTemplateSuccess: false,

};


const certificatesTemplateReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET CERTIFICATES TEMPLATES LIST
    case certificatesTemplateConstants.GET_CERTIFICATES_TEMPLATES_LIST_REQUEST:
      return {
        ...state,
        certificatesListLoading: true,
        certificatesListError: null,
        certificatesListSuccess: false,
      };
    case certificatesTemplateConstants.GET_CERTIFICATES_TEMPLATES_LIST_SUCCESS:
      return {
        ...state,
        certificatesListLoading: false,
        certificatesListError: null,
        certificatesListSuccess: true,
        certificatesList: action.payload.list,
        certificatesMeta: action.payload.meta,
        selectedCertificate: action.payload.selectedCertificate,
      };
    case certificatesTemplateConstants.GET_CERTIFICATES_TEMPLATES_LIST_REJECTED:
      return {
        ...state,
        certificatesListLoading: false,
        certificatesListError: action.payload,
        certificatesListSuccess: false,
      };
    
    // UPDATE CERTIFICATES TEMPLATES STATUS
    case certificatesTemplateConstants.UPDATE_CERTIFICATES_TEMPLATES_STATUS_REQUEST:
      return {
        ...state,
        updateCertificatesTemplateStatusLoading: true,
        updateCertificatesTemplateStatusError: null,
        updateCertificatesTemplateStatusSuccess: false,
      };

    case certificatesTemplateConstants.UPDATE_CERTIFICATES_TEMPLATES_STATUS_SUCCESS:
      return {
        ...state,
        updateCertificatesTemplateStatusLoading: false,
        updateCertificatesTemplateStatusError: null,
        updateCertificatesTemplateStatusSuccess: true,
        certificatesList: state.certificatesList.map((certificate) => {
          if (certificate.id === action.payload.id) {
            return {
              ...certificate,
              status: !certificate.status,
            };
          }
          return certificate;
        }),
      };
    
    case certificatesTemplateConstants.UPDATE_CERTIFICATES_TEMPLATES_STATUS_REJECTED:
      return {
        ...state,
        updateCertificatesTemplateStatusLoading: false,
        updateCertificatesTemplateStatusError: action.payload,
        updateCertificatesTemplateStatusSuccess: false,
      };
    // Delete Certificate Template
    case certificatesTemplateConstants.DELETE_CERTIFICATES_TEMPLATE_REQUEST:
      return {
        ...state,
        deleteCertificatesTemplateLoading: true,
        deleteCertificatesTemplateError: null,
        deleteCertificatesTemplateSuccess: false,
      };

    case certificatesTemplateConstants.DELETE_CERTIFICATES_TEMPLATE_SUCCESS:
      return {
        ...state,
        deleteCertificatesTemplateLoading: false,
        deleteCertificatesTemplateError: null,
        deleteCertificatesTemplateSuccess: true,
        certificatesList: state.certificatesList.filter(
          (certificate) => certificate.id !== action.payload.id
        ),
      };

    case certificatesTemplateConstants.DELETE_CERTIFICATES_TEMPLATE_REJECTED:
      return {
        ...state,
        deleteCertificatesTemplateLoading: false,
        deleteCertificatesTemplateError: action.payload,
        deleteCertificatesTemplateSuccess: false,
      };

    // ASSIGN CERTIFICATE Template
    case certificatesTemplateConstants.ASSIGN_CERTIFICATES_TEMPLATE_REQUEST:
      return {
        ...state,
        assignCertificatesTemplateLoading: true,
        assignCertificatesTemplateError: null,
        assignCertificatesTemplateSuccess: false,
      };
    case certificatesTemplateConstants.ASSIGN_CERTIFICATES_TEMPLATE_SUCCESS:
      return {
        ...state,
        assignCertificatesTemplateLoading: false,
        assignCertificatesTemplateError: null,
        assignCertificatesTemplateSuccess: true,
        selectedCertificate: action.payload.id
      };
    case certificatesTemplateConstants.ASSIGN_CERTIFICATES_TEMPLATE_REJECTED:
      return {
        ...state,
        assignCertificatesTemplateLoading: false,
        assignCertificatesTemplateError: action.payload,
        assignCertificatesTemplateSuccess: false,
      };

    // UNASSIGN CERTIFICATE Template
    case certificatesTemplateConstants.UNASSIGN_CERTIFICATES_TEMPLATE_REQUEST:
      return {
        ...state,
        assignCertificatesTemplateLoading: true,
        assignCertificatesTemplateError: null,
        assignCertificatesTemplateSuccess: false,
      };
    case certificatesTemplateConstants.UNASSIGN_CERTIFICATES_TEMPLATE_SUCCESS:
      return {
        ...state,
        assignCertificatesTemplateLoading: false,
        assignCertificatesTemplateError: null,
        assignCertificatesTemplateSuccess: true,
        selectedCertificate: null
      };
    case certificatesTemplateConstants.UNASSIGN_CERTIFICATES_TEMPLATE_REJECTED:
      return {
        ...state,
        assignCertificatesTemplateLoading: false,
        assignCertificatesTemplateError: action.payload,
        assignCertificatesTemplateSuccess: false,
      };

      default:
      return state;
  }

};

export default certificatesTemplateReducer;