import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { Hint } from "../../../../Components";
import {
  addCourse,
  listCourseCategories,
  listCourseDepartments,
} from "../../../../Services/api/courses/courseProvider";
import ear from "../../../../assets/image/eardoc.png";
import browsePhoto from "../../../../assets/image/photo.png";
import i18n from "../../../../i18n/i18n";
import { Lang } from "../../../../utils";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";

function AddCourse() {
  require("./add.css");
  const { t } = useTranslation();
  const history = useHistory();
  const inputFile = useRef(null);

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [hasFetchedCategories, setHasFetchedCategories] = useState(false);
  const [hasFetchedDepartments, setHasFetchedDepartments] = useState(false);
  const [categories, setCategories] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [toBeShownImage, setToBeShownImage] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  setTimeout(() => {
    setIsLoading(false);
  }, 500);

  useEffect(() => {
    listCourseCategories()
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setHasFetchedCategories(true);
          setCategories(res.data.data);
        }
      })
      .catch((err) => {
        
      });

    listCourseDepartments()
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setHasFetchedDepartments(true);
          setDepartments(res.data.data);
        }
      })
      .catch((err) => {
        
      });
  }, []);

  const formatDate = (d) => {
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const formatTime = (t) => {
    let hours = "" + t.getHours(),
      minutes = "" + t.getMinutes(),
      seconds = "" + t.getSeconds();

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return [hours, minutes, seconds].join(":");
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row bg-container">
          <div className="col-12 bg-container">
            <div className="training_first_label mt-4">
              <NavLink to="/">{t("Home")}</NavLink>
              <p style={{ padding: "0 5px" }}>/</p>
              {t("createneewcourse")}
            </div>
            {isLoading ? (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "70%",
                    height: "100vh",
                  }}
                >
                  <SkeletonCard />
                </div>
              </div>
            ) : (
              <>
                <div
                  className="personal_info_label"
                  style={{
                    margin: "10px 100px",
                  }}
                >
                  <div className="personal_info_top">{t("createneewcourse")}</div>
                  <div className="personal_info_bottom">{t("aftercreateing")}</div>
                </div>
                <div className="personal_info_card_st">
                  <div className="personal_info_card">
                    <Formik
                      initialValues={{
                        name: "",
                        description: "",
                        code: "",
                        section_id: "",
                        category_id: "",
                        image: "",
                        start_date: "",
                        end_date: "",
                        start_register_date: "",
                        end_register_date: "",
                        number_hours: "",
                      }}
                      onSubmit={async (values, { setSubmitting }) => {
                        try {
                          let dataToBeUploaded = {
                            ...values,
                          };
                          let formData = new FormData();
                          dataToBeUploaded.start_date =
                            formatDate(values.start_date) + " " + formatTime(values.start_date);
                          dataToBeUploaded.end_date = formatDate(values.end_date) + " " + formatTime(values.end_date);
                          dataToBeUploaded.start_register_date =
                            formatDate(values.start_register_date) + " " + formatTime(values.start_register_date);
                          dataToBeUploaded.end_register_date =
                            formatDate(values.end_register_date) + " " + formatTime(values.end_register_date);
                          Object.keys(dataToBeUploaded).forEach((field) =>
                            formData.append(field, dataToBeUploaded[field])
                          );
                          let response = await addCourse(formData);
                          if (response.status === 201 && response.data.status) {
                            history.push("/private-profile");
                          } else {
                            alert("Failure");
                          }
                          setSubmitting(false);
                        } catch (err) {
                          if (
                            err.response.data &&
                            err.response.data.errors != null &&
                            typeof err.response.data.errors === "object" &&
                            !Array.isArray(err.response.data.errors)
                          ) {
                            const issues = [];
                            Object.keys(err.response.data.errors).forEach((key) => {
                              issues.push(
                                Array.isArray(err.response.data.errors[key]) ? err.response.data.errors[key][0] : ""
                              );
                            });
                            toast.error(
                              <>
                                <span style={{ fontSize: 14 }}>{err.response.data.msg}:</span>
                                <ul>
                                  {issues.map((item, index) => {
                                    return (
                                      <li key={index}>
                                        <span style={{ fontSize: 12 }}>{item}</span>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </>
                            );
                          }
                        }
                      }}
                      validateOnChange={hasSubmitted}
                      validate={(values) => {
                        setHasSubmitted(true);
                        const errors = {};
                        if (!values.name) {
                          errors.name = t("crud.errors.required");
                        }

                        if (!values.image) {
                          errors.image = t("crud.errors.required");
                        }

                        if (!values.description) {
                          errors.description = t("crud.errors.required");
                        }

                        if (!values.code) {
                          errors.code = t("crud.errors.required");
                        }

                        if (!values.number_hours) {
                          errors.number_hours = t("crud.errors.required");
                        }

                        if (!values.section_id) {
                          errors.section_id = t("crud.errors.required");
                        }

                        if (!values.category_id) {
                          errors.category_id = t("crud.errors.required");
                        }

                        if (!values.start_date) {
                          errors.start_date = t("crud.errors.required");
                        }

                        if (!values.end_date) {
                          errors.end_date = t("crud.errors.required");
                        }

                        if (!values.start_register_date) {
                          errors.start_register_date = t("crud.errors.required");
                        }

                        if (!values.end_register_date) {
                          errors.end_register_date = t("crud.errors.required");
                        }
                        return errors;
                      }}
                    >
                      {({
                        setFieldValue,
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                      }) => (
                        <>
                          <div className="perosnal_text_img">{t("coursephoto")}</div>
                          <div className="course_img">
                            <div className="">
                              <div className="">
                                <div className="">
                                  <img src={toBeShownImage ? toBeShownImage : ear} alt="" className="course_dimage" />
                                </div>
                                <div
                                  className="course_browse"
                                  onClick={() => {
                                    if (inputFile) {
                                      inputFile.current.click();
                                    }
                                  }}
                                >
                                  <img src={browsePhoto} alt="" className="personal_browse_img" />
                                </div>
                              </div>
                            </div>
                            <div className="personal_img_text">{t("choosepng")}</div>
                          </div>
                          <p className={"form-input-error-space"}>{errors.image ? errors.image : null}</p>
                          <input
                            id="file"
                            name="file"
                            type="file"
                            ref={inputFile}
                            style={{ display: "none" }}
                            onChange={(event) => {
                              let file = event.target.files[0];
                              if (file.name.match(/\.(jpg|jpeg|png|gif)$/i)) {
                                let reader = new FileReader();
                                reader.readAsDataURL(file);
                                reader.onload = () => {
                                  setFieldValue("image", event.target.files[0]);
                                  setToBeShownImage(reader.result);
                                };
                                reader.onerror = function (error) {
                                  
                                };
                              } else {
                                toast.error(<span style={{ fontSize: 14 }}>صيغة الملف خطأ</span>);
                              }
                            }}
                          />
                          <div className={"personal_form"}>
                            <form onSubmit={handleSubmit} className="admin_add_form">
                              <div>
                                <label htmlFor="name" className="personal_label">
                                  {t("crud.placeholders.name")}
                                </label>
                                <input
                                  id="name"
                                  type="text"
                                  name="name"
                                  maxLength={100}
                                  className="personal_input_in"
                                  onChange={handleChange}
                                  placeholder={t("crud.placeholders.name")}
                                  value={values.name}
                                />
                                <Hint
                                  hint={t("hint.course.addCourse.length_name_course")}
                                  color="#03292B"
                                  fontSize={12}
                                />
                                <p className={"form-input-error-space"}>{errors.name ? errors.name : null}</p>
                              </div>

                              <div>
                                <label htmlFor="description" className="personal_label">
                                  {t("crud.placeholders.description")}
                                </label>
                                <input
                                  id="description"
                                  type="text"
                                  name="description"
                                  className="personal_input_in"
                                  onChange={handleChange}
                                  placeholder={t("crud.placeholders.description")}
                                  value={values.description}
                                />
                                <Hint hint={t("hint.course.addCourse.describe")} color="#03292B" fontSize={12} />
                                <p className={"form-input-error-space"}>
                                  {errors.description ? errors.description : null}
                                </p>
                              </div>

                              <div>
                                <label htmlFor="code" className="personal_label">
                                  {t("crud.placeholders.code")}
                                </label>
                                <input
                                  id="code"
                                  type="text"
                                  name="code"
                                  className="personal_input_in"
                                  onChange={handleChange}
                                  placeholder={t("crud.placeholders.code")}
                                  value={values.code}
                                />
                                <Hint hint={t("hint.course.addCourse.code")} color="#03292B" fontSize={12} />
                                <p className={"form-input-error-space"}>{errors.code ? errors.code : null}</p>
                              </div>
                              <div>
                                <label htmlFor="number_hours" className="personal_label">
                                  {t("numberHours") + ":"}
                                </label>
                                <input
                                  id="number_hours"
                                  name="number_hours"
                                  type="number"
                                  min={1}
                                  value={values.number_hours}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className="personal_input_in"
                                />
                                <p className={"form-input-error-space"}>
                                  {errors.number_hours ? errors.number_hours : null}
                                </p>
                              </div>

                              <div>
                                <label htmlFor="category_id" className="personal_label">
                                  {t("Category")}
                                </label>
                                <select
                                  id="category_id"
                                  name="category_id"
                                  value={values.category_id}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className="personal_input_in"
                                >
                                  <option></option>
                                  {categories.map((item) => {
                                    return (
                                      <option key={item.id} value={item.id}>
                                        {i18n.language === Lang.AR ? item.name_ar : item.name_en}
                                      </option>
                                    );
                                  })}
                                </select>
                                <p className={"form-input-error-space"}>
                                  {errors.category_id ? errors.category_id : null}
                                </p>
                              </div>

                              <div>
                                <label htmlFor="section_id" className="personal_label">
                                  {t("department")}
                                </label>
                                <select
                                  id="section_id"
                                  name="section_id"
                                  value={values.parent_id}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className="personal_input_in"
                                >
                                  <option></option>
                                  {departments.map((item) => {
                                    return (
                                      <option key={item.id} value={item.id}>
                                        {i18n.language === Lang.AR ? item.name_ar : item.name_en}
                                      </option>
                                    );
                                  })}
                                </select>
                                <p className={"form-input-error-space"}>
                                  {errors.section_id ? errors.section_id : null}
                                </p>
                              </div>

                              <div
                                className="admin_add_form__group"
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  width: "100%",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  style={{
                                    width: "40%",
                                  }}
                                >
                                  <label htmlFor="start_date" className="personal_label">
                                    {t("crud.placeholders.course_start_date")}
                                  </label>
                                  <DatePicker
                                    selected={values.start_date}
                                    onChange={(date) => setFieldValue("start_date", date)}
                                    showTimeSelect
                                    placeholderText={t("crud.placeholders.course_start_date")}
                                    className="admin_add_input"
                                    showTime={{ use12Hours: true }}
                                    dateFormat={"yyyy-MM-dd hh:mm:ss a"}
                                  />
                                  <p className={"form-input-error-space"}>
                                    {errors.start_date ? errors.start_date : null}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "40%",
                                  }}
                                >
                                  <label htmlFor="end_date" className="personal_label">
                                    {t("crud.placeholders.course_end_date")}
                                  </label>
                                  <DatePicker
                                    selected={values.end_date}
                                    onChange={(date) => setFieldValue("end_date", date)}
                                    showTimeSelect
                                    placeholderText={t("crud.placeholders.course_end_date")}
                                    className="admin_add_input"
                                    showTime={{ use12Hours: true }}
                                    dateFormat={"yyyy-MM-dd hh:mm:ss a"}
                                  />
                                  <p className={"form-input-error-space"}>{errors.end_date ? errors.end_date : null}</p>
                                </div>
                              </div>

                              <div
                                className="admin_add_form__group"
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  width: "100%",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  style={{
                                    width: "40%",
                                  }}
                                >
                                  <label htmlFor="start_register_date" className="personal_label">
                                    {t("crud.placeholders.course_start_register")}
                                  </label>
                                  <DatePicker
                                    selected={values.start_register_date}
                                    onChange={(date) => setFieldValue("start_register_date", date)}
                                    showTimeSelect
                                    placeholderText={t("crud.placeholders.course_start_register")}
                                    className="admin_add_input"
                                    showTime={{ use12Hours: true }}
                                    dateFormat={"yyyy-MM-dd hh:mm:ss a"}
                                  />
                                  <p className={"form-input-error-space"}>
                                    {errors.start_register_date ? errors.start_register_date : null}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "40%",
                                  }}
                                >
                                  <label htmlFor="end_register_date" className="personal_label">
                                    {t("crud.placeholders.course_end_register")}
                                  </label>
                                  <DatePicker
                                    selected={values.end_register_date}
                                    onChange={(date) => setFieldValue("end_register_date", date)}
                                    showTimeSelect
                                    placeholderText={t("crud.placeholders.course_end_register")}
                                    className="admin_add_input"
                                    showTime={{ use12Hours: true }}
                                    dateFormat={"yyyy-MM-dd hh:mm:ss a"}
                                  />
                                  <p className={"form-input-error-space"}>
                                    {errors.end_register_date ? errors.end_register_date : null}
                                  </p>
                                </div>
                              </div>

                              <div>
                                <button
                                  type="submit"
                                  className="admin_add_button"
                                  disabled={!hasFetchedCategories || !hasFetchedDepartments || isSubmitting}
                                >
                                  {t("addcourse")}
                                </button>
                              </div>
                            </form>
                          </div>
                        </>
                      )}
                    </Formik>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AddCourse;
