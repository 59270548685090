import { toast } from "react-toastify";
import env from "../../../Services/api/Environment";
import connector from "../../../Services/api/Handler";
import { default as URLBuilder } from "../../../Services/api/UrlBuilder";
import { partnersListConstant } from "../../Constants/PartnersTebx/partnersTebx.constent";

// GET SYSTEM USERS LIST
export const fetchPartnersList = ({ page = 1, perPage = 10, filter }) => {
  return async (dispatch) => {
    dispatch({
      type: partnersListConstant.GET_PARTNERS_LIST_REQUEST,
    });

    await connector
      .get(env.get_partners_list, {
        params: {
          page,
          perPage,
          ...filter,
        },
      })
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: partnersListConstant.GET_PARTNERS_LIST_SUCCESS,
            payload: {
              users: response.data.response.Users,
              roles: response.data.response.AllRoles,
              genders: response.data.response.genders,
              // medicalFields: response.data.response.medical_fields,
              specialties: response.data.response.specialties,
              countries: response.data.response.countries,
            },
          });
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: partnersListConstant.GET_PARTNERS_LIST_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: partnersListConstant.GET_PARTNERS_LIST_REJECTED,
          payload: err,
        });
      });
  };
};

export const updatePartnersStatus = ({ id }) => {
  return async (dispatch) => {
    dispatch({
      type: partnersListConstant.UPDATE_PARTNER_STATUS_REQUEST,
    });

    await connector
      .post(env.active_users, { user_id: id })
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: partnersListConstant.UPDATE_PARTNER_STATUS_SUCCESS,
            payload: {
              id,
            },
          });
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: partnersListConstant.UPDATE_PARTNER_STATUS_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: partnersListConstant.UPDATE_PARTNER_STATUS_REJECTED,
          payload: err,
        });
      });
  };
};
