import { Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import toLocalTimezone from "../../../Services/Timezone";
import { exportReport, zoomReport } from "../../../Services/api/Zoom/ZoomProvider";
import { getCountries } from "../../../Services/api/profile/profileProvider";
import HomeIcon from "../../../assets/icons/home.svg";
import Breadcrumb from "../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../Shared/Components/MainBoxHead/MainBoxHead";
import SkeletonCardOverlay from "../../Shared/Components/Spinner/SkeletonCardOverlay";
import InvoiceFilter from "./InvoiceFilter";
import InvoiceTable from "./InvoiceTable";
import classes from "./invoice.module.css";
import { filterActions } from "./invoiceActions";

export default function Invoice() {
  require("./invoice.css");
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { courseId, id, lessonId } = useParams();
  const roles = JSON.parse(localStorage.getItem("roles"));
  const role = location?.state?.role
    ? location?.state?.role
    : roles.includes("admin")
    ? "admin"
    : roles.includes("manager")
    ? "manager"
    : roles.includes("partner")
    ? "partner"
    : "trainer";

  const [report, setReport] = useState([]);
  const [reportMeta, setReportMeta] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [countries, setCountries] = useState([]);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [currentFilter, setCurrentFilter] = useState({});
  const [filterData, setFilterData] = useState({
    name: "",
    email: "",
  });

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
  };

  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };

  const resetFilterHandler = () => {
    setFilterData({
      name: "",
      email: "",
    });
    setIsResetApplied(true);
  };

  const showFilterHandler = () => {
    setShowFilter((prev) => !prev);
  };

  const getFilterData = () => {
    const filter = {};
    filterData.name && (filter.name = filterData.name);
    filterData.email && (filter.email = filterData.email);

    return filter;
  };

  const zoomReportAPI = () => {
    setLoading(true);
    const filter = getFilterData();
    setCurrentFilter(filter);
    zoomReport({ id, page: currentPage, perPage: 10, filter })
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          const filteredData = res.data.data.result.data.filter((item) => !item.user.email.startsWith("devrobot"));
          setReport(filteredData);
          setReportMeta(res.data.data.result.meta);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getCountriesAPI = () => {
    getCountries()
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setCountries(res.data.countries);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    zoomReportAPI();
    if (isResetApplied) {
      setIsResetApplied(false);
    }
  }, [currentPage, isResetApplied]);

  useEffect(() => {
    getCountriesAPI();
  }, []);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "lesson",
      page: t("zoom.invoice.Lesson"),
      pagePath: `/${role}/course/${courseId}/lesson/${lessonId}`,
    },
    {
      id: "course",
      page: t("zoom.invoice.Reports"),
      active: true,
    },
  ];

  const exportHandler = async (type) => {
    setIsExportLoading(true);
    try {
      await exportReport(id, type, currentFilter);
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
    }
  };

  return (
    <div>
      {isExportLoading && <SkeletonCardOverlay skeletonWidth="100" />}
      <div className="container-fluid">
        <div className={classes["invoice-container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <MainBox>
                <MainBoxHead
                  title={t("zoom.invoice.Reports")}
                  actions={filterActions({
                    showFilter: showFilterHandler,
                    ...(role !== "partner" ? { exportPdf: () => exportHandler("pdf") } : {}),
                    ...(role !== "partner" ? { exportExcel: () => exportHandler("xlsx") } : {}),
                  })}
                />
                {showFilter && (
                  <InvoiceFilter
                    filterData={filterData}
                    changeHandler={changeHandler}
                    resetHandler={resetHandler}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    filterHandler={zoomReportAPI}
                    resetFilterHandler={resetFilterHandler}
                  />
                )}
                <InvoiceTable isLoading={loading} users={report} countries={countries} />
                <Pagination
                  count={reportMeta.totalPages || 0}
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  className="main-pagination"
                  onChange={(e, page) => {
                    setCurrentPage(page);
                  }}
                />
              </MainBox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
