import { SelectAll } from "@mui/icons-material";
import React from "react";
import DatePicker from "react-multi-date-picker";
import { DatePicker as YearPicker } from "react-datepicker";
import Select from "react-select";
import ResetIcon from "../../../../assets/icons/remove-gray.svg";
import Button from "../Button/Button";
import SelectWithSearch from "../SelectWithSearch/SelectWithSearch";
import "./filterBox.css";
import classes from "./filterBox.module.css";

const FilterBox = ({ fields, justifyContent }) => {
  const checkField = (field) => {
    switch (field.component) {
      case "input":
        return (
          <input
            type={field.type}
            placeholder={field.placeholder}
            name={field.name}
            onChange={field.onchange}
            value={field.value}
          />
        );
      case "select":
        return (
          <select
            name={field.name}
            onChange={field.onchange}
            value={field.value}
          >
            <option value="">{field.initialValue}</option>
            {field.options.map((option) => (
              // <option key={option.value} value={option.value}>
              <option
                key={option.value}
                value={field.id === "country" ? option.id : option.value}
              >
                {option.label}
              </option>
            ))}
          </select>
        );

      case "datePicker":
        return (
          <input
            type="date"
            name={field.name}
            onChange={field.onchange}
            placeholder={field.placeholder}
            value={field.value}
          />
        );
      case "selectSearch":
        return (
          <div style={{ height: "50px" }}>
            <Select
              id={field.id}
              name={field.name}
              onChange={(item) =>
                field.onchange({ target: { name: field.name, value: item } })
              }
              placeholder={field.placeholder}
              options={field.options}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              value={field.value}
            />
          </div>
        );

      case "dateRange":
        return (
          <DatePicker
            value={field.value}
            range
            containerClassName="tw-w-full"
            placeholder={field.placeholder}
            onChange={(value) =>
              field.onchange({ target: { value: value, name: field.name } })
            }
          />
        );

      case "button":
        return (
          <Button
            type={field.type}
            action={field.action}
            backgroundColor={field.backgroundColor}
            color={field.color}
            borderColor={field.borderColor}
          >
            {field.value}
          </Button>
        );

      default:
        return null;
    }
  };

  return (
    <div className={classes["filter-box"]}>
      <div
        className={`row ${justifyContent && justifyContent} ${
          classes["filter-box__fields"]
        } ${classes["align-end"]}`}
      >
        {fields.map((field) => (
          <div
            key={field.id}
            className={`${field.classes} ${classes["filter-box__field"]}`}
          >
            <label htmlFor="">{field.label}</label>
            <div className={`${classes["filter-box_field_item"]}`}>
              {checkField(field)}
              {field.component === "input" && field.type !== "date" && (
                <button
                  className={classes["filter-box__field-reset"]}
                  onClick={() => {
                    field.onReset(field.name);
                  }}
                >
                  <img src={ResetIcon} alt="" />
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FilterBox;
