import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import ArrowIcon from "../../../../assets/icons/arrow.png";
import LockIcon from "../../../../assets/icons/lock.svg";
import { getLocaleTime } from "../../../../utils/utcToLocal";
import classes from "./trainingCourseContentLessons.module.css";

export default function CourseContentLessonsSyllabus({ subChapter, setSelectedLesson, selectedLesson }) {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const history = useHistory();
  const { id } = useParams();

  return (
    <div
      className={classes["syllabus"]}
      style={i18n.language == "ar" ? { borderLeft: "1px solid #c8c8c8" } : { borderRight: "1px solid #c8c8c8" }}
    >
      <div className={classes["syllabus-header"]}>
        <div className={classes["syllabus-header-details"]}>
          <div
            className={classes["syllabus-body-sub-chapter"]}
            onClick={() => history.push(`/training-courses/${id}/content`)}
          >
            <div className={classes["syllabus-body-sub-chapter-icon"]}>
              <img src={ArrowIcon} alt="" />
            </div>
            <p className={classes["back_title"]}>{subChapter.name}</p>
          </div>
        </div>
        <div
          className={classes["syllabus-header-image"]}
          style={i18n.language == "ar" ? { paddingLeft: 20 } : { paddingRight: 20 }}
        >
          <img src={subChapter.course_image} alt="course" />
        </div>
        <h2 className={classes["course-title-sidebar"]}>{subChapter.course_name}</h2>
        <p
          dangerouslySetInnerHTML={{
            __html: subChapter.course_brief_description,
          }}
        ></p>
      </div>
      <div className={classes["syllabus-body"]}>
        <div className={classes["syllabus-body-lessons"]}>
          {subChapter?.lessons?.map((item, index) => (
            <div
              className={classes["syllabus-body-lessons-item"]}
              key={item.id}
              onClick={() =>
                (item.start_date ? moment(getLocaleTime(new Date(item.start_date))).isBefore(new Date()) : true) &&
                (item.end_date ? moment(getLocaleTime(new Date(item.end_date))).isAfter(new Date()) : true) &&
                (index !== 0 ? item.is_previous_completed !== false : true)
                  ? setSelectedLesson(item.id)
                  : null
              }
            >
              <div className={classes["syllabus-body-lessons-item-content"]}>
                <div className={classes["syllabus-body-lessons-item-number"]}>{index + 1}</div>
                <div className={classes["syllabus-body-lessons-item-name"]}>{item.title}</div>
              </div>
              {(item.start_date ? moment(getLocaleTime(new Date(item.start_date))).isBefore(new Date()) : true) &&
              (item.end_date ? moment(getLocaleTime(new Date(item.end_date))).isAfter(new Date()) : true) &&
              (index !== 0 ? item.is_previous_completed !== false : true) ? (
                <div className={classes["syllabus-body-lessons-item-progress"]}>
                  <div
                    className={`${classes["lesson-progress-bar"]} ${classes["lesson-progress-bar-watch"]}`}
                    style={{ "--watch_percentage": `${Number(item.watch_percentage) || 0}%` }}
                    title={t("watchingProgress")}
                  >
                    <div className={classes["lesson-progress-bar-tooltip"]}>
                      <span>{`${Math.round(item.watch_percentage) || 0}%`}</span>
                    </div>
                  </div>
                  <div
                    className={`${classes["lesson-progress-bar"]} ${classes["lesson-progress-bar-achievement"]}`}
                    style={{ "--progress_percentage": `${Number(item.progress_percentage) || 0}%` }}
                    title={t("progress")}
                  >
                    <div className={classes["lesson-progress-bar-tooltip"]}>
                      <span>{`${Math.round(item.progress_percentage) || 0}%`}</span>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className={classes["syllabus-body-lessons-item-lock"]}
                  title={`${t("trainer.course.lesson.locked")} - ${
                    index !== 0 && item.is_previous_completed === false
                      ? `${t("trainer.course.lesson.by_previous")} (${item?.previous_lesson?.title})`
                      : `${t("trainer.course.lesson.start")} ${
                          moment(getLocaleTime(new Date(item?.start_date))).format("DD/MM/YYYY HH:mm") +
                          " " +
                          t("trainer.course.lesson.end")
                        } ${moment(getLocaleTime(new Date(item?.end_date))).format("DD/MM/YYYY HH:mm")}`
                  }`}
                >
                  <img src={LockIcon} alt="lock" />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
