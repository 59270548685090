import React from "react";

const BoothDetailsSelect = ({
  label,
  name,
  defaultValue,
  onChange,
  options,
  error,
}) => {
  return (
    <>
      <label htmlFor="name" className="admin_add_label">
        {label}
      </label>
      <select
        name={name}
        id={name}
        style={{ maxWidth: "120px" }}
        className="admin_add_input"
        defaultValue={defaultValue}
        onChange={onChange}
      >
        {options.map((option) => (
          <option value={option.value} key={option.name}>
            {option.name}
          </option>
        ))}
      </select>
      <p className={"form-input-error-space"}>{error ? error : null}</p>
    </>
  );
};

export default BoothDetailsSelect;
