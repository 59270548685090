import React from 'react';
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip, ArcElement} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import {useTranslation} from 'react-i18next'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

export default function BarChart(props) {
    const {t} = useTranslation()



     const data = {
        labels:props.dates,
        datasets: [
          {
            label: t('survey.statistics.label'),
            data: props.counts == undefined ? [50] : props.counts,
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      };

       const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
          },
        },
      };

  return <Bar options={options} data={data} />;
}
