import env from "../Environment";
import connector from "../Handler";
import URLBuilder from "../UrlBuilder";


export function CreateFaqForm(id) {
    let url = ""
    if(id == ""){
      url =  env.Create_Faq_Form_without_course
    }else{
      url =  env.Create_Faq_Form
    }
    const myPromise = new Promise((resolve, reject) => {
        try {
            connector
            .get(URLBuilder(url,{course_id:id}))
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
        } catch (error) {
            reject(error);
        }
    });

    return myPromise;
}

export function addFaqList(payload) {
    const myPromise = new Promise((resolve, reject) => {
        try {
            connector
                .post(env.FAQ_list,payload)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        } catch (error) {
            reject(error);
        }
    });

    return myPromise;
}

export function EditFaqProvider(id,payload) {
    const myPromise = new Promise((resolve, reject) => {
        try {
            connector
                .post(URLBuilder(env.Edit_Faq,{id:id}),payload)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        } catch (error) {
            reject(error);
        }
    });

    return myPromise;
}

export function DeleteFaq(id,payload) {
    const myPromise = new Promise((resolve, reject) => {
        try {
            connector
                .post(URLBuilder(env.Edit_Faq,{id:id}),payload)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        } catch (error) {
            reject(error);
        }
    });

    return myPromise;
}
