import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import DatePicker from "react-datepicker";
import { useHistory, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";

import SurveyManager from "../../Admin/survey/add/SurveyManager";

function TrainerSurveyManager() {
  require("./add.css");
  const { t } = useTranslation();
  const history = useHistory();
  const inputFile = useRef(null);

  
  const {id} = useParams();
  const {targetCourseId} = useParams();
  
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [hasFetchedCategories, setHasFetchedCategories] = useState(false);
  const [hasFetchedDepartments, setHasFetchedDepartments] = useState(false);
  const [categories, setCategories] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [toBeShownImage, setToBeShownImage] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

 


  return (
    <>
      <div className="container-fluid">
        <div className="row bg-container">
          <div className="col-12 bg-container">
            <SurveyManager isTrainer={true}/>
          </div>
        </div>
      </div>
    </>
  );
}

export default TrainerSurveyManager;
