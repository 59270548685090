import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { applyCoupon } from "../../../../Services/api/courses/courseProvider";
import CustomButton from "../../../Shared/Components/CustomButton/CustomButton";
import Input from "../../../Shared/Components/Input/Input";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import classes from "./trainingCoursePayment.module.css";

const GiftProgramPaymentDetails = ({
  handleSubmit,
  courseDetails,
  validCoupon,
  setValidCoupon,
  handleSelectPaymentClick,
  values,
  errors,
  handleChange,
  setFieldValue,
  trainees,
}) => {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [couponCode, setCouponCode] = useState("");

  const [payments, setPayments] = useState(
    courseDetails.payments.filter((payment) => payment.is_payment_overdue === false)
  );

  const applyCouponCode = () => {
    setIsLoading(true);
    applyCoupon(courseDetails.id, couponCode)
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status && res.data.coupon) {
          setValidCoupon({
            code: couponCode,
            value: res.data.coupon?.value,
            mechanism: res.data.coupon?.mechanism,
            include_tax: res.data.coupon?.include_tax,
          });

          if (res.data.coupon?.mechanism === "2" && Number(validCoupon?.value) === 100) {
            handleSelectPaymentClick("wallet");
          }
        } else {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{res.data.msg}</span>);
        }
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.data.msg}</span>);
        setCouponCode("");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const removeCoupon = () => {
    setValidCoupon({});
    setCouponCode("");
  };

  const calculateValue = (value, mechanism, price) => {
    if (!value) return 0;
    if (mechanism === "1") {
      return value;
    } else {
      return Number((value / 100) * price).toFixed(2);
    }
  };

  return (
    <div className={classes["payment-details"]}>
      <div className={classes["payment-field"]}>
        <Input
          name="email"
          label={t("trainee_list_label")}
          id="email"
          type="email"
          placeholder={t("gifted_to")}
          onChange={handleChange}
          value={values.email}
          error={errors.email}
          required
        />
      </div>
      <div>
        <div className={classes["payment-details-container"]}>
          <div className={classes["payment-details-title"]}>{t("payments.programFullSummary")}</div>
          {payments.map((payment, index) => (
            <>
              <div className={classes["payment-details-subtitle"]}>
                {t("trainer.program.payment")} {index + 1}
              </div>
              <div className={classes["payment-details-price-container"]}>
                <div className={classes["payment-details-price-item"]}>
                  <div className={classes["payment-details-price"]}>
                    <div className={classes["payment-details-price-top"]}>
                      <div className={classes["payment-details-price-title"]}>{t("payments.main_price")}</div>
                    </div>
                  </div>
                  <div className={classes["payment-details-price-value"]}>
                    {Number(payment.amount).toFixed(2)} {t("rs")}
                  </div>
                </div>
                {Object.keys(validCoupon).length > 0 ? (
                  <div className={classes["payment-details-price-item-minus"]}>
                    <div className={classes["payment-details-price"]}>
                      <div className={classes["payment-details-price-top"]}>
                        <div className={classes["payment-details-price-title"]}>
                          {t("payments.coupon_code") + " " + validCoupon.code}
                        </div>
                        {validCoupon.mechanism === "2" ? (
                          <div className={classes["payment-details-price-percentage"]}>{`(${validCoupon.value}%)`}</div>
                        ) : null}
                      </div>
                      {validCoupon.include_tax ? (
                        <div className={classes["payment-details-price-note"]}>{t("financial.label.include_tax")}</div>
                      ) : null}
                    </div>
                    <div className={classes["payment-details-price-value"]}>
                      {calculateValue(validCoupon.value, validCoupon.mechanism, payment.amount)} {t("rs")}
                    </div>
                  </div>
                ) : null}
                {Object.keys(validCoupon).length > 0 ? (
                  <div className={classes["payment-details-price-item"]}>
                    <div className={classes["payment-details-price"]}>
                      <div className={classes["payment-details-price-top"]}>
                        <div className={classes["payment-details-price-title"]}>{t("payments.net_price")}</div>
                      </div>
                    </div>
                    <div className={classes["payment-details-price-value"]}>
                      {Number(
                        payment.amount - calculateValue(validCoupon.value, validCoupon.mechanism, payment.amount)
                      ).toFixed(2)}{" "}
                      {t("rs")}
                    </div>
                  </div>
                ) : null}
                {courseDetails.taxes.length > 0
                  ? courseDetails.taxes
                      .filter((tax) => tax.user_can_use_now)
                      .map((tax) => {
                        return (
                          <div className={classes["payment-details-price-item-plus"]}>
                            <div className={classes["payment-details-price"]}>
                              <div className={classes["payment-details-price-top"]}>
                                <div className={classes["payment-details-price-title"]}>{tax.name}</div>
                                {tax.mechanism === "2" ? (
                                  <div className={classes["payment-details-price-percentage"]}>{`(${tax.value}%)`}</div>
                                ) : null}
                              </div>
                            </div>
                            <div className={classes["payment-details-price-value"]}>
                              {Object.keys(validCoupon).length === 0 || !validCoupon.include_tax
                                ? calculateValue(tax.value, tax.mechanism, payment.amount)
                                : calculateValue(
                                    tax.value,
                                    tax.mechanism,
                                    payment.amount -
                                      calculateValue(validCoupon.value, validCoupon.mechanism, payment.amount)
                                  )}{" "}
                              {t("rs")}
                            </div>
                          </div>
                        );
                      })
                  : null}
                <div className={classes["payment-details-total-price-item"]}>
                  <div className={classes["payment-details-total-price-title"]}>
                    {t("payments.total_price")}
                    <span className={classes["payment-details-total-price-title-span"]}>
                      {" "}
                      ({t("payments.include_tax")})
                    </span>
                  </div>
                  <div className={classes["payment-details-total-price-value"]}>
                    {Number(
                      Number(payment.amount) -
                        Number(calculateValue(validCoupon.value, validCoupon.mechanism, payment.amount)) +
                        Number(
                          courseDetails.taxes
                            .filter((tax) => tax.user_can_use_now)
                            .reduce(
                              (acc, tax) =>
                                acc +
                                (Object.keys(validCoupon).length === 0 || !validCoupon.include_tax
                                  ? calculateValue(tax.value, tax.mechanism, payment.amount)
                                  : calculateValue(
                                      tax.value,
                                      tax.mechanism,
                                      payment.amount -
                                        calculateValue(validCoupon.value, validCoupon.mechanism, payment.amount)
                                    )),
                              0
                            )
                        )
                    ).toFixed(2)}{" "}
                  </div>
                </div>
              </div>
            </>
          ))}
          <div
            className={`${classes["payment-details-total-price-item"]} ${classes["payment-details-total-price-item-final"]}`}
          >
            <div className={classes["payment-details-total-price-title"]}>{t("payments.total_payments_price")}</div>
            <div className={classes["payment-details-total-price-value"]}>
              {Number(
                payments.reduce((acc, payment) => {
                  return (
                    acc +
                    Number(payment.amount) -
                    Number(calculateValue(validCoupon.value, validCoupon.mechanism, payment.amount)) +
                    Number(
                      courseDetails.taxes
                        .filter((tax) => tax.user_can_use_now)
                        .reduce(
                          (acc, tax) =>
                            acc +
                            (Object.keys(validCoupon).length === 0 || !validCoupon.include_tax
                              ? calculateValue(tax.value, tax.mechanism, payment.amount)
                              : calculateValue(
                                  tax.value,
                                  tax.mechanism,
                                  payment.amount -
                                    calculateValue(validCoupon.value, validCoupon.mechanism, payment.amount)
                                )),
                          0
                        )
                    )
                  );
                }, 0)
              ).toFixed(2)}{" "}
              {t("rs")}
            </div>
          </div>
          <div className={classes["payment-details-coupon-container"]}>
            <Input
              label={t("payments.coupon_code")}
              className={classes["payment-details-coupon-input"]}
              placeholder={t("payments.coupon_code")}
              value={validCoupon?.code ? validCoupon.code : couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
            />
            {Object.keys(validCoupon).length > 0 ? (
              <CustomButton
                action={removeCoupon}
                type="button"
                disable={false}
                colors="#036c77"
                value={t("general.cancellation")}
                classes={classes["payment-details-coupon-button"]}
              />
            ) : (
              <CustomButton
                action={applyCouponCode}
                type="button"
                disable={couponCode === "" || Object.keys(validCoupon).length > 0}
                colors="#036c77"
                value={t("payments.apply")}
                classes={`${classes["payment-details-coupon-button"]} ${
                  couponCode === "" || Object.keys(validCoupon).length > 0
                    ? classes["payment-details-coupon-button-disabled"]
                    : ""
                }`}
              />
            )}
          </div>
          <CustomButton
            action={handleSubmit}
            disable={false}
            colors="#036c77"
            value={
              validCoupon?.mechanism === "2" && Number(validCoupon?.value) === 100
                ? t("payments.register")
                : t("payments.pay")
            }
            classes={classes["payment-details-pay-button"]}
          />
        </div>
        {isLoading && <SkeletonCardOverlay />}
      </div>
    </div>
  );
};

export default GiftProgramPaymentDetails;
