
import DeleteIcon from "../../../assets/icons/delete.svg";
import classes from "./search.module.css";

const SearchInput = ({
  name,
  placeholder,
  onChange,
  type,
  width,
  value,
  disabled,
  canDelete,
  deleteHandler,
}) => {
  return (
    <div className={classes["search-input-container"]}>
    <input
      className={classes["search-input"]}
      value={value}
      type={type}
      name={name}
      placeholder={placeholder}
      onChange={(e) => {
        onChange(e.target.value)
      }}
      style={{ width: width || "100%" }}
      disabled={disabled}
    />
    {canDelete && value && (
      <div className={classes["search-input-clear"]}
        onClick={() => {
          deleteHandler()
        }}
      >
        <img src={DeleteIcon} alt="" />
      </div>
    )}
    </div>
  )
}

export default SearchInput