import { Pagination } from "@mui/material";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import IconTooltips from "../../../../Components/tooltips/IconsTooltip";
import {
  blockAllowedIgnored,
  blockIgnore,
  blockRequest,
  unblockRequest,
} from "../../../../Services/api/ipBlocked/ipBlockedProvider";
import FilterIcon from "../../../../assets/icons/filter.svg";
import HomeIcon from "../../../../assets/icons/home.svg";
import warning from "../../../../assets/icons/warning-green.svg";
import DeleteIcon from "../../../../assets/image/alert-octagon.png";
import userCheck from "../../../../assets/image/user-check.svg";
import userX from "../../../../assets/image/user-x.svg";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import FilterBox from "../../../Shared/Components/FilterBox/FilterBox";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import SharedModal from "../../../Shared/Components/SharedModal/SharedModal";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import Tabs from "../../../Shared/Components/Tabs/Tabs";

function IpBlockedRequests() {
  require("./IpBlockedRequests.css");
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [blockRequests, setBlockRequests] = useState([]);
  const [blockRequestsMeta, setBlockRequestsMeta] = useState({});
  const [loading, setLoading] = useState(false);
  const [unblockRequestByIpLoading, setUnblockRequestByIpLoading] = useState(false);
  const [ignoreModal, setIgnoreModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [rowID, setRowID] = useState();
  const [ip, setIp] = useState(null);
  const modalRef = useRef();
  const [showFilter, setShowFilter] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const [countries, setCountries] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isUpdating, setIsUpdating] = useState(false);
  const [filterData, setFilterData] = useState({
    name: "",
    email: "",
    customizeBan: "",
    range: "",
    ip: "",
    country: "",
    desired_action: "",
  });

  const [isResetApplied, setIsResetApplied] = useState(false);

  const actionsList = [
    {
      id: "filter",
      icon: <img src={FilterIcon} alt="" />,
      action: () => {
        setShowFilter((prev) => !prev);
      },
    },
  ];

  const tabs = [
    {
      id: "unblock",
      key: 0,
      label: t("block.label.req_list"),
    },
    {
      id: "unblockApproved",
      key: 1,
      label: t("block.label.req_list_accept"),
    },
    {
      id: "unblockRejected",
      key: 2,
      label: t("block.label.req_list_reject"),
    },
  ];

  const getFilterData = () => {
    const filter = {};
    filterData.name && (activeTab === 0 ? (filter.name = filterData.name) : (filter.block_name = filterData.name));
    filterData.email && (filter.email = filterData.email);
    filterData.date && (filter.date = filterData.date);
    filterData.user_name &&
      (activeTab === 0 ? (filter.block_name = filterData.user_name) : (filter.user_name = filterData.user_name));

    activeTab !== 0 && (filter.status = activeTab);
    return filter;
  };

  const getBlockRequestHandler = async () => {
    const filter = getFilterData();

    setLoading(true);
    activeTab === 0
      ? await blockRequest({
          perPage: 10,
          page: currentPage,
          filter,
        })
          .then((res) => {
            if (res.status && res.status === 200 && res.data.status) {
              setBlockRequests(res.data.data.requests.data);
              setBlockRequestsMeta(res.data.data.requests.meta);
              setLoading(false);
            }
          })
          .catch((err) => {
            setLoading(false);
            toast.error(<span style={{ fontSize: 15 }}>{err.response.data.message}</span>);
          })
      : await blockAllowedIgnored({
          perPage: 10,
          page: currentPage,
          filter,
        })
          .then((res) => {
            if (res.status && res.status === 200 && res.data.status) {
              setBlockRequests(res.data.data);
              setLoading(false);
            }
          })
          .catch((err) => {
            setLoading(false);
            toast.error(<span style={{ fontSize: 15 }}>{err.response.data.message}</span>);
          });
  };

  useEffect(() => {
    getBlockRequestHandler();
    setIsResetApplied(false);
  }, [currentPage, isResetApplied, activeTab]);

  const rejectUnblockHandler = async (id, ip) => {
    setIgnoreModal(true);
    // modalRef.current.showModal();
    setRowID(id);
    setIp(ip);
  };

  const modalHandler = () => {
    setIgnoreModal(false);
    setDeleteModal(false);
  };

  const acceptUnblockHandler = async (id, ip) => {
    if (unblockRequestByIpLoading) return;
    setIgnoreModal(false);
    setDeleteModal(true);
    // modalRef.current.showModal();
    setRowID(id);
    setIp(ip);
  };

  const unBlockRequestHandler = async (id, ip, allow) => {
    setUnblockRequestByIpLoading(true);
    await unblockRequest({
      request_id: id,
      ip,
      allow: allow,
    })
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>{t("block.massages.unblock_success")}</span>
          );
          setBlockRequests(blockRequests.filter((item) => item.id !== id));
        }
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err?.response?.data?.message}</span>);
      })
      .finally(() => {
        setDeleteModal(false);
        setIgnoreModal(false);
        setUnblockRequestByIpLoading(false);
      });
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
  };

  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };

  const resetFilterHandler = () => {
    setFilterData({
      name: "",
      customizeBan: "",
      range: "",
      ip: "",
      country: "",
      desired_action: "",
    });
    setIsResetApplied(true);
  };

  const applyFilterHandler = async () => {
    getBlockRequestHandler();
  };

  const filterFields = [
    {
      id: "name",
      component: "input",
      type: "text",
      placeholder: t("block.table.name"),
      label: t("block.table.name"),
      name: "name",
      value: filterData.name ?? "",
      onReset: resetHandler,
      classes: "col-12 col-md-6",
      onchange: changeHandler,
    },
    {
      id: "email",
      component: "input",
      type: "text",
      placeholder: t("block.table.email"),
      label: t("block.table.email"),
      name: "email",
      value: filterData.email ?? "",
      onReset: resetHandler,
      classes: "col-12 col-md-6",
      onchange: changeHandler,
    },
    {
      id: "user_name",
      component: "input",
      type: "text",
      placeholder: t("block.table.user_name"),
      label: t("block.table.user_name"),
      name: "user_name",
      value: filterData.user_name ?? "",
      onReset: resetHandler,
      classes: "col-12 col-md-6",
      onchange: changeHandler,
    },
    {
      id: "date",
      component: "input",
      type: "date",
      placeholder: t("block.table.date"),
      label: t("block.table.date"),
      name: "date",
      value: filterData.date ?? "",
      onReset: resetHandler,
      classes: "col-12 col-md-6",
      onchange: changeHandler,
    },
    {
      id: "reset",
      component: "button",
      type: "button",
      name: "action",
      value: t(`Exhibition.reset`),
      classes: "col-12 col-md-3",
      backgroundColor: "white",
      color: "rgb(4, 108, 119)",
      borderColor: "rgb(4, 108, 119)",
      action: () => {
        resetFilterHandler();
      },
    },
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("Exhibition.search"),
      classes: "col-12 col-md-3",
      action: () => {
        applyFilterHandler();
      },
    },
  ];

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "IpBlocked",
      page: t("block.label.req_list_block"),
      active: true,
    },
  ];

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData }) => <>{rowData.id}</>,
    },
    {
      id: "name",
      name: t("block.table.name"),
      key: "name",
      cell: ({ rowData }) => (
        <>
          {/* <NavLink to={`/admin/ip-blocked/edit/${activeTab === 0 ? rowData.block && rowData.block.id : rowData.id}`}> */}
          {activeTab === 0 ? rowData.block && rowData.block.name : rowData.block_name}
          {/* </NavLink> */}
        </>
      ),
      sortable: false,
    },
    {
      id: "email",
      name: t("block.table.email"),
      key: "email",
      cell: ({ rowData }) => <>{rowData.email || "-"}</>,
      sortable: false,
    },
    {
      id: "user_name",
      name: t("block.table.user_name"),
      key: "user_name",
      cell: ({ rowData }) => <>{activeTab === 0 ? rowData.name : rowData.user_name}</>,
      sortable: false,
    },
    {
      id: "message",
      name: t("block.table.message"),
      key: "message",
      cell: ({ rowData }) => <>{rowData.message}</>,
      sortable: false,
    },
    {
      id: "ip",
      name: t("block.table.range"),
      key: "ip",
      cell: ({ rowData }) => <>{rowData.ip || "-"}</>,
      sortable: false,
    },
    {
      id: "date",
      name: t("block.table.date"),
      key: "date",
      cell: ({ rowData }) => <>{rowData.created_at ? moment(rowData.created_at).format("YYYY-MM-DD") : "-"}</>,
      sortable: false,
    },
    ...(activeTab === 0
      ? [
          {
            id: "actions",
            name: t("block.table.edit"),
            key: "actions",
            cell: ({ rowData }) => (
              <div
                className=""
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <IconTooltips
                  title={t("block.label.accepte_block")}
                  content={
                    <img
                      className="cursor-pointer"
                      onClick={() => acceptUnblockHandler(rowData.id, rowData.ip)}
                      src={userCheck}
                      alt=""
                    />
                  }
                />
                <IconTooltips
                  title={t("block.label.ignorance")}
                  content={
                    <img
                      className="cursor-pointer"
                      onClick={() => rejectUnblockHandler(rowData.id, rowData.ip)}
                      src={userX}
                      alt=""
                    />
                  }
                />
              </div>
            ),
            sortable: false,
          },
        ]
      : []),
  ];

  const ignoreBlockHandler = async (rowID) => {
    setUnblockRequestByIpLoading(true);
    await blockIgnore(rowID)
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{res?.data?.msg}</span>);
          setBlockRequests(blockRequests.filter((item) => item.id !== rowID));
        }
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err?.data?.message}</span>);
      })
      .finally(() => {
        setDeleteModal(false);
        setIgnoreModal(false);
        setUnblockRequestByIpLoading(false);
      });
  };

  return (
    <>
      {ignoreModal && (
        <SharedModal
          icon={DeleteIcon}
          title={t("confirmIgnore")}
          name={t("ignoreBody")}
          // confirmMsg={t("ignoreBody")}

          confirmText={t("modal.common.confirm")}
          cancelText={t("modal.common.cancel")}
          onCancel={() => {
            setIgnoreModal(false);
          }}
          onConfirm={() => {
            // unBlockRequestHandler(rowID, ip, 0);
            ignoreBlockHandler(rowID);
          }}
          isLoading={unblockRequestByIpLoading}
        />
      )}
      {deleteModal && (
        <SharedModal
          icon={warning}
          title={t("modal.block.delete.title")}
          name={t("modal.block.delete.body")}
          // confirmMsg={t("coupon.label.delete_aprove")}
          color={"#26b3b9"}
          confirmText={t("modal.common.confirm")}
          cancelText={t("modal.common.cancel")}
          onCancel={() => {
            setDeleteModal(false);
          }}
          onConfirm={() => {
            unBlockRequestHandler(rowID, ip, 1);
          }}
          isLoading={unblockRequestByIpLoading}
        />
      )}

      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="pt-5 pb-5">
              <Breadcrumb list={breadcrumbList} />
            </div>

            {isUpdating ? <SkeletonCardOverlay skeletonWidth="100" /> : <></>}

            <MainBox className="mb-4">
              <div className="label_container">
                <div>
                  <div className="main_label mb-4">{t("block.label.req_list_block")}</div>
                  <div className="main_label">
                    <Tabs
                      tabs={tabs}
                      disabled={loading}
                      activeTab={activeTab}
                      onSelectTab={(key) => {
                        setActiveTab(key);
                      }}
                    />
                  </div>
                </div>
                <MainBoxHead actions={actionsList} />
              </div>

              {showFilter && <FilterBox fields={filterFields} justifyContent={"justify-content-between"} />}

              <MainTable columns={columns} data={blockRequests} isLoading={loading} />

              <Pagination
                count={blockRequestsMeta?.totalPages || 0}
                showFirstButton
                showLastButton
                variant="outlined"
                shape="rounded"
                className="main-pagination"
                page={currentPage}
                onChange={(e, page) => {
                  setCurrentPage(page);
                }}
              />
            </MainBox>
          </div>
        </div>
      </div>
    </>
  );
}

export default IpBlockedRequests;
