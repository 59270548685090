import { Pagination } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchPartnerCoursesList } from "../../../Redux/Actions/courses/coursesList.action";
import { fetchDepartmentList } from "../../../Redux/Actions/departments/departmentList.action";
import {
  exportOneCourse,
  exportOneProgram,
  exportPartnerCoursesList,
} from "../../../Services/api/CoursesList";
import HomeIcon from "../../../assets/icons/home.svg";
import Breadcrumb from "../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../Shared/Components/MainBoxHead/MainBoxHead";
import { BasicModal } from "../../Shared/Components/Modal/BasicModal";
import SkeletonCardOverlay from "../../Shared/Components/Spinner/SkeletonCardOverlay";
import CloneCourseModal from "./CloneCourseModal";
import CoursesListTable from "./CoursesListTable";
import { filterActions } from "./CoursesListTableActions";
import classes from "./courses.module.css";

const PartnerCoursesList = ({
  role,
  isProgram,
  programCourses,
  status,
  isHistoryCourses,
  isHistoryPrograms,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const cloneCourseModalRef = useRef();

  const { departmentList } = useSelector(
    (state) => state.departmentListReducer
  );
  const { partnerCoursesListMeta } = useSelector(
    (state) => state.coursesListReducer
  );

  const [isExportLoading, setIsExportLoading] = useState(false);
  const [showCloneCourseModal, setShowCloneCourseModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: `${role === "admin" ? "content_development" : "private_profile"}`,
      page: t(
        `sidebar.label.${
          role === "admin" ? "content_development" : "private_profile"
        }`
      ),
      pagePath: role === "admin" ? "#" : "/private-profile",
    },
    {
      id: `${isProgram || programCourses ? "programs" : "courses"}`,
      page: t(
        `sidebar.label.${isProgram || programCourses ? "program" : "courses"}`
      ),
      [programCourses ? "pagePath" : "active"]: programCourses
        ? `/admin/program/list`
        : true,
    },
    ...(programCourses
      ? [
          {
            id: "courses",
            page: t("sidebar.label.courses"),
            active: true,
          },
        ]
      : []),
  ];

  const openCloneCourseModalHandler = () => {
    setShowCloneCourseModal(true);
    cloneCourseModalRef.current.showModal();
  };

  const closeCloneCourseModalHandler = () => {
    setShowCloneCourseModal(false);
    cloneCourseModalRef.current.dismissModal();
  };

  const getCoursesListHandler = () => {
    dispatch(
      fetchPartnerCoursesList({
        status: status,
        type: isProgram ? "programs" : "courses",
        perPage: 10,
        page: currentPage,
      })
    );
  };

  const getDepartmentsListHandler = async () => {
    dispatch(
      fetchDepartmentList({
        filter: {},
      })
    );
  };

  useEffect(() => {
    getCoursesListHandler();
    if (!departmentList?.length) {
      getDepartmentsListHandler();
    }
  }, []);

  const exportHandler = async (type) => {
    setIsExportLoading(true);

    try {
      await exportPartnerCoursesList(
        {},
        type,
        isProgram ? "programs" : "courses"
      );
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };
  const exportOneProgramHandler = async (type, id) => {
    setIsExportLoading(true);
    try {
      await exportOneProgram(type, id, role);
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };
  const exportOneCourseHandler = async (type, id) => {
    setIsExportLoading(true);
    try {
      await exportOneCourse(type, id, role);
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };

  return (
    <div className={classes["courses-list"]}>
      {isExportLoading && <SkeletonCardOverlay skeletonWidth="100" />}
      <div className="container-fluid">
        <div className={classes["courses-list__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <MainBox>
                <MainBoxHead
                  title={isProgram ? t("program") : t("sidebar.label.courses")}
                  actions={filterActions({
                    [programCourses ? "cloneCourse" : ""]:
                      openCloneCourseModalHandler,
                    [role !== "partner" ? "addAction" : ""]: () => {
                      history.push(
                        `/admin/${
                          programCourses
                            ? `program/${id}/course/add`
                            : isProgram
                            ? "program/add"
                            : "course/add"
                        }`
                      );
                    },
                    exportPdf: () => {
                      exportHandler("pdf");
                    },
                    exportExcel: () => {
                      exportHandler("xlsx");
                    },
                  })}
                />

                <CoursesListTable
                  isAdmin={role === "admin"}
                  isProgram={isProgram}
                  programCourses={programCourses}
                  programId={id}
                  isHistoryCourses={isHistoryCourses}
                  isHistoryPrograms={isHistoryPrograms}
                  role={role}
                  exportOneProgramHandler={exportOneProgramHandler}
                  exportOneCourseHandler={exportOneCourseHandler}
                />
                <Pagination
                  count={partnerCoursesListMeta?.totalPages || 0}
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  className="main-pagination"
                  page={currentPage}
                  onChange={(e, page) => {
                    setCurrentPage(page);
                  }}
                />
              </MainBox>
            </div>
          </div>
        </div>
      </div>
      {programCourses && (
        <BasicModal ref={cloneCourseModalRef}>
          {showCloneCourseModal && (
            <CloneCourseModal
              programId={id}
              closeModal={closeCloneCourseModalHandler}
            />
          )}
        </BasicModal>
      )}
    </div>
  );
};

export default PartnerCoursesList;
