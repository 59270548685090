import moment from 'moment-timezone';


function getServerTimezone() {
  return process.env.REACT_APP_BACKEND_TIMEZONE || 'UTC';
}

function toLocalTimezone(datetime, time = null) {
  // return the original when not supported
  var originalDatetime = datetime;
  if (!datetime || datetime === "" || datetime === 0 || datetime === "0") {
    return datetime;
  }
  // try to convert
  try {  
    if (time) {
      datetime += ' ' + time;
    }
    var serverTimezone = getServerTimezone();
    var localTimezone = moment.tz.guess();
    var serverTime = moment.tz(datetime,  'YYYY-MM-DD HH:mm:ss', serverTimezone);
    var localTime = serverTime.clone().tz(localTimezone);
    // if the result is invalid retrun the original
    if (! localTime.isValid()) {
      return originalDatetime;
    }
  
    return localTime.format("YYYY-MM-DD HH:mm:ss");
  } catch {
    // sorry ): we had troubles handling this for you
    return originalDatetime;
  }
}

export function toServerTime(datetime, time = null) {
  // return the original when not supported
  var originalDatetime = datetime;
  if (!datetime || datetime === "" || datetime === 0 || datetime === "0") {
    return datetime;
  }
  // try to convert
  try {  
    if (time) {
      datetime += ' ' + time;
    }
    var localTimezone = moment.tz.guess();
    var localTime = moment.tz(datetime,  'YYYY-MM-DD HH:mm:ss', localTimezone);
    var serverTimezone = getServerTimezone();
    var serverTime = localTime.clone().tz(serverTimezone);
    // if the result is invalid retrun the original
    if (! serverTime.isValid()) {
      return originalDatetime;
    }
  
    return serverTime.format("YYYY-MM-DD HH:mm:ss");
  } catch {
    // sorry ): we had troubles handling this for you
    return originalDatetime;
  }
}

export default toLocalTimezone;
