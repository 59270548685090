import React from "react";
import H5PElement from "./H5PElement/H5PElement";
import ReactPlayer from "react-player";


function LessonPart(props) {
    // require("./lessonPart.css");
    return (<>
            {props.item != null ? <div style={{width: "100%"}}>
                {
                    props.item.type === "video" ? (
                        <ReactPlayer
                            url={props.item.link}
                            height="500px"
                            width="100%"
                        ></ReactPlayer>
                    ) : props.item.type === "text" ? (
                        <div dangerouslySetInnerHTML={{
                            __html: props.item.content
                        }}>
                        </div>
                    ) : props.item.type === "scorm" ? (
                        // <iframe
                        //     title={props.item.title}
                        //     src={props.item.link}
                        //     display="flex"
                        //     height="500px"
                        //     width="100%"
                        // >
                        // </iframe>
                        <iframe
                            title={props.item.title}
                            src={`${process.env.REACT_APP_SERVER_PATH}scorm/player?path=${props.item.link}`}
                            display="flex"
                            height="500px"
                            width="100%"
                        >
                        </iframe>
                    ) : props.item.type === "xapi" ? (
                        <iframe
                            title={props.item.title}
                            src={props.item.link}
                            display="flex"
                            height="500px"
                            width="100%"
                        >
                        </iframe>
                    ) : props.item.type === "h5p" ? (
                        <div style={{width: props.item.width}}>
                            <H5PElement id={props.item.id} link={props.item.link}></H5PElement>
                        </div>) : null
                }
            </div> : null}
        </>
    );
}

export default LessonPart;
