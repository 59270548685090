import SendOutlinedIcon from "@material-ui/icons/SendOutlined";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import i18n from "../../i18n/i18n";
import { Lang } from "../../utils";
import classes from "./ChatBubbleInputs.module.css";

function ChatBubbleInputs(props) {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);

  return (
    <div
      className={`${classes["input-container"]} ${props.disabled && classes["input-disable"]}`}
      style={{
        width: "100%",
      }}
    >
      <textarea
        className={classes.textarea}
        disabled={props.disabled}
        placeholder={t("chat.enteryourmessagehere")}
        value={props.inputMessage}
        onChange={({ target: { value } }) => {
          setInputValue(value);
          props.setInputMessage(value, props.messages);
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            props.sendMessage();
            setInputValue("");
          }
        }}
      />

      {props.selectbox && (
        <Select
          name="require_approval"
          id="require_approval"
          options={props.options}
          isDisabled={props.disabled}
          getOptionLabel={(option) => (i18n.language === Lang.AR ? option.message_ar : option.message_en)}
          getOptionValue={(option) => option.id}
          onChange={(item) => {
            setSelectedOption(i18n.language === Lang.AR ? item.message_ar : item.message_en);
            setInputValue(i18n.language === Lang.AR ? item.message_ar : item.message_en);
            props.setInputMessage(i18n.language === Lang.AR ? item.message_ar : item.message_en, props.messages);
          }}
          value={selectedOption}
          placeholder={t("chat.answer_reply")}
          styles={{
            control: (base, state) => ({
              ...base,
              maxHeight: 50,
              boxShadow: "none",
              border: "none",
              backgroundColor: "transparent",
              minWidth: "130px",
              height: 50,
            }),
          }}
        />
      )}

      <SendOutlinedIcon
        className={props.disabled || (!inputValue.trim() && !selectedOption) ? classes.disabledicon : classes.icon}
        onClick={() => {
          props.sendMessage();
          setInputValue("");
        }}
      ></SendOutlinedIcon>
    </div>
  );
}

export default ChatBubbleInputs;
