import { Formik } from "formik";
import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import InputPhoneWithCountry from "../../../Components/Input/InputPhoneWithCountry";
import { CoursePaymentContext } from "../../../Services/api/courses/coursePaymentContext";
import {
  addProgramToCart,
  payCourseWithBankTransfer,
} from "../../../Services/api/courses/courseProvider";
import logoTebx from "../../../assets/image/Logo Tebx.png";

function BankTransfer(props) {
  require("./BankTransfer.css");
  const { t } = useTranslation();
  const history = useHistory();
  const coursePaymentContext = useContext(CoursePaymentContext);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [toBeShownImage, setToBeShownImage] = useState(null);
  const [phone, setPhone] = useState("");
  const inputFile = useRef(null);

  const { id, paymentId } = useParams();

  return (
    <>
      <div className="bank-transfer-card">
        <div className="bank-transfer-head">
          <div>
            <i className="fas rotate-icon fa-university back-transfer-icon"></i>
          </div>
          <div className="bank-transfer-label">
            <span>{t("bankTransfer")}</span>
            <span className="bank-transfer-label-small">
              {t("bankTransferModalDescription")}
            </span>
          </div>
        </div>
        <div className="bank-transfer-name">
          <div>
            <div>
              <span>{t("bankName")}:</span> <span>الراجحي</span>
            </div>
            <div>
              <span>{t("ibanNumber")}:</span>{" "}
              <span>SA8980000454608010000000</span>
            </div>
          </div>
        </div>
        <Formik
          initialValues={{
            account_holder_name: "",
            phone_number: "",
            bank_name: "",
            iban_number: "",
            image: "",
          }}
          onSubmit={async (values, { setSubmitting }) => {
            if (hasSubmitted) return;
            try {
              setSubmitting(true);
              setHasSubmitted(true);

              let addToCartResponse = await addProgramToCart(
                paymentId,
                props.paymentEntity.amount
              );
              if (
                addToCartResponse.status === 201 &&
                addToCartResponse.data.status
              ) {
                let formData = new FormData();
                Object.keys(values).forEach((field) => {
                  if (field == "image") {
                    formData.append("payment_proof_img_name", values[field]);
                  } else {
                    formData.append(field, values[field]);
                  }
                });
                formData.append("payment_type", "bank-transfer");
                formData.append("cart_id", addToCartResponse.data.cart.id);
                await payCourseWithBankTransfer(formData);
              }
              history.push(`/table-payment/${id}`);
              toast.success(
                <span style={{ fontSize: 14 }}>
                  {t("dashBoard.label.paymentSuccess")}
                </span>
              );
              coursePaymentContext.setModal(false);
              setSubmitting(false);
              setHasSubmitted(false);
            } catch (err) {
              if (err.response.data && err.response.data.errors) {
                for (const [key, value] of Object.entries(
                  err.response.data.errors
                )) {
                  toast.error(<span style={{ fontSize: 14 }}>{value}</span>);
                }
              }
              setHasSubmitted(false);
            }
          }}
          validate={(values) => {
            const errors = {};
            if (!values.account_holder_name) {
              errors.account_holder_name = t("crud.errors.required");
            }

            if (!values.phone_number) {
              errors.phone_number = t("crud.errors.required");
            }

            if (!values.bank_name) {
              errors.bank_name = t("crud.errors.required");
            }

            if (!values.iban_number) {
              errors.iban_number = t("crud.errors.required");
            }

            if (
              values.iban_number.length < 20 ||
              values.iban_number.length > 34
            ) {
              errors.iban_number = t("dashBoard.label.iban_number");
            }

            if (!values.image) {
              errors.image = t("crud.errors.required");
            }

            return errors;
          }}
        >
          {({
            setFieldValue,
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="bank-transfer-form-input">
                <div className="bank-transfer-width-input">
                  <div className="bank-transfer-flex">
                    <div className="bank-transfer-label-input">
                      <span>{t("accountOwnerName")}</span>
                      <span className="bank-transfer-red-star">*</span>
                    </div>
                    <input
                      className="bank-transfer-input"
                      disabled={hasSubmitted}
                      onChange={handleChange}
                      type={"text"}
                      placeholder={t("crud.placeholders.bank_account_name")}
                      value={values.account_holder_name}
                      name={"account_holder_name"}
                      id={"account_holder_name"}
                    />
                    <p className={"form-input-error-space"}>
                      {errors.account_holder_name
                        ? errors.account_holder_name
                        : null}
                    </p>
                  </div>
                  <div className="bank-transfer-flex">
                    <div className="bank-transfer-label-input">
                      <span>{t("mobileNumber")}</span>
                      <span className="bank-transfer-red-star">*</span>
                    </div>
                    <InputPhoneWithCountry
                      initialValue={phone}
                      setContent={(input) => {
                        setFieldValue("phone_number", input);
                      }}
                      maxLength={15}
                      icon={
                        <i
                          style={{ fontSize: 25, color: "#ccc" }}
                          className="fal fa-mobile"
                        ></i>
                      }
                      withoutSpaceAround={true}
                    />
                    <p className={"form-input-error-space"}>
                      {errors.phone_number ? errors.phone_number : null}
                    </p>
                  </div>
                </div>
              </div>

              <div className="bank-transfer-form-input">
                <div className="bank-transfer-width-input">
                  <div className="bank-transfer-flex">
                    <div className="bank-transfer-label-input">
                      <span>{t("bankName")}</span>
                      <span className="bank-transfer-red-star">*</span>
                    </div>
                    <input
                      className="bank-transfer-input"
                      disabled={hasSubmitted}
                      onChange={handleChange}
                      type={"text"}
                      placeholder={t("crud.placeholders.bank_name")}
                      value={values.bank_name}
                      name={"bank_name"}
                      id={"bank_name"}
                    />
                    <p className={"form-input-error-space"}>
                      {errors.bank_name ? errors.bank_name : null}
                    </p>
                  </div>
                  <div className="bank-transfer-flex">
                    <div className="bank-transfer-label-input">
                      <span>{t("ibanNumber")}</span>
                      <span className="bank-transfer-red-star">*</span>
                    </div>
                    <input
                      className="bank-transfer-input"
                      disabled={hasSubmitted}
                      onChange={handleChange}
                      type={"text"}
                      placeholder={t("crud.placeholders.iban_number")}
                      value={values.iban_number}
                      name={"iban_number"}
                      id={"iban_number"}
                    />
                    <p className={"form-input-error-space"}>
                      {errors.iban_number ? errors.iban_number : null}
                    </p>
                  </div>
                </div>
              </div>

              <div className="bank-transfer-form-input">
                <div className="bank-transfer-card-width">
                  <div className="bank-transfer-label-input">
                    <span>{t("bankTransferPhotoUpload")}</span>
                    <span className="bank-transfer-red-star ">*</span>
                  </div>
                  <div className="bank-transfer-card-upload">
                    <div className="bank-transfer-flex-upload">
                      <div className="bank-transfer-upload">
                        <img
                          className="bank-transfer-upload-img"
                          src={toBeShownImage ? toBeShownImage : logoTebx}
                          className="logo-tebx"
                        />
                        <div
                          className="bank-trasnfer-upload-small-img"
                          onClick={() => {
                            if (!hasSubmitted && inputFile) {
                              inputFile.current.click();
                            }
                          }}
                        >
                          <i
                            className={
                              "fas rotate-icon fa-image bank-transfer-small-image"
                            }
                          ></i>
                        </div>
                        <input
                          id="file"
                          name="file"
                          type="file"
                          ref={inputFile}
                          style={{ display: "none" }}
                          onChange={(event) => {
                            let file = event.target.files[0];
                            let reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onload = () => {
                              setFieldValue("image", event.target.files[0]);
                              setToBeShownImage(reader.result);
                            };
                            reader.onerror = function (error) {
                              
                            };
                          }}
                        />
                        <p className={"form-input-error-space"}>
                          {errors.image ? errors.image : null}
                        </p>
                      </div>
                    </div>
                    <div className="bank-transfer-upload-label">
                      <p>{t("bankTransferPhotoUploadMessage")}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="bank-transfer-form-input"
                style={{
                  marginTop: 50,
                }}
              >
                <div className="bank-transfer-card-width">
                  <button
                    className="bank-transfer-btn"
                    disabled={hasSubmitted}
                    type="submit"
                  >
                    {t("programs.values.pay_now")} :{" "}
                    {props.paymentEntity.amount} {t("Rial")}
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default BankTransfer;
