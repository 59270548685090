import React from 'react';
import {ProgramProvider} from '../../../Services/api/program/programProvider';
import Programs from './programs';

function program() {
  return (
    <ProgramProvider>
      <Programs />
      {/*<AllPrograms/>*/}
    </ProgramProvider>
  );
}

export default program;
