import SearchIcon from "@material-ui/icons/Search";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { NavLink } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { assignTrainers, getTrainers } from "../../../../Services/api/toutorials/assignToCourseProvider";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";

function TrainerList(props) {
  require("./courseList.css");
  const { t } = useTranslation();
  const { id } = useParams();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [popUp, setPopup] = useState(false);
  const [trainers, setTrainers] = useState([]);
  const [newTrainer, setNewTrainer] = useState([]);
  const [newManager, setNewManager] = useState([]);
  const [allTrainer, setAllTrainer] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);

  const getTrainerApi = () => {
    setIsUpdating(true);
    getTrainers(id)
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setTrainers(res.data.response.course.sub_trainers);
          setNewManager(res.data.response.course.trainer);
          setNewTrainer(res.data.response.course.sub_trainers);

          setAllTrainer([
            ...res.data.response.other_trainers,
            ...res.data.response.course.sub_trainers,
            res.data.response.course.trainer,
          ]);

          setLoading(false);
          setIsUpdating(false);
        }
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.msg}</span>);
      });
  };

  useEffect(() => {
    getTrainerApi();
  }, []);

  return (
    <>
      {popUp && (
        <>
          <div
            className="backdrop"
            onClick={() => {
              setPopup(false);
            }}
          ></div>
          <div className="container-modal">
            <div className="assign-modal">
              <div className="tajah-basic-modal-header">
                <div className="header-modal">
                  <div className="">{t("assginCourse.label.assign_course")}</div>
                </div>
                <i
                  className="fas fa-times tajah-modal-dismiss"
                  onClick={() => {
                    setPopup(false);
                  }}
                ></i>
              </div>

              <Formik
                initialValues={{
                  manager_trainer: newManager,
                  trainers: newTrainer,
                }}
                enableReinitialize
                onSubmit={async (values, { setSubmitting }) => {
                  try {
                    setSubmitting(true);
                    setPopup(false);
                    setLoading(true);
                    let payload = {
                      ...values,
                    };

                    payload.trainers = payload.trainers.map((e) => e.id);
                    payload.manager_trainer = payload.manager_trainer.id;

                    let response = await assignTrainers(id, payload);
                    if (response.status === 200 && response.data.status) {
                      getTrainerApi();
                      setLoading(false);
                      setSubmitting(false);
                    } else {
                      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
                    }
                  } catch (err) {
                    toast.error(
                      <span style={{ fontSize: 13, fontWeight: "bold" }}>
                        {err.response.data && err.response.data.errors.manager_trainer[0]}
                      </span>
                    );
                    setLoading(false);
                    setSubmitting(false);
                  }
                }}
                validateOnChange={hasSubmitted}
                validate={(values) => {
                  setHasSubmitted(true);
                  const errors = {};

                  if (values.trainers.length == 0) {
                    errors.trainers = t("crud.errors.required");
                  }

                  if (values.manager_trainer.length == 0) {
                    errors.manager_trainer = t("crud.errors.required");
                  }

                  return errors;
                }}
              >
                {({ errors, handleSubmit, isSubmitting }) => (
                  <form onSubmit={handleSubmit} className="admin_add_form">
                    <div className="body-modal mt-5">
                      <div className="mb-2">{t("assginCourse.label.assign_manager_trainer")}</div>
                      <div>
                        <Select
                          name="user"
                          defaultValue={newManager}
                          options={allTrainer}
                          getOptionLabel={(manager) => manager.name + "      " + "(" + manager.email + ")"}
                          getOptionValue={(manager) => manager.id}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={(manager_trainers) => {
                            setNewManager(manager_trainers);
                          }}
                          placeholder={t("assginCourse.placeholders.manager_trainer_placeholders")}
                        />
                        <p className={"form-input-error-space"}>
                          {errors.manager_trainer ? errors.manager_trainer : null}
                        </p>
                      </div>
                    </div>
                    <div className="body-modal mt-3">
                      <div className="mb-2">{t("assginCourse.label.assign_trainer")}</div>
                      <div>
                        <Select
                          isMulti
                          name="user"
                          defaultValue={newTrainer}
                          options={allTrainer}
                          getOptionLabel={(trainer) => trainer.name + "      " + "(" + trainer.email + ")"}
                          getOptionValue={(trainer) => trainer.id}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={(t) => {
                            if (t.length == 0) {
                              setNewTrainer([]);
                            } else {
                              setNewTrainer(t);
                            }
                          }}
                          placeholder={t("assginCourse.placeholders.trainer_placeholders")}
                        />

                        <p className={"form-input-error-space"}>{errors.trainers ? errors.trainers : null}</p>
                      </div>
                    </div>

                    <div className="btn-assign-action mt-5">
                      <button type="submit" className="btn btn-assign" disabled={isSubmitting}>
                        {t("assginCourse.label.release")}
                      </button>
                      <button
                        className="btn mx-4"
                        onClick={() => {
                          setPopup(false);
                        }}
                      >
                        {t("back")}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </>
      )}
      <div className="container-fluid">
        <div className="admin_flex">
          <div className="admin_label">
            <NavLink to="/admin">{t("admin.label.admin_label")}</NavLink>
            <p style={{ padding: "0 5px" }}>/</p>
            {t("assginCourse.label.assign_course")}
          </div>
        </div>
        {isUpdating ? <SkeletonCardOverlay skeletonWidth="100" /> : <div></div>}

        {loading ? (
          <div
            style={{
              height: "65vh",
            }}
          >
            <SkeletonCard />
          </div>
        ) : (
          <div className="admin_card">
            <div className="table_header">
              <div className="">{t("assginCourse.label.assign_course")}</div>
              <div className="search_input">
                <SearchIcon className="icon" />
                <input
                  id="filter"
                  name="filter"
                  type="text"
                  value={filter}
                  placeholder={t("block.placeholders.name")}
                  onChange={(event) => setFilter(event.target.value)}
                />
              </div>
              <div className="">
                <button
                  className="btn btn-assign"
                  onClick={(e) => {
                    e.stopPropagation();
                    setPopup(true);
                  }}
                >
                  <i
                    className="fa fa-plus"
                    style={{
                      margin: "0 5px",
                      fontSize: "16px",
                    }}
                  ></i>
                  {t("assginCourse.label.add")}
                </button>
              </div>
            </div>
            <div
              className="table-responsive"
              style={{
                width: "90%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">{t("assginCourse.label.img")}</th>
                    <th scope="col">{t("assginCourse.label.name")}</th>
                  </tr>
                </thead>
                <tbody>
                  {trainers &&
                    trainers
                      .filter((f) => f.name.includes(filter) || filter === "")
                      .map((t, index) => (
                        <tr key={t.id}>
                          <td className="trainer-name">{index + 1}</td>
                          <td>
                            <img src={`${t.img}`} alt="" className="trainer-img" />
                          </td>
                          <td className="trainer-name">{t.name}</td>
                        </tr>
                      ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default TrainerList;
