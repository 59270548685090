import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
import { useContext, useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory } from "react-router-dom";
import PasswordInput from "../../../Components/Input/PasswordInput";
import ValidatorInput from "../../../Components/Input/ValidatorInput";
import { SpinnerContext } from "../../../Components/Spinner/SpinnerContext";
import { AuthContext } from "../../../Services/api/auth/AuthContext";
import { register } from "../../../Services/api/auth/AuthProvider";
import { getCountries } from "../../../Services/api/profile/profileProvider";
import { FooterContext } from "../../../Services/api/toutorials/FooterContext";
import i18n from "../../../i18n/i18n";
import { Lang } from "../../../utils";
import CustomButton from "../../Shared/Components/CustomButton/CustomButton";
import Input from "../../Shared/Components/Input/Input";
import Select from "../../Shared/Components/Select/Select";
import Social from "../Social/Social";
import env from "./../../../Services/api/Environment";
import classes from "./register.module.css";

function Register() {
  document.body.dir = i18n.dir();
  const { t } = useTranslation();

  const spinnerContext = useContext(SpinnerContext);
  const footerCtx = useContext(FooterContext);
  const authContext = useContext(AuthContext);

  const [nameEn, setNameEn] = useState("");
  const [nameAr, setNameAr] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setconfirmPasswordError] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [nameEnError, setNameEnError] = useState("");
  const [nameArError, setNameArError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [conPasswordError, setConPasswordError] = useState("");
  const [countries, setCountries] = useState([]);
  const [countriesId, setCountriesId] = useState(0);
  const [countryError, setCountryError] = useState("");
  const [isVerified, setVerified] = useState(false);
  const [isAgree, setIsAgree] = useState(false);
  const [isSSO, setIsSSO] = useState(false);

  /** TODO: */
  const history = useHistory();
  /** */

  useEffect(() => {
    footerCtx.setFooter(false);
    getCountries()
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setCountries(res.data.countries);
        }
      })
      .catch((err) => {});
    return () => {
      footerCtx.setFooter(true);
    };
  }, []);

  const nameEnHandleChange = (event) => {
    setNameEn(event.target.value);
  };

  const nameArHandleChange = (event) => {
    setNameAr(event.target.value);
  };

  const confirmPasswordHandleChange = (confirmPassword) => {
    setConfirmPassword(confirmPassword);
    if (password !== confirmPassword) {
      spinnerContext.setShowSpinner(false);
      authContext.setIsPerformingAuthenticationOperation(false);
      setconfirmPasswordError(t("confirmPasswordError"));
    } else {
      setconfirmPasswordError("");
    }
  };
  function containsIauEmail(email) {
    // Check if the string contains '@iau.edu.sa' at the correct position
    const domain = "@iau.edu.sa";
    return email.includes(domain, email.indexOf("@"));
  }

  const emailHandleChange = (email) => {
    setEmail(email);

    if (containsIauEmail(email)) {
      setIsSSO(true);
    } else {
      setIsSSO(false);
    }
  };

  const passwordHandleChange = (password) => {
    setPassword(password);
    if (checkPasswordValidation(password) !== null) {
      spinnerContext.setShowSpinner(false);
      authContext.setIsPerformingAuthenticationOperation(false);
      setPasswordError(checkPasswordValidation(password));
    } else {
      setPasswordError(null);
    }
  };

  function checkPasswordValidation(pass) {
    if (pass.length === 0) {
      return t("password_validation.empty");
    }

    const isContainsUppercase = /^(?=.*[A-Z])/;
    if (!isContainsUppercase.test(pass)) {
      return t("password_validation.uppercase");
    }

    const isContainsLowercase = /^(?=.*[a-z])/;
    if (!isContainsLowercase.test(pass)) {
      return t("password_validation.lowercase");
    }

    const isContainsNumber = /^(?=.*[0-9])/;
    if (!isContainsNumber.test(pass)) {
      return t("password_validation.digit");
    }

    const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])/;
    if (!isContainsSymbol.test(pass)) {
      return t("password_validation.symbol");
    }

    const isValidLength = /^.{8,16}$/;
    if (!isValidLength.test(pass)) {
      return t("password_validation.long");
    }
    return null;
  }

  function validate() {
    let isValid = true;

    if (!nameEn) {
      isValid = false;
      setNameEnError(t("crud.errors.required"));
    } else if (nameEn.length > 50) {
      setNameEnError(t("crud.errors.maxlength"));
    } else {
      setNameEnError("");
    }

    if (!nameAr) {
      isValid = false;
      setNameArError(t("crud.errors.required"));
    } else if (nameAr.length > 50) {
      setNameArError(t("crud.errors.maxlength"));
    } else {
      setNameArError("");
    }

    if (!email) {
      isValid = false;
      setEmailError(t("crud.errors.required"));
    } else if (email.length > 50) {
      setEmailError(t("crud.errors.maxlength"));
    } else {
      setEmailError("");
    }

    if (!password) {
      isValid = false;
      setPasswordError(t("crud.errors.required"));
    } else {
      setPasswordError("");
    }

    if (!confirmPassword) {
      isValid = false;
      setConPasswordError(t("crud.errors.required"));
    } else {
      setConPasswordError("");
    }

    const checkPassword = checkPasswordValidation(password);
    if (checkPassword !== null) {
      isValid = false;
      setPasswordError(checkPassword);
    } else {
      setPasswordError("");
    }

    if (password !== confirmPassword) {
      isValid = false;
      setconfirmPasswordError(t("confirmPasswordError"));
    } else {
      setconfirmPasswordError("");
    }

    if (email && !validateEmail(email)) {
      isValid = false;
      setEmailError(t("email_format"));
    } else {
      setEmailError("");
    }
    // if (email && true) {
    //   setEmailError(t("you_can_login_directly"));
    // }
    return isValid;
  }

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleSubmit = async (event) => {
    authContext.setIsPerformingAuthenticationOperation(true);
    spinnerContext.setShowSpinner(true);
    event.preventDefault();

    if (validate()) {
      try {
        let response = await register({
          nameEn,
          nameAr,
          email,
          password,
          confirmPassword,
        });
        if (response.status === 201 && response.data.status) {
          spinnerContext.setShowSpinner(false);
          authContext.setIsPerformingAuthenticationOperation(false);
          history.push({
            pathname: "/email-verification",
            state: {
              email: email,
            },
          });
        } else {
          alert("Failure");
        }
      } catch (err) {
        if (err.response.data.errors) {
          setNameArError(err.response.data && err.response.data.errors.name_ar);
          setNameEnError(err.response.data && err.response.data.errors.name_en);
          setEmailError(err.response.data && err.response.data.errors.email);
          setPasswordError(
            err.response.data && err.response.data.errors.password
          );
          setConPasswordError(
            err.response.data && err.response.data.errors.password_confirmation
          );
          // TODO:
          setCountryError(
            err.response.data && err.response.data.errors.country_id
          );
          authContext.setIsPerformingAuthenticationOperation(false);
        } else {
          authContext.setIsPerformingAuthenticationOperation(false);
        }
        // authContext.setIsPerformingAuthenticationOperation(false);
      }
    } else {
      if (!validateEmail(email)) {
        spinnerContext.setShowSpinner(false);
        authContext.setIsPerformingAuthenticationOperation(false);
        setEmailError(t("email_format"));
      } else {
        setEmailError(null);
      }
      if (checkPasswordValidation(password) !== null) {
        spinnerContext.setShowSpinner(false);
        authContext.setIsPerformingAuthenticationOperation(false);
        setPasswordError(checkPasswordValidation(password));
      } else {
        setPasswordError(null);
      }
      if (countriesId === 0) {
        spinnerContext.setShowSpinner(false);
        authContext.setIsPerformingAuthenticationOperation(false);
        setCountryError(t("select_your_country_error"));
      } else {
        setCountryError(null);
      }
    }
    spinnerContext.setShowSpinner(false);
  };

  function handleLoading() {
    setDisabled(true);
    setTimeout(() => {
      setDisabled(false);
    }, 3000);
  }

  let spinnerLoading = "";
  if (disabled === true) {
    spinnerLoading = <div className="lds-dual-ring"></div>;
  } else {
    spinnerLoading = "";
  }

  const verifyCallback = (response) => {
    if (response) {
      setVerified(!isVerified);
    }
  };

  return (
    <div className={classes["auth_forms_reg"]}>
      <div className={classes["auth_home_reg"]}>
        <NavLink to="/">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path d="M575.8 255.5C575.8 273.5 560.8 287.6 543.8 287.6H511.8L512.5 447.7C512.5 450.5 512.3 453.1 512 455.8V472C512 494.1 494.1 512 472 512H456C454.9 512 453.8 511.1 452.7 511.9C451.3 511.1 449.9 512 448.5 512H392C369.9 512 352 494.1 352 472V384C352 366.3 337.7 352 320 352H256C238.3 352 224 366.3 224 384V472C224 494.1 206.1 512 184 512H128.1C126.6 512 125.1 511.9 123.6 511.8C122.4 511.9 121.2 512 120 512H104C81.91 512 64 494.1 64 472V360C64 359.1 64.03 358.1 64.09 357.2V287.6H32.05C14.02 287.6 0 273.5 0 255.5C0 246.5 3.004 238.5 10.01 231.5L266.4 8.016C273.4 1.002 281.4 0 288.4 0C295.4 0 303.4 2.004 309.5 7.014L564.8 231.5C572.8 238.5 576.9 246.5 575.8 255.5L575.8 255.5z" />
          </svg>
        </NavLink>
      </div>
      <div className={classes["auth_form_flex_col"]}>
        <p className={classes["auth_label"]}>{t("RegisteraNewUser")}</p>
        <form
          onSubmit={handleSubmit}
          className={`${classes["auth_form_flex_col"]} ${classes["auth_form_form"]}`}
          method="post"
        >
          <div className={classes["input-name-register"]}>
            <div>
              <Input
                type="input"
                value={nameEn}
                onChange={nameEnHandleChange}
                className={classes["auth_input"]}
                required
                placeholder={t("nameEn")}
                label={t("nameEn")}
              />
              <div
                className="text-danger"
                style={{ fontSize: "13px", height: "10px" }}
              >
                {nameEnError && nameEnError}
              </div>
            </div>
            <div>
              <Input
                type="input"
                value={nameAr}
                onChange={nameArHandleChange}
                className={classes["auth_input"]}
                required
                placeholder={t("nameAr")}
                label={t("nameAr")}
              />
              <div
                className="text-danger"
                style={{ fontSize: "13px", height: "10px" }}
              >
                {nameArError && nameArError}
              </div>
            </div>
          </div>
          <div>
            <ValidatorInput
              setContent={emailHandleChange}
              validationParams="email"
              setRequired={true}
              type="email"
              placeholder={t("Email")}
              label={t("Email")}
            />
            <div
              className="text-danger"
              style={{ fontSize: "13px", height: "10px" }}
            >
              {emailError
                ? emailError
                : isSSO
                ? t("you_can_login_directly")
                : ""}
            </div>
          </div>
          <label htmlFor="password" style={{ margin: 0 }}>
            <PasswordInput
              setContent={passwordHandleChange}
              setRequired={true}
            />
            <div
              className="text-danger"
              style={{ fontSize: "13px", height: "10px" }}
            >
              {passwordError && passwordError}
            </div>
          </label>
          <label htmlFor="confirmPassword" style={{ margin: 0 }}>
            <PasswordInput
              setContent={confirmPasswordHandleChange}
              setRequired={true}
              confirmation
            />
            <div
              className="text-danger"
              style={{ fontSize: "13px", height: "10px" }}
            >
              {confirmPasswordError && confirmPasswordError}
            </div>
          </label>
          <div className={classes["auth_terms"]}>
            <input
              type="checkbox"
              id="terms"
              name="terms"
              required
              onChange={() => setIsAgree(!isAgree)}
              checked={isAgree}
            />
            <label htmlFor="terms">
              {t("IagreeToThe")}{" "}
              <NavLink to="/terms-and-conditions">
                {t("TermsAndConditions")}
              </NavLink>{" "}
              {t("general.and")}{" "}
              <NavLink to="/privacy-policy">{t("PrivacyPolicy")}</NavLink>
            </label>
          </div>
          <ReCAPTCHA
            sitekey={env.siteKey}
            render="explicit"
            onChange={verifyCallback}
          />
          <CustomButton
            value={t("CreateAnAccount")}
            type="submit"
            variant="contained"
            disable={disabled || !isVerified || !isAgree || isSSO}
            loading={authContext.isPerformingAuthenticationOperation}
            colors="#126d77"
            classes="auth_btn"
          />
        </form>
        <Social />
      </div>
    </div>
  );
}

export default Register;
