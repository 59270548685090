import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { Formik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { toServerTime } from "../../../../Services/Timezone";
import { JPEG_TYPE, JPG_TYPE, PNG_TYPE, acceptImages, fromKbToMb } from "../../../../Services/Validator";
import { getListType } from "../../../../Services/api/TypeFile/TypeFileProvider";
import {
  editCourseVisibility,
  getCoursesInfo,
  getProgramDetails,
  getSpecialtiesNew,
} from "../../../../Services/api/courses/courseProvider";
import HomeIcon from "../../../../assets/icons/home.svg";
import { Lang } from "../../../../utils";
import { getLocaleTime } from "../../../../utils/utcToLocal";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import Checkbox from "../../../Shared/Components/Checkbox/Checkbox";
import CustomButton from "../../../Shared/Components/CustomButton/CustomButton";
import DataTable from "../../../Shared/Components/DataTable/DataTable";
import Input from "../../../Shared/Components/Input/Input";
import InputFile from "../../../Shared/Components/InputFile/InputFile";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import MainDatePicker from "../../../Shared/Components/MainDatePicker/MainDatePicker";
import Select from "../../../Shared/Components/Select/Select";
import SelectWithSearch from "../../../Shared/Components/SelectWithSearch/SelectWithSearch.jsx";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import Tags from "../../../Shared/Components/Tags/Tags.jsx";
import classes from "./courseForm.module.css";

const CourseForm = ({
  submitHandler,
  type = "create",
  courseData,
  loading = false,
  isProgram,
  role,
  id,
  programCourses = false,
  programId,
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [allCourses, setAllCourses] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [categories, setCategories] = useState([]);
  const [level, setLevel] = useState([]);
  const [startDateHours, setStartDateHours] = useState(null);
  const [startDateMinutes, setStartDateMinutes] = useState(null);
  const [endDateHours, setEndDateHours] = useState(null);
  const [endDateMinutes, setEndDateMinutes] = useState(null);
  const [startRegistrationHours, setStartRegistrationHours] = useState(null);
  const [startRegistrationMinutes, setStartRegistrationMinutes] = useState(null);
  const [endRegistrationHours, setEndRegistrationHours] = useState(null);
  const [endRegistrationMinutes, setEndRegistrationMinutes] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [listTypeFile, setListTypeFile] = useState([]);
  const [courseDetails, setCourseDetails] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    currentDate();

    if (programCourses) {
      getProgramDetails(programId)
        .then((res) => {
          setCourseDetails(res.data.program);
        })
        .catch((err) => {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
        });
    }

    getCoursesInfo()
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setAllCourses(res.data.data.courses);
          setLevel(res.data.data.levels);
        }
      })
      .catch((err) => {});

    // getSpecialties()
    //   .then((res) => {
    //     if (res.status && res.status === 200 && res.data.status) {
    //       setCategories(res.data.data.specialties.data);
    //     }
    //   })
    //   .catch((err) => {
    //   });

    getSpecialtiesNew()
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setCategories(res.data.data.categories);
          if (courseData?.category_id) {
            setDepartments(
              res.data.data.categories.find((category) => category.id === Number(courseData?.category_id))?.departments
            );
          }
        }
      })
      .catch((err) => {});
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (courseData?.category_id && categories.length > 0) {
      setDepartments(categories.find((category) => category.id === Number(courseData?.category_id))?.departments);
    }
  }, [courseData?.category_id, categories]);

  const currentDate = (datePicker) => {
    let HoursDate = new Date().getHours();
    let MinutesDate = new Date().getMinutes();

    if (datePicker === "startDate") {
      setStartDateHours(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setStartDateMinutes(MinutesDate >= 30 ? 0 : MinutesDate + 10);
    } else if (datePicker === "endDate") {
      setEndDateHours(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setEndDateMinutes(MinutesDate >= 30 ? 0 : MinutesDate + 10);
    } else if (datePicker === "startRegisterDate") {
      setStartRegistrationHours(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setStartRegistrationMinutes(MinutesDate >= 30 ? 0 : MinutesDate + 10);
    } else if (datePicker === "endRegisterDate") {
      setEndRegistrationHours(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setEndRegistrationMinutes(MinutesDate >= 30 ? 0 : MinutesDate + 10);
    } else {
      setStartDateHours(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setStartDateMinutes(MinutesDate >= 30 ? 0 : MinutesDate + 10);
      setEndDateHours(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setEndDateMinutes(MinutesDate >= 30 ? 0 : MinutesDate + 10);
      setStartRegistrationHours(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setStartRegistrationMinutes(MinutesDate >= 30 ? 0 : MinutesDate + 10);
      setEndRegistrationHours(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setEndRegistrationMinutes(MinutesDate >= 30 ? 0 : MinutesDate + 10);
    }
  };

  const formatDate = (d) => {
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const formatTime = (t) => {
    let hours = "" + t.getHours(),
      minutes = "" + t.getMinutes(),
      second = "00";

    hours = hours === "0" ? "00" : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return [hours, minutes, second].join(":");
  };

  const getListTypeFile = () => {
    const myPromise = new Promise((resolve, reject) => {
      getListType().then(
        (res) => {
          setListTypeFile(res?.data?.data);
          resolve();
        },
        (error) => {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("programs.failed_loading")}</span>);
          reject();
        }
      );
    });

    return myPromise;
  };

  useEffect(() => {
    getListTypeFile();
  }, []);

  const getTypeFile = (searchType) => {
    return listTypeFile.filter((file) => file.type === searchType);
  };

  const languageOptions = [
    { id: "ar", value: "ar", label: t("arabic") },
    { id: "en", value: "en", label: t("english") },
  ];

  const categoryOptions = categories.map((category) => ({
    id: category.id,
    value: category.id,
    label: i18n.language === Lang.AR ? category.name_ar : category.name_en,
  }));

  const departmentOptions = departments?.map((department) => ({
    id: department.id,
    value: department.id,
    label: i18n.language === Lang.AR ? department.name_ar : department.name_en,
  }));

  // const courseOptions = allCourses.map((course) => ({
  //   id: course.id,
  //   value: course.id,
  //   label: course.name,
  // }));

  const levelOptions = level.map((level) => ({
    id: level.value,
    value: level.value,
    label: level.text,
  }));

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: `${role === "admin" ? "content_development" : "private_profile"}`,
      page: t(`sidebar.label.${role === "admin" ? "content_development" : "private_profile"}`),
      pagePath: role === "admin" ? "#" : "/private-profile",
    },
    {
      id: "courses",
      page: isProgram ? t("program") : t("courses"),
      pagePath: `/${role}/${
        isProgram
          ? role === "manager"
            ? localStorage.getItem("course_status") === "Closed"
              ? "programs-history"
              : "programs"
            : "program/list"
          : programCourses && role === "manager"
          ? localStorage.getItem("course_status") === "Closed"
            ? `programs-history/program/${id}/courses`
            : `program/${id}/courses`
          : localStorage.getItem("course_status") === "Closed" && role === "manager"
          ? "courses-history"
          : "courses"
      }`,
    },
    {
      id: "course",
      page:
        type === "create"
          ? isProgram
            ? t("trainer.program.add_program")
            : t("trainer.course.add_course")
          : courseData?.name,
      active: true,
    },
  ];

  const coursesFields = [
    {
      id: "id",
      column: ({ rowData }) => <div>{rowData?.id}</div>,
    },
    {
      id: "name",
      column: ({ rowData }) => <div>{rowData?.name}</div>,
    },
    {
      id: "code",
      column: ({ rowData }) => <div>{rowData?.code}</div>,
    },
    {
      id: "run_serial_number",
      column: ({ rowData }) => <div>{rowData?.run_serial_number}</div>,
    },
    {
      id: "start_date",
      column: ({ rowData }) => <div>{moment(rowData?.start_date).format("YYYY-MM-DD")}</div>,
    },
  ];

  return (
    <div className={classes["course"]}>
      <div className="container-fluid">
        <div className={classes["course__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {loading || isLoading ? (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      height: "100vh",
                    }}
                  >
                    <SkeletonCardOverlay />
                  </div>
                </div>
              ) : (
                <MainBox>
                  <Formik
                    initialValues={{
                      name: courseData?.name || "",
                      description: courseData?.description || "",
                      brief_description: courseData?.brief_description || "",
                      image: courseData?.img
                        ? {
                            preview: courseData?.img,
                          }
                        : "",
                      // file: courseData?.file || "",
                      video: courseData?.video || "",
                      mode: courseData?.mode || "Paid",
                      price: courseData?.price || "",
                      number_hours: courseData?.number_hours || "",
                      participators: courseData?.participators || 1,
                      language: courseData?.language || "",
                      level: courseData?.level || "",
                      code: courseData?.code || "",
                      division: courseData?.division || "",
                      prerequisited_course_id: courseData?.prerequisited_course_id || "",
                      category_id: courseData?.category_id || "",
                      section_id: courseData?.section_id || "",
                      tags: courseData?.tags || "",
                      start_date: courseData?.start_date
                        ? new Date(getLocaleTime(new Date(courseData?.start_date)))
                        : setHours(setMinutes(new Date(), startDateMinutes), startDateHours),
                      end_date: courseData?.end_date
                        ? new Date(getLocaleTime(new Date(courseData?.end_date)))
                        : setHours(setMinutes(new Date(), endDateMinutes), endDateHours),
                      start_register_date: courseData?.start_register_date
                        ? new Date(getLocaleTime(new Date(courseData?.start_register_date)))
                        : setHours(setMinutes(new Date(), startRegistrationMinutes), startRegistrationHours),
                      end_register_date: courseData?.end_register_date
                        ? new Date(getLocaleTime(new Date(courseData?.end_register_date)))
                        : setHours(setMinutes(new Date(), endRegistrationMinutes), endRegistrationHours),
                      is_visible: courseData?.is_visible ? true : false,
                      certificate_after_course: courseData?.certificate_after_course ? true : false,
                      accessable_after_close: courseData?.accessable_after_close ? true : false,
                      can_gift: courseData?.can_gift || "",
                      is_scfhsv: courseData?.is_scfhsv ? true : false,
                      status: courseData?.status || "",
                      // is_vat_exempted: courseData?.is_vat_exempted ? true : false,
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                      let data = { ...values };
                      data.is_scfhsv = data.is_scfhsv ? "1" : "0";
                      data.is_visible = data.is_visible ? "1" : "0";
                      // data.is_vat_exempted = data.is_vat_exempted ? "1" : "0";
                      data.certificate_after_course = data.certificate_after_course ? "1" : "0";
                      data.accessable_after_close = data.accessable_after_close ? "1" : "0";
                      data.start_date = toServerTime(formatDate(data.start_date) + " " + formatTime(data.start_date));
                      data.end_date = toServerTime(formatDate(data.end_date) + " " + formatTime(data.end_date));
                      data.start_register_date = toServerTime(
                        formatDate(data.start_register_date) + " " + formatTime(data.start_register_date)
                      );
                      data.end_register_date = toServerTime(
                        formatDate(data.end_register_date) + " " + formatTime(data.end_register_date)
                      );

                      data.image = data.image?.file;
                      isProgram && (data.is_program = 1);

                      if (programCourses) {
                        delete data.status;
                        delete data.mode;
                        delete data.start_register_date;
                        delete data.end_register_date;
                        delete data.price;
                        delete data.participators;
                        delete data.prerequisited_course_id;
                        delete data.division;
                        delete data.is_scfhsv;
                        delete data.certificate_after_course;
                        // delete data.is_vat_exempted;
                        delete data.accessable_after_close;
                      } else if (isProgram) {
                        // delete data.is_vat_exempted;
                      }

                      const formData = new FormData();
                      for (let key in data) {
                        if (!data[key]) continue;
                        if (Array.isArray(data[key])) {
                          data[key].forEach((item) => {
                            formData.append(`${key}[]`, item);
                          });
                        } else {
                          formData.append(key, data[key]);
                        }
                      }
                      if (type === "canEdit" && values?.participators < courseData?.number_of_users) {
                        toast.error(
                          <>
                            <span
                              style={{
                                fontSize: 13,
                                fontWeight: "bold",
                                display: "block",
                                paddingBottom: "10px",
                              }}
                            >
                              {t("number_of_seat_massege")} - {courseData?.number_of_users}
                            </span>
                          </>
                        );
                      } else {
                        await submitHandler(formData);
                      }
                    }}
                    validateOnChange={true}
                    validate={(values) => {
                      const errors = {};

                      if (!values.name) {
                        errors.name = t("crud.errors.required");
                      }
                      if (!programCourses) {
                        if (values.mode === "Paid" && !values.price) {
                          errors.price = t("crud.errors.required");
                        }
                        if (!values.participators) {
                          errors.participators = t("crud.errors.required");
                        }
                        if (values.participators && values.participators <= 0) {
                          errors.participators = t("trainer.course.errors.min_participators");
                        }
                        if (!values.start_register_date) {
                          errors.start_register_date = t("crud.errors.required");
                        }
                        if (!values.end_register_date) {
                          errors.end_register_date = t("crud.errors.required");
                        }
                      }
                      if (!values.number_hours) {
                        errors.number_hours = t("crud.errors.required");
                      }
                      if (values.number_hours && values.number_hours <= 0) {
                        errors.number_hours = t("trainer.course.errors.min_number_hours");
                      }
                      if (values.start_date && values.end_date && values.number_hours) {
                        const days = Math.ceil(
                          Math.abs(new Date(values.start_date) - new Date(values.end_date)) / (1000 * 60 * 60 * 24)
                        );
                        if (values.number_hours > days * 10) {
                          errors.number_hours = t("trainer.course.errors.max_number_hours");
                        }
                      }
                      if (!values.code) {
                        errors.code = t("crud.errors.required");
                      }
                      if (values.code && !values.code.match(/^(?=.*[A-Z])(?=.*[0-9])[A-Z0-9]+$/)) {
                        errors.code = t("trainer.course.errors.code");
                      }
                      if (!values.category_id) {
                        errors.category_id = t("crud.errors.required");
                      }
                      if (!values.section_id) {
                        errors.section_id = t("crud.errors.required");
                      }
                      if (!values.start_date) {
                        errors.start_date = t("crud.errors.required");
                      }
                      if (!values.end_date) {
                        errors.end_date = t("crud.errors.required");
                      }

                      if (values.video && !values.video.startsWith("https://") && !values.video.startsWith("http://")) {
                        errors.video = t("crud.errors.link");
                      }
                      if (values.video && !values.video.includes("youtube.com") && !values.video.includes("youtu.be")) {
                        errors.video = t("crud.errors.youtube_link");
                      }
                      return errors;
                    }}
                  >
                    {({
                      values,
                      errors,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      handleBlur,
                      setFieldValue,
                      validateForm,
                    }) => (
                      <form onSubmit={handleSubmit} className={classes["course__form"]}>
                        {type === "cannotEdit" && (
                          <div className={classes["course__warning__note"]}>{t("trainer.course.cannot_edit")}</div>
                        )}
                        {type === "canEdit" && (
                          <div className={classes["course__warning__note"]}>{t("trainer.course.can_edit")}</div>
                        )}
                        {type === "preview" && (
                          <div className={classes["course__manage-content"]}>
                            <CustomButton
                              type="button"
                              value={t("trainer.course.manage_course_content")}
                              colors="#036c77"
                              action={() => {
                                history.push(`/admin/course/profile/${courseData?.id}`);
                              }}
                            />
                          </div>
                        )}
                        <MainBoxHead
                          title={
                            type === "create"
                              ? isProgram
                                ? t("trainer.program.add_program")
                                : t("trainer.course.add_course")
                              : type === "edit"
                              ? isProgram
                                ? t("trainer.program.edit_program")
                                : t("trainer.course.edit_course")
                              : courseData?.name
                          }
                          actions={
                            !programCourses
                              ? [
                                  {
                                    id: "course-visibility",
                                    type: "switch",
                                    value: true,
                                    active: t("trainer.course.active"),
                                    inactive: t("trainer.course.inactive"),
                                    checked: values.is_visible,
                                    disabled: type === "preview",
                                    onChange: (event) => {
                                      editCourseVisibility(courseData?.id);
                                      setFieldValue("is_visible", event.target.checked);
                                    },
                                  },
                                ]
                              : []
                          }
                        />

                        <div className={`row ${classes["course__form__fields"]}`}>
                          <div className={`${classes["course__form__field"]} col-12`}>
                            <Input
                              label={t(`trainer.${isProgram ? "program" : "course"}.name`)}
                              type="text"
                              name="name"
                              placeholder={t(`trainer.${isProgram ? "program" : "course"}.name`)}
                              onChange={handleChange}
                              error={errors.name}
                              value={values.name}
                              required
                              readonly={type === "preview" || type === "cannotEdit"}
                            />
                          </div>
                          {type !== "create" && !programCourses && (
                            <div className={`${classes["course__form__field"]} col-12 col-md-6`}>
                              <Checkbox
                                name="status"
                                label={t(`trainer.${isProgram ? "program" : "course"}.status`)}
                                onChange={(event) => {
                                  setFieldValue("status", event.target.checked ? "Published" : courseData?.status);
                                }}
                                checked={values.status === "Published"}
                                error={errors.status}
                                disabled={type === "preview" || type === "cannotEdit" || type === "canEdit"}
                              />
                            </div>
                          )}
                          {!programCourses && (
                            <div className={`${classes["course__form__field"]} col-12 col-md-6`}>
                              <Checkbox
                                name="mode"
                                label={t(`trainer.${isProgram ? "program" : "course"}.is_free`)}
                                onChange={(event) => {
                                  setFieldValue("mode", event.target.checked ? "Free" : "Paid");
                                  setFieldValue("price", event.target.checked ? "0.0" : "");
                                }}
                                checked={values.mode === "Free"}
                                error={errors.mode}
                                disabled={
                                  (courseData?.start_date && new Date(courseData?.start_date) < new Date()) ||
                                  type === "preview" ||
                                  type === "cannotEdit" ||
                                  type === "canEdit"
                                }
                              />
                            </div>
                          )}
                          {!programCourses && (
                            <div className={`${classes["course__form__field"]} col-12 col-md-6`}>
                              <Checkbox
                                name="is_scfhsv"
                                label={t(`trainer.${isProgram ? "program" : "course"}.is_scfhsv`)}
                                onChange={(event) => {
                                  setFieldValue("is_scfhsv", event.target.checked);
                                }}
                                checked={values.is_scfhsv}
                                error={errors.is_scfhsv}
                                disabled={type === "preview" || type === "cannotEdit" || type === "canEdit"}
                              />
                            </div>
                          )}
                          {!programCourses && (
                            <div className={`${classes["course__form__field"]} col-12 col-md-6`}>
                              <Checkbox
                                name="certificate_after_course"
                                label={t(`trainer.${isProgram ? "program" : "course"}.certificate_after_course`)}
                                onChange={(event) => {
                                  setFieldValue("certificate_after_course", event.target.checked);
                                }}
                                checked={values.certificate_after_course}
                                error={errors.certificate_after_course}
                                disabled={type === "preview" || type === "cannotEdit"}
                              />
                            </div>
                          )}
                          {!programCourses && (
                            <div className={`${classes["course__form__field"]} col-12 col-md-6`}>
                              <Checkbox
                                name="accessable_after_close"
                                label={t(`trainer.${isProgram ? "program" : "course"}.accessable_after_close`)}
                                onChange={(event) => {
                                  setFieldValue("accessable_after_close", event.target.checked);
                                }}
                                checked={values.accessable_after_close}
                                error={errors.accessable_after_close}
                                disabled={type === "preview" || type === "cannotEdit"}
                              />
                            </div>
                          )}
                          {/* {!programCourses && !isProgram && (
                            <div className={`${classes["course__form__field"]} col-12 col-md-6`}>
                              <Checkbox
                                name="is_vat_exempted"
                                label={t(`trainer.course.is_vat_exempted`)}
                                onChange={(event) => {
                                  setFieldValue("is_vat_exempted", event.target.checked);
                                }}
                                checked={values.is_vat_exempted}
                                error={errors.is_vat_exempted}
                                disabled={type === "preview" || type === "cannotEdit"}
                              />
                            </div>
                          )} */}
                          <div className={`${classes["course__form__field"]} col-12`}>
                            <label htmlFor="brief_description" style={{ color: "#777777" }}>
                              {t("trainer.course.brief_description")}
                            </label>
                            <textarea
                              id="brief_description"
                              name="brief_description"
                              style={{
                                border: "1px solid rgb(201, 200, 200)",
                                display: "block",
                                width: "100%",
                                borderRadius: "6px",
                                padding: "15px",
                              }}
                              maxLength={250}
                              value={values.brief_description}
                              onChange={handleChange}
                              placeholder={t("trainer.course.brief_description")}
                              defaultValue={values.brief_description}
                              disabled={type === "preview" || type === "cannotEdit"}
                            />
                          </div>
                          <div className={`${classes["course__form__field"]} col-12`}>
                            <label htmlFor="description" style={{ color: "#777777" }}>
                              {t(`trainer.${isProgram ? "program" : "course"}.description`)}
                            </label>

                            <SunEditor
                              placeholder={t(`trainer.${isProgram ? "program" : "course"}.description`)}
                              value={values.description}
                              defaultValue={values.description}
                              onChange={(e) => {
                                handleChange({
                                  target: { name: "description", value: e },
                                });
                              }}
                              setOptions={{
                                height: 150,
                                rtl: i18n.language === "ar" ? true : false,
                                buttonList: [
                                  [
                                    "bold",
                                    "underline",
                                    "italic",
                                    "strike",
                                    "align",
                                    "horizontalRule",
                                    "list",
                                    "lineHeight",

                                    "codeView",
                                  ],
                                ],
                              }}
                              setDefaultStyle="font-family: 'Montserrat', sans-serif; font-size: 16px;"
                              disable={type === "preview" || type === "cannotEdit" || type === "canEdit"}
                            />
                          </div>
                          <div className={`${classes["course__form__field"]} col-12`}>
                            <InputFile
                              id="image"
                              name="image"
                              type="gallery"
                              placeholder={""}
                              setFieldValue={setFieldValue}
                              accept={acceptImages()}
                              label={t(`trainer.${isProgram ? "program" : "course"}.image`)}
                              maxSize={`${t("trainer.course.upload_image")} ${fromKbToMb(
                                Math.max(
                                  getTypeFile(PNG_TYPE)[0]?.max_size,
                                  getTypeFile(JPEG_TYPE)[0]?.max_size,
                                  getTypeFile(JPG_TYPE)[0]?.max_size
                                )
                              )} ${t("booth.megabyte")}`}
                              error={errors?.image}
                              value={values.image}
                              note={`${t("trainer.course.upload_image")} ${fromKbToMb(
                                Math.max(
                                  getTypeFile(PNG_TYPE)[0]?.max_size,
                                  getTypeFile(JPEG_TYPE)[0]?.max_size,
                                  getTypeFile(JPG_TYPE)[0]?.max_size
                                )
                              )} ${t("booth.megabyte")}`}
                              disabled={type === "preview" || type === "cannotEdit" || type === "canEdit"}
                            />
                          </div>
                          <div className={`${classes["course__form__field"]} col-12`}>
                            <Input
                              label={t(`trainer.${isProgram ? "program" : "course"}.video`)}
                              type="text"
                              name="video"
                              placeholder={t(`trainer.${isProgram ? "program" : "course"}.video`)}
                              onChange={handleChange}
                              error={errors.video}
                              value={values.video}
                              note={t("trainer.course.video_note")}
                              readonly={type === "preview" || type === "cannotEdit" || type === "canEdit"}
                            />
                          </div>
                          <div className={`${classes["course__form__field"]} col-12 col-md-6`}>
                            <MainDatePicker
                              label={t(`trainer.${isProgram ? "program" : "course"}.start_date`)}
                              name="start_date"
                              error={errors.start_date}
                              value={values.start_date}
                              onChange={(date) => {
                                setFieldValue("start_date", date);
                                values.end_date < date && setFieldValue("end_date", date);
                                let CurrentDate = new Date();
                                let CurrentDateFormate = CurrentDate.toISOString().split("T")[0];
                                let DataPickerValue = date.toISOString().split("T")[0];
                                if (new Date(DataPickerValue).getTime() > new Date(CurrentDateFormate).getTime()) {
                                  setStartDateHours(0);
                                  setStartDateMinutes(0);
                                } else {
                                  currentDate("startDate");
                                }
                              }}
                              // minDate={new Date()}
                              minDate={
                                programCourses ? Math.max(new Date(), new Date(courseDetails?.start_date)) : new Date()
                              }
                              minTime={setHours(setMinutes(new Date(), startDateMinutes), startDateHours)}
                              maxTime={setHours(setMinutes(new Date(), 30), 23)}
                              maxDate={programCourses ? new Date(courseDetails?.end_date) : null}
                              readOnly={type !== "create"}
                              required
                            />
                          </div>
                          <div className={`${classes["course__form__field"]} col-12 col-md-6`}>
                            <MainDatePicker
                              label={t(`trainer.${isProgram ? "program" : "course"}.end_date`)}
                              name="end_date"
                              error={errors.end_date}
                              value={values.end_date}
                              onChange={(date) => {
                                setFieldValue("end_date", date);

                                let CurrentDate = new Date();
                                let CurrentDateFormate = CurrentDate.toISOString().split("T")[0];
                                let DataPickerValue = date.toISOString().split("T")[0];
                                if (new Date(DataPickerValue).getTime() > new Date(CurrentDateFormate).getTime()) {
                                  setEndDateHours(0);
                                  setEndDateMinutes(0);
                                } else {
                                  currentDate("endDate");
                                }
                              }}
                              minDate={new Date(values.start_date)}
                              minTime={setHours(setMinutes(new Date(), startDateMinutes), startDateHours)}
                              maxTime={setHours(setMinutes(new Date(), 30), 23)}
                              maxDate={programCourses ? new Date(courseDetails?.end_date) : null}
                              readOnly={type === "preview" || type === "cannotEdit"}
                              required
                            />
                          </div>
                          {!programCourses && (
                            <div className={`${classes["course__form__field"]} col-12 col-md-6`}>
                              <MainDatePicker
                                label={t("trainer.course.start_register_date")}
                                name="start_register_date"
                                error={errors.start_register_date}
                                value={values.start_register_date}
                                onChange={(date) => {
                                  setFieldValue("start_register_date", date);
                                  values.end_register_date < date && setFieldValue("end_register_date", date);

                                  let CurrentDate = new Date();
                                  let CurrentDateFormate = CurrentDate.toISOString().split("T")[0];
                                  let DataPickerValue = date.toISOString().split("T")[0];
                                  if (new Date(DataPickerValue).getTime() > new Date(CurrentDateFormate).getTime()) {
                                    setStartDateHours(0);
                                    setStartDateMinutes(0);
                                  } else {
                                    currentDate("startRegisterDate");
                                  }
                                }}
                                minDate={new Date()}
                                minTime={setHours(setMinutes(new Date(), startDateMinutes), startDateHours)}
                                maxTime={setHours(setMinutes(new Date(), 30), 23)}
                                readOnly={type === "preview" || type === "cannotEdit" || type === "canEdit"}
                                required
                              />
                            </div>
                          )}
                          {!programCourses && (
                            <div className={`${classes["course__form__field"]} col-12 col-md-6`}>
                              <MainDatePicker
                                label={t("trainer.course.end_register_date")}
                                name="end_register_date"
                                error={errors.end_register_date}
                                value={values.end_register_date}
                                onChange={(date) => {
                                  setFieldValue("end_register_date", date);
                                  values.end_date < date && setFieldValue("end_date", date);

                                  let CurrentDate = new Date();
                                  let CurrentDateFormate = CurrentDate.toISOString().split("T")[0];
                                  let DataPickerValue = date.toISOString().split("T")[0];
                                  if (new Date(DataPickerValue).getTime() > new Date(CurrentDateFormate).getTime()) {
                                    setStartDateHours(0);
                                    setStartDateMinutes(0);
                                  } else {
                                    currentDate("endRegisterDate");
                                  }
                                }}
                                minDate={new Date(values.start_register_date)}
                                minTime={setHours(setMinutes(new Date(), startDateMinutes), startDateHours)}
                                maxTime={setHours(setMinutes(new Date(), 30), 23)}
                                maxDate={new Date(values.end_date)}
                                readOnly={type === "preview" || type === "cannotEdit"}
                                required
                              />
                            </div>
                          )}
                          {!programCourses && (
                            <div className={`${classes["course__form__field"]} col-12 col-md-6`}>
                              <Input
                                label={t(`trainer.${isProgram ? "program" : "course"}.price`)}
                                type="number"
                                name="price"
                                placeholder={t(`trainer.${isProgram ? "program" : "course"}.price`)}
                                onChange={handleChange}
                                error={errors.price}
                                value={values.price}
                                readonly={
                                  values.mode !== "Paid" ||
                                  type === "preview" ||
                                  type === "cannotEdit" ||
                                  type === "canEdit"
                                }
                                required={values.mode === "Paid"}
                              />
                            </div>
                          )}

                          <div className={`${classes["course__form__field"]} col-12 col-md-6`}>
                            <Input
                              label={t(`trainer.${isProgram ? "program" : "course"}.number_hours`)}
                              type="number"
                              name="number_hours"
                              placeholder={t(`trainer.${isProgram ? "program" : "course"}.number_hours`)}
                              onChange={handleChange}
                              error={errors.number_hours}
                              value={values.number_hours}
                              required
                              readonly={type === "preview" || type === "cannotEdit"}
                            />
                          </div>
                          {!programCourses && (
                            <div className={`${classes["course__form__field"]} col-12`}>
                              <Input
                                label={t("trainer.course.participators")}
                                type="number"
                                name="participators"
                                placeholder={t("trainer.course.participators")}
                                onChange={handleChange}
                                error={errors.participators}
                                value={values.participators}
                                required
                                readonly={type === "preview" || type === "cannotEdit"}
                              />
                            </div>
                          )}
                          <div className={`${classes["course__form__field"]} col-12 col-md-6`}>
                            <Select
                              label={t(`trainer.${isProgram ? "program" : "course"}.language`)}
                              name="language"
                              onchange={handleChange}
                              options={languageOptions}
                              initialValue={t(`trainer.${isProgram ? "program" : "course"}.language`)}
                              error={errors.language}
                              value={values.language}
                              disabled={type === "preview" || type === "cannotEdit" || type === "canEdit"}
                            />
                          </div>
                          <div className={`${classes["course__form__field"]} col-12 col-md-6`}>
                            <Select
                              label={t(`trainer.${isProgram ? "program" : "course"}.level`)}
                              name="level"
                              onchange={handleChange}
                              options={levelOptions}
                              initialValue={t(`trainer.${isProgram ? "program" : "course"}.level`)}
                              error={errors.level}
                              value={values.level}
                              disabled={type === "preview" || type === "cannotEdit" || type === "canEdit"}
                            />
                          </div>
                          <div className={`${classes["course__form__field"]} col-12 col-md-6`}>
                            <Input
                              label={t(`trainer.${isProgram ? "program" : "course"}.code`)}
                              type="text"
                              name="code"
                              placeholder={t(`trainer.${isProgram ? "program" : "course"}.code`)}
                              onChange={handleChange}
                              error={errors.code}
                              value={values.code}
                              required
                              readonly={type !== "create"}
                            />
                          </div>
                          {!programCourses && (
                            <div className={`${classes["course__form__field"]} col-12 col-md-6`}>
                              <Input
                                label={t(`trainer.${isProgram ? "program" : "course"}.division`)}
                                type="text"
                                name="division"
                                placeholder={t(`trainer.${isProgram ? "program" : "course"}.division`)}
                                onChange={handleChange}
                                error={errors.division}
                                value={values.division}
                                readonly={type === "preview" || type === "cannotEdit" || type === "canEdit"}
                              />
                            </div>
                          )}
                          <div className={`${classes["course__form__field"]} col-12`}>
                            <Tags
                              label={t(`trainer.${isProgram ? "program" : "course"}.tags`)}
                              name="tags"
                              placeholder={t(`trainer.${isProgram ? "program" : "course"}.tags`)}
                              setFieldValue={setFieldValue}
                              value={values.tags}
                              error={errors.tags}
                              disabled={type === "preview" || type === "cannotEdit" || type === "canEdit"}
                            />
                          </div>
                          <div className={`${classes["course__form__field"]} col-12 col-md-6`}>
                            <SelectWithSearch
                              label={t("trainer.course.category")}
                              name="category_id"
                              onchange={(value) => {
                                setFieldValue("category_id", value.value);
                                setFieldValue("section_id", "");
                                setDepartments(
                                  categories
                                    .filter((item) => item.id === Number(value.value))[0]
                                    .departments.filter((department) => department.status === true)
                                );
                              }}
                              options={categoryOptions}
                              value={{
                                id: values.category_id,
                                value: values.category_id,
                                label:
                                  i18n.language === Lang.AR
                                    ? categories.filter((category) => category.id === Number(values.category_id))[0]
                                        ?.name_ar
                                    : categories.filter((category) => category.id === Number(values.category_id))[0]
                                        ?.name_en,
                              }}
                              error={errors.category_id}
                              initialValue={t("trainer.course.category")}
                              disabled={type === "preview" || type === "cannotEdit" || type === "canEdit"}
                              required
                            />
                          </div>
                          <div className={`${classes["course__form__field"]} col-12 col-md-6`}>
                            <SelectWithSearch
                              label={t("trainer.course.section")}
                              name="section_id"
                              onchange={(value) => {
                                setFieldValue("section_id", value.value);
                              }}
                              options={departmentOptions}
                              value={{
                                id: values.section_id,
                                value: values.section_id,
                                label:
                                  i18n.language === Lang.AR
                                    ? departments?.filter(
                                        (department) => department.id === Number(values.section_id)
                                      )[0]?.name_ar
                                    : departments.filter((department) => department.id === Number(values.section_id))[0]
                                        ?.name_en,
                              }}
                              error={errors.section_id}
                              initialValue={t("trainer.course.section")}
                              disabled={type === "preview" || type === "cannotEdit" || type === "canEdit"}
                              required
                            />
                          </div>
                          {!programCourses && (
                            <div className={`${classes["course__form__field"]} col-12`}>
                              {/* <Select
                                label={t("trainer.course.prerequisited")}
                                name="prerequisited_course_id"
                                onchange={handleChange}
                                options={courseOptions}
                                initialValue={t("trainer.course.prerequisited")}
                                error={errors.prerequisited_course_id}
                                value={values.prerequisited_course_id}
                                disabled={type === "preview" || type === "cannotEdit"}
                              /> */}
                              <DataTable
                                label={t("trainer.course.prerequisited")}
                                title={t("trainer.course.prerequisited")}
                                isMultiple={false}
                                selectedItems={[values.prerequisited_course_id]}
                                selectedHandler={(item) => {
                                  setFieldValue("prerequisited_course_id", item.id);
                                }}
                                data={allCourses.toReversed()}
                                fields={coursesFields}
                                error={errors.prerequisited_course_id}
                                searchableFields={["name", "code", "run_serial_number"]}
                              />
                            </div>
                          )}
                          {type !== "preview" && (
                            <div className={`${classes["course__form__submit__container"]} col-12`}>
                              <CustomButton
                                type="submit"
                                colors="#036c77"
                                value={
                                  type === "create"
                                    ? isProgram
                                      ? t("trainer.program.add_program_button")
                                      : t("trainer.course.add_course_button")
                                    : isProgram
                                    ? t("trainer.program.edit_program_button")
                                    : t("trainer.course.edit_course_button")
                                }
                                disable={isSubmitting || type === "cannotEdit"}
                                loading={isSubmitting}
                              />
                            </div>
                          )}
                        </div>
                        {isSubmitting && <SkeletonCardOverlay />}
                      </form>
                    )}
                  </Formik>
                </MainBox>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseForm;
