import React from "react";
import { useTranslation } from "react-i18next";
import classes from "./bootDetails.module.css";
const BoothStatus = ({ sponserStatus, paymentStatus, rejectReason, editStatus }) => {
  const { t, i18n } = useTranslation();

  const displaySponsorStatus = () => {
    if (sponserStatus === 1) {
      return t("Exhibition.waiting_for_approval_of_the_reservation");
    } else if (sponserStatus === 2 && paymentStatus === 0) {
      return t("Exhibition.reservation_approved");
    } else if (sponserStatus === 3) {
      return t("Exhibition.reservation_declined");
    } else if (sponserStatus === 2 && paymentStatus === 1) {
      return t("Exhibition.waiting_for_approval_of_the_payment");
    } else if (sponserStatus === 2 && paymentStatus === 2) {
      return t("Exhibition.payment_approved");
    } else if (sponserStatus === 2 && paymentStatus === 3) {
      return t("Exhibition.payment_declined");
    } else if (sponserStatus === 4) {
      return t("Exhibition.reservation_canceled_by_admin");
    }
  };

  const displayEditStatus = () => {
    if (editStatus === 5) {
      return t("booth.edit_waiting");
    } else if (editStatus === 6) {
      return t("booth.edit_approve");
    } else if (editStatus === 7) {
      return t("booth.edit_reject");
    } else if (editStatus === 8) {
      return t("booth.edit_by_admin");
    }
  };

  const getSponsorStatusColor = () => {
    if (sponserStatus === 1) return "#C8C8C8";
    else if (sponserStatus === 2 && paymentStatus === 0) return "#56B1B7";
    else if (sponserStatus === 3) return "#DF4B43";
    else if (sponserStatus === 2 && paymentStatus === 1) return "#C8C8C8";
    else if (sponserStatus === 2 && paymentStatus === 2) return "#AF8BBE";
    else if (sponserStatus === 2 && paymentStatus === 3) return "#DF4B43";
    else if (sponserStatus === 4) return "#DF4B43";
  };

  const getEditStatusColor = () => {
    if (editStatus === 5) return "#B5B845";
    else if (editStatus === 6) return "#56B1B7";
    else if (editStatus === 7) return "#DF4B43";
    else if (editStatus === 8) return "#AF8BBE";
  };

  return (
    <div className={classes["booth-status"]}>
      {sponserStatus !== null || sponserStatus !== 0 ? (
        <div className={classes["booth-status-container"]}>
          {t("booth.booth_order_status")}:
          <div className={`${classes["booth-status-box"]}`} style={{ backgroundColor: getSponsorStatusColor() }}>
            {displaySponsorStatus()}
          </div>
        </div>
      ) : null}
      {editStatus && Number(editStatus) !== 0 ? (
        <div className={classes["booth-status-container"]}>
          {t("booth.booth_edit_status")}:
          <div className={`${classes["booth-status-box"]}`} style={{ backgroundColor: getEditStatusColor() }}>
            {displayEditStatus()}
          </div>
        </div>
      ) : null}
      {sponserStatus === 3 ? (
        <div className={classes["booth-status-reason"]}>
          {t("booth.reject_reason")}: {rejectReason || t("booth.no_reason_provided")}
        </div>
      ) : null}
    </div>
  );
};

export default BoothStatus;
