import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import clearEmptyProp from "../../../../Helpers/ClearEmptyProp";
import DeleteModal from "../../../../Modals/DeleteModal/DeleteModal";
import DisableModal from "../../../../Modals/DeleteModal/DisableModal";
import TraineesModal from "../../../../Modals/surveyModal/TraineesModal";
import { getCourseDetails } from "../../../../Services/api/courses/courseProvider";
import {
  deleteSurvey,
  exportSurvies,
  getAllCourses,
  getAllUsers,
  getExhibitionsSurvies,
  getLessonById,
  getSurveyResponse,
  getSurvies,
  toggleSurveyAccessibility,
} from "../../../../Services/api/survey/SurveyProvider";
import { BasicModal } from "../../../Shared/Components/Modal/BasicModal";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";

import { Chip } from "@material-ui/core";
import { Pagination } from "@mui/material";
import moment from "moment";
import { IconsTooltip } from "../../../../Components";
import toLocalTimezone, { toServerTime } from "../../../../Services/Timezone";
import analysis from "../../../../assets/icons/analyze.svg";
import ViewBoothListIcon from "../../../../assets/icons/boothList.svg";
import deleteIcon from "../../../../assets/icons/delete.svg";
import ViewExhDetailsIcon from "../../../../assets/icons/eye.svg";
import FilterIcon from "../../../../assets/icons/filter.svg";
import HomeIcon from "../../../../assets/icons/home.svg";
import PdfIcon from "../../../../assets/icons/pdf.svg";
import editExhIcon from "../../../../assets/icons/pen.svg";
import AddIcon from "../../../../assets/icons/plus.svg";
import ViewSponsorListIcon from "../../../../assets/icons/sponsorList.svg";
import user from "../../../../assets/icons/user.svg";
import DeleteIcon from "../../../../assets/icons/warning.svg";
import XlsIcon from "../../../../assets/icons/xls.svg";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import FilterBox from "../../../Shared/Components/FilterBox/FilterBox";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import SharedModal from "../../../Shared/Components/SharedModal/SharedModal";
import SwitchToggle from "../../../Shared/Components/SwitchToggle/SwitchToggle";
import classes from "./exhibitionList.module.css";
import { utcToLocal } from "../../../../utils/utcToLocal";

function SurveyList({ fromTrainer = false, role, isAll }) {
  require("./list.css");

  const { t } = useTranslation();
  const { targetCourseId, targetLessonId } = useParams();

  const history = useHistory();
  const traineesModalRef = useRef();
  const modalHandler = () => deleteModalRef.current.dismissModal();
  const deleteModalRef = useRef();

  const [isLoading, setIsLoading] = useState(true);
  const [meta, setMeta] = useState({});
  const [metaTrainees, setMetaTrainees] = useState({});
  const [courses, setCourses] = useState([]);
  const [lessons, setlessons] = useState([]);
  const [trainers, setTrainers] = useState([]);
  const [surveyResponse, setSurveyResonse] = useState([]);
  const [showTrainees, setShowTrainees] = useState(false);
  const [surveyId, setSurveyId] = useState("");
  const [surveyIndex, setSurveyIndex] = useState("");
  const [typeContentModal, setTypeContentModal] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [targetCourse, setTargetCourse] = useState({});

  const [survies, setSurvies] = useState([]);
  const [filter, setFilter] = useState({});
  const [surveyFilter, setSurveyFilter] = useState({});
  const [currentSurveyFilter, setCurrentSurveyFilter] = useState({});

  const [survey, setSurvey] = useState({});
  const [showAsseccibilityModal] = useState(false);
  const [isDisabled] = useState(false);

  const [sortColumns, setSortColumns] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [iseDeleteLoading] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [exhibitions, setExhibitions] = useState([]);
  const [fetchFilterData, setFetchFilterDate] = useState(false);

  function getSurveyFilter() {
    let filter = { ...surveyFilter };

    if (filter.user_type != undefined && filter.user_type != null) {
      switch (filter.user_type) {
        case "trainer":
          filter = { ...filter, roles: ["trainer"] };
          break;
        case "trainee":
          filter = { ...filter, roles: ["trainee"] };
          break;
        case "trainerAndTrainee":
          filter = { ...filter, roles: ["trainer", "trainee"] };
          break;
        default:
          filter = { ...filter, roles: [] };
      }
    }

    if (filter.responses_count != undefined && filter.responses_count != null) {
      const parseValue = JSON.parse(filter.responses_count);
      filter = {
        ...filter,
        min_responses: parseValue.min,
        max_responses: parseValue.max,
      };
    }

    if (filter.start_time != undefined && filter.start_time != null) {
      filter = {
        ...filter,
        start_time: toServerTime(filter.start_time),
      };
    }

    if (filter.start_time_to != undefined && filter.start_time_to != null) {
      filter = {
        ...filter,
        start_time_to: toServerTime(filter.start_time_to),
      };
    }

    if (filter.user_type != undefined && filter.user_type != null) {
      filter.is_public =
        filter.user_type == "all"
          ? 0
          : filter.user_type == "all_guest"
          ? 1
          : null;
    }
    if (fromTrainer) filter.from_trainers = true;
    return filter;
  }

  const filterChangeHandler = (e) => {
    const { name, value } = e.target;
    let filter = { ...surveyFilter, [name]: value };
    if (name == "type") {
      filter = { ...filter, exhibition_id: "", course_id: "", lesson_id: "" };
    }
    setSurveyFilter(filter);
  };

  const actionsList = [
    {
      id: "filter",
      icon: <img src={FilterIcon} alt="" />,
      action: () => {
        setFetchFilterDate(true);
        if (!fetchFilterData) {
          fetchDataForFilter();
        }
        setIsFilter((val) => !val);
      },
    },
    ...(fromTrainer
      ? []
      : [
          {
            id: "add",
            icon: <img src={AddIcon} alt="" />,
            theme: "add",
            action: () => {
              if (targetCourseId)
                history.push(
                  `/${role}/course/${targetCourseId}/survey${
                    fromTrainer ? "/trainer" : ""
                  }/add`
                );
              else
                history.push(
                  `/${role}/survey${fromTrainer ? "/trainer" : ""}/add`
                );
            },
          },
        ]),
    {
      id: "pdf",
      icon: <img src={PdfIcon} alt="" />,
      theme: "pdf",
      action: () => {
        setIsUpdating(true);
        exportSurvies("pdf", currentSurveyFilter)
          .then((res) => {
            if (res.status && res.status === 200) {
              setIsUpdating(false);
              toast.success(
                <span style={{ fontSize: 13, fontWeight: "bold" }}>
                  {t("export_success")}
                </span>
              );
            } else {
              throw res;
            }
          })
          .catch((err) => {
            setIsUpdating(false);
            toast.error(
              <span style={{ fontSize: 13, fontWeight: "bold" }}>
                {t("export_faild")}
              </span>
            );
          });
      },
    },
    {
      id: "xls",
      icon: <img src={XlsIcon} alt="" />,
      theme: "xls",
      action: () => {
        setIsUpdating(true);
        exportSurvies("xlsx", currentSurveyFilter)
          .then((res) => {
            if (res.status && res.status === 200) {
              setIsUpdating(false);
              toast.success(
                <span style={{ fontSize: 13, fontWeight: "bold" }}>
                  {t("export_success")}
                </span>
              );
            } else {
              throw res;
            }
          })
          .catch((err) => {
            setIsUpdating(false);
            toast.error(
              <span style={{ fontSize: 13, fontWeight: "bold" }}>
                {t("export_faild")}
              </span>
            );
          });
      },
    },
  ];

  const surveyActionsList = [
    {
      id: "view-booths",
      icon: <img src={user} alt="" />,
      title: t("participants"),
      action: (id) => {
        isAll
          ? history.push(
              `/${role}/survey/list${
                fromTrainer ? "/trainer" : ""
              }/${id}/participants`
            )
          : history.push(
              `/${role}/survey${
                fromTrainer ? "/trainer" : ""
              }/${id}/participants`
            );
      },
    },
    {
      id: "view-sponsors",
      icon: <img src={analysis} alt="" />,
      title: t("sidebar.report.title"),
      action: (id) => {
        isAll
          ? history.push(
              `/${role}/survey/list${
                fromTrainer ? "/trainer" : ""
              }/${id}/questions`
            )
          : history.push(
              `/${role}/survey${fromTrainer ? "/trainer" : ""}/${id}/questions`
            );
      },
    },
    {
      id: "view-details",
      icon: <img src={ViewExhDetailsIcon} alt="" />,
      title: t("preview"),
      action: (id) => {
        if (targetCourseId)
          history.push(
            `/${role}/course/${targetCourseId}/survey${
              fromTrainer ? "/trainer" : ""
            }/preview/${id}`
          );
        else history.push(`/${role}/survey/preview/${id}`);
      },
    },
    {
      id: "edit",
      icon: <img src={editExhIcon} alt="" />,
      title: t("edit"),
      action: (id) => {
        if (targetCourseId)
          history.push(
            `/${role}/course/${targetCourseId}/survey${
              fromTrainer ? "/trainer" : ""
            }/edit/${id}`
          );
        else history.push(`/${role}/survey/edit/${id}`);
      },
    },
    {
      id: "delete",
      icon: <img src={deleteIcon} alt="" />,
      title: t("delete"),
      action: (id, index = null) => {
        const selectedSurvey = survies.find((survey) => survey.id == id);
        if (!selectedSurvey) return;
        setSurvey(selectedSurvey);
        setSurveyIndex(index);
        setShowDeleteModal(true);
      },
    },
  ];

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => (
        <>{rowIndex + 1 + (meta.currentPage - 1) * 10}</>
      ),
    },
    {
      id: "name",
      name: t("survey.nameHeader"),
      key: "name",
      sortable: true,
      cell: ({ rowData }) => (
        <div className="cell-font tw-w-36 text2lines">{rowData.name}</div>
      ),
    },
    {
      id: "type",
      name: t("survey.type"),
      key: "name",
      cell: ({ rowData }) => (
        <span className="cell-font">{getSurveyType(rowData)}</span>
      ),
    },
    fromTrainer
      ? {
          id: "trainer",
          name: t("survey.trainer_name"),
          key: "trainer",
          cell: ({ rowData }) => (
            <div className="cell-font tw-max-w-[36rem] text2lines">
              {rowData.user?.name}
            </div>
          ),
        }
      : {
          id: "roles",
          name: t("survey.roles"),
          key: "roles",
          cell: ({ rowData }) => (
            <div
              style={{
                color: "#066d77",
              }}
              className="cell-font tw-whitespace-pre-wrap tw-w-28"
            >
              {rowData.roles.length
                ? rowData.roles.map((role, index) => {
                    if (index < rowData.roles.length - 1) {
                      return t(`selection.role.${role.name}`) + " | ";
                    } else {
                      return t(`selection.role.${role.name}`);
                    }
                  })
                : rowData.is_public
                ? t(`selection.role.all_guest`)
                : t(`selection.role.all`)}
            </div>
          ),
        },
    {
      id: "noOfParticipants",
      name: t("survey.sharedNumber"),
      key: "noOfParticipants",
      // sortable: true,
      cell: ({ rowData }) => <>{rowData.responses_count}</>,
    },
    {
      id: "noOfQuestions",
      name: t("survey.numberQuestions"),
      key: "noOfQuestions",
      // sortable: true,
      cell: ({ rowData }) => <>{rowData.questions_count}</>,
    },
    {
      id: "startDate",
      name: t("Exhibition.start_exh_date"),
      key: "startDate",
      sortable: true,
      cell: ({ rowData }) => (
        <>
          {rowData.lesson_id || rowData.course_id ? (
            <div className="cell-font tw-w-36 text2lines">
              {t(
                rowData.dependency_type
                  ? `times.${rowData.dependency_type}`
                  : "undefined"
              )}
            </div>
          ) : rowData.exhibition_id ? (
            <div className="cell-font tw-w-36 text2lines">
              {t("times.exhibition_start_time")}
            </div>
          ) : rowData.start_time ? (
            <>
              <div>{moment(rowData.start_time).format("DD/MM/YYYY")}</div>
              <div>{utcToLocal(rowData.start_time)}</div>
            </>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      id: "endDate",
      name: t("Exhibition.end_exh_date"),
      key: "endDate",
      sortable: true,
      cell: ({ rowData }) => (
        <>
          {rowData.lesson_id || rowData.course_id ? (
            <div className={"cell-font tw-w-36 text2lines"}>
              {t("times.course_end_time")}
            </div>
          ) : rowData.exhibition_id ? (
            <div className="cell-font tw-w-36 text2lines">
              {t("times.exhibition_end_time")}
            </div>
          ) : rowData.end_time ? (
            <>
              <div>{moment(rowData.end_time).format("DD/MM/YYYY")}</div>
              <div>{utcToLocal(rowData.end_time)}</div>
            </>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      id: "actions",
      name: t("Exhibition.actions"),
      key: "actions",
      cell: ({ rowData, rowIndex }) => (
        <div className={classes["exhibition_list__actions"]}>
          {surveyActionsList.map((action, index) => (
            <IconsTooltip
              title={action.title}
              content={
                <button
                  key={action.id}
                  className={classes["exhibition_list__actions-btn"]}
                  onClick={() => {
                    action.action(rowData.id, rowIndex);
                  }}
                >
                  {action.icon}
                </button>
              }
            />
          ))}
        </div>
      ),
    },
    {
      id: "status",
      name: t("Exhibition.status_exh"),
      key: "status",

      cell: ({ rowData, rowIndex }) => (
        <SwitchToggle
          key={rowData.id}
          status={!rowData.is_disabled}
          id={rowData.id}
          onStatusChange={() => {
            disableOrEnableSurvey(rowData.id, rowIndex);
          }}
        />
      ),
    },
  ];

  const filterFields = [
    // name
    {
      id: "name",
      component: "input",
      type: "text",
      placeholder: t("survey.nameHeader"),
      label: t("survey.nameHeader"),
      name: "name",
      value: surveyFilter.name ?? "",
      onReset: () =>
        filterChangeHandler({ target: { value: "", name: "name" } }),
      classes: "col-12 col-md-6 col-xl-4",
      onchange: filterChangeHandler,
    },
    // type
    {
      id: "type",
      component: "select",
      placeholder: t("survey.type"),
      label: t("survey.type"),
      initialValue: t("survey.type"),
      name: "type",
      value: surveyFilter.type ?? "",
      classes: "col-12 col-md-6 col-xl-4",
      onchange: filterChangeHandler,
      options: [
        ...(fromTrainer || targetCourseId
          ? []
          : [
              {
                id: "all",
                value: "all",
                label: t("survey.audienceType.all"),
              },
              {
                id: "exhibition",
                value: "exhibition",
                label: t("survey.audienceType.exhibition"),
              },
            ]),
        {
          id: "course",
          value: "course",
          label: t("survey.audienceType.course"),
        },
        {
          id: "lesson",
          value: "lesson",
          label: t("survey.audienceType.lesson"),
        },
      ],
    },
    // exhibition
    ...(surveyFilter.type == "exhibition"
      ? [
          {
            id: "exhibition_id",
            component: "select",
            placeholder: t("select_exhibition"),
            label: t("select_exhibition"),
            initialValue: t("select_exhibition"),
            name: "exhibition_id",
            value: surveyFilter.exhibition_id ?? "",
            classes: "col-12 col-md-6 col-xl-4",
            onchange: filterChangeHandler,
            options: exhibitions,
          },
        ]
      : []),
    // course
    ...(surveyFilter.type == "course" || surveyFilter.type == "lesson"
      ? [
          {
            id: "course_id",
            component: "select",
            placeholder: t("survey.course"),
            label: t("survey.course"),
            initialValue: t("survey.course"),
            name: "course_id",
            value: surveyFilter.course_id ?? "",
            classes: "col-12 col-md-6 col-xl-4",
            onchange: (e) => {
              setlessons([]);
              delete surveyFilter?.lesson_id;
              fetchLesson(e.target.value);
              filterChangeHandler(e);
            },
            options: courses,
          },
        ]
      : []),
    // lesson
    ...(surveyFilter.type == "lesson"
      ? [
          {
            id: "lesson_id",
            component: "select",
            placeholder: t("survey.lesson"),
            label: t("survey.lesson"),
            initialValue: t("survey.lesson"),
            name: "lesson_id",
            value: surveyFilter.lesson_id ?? "",
            classes: "col-12 col-md-6 col-xl-4",
            onchange: filterChangeHandler,
            options: lessons,
          },
        ]
      : []),
    ...(fromTrainer
      ? [
          {
            id: "trainer_name",
            component: "select",
            placeholder: t("survey.trainer_name"),
            label: t("survey.trainer_name"),
            initialValue: t("survey.trainer_name"),
            name: "user_id",
            value: surveyFilter.user_id ?? "",
            classes: "col-12 col-md-6 col-xl-4",
            onchange: filterChangeHandler,
            options: trainers,
          },
        ]
      : []),
    ...(fromTrainer || role == "trainer"
      ? []
      : [
          // Trainer name or User type
          {
            id: "user_type",
            component: "select",
            placeholder: t("crud.placeholders.select.userType"),
            label: t("crud.placeholders.select.userType"),
            initialValue: t("crud.placeholders.select.userType"),
            name: "user_type",
            value: surveyFilter.user_type ?? "",
            classes: "col-12 col-md-6 col-xl-4",
            onchange: filterChangeHandler,
            options: [
              {
                id: "all",
                value: "all",
                label: t("selection.role.all"),
              },
              {
                id: "all_guest",
                value: "all_guest",
                label: t("selection.role.all_guest"),
              },
              {
                id: "trainer",
                value: "trainer",
                label: t("survey.select.option.trainer"),
              },
              {
                id: "trainee",
                value: "trainee",
                label: t("survey.select.option.trainee"),
              },
              {
                id: "trainerAndTrainee",
                value: "trainerAndTrainee",
                label: t("survey.select.option.trainerAndTrainee"),
              },
            ],
          },
          // start time
          {
            id: "startDate",
            component: "datePicker",
            placeholder: t("crud.placeholders.from_date"),
            label: t("crud.placeholders.from_date"),
            name: "start_time",
            value: surveyFilter.start_time ?? "",
            classes: "col-12 col-md-6 col-xl-4",
            onchange: filterChangeHandler,
          },
          // end time
          {
            id: "endDate",
            component: "datePicker",
            placeholder: t("crud.placeholders.to_date"),
            label: t("crud.placeholders.to_date"),
            name: "start_time_to",
            value: surveyFilter.start_time_to ?? "",
            classes: "col-12 col-md-6 col-xl-4",
            onchange: filterChangeHandler,
          },
        ]),
    // status
    {
      id: "status",
      component: "select",
      placeholder: t("Exhibition.status_exh"),
      label: t("Exhibition.status_exh"),
      initialValue: t("Exhibition.status_exh"),
      name: "is_disabled",
      value: surveyFilter.is_disabled ?? "",
      classes: "col-12 col-md-6 col-xl-4",
      onchange: filterChangeHandler,
      options: [
        {
          id: "enabled",
          value: 0,
          label: t("Exhibition.active"),
        },
        {
          id: "disabled",
          value: 1,
          label: t("Exhibition.inactive"),
        },
      ],
    },
    // responses count
    {
      id: "responses_count",
      component: "select",
      placeholder: t("survey.responses_count"),
      label: t("survey.responses_count"),
      initialValue: t("survey.responses_count"),
      name: "responses_count",
      value: surveyFilter.responses_count ?? "",
      classes: "col-12 col-md-2 ",
      onchange: filterChangeHandler,
      options: [
        {
          id: "1",
          value: JSON.stringify({ max: 10, min: 0 }),
          label: "0 - 10",
        },
        {
          id: "2",
          value: JSON.stringify({ max: 20, min: 11 }),
          label: "11 - 20",
        },
        {
          id: "3",
          value: JSON.stringify({ max: 30, min: 21 }),
          label: "21 - 30",
        },
        {
          id: "4",
          value: JSON.stringify({ max: 40, min: 31 }),
          label: "31 - 40",
        },
        {
          id: "5",
          value: JSON.stringify({ max: 50, min: 41 }),
          label: "41 - 50",
        },
        {
          id: "6",
          value: JSON.stringify({ max: 100, min: 51 }),
          label: "51 - 100",
        },
        {
          id: "7",
          value: JSON.stringify({ min: 101, max: "" }),
          label: t("survey.morethan"),
        },
      ],
    },
    // reset
    {
      id: "reset",
      component: "button",
      type: "button",
      name: "action",
      value: t(`Exhibition.reset`),
      classes: "col-12 col-md-6 col-xl-4",
      backgroundColor: "white",
      color: "rgb(4, 108, 119)",
      borderColor: "rgb(4, 108, 119)",
      action: () => {
        setSurveyFilter({});
        if (currentPage == 1) {
          refetchSurveys({});
        } else {
          setCurrentPage(1);
        }
      },
    },
    // button
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("Exhibition.search"),
      classes: "col-12 col-md-6 col-xl-4",
      action: () => {
        if (currentPage == 1) {
          refetchSurveys();
        } else {
          setCurrentPage(1);
        }
      },
    },
  ];

  function transformObject(data) {
    const { name, id } = data;
    return { value: id, id: id, label: name };
  }

  function fetchDataForFilter() {
    getExhibitionsSurvies()
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          let newExhibition = res.data.exhibitions.map(transformObject);
          setExhibitions(newExhibition);
        }
      })
      .catch((err) => {});

    getAllCourses()
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          let newCourses = res.data.courses.map(transformObject);
          setCourses(newCourses);
        }
      })
      .catch((err) => {});
    if (fromTrainer) {
      getAllUsers({ roles: ["trainer"] })
        .then((res) => {
          if (res.status && res.status === 200 && res.data.status) {
            let users = res.data.users.map(transformObject);
            setTrainers(users);
          }
        })
        .catch((err) => {});
    }
  }

  const fetchLesson = (id) => {
    id
      ? getLessonById(id)
          .then((res) => {
            let newLessons = res.data.lessons.map(transformObject);
            setIsLoading(false);
            setlessons(newLessons);
          })
          .catch((err) => {})
      : setlessons([]);
  };

  const fetchSurvies = (params = getSurveyFilter()) => {
    if (targetCourseId) {
      params = { ...params, course_id: targetCourseId };
    }
    if (targetLessonId) {
      params = { ...params, lesson_id: targetLessonId };
    }

    params = {
      ...params,
      perPage: 10,
      from_trainers: fromTrainer ? true : null,
    };
    setCurrentSurveyFilter(params);

    setIsUpdating(true);
    const myPromise = new Promise(async (resolve, reject) => {
      try {
        let response = await getSurvies(clearEmptyProp(params));
        if (response.status == 200) {
          setSurvies(response.data.data.surveys);
          setMeta(response.data.data.meta);
          setIsLoading(false);
          setIsUpdating(false);
        }
        resolve();
      } catch (e) {
        reject(e);
      }
    });

    return myPromise;
  };

  const getSurveyResponses = (surveyId) => {
    getSurveyResponse(surveyId)
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setSurveyResonse(res.data.data.responses);
          setMetaTrainees(res.data.data.meta);
          setIsLoading(false);
        }
      })
      .catch((err) => {});
  };

  const handlePageClickTrainees = (event) => {
    const toBeUpdatedFilters = filter;
    toBeUpdatedFilters.page = event.selected + 1;
    setFilter(toBeUpdatedFilters);

    getSurveyResponse(toBeUpdatedFilters);
  };

  const showTraineeSurvey = (type, surveyId, survey) => {
    if (type == "updateEndDate") {
      if (survey.dependency_type != null) {
      } else {
        getSurveyResponses(surveyId);
        traineesModalRef.current.showModal();
        setShowTrainees(true);
        setTypeContentModal(type);
        setEndDate(survey.end_time);
        setSurveyId(surveyId);
      }
    } else {
      getSurveyResponses(surveyId);
      traineesModalRef.current.showModal();
      setShowTrainees(true);
      setTypeContentModal(type);
      setEndDate(survey.end_time);
      setSurveyId(surveyId);
    }
  };

  const removeSurvey = (id, index) => {
    setIsUpdating(true);
    deleteSurvey(id)
      .then(() => {
        setShowDeleteModal(false);
        const newSurvies = survies.filter((survey) => survey.id !== id);
        setSurvies(newSurvies);
        traineesModalRef.current.dismissModal();
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {t("survey.successDeleted")}
          </span>
        );

        setIsUpdating(false);
      })
      .catch((err) => {
        setShowDeleteModal(false);
        traineesModalRef.current.dismissModal();
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.data.msg}
          </span>
        );
        setIsUpdating(false);
      });
  };

  const disableOrEnableSurvey = (id, index) => {
    let isDisabled = false;
    setIsUpdating(true);
    toggleSurveyAccessibility(id)
      .then(() => {
        const newSurvies = survies.map((item, i) => {
          if (item.id == id) {
            item.is_disabled = !item.is_disabled;
            isDisabled = !item.is_disabled;
          }
          return item;
        });
        setSurvies(newSurvies);
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {isDisabled
              ? t("survey.messages.enabled")
              : t("survey.messages.disabled")}
          </span>
        );

        setIsUpdating(false);
      })
      .catch((err) => {
        traineesModalRef.current.dismissModal();
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.data.msg}
          </span>
        );
        setIsUpdating(false);
      });
  };

  const getSurveyType = (survey) => {
    if (survey.course_id) {
      if (survey.lesson_id) {
        return (
          <IconsTooltip
            title={`${survey?.lesson?.title} ${
              survey?.course?.name ? `. ${survey?.course?.name}` : ""
            }`}
            content={
              <Chip
                label={t("survey.audienceType.lesson")}
                style={{
                  background: "rgb(176 171 224)",
                  fontFamily: "dinNextRegular, sans-serif",
                }}
              />
            }
          />
        );
      } else {
        return (
          <IconsTooltip
            title={survey?.course?.name}
            content={
              <Chip
                label={t("survey.audienceType.course")}
                style={{
                  background: "rgb(182 ,226, 227)",
                  fontFamily: "dinNextRegular, sans-serif",
                }}
              />
            }
          />
        );
      }
    } else if (survey.exhibition_id) {
      return (
        <IconsTooltip
          title={survey.exhibition.name}
          content={
            <Chip
              label={t("survey.audienceType.exhibition")}
              style={{
                background: "rgb(227 219 175)",
                fontFamily: "dinNextRegular, sans-serif",
              }}
            />
          }
        />
      );
    } else {
      return (
        <Chip
          label={t("survey.audienceType.all")}
          style={{
            background: "rgb(182 228 187)",
            fontFamily: "dinNextRegular, sans-serif",
          }}
        />
      );
    }
  };

  const sortActionHandler = async (columnKey) => {
    let columns = [...sortColumns];
    let key = columnKey;
    // Map the local key to the backend key
    switch (columnKey) {
      case "name":
        key = "name";
        break;
      case "startDate":
        key = "start_time";
        break;
      case "endDate":
        key = "end_time";
        break;
      case "noOfParticipants":
        key = "responses_count";
        break;
      case "status":
        key = "is_disabled";
        break;
    }

    const col = columns.find((c) => c.key == key);
    if (col) {
      columns = [
        {
          key,
          orderBy: col.orderBy == "ASC" ? "DESC" : "ASC",
        },
      ];
    } else {
      columns = [
        {
          key,
          orderBy: "ASC",
        },
      ];
    }

    setSortColumns(columns);
  };

  const refetchSurveys = async (params = getSurveyFilter()) => {
    try {
      let customFilter = { ...params, sortColumns, page: currentPage };
      await fetchSurvies(customFilter);
    } catch (e) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };

  const getCourseDetailsAPI = () => {
    getCourseDetails(targetCourseId)
      .then((res) => {
        if (res.status === 200 && res.data.status) {
          setTargetCourse(res.data.course);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    refetchSurveys();
  }, [currentPage, sortColumns]);

  useEffect(() => {
    if (targetCourseId) {
      getCourseDetailsAPI();
    }
  }, [targetCourseId]);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    // if from course then add course name
    ...(targetCourseId
      ? [
          // {
          //   id: "courses",
          //   page: t("courses"),
          //   pagePath: `/${role}/courses`,
          // },
          {
            id: "course",
            page: targetCourse?.name,
            pagePath: `/${role}${
              targetCourse?.program_id
                ? `/program/${targetCourse.program_id}`
                : ``
            }/course/${targetCourseId}`,
          },
        ]
      : []),
    {
      id: "exhibition",
      page: t("survey.title"),
      active: true,
    },
  ];

  return (
    <>
      <BasicModal ref={traineesModalRef}>
        {showAsseccibilityModal && !showTrainees ? (
          <DisableModal
            deleteMsg={t("modal.survey.disable.body")}
            refuse={modalHandler}
            accept={() => disableOrEnableSurvey(surveyId, surveyIndex)}
            deleteModalRef={traineesModalRef}
            isDisabled={isDisabled}
          />
        ) : !showTrainees && !showAsseccibilityModal ? (
          <DeleteModal
            label={t("survey.delete_aprove")}
            title={t("modal.survey.delete.title")}
            deleteMsg={t("modal.survey.delete.body")}
            refuse={modalHandler}
            accept={() => removeSurvey(surveyId, surveyIndex)}
            deleteModalRef={traineesModalRef}
          />
        ) : (
          <TraineesModal
            label={
              typeContentModal == "trainees"
                ? t("survey.trainee.label")
                : t("survey.trainee.endDateHint")
            }
            title={
              typeContentModal == "trainees"
                ? t("survey.trainee.label")
                : t("survey.trainee.endDateHint")
            }
            body={surveyResponse}
            type={typeContentModal}
            endTime={endDate}
            surveyId={surveyId}
            traineesModalRef={traineesModalRef}
            showTraineeSurvey={showTraineeSurvey}
            getSurvies={fetchSurvies}
            pagination={
              <ReactPaginate
                nextLabel={t("next")}
                onPageChange={handlePageClickTrainees}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={metaTrainees.totalPages}
                previousLabel={t("previous")}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            }
          />
        )}
      </BasicModal>
      <div className={"container-fluid"} id={"content-section"}>
        <div className="row">
          <div className="col-12 sm:tw-px-2 tw-py-8">
            {/*TODO*/}
            <Breadcrumb list={breadcrumbList} />
          </div>
        </div>
        {isUpdating ? <SkeletonCardOverlay skeletonWidth="100" /> : <div></div>}
        {!isLoading ? (
          <MainBox>
            <MainBoxHead
              title={t(
                fromTrainer ? "survey.trainerListSideBar" : "survey.listHeading"
              )}
              actions={actionsList}
            />
            {isFilter && <FilterBox fields={filterFields} />}
            <MainTable
              columns={columns}
              data={survies}
              sortAction={sortActionHandler}
            />
            <Pagination
              count={meta.totalPages}
              showFirstButton
              showLastButton
              variant="outlined"
              shape="rounded"
              className="main-pagination"
              page={currentPage}
              onChange={(e, page) => {
                setCurrentPage(page);
              }}
            />
            {showDeleteModal && (
              <SharedModal
                icon={DeleteIcon}
                title={t("modal.survey.delete.title")}
                name={survey.name}
                confirmMsg={t("modal.survey.delete.body")}
                confirmText={t("survey.delete_aprove")}
                cancelText={t("Exhibition.cancel")}
                onCancel={() => {
                  setShowDeleteModal(false);
                }}
                onConfirm={() => {
                  removeSurvey(survey.id, surveyIndex);
                }}
                isLoading={iseDeleteLoading}
              />
            )}
          </MainBox>
        ) : (
          <div
            className="mt-5"
            style={{
              height: "65vh",
            }}
          >
            <SkeletonCard />
          </div>
        )}
      </div>
    </>
  );
}

export default SurveyList;
