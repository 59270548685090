import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { updateCouponStatus } from "../../../../Redux/Actions/coupons/couponsList.action";
import DeleteIcon from "../../../../assets/icons/delete.svg";
import EyeIcon from "../../../../assets/icons/eye.svg";
import editIcon from "../../../../assets/icons/pen.svg";
import { utcToLocal } from "../../../../utils/utcToLocal";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import SwitchToggle from "../../../Shared/Components/SwitchToggle/SwitchToggle";
import TableActionsColumn from "../../../Shared/Components/TableActionsColumn/TableActionsColumn";
import classes from "./coupons.module.css";

const CouponUserTable = ({ tableData, isTableLoading }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => <>{rowIndex + 1}</>,
    },
    {
      id: "name",
      name: t("giftCourse.label.trainee_name"),
      key: "name",
      cell: ({ rowData }) => (
        <div className={classes["coupon-list__table-name"]}>
          {rowData?.name || "-"}
        </div>
      ),
    },
    {
      id: "email",
      name: t("general.email"),
      key: "email",
      cell: ({ rowData }) => <>{rowData?.email}</>,
      sortable: false,
    },
    {
      id: "use_date",
      name: t("use_date"),
      key: "use_date",
      cell: ({ rowData }) => (
        <>
          {moment(rowData?.created_at).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData?.created_at)}
        </>
      ),
      sortable: false,
    },
  ];

  return (
    <MainTable
      columns={columns}
      data={tableData || []}
      isLoading={isTableLoading}
    />
  );
};

export default CouponUserTable;
