import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ExhibitionVisit } from "../../Services/api/Exhibition/ExhibitionProvider";
import deleteIcon from "../../assets/icons/delete.svg";
import CustomButton from "../Shared/Components/CustomButton/CustomButton";
import Input from "../Shared/Components/Input/Input";
import MainBox from "../Shared/Components/MainBox/MainBox";
import PhoneInput from "../Shared/Components/PhoneInput/PhoneInput";
import Title from "../Shared/Components/Title/Title";

const FreeExhibitionForGuestModal = ({ closeFreeExhibitionForGuest, exhibitionId }) => {
  require("./exhibition.css");
  const { t } = useTranslation();
  const history = useHistory();
  const { phoneCode } = useSelector((state) => state.exhibitionReducer);

  return (
    <MainBox className="border-8 w-60">
      <div className="popup-header">
        <Title title={t("Exhibition.popuplabel")} />
        <img src={deleteIcon} alt="close" onClick={closeFreeExhibitionForGuest} className="pointer" />
      </div>
      <Formik
        initialValues={{
          name: "",
          email: "",
          mobile: "",
          code: "",
          exhibition_type: "FREE",
          exhibition_id: exhibitionId,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          values.mobile = phoneCode + values.mobile;
          delete values.code;
          if (!values.mobile) values.mobile = "-";
          try {
            const res = await ExhibitionVisit(values);
            if (res.status === 200 && res.data.status) {
              closeFreeExhibitionForGuest();
              localStorage.setItem("exhibition_id", res.data.booths.exhibition_id);
              localStorage.setItem("guest_email", res.data.booths.email);
              localStorage.setItem("guest_token", res.data.booths.guest_token);
              history.push(`/show-exhibition/unity-iframe/${exhibitionId}`);
            } else {
              toast.error(
                <span style={{ fontSize: 13, fontWeight: "bold" }}>
                  {res.data.msg ? res.data.msg : "Failure in service"}
                </span>
              );
            }
          } catch (err) {
            closeFreeExhibitionForGuest();
            toast.error(
              <>
                <span
                  style={{
                    fontSize: 13,
                    fontWeight: "bold",
                    display: "block",
                    paddingBottom: "10px",
                  }}
                >
                  {err.response.data.msg}
                </span>
                <ul>
                  {Object.keys(err.response.data.errors).map((key) => {
                    return (
                      <li>
                        <span style={{ fontSize: 11 }}>{err.response.data.errors[key]}</span>
                      </li>
                    );
                  })}
                </ul>
              </>
            );
          } finally {
            setSubmitting(false);
          }
        }}
        validate={(values) => {
          const errors = {};

          if (!values.name) {
            errors.name = t("Exhibition.errors.required");
          }

          if (values.mobile && values.mobile.toString().length <= 7 && values.mobile.toString().length >= 15) {
            errors.mobile = t("Exhibition.errors.lengthrequired");
          }

          if (!values.email) {
            errors.email = t("Exhibition.errors.required");
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = t("Exhibition.errors.emailrequired");
          }

          return errors;
        }}
        validateOnChange={true}
      >
        {({ errors, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="mb-20">
              <Input
                label={t("Exhibition.popupusername")}
                type="text"
                name="name"
                placeholder={t("Exhibition.popupusername")}
                onChange={handleChange}
                error={errors.name}
                required={true}
              />
            </div>

            <div className="mb-20">
              <PhoneInput
                label={t("Exhibition.popupphone")}
                type="number"
                nameInput="mobile"
                nameSelect="code"
                placeholder={t("Exhibition.popupphone")}
                onChange={handleChange}
                error={errors.mobile}
                min={0}
              />
            </div>

            <div className="mb-20">
              <Input
                label={t("Exhibition.popupemail")}
                type="email"
                name="email"
                placeholder={t("Exhibition.popupemail")}
                onChange={handleChange}
                error={errors.email}
                required={true}
              />
            </div>

            <div className="modal-buttons tw-mt-5">
              <CustomButton
                value={t("Exhibition.popupsave")}
                colors={"#036c77"}
                type={"button"}
                iconClass={"mt-2"}
                action={handleSubmit}
                classes={"button-width"}
              />
            </div>
          </form>
        )}
      </Formik>
    </MainBox>
  );
};

export default FreeExhibitionForGuestModal;
