import React from "react";

function DragAndDropSelectedAnswersItem(props) {

    return (
        <>
            <tr>
                <td>{props.name}</td>
                <td>
                    <img src={props.image} width="50" height="50" />
                </td>
            </tr>
        </>
    )
}

export default DragAndDropSelectedAnswersItem;
