import React, {useContext} from "react";
import {TrainingCourseProfileContext} from "../../../../../Services/api/toutorials/TrainingCourseProfileContext";
import {useTranslation} from "react-i18next";
import like from "../../../../../assets/image/like.png";

function TrainingCourseProfileDiscussions() {
  require("./TrainingCourseDiscussions.css");

  const { t } = useTranslation();
  const trainingCourseProfileContext = useContext(TrainingCourseProfileContext);

  return (
    <>
      <div className="training-course-comments-container">
        {trainingCourseProfileContext.comments.map((item, index) => {
          return (
            <>
              <div className="training-course-comment-outline">
                <div className="training-course-comment-header">
                  <div className="courses_card_icon"></div>
                  <div className="training-course-commenter">
                    {t("yourGrades")}
                  </div>
                </div>
                <div className="training-course-comment-body">
                  <div>
                    {t("basicCourse")} {t("basicCourse")} {t("basicCourse")}{" "}
                    {t("basicCourse")}
                  </div>
                </div>
                <div className="training-course-comment-footer">
                  <img
                    src={like}
                    alt="..."
                    className="training-course-comment-like-icon"
                  />
                  <div className="training-course-comment-like-text">
                    0 {t("likes")}
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
}

export default TrainingCourseProfileDiscussions;
