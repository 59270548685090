import React from "react";
import { useTranslation } from "react-i18next";
import boothsIcon from "../../../../assets/icons/booths.svg";
import calenderIcon from "../../../../assets/icons/calender.svg";
import Avatar from "../../../Shared/Components/Avatar/Avatar";
import Badge from "../../../Shared/Components/Badge/Badge";
import IconWithText from "../../../Shared/Components/IconWithText/IconWithText";
import Logo from "../../../Shared/Components/Logo/Logo";
import Options from "../../../Shared/Components/Options/Options";
import StarsRating from "../../../Shared/Components/StarsRating/StarsRating";
import Title from "../../../Shared/Components/Title/Title";
import classes from "./exhibitionDetails.module.css";
const ExhibitionDetailsHead = ({
	image,
	name,
	startDate,
	endDate,
	boothsNumber,
	rating,
	price,
	userStatus,
	canEdit,
	editAction,
	userAction,
	reportAction,
}) => {
	const { t, i18n } = useTranslation();

	const exhibitionDetailsOptions = [
		{
			id: "documents",
			label: "التقارير",
			icon: (
				<svg xmlns="http://www.w3.org/2000/svg" width="21.031" height="25.789" viewBox="0 0 21.031 25.789">
					<g id="file-text_5_" data-name="file-text (5)" transform="translate(1 1)">
						<path
							id="Path_195"
							data-name="Path 195"
							d="M15.894,2H6.379A2.379,2.379,0,0,0,4,4.379V23.41a2.379,2.379,0,0,0,2.379,2.379H20.652a2.379,2.379,0,0,0,2.379-2.379V9.137Z"
							transform="translate(-4 -2)"
							fill="none"
							stroke="#9A9A9A"
							strokeLinecap="round"
							stroke-linejoin="round"
							stroke-width="2"
						/>
						<path
							id="Path_196"
							data-name="Path 196"
							d="M14,2V9.137h7.137"
							transform="translate(-2.106 -2)"
							fill="none"
							stroke="#9A9A9A"
							strokeLinecap="round"
							stroke-linejoin="round"
							stroke-width="2"
						/>
						<line
							id="Line_31"
							data-name="Line 31"
							x1="9.692"
							transform="translate(4.67 12.864)"
							fill="none"
							stroke="#9A9A9A"
							strokeLinecap="round"
							stroke-linejoin="round"
							stroke-width="2"
						/>
						<line
							id="Line_32"
							data-name="Line 32"
							x1="9.692"
							transform="translate(4.67 17.709)"
							fill="none"
							stroke="#9A9A9A"
							strokeLinecap="round"
							stroke-linejoin="round"
							stroke-width="2"
						/>
						<path
							id="Path_197"
							data-name="Path 197"
							d="M10.379,9H8"
							transform="translate(-3.242 -0.674)"
							fill="none"
							stroke="#9A9A9A"
							strokeLinecap="round"
							stroke-linejoin="round"
							stroke-width="2"
						/>
					</g>
				</svg>
			),
			action: () => {
				reportAction();
			},
		},
		{
			id: "edit",
			label: "تعديل",
			icon: (
				<svg xmlns="http://www.w3.org/2000/svg" width="33.78" height="32.229" viewBox="0 0 33.78 32.229">
					<g id="edit-3_1_" data-name="edit-3 (1)" transform="translate(1 1)">
						<path
							id="Path_199"
							data-name="Path 199"
							d="M12,20H27.89"
							transform="translate(3.89 10.229)"
							fill="none"
							stroke="#9a9a9a"
							strokeLinecap="round"
							stroke-linejoin="round"
							stroke-width="2"
						/>
						<path
							id="Path_200"
							data-name="Path 200"
							d="M26.835,3.976a3.745,3.745,0,1,1,5.3,5.3l-22.07,22.07L3,33.107l1.766-7.062Z"
							transform="translate(-3 -2.879)"
							fill="none"
							stroke="#9a9a9a"
							strokeLinecap="round"
							stroke-linejoin="round"
							stroke-width="2"
						/>
					</g>
				</svg>
			),
			disabled: !canEdit,
			action: () => {
				if (!canEdit) return;
				editAction();
			},
		},
	];

	return (
		<div className={classes["exhibition-details__head"]}>
			<div className={classes["exhibition-details__head__info"]}>
				<div className={classes["exhibition-details__head-image"]}>
					<Avatar image={image} active={userStatus} />
				</div>
				<div className={classes["exhibition-details__head__exh"]}>
					<div className={classes["exhibition-details__head__exh-top"]}>
						<Title title={name} />
						<Badge
							label={`${price === ".00" ? "" : t("Exhibition.enter_price")} ${
								price === ".00" ? t("Exhibition.free") : price
							} ${price === ".00" ? "" : `${t("Exhibition.sar_currency")}`}`}
						/>
					</div>
					<div className={classes["exhibition-details__head__exh-bottom"]}>
						<IconWithText text={`${startDate} ${t("to")} ${endDate}`} icon={calenderIcon} color={"#24B3B9"} />
						<IconWithText text={`${boothsNumber} ${t("Exhibition.booths")}`} icon={boothsIcon} color={"#24B3B9"} />
						<div className="rate">
							<StarsRating rating={rating || 0} cutStyle="star-font-size" />
						</div>
					</div>
				</div>
			</div>
			<div className={classes["exhibition-details__head__options"]}>
				<Options options={exhibitionDetailsOptions} />
			</div>
		</div>
	);
};

export default ExhibitionDetailsHead;
