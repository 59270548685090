import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { saveWatchingProgress } from "../../../../../Redux/Actions/courses/coursesList.action";
import {
  answerLessonActivity,
  getLessonActivity,
  getLessonSingleContent,
} from "../../../../../Services/api/courses/courseProvider";
import ArrowIcon from "../../../../../assets/icons/arrow.png";
import HomeIcon from "../../../../../assets/icons/home.svg";

import SkeletonCard from "../../../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../../../Shared/Components/Spinner/SkeletonCardOverlay";
import classes from "../LessonActivityView/ListExams.module.css";

import VideoView from "../ContentView/VideoView";
import AudioView from "../ContentView/AudioView";
import PdfView from "../ContentView/PdfView";
import FileView from "../ContentView/FileView";

function LessonSingleContentView({}) {
  require("../LessonActivityView/exams.css");
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    id: courseId,
    chapterId,
    supChapterId,
    lessonId,
    contentId,
  } = useParams();
  const fromRef = useRef();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [content, setContent] = useState({});
  const [isCourseClosed, setIsCourseClosed] = useState(true);

  useEffect(() => {
    getLessonSingleContent(contentId).then((res) => {
      if (res.status && res.status === 200 && res.data.status) {
        setContent(res.data.content);
        setIsCourseClosed(
          res.data.content.course_status === "Closed" ||
            res.data.content.course_status === "مغلقة"
        );
        // saveWatchingProgressAPI(res.data.activity);
      }
    });
  }, []);

  return (
    <div className="container-fluid">
      {isUpdating ? <SkeletonCardOverlay skeletonWidth="100" /> : <div></div>}
      {content && content.id ? (
        <>
          <div className="tw-bg-white tw-shadow tw-rounded tw-p-8 tw-space-y-8 tw-mt-8">
            <div className={classes["back"]} onClick={() => history.goBack()}>
              <div className={classes["back-icon"]}>
                <img src={ArrowIcon} alt="back" />
              </div>
              <div className={classes["back-text"]}>{t("back")}</div>
            </div>
            <div className="tw-flex tw-items-center tw-justify-between">
              <div className="tw-flex tw-flex-wrap tw-items-center tw-space-s-2">
                <div className="tw-flex tw-flex-wrap tw-items-center tw-space-s-2 tw-text-lg sm:tw-text-2xl tw-font-bold tw-text-teal-600">
                  <div>{`${content.title}`}</div>
                  {/* <div>{`(${activity.type})`}</div> */}
                </div>
              </div>
            </div>
            <div className="tw-px-8 tw-py-4">
              {content.type === "video" ? (
                <>
                  <div>
                    <VideoView question={content} />
                  </div>
                </>
              ) : content.type === "audio" ? (
                <>
                  <div>
                    <AudioView question={content} />
                  </div>
                </>
              ) : content.type === "pdf" ? (
                <>
                  <div>
                    <PdfView question={content} />
                  </div>
                </>
              ) : content.type === "file" ? (
                <>
                  <div>
                    <FileView question={content} />
                  </div>
                </>
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: content.content,
                  }}
                ></div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            height: "65vh",
            width: "100%",
          }}
        >
          <SkeletonCard />
        </div>
      )}
    </div>
  );
}

export default LessonSingleContentView;
