import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { NavLink, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import UploadAdapter from "../../CKEditor/Adapter/UploadAdapter";
import { CreateFaqForm, EditFaqProvider } from "../../Services/api/FAQ/AdminAddFaqProvider";
import SkeletonCard from "../Shared/Components/Spinner/SkeletonCard";


export default function EditFaq() {
    require("./addfaq.css");

    const { t, i18n } = useTranslation();
    const history = useHistory();
    const {id} = useParams();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [langs, setLangs] = useState([]);
    const [ticketTypes, setTicketTypes] = useState([]);

    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [htmlEditor, setHtmlEditor] = useState(null);
    const [editorAr , setEditorAr] = useState(null);
    const [dataById, setDataById] = useState([])

    useEffect(() => {

        if(location.state){
            setDataById(location.state.data);
            setHtmlEditor(location.state.data.answer)
            setEditorAr(location.state.data.answer_ar)
        }
        setIsLoading(false);
        async function executeOnLoad() {
            try{
                let response = await CreateFaqForm("");
                if (response.status && response.status == 200) {
                    setLangs(response.data.data.langs)
                }
            }
            catch(error){
                toast.error(
                    <span style={{fontSize: 13, fontWeight: "bold"}}>
                        {t("failed_fetching")}
                    </span>
                )
            }
        }

        executeOnLoad();
    }, []);

    const handleText = (event, editor) => {
        const data = editor.getData();
        setHtmlEditor(data);
    };
    const handleTextar = (event, editor) => {
        const data = editor.getData();
        setEditorAr(data);
    };
    return (
        <div className="faq-add-pg">
            <div className="container-fluid profile_wrapper" style={{ paddingTop: "45px" }}>
                <div className="admin_label">
                   <NavLink to="/admin">{t("admin.label.admin_label")}</NavLink>
                    <p style={{ padding: "0 5px" }}>/</p>
                    <NavLink to="/admin/faq-list">{t('FAQ.answer_bank')}</NavLink>
                    <p style={{ padding: "0 5px" }}>/</p>
                    {t('FAQ.edit_questions')}
                </div>
            </div>
            <div className="container-fluid profile_wrapper">
                <div className={isLoading ? null : 'table_bank_tran faq_box_white'}>

                    {
                        isLoading ?
                            <div style={{
                                height: "65vh",
                                width: '100%'
                            }}>
                                <SkeletonCard />
                            </div>
                            :
                            <>
                                <h3 className="table_header">
                                {t('FAQ.edit_questions')}
                                </h3>
                                <div className="box_faq">
                                    <div>
                                        <Formik
                                            initialValues={{
                                                question: dataById.question,
                                                answer:  dataById.answer,
                                                question_ar: dataById.question_ar,
                                                answer_ar:  dataById.answer_ar
                                            }}
                                            onSubmit={async (values, { setSubmitting }) => {
                                                try {
                                                    // setIsLoading(true);
                                                    let dataToBeUploaded = {
                                                        _method: "put",
                                                        ...values,
                                                    };
                                                    dataToBeUploaded.answer = htmlEditor ? htmlEditor : dataById.answer;
                                                    dataToBeUploaded.answer_ar = editorAr ? editorAr : dataById.answer_ar;

                                                    const response = await EditFaqProvider(id, dataToBeUploaded);
                                                    if (response.status === 200 && response.data.status) {

                                                        toast.success(
                                                            <span style={{ fontSize: 13, fontWeight: "bold" }}>
                                                               {t('FAQ.toast_Two')}
                                                            </span>,
                                                            {
                                                                
                                                                onClose: () => history.push(`/trainer/course/${dataById.id}`),
                                                                autoClose: 1000
                                                            }
                                                        )
                                                    } else {
                                                        toast.error(
                                                            <span style={{ fontSize: 13, fontWeight: "bold" }}>
                                                                {response.data.msg ? response.data.msg : "Failure in service"}
                                                            </span>
                                                        );
                                                        setIsLoading(false);
                                                    }
                                                } catch (err) {
                                                    setIsLoading(false);
                                                    toast.error(
                                                        <span style={{ fontSize: 13, fontWeight: "bold" }}>
                                                            {err.response.data && err.response.data.msg ? err.response.data.msg : "Failure in service"}
                                                        </span>
                                                    );
                                                }
                                            }}
                                            validateOnChange={hasSubmitted}
                                            validate={(values) => {
                                                setHasSubmitted(true);
                                                values.answer = htmlEditor;
                                                values.answer_ar = editorAr;

                                                const errors = {};
                                                if (!values.question) {
                                                    errors.question = t("crud.errors.required");
                                                }

                                                if (!values.answer) {
                                                    errors.answer = t("crud.errors.required");
                                                }
                                                if (!values.question_ar) {
                                                    errors.question_ar = t("crud.errors.required");
                                                }

                                                if (!values.answer_ar) {
                                                    errors.answer_ar = t("crud.errors.required");
                                                }
                                                return errors;
                                            }}
                                        >
                                            {({
                                                values,
                                                errors,
                                                handleChange,
                                                handleSubmit,
                                                isSubmitting,
                                                handleBlur,
                                                setFieldValue,
                                            }) => (
                                                <form onSubmit={handleSubmit} className="admin_add_form">
                                                    <div className="row">
                                                    <div className="col-md-12">
                                                            <label htmlFor="name" className="admin_add_label">
                                                            {t('FAQ.question_ar')}
                                                            </label>
                                                            <textarea
                                                                name="question_ar"
                                                                className="admin_add_input"
                                                                value={values.question_ar}
                                                                onChange={handleChange}></textarea>
                                                            <p className={"form-input-error-space"}>
                                                                {errors.question_ar ? errors.question_ar : null}
                                                            </p>
                                                        </div>
                                                        <div className="col-md-12">
                                                        <label htmlFor="answer_ar">{t("FAQ.answer_ar")}</label>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={values.answer_ar}
                                                                    onReady={(editor) => {
                                                                        editor.plugins.get("FileRepository").createUploadAdapter =
                                                                            function (loader) {
                                                                                return new UploadAdapter(loader);
                                                                            };
                                                                    }}
                                                                    config={{
                                                                        simpleUpload:
                                                                            {
                                                                                uploadUrl:"http://tajah-tebx.test/api/ckeditor/image-upload",
                                                                                withCredentials: true,

                                                                                // Headers sent along with the XMLHttpRequest to the upload server.
                                                                                headers: {
                                                                                    // 'X-CSRF-TOKEN': 'CSRF-Token',
                                                                                    Authorization: `Bearer ${localStorage.getItem('token')}`
                                                                                }
                                                                            }
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        handleTextar(event, editor)
                                                                    }}
                                                                    onBlur={(event, editor) => {}}
                                                                    onFocus={(event, editor) => {}}
                                                                />
                                                                 <p className={"form-input-error-space"}>
                                                                    {errors.answer_ar ? errors.answer_ar : null}
                                                                </p>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <label htmlFor="name" className="admin_add_label">
                                                            {t('FAQ.question')}
                                                            </label>
                                                            <textarea
                                                                name="question"
                                                                className="admin_add_input"
                                                                value={values.question}
                                                                onChange={handleChange}></textarea>
                                                            <p className={"form-input-error-space"}>
                                                                {errors.question ? errors.question : null}
                                                            </p>
                                                        </div>
                                                        <div className="col-md-12">
                                                        <label htmlFor="answer">{t("FAQ.answer")}</label>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={values.answer}
                                                                    onReady={(editor) => {
                                                                        editor.plugins.get("FileRepository").createUploadAdapter =
                                                                            function (loader) {
                                                                                return new UploadAdapter(loader);
                                                                            };
                                                                    }}
                                                                    config={{
                                                                        simpleUpload:
                                                                            {
                                                                                uploadUrl:"http://tajah-tebx.test/api/ckeditor/image-upload",
                                                                                withCredentials: true,

                                                                                // Headers sent along with the XMLHttpRequest to the upload server.
                                                                                headers: {
                                                                                    // 'X-CSRF-TOKEN': 'CSRF-Token',
                                                                                    Authorization: `Bearer ${localStorage.getItem('token')}`
                                                                                }
                                                                            }
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        handleText(event, editor)
                                                                    }}
                                                                    onBlur={(event, editor) => {}}
                                                                    onFocus={(event, editor) => {}}
                                                                />
                                                                 <p className={"form-input-error-space"}>
                                                                    {errors.answer ? errors.answer : null}
                                                                </p>
                                                        </div>
                                                       <div className="col-md-12">
                                                            <button
                                                                type="submit"
                                                                disabled={isSubmitting}
                                                                className="admin_add_button btn_zoom"
                                                            >
                                                                {t('zoom.addition')}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            )}
                                        </Formik>

                                    </div>
                                </div>
                            </>

                    }
                </div>
            </div>
        </div>

    )
}
