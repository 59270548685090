import FilterIcon from "../../../../assets/icons/filter.svg";
import PdfIcon from "../../../../assets/icons/pdf.svg";
import XlsIcon from "../../../../assets/icons/xls.svg";
import i18n from "../../../../i18n/i18n";
import { Lang } from "../../../../utils";

export const filterActions = ({ exportPdf, exportExcel, openModal, openIbanModal }) => {
  const actionsList = [
    ...(openIbanModal
      ? [
          {
            id: "iban",
            icon: i18n.language === Lang.AR ? "تعديل الحساب البنكي" : "Edit bank account",
            action: openIbanModal,
            theme: "new-button",
          },
        ]
      : []),
    {
      id: "filter",
      icon: i18n.language === Lang.AR ? "ايداع جديد في المحفظة" : "New deposit in wallet",
      action: openModal,
      theme: "new-button",
    },
    {
      id: "pdf",
      icon: <img src={PdfIcon} alt="" />,
      theme: "pdf",
      action: exportPdf,
    },
    {
      id: "xls",
      icon: <img src={XlsIcon} alt="" />,
      theme: "xls",
      action: exportExcel,
    },
  ];
  return actionsList;
};
