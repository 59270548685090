import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {getLearningAnalysisRegistered} from "../../../../Services/api/LearningAnalysis/LearningAnalysisService";
import {toast} from "react-toastify";

function LearningActivityRegisteredList(props) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [countries, setCountries] = useState(true);
    const { courseId } = useParams();

    useEffect(() => {
        fetchRegistered(courseId);
    }, []);

    const fetchRegistered = async (id = null) => {
        try {
            let registered = await getLearningAnalysisRegistered(id);
            let totalRegisteredCount = 0;
            registered.data.response.countries.forEach(item => {
                totalRegisteredCount += item.users_count
            })
            setCountries(registered.data.response.countries)
            setLoading(false);
        } catch (err) {
            toast.error(
                <span style={{fontSize: 13, fontWeight: "bold"}}>
                    {t("learning_analysis.failure.loading_registered")}
                </span>
            );
        }
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="admin_label">
                            <NavLink to="/admin">{t("admin.label.admin_label")}</NavLink>
                            <p style={{ padding: "0 5px" }}>/</p>
                            <NavLink to="/admin/learning-analysis">{t("learning_analysis.title")}</NavLink>
                            <p style={{ padding: "0 5px" }}>/</p>
                            {t("learning_analysis.registered.title")}
                        </div>
                        {loading == false ? (
                            <div className="table_bank_tran">
                                <div className="admin_label_card">
                                    <div className="">{t("learning_analysis.registered.number_of_registered_based_on_country")}</div>
                                </div>
                                <div className="table-responsive" style={{
                                    width: "90%"
                                }}>
                                    <table className="table table-bordered">
                                        <thead>
                                        <tr>
                                            <th scope="col">{t("learning_analysis.registered.country")}</th>
                                            <th scope="col">{t("learning_analysis.registered.count")}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {countries.map((item) => {
                                            return (
                                                <tr key={item.id}>
                                                    <td>{item.name}</td>
                                                    <td>{item.users_count}</td>
                                                </tr>
                                            );
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ) : (
                            <div
                                className="mt-5"
                                style={{
                                    height: "65vh",
                                }}
                            >
                                <SkeletonCard />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default LearningActivityRegisteredList;
