import { ErrorMessage, Field, FieldArray, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
import { toast } from "react-toastify";
import {
	ticketTypesAdd,
	ticketTypesCreate,
	ticketTypesEdit,
	ticketTypesUpdate,
} from "../../../../../Services/api/Tickets/TicketsProvider";
import Breadcrumb from "../../../../Shared/Components/Breadcrumb/Breadcrumb";
import SkeletonCard from "../../../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../../../Shared/Components/Spinner/SkeletonCardOverlay";
import "./Add.css";

function PlusIcon({}) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<g id="Group_105" data-name="Group 105" transform="translate(-347 -498)">
				<g id="plus-square" transform="translate(344.698 495.698)">
					<rect
						id="Rectangle_19"
						data-name="Rectangle 19"
						width="22"
						height="22"
						rx="2"
						transform="translate(3.302 3.302)"
						fill="none"
						stroke="#046c77"
						strokeLinecap="round"
						stroke-linejoin="round"
						stroke-width="2"
					/>
					<line
						id="Line_4"
						data-name="Line 4"
						y2="10"
						transform="translate(14.302 9.302)"
						fill="none"
						stroke="#046c77"
						strokeLinecap="round"
						stroke-linejoin="round"
						stroke-width="2"
					/>
					<line
						id="Line_5"
						data-name="Line 5"
						x2="10"
						transform="translate(9.302 14.302)"
						fill="none"
						stroke="#046c77"
						strokeLinecap="round"
						stroke-linejoin="round"
						stroke-width="2"
					/>
				</g>
			</g>
		</svg>
	);
}

function CloseIcon({ className }) {
	return (
		<svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
			<g id="x-circle_2_" data-name="x-circle (2)" transform="translate(-1 -1)">
				<circle
					id="Ellipse_23"
					data-name="Ellipse 23"
					cx="10"
					cy="10"
					r="10"
					transform="translate(2 2)"
					fill="none"
					stroke="#e62626"
					strokeLinecap="round"
					stroke-linejoin="round"
					stroke-width="2"
				/>
				<line
					id="Line_35"
					data-name="Line 35"
					x1="6"
					y2="6"
					transform="translate(9 9)"
					fill="none"
					stroke="#e62626"
					strokeLinecap="round"
					stroke-linejoin="round"
					stroke-width="2"
				/>
				<line
					id="Line_36"
					data-name="Line 36"
					x2="6"
					y2="6"
					transform="translate(9 9)"
					fill="none"
					stroke="#e62626"
					strokeLinecap="round"
					stroke-linejoin="round"
					stroke-width="2"
				/>
			</g>
		</svg>
	);
}

function AddTicketsType(props) {
	const { t } = useTranslation();
	const history = useHistory();

	const [hasSubmitted, setHasSubmitted] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [statuses, setStatuses] = useState([]);
	const [fileTypes, setFileTypes] = useState([]);
	const [filteredFilesTypes, setFilteredFileTypes] = useState([]);
	const [selectedFileTypes, setSelectedFileTypes] = useState([]);
	const [fileSizes, setFileSizes] = useState([
		{ value: 5, label: "5MB" },
		{ value: 10, label: "10MB" },
		{ value: 15, label: "15MB" },
	]);
	const [fieldRequirements, setFieldRequirements] = useState([]);
	const [globalIsSubmitting, setGlobalIsSubmitting] = useState(false);
	const [subjectsAssignees, setsubjectsAssignees] = useState([]);
	const [assignees, setAssignees] = useState([]);
	const [data, setData] = useState([]);
	const { id } = useParams();

	const allowedAttachmentTypesRef = useRef();

	useEffect(() => {
		async function executeOnLoad() {
			let response = id ? await ticketTypesEdit(id) : await ticketTypesAdd();
			if (response.status && response.status == 200) {
				setStatuses(response.data.data.statuses);
				setFieldRequirements(response.data.data.fieldRequirementRules);
				const filesTypesList = response?.data?.data?.fileTypes
				const filteredFilesTypesList = filesTypesList?.filter((item) => {
					return item.value !== "csv";
				})
				setFileTypes(filteredFilesTypesList ?? []);
				if (id) {
					const filesTypes = filteredFilesTypesList;
					const allowedFiles = response.data.data.ticketType?.allowed_files;
					const allowedExtensions = new Set(allowedFiles.map((file) => file.extension));

					// get filter file types from two array of objects
					const filteredFileTypes = filesTypes.filter((item) => {
						return !allowedExtensions.has(item.value);
					});
					setFilteredFileTypes(filteredFileTypes);
				} else {
					setFilteredFileTypes(filteredFilesTypesList);
				}
				if (id) {
					setData(response.data.data.ticketType);
					setAssignees(response.data.data.ticketType.subjects);

					let tempSelectedFileTypes = [];
					filteredFilesTypesList.forEach((item) => {
						if (response.data.data.ticketType.allowed_files?.includes(item.value)) {
							tempSelectedFileTypes.push(item);
						}
					});

					setSelectedFileTypes(tempSelectedFileTypes);
				}
				setIsLoading(false);
			}
		}

		executeOnLoad();
	}, []);

	const initialSubjectRowData = {
		name_ar: "",
		name_en: "",
	};

	const reformatAssignees = async () => {
		let filteredAssignees = [];
		await assignees.forEach((item) => {
			const filteredAssigneesItem = {};
			filteredAssigneesItem.name_ar = item.name_ar;
			filteredAssigneesItem.name_en = item.name_en;
			filteredAssignees.push(filteredAssigneesItem);
		});
		await setsubjectsAssignees(filteredAssignees);
	};

	useEffect(() => {
		if (assignees && assignees.length > 0) {
			reformatAssignees();
		}
	}, [assignees]);

	function breadcrumbList() {
		let breadcrumbList = [
			{
				id: t("admin.label.admin_label"),
				page: t("admin.label.admin_label"),
				pagePath: "/admin",
			},
			{
				id: t("admin.label.list_tickets"),
				page: t("admin.label.list_tickets"),
				pagePath: `/admin/tickets`,
			},
			{
				id: t("admin.label.list_ticket_types"),
				page: t("admin.label.list_ticket_types"),
				pagePath: `/admin/ticket/types`,
			},
		];
		if (id) {
			breadcrumbList.push({
				id: t("edit"),
				page: t("edit"),
				active: true,
			});
		} else {
			breadcrumbList.push({
				id: t("admin.label.create_ticket_types"),
				page: t("admin.label.create_ticket_types"),
				active: true,
			});
		}
		return breadcrumbList;
	}

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12 sm:tw-px-2 tw-py-8">
						{/*TODO*/}
						<Breadcrumb list={breadcrumbList()} />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						{isLoading ? (
							<div
								style={{
									height: "65vh",
								}}
							>
								<SkeletonCard />
							</div>
						) : (
							<div>
								{globalIsSubmitting ? <SkeletonCardOverlay borderRadius={20} /> : null}
								<Formik
									initialValues={{
										name_en: data?.names?.en ?? "",
										name_ar: data?.names?.ar ?? "",
										serial_prefix: data.serial_prefix ?? "",
										attachment: data.attachment?.value ?? "",
										allowed_files: data?.allowed_files?.length ? data.allowed_files : [{ size: "", extension: "" }],
										reopened_days: data.reopened_days ?? "",
										// hold_days: data.hold_days ?? "",
										status: data.status?.value ?? "active",
										should_rate: data.should_rate ?? false,
										subjects: data.subjects ? data.subjects : [initialSubjectRowData],
									}}
									onSubmit={async (values, { setSubmitting }) => {
										try {
											setGlobalIsSubmitting(true);
											setSubmitting(true);

											let payload = {
												...values,
												should_rate: values.should_rate ? 1 : 0,
											};

											if (payload?.attachment === "not allowed") {
												delete payload?.allowed_files;
											}

											let response = id ? await ticketTypesUpdate(id, payload) : await ticketTypesCreate(payload);
											if (response.status == 200 && response.data.status) {
												history.push("/admin/ticket/types");
												toast.success(
													<span style={{ fontSize: 13, fontWeight: "bold" }}>
														{response.data.msg ? response.data.msg : t("support.tickets_type.created_successfully")}
													</span>,
													{
														onClose: () => {},
														autoClose: 1000,
													}
												);
											} else {
												toast.error(
													<span style={{ fontSize: 13, fontWeight: "bold" }}>
														{t("support.tickets_type.failed_creating")}
													</span>
												);
											}
											setGlobalIsSubmitting(false);
											setSubmitting(false);
										} catch (err) {
											setSubmitting(false);
											toast.error(
												<div>
													<p style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.msg}</p>
													<ul>
														{Object.keys(err.response.data.errors).map((key) => {
															return (
																<li>
																	<span style={{ fontSize: 11 }}>{err.response.data.errors[key]}</span>
																</li>
															);
														})}
													</ul>
												</div>
											);
											setGlobalIsSubmitting(false);
										}
									}}
									validateOnChange={hasSubmitted}
									validate={async (values) => {
										setHasSubmitted(true);
										const errors = {};
										const subjectsErrors = [];
										const allowedFilesErrors = [];

										if (!values.name_en) {
											errors.name_en = t("crud.errors.required");
										}

										if (!values.name_ar) {
											errors.name_ar = t("crud.errors.required");
										}

										await values.subjects.forEach((item, index) => {
											const errorInstance = {};
											if (!item.name_en) {
												errorInstance.name_en = t("crud.errors.required");
											}

											if (!item.name_ar) {
												errorInstance.name_ar = t("crud.errors.required");
											}

											if (Object.keys(errorInstance).length !== 0) {
												subjectsErrors[index] = errorInstance;
											}
										});

										if (subjectsErrors.length > 0) {
											errors.subjects = subjectsErrors;
										}
										if (values.attachment != "not allowed") {
											await values.allowed_files.forEach((item, index) => {
												const errorInstance = {};
												if (!item.extension) {
													errorInstance.extension = t("crud.errors.required");
												}

												if (!item.size) {
													errorInstance.size = t("crud.errors.required");
												}

												if (Object.keys(errorInstance).length !== 0) {
													allowedFilesErrors[index] = errorInstance;
												}
											});

											if (allowedFilesErrors.length > 0) {
												errors.allowed_files = allowedFilesErrors;
											}
										}

										if (!values.serial_prefix) {
											errors.serial_prefix = t("crud.errors.required");
										}

										if (!values.reopened_days) {
											errors.reopened_days = t("crud.errors.required");
										} else if (values.reopened_days && values.reopened_days.toString().match(/(\D)/g)) {
											errors.reopened_days = t("crud.errors.positiveInteger");
										}

										// if (!values.hold_days) {
										// 	errors.hold_days = t("crud.errors.required");
										// } else if (values.hold_days && values.hold_days.toString().match(/(\D)/g)) {
										// 	errors.hold_days = t("crud.errors.positiveInteger");
										// }

										if (!values.status) {
											errors.status = t("crud.errors.required");
										}
										return errors;
									}}
								>
									{({
										values,
										errors,
										touched,
										handleChange,
										handleBlur,
										handleSubmit,
										isSubmitting,
										setFieldValue,
									}) => (
										<form onSubmit={handleSubmit} className="admin_add_form">
											<div className="tw-bg-white tw-shadow tw-rounded tw-p-8 tw-space-y-8 tw-mb-8">
												<div className="tw-flex tw-items-center tw-justify-between">
													<div className="tw-text-teal-500 tw-text-lg sm:tw-text-2xl">{t("crud.list.ticket_type")}</div>
													<button
														type="button"
														onClick={() => {
															setFieldValue("status", values.status == "active" ? "inactive" : "active");
														}}
														className={`tw-relative tw-w-20 tw-py-2 tw-px-1 tw-rounded-full tw-flex tw-items-center tw-justify-between tw-transition-all ${
															values.status == "active"
																? "tw-bg-teal-800 tw-text-white tw-pl-7"
																: "tw-bg-gray-200 !tw-text-gray-700 tw-pr-7"
														}`}
													>
														<div className="tw-text-xs tw-white-space-nowrap tw-grow tw-text-center">
															{t(values.status == "active" ? "Exhibition.active" : "Exhibition.inactive")}
														</div>
														<div
															className={`tw-absolute tw-top-1/2 -tw-translate-y-1/2 ${
																values.status == "active" ? "tw-left-2" : "tw-right-2"
															} tw-h-5 tw-w-5 tw-bg-white tw-shadow tw-rounded-full`}
														></div>
													</button>
												</div>

												<div className="tw-space-y-4">
													<div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-4">
														<div>
															<label className="tw-block mb-0">
																<div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("crud.list.name_en")}</div>

																<Field
																	name="name_en"
																	className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
																	placeholder={t("crud.placeholders.name_en")}
																/>
															</label>
															<ErrorMessage
																name={`name_en`}
																component="div"
																className="tw-text-xs tw-text-red-700 tw-h-4"
															/>
														</div>
														<div>
															<label className="tw-block mb-0">
																<div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("crud.list.name_ar")}</div>

																<Field
																	name="name_ar"
																	className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
																	placeholder={t("crud.placeholders.name_ar")}
																/>
															</label>
															<ErrorMessage
																name={`name_ar`}
																component="div"
																className="tw-text-xs tw-text-red-700 tw-h-4"
															/>
														</div>
													</div>

													<div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-4">
														<div>
															<label className="tw-block mb-0">
																<div className="tw-text-gray-600 tw-font-medium tw-mb-2">
																	{t("crud.list.sequence_opening")}
																</div>

																<Field
																	name="serial_prefix"
																	className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
																	placeholder={t("crud.placeholders.sequence_opening")}
																/>
															</label>
															<ErrorMessage
																name={`serial_prefix`}
																component="div"
																className="tw-text-xs tw-text-red-700 tw-h-4"
															/>
														</div>
														<div>
															<label className="tw-block mb-0">
																<div className="tw-text-gray-600 tw-font-medium tw-mb-2">
																	{t("crud.placeholders.select.file_selection")}
																</div>
																<Select
																	name="attachment"
																	id="attachment"
																	options={fieldRequirements}
																	defaultValue={fieldRequirements.find((item) => item.value == values.attachment)}
																	getOptionLabel={(option) => option.text}
																	getOptionValue={(option) => option.value}
																	onChange={(item) => {
																		setFieldValue("attachment", item.value);
																	}}
																	placeholder={t("crud.placeholders.select.file_selection")}
																/>
															</label>
															<ErrorMessage
																name={`name_ar`}
																component="div"
																className="tw-text-xs tw-text-red-700 tw-h-4"
															/>
														</div>
													</div>

													<div>
														<label className="tw-block mb-0">
															<div className="tw-text-gray-600 tw-font-medium tw-mb-2">
																{t("client_response_waitting_time")}
															</div>

															<Field
																name="reopened_days"
																className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
																placeholder={t("client_response_waitting_time")}
															/>
														</label>
														<ErrorMessage
															name={`reopened_days`}
															component="div"
															className="tw-text-xs tw-text-red-700 tw-h-4"
														/>
													</div>
													<div className="tw-py-1">
														<hr className="tw-border-gray-300 tw-border-dashed" />
													</div>
													{values.attachment != "not allowed" ? (
														<>
															<div className="tw-space-y-4">
																<div className="tw-text-gray-500 tw-text-xl">{t(`ticket.files_type`)}</div>
																<FieldArray name={`allowed_files`}>
																	{({ insert, remove, push }) => (
																		<div className="tw-space-y-3">
																			{values.allowed_files?.length ? (
																				values.allowed_files.map((item, index) => (
																					<div key={index}>
																						<div className="tw-flex tw-items-start tw-space-s-4">
																							<div className="tw-grow tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-4">
																								<div>
																									<label className="tw-block mb-0">
																										<div className="tw-text-gray-600 tw-font-medium tw-mb-2">
																											{t("crud.placeholders.select.file_types")}
																										</div>
																										<Select
																											name={`allowed_files.${index}.extension`}
																											id={`allowed_files.${index}.extension`}
																											options={filteredFilesTypes}
																											value={
																												fileTypes.find(
																													(item) => item.value == values.allowed_files[index].extension
																												) || ""
																											}
																											defaultValue={
																												fileTypes.find(
																													(item) => item.value == values.allowed_files[index].extension
																												) || ""
																											}
																											getOptionLabel={(option) => option.text}
																											getOptionValue={(option) => option.value}
																											onChange={(item) => {
																												setFieldValue(`allowed_files.${index}.extension`, item.value);

																												const oldFileTypes = values.allowed_files[index].extension;
																												const newFileTypes = filteredFilesTypes.filter(
																													(fileType) => fileType.value != item.value
																												);
																												const oldValue = fileTypes.find(
																													(fileType) => fileType.value == oldFileTypes
																												);
																												if (oldValue) {
																													newFileTypes.push(oldValue);
																												}
																												setFilteredFileTypes([...newFileTypes]);
																											}}
																											placeholder={t("crud.placeholders.select.file_types")}
																										/>
																									</label>
																									<ErrorMessage
																										name={`allowed_files.${index}.extension`}
																										component="div"
																										className="tw-text-xs tw-text-red-700 tw-h-4"
																									/>
																								</div>
																								<div>
																									<label className="tw-block mb-0">
																										<div className="tw-text-gray-600 tw-font-medium tw-mb-2">
																											{t("crud.placeholders.select.maximum_size")}
																										</div>
																										<Field
																											name={`allowed_files.${index}.size`}
																											className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
																											placeholder={t("TypeFile.sizeType")}
																											type="text"
																										/>
																									</label>
																									<ErrorMessage
																										name={`allowed_files.${index}.size`}
																										component="div"
																										className="tw-text-xs tw-text-red-700 tw-h-4"
																									/>
																								</div>
																							</div>

																							<button
																								type="button"
																								onClick={async () => {
																									remove(index);
																									const selectedItem = fileTypes.find(
																										(file) => file.value == values.allowed_files[index].extension
																									);
																									if (selectedItem) {
																										setFilteredFileTypes([...filteredFilesTypes, selectedItem]);
																									}
																								}}
																								className="tw-pt-12"
																							>
																								<CloseIcon className="tw-w-5 tw-h-5" />
																							</button>
																						</div>
																					</div>
																				))
																			) : (
																				<></>
																			)}

																			<div className="tw-relative tw-py-4">
																				<hr className="tw-border-gray-300 tw-border-dashed" />
																				<button
																					type="button"
																					onClick={() => {
																						if (values.allowed_files?.length >= fileTypes?.length) return;
																						push({
																							size: "",
																							extension: "",
																						});
																					}}
																					className="add-file-type tw-flex tw-items-center tw-space-s-2 tw-absolute tw-left-1/2 -tw-translate-x-1/2 tw-top-1/2 -tw-translate-y-1/2 tw-bg-white tw-px-3 tw-text-teal-700"
																					disabled={values.allowed_files?.length >= fileTypes?.length}
																				>
																					<div className="tw-h-4 tw-w-4">
																						<PlusIcon />
																					</div>
																					<div>{t("add_new_file_type")}</div>
																				</button>
																			</div>
																		</div>
																	)}
																</FieldArray>
															</div>
															<div className="tw-py-1">
																<hr className="tw-border-gray-300 tw-border-dashed" />
															</div>
														</>
													) : (
														<></>
													)}

													{/* <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <div
                              style={{
                                flex: 1,
                                paddingLeft: 15,
                                paddingRight: 15,
                              }}
                            >
                              <label
                                htmlFor="reopened_days"
                                className="admin_add_label"
                              >
                                {t("crud.placeholders.allowance_days")}
                              </label>
                              <input
                                id="reopened_days"
                                type="text"
                                name="reopened_days"
                                className="admin_add_input"
                                onChange={handleChange}
                                placeholder={t(
                                  "crud.placeholders.allowance_days"
                                )}
                                value={values.reopened_days}
                              />
                              <p className={"form-input-error-space"}>
                                {errors.reopened_days
                                  ? errors.reopened_days
                                  : null}
                              </p>
                            </div>

                            <div
                              style={{
                                flex: 1,
                                paddingLeft: 15,
                                paddingRight: 15,
                              }}
                            >
                              <label
                                htmlFor="hold_days"
                                className="admin_add_label"
                              >
                                {t("crud.placeholders.waiting_days")}
                              </label>
                              <input
                                id="hold_days"
                                type="text"
                                name="hold_days"
                                className="admin_add_input"
                                onChange={handleChange}
                                placeholder={t(
                                  "crud.placeholders.waiting_days"
                                )}
                                value={values.hold_days}
                              />
                              <p className={"form-input-error-space"}>
                                {errors.hold_days ? errors.hold_days : null}
                              </p>
                            </div>
                          </div> */}
													<div className="tw-space-y-4">
														<div className="tw-text-gray-500 tw-text-xl">{t(`ticket.subjects`)}</div>
														<FieldArray name={`subjects`}>
															{({ insert, remove, push }) => (
																<div className="tw-space-y-3">
																	{values.subjects?.length ? (
																		values.subjects.map((item, index) => (
																			<div key={index}>
																				<div className="tw-flex tw-items-start tw-space-s-4">
																					<div className="tw-grow tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-4">
																						<div>
																							<label className="tw-block mb-0">
																								<div className="tw-text-gray-600 tw-font-medium tw-mb-2">
																									{t("crud.label.subject_name_ar")}
																								</div>
																								<Field
																									name={`subjects.${index}.name_ar`}
																									className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
																									placeholder={t("crud.label.subject_name_ar")}
																									type="text"
																								/>
																							</label>
																							<ErrorMessage
																								name={`subjects.${index}.name_ar`}
																								component="div"
																								className="tw-text-xs tw-text-red-700 tw-h-4"
																							/>
																						</div>
																						<div>
																							<label className="tw-block mb-0">
																								<div className="tw-text-gray-600 tw-font-medium tw-mb-2">
																									{t("crud.label.subject_name_en")}
																								</div>
																								<Field
																									name={`subjects.${index}.name_en`}
																									className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
																									placeholder={t("crud.label.subject_name_en")}
																									type="text"
																								/>
																							</label>
																							<ErrorMessage
																								name={`subjects.${index}.name_en`}
																								component="div"
																								className="tw-text-xs tw-text-red-700 tw-h-4"
																							/>
																						</div>
																					</div>

																					<button
																						type="button"
																						onClick={async () => {
																							remove(index);
																						}}
																						className="tw-pt-12"
																					>
																						<CloseIcon className="tw-w-5 tw-h-5" />
																					</button>
																				</div>
																			</div>
																		))
																	) : (
																		<></>
																	)}

																	<div className="tw-relative tw-py-4">
																		<hr className="tw-border-gray-300 tw-border-dashed" />
																		<button
																			type="button"
																			onClick={() =>
																				push({
																					name_ar: "",
																					name_en: "",
																				})
																			}
																			className="tw-flex tw-items-center tw-space-s-2 tw-absolute tw-left-1/2 -tw-translate-x-1/2 tw-top-1/2 -tw-translate-y-1/2 tw-bg-white tw-px-3 tw-text-teal-700"
																		>
																			<div className="tw-h-4 tw-w-4">
																				<PlusIcon />
																			</div>
																			<div>{t("crud.placeholders.add_new_subject")}</div>
																		</button>
																	</div>
																</div>
															)}
														</FieldArray>
													</div>

													<div className="tw-flex tw-items-center tw-justify-between tw-space-s-4 tw-pt-8">
														<div>
															<label className="tw-flex tw-items-center tw-space-s-2 tw-mb-2">
																<Field
																	name="should_rate"
																	type="checkbox"
																	className="!tw-w-auto tw-border tw-border-gray-300 tw-rounded tw-text-teal-700"
																/>
																<div className="tw-text-gray-600 tw-font-medium">
																	{t("crud.placeholders.select.support_rating")}
																</div>
															</label>
														</div>
														<button
															type="submit"
															disabled={isSubmitting}
															className="tw-bg-teal-700 tw-py-2 tw-px-16 tw-rounded tw-text-white"
														>
															{t("crud.placeholders.submit")}
														</button>
													</div>
												</div>
											</div>
										</form>
									)}
								</Formik>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
}

export default AddTicketsType;
