import { Pagination } from "@mui/material";
import { Formik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import toLocalTimezone from "../../../../Services/Timezone";
import {
  exportCouponUsers,
  getCouponInfo,
  getCouponUsers,
} from "../../../../Services/api/coupon/couponProvider";
import HomeIcon from "../../../../assets/icons/home.svg";
import { Lang } from "../../../../utils";
import { getLocaleTime } from "../../../../utils/utcToLocal";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import DataTable from "../../../Shared/Components/DataTable/DataTable";
import NewDataTable from "../../../Shared/Components/DataTable/NewDataTable";
import Input from "../../../Shared/Components/Input/Input";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import MainDatePicker from "../../../Shared/Components/MainDatePicker/MainDatePicker";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import { filterActions } from "./CouponTableActionUser";
import CouponUserTable from "./CouponUserTable";
import CouponUsersTableFilter from "./CouponUsersTableFilter";
import classes from "./couponDetails.module.css";

const CouponDetails = ({ type }) => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const roles = JSON.parse(localStorage.getItem("roles"));
  const role = roles.includes("admin")
    ? "admin"
    : roles.includes("manager")
    ? "manager"
    : roles.includes("trainer")
    ? "trainer"
    : "trainee";

  const [isExportLoading, setIsExportLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [currentCouponFilter, setCurrentCouponFilter] = useState({});
  const [filterData, setFilterData] = useState({
    payer_name: "",
    payer_email: "",
    creation_date: "",
  });
  const [tableData, setTableData] = useState(null);
  const [tableMeta, setTableMeta] = useState({});

  const [couponDetails, setCouponDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const showFilterHandler = () => {
    setShowFilter((prev) => !prev);
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
  };

  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };

  const resetFilterHandler = () => {
    setFilterData({
      payer_name: "",
      payer_email: "",
      creation_date: "",
    });
    setIsResetApplied(true);
  };

  const getFilterData = () => {
    const filter = { perPage: 10, page: currentPage };
    filterData.payer_name && (filter.payer_name = filterData.payer_name);
    filterData.payer_email && (filter.payer_email = filterData.payer_email);
    filterData.creation_date &&
      (filter.creation_date = filterData.creation_date);

    return filter;
  };

  const getTableDataHandler = async () => {
    setIsTableLoading(true);
    const filter = getFilterData();
    await getCouponUsers(id, filter) /////////////
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setTableData(res?.data?.data?.users?.data);
          setTableMeta(res?.data?.data?.users?.meta);
          setCurrentCouponFilter(filter);
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          return history.push("/admin/page-not-found");
        }
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {error?.response?.data?.msg
              ? error?.response?.data?.msg
              : "Failure in service"}
          </span>
        );
      })
      .finally(() => {
        setIsTableLoading(false);
      });
  };

  useEffect(() => {
    getTableDataHandler();
    if (isResetApplied) {
      setIsResetApplied(false);
    }
  }, [currentPage, isResetApplied]);

  const getCouponHandler = async () => {
    await getCouponInfo(id)
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setCouponDetails(res?.data?.response);
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          return history.push("/admin/page-not-found");
        }
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {error?.response?.data?.msg
              ? error?.response?.data?.msg
              : "Failure in service"}
          </span>
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    ...(type === "sendCoupon"
      ? [
          {
            id: "privateProfile",
            page: t("sidebar.label.private_profile"),
            pagePath: "/private-profile",
          },
          {
            id: `sent_coupon`,
            page: t(`private_profile.sent_coupon_gift`),
            pagePath: `/private-profile/sent-coupons`,
          },
        ]
      : [
          {
            id: "coupon-list",
            page: t(`coupon.label.${type === "gift" ? "gift_" : ""}coupon`),
            pagePath: `${
              localStorage.getItem("type") == "accountant"
                ? "/accountant"
                : "/admin"
            }/${type === "gift" ? "gift-" : ""}coupon`,
          },
        ]),
    {
      id: "create_exhibition",
      page: couponDetails?.name,
      active: true,
    },
  ];

  const traineesFields = [
    {
      id: "name",
      column: ({ rowData }) => (
        <div>
          {i18n.language === Lang.AR ? rowData?.name_ar : rowData?.name_en}
        </div>
      ),
    },
    {
      id: "email",
      column: ({ rowData }) => <div>{rowData?.email}</div>,
    },
  ];

  const fields = (type) => {
    const createField = (
      id,
      name,
      valueFormatter = (value) => value || "-"
    ) => ({
      id,
      name: t(`general.${name}`),
      column: ({ rowData }) => <>{valueFormatter(rowData[id])}</>,
    });
    return [
      createField("id", "id"),
      ...(type === "courses"
        ? [
            createField("name", "name"),
            createField("code", "code"),
            createField("run_serial_number", "serial_number"),
            createField("start_date", "start_date", (value) =>
              value ? moment(value).format("YYYY-MM-DD HH:mm:ss") : "-"
            ),
          ]
        : []),
      ...(type === "trainees"
        ? [
            // name_ar, name_en
            ...(i18n.language === Lang.AR
              ? [createField("name_ar", "name")]
              : [createField("name_en", "name")]),
            createField("email", "email", (value) =>
              value ? (
                <a href={`mailto:${value}`} className={classes["email-link"]}>
                  {value}
                </a>
              ) : (
                "-"
              )
            ),
          ]
        : []),
    ];
  };

  const dataTables = {
    courses: {
      label:
        couponDetails?.parent_type == 1
          ? t("coupon.label.course")
          : t("coupon.label.program"),
      data: couponDetails?.parent ? [couponDetails?.parent] : [],
      fields: fields("courses"),
      searchableFields: ["id", "name", "code", "run_serial_number"],
    },
    trainees: {
      label: t("coupon.label.direct_to"),
      data: couponDetails
        ? [...couponDetails?.trainees, ...couponDetails?.guests]
        : [],
      fields: fields("trainees"),
      searchableFields: ["id", "name", "email"],
    },
  };

  useEffect(() => {
    getCouponHandler();
  }, [id]);

  const exportHandler = async (type) => {
    setIsExportLoading(true);
    try {
      await exportCouponUsers(type, currentCouponFilter, id);
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };

  return (
    <div className={classes["coupon-details"]}>
      <div className="container-fluid">
        <div className={classes["coupon-details__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {isLoading ? (
                <div
                  style={{
                    height: "65vh",
                    width: "100%",
                  }}
                >
                  <SkeletonCard />
                </div>
              ) : (
                <MainBox>
                  {!couponDetails?.is_valid && (
                    <div className={classes["coupon-details__warning__note"]}>
                      {t("coupon.messages.coupon_in_valid")}
                    </div>
                  )}
                  <MainBoxHead
                    title={couponDetails?.name}
                    // actions={[
                    //   ...(role !== "trainee"
                    //     ? [
                    //         {
                    //           id: "coupon-status",
                    //           type: "switch",
                    //           value: true,
                    //           active: t("general.active"),
                    //           inactive: t("general.inactive"),
                    //           checked: couponDetails?.is_valid,
                    //           disabled: true,
                    //         },
                    //       ]
                    //     : []),
                    // ]}
                  />
                  <div
                    className={`row ${classes["coupon-details__form__fields"]}`}
                  >
                    <div
                      className={`${classes["coupon-details__form__field"]} col-12`}
                    >
                      <Input
                        label={t("coupon.placeholders.coupon_name")}
                        type="text"
                        name="name"
                        placeholder={t("coupon.placeholders.coupon_name")}
                        value={couponDetails?.name}
                        readonly={true}
                      />
                    </div>
                    {type !== "notGift" && (
                      <>
                        <div
                          className={`${classes["coupon-details__form__field"]} col-12 col-md-6`}
                        >
                          <Input
                            label={t("coupon.label.sender_name")}
                            type="text"
                            name="name"
                            placeholder={t("coupon.label.sender_name")}
                            value={
                              couponDetails?.sender[
                                i18n.language === "ar" ? "name_ar" : "name_en"
                              ]
                            }
                            readonly={true}
                          />
                        </div>
                        <div
                          className={`${classes["coupon-details__form__field"]} col-12 col-md-6`}
                        >
                          <Input
                            label={t("coupon.label.gift_date")}
                            type="text"
                            name="start_date"
                            placeholder={t("coupon.label.gift_date")}
                            value={moment(
                              getLocaleTime(new Date(couponDetails?.created_at))
                            ).format("YYYY-MM-DD A hh:mm")}
                            readonly={true}
                          />
                        </div>
                      </>
                    )}
                    <div
                      className={`${classes["coupon-details__form__field"]} col-12 col-md-6`}
                    >
                      <Input
                        label={t(
                          `coupon.label.${
                            type === "notGift" ? "" : "gift_"
                          }max_times`
                        )}
                        type="text"
                        name="name"
                        placeholder={t(
                          `coupon.label.${
                            type === "notGift" ? "" : "gift_"
                          }max_times`
                        )}
                        value={couponDetails?.times}
                        readonly={true}
                      />
                    </div>
                    <div
                      className={`${classes["coupon-details__form__field"]} col-12 col-md-6`}
                    >
                      <Input
                        label={t("coupon.label.used_times")}
                        type="text"
                        name="name"
                        placeholder={t("coupon.label.used_times")}
                        value={
                          Number(couponDetails?.approved_payments_count) +
                          Number(couponDetails?.waiting_payments_count)
                        }
                        readonly={true}
                      />
                    </div>
                    <div
                      className={`${classes["coupon-details__form__field"]} col-12 col-md-6`}
                    >
                      <Input
                        label={t("coupon.label.machinsim")}
                        type="text"
                        name="mechanism"
                        placeholder={t("coupon.label.machinsim")}
                        value={
                          couponDetails?.mechanism == "2"
                            ? t("coupon.label.per_value")
                            : t("coupon.label.co_value")
                        }
                        readonly={true}
                      />
                    </div>
                    <div
                      className={`${classes["coupon-details__form__field"]} col-12 col-md-6`}
                    >
                      <Input
                        label={t("coupon.placeholders.value_coupon")}
                        type="text"
                        name="value"
                        placeholder={t("coupon.placeholders.value_coupon")}
                        value={couponDetails?.value}
                        readonly={true}
                      />
                    </div>
                    <div
                      className={`${classes["coupon-details__form__field"]} col-12 col-md-6`}
                    >
                      <Input
                        label={t("general.start_date_time")}
                        type="text"
                        name="start_date"
                        placeholder={t("general.start_date_time")}
                        value={moment(
                          getLocaleTime(new Date(couponDetails?.start_date))
                        ).format("YYYY-MM-DD A hh:mm")}
                        readonly={true}
                      />
                    </div>
                    <div
                      className={`${classes["coupon-details__form__field"]} col-12 col-md-6`}
                    >
                      <Input
                        label={t("general.end_date_time")}
                        type="text"
                        name="end_date"
                        placeholder={t("general.end_date_time")}
                        value={moment(
                          getLocaleTime(new Date(couponDetails?.end_date))
                        ).format("YYYY-MM-DD A hh:mm")}
                        readonly={true}
                      />
                    </div>
                    <div
                      className={`${classes["coupon-details__form__field"]} col-12`}
                    >
                      <Formik>
                        {() => (
                          <NewDataTable
                            label={dataTables.trainees.label}
                            selectedItems={
                              couponDetails?.trainees.map((item) => item.id) ||
                              []
                            }
                            data={dataTables.trainees.data}
                            fields={dataTables.trainees.fields}
                            isMultiple={true}
                            searchableFields={
                              dataTables.trainees.searchableFields
                            }
                            disabled={true}
                          />
                        )}
                      </Formik>
                    </div>
                    <div
                      className={`${classes["coupon-details__form__field"]} col-12`}
                    >
                      <Input
                        label={t("general.type")}
                        type="text"
                        name="course_program"
                        placeholder={t("general.type")}
                        value={
                          couponDetails?.parent_type == 1
                            ? t("coupon.label.course")
                            : t("coupon.label.program")
                        }
                        readonly={true}
                      />
                    </div>
                    <div
                      className={`${classes["coupon-details__form__field"]} col-12`}
                    >
                      <Formik>
                        {() => (
                          <NewDataTable
                            label={dataTables.courses.label}
                            selectedItems={
                              couponDetails?.parent?.id
                                ? [couponDetails?.parent?.id]
                                : []
                            }
                            data={dataTables.courses.data}
                            fields={dataTables.courses.fields}
                            searchableFields={
                              dataTables.courses.searchableFields
                            }
                            disabled={true}
                          />
                        )}
                      </Formik>
                    </div>
                    {type !== "notGift" && couponDetails?.parent_type == 2 && (
                      <div
                        className={`${classes["coupon-details__form__field"]} col-12`}
                      >
                        <Input
                          label={t("financial.label.payment")}
                          type="text"
                          name="name"
                          placeholder={t("financial.label.payment")}
                          value={`${t("general.payment")} ${
                            couponDetails?.parent?.payment_number
                          }`}
                          readonly={true}
                        />
                      </div>
                    )}
                  </div>
                  <MainBoxHead
                    title={t("coupon.label.coupon_users")}
                    actions={filterActions({
                      showFilter: showFilterHandler,

                      exportPdf: () => {
                        exportHandler("pdf");
                      },
                      exportExcel: () => {
                        exportHandler("xlsx");
                      },
                    })}
                  />
                  {showFilter && (
                    <CouponUsersTableFilter
                      filterData={filterData}
                      changeHandler={changeHandler}
                      resetHandler={resetHandler}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      filterHandler={getTableDataHandler}
                      resetFilterHandler={resetFilterHandler}
                    />
                  )}
                  <CouponUserTable
                    isTableLoading={isTableLoading}
                    tableData={tableData}
                  />
                  <Pagination
                    count={tableMeta?.totalPages || 0}
                    showFirstButton
                    showLastButton
                    variant="outlined"
                    shape="rounded"
                    className="main-pagination"
                    onChange={(e, page) => {
                      setCurrentPage(page);
                    }}
                  />
                </MainBox>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CouponDetails;
