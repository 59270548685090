import React from "react";
import Footer from "../../Shared/Components/footer/footer";
import {useTranslation} from "react-i18next";
import {CertificateHeader} from "./";
import cer from "../../../assets/image/ce.png";

function ShowCertificate() {
  require("./showCertificate.css");
  const { t } = useTranslation();

  return (
    <div>
      <CertificateHeader />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 profiles">
            <div className="training_first_label ">{t("hometraineee")}</div>

            <div className="trainee_tag">{t("hakemcer")}</div>

            <div className="trainee_cer_image">
              <img src={cer} alt="..." className="cer_img" />
            </div>
            <div className="trainee_cer_btn">
              <button className="cer_btn">{t("downloadcer")}</button>
            </div>

            <div className="trainee_cer_dlabel">{t("mastered")}</div>
            <ul className="trainee_cer_dlist">
              <li>{t("domeText1")} </li>
              <li>{t("domeText1")} </li>
              <li>{t("domeText1")} </li>
              <li>{t("domeText1")} </li>
              <li>{t("domeText1")} </li>
              <li>{t("domeText1")} </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ShowCertificate;
