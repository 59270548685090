import React, {createContext, useState} from "react";

const FooterContext = createContext();

function FooterProvider(props) {
  const [footer, setFooter] = useState(true);

  return (
    <FooterContext.Provider value={{ footer, setFooter }}>
      {props.children}
    </FooterContext.Provider>
  );
}

export { FooterContext, FooterProvider };
