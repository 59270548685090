import { useTranslation } from "react-i18next";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import StarsRating from "../../../Shared/Components/StarsRating/StarsRating";
const VisitsTable = ({ tableData, sortActionHandler }) => {
  const { t, i18n } = useTranslation();

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData }) => <>{rowData?.id}</>,
    },
    {
      id: "name",
      name: t("Exhibition.user_name"),
      key: "name",
      cell: ({ rowData }) => <>{rowData?.name || "-"}</>,
      sortable: false,
    },
    {
      id: "email",
      name: t("booth.email"),
      key: "email",
      cell: ({ rowData }) => <>{rowData?.email || "-"}</>,
      sortable: false,
    },
    {
      id: "phone",
      name: t("booth.phone"),
      key: "phone",
      cell: ({ rowData }) => <>{rowData?.phone === "null" ? "-" : rowData?.phone || "-"}</>,
      sortable: false,
    },
    {
      id: "country",
      name: t("country"),
      key: "country",
      cell: ({ rowData }) => <>{rowData?.country || "-"}</>,
      sortable: false,
    },

    {
      id: "rating",
      name: t("Exhibition.rating"),
      key: "rating",
      cell: ({ rowData }) => (
        <StarsRating
          defaultColor="#ededed"
          rating={rowData?.rating?.stars || 0}
          ratingComment={rowData?.rating?.description}
        />
      ),
      sortable: false,
    },
  ];

  return (
    <div>
      <MainTable columns={columns} data={tableData} isLoading={false} sortAction={sortActionHandler} />
    </div>
  );
};

export default VisitsTable;
