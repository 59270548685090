import {TrainerAddExamProvider} from "../../../../../Providers/TrainerAddExamProvider";
import AddExam from "../Add";
import {useLocation} from "react-router-dom";

function AddExamWrapper(props) {
    const location = useLocation();

    return (
        <>
            <TrainerAddExamProvider>
                <AddExam
                    quizable={props.quizable}
                    editable={props.editMode}
                    questionTypes={location.state?.questionTypes}
                    courseObjectives={location.state?.courseObjectives}
                    trainees={location.state?.trainees}
                    examInstance={location.state?.examInstance}
                    activePanel={location.state?.activePanel}
                    selectedQuestionPanel={location.state?.selectedQuestionPanel}
                ></AddExam>
            </TrainerAddExamProvider>
        </>
    )
}

export default AddExamWrapper;
