import env from "./Environment";
import connector from "./Handler";
import URLBuilder from "./UrlBuilder";

export function getAllAvailableCourses() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_all_available_courses)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function getAdHome(perPage, currentPage) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_Ad, {
          params: {
            perPage: perPage,
            page: currentPage,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function getAdHomepage() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_Ad)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function getAdDetails(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_ad_details, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getAllAvailablePrograms() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_all_available_programs)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getAllSpecialties(params) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_all_specialties, { params })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function getSearchResults(params) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_search_results, { params: params })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function getSearchAutoComplete() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_search_autoComplete)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
