import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import i18n from "../../../i18n/i18n";
import { Lang } from "../../../utils";
import CourseCard from "../../Shared/Components/CourseCard/CourseCard";
import classes from "./trainersCourseDetails.module.css";

export default function TrainersCourseDetails({ trainerCourses, loading }) {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className={classes["trainer-courses"]}>
      {!loading &&
        trainerCourses &&
        trainerCourses.map((course) => (
          <div key={course.id} className={classes["course-card"]}>
            <CourseCard
              img={course.img}
              rightimgbadge={course.is_expired ? t("general.expire") : t("general.now")}
              rightcolorbadge={course.is_expired ? "#8599B6" : "#24B951"}
              leftimgbadge={
                course.mode === "Paid"
                  ? t("general.paid")
                  : course.mode === "Free"
                  ? t("general.free")
                  : course.mode == "programmed"
                  ? t("general.program")
                  : ""
              }
              leftcolorbadge={
                course.mode === "Paid"
                  ? "#2E3E5F"
                  : course.mode === "Free"
                  ? "#FF1414"
                  : course.mode == "programmed"
                  ? "#73C2B5"
                  : ""
              }
              badgelabel={i18n.language === Lang.AR ? course.department_name_ar : course.department_name_en}
              badgecolor="#24B3B9"
              title={course.name}
              price={course.price}
              date={course.start_date.split(" ")[0]}
              seat={`${course.participators}/${course.number_of_users}`}
              hour={course.number_hours}
              levelbadge={
                course.level === "1"
                  ? t("levels.beginner")
                  : course.level === "2"
                  ? t("levels.medium")
                  : course.level === "3"
                  ? t("levels.professional")
                  : ""
              }
              level={course.level}
              handleSubmit={() =>
                history.push(`/${course.is_program ? "training-programs" : "training-courses"}/${course.id}/details`)
              }
              expired={course.is_expired}
              btn={course.is_expired ? t("general.endcourse") : t("general.registercourse")}
              //   userdata={true}
              //   userimg={course.trainer.img}
              //   username={course.trainer.name}
              //   userrate={3}
            />
          </div>
        ))}
    </div>
  );
}
