import { useTranslation } from "react-i18next";

import FilterBox from "../../../Shared/Components/FilterBox/FilterBox";

const PeymentsRequestFilter = ({
  filterData,
  changeHandler,
  resetHandler,
  currentPage,
  setCurrentPage,
  filterHandler,
  resetFilterHandler,
  isProgram,
  courses,
  programs,
  role,
}) => {
  const { t, i18n } = useTranslation();

  const parentTypeOptions = [
    {
      label: t("coupon.label.course"),
      id: 1,
      value: 1,
    },
    {
      label: t("coupon.label.program"),
      id: 2,
      value: 2,
    },
  ];

  const statusOptions = [
    {
      label: t("general.accepted"),
      id: 1,
      value: "Accepted",
    },
    {
      label: t("general.rejected"),
      id: 2,
      value: "Rejected",
    },
  ];

  const filterFields = [
    {
      id: "is_program",
      label: t("coupon.label.select_course_or_program"),
      component: "select",
      placeholder: t("coupon.label.select_course_or_program"),
      initialValue: t("coupon.label.select_course_or_program"),
      name: "is_program",
      value: filterData.is_program,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: parentTypeOptions || [],
    },

    ...(filterData.is_program == 1
      ? [
          {
            id: "course_id",
            label: t("coupon.label.select_course"),
            component: "select",
            placeholder: t("coupon.label.select_course"),
            initialValue: t("coupon.label.select_course"),
            name: "course_id",
            value: filterData.course_id,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            options: courses?.length
              ? courses?.map((course) => ({
                  id: course.id,
                  value: course.id,
                  label: course.name,
                }))
              : [],
          },
          {
            id: "course_code",
            component: "input",
            label: t("financial.payments_request.course_code"),
            type: "text",
            placeholder: t("financial.payments_request.course_code"),
            name: "course_code",
            value: filterData.course_code,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            onReset: resetHandler,
          },
          {
            id: "run_serial_number",
            component: "input",
            label: t("financial.payments_request.course_run_serial_number"),
            type: "text",
            placeholder: t(
              "financial.payments_request.course_run_serial_number"
            ),
            name: "run_serial_number",
            value: filterData.run_serial_number,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            onReset: resetHandler,
          },
        ]
      : []),
    ...(filterData.is_program == 2
      ? [
          {
            id: "program_id",
            label: t("coupon.label.select_program"),
            component: "select",
            placeholder: t("coupon.label.select_program"),
            initialValue: t("coupon.label.select_program"),
            name: "program_id",
            value: filterData.program_id,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            options: programs?.length
              ? programs?.map((program) => ({
                  id: program.id,
                  value: program.id,
                  label: program.name,
                }))
              : [],
          },
          {
            id: "program_code",
            component: "input",
            label: t("financial.payments_request.program_code"),
            type: "text",
            placeholder: t("financial.payments_request.program_code"),
            name: "program_code",
            value: filterData.program_code,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            onReset: resetHandler,
          },
        ]
      : []),

    ...(role === "trainer"
      ? [
          {
            id: "start_date",
            component: "datePicker", ////////////////////////
            label: t("financial.payments_request.from_date"),

            placeholder: t("financial.payments_request.from_date"),
            name: "start_date",
            value: filterData.start_date,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            onReset: resetHandler,
          },
          {
            id: "end_date",
            component: "datePicker", ////////////////////////
            label: t("financial.payments_request.to_date"),
            placeholder: t("financial.payments_request.to_date"),
            name: "end_date",
            value: filterData.end_date,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            onReset: resetHandler,
          },
          {
            id: "status",
            label: t("general.status"),
            component: "select",
            placeholder: t("general.status"),
            initialValue: t("general.status"),
            name: "status",
            value: filterData.status,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            options: statusOptions || [],
          },
        ]
      : []),

    {
      id: "reset",
      component: "button",
      type: "button",
      name: "action",
      value: t("general.reset"),
      classes: "col-12 col-md-6 col-xl-2 border-button",
      action: () => {
        resetFilterHandler();
      },
      backgroundColor: "transparent",
      color: "#046c77",
      borderColor: "#046c77",
    },
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("general.search"),
      classes: "col-12 col-md-6  col-xl-2",
      action: () => {
        if (currentPage != 1) {
          setCurrentPage(1);
        } else {
          filterHandler();
        }
      },
    },
  ];

  return <FilterBox fields={filterFields} />;
};

export default PeymentsRequestFilter;
