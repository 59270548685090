import React, { useEffect, useState } from "react";
import classes from "./Statistics.module.css";
import SkeletonCardOverlay from "../../Shared/Components/Spinner/SkeletonCardOverlay";
import Breadcrumb from "../../Shared/Components/Breadcrumb/Breadcrumb";
import HomeIcon from "../../../assets/icons/home.svg";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import moment from "moment";
import MainBox from "../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../Shared/Components/MainBoxHead/MainBoxHead";
import { filterActions } from "./StatisticsActions";
import { toast } from "react-toastify";
import StatisticsFilter from "./StatisticsFilter";
import { getStatistics } from "../../../Services/api/Statistics/StatisticsProvider";
import StatisticsChart from "./StatisticsChart";
import clearEmptyProp from "../../../Helpers/ClearEmptyProp";
import { exportChartsData } from "../../../Services/api/coupon/couponProvider";

export default function Statistics() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [showFilter, setShowFilter] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [chartsData, setchartsData] = useState([]);
  const [currentFilter, setCurrentFilter] = useState({});
  const [ticketsData, setTicketsData] = useState([]);
  const [ticketTypes, setTicketTypes] = useState([]);
  const [ticketsDataSet, setTicketsDataSet] = useState([]);
  const [filterData, setFilterData] = useState({
    trainer: "",
    specialty: "",
    course: "",
    start_year: "",
    end_year: "",
  });
  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "events",
      page: t("sidebar.label.statistics"),
      active: true,
    },
  ];
  const showFilterHandler = () => {
    setShowFilter((prev) => !prev);
  };
  const resetFilterHandler = () => {
    setFilterData({
      trainer: "",
      specialty: "",
      course: "",
      start_year: "",
      end_year: "",
    });

    setIsResetApplied(true);
  };
  const getFilterData = () => {
    const filter = { type: "both" };
    filterData.trainer && (filter.trainer = filterData.trainer["value"]);
    filterData.specialty && (filter.specialty = filterData.specialty["value"]);
    filterData.course && (filter.course = filterData.course["value"]);
    filterData.start_year && (filter.start_time = filterData.start_year);
    filterData.end_year && (filter.end_time = filterData.end_year);

    return filter;
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
    });
  };

  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };

  const exportHandler = async (type) => {
    setIsExportLoading(true);
    try {
      await exportChartsData(type, clearEmptyProp(currentFilter));
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };
  function separateTicketsByType(tickets, ticketTypes) {
    // console.log(ticketTypes);
    const ticketMap = {};

    // Initialize ticketMap with empty arrays for each ticket type
    for (const ticketType of ticketTypes) {
      ticketMap[ticketType.name] = [];
    }

    // Group tickets by type name
    for (const ticket of tickets) {
      const typeName = ticketTypes.find(
        (type) => type.id.toString() === ticket.type_id.toString()
      ).name;
      ticketMap[typeName].push(ticket);
    }

    return ticketMap;
  }
  const colors = [
    "#2e3e5f",
    "#24b3b9",
    "#8599b6",
    "#845EC2",
    "#D65DB1",
    "#FF6F91",
    "#FF9671",
    "#FFC75F",
    "#F9F871",
    "#2C73D2",
    "#008E9B",
    "#008F7A",
    "#B39CD0",
    "#FBEAFF",
    "#C34A36",
  ];
  const getlabelsTickets = (labels, ticket_data) => {
    const years = [];
    labels.forEach((label) => {
      ticket_data[label]?.forEach((element) => {
        if (!years.includes(element.year)) {
          years.push(element.year);
        }
      });
    });

    return years.sort();
  };

  const getDataTickets = (label, labels, ticket_data) => {
    const years = getlabelsTickets(labels, ticket_data);
    // console.log(years);
    const data = [];

    if (years.length > ticket_data[label].length) {
      // vist all years year by year
      years.forEach((year) => {
        // check if data has count for the current year
        if (year !== null) {
          const item = ticket_data[label].find(
            (element) => element.year === year
          );
          if (item) {
            data.push(item.count);
          } else {
            data.push(0);
          }
        }
      });
    } else {
      ticket_data[label]?.forEach((element) => {
        if (element.year !== null) {
          data.push(element.count);
        }
      });
    }
    // console.log(data);
    return data;
  };

  function getSumCounts(data) {
    const sum = {};

    // Loop through the data
    data.forEach((entry) => {
      const year = entry.year;
      const count = parseInt(entry.count);

      // If the year already exists in the sum object, add the count to it
      if (sum[year]) {
        sum[year] += count;
      } else {
        // If the year doesn't exist, initialize it with the count
        sum[year] = count;
      }
    });

    // Convert the object to an array of objects
    const result = Object.entries(sum).map(([year, count]) => ({
      year,
      count,
    }));

    return result;
  }

  const fetchStatistics = () => {
    const filter = getFilterData();
    setisLoading(true);
    getStatistics(filter)
      .then((res) => {
        if (res.status === 200) {
          //   console.log(res);
          const allData = res.data.data;
          allData["all_tickets"] = getSumCounts(res.data.data.tickets);
          setchartsData(allData);
          const ticket_data = separateTicketsByType(
            res.data.data.tickets,
            res.data.data.ticket_types
          );
          setTicketsData(ticket_data);
          const ticket_types = res.data.data.ticket_types.map(
            (type) => type.name
          );

          setTicketTypes(ticket_types);
          const tickets_dataSet = ticket_types.map((ticket, index) => ({
            label: ticket,
            data: getDataTickets(ticket, ticket_types, ticket_data),
            backgroundColor: colors[index % colors.length],
            borderColor: "#fff",
            borderWidth: 1,
            // barThickness: 10,
          }));
          setTicketsDataSet(tickets_dataSet);
          setisLoading(false);
        }
      })

      .catch((err) => {
        setisLoading(false);
        console.log(err);
      });
    setCurrentFilter(filter);
  };

  useEffect(() => {
    fetchStatistics();
    if (isResetApplied) {
      setIsResetApplied(false);
    }
  }, [isResetApplied]);
  return (
    <div className={classes["events-list"]}>
      {isExportLoading && <SkeletonCardOverlay skeletonWidth="100" />}
      <div className="container-fluid">
        <div className={classes["events-list__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <MainBox>
                <MainBoxHead
                  title={t("sidebar.label.statistics")}
                  actions={filterActions({
                    showFilter: showFilterHandler,
                    // addAction: () => {
                    //   history.push("/admin/advertisements-list/add");
                    // },
                    exportPdf: () => {
                      exportHandler("pdf");
                    },
                    exportExcel: () => {
                      exportHandler("xlsx");
                    },
                  })}
                />
                {showFilter && (
                  <StatisticsFilter
                    filterData={filterData}
                    changeHandler={changeHandler}
                    resetHandler={resetHandler}
                    filterHandler={fetchStatistics}
                    resetFilterHandler={resetFilterHandler}
                  />
                )}
                <StatisticsChart
                  chartsData={chartsData}
                  ticketsData={ticketsData}
                  isLoading={isLoading}
                  ticketTypes={ticketTypes}
                  ticketsDataSet={ticketsDataSet}
                />
              </MainBox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
