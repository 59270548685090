import React from "react";
import { useTranslation } from "react-i18next";
import "./index.css";

import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepConnector from "@mui/material/StepConnector";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import useMediaQuery from "@mui/material/useMediaQuery";

const RowTabs = ({ tabs, typeMode, editBooth }) => {
  const matches = useMediaQuery("(max-width:768px)");
  const { t, i18n } = useTranslation();
  const steps = [
    t("booth.Basic_information"),
    t("booth.Booth_design"),
    t("booth.Add_products"),
    t("booth.Record_construction"),
  ];

  const [skipped, setSkipped] = React.useState(new Set());

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  return (
    <>
      <Box
        sx={{
          background: "#fff",
          width: "100%",
          borderRadius: "8px",
          padding: "50px 55px",
          boxShadow: "0px 0px 10px rgba(0,0,0, 0.1)",
          display: matches && "flex",
          justifyContent: matches && "center",
          alignItems: matches && "center",
        }}
      >
        <Stepper
          activeStep={tabs - 1}
          connector={<StepConnector style={{ height: "60px", display: matches && "none" }} className={`test`} />}
        >
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};

            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }

            return (
              <Step key={label} {...stepProps} style={{ display: matches && tabs - 1 !== index ? "none" : "flex" }}>
                <StepLabel
                  {...labelProps}
                  StepIconProps={{
                    style: {
                      color: tabs - 1 >= index ? "#126e77" : "",
                      marginBottom: "30px",
                      display: "flex",
                      flexDirection: "column",
                    },
                  }}
                  style={{ flexDirection: "column" }}
                >
                  <h5
                    style={{
                      color: tabs - 1 >= index && "#126e77",
                      position: tabs - 1 >= index ? "relative" : "",
                      fontFamily: "dinNextRegular",
                      fontSize: "16px",
                    }}
                  >
                    {label}

                    {tabs - 1 === index && (
                      <div
                        style={{
                          content: "''",
                          position: "absolute",
                          backgroundColor: "#126e77",
                          bottom: "0%",
                          left: "50%",
                          transform: "translate(-50%, -80%)",
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                        }}
                      />
                    )}
                  </h5>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
    </>
  );
};

export default RowTabs;
