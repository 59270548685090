import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { IconsTooltip } from "../../../../Components";
import { getExamGrades } from "../../../../Services/api/exams/ExamsProvider";

function ExamGrades(props) {
  require("./ExamGrades.css");

  const { t } = useTranslation();
  const { id, quizable_id, exam_id } = useParams();
  const history = useHistory();

  const [grades, setGrades] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getGrades = () => {
    getExamGrades(exam_id)
      .then((res) => {
        setGrades(res.data.response.answers);
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {t("failed_fetching")}
          </span>
        );
      });
  };

  useEffect(() => {
    getGrades();
  }, []);

  return (
    <>
      <div className={"exam-grades-canvas"}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <div className="admin_label" style={{ marginBottom: 30 }}>
            <NavLink
              to={`/trainer/course/${id}/${props.quizable}/${quizable_id}/assessments`}
            >
              {t("trainer.quiz.exams")}
            </NavLink>
            <p style={{ padding: "0 5px" }}>/</p>
            {t("trainer.quiz.trainee_exam_grades")}
          </div>
        </div>
        <Paper
          variant={"outlined"}
          sx={{
            width: "100%",
            boxShadow: "2px 2px 10px #ddd",
            height: isLoading ? 500 : "auto",
          }}
        >
          {isLoading ? (
            <div className={"tajah-question-progress"}>
              <CircularProgress />
            </div>
          ) : (
            <div className="table-responsive">
              <div
                className={"tajah-form-container"}
                style={{
                  boxShadow: "none",
                  borderRadius: 0,
                }}
              >
                <div className={"tajah-pre-table-header"}>
                  <span>{t("trainer.quiz.grades")}</span>
                </div>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">
                        {t("trainer.quiz.crud.exam_grade.student_name")}
                      </th>
                      <th scope="col">
                        {t("trainer.quiz.crud.exam_grade.grade")}
                      </th>
                      <th scope="col">
                        {t("trainer.quiz.crud.exam_grade.is_corrected")}
                      </th>
                      <th scope="col">{t("crud.list.actions")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {grades.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.name}</td>
                          <td>{item.score}</td>
                          <td
                            style={{
                              color: item.is_corrected ? "green" : "#d20101",
                            }}
                          >
                            {item.is_corrected
                              ? t(
                                  "trainer.quiz.crud.exam_grade.has_been_corrected"
                                )
                              : t(
                                  "trainer.quiz.crud.exam_grade.has_not_been_corrected"
                                )}
                          </td>
                          <td>
                            <IconsTooltip
                              title={t("edit")}
                              content={
                                <i
                                  className="fal fa-eye"
                                  onClick={() => {
                                    history.push(
                                      `/trainer/course/${id}/${props.quizable}/${quizable_id}/exam/${exam_id}/grades/${item.trainee_id}/response/${item.id}`
                                    );
                                  }}
                                  style={{ fontSize: 20, color: "black" }}
                                ></i>
                              }
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </Paper>
      </div>
    </>
  );
}

export default ExamGrades;
