import { couponsListConstants } from "../../Constants/coupons/couponsList.constants";

const initialState = {
	couponsList: [],
	couponsListMeta: {},
	couponsListLoading: false,
	couponsListError: null,
	couponsListSuccess: false,
	updateCouponStatusLoading: false,
	updateCouponStatusError: null,
	updateCouponStatusSuccess: false,
	deleteCouponLoading: false,
	deleteCouponError: null,
	deleteCouponSuccess: false,
};

const couponsListReducer = (state = initialState, action) => {
	switch (action.type) {
		case couponsListConstants.GET_COUPONS_LIST_REQUEST:
			return {
				...state,
				couponsListLoading: true,
			};
		case couponsListConstants.GET_COUPONS_LIST_SUCCESS:
			return {
				...state,
				couponsListLoading: false,
				couponsListSuccess: true,
				couponsList: action.payload.coupons,
				couponsListMeta: action.payload.meta,
			};
		case couponsListConstants.GET_COUPONS_LIST_REJECTED:
			return {
				...state,
				couponsListLoading: false,
				couponsListError: action.payload,
				couponsListSuccess: false,
			};

		// UPDATE COUPON STATUS
		case couponsListConstants.UPDATE_COUPON_STATUS_REQUEST:
			return {
				...state,
				updateCouponStatusLoading: true,
			};

		case couponsListConstants.UPDATE_COUPON_STATUS_SUCCESS:
			return {
				...state,
				updateCouponStatusLoading: false,
				updateCouponStatusSuccess: true,
				updateCouponStatusError: null,
				couponsList: state.couponsList.map((coupon) => {
					if (coupon.id === action.payload.id) {
						return {
							...coupon,
							status: coupon.status == 1 ? 0 : 1,
						};
					}
					return coupon;
				}),
			};

		// DELETE COUPON
		case couponsListConstants.DELETE_COUPON_REQUEST:
			return {
				...state,
				deleteCouponLoading: true,
			};
		case couponsListConstants.DELETE_COUPON_SUCCESS:
			return {
				...state,
				deleteCouponLoading: false,
				deleteCouponSuccess: true,
				deleteCouponError: null,
				couponsList: state.couponsList.filter((coupon) => coupon.id !== action.payload.id),
			};
		case couponsListConstants.DELETE_COUPON_REJECTED:
			return {
				...state,
				deleteCouponLoading: false,
				deleteCouponError: action.payload,
				deleteCouponSuccess: false,
			};

		default:
			return state;
	}
};

export default couponsListReducer;
