import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import FilterBox from "../../../../Shared/Components/FilterBox/FilterBox";

const WalletDetailsListFilter = ({
  filterData,
  changeHandler,
  resetHandler,
  currentPage,
  setCurrentPage,
  filterHandler,
  resetFilterHandler,
}) => {
  const { t, i18n } = useTranslation();
  const { userWalletFilter } = useSelector((state) => state.userWalletReducer);

  const filterFields = [
    {
      id: "wallet_transaction_type",
      label: t("wallet.label.WDtransactiontype"),
      component: "select",
      placeholder: t("wallet.label.WDtransactiontype"),
      initialValue: t("wallet.label.WDtransactiontype"),
      name: "wallet_transaction_type",
      value: filterData.wallet_transaction_type,
      classes: "col-12 col-md-6 col-xl-2",
      onchange: changeHandler,
      options: userWalletFilter?.wallet_transaction_type?.map((item) => ({
        label: item.text,
        value: item.value,
      })),
    },
    {
      id: "payable_type",
      label: t("payments.payable_type"),
      component: "select",
      placeholder: t("payments.payable_type"),
      initialValue: t("payments.payable_type"),
      name: "payable_type",
      value: filterData.payable_type,
      classes: "col-12 col-md-6 col-xl-2",
      onchange: changeHandler,
      options: [
        {
          id: "course",
          value: "course",
          label: t("trainer.course.label"),
        },
        {
          id: "program_payment",
          value: "program_payment",
          label: t("trainer.program.label"),
        },
        {
          id: "booth",
          value: "booth",
          label: t("general.exhibition_booth"),
        },
        {
          id: "visit",
          value: "exhibition_visit",
          label: t("general.exhibition_visitor"),
        },
        {
          id: "wallet",
          value: "wallet",
          label: t("general.wallet"),
        },
        {
          id: "wallet_transaction",
          value: "wallet_transaction",
          label: t("general.user"),
        },
      ],
    },
    ...(filterData.payable_type === "course"
      ? [
          {
            id: "course_id",
            label: t("coupon.label.select_course"),
            component: "select",
            placeholder: t("coupon.label.select_course"),
            initialValue: t("coupon.label.select_course"),
            name: "course_id",
            value: filterData.course_id,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            options: userWalletFilter?.courses?.map((item) => ({
              label: item.name,
              value: item.id,
            })),
          },
        ]
      : []),
    ...(filterData.payable_type === "program_payment"
      ? [
          {
            id: "program_id",
            label: t("coupon.label.select_program"),
            component: "select",
            placeholder: t("coupon.label.select_program"),
            initialValue: t("coupon.label.select_program"),
            name: "program_id",
            value: filterData.program_id,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            options: userWalletFilter?.programs?.map((item) => ({
              label: item.name,
              value: item.id,
            })),
          },
        ]
      : []),
    ...(filterData.payable_type === "exhibition_visit"
      ? [
          {
            id: "exhibition_id",
            label: t("coupon.label.select_exhibition"),
            component: "select",
            placeholder: t("coupon.label.select_exhibition"),
            initialValue: t("coupon.label.select_exhibition"),
            name: "exhibition_id",
            value: filterData.exhibition_id,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            options: userWalletFilter?.exhibitions?.map((item) => ({
              label: item.name,
              value: item.id,
            })),
          },
        ]
      : []),
    ...(filterData.payable_type !== "exhibition_visit" &&
    filterData.payable_type !== "program_payment" &&
    filterData.payable_type !== "course"
      ? [
          {
            id: "payable_name",
            component: "input",
            label: t("payments.payable_name"),
            type: "text",
            placeholder: t("payments.payable_name"),
            name: "payable_name",
            value: filterData.payable_name,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            onReset: resetHandler,
          },
        ]
      : []),
    {
      id: "status",
      label: t("general.status"),
      component: "select",
      placeholder: t("general.status"),
      initialValue: t("general.status"),
      name: "status",
      value: filterData.status,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: userWalletFilter?.status?.map((item) => ({
        label: item.text,
        value: item.value,
      })),
    },
    {
      id: "reset",
      component: "button",
      type: "button",
      name: "action",
      value: t("Exhibition.reset"),
      classes: "col-12 col-md-6 col-xl-3 border-button",
      action: () => {
        resetFilterHandler();
      },
      backgroundColor: "transparent",
      color: "#046c77",
      borderColor: "#046c77",
    },
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("Exhibition.search"),
      classes: "col-12 col-md-6  col-xl-3",
      action: () => {
        if (currentPage !== 1) {
          setCurrentPage(1);
        } else {
          filterHandler();
        }
      },
    },
  ];
  return <FilterBox fields={filterFields} />;
};

export default WalletDetailsListFilter;
