import { useTranslation } from "react-i18next";
import CustomButton from "../CustomButton/CustomButton";
import style from "./warningMessage.module.css";

export default function WarningMessage({
  img,
  label,
  description,
  btn,
  isStart,
}) {
  const { t } = useTranslation();

  return (
    <>
      <div className={`${style["warning-message"]}`}>
        <div className={style["warning-img"]}>
          <img src={img} alt="warning" />
        </div>
        <div>
          <h4 className={style.label}>{label}</h4>
          {description && <h2 className={style.description}>{description}</h2>}
          {btn && (
            <CustomButton
              value={t("chat.chat_register.start_chat")}
              colors={"#036c77"}
              type={"button"}
              action={isStart}
              classes={style["button-width"]}
            />
          )}
        </div>
      </div>
    </>
  );
}
