import { useTranslation } from "react-i18next";
import HomeIcon from "../../../../assets/icons/home.svg";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import DepartmentsListTableContainer from "./DepartmentsListTableContainer";
import classes from "./departmentsList.module.css";

const DepartmentsList = () => {
	const { t, i18n } = useTranslation();

	const breadcrumbList = [
		{
			id: "home",
			page: <img src={HomeIcon} alt="" />,
			pagePath: "/",
		},
		{
			id: "specialties",
			page: t("specialties"),
			pagePath: "/admin/category",
		},
		{
			id: "allDepartments",
			page: t("FromAllSections"),
			active: true,
		},
	];

	return (
		<div className={classes["departments-list"]}>
			<div className="container-fluid">
				<div className={classes["departments-list__container"]}>
					<div className="row">
						<div className="col-12">
							<Breadcrumb list={breadcrumbList} />
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<MainBox>
								<DepartmentsListTableContainer />
							</MainBox>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DepartmentsList;
