import React from "react";
import { IconsTooltip } from "../../../../Components";
import classes from "./mainBoxHead.module.css";
const MainBoxHead = ({ title, actions }) => {
  return (
    <div className={classes["main-box__head"]}>
      {title ? <h1 className={classes["main-box__head-title"]}>{title}</h1> : <div />}
      {actions && !!actions.length && (
        <div className={classes["main-box__head__actions"]}>
          {actions.map((action, index) =>
            action.type === "button" ? (
              <button
                className="tw-px-4 tw-text-white tw-rounded"
                style={{
                  backgroundColor: action.color,
                  color: action.textColor,
                  ...action.style,
                }}
                onClick={action.action}
                key={index}
              >
                {action.icon && <div className={classes["main-box__head__action__icon"]}>{action.icon}</div>}
                {action.btnTextColor ? (
                  <span style={{ color: action.btnTextColor }}>{action.label}</span>
                ) : (
                  action.label
                )}
              </button>
            ) : action.type === "switch" ? (
              <div className={classes["main-box__head__switch"]} key={index}>
                <input
                  type="checkbox"
                  id={action.id}
                  switch="bool"
                  checked={action.checked}
                  onChange={action.onChange}
                  disabled={action.disabled}
                />
                <label htmlFor={action.id} data-on-label={action.active} data-off-label={action.inactive}></label>
              </div>
            ) : (
              <>
                {action.title ? (
                  <IconsTooltip
                    title={action.title}
                    content={
                      <div
                        key={index}
                        className={`${classes["main-box__head__action"]} ${
                          classes[`main-box__head__action-${action.theme}`]
                        }`}
                        style={{ backgroundColor: action.color, ...action.style }}
                        onClick={action.action}
                      >
                        <div className={classes["main-box__head__action__icon"]}>{action.icon}</div>
                      </div>
                    }
                  />
                ) : (
                  <div
                    key={index}
                    className={`${classes["main-box__head__action"]} ${
                      classes[`main-box__head__action-${action.theme}`]
                    }`}
                    style={{ backgroundColor: action.color }}
                    onClick={action.action}
                  >
                    <div className={classes["main-box__head__action__icon"]}>{action.icon}</div>
                  </div>
                )}
              </>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default MainBoxHead;
