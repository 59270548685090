export const systemUsersListConstant = {
  // GET SYSTEM USERS LIST
  GET_SYSTEM_USERS_LIST_REQUEST: "GET_SYSTEM_USERS_LIST_REQUEST",
  GET_SYSTEM_USERS_LIST_SUCCESS: "GET_SYSTEM_USERS_LIST_SUCCESS",
  GET_SYSTEM_USERS_LIST_REJECTED: "GET_SYSTEM_USERS_LIST_REJECTED",

  // UPDATE SYSTEM USER STATUS
  UPDATE_SYSTEM_USER_STATUS_REQUEST: "UPDATE_SYSTEM_USER_STATUS_REQUEST",
  UPDATE_SYSTEM_USER_STATUS_SUCCESS: "UPDATE_SYSTEM_USER_STATUS_SUCCESS",
  UPDATE_SYSTEM_USER_STATUS_REJECTED: "UPDATE_SYSTEM_USER_STATUS_REJECTED",

  ADD_USERS_BY_EXCEL_REQUEST: "ADD_USERS_BY_EXCEL_REQUEST",
  ADD_USERS_BY_EXCEL_SUCCESS: "ADD_USERS_BY_EXCEL_SUCCESS",
  ADD_USERS_BY_EXCEL_REJECTED: "ADD_USERS_BY_EXCEL_REJECTED",
};
