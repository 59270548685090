import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { fetchDepartmentList } from "../../../../Redux/Actions/departments/departmentList.action";
import { fetchSpecialtiesList } from "../../../../Redux/Actions/specialties/specialtiesList.action";
import { exportDepartmentsList } from "../../../../Services/api/DepartmentsList";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import DepartmentsListFilter from "./DepartmentsListFilter";
import DepartmentsListTable from "./DepartmentsListTable";
import { filterActions } from "./DepartmentsListTableActions";


const DepartmentsListTableContainer = () => {
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();

	const { departmentListMeta } = useSelector((state) => state.departmentListReducer);
	const { specialtiesList } = useSelector((state) => state.specialtiesListReducer);

	const [showFilter, setShowFilter] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [isResetApplied, setIsResetApplied] = useState(false);
	const [isExportLoading, setIsExportLoading] = useState(false);

	const [filterData, setFilterData] = useState({
		name: "",
		description: "",
		specialty: "",
		created_at: "",
	});

	const showFilterHandler = () => {
		setShowFilter((prev) => !prev);
	};
	const changeHandler = (e) => {
		const { name, value } = e.target;
		setFilterData({ ...filterData, [name]: value });
	};

	const resetHandler = (name) => {
		setFilterData({ ...filterData, [name]: "" });
	};
	const resetFilterHandler = () => {
		setFilterData({
			name: "",
			description: "",
			specialty: "",
			created_at: "",
		});
		setIsResetApplied(true);
	};

	const getFilterData = () => {
		const filter = {};
		filterData.name && (filter.name = filterData.name);
		filterData.description && (filter.description = filterData.description);
		filterData.specialty && (filter.specialty_id = filterData.specialty);
		filterData.created_at && (filter.date = filterData.created_at);
		return filter;
	};

	const getDepartmentsListHandler = async () => {
		const filter = getFilterData();
		dispatch(
			fetchDepartmentList({
				page: currentPage,
				perPage: 10,
				filter,
			})
		);
	};

	const getSpecialtiesListHandler = async () => {
    dispatch(
      fetchSpecialtiesList({
        filter: {}
      })
    );
  }


	useEffect(() => {
		getDepartmentsListHandler();
		if (isResetApplied) {
			setIsResetApplied(false);
		}
		if(!specialtiesList?.length){
			getSpecialtiesListHandler();
		}
	}, [currentPage, isResetApplied]);

	const exportHandler = async (type) => {
		setIsExportLoading(true);
		try {
			const filter = getFilterData();
			await exportDepartmentsList(filter, type);
			setIsExportLoading(false);
		} catch (e) {
			setIsExportLoading(false);
			toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
		}
	};
	return (
		<>
			{isExportLoading && <SkeletonCardOverlay skeletonWidth="100" />}

			<MainBoxHead
				title={t("departments")}
				actions={filterActions({
					showFilter: showFilterHandler,
					exportPdf: () => {
						exportHandler("pdf");
					},
					exportExcel: () => {
						exportHandler("xlsx");
					},
				})}
			/>
			{showFilter && (
				<DepartmentsListFilter
					filterData={filterData}
					changeHandler={changeHandler}
					resetHandler={resetHandler}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					filterHandler={() => {
						getDepartmentsListHandler();
					}}
					resetFilterHandler={resetFilterHandler}
				/>
			)}

			<DepartmentsListTable />
			<Pagination
				count={departmentListMeta?.totalPages || 0}
				showFirstButton
				showLastButton
				variant="outlined"
				shape="rounded"
				className="main-pagination"
				page={currentPage}
				onChange={(e, page) => {
					setCurrentPage(page);
				}}
			/>
		</>
	);
};

export default DepartmentsListTableContainer;
