import i18n from "../../../i18n/i18n";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import logo from "../../../assets/image/logo.png";

function CurrentCouses() {
  document.body.dir = i18n.dir();
  const { t } = useTranslation();

  return (
    <>
      <div className="head_box">
        <div className="head_lable">{t("CurrentCourses")}</div>

        <div className="head_view select_width">
          <select className="select_control">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
          </select>
        </div>
      </div>

      <div className="courses_card_grid">
        <div className="courses_card_courses">
          <div className="courses_card_first_courses">
            <div className="courses_name_course">
              <div className="create_courses_name">
                <div className="create_couses_edit">
                  <img src={logo} alt="" className="logo_hover_width" />
                </div>
                <div className="">{t("basicCourse")}</div>
              </div>
              <div className="create_couses_edit">
                <div className="">{t("department")}</div>
                {/* <div className="">{t("modification")}</div> */}
                <NavLink to="modify-the-course" className="link-style">
                  {t("modification")}
                </NavLink>
              </div>
            </div>
          </div>
        </div>

        <div className="courses_card_courses">
          <div className="courses_card_first_courses">
            <div className="courses_name_course">
              <div className="create_courses_name">
                <div className="create_couses_edit">
                  <img src={logo} alt="" className="logo_hover_width" />
                </div>
                <div className="">{t("basicCourse")}</div>
              </div>
              <div className="create_couses_edit">
                <div className="">{t("department")}</div>
                <div className="">{t("modification")}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="courses_card_courses">
          <div className="courses_card_first_courses">
            <div className="courses_name_course">
              <div className="create_courses_name">
                <div className="create_couses_edit">
                  <img src={logo} alt="" className="logo_hover_width" />
                </div>
                <div className="">{t("basicCourse")}</div>
              </div>
              <div className="create_couses_edit">
                <div className="">{t("department")}</div>
                <div className="">{t("modification")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CurrentCouses;
