import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { deleteSavedSearch, getSavedSearches, updateSavedSearch } from "../../../../Redux/Actions/search.action";
import HomeIcon from "../../../../assets/icons/home.svg";
import DeleteIcon from "../../../../assets/icons/warning.svg";
import { validEmail } from "../../../../utils/validations";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import SharedModal from "../../../Shared/Components/SharedModal/SharedModal";
import SearchSaveModal from "../SearchSaveModal";
import classes from "../search.module.css";
import SearchHistoryTable from "./SearchHistoryTable";

const SearchHistory = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedSearchId, setSelectedSearchId] = useState(null);
  const { deleteSavedSearchLoading, savedSearches, updateSavedSearchLoading } = useSelector(
    (state) => state.searchReducer
  );
  const [showSaveSearchModal, setShowSaveSearchModal] = useState(false);
  const [saveSearchName, setSaveSearchName] = useState("");
  const [saveSearchError, setSaveSearchError] = useState("");
  const [searchReport, setSearchReport] = useState("0");
  const [emailValue, setEmailValue] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emails, setEmails] = useState([]);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "dashboard",
      page: t("admin.label.dashboard"),
      pagePath: "/admin",
    },
    {
      id: "search",
      page: t("search.search_reports"),
      pagePath: "/admin/search/history",
    },
    {
      id: "search_history",
      page: t("search.search_history"),
      active: true,
    },
  ];

  const getSavedSearchesHandler = (page = 1) => {
    dispatch(
      getSavedSearches({
        page,
        perPage: 10,
      })
    );
  };

  const deleteSearchHandler = () => {
    if (!selectedSearchId) return;
    dispatch(
      deleteSavedSearch({
        id: selectedSearchId,
        callback: () => {
          setShowDeleteModal(false);
        },
      })
    );
  };

  const saveNameChangeHandler = (value) => {
    setSaveSearchName(value);
    setSaveSearchError("");
  };

  const selectedSearchDetailsHandler = (id) => {
    const selectedSearch = savedSearches.find((search) => search.id === id);
    if (!selectedSearch) return;
    setSaveSearchName(selectedSearch?.name || "");
    setSearchReport(selectedSearch?.report || 0);
    setSelectedSearchId(id);
  };

  const updateSaveSearchHandler = () => {
    if (!saveSearchName) {
      setSaveSearchError(t("crud.errors.required"));
      return;
    }
    dispatch(
      updateSavedSearch({
        id: selectedSearchId,
        name: saveSearchName,
        report: searchReport,
        emails,
        callback: () => {
          setShowSaveSearchModal(false);
        },
      })
    );
  };

  const emailChangeHandler = (value) => {
    setEmailValue(value.trim());
    if (value.trim()) {
      setEmailError("");
    }
  };

  const addEmailHandler = () => {
    if (!emailValue.trim()) {
      setEmailError(t("crud.errors.required"));
      return;
    }
    if (emailValue.trim() && !validEmail(emailValue)) {
      setEmailError(t("crud.errors.errorEmail"));
      return;
    }
    if (emails.includes(emailValue.trim())) {
      setEmailError(t("crud.errors.email_already_exist"));
      return;
    }
    setEmails((prev) => [...prev, emailValue.trim()]);
    setEmailValue("");
    setEmailError("");
  };

  const deleteEmailHandler = (email) => {
    setEmails((prev) => prev.filter((item) => item !== email));
  };

  useEffect(() => {
    getSavedSearchesHandler();
  }, []);

  useEffect(() => {
    // add the emails to the emails array
    if (selectedSearchId) {
      const selectedSearch = savedSearches.find((search) => search.id === selectedSearchId);
      if (selectedSearch) {
        setEmails(selectedSearch?.emails || []);
      }
    }
  }, [selectedSearchId]);

  return (
    <div className={classes["search"]}>
      <div className="container-fluid">
        <div className={classes["search__wrapper"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <MainBox>
                <MainBoxHead title={t("search.search_history")} />
                <SearchHistoryTable
                  getSavedSearchesHandler={getSavedSearchesHandler}
                  setShowDeleteModal={setShowDeleteModal}
                  setSelectedSearchId={setSelectedSearchId}
                  setShowSaveSearchModal={setShowSaveSearchModal}
                  saveNameChangeHandler={saveNameChangeHandler}
                  selectedSearchDetailsHandler={selectedSearchDetailsHandler}
                />
              </MainBox>
            </div>
          </div>
          {showDeleteModal && (
            <SharedModal
              icon={DeleteIcon}
              title={t("search.delete_saved_search")}
              confirmMsg={t("general.delete_confirmMsg")}
              confirmText={t("general.confirm")}
              cancelText={t("general.cancel")}
              onCancel={() => {
                setShowDeleteModal(false);
              }}
              onConfirm={() => {
                deleteSearchHandler();
              }}
              isLoading={deleteSavedSearchLoading}
            />
          )}
          {showSaveSearchModal && (
            <SearchSaveModal
              title={t("search.save_search")}
              confirmText={t("general.save")}
              cancelText={t("general.cancellation")}
              label={t("search.save_search_name")}
              onConfirm={() => {
                updateSaveSearchHandler();
              }}
              onCancel={() => {
                setShowSaveSearchModal(false);
              }}
              isLoading={updateSavedSearchLoading}
              value={saveSearchName}
              onChange={saveNameChangeHandler}
              error={saveSearchError}
              searchReport={searchReport}
              setSearchReport={setSearchReport}
              onEmailChange={emailChangeHandler}
              emailValue={emailValue}
              emailError={emailError}
              emails={emails}
              addEmailHandler={addEmailHandler}
              deleteEmailHandler={deleteEmailHandler}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchHistory;
