import { Box, CircularProgress } from "@material-ui/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { refundOrder } from "../../../../Services/api/Financial/FinancialProvider";
import Avatar from "../../../Shared/Components/Avatar/Avatar";
import CustomButton from "../../../Shared/Components/CustomButton/CustomButton";
import ModelWithInput from "../../../Shared/Components/ModelWithInput/ModelWithInput";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import StarsRatingDynamic from "../../../Shared/Components/StarsRatingDynamic/StarsRatingDynamic";
import classes from "./bootDetails.module.css";

const ImageContainer = ({
  booth,
  exhibition,
  type,
  isRefundActive,
  openCancelModal,
  approveAction,
  approveActionLoading,
  rejectAction,
  rejectActionLoading,
}) => {
  require("./index.css");
  const { t, i18n } = useTranslation();
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const history = useHistory();

  const submitRefund = async (reason) => {
    try {
      setIsUpdating(true);
      const dataToBeUploaded = {
        reason,
        payment_method: "Wallet",
      };
      let response = await refundOrder(booth?.id, dataToBeUploaded, "exhibition-booth");
      if (response.status === 201 && response.data.status) {
        toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{response.data.msg}</span>);
        setIsUpdating(false);
      } else {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {response.data.msg ? response.data.msg : "Failure in service"}
          </span>
        );
        setIsUpdating(false);
      }
    } catch (err) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>{err.data.msg ? err.data.msg : "Failure in service"}</span>
      );
      setIsUpdating(false);
    }
    setShowRefundModal(false);
  };

  const handleOpenRefundModal = (id = 0) => {
    setShowRefundModal(true);
  };

  return (
    <div className={classes["image-container"]}>
      {isUpdating && <SkeletonCardOverlay skeletonWidth="100" />}
      <div className="embed-responsive embed-responsive-16by9 ">
        <img src={booth?.booth_main_img} alt="Your Image" className="embed-responsive-item" />
        <div className={classes["overlay"]}></div>
      </div>

      <div className={classes["content"]}>
        <div className={classes["info-content"]}>
          <Avatar image={booth?.logo} active={Number(booth?.status) === 1} />
          <div className={classes["d-flex-col"]}>
            <div className={classes["d-flex"]}>
              <h2 className={classes["image-title"]}>{booth?.name}</h2>
              {type !== "sponsor" && (
                <p className={classes["image-description"]}>{`${t("booth.size")} ${booth?.size}`}</p>
              )}
            </div>
            <div className={classes["rate"]}>
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23.999" viewBox="0 0 22 23.999">
                <path
                  id="police-box_1_"
                  data-name="police-box (1)"
                  d="M7,10V9A1,1,0,0,1,9,9v1a1,1,0,0,1-2,0Zm3,3H8a1,1,0,0,0-1,1v2a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1V14A1,1,0,0,0,10,13Zm2-5a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V9A1,1,0,0,0,12,8ZM23,23a1,1,0,0,1-1,1H2a1,1,0,0,1,0-2H3V7.09A4.507,4.507,0,0,1,6.941,2.625L11,2.118V1a1,1,0,1,1,2,0V2.118l4.059.507A4.507,4.507,0,0,1,21,7.09V22h1A1,1,0,0,1,23,23ZM19,7.09A2.486,2.486,0,0,0,18.749,6H5.251A2.486,2.486,0,0,0,5,7.09V22H19ZM16,8a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V9A1,1,0,0,0,16,8Z"
                  transform="translate(-1 -0.001)"
                  fill="#FFFFFF"
                />
              </svg>

              <p>{booth?.position}</p>
              <StarsRatingDynamic rating={booth?.rate_avg || 0} />
            </div>
          </div>
        </div>
        <div className={classes["list-buttons"]}>
          {(type !== "sponsor" && Number(booth?.sponsor_status) === 1) || type === "draftBooth" ? (
            <>
              <button className={classes["accepted"]} onClick={approveAction} disabled={approveActionLoading}>
                {approveActionLoading ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress color="white" size={25} />
                  </Box>
                ) : (
                  t("booth.approval")
                )}
              </button>
              <button className={classes["rejected"]} onClick={rejectAction} disabled={rejectActionLoading}>
                {rejectActionLoading ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress color="white" size={25} />
                  </Box>
                ) : (
                  t("booth.reject")
                )}
              </button>
            </>
          ) : null}
          {type === "sponsor" ? (
            <>
              {isRefundActive && (
                <button className={classes["accepted"]} onClick={() => handleOpenRefundModal()}>
                  {t("trainer.course.refund")}
                </button>
              )}
              {(exhibition?.is_active || exhibition?.is_coming) &&
              Number(booth?.sponsor_status) === 2 &&
              (Number(booth?.payment_status) === 0 || Number(booth?.payment_status) === 3) &&
              booth?.price !== ".00" ? (
                <CustomButton
                  value={t(`Exhibition.${Number(booth?.payment_status) === 0 ? "complete_the_payment" : "re_payment"}`)}
                  colors={"#26b3b9"}
                  type={"button"}
                  iconClass={"mt-2"}
                  classes={"button-font-size"}
                  action={() => {
                    history.push(`/exhibition-payment/booth/${exhibition?.id}`);
                  }}
                />
              ) : null}
              {(exhibition?.is_active || exhibition?.is_coming) &&
              Number(booth?.sponsor_status) === 2 &&
              Number(booth?.payment_status) === 2 ? (
                <CustomButton
                  value={t("Exhibition.edit_booth")}
                  type={"button"}
                  classes={"button-font-size"}
                  disable={Number(booth?.edit_status) === "5"}
                  colors={"#d1d626"}
                  action={() => {
                    history.push(`/exhibition-booth/${exhibition?.id}_${booth?.id}`);
                  }}
                />
              ) : null}
              {Number(booth?.sponsor_status) === 1 ||
              (Number(booth?.sponsor_status) === 2 && Number(booth?.payment_status) === 0) ||
              Number(booth?.sponsor_status) === 3 ? (
                <CustomButton
                  value={t("Exhibition.delete_booth")}
                  type={"button"}
                  classes={"button-font-size"}
                  colors={"#DF4B43"}
                  action={() => {
                    openCancelModal(booth?.id);
                  }}
                />
              ) : null}
            </>
          ) : null}
        </div>
      </div>
      {showRefundModal && (
        <ModelWithInput
          openModal={showRefundModal}
          setOpenModal={setShowRefundModal}
          modalTitle={t("general.refund_request")}
          buttonText={t("general.submit")}
          type={"textarea"}
          inputLabel={t("general.refund_reason")}
          handleSubmit={submitRefund}
          note={`${t("financial.refund.refund_note")} ${booth?.refund_amount} ${t("rs")}`}
        />
      )}
    </div>
  );
};

export default ImageContainer;
