import React from "react";
import { useDroppable } from "@dnd-kit/core";
import classNames from "classnames";

import styles from "./Droppable.module.css";

export function Droppable({ children, id, dragging }) {
  const { isOver, setNodeRef } = useDroppable({
    id,
  });

  return (
    <div
      ref={setNodeRef}
      className={`${classNames(
        isOver && styles.over,
        dragging && styles.dragging,
        children && styles.dropped
      )} tw-h-full`}
      aria-label="Droppable region"
    >
      {children}
    </div>
  );
}
