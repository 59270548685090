import React from "react";

const BoothsDetailsInput = ({
  label,
  type,
  name,
  defaultValue,
  onChange,
  error,
}) => {
  return (
    <>
      <label htmlFor={name} className="admin_add_label">
        {label}
      </label>
      <input
        id={name}
        type={type}
        name={name}
        className="admin_add_input"
        defaultValue={defaultValue}
        onChange={onChange}
      />
      <p className={"form-input-error-space"}>{error ? error : null}</p>
    </>
  );
};

export default BoothsDetailsInput;
