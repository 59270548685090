import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { NavLink, useLocation } from "react-router-dom";
import { getLessonActivities, getLessonContent } from "../../../Services/api/Trainee/TraineeProvider";
import SkeletonCard from "../../Shared/Components/Spinner/SkeletonCard";
import SkeletonSpinnerOverlay from "../../Shared/Components/Spinner/SkeletonSpinnerOverlay";
import { useParams } from "react-router";

import { toast } from "react-toastify";
import { sessionPreview } from "../../../Services/api/Zoom/ZoomProvider";
import { downloadFileFromLesson, getCourseDetails } from "../../../Services/api/courses/courseProvider";
import { updateProgress } from "../../../Services/api/toutorials/UpdateProgressProvider";
import FileBrowser from "../../Trainer/Course/NewLesson/Content/Browser/Browser";
import LessonActivity from "./Activity/LessonActivity";
import LessonPart from "./LessonPart";

function LessonView(props) {
  require("./lesson.css");
  const { t } = useTranslation();
  const {courseId, id: lessonId} = useParams();
  const [lessonContent, setLessonContent] = useState([]);
  const [lessonFiles, setLessonFiles] = useState([]);
  const [lessonActivities, setLessonActivities] = useState([]);
  const [lessonContentCount, setLessonContentCount] = useState(0);
  const history = useHistory();
  const [value, setValue] = useState(0);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [dataPreview, setDataPreview] = useState([]);
  const [course, setCourse] = useState(null);
  const [progress, setProgress] = useState(0);
  const [total, setTotal] = useState(0);
  const [progressQuiz, setProgressQuiz] = useState(0);
  const [totalQuiz, setTotalQuiz] = useState(0);
  const [currentShowingPart, setCurrentShowingPart] = useState(null);
  const [showActivity, setShowActivity] = useState(false);
  const [typesWidth, setTypesWidth] = useState("30%");
  const [partsDisplay, setPartsDisplay] = useState("block");
  // const zoomButton = document.getElementById('simple-tab-1');
  function showNewPart(e) {
    setPartsDisplay("block");
    setShowActivity(false);

    let index = lessonFiles.findIndex((item) => item.id === e);
    if (lessonFiles[index].type === "file") {
      downloadFileFromLesson(lessonFiles[index].title, lessonFiles[index].extension, lessonFiles[index].id);
    }
    setValue(index);
    if (lessonFiles[index].type === "folder") return;
    setCurrentShowingPart(lessonFiles[index]);
  }

  function onActivityClickCallBack(e) {
    setShowActivity(true);
    setCurrentShowingPart(e);
  }

  useEffect(async () => {
    if (lessonFiles[value] && lessonFiles[value].id !== undefined) {
      if (lessonFiles.length != 0 || value === lessonContentCount - 1) {
        if (lessonFiles[value].type == "folder") return;
        let response = await updateProgress(lessonFiles[value].id);
        if (response.status === 200 && response.data.status) {
          setProgress(response.data.contents.progress.progress);
          setTotal(response.data.contents.progress.total);
          setProgressQuiz(response.data.contents.quiz_progress.progress);
          setTotalQuiz(response.data.contents.quiz_progress.total);
        } else {
          alert("Failure");
        }
      }
    }
  }, [lessonContent, value]);

  useEffect(() => {
    getCourseDetails(courseId).then((res) => {
      if (res.status && res.status == 200 && res.data.status) {
        setCourse(res.data.course);
      }
    });
    getLessonContent(lessonId)
      .then(async (res) => {
        if (res.status && res.status == 200 && res.data.status) {
          let content = [];
          await res.data.contents.forEach((item) => {
            item.name = item.title;
            if (item.childs.length > 0) {
              item.childs.forEach((child) => {
                child.name = child.title;
                content.push(child);
              });
            }
            content.push(item);
          });
          setLessonFiles(content);
          prepareData(res.data.contents);
          setLessonContentCount(content.length);
          setCurrentShowingPart(content[0]);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err}</span>);
      });
    getLessonActivities(lessonId)
      .then(async (res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setLessonActivities(res.data.data.activities.data);
        }
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err}</span>);
      });

    async function executeOnLoad() {
      let response = await sessionPreview(lessonId);
      if (response.status && response.status == 200) {
        if (response.data.msg == "No Result") {
          setDataPreview([]);
        } else {
          setDataPreview(response.data.meetings);
        }
      }
    }

    executeOnLoad();
  }, []);

  const prepareData = (data) => {
    let manipulatedData = [];
    data.forEach((item) => {
      if (item.type !== "folder") {
        manipulatedData.push({
          id: item.id,
          name: item.title + (item.extension ? "." + item.extension : ""),
          type: item.type,
          extension: item.extension,
        });
      } else {
        if (item.childs.length > 0) {
          manipulatedData.push({
            id: item.id,
            name: item.title,
            type: "directory",
            files: item.childs.map((mapItem) => {
              return {
                id: mapItem.id,
                name: mapItem.title + (mapItem.extension ? "." + mapItem.extension : ""),
                type: mapItem.type,
                extension: mapItem.extension,
              };
            }),
          });
        }
      }
    });
    setLessonContent(manipulatedData);
  };

  const onPanelSelection = (index) => {
    setPartsDisplay("none");
    if (index == 1) {
      setTypesWidth("90%");
      setPartsDisplay("none");
    } else {
      setTypesWidth("30");
      setPartsDisplay("block");
      setShowActivity(false);
      setCurrentShowingPart(null);
    }
  };

  return (
    <>
      <div className="tajah-trainee-lesson-header_profile">
        <div className="tajah-trainee-lesson-profile_wrapper">
          <div className="tajah-trainee-lesson-profile_grid">
            <div className="tajah-trainee-lesson-profile_dname">
              {isLoading ? (
                <SkeletonSpinnerOverlay backdrop={true} skeletonWidth="70" />
              ) : (
                <div className="tajah-trainee-lesson-profile_dtop">
                  {location.state ? location.state.lessonTitle : "lesson"}
                </div>
              )}
              {isLoading ? (
                <SkeletonSpinnerOverlay backdrop={true} skeletonWidth="100" />
              ) : (
                <div
                  className="tajah-trainee-lesson-profile_dbottom mt-4 row"
                  style={{
                    display: "flex",
                    width: "100%",
                    padding: "30px 15px 0 15px",
                    justifyContent: "space-between",
                  }}
                >
                  <div>{t("yourCompleteLesson")}</div>
                  <div>
                    {!total && !totalQuiz
                      ? "100%"
                      : Math.round(((progress + progressQuiz) / (total + totalQuiz)) * 100) + "%"}
                  </div>
                  {/* <div>
                                        {t("points")}({Math.round((progress && total) && (progress / total) * 100)}
                                        ٪)
                                    </div> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="tajah-trainee-lesson-training_first_label">
        <NavLink to="/">{t("Home")}</NavLink>
        <p style={{ padding: "0 5px" }}>/</p>
        <NavLink to="/training-courses">{t("Hometraining")}</NavLink>
        <p style={{ padding: "0 5px" }}>/</p>
        <NavLink to={`/training-courses/${courseId}/profile`}>{course?.name}</NavLink>
        <p style={{ padding: "0 5px" }}>/</p>
        <div className="">{location.state ? location.state.lessonTitle : "lesson"}</div>
      </div>
      <div style={{ minHeight: "calc(100vh - 407px)" }}>
        <div className="row">
          <div className="col-12">
            {isLoading ? (
              <div
                style={{
                  height: "60vh",
                }}
              >
                <SkeletonCard />
              </div>
            ) : (
              <div style={{ display: "flex", marginBottom: "20px" }} className="lesson-wrapper">
                <div style={{ flex: "1", width: typesWidth }}>
                  <FileBrowser
                    content={lessonContent}
                    isTrainer={false}
                    onLessonFileClick={showNewPart}
                    activities={lessonActivities}
                    onActivityClickCallBack={onActivityClickCallBack}
                    role={props.role}
                  />
                </div>
                <div
                  style={{
                    flex: "2",
                    width: "70%",
                    display: partsDisplay,
                    maxWidth: "100%",
                    wordBreak: "break-word",
                    padding: "3%",
                  }}
                >
                  {showActivity ? (
                    <LessonActivity activity={currentShowingPart} showNewPart={showNewPart}></LessonActivity>
                  ) : (
                    <LessonPart item={currentShowingPart}></LessonPart>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default LessonView;
