import style from "./iconWithText.module.css";

export default function IconWithText({ icon, text, color }) {
  return (
    <div
      style={{
        color: color,
      }}
      className={style["icon-text"]}
    >
      {icon && <img src={icon} alt="icon" className={style["icon-w"]} />}
      <span className={style["text-size"]}>{text}</span>
    </div>
  );
}
