import { useTranslation } from "react-i18next";
import SunEditor from "suneditor-react";
const SunEditorText = ({ value, setValue, dirEditor, minHeight, disabled = false }) => {
  require("./Suneditor.min.css");
  const { i18n } = useTranslation();

  const onChange = (content) => {
    setValue(content);
  };

  return (
    <SunEditor
      defaultValue={value}
      value={value}
      disable={disabled}
      onChange={onChange}
      setDefaultStyle={`font-family: dinNextRegular, sans-serif; font-size: 16px;`}
      setOptions={{
        minHeight: "200px",
        rtl: i18n.language === "ar" ? true : false,
        buttonList: [
          ["undo", "redo"],
          ["fontSize", "formatBlock"],
          ["bold", "underline", "italic", "strike", "subscript", "superscript"],
          ["removeFormat"],
          ["outdent", "indent"],
          ["align", "horizontalRule", "list", "table"],
          ["link", "image", "video"],
          ["fullScreen", "showBlocks", "codeView"],
          ["preview", "print"],
          ["fontColor", "hiliteColor"],
        ],
      }}
      minHeight={minHeight}
    />
  );
};

export default SunEditorText;
