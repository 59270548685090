import moment from "moment";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { IconsTooltip } from "../../../../Components";
import { AuthContext } from "../../../../Services/api/auth/AuthContext";
import { updateTraineeStatus } from "../../../../Services/api/toutorials/assignToCourseProvider";
import { ReactComponent as EyeIcon } from "../../../../assets/icons/eye.svg";
import { utcToLocal } from "../../../../utils/utcToLocal";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import StarsRating from "../../../Shared/Components/StarsRating/StarsRating";
import SwitchToggle from "../../../Shared/Components/SwitchToggle/SwitchToggle";
import classes from "./departmentsTrainees.module.css";

const DepartmentsTraineesTable = ({
  trainees,
  role,
  getTraineesHandler,
  courseId,
  courseDetails,
  programCourses,
  isProgram,
  backPath,
}) => {
  const { t, i18n } = useTranslation();
  const authContext = useContext(AuthContext);

  const history = useHistory();
  const { id } = useParams();
  const handleStatusChange = (id, status) => {
    updateTraineeStatus(id, courseId, status)
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          getTraineesHandler();
        }
      })
      .catch((err) => {});
  };

  const subscriptionWayClass = (rowData) => {
    if (
      !rowData?.enrolments[0]?.payment_id &&
      !rowData?.enrolments[0]?.register_by &&
      !rowData?.enrolments[0]?.gift_id
    ) {
      return classes["departments-trainees__table-subscription-free"];
    } else if (
      rowData?.enrolments[0]?.payment_id &&
      !rowData?.enrolments[0]?.register_by &&
      !rowData?.enrolments[0]?.gift_id
    ) {
      if (!programCourses) {
        if (rowData?.payments[0]?.payment_method === "Bank") {
          return classes["departments-trainees__table-subscription-bank"];
        } else if (rowData?.payments[0]?.payment_method === "Wallet") {
          return classes["departments-trainees__table-subscription-wallet"];
        }
      } else {
        const payments = rowData?.payments?.filter((payment) => !payment?.is_gift);
        const courseIndex = payments?.findIndex((payment) =>
          payment?.covered_courses?.map((course) => Number(course?.id)).includes(Number(courseId))
        );

        if (courseIndex > -1) {
          if (payments[courseIndex]?.payment_method === "Bank") {
            return classes["departments-trainees__table-subscription-bank"];
          } else if (payments[courseIndex]?.payment_method === "Wallet") {
            return classes["departments-trainees__table-subscription-wallet"];
          }
        } else {
          return classes["departments-trainees__table-no-subscription"];
        }
      }
    } else if (
      rowData?.enrolments[0]?.register_by &&
      !rowData?.enrolments[0]?.payment_id &&
      !rowData?.enrolments[0]?.gift_id
    ) {
      return classes["departments-trainees__table-subscription-register"];
    } else if (rowData?.enrolments[0]?.gift_id) {
      return classes["departments-trainees__table-subscription-gift"];
    }
  };

  const displaySubscriptionWay = (rowData) => {
    if (
      !rowData?.enrolments[0]?.payment_id &&
      !rowData?.enrolments[0]?.register_by &&
      !rowData?.enrolments[0]?.gift_id
    ) {
      return t("general.free");
    } else if (
      rowData?.enrolments[0]?.payment_id &&
      !rowData?.enrolments[0]?.register_by &&
      !rowData?.enrolments[0]?.gift_id
    ) {
      if (!programCourses) {
        if (rowData?.payments[0]?.payment_method === "Bank") {
          return t("general.bank");
        } else if (rowData?.payments[0]?.payment_method === "Wallet") {
          return t("general.wallet");
        }
      } else {
        const payments = rowData?.payments?.filter((payment) => !payment?.is_gift);
        const courseIndex = payments?.findIndex((payment) =>
          payment?.covered_courses?.map((course) => Number(course?.id)).includes(Number(courseId))
        );
        if (courseIndex > -1) {
          if (payments[courseIndex]?.payment_method === "Bank") {
            return t("general.bank");
          } else if (payments[courseIndex]?.payment_method === "Wallet") {
            return t("general.wallet");
          }
        } else {
          return t("general.no_subscription");
        }
      }
    } else if (
      rowData?.enrolments[0]?.register_by &&
      !rowData?.enrolments[0]?.payment_id &&
      !rowData?.enrolments[0]?.gift_id
    ) {
      return t("general.register_by");
    } else if (rowData?.enrolments[0]?.gift_id) {
      return t("general.gift");
    }
  };

  const findLastCourse = (enrolments) => {
    return enrolments?.sort((a, b) => new Date(b.last_seen) - new Date(a.last_seen))[0];
  };

  const actionsList = [
    ...(!isProgram
      ? [
          {
            id: "view-details",
            icon: (row) => <EyeIcon className="tw-w-5 tw-h-5" />,
            title: (row) => t("general.view_details"),
            action: (row) => {
              history.push(
                `/${role}${
                  (role === "manager" || role === "trainer" || role === "partner") && programCourses
                    ? backPath === "programs-history"
                      ? `/programs-history/program/${id}`
                      : `/programs/program/${id}`
                    : ""
                }${programCourses && role === "admin" ? `/program/${id}` : ``}${
                  backPath === "courses-history" ? "/courses-history" : ""
                }/course/${courseId}/trainees/${row.id}`
              );
            },
          },
        ]
      : []),
  ];

  const columns = [
    {
      id: "order",
      name: "#",
      key: "order",
      cell: ({ rowData, rowIndex }) => <>{rowIndex + 1}</>,
      sortable: false,
    },
    {
      id: "id",
      name: t("general.id"),
      key: "id",
      cell: ({ rowData }) => <>{rowData?.id}</>,
      sortable: false,
    },
    {
      id: "photo",
      name: t("general.photo"),
      key: "photo",
      cell: ({ rowData }) => (
        <div className={classes["departments-trainees__table-photo"]}>
          {rowData.image ? <img src={rowData.image} alt="" /> : <i className="fa fa-user" aria-hidden="true"></i>}
        </div>
      ),
    },
    {
      id: "name",
      name: t("department_trainees.trainee_name"),
      key: "name",
      cell: ({ rowData }) => <>{rowData?.name || "-"}</>,
    },
    {
      id: "email",
      name: t("general.email"),
      key: "email",
      cell: ({ rowData }) => (
        <a className={classes["departments-trainees__table-email"]} href={`mailto: ${rowData?.email}`}>
          {rowData?.email}
        </a>
      ),
    },

    {
      id: "rating",
      name: t("general.rating"),
      key: "rating",
      cell: ({ rowData }) => <StarsRating rating={rowData.rating?.stars || 0} />,
    },
    {
      id: "rating_desc",
      name: t("note_rate"),
      key: "rating_desc",
      // cell: ({ rowData }) => <>{rowData.rating?.description || "-"}</>,
      cell: ({ rowData }) => (
        <div className={classes["departments-trainees__table-rating-desc"]}>{rowData.rating?.description || "-"}</div>
      ),
    },

    {
      id: "completion_rate",
      name: t("general.completion_rate"),
      key: "completion_rate",
      cell: ({ rowData }) => (
        <>
          {Number(rowData?.progress_evidences_count) === 0 ||
          Number(rowData?.progress_evidences_count) === null ||
          Number(rowData?.progress_trackers_count) === 0 ||
          Number(rowData?.progress_trackers_count) === null
            ? 0
            : Math.round((rowData?.progress_evidences_count / rowData?.progress_trackers_count) * 100)}
          %
        </>
      ),
    },
    {
      id: "watch_progress",
      name: t("general.watch_progress"),
      key: "watch_progress",
      cell: ({ rowData }) => (
        <>
          {Number(rowData?.watch_evidences_count) === 0 ||
          Number(rowData?.watch_evidences_count) === null ||
          Number(rowData?.watch_trackers_count) === 0 ||
          Number(rowData?.watch_trackers_count) === null
            ? 0
            : Math.round((rowData?.watch_evidences_count / rowData?.watch_trackers_count) * 100)}
          %
        </>
      ),
    },
    ...(!programCourses
      ? [
          {
            id: "issuing_certificate",
            name: t("general.issuing_certificate"),
            key: "issuing_certificate",
            cell: ({ rowData }) => (
              <div
                className={`${classes[`departments-trainees__table-certificate`]} ${
                  classes[`departments-trainees__table-certificate-${rowData?.certificates?.length > 0 ? "yes" : "no"}`]
                }`}
              >
                {rowData?.certificates?.length > 0 ? t("yes") : t("no")}
              </div>
            ),
          },
        ]
      : []),
    {
      id: "subscription_way",
      name: t("general.subscription_way"),
      key: "subscription_way",
      cell: ({ rowData }) => (
        <div className={`${classes["departments-trainees__table-subscription"]} ${subscriptionWayClass(rowData)}`}>
          {displaySubscriptionWay(rowData)}
        </div>
      ),
    },
    {
      id: "enrolments",
      name: t("general.subscription_date"),
      key: "enrolments",
      cell: ({ rowData }) => (
        <>
          {moment(rowData?.enrolments[0]?.created_at).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData?.enrolments[0]?.created_at)}
        </>
      ),
    },
    {
      id: "last_seen",
      name: t("general.last_seen"),
      key: "last_seen",
      cell: ({ rowData }) => (
        <>
          {rowData?.last_seen ? moment(rowData?.last_seen).format("DD/MM/YYYY") : "-"}
          <br />
          {rowData?.last_seen ? utcToLocal(rowData?.last_seen) : "-"}
        </>
      ),
    },
    // number of days
    {
      id: "days",
      name: t("general.days_number"),
      key: "days",
      cell: ({ rowData }) => <>{rowData?.last_seen ? `${moment().diff(rowData?.last_seen, "days")}` : "-"}</>,
    },
    ...(isProgram
      ? [
          {
            id: "last_course",
            name: t("general.last_course"),
            key: "last_course",
            cell: ({ rowData }) => (
              <>
                {findLastCourse(rowData?.enrolments_last_seen)?.last_seen
                  ? findLastCourse(rowData?.enrolments_last_seen)?.course_name
                  : "-"}
                <br />
                {findLastCourse(rowData?.enrolments_last_seen)?.last_seen
                  ? moment(findLastCourse(rowData?.enrolments_last_seen)?.last_seen)?.format("DD/MM/YYYY")
                  : "-"}
                <br />
                {findLastCourse(rowData?.enrolments_last_seen)?.last_seen
                  ? utcToLocal(findLastCourse(rowData?.enrolments_last_seen)?.last_seen)
                  : "-"}
              </>
            ),
          },
        ]
      : []),

    // actions
    ...(actionsList.length
      ? [
          {
            id: "actions",
            name: t("Exhibition.actions"),
            key: "actions",
            cell: ({ rowData, rowIndex }) => (
              <div className={`${classes["exhibition_list__actions"]} tw-flex tw-items-center tw-justify-center`}>
                {actionsList.map((action, index) => (
                  <div
                    className={
                      action.disabled && action.disabled(rowData, rowIndex)
                        ? "tw-pointer-events-none tw-opacity-50"
                        : ""
                    }
                  >
                    <IconsTooltip
                      title={action.title(rowData)}
                      content={
                        <button
                          key={action.id}
                          disabled={action.disabled && action.disabled(rowData, rowIndex)}
                          className={classes["exhibition_list__actions-btn"]}
                          onClick={() => {
                            action.action(rowData, rowIndex);
                          }}
                        >
                          {action.icon(rowData, rowIndex)}
                        </button>
                      }
                    />
                  </div>
                ))}
              </div>
            ),
          },
        ]
      : []),
    ...(role !== "partner" && !programCourses
      ? [
          {
            id: "status",
            name: t("general.status"),
            key: "status",
            cell: ({ rowData }) => (
              <SwitchToggle
                disabled={role === "trainer"}
                status={Number(rowData?.enrolments[0]?.active) === 1}
                id={rowData.id}
                onStatusChange={(status) => handleStatusChange(rowData.id, status)}
              />
            ),
          },
        ]
      : []),
  ];

  return (
    <div className={classes["departments-trainees__tables"]}>
      <MainTable columns={columns} data={trainees || []} isLoading={false} />
    </div>
  );
};

export default DepartmentsTraineesTable;
