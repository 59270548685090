import React, {createContext, useState} from "react";

const SurveyContext = createContext();

function SurveyProvider(props) {
  const [quations, setQuations] = useState([]);
  const [quationsSection, setQuationsSection] = useState([]);
  const [withSection, setWithSection] = useState([]);
  const [withoutSection, setWithoutSection] = useState([]);
  const [allRequest, setAllRequest] = useState([]);
  const [newAllRequest, setNewAllRequest] = useState([]);
  const [sectionQuestions, setSectionQuestions] = useState([]);
  const [allQuestions, setAllQuestions] = useState([]);
  const [emojis, setEmojis] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [options, setOptions] = useState([]);



  return (
    <SurveyContext.Provider
    value={{
      quations, setQuations,
      quationsSection,setQuationsSection,
      withSection,setWithSection,
      withoutSection,setWithoutSection,
      allRequest,setAllRequest,
      sectionQuestions,setSectionQuestions,
      newAllRequest,setNewAllRequest,
      allQuestions,setAllQuestions,
      emojis,setEmojis,
      questions,setQuestions,
      options,setOptions

    }}
    >
      {props.children}
    </SurveyContext.Provider>
  );
}

export { SurveyContext, SurveyProvider };
