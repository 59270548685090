import React, { useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { boothActions } from "../../../../Redux/Actions/Booth/booth.actions";
import HomeIcon from "../../../../assets/icons/home.svg";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import ConfirmationModal from "../../../Shared/Components/ConfirmationModal/ConfirmationModal";
import PaymentDetailsModal from "../../../Shared/Components/PaymentDetailsModal/PaymentDetailsModal";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import BootDetailsCarousel from "../BoothDetails/BootDetailsCarousel";
import BootDetailsCarouselVertical from "../BoothDetails/BootDetailsCarouselVertical";
import BootDetailsPref from "../BoothDetails/BootDetailsPref";
import BootDetailsTable from "../BoothDetails/BootDetailsTable";
import BootPayment_Response from "../BoothDetails/BootPayment_Response";
import ImageContainer from "../BoothDetails/ImageContainer";
import BootDetailsDocument from "./BootDetailsDocument.jsx";
import BootDetailsVisitsTable from "./BootDetailsVisitsTable";
import BoothStatus from "./BoothStatus";
import classes from "./bootDetails.module.css";

const BoothDetailsLayout = ({ type = "boothDetails" }) => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    booth,
    boothLoading,
    boothError,
    boothDraft,
    boothDraftLoading,
    boothDraftError,
    boothVisitors,
    boothVisitorsLoading,
    approveBoothLoading,
    rejectBoothLoading,
  } = useSelector((state) => state.boothReducer);

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showRejectConfirmationModal, setShowRejectConfirmationModal] =
    useState(null);
  const [cancelReason, setCancelReason] = useState("");
  const [cancelReasonError, setCancelReasonError] = useState("");
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [payment, setPayment] = useState(null);
  const [boothDetails, setBoothDetails] = useState({});

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "exhibition",
      page: t("Exhibition.Exhibition"),
      pagePath: "/admin/exhibitions-list",
    },
    {
      id: "exhibition-details",
      page: boothDetails?.exhibition?.name,
      pagePath: `/admin/exhibition/${boothDetails?.exhibition?.id}`,
    },
    {
      id: "booth-details",
      page: boothDetails?.name,
      active: true,
    },
  ];

  const getBoothsDetails = async () => {
    dispatch(boothActions.getBooth(id));
  };

  const getDraftBoothsDetails = async () => {
    dispatch(boothActions.getDraftBooth(id));
  };

  const getBoothVisitors = async () => {
    dispatch(boothActions.getBoothVisitors({ id: id, perPage: 10, page: 1 }));
  };

  useEffect(() => {
    if (type === "boothDetails") {
      getBoothsDetails();
      getBoothVisitors();
    } else {
      getDraftBoothsDetails();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(booth).length) {
      setBoothDetails(booth);
    } else if (Object.keys(boothDraft).length) {
      setBoothDetails(boothDraft);
    }
  }, [booth, boothDraft]);

  const paymentDetailsHandler = (payment) => {
    setShowPaymentModal(true);
    setPayment(payment);
  };

  const approveBoothActionHandler = () => {
    // if (boothDetails?.cartItem?.cart?.order?.status == 3) {
    //   toast.error(
    //     <span style={{ fontSize: 13, fontWeight: "bold" }}>
    //       {t("Exhibition.errors.approve_payment_before_approve")}
    //     </span>
    //   );
    //   return;
    // }
    // if (boothDetails?.cartItem?.cart?.order?.status == 2) {
    //   toast.error(
    //     <span style={{ fontSize: 13, fontWeight: "bold" }}>
    //       {t("Exhibition.errors.cant_approve_cuz_booth_rejected")}
    //     </span>
    //   );
    //   return;
    // }
    dispatch(
      boothActions.approveBooth({
        boothId: boothDetails?.id,
        orderId: 1,
        getBoothsDetails: getBoothsDetails,
      })
    );
  };

  const rejectBoothActionHandler = () => {
    // if (Number(boothDetails?.cartItem?.cart?.order?.status) === 3) {
    //   toast.error(
    //     <span style={{ fontSize: 13, fontWeight: "bold" }}>{t("Exhibition.errors.reject_payment_before_reject")}</span>
    //   );
    //   return;
    // }
    // if (
    //   Number(boothDetails?.cartItem?.cart?.order?.status) === 1 &&
    //   boothDetails?.cartItem?.cart?.order?.payment_method !== "Wallet"
    // ) {
    //   toast.error(
    //     <span style={{ fontSize: 13, fontWeight: "bold" }}>
    //       {t("Exhibition.errors.cant_reject_cuz_booth_approved")}
    //     </span>
    //   );
    //   return;
    // }

    setShowRejectConfirmationModal(true);
  };
  const rejectBoothConfirmationHandler = () => {
    if (!cancelReason || cancelReasonError)
      return setCancelReasonError(t("crud.errors.required"));
    dispatch(
      boothActions.rejectBooth({
        boothId: boothDetails?.id,
        orderId: boothDetails?.cartItem?.cart?.order?.id,
        cancelReason: cancelReason,
        getBoothsDetails: getBoothsDetails,
      })
    );
    setShowRejectConfirmationModal(null);
  };

  useEffect(() => {
    if (!boothError && !boothDraftError) return;
    if (
      boothError?.response?.status === 404 ||
      boothDraftError?.response?.status === 404
    ) {
      history.push("/admin/page-not-found");
    }
  }, [boothError, boothDraftError]);

  const approveDraftBoothHandler = () => {
    dispatch(
      boothActions.approveDraftBooth({
        boothId: id,
        exhibitionId: boothDetails?.exhibition?.id,
        history: history,
      })
    );
  };
  const rejectDraftBoothHandler = () => {
    dispatch(
      boothActions.rejectDraftBooth({
        boothId: id,
        exhibitionId: boothDetails?.exhibition?.id,
        history: history,
      })
    );
  };

  return (
    <div className="container-fluid">
      {isExportLoading && <SkeletonCardOverlay skeletonWidth="100" />}
      <div className="row">
        <div className="col-12">
          <div className={classes["breadcrumbs-my-30"]}>
            <Breadcrumb list={breadcrumbList} />
          </div>
          <div className="row">
            <div className="col-12">
              {boothLoading || boothDraftLoading ? (
                <div
                  style={{
                    height: "65vh",
                    width: "100%",
                  }}
                >
                  <SkeletonCard />
                </div>
              ) : (
                <div className="py-30">
                  <ImageContainer
                    booth={boothDetails}
                    exhibition={boothDetails?.exhibition}
                    type={type}
                    approveAction={
                      type === "draftBooth"
                        ? approveDraftBoothHandler
                        : approveBoothActionHandler
                    }
                    approveActionLoading={approveBoothLoading}
                    rejectAction={
                      type === "draftBooth"
                        ? rejectDraftBoothHandler
                        : rejectBoothActionHandler
                    }
                    rejectActionLoading={rejectBoothLoading}
                  />
                  <BoothStatus
                    sponserStatus={Number(boothDetails?.sponsor_status)}
                    payment_status={Number(boothDetails?.payment_status)}
                    rejectReason={boothDetails?.reject_reason || ""}
                    editStatus={Number(boothDetails?.edit_status)}
                  />
                  <BootDetailsPref
                    title={t("Exhibition.summary_Booth")}
                    description={boothDetails.description}
                  />
                  <BootPayment_Response
                    userName={boothDetails?.sponsor?.name || ""}
                    userImage={boothDetails?.sponsor?.image || ""}
                    paymentMethod={boothDetails?.payment?.payment_method || ""}
                    status={boothDetails?.payment?.status}
                    payment={boothDetails?.payment}
                    paymentDetailsHandler={paymentDetailsHandler}
                    type={type}
                    isFree={boothDetails?.payment?.amount === ".00"}
                  />
                  <BootDetailsDocument
                    document={boothDetails?.document || ""}
                    audio={boothDetails?.audio || ""}
                  />
                  {!!boothDetails?.banners?.length && (
                    <BootDetailsCarousel photos={boothDetails?.banners} />
                  )}
                  <div id="booths-list">
                    <BootDetailsTable
                      id={id}
                      tableData={boothDetails?.products || []}
                      type=""
                      setIsExportLoading={setIsExportLoading}
                    />
                  </div>

                  <div className={classes["visits-table"]}>
                    <BootDetailsVisitsTable
                      id={id}
                      tableData={boothVisitors || []}
                      loading={boothVisitorsLoading}
                      setIsExportLoading={setIsExportLoading}
                    />
                  </div>

                  <BootDetailsCarouselVertical
                    webinars={boothDetails?.webinars || []}
                  />
                </div>
              )}
            </div>
          </div>
          {showPaymentModal && (
            <PaymentDetailsModal
              closeModal={() => {
                setShowPaymentModal(false);
                setPayment(null);
              }}
              payment={payment}
            />
          )}
          {showRejectConfirmationModal && (
            <ConfirmationModal
              closeModal={() => {
                setShowRejectConfirmationModal(null);
              }}
              action={() => {
                rejectBoothConfirmationHandler();
              }}
              type={"reject"}
              onChange={(e) => {
                setCancelReason(e.target.value);

                setCancelReasonError((prev) => {
                  return !e.target.value.trim()
                    ? t("crud.errors.required")
                    : "";
                });
              }}
              isLoading={rejectBoothLoading}
              error={cancelReasonError}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default BoothDetailsLayout;
