import React from "react";
import { toast } from "react-toastify";
import env from "../../../Services/api/Environment";
import connector from "../../../Services/api/Handler";
import URLBuilder from "../../../Services/api/UrlBuilder";
import { educationalGoalsConstants } from "../../Constants/educationalGoals/educationalGoals.constants";

const getAllObjectives = (id, page = 1, pageSize = 10) => {
  return (dispatch) => {
    dispatch({ type: educationalGoalsConstants.GET_EDUCATIONAL_GOALS_REQUEST });

    connector
      .get(URLBuilder(env.get_all_objective + `?page=${page}`, { id: id }))
      .then((response) => {
        dispatch({
          type: educationalGoalsConstants.GET_EDUCATIONAL_GOALS_SUCCESS,
          payload: response.data.data.objectives,
        });
      })
      .catch((err) => {
        dispatch({
          type: educationalGoalsConstants.GET_EDUCATIONAL_GOALS_REJECTED,
          payload: err,
        });
      });
  };
};

const addEducationalObjective = (data) => {
  return (dispatch) => {
    dispatch({
      type: educationalGoalsConstants.ADD_EDUCATIONAL_OBJECTIVE_REQUEST,
    });

    connector
      .post(env.add_objective, data)
      .then((response) => {
        dispatch({
          type: educationalGoalsConstants.ADD_EDUCATIONAL_OBJECTIVE_SUCCESS,
          payload: response.data.courseObjective,
        });
        if (
          response.status &&
          response.status === 201 &&
          response.data.status
        ) {
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response.data.msg}
            </span>
          );
        }
      })
      .catch((err) => {
        
        toast.error(
          <>
            {err?.response?.data?.errors ? (
              Object.keys(err?.response?.data?.errors)?.map((key) => {
                return (
                  <span style={{ fontSize: 12 }}>
                    {err?.response?.data?.errors[key]}{" "}
                  </span>
                );
              })
            ) : (
              <span style={{ fontSize: 13, fontWeight: "bold" }}>
                {err?.response?.data?.msg}
              </span>
            )}
          </>
        );

        dispatch({
          type: educationalGoalsConstants.ADD_EDUCATIONAL_OBJECTIVE_REJECTED,
          payload: err.response.data.msg,
        });
      });
  };
};

const editEducationalObjective = (id, data) => {
  return (dispatch) => {
    dispatch({
      type: educationalGoalsConstants.EDIT_EDUCATIONAL_OBJECTIVE_REQUEST,
    });

    connector
      .post(URLBuilder(env.edit_objective, { id: id }), data)
      .then((response) => {
        dispatch({
          type: educationalGoalsConstants.EDIT_EDUCATIONAL_OBJECTIVE_SUCCESS,
          payload: response.data.courseObjective,
        });
        if (
          response.status &&
          response.status === 200 &&
          response.data.status
        ) {
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response.data.msg}
            </span>
          );
        }
      })
      .catch((err) => {
        dispatch({
          type: educationalGoalsConstants.EDIT_EDUCATIONAL_OBJECTIVE_REJECTED,
          payload: err.response.data.msg,
        });
      });
  };
};

const deleteEducationalObjective = (id) => {
  return (dispatch) => {
    dispatch({
      type: educationalGoalsConstants.DELETE_EDUCATIONAL_OBJECTIVE_REQUEST,
    });

    connector
      .delete(URLBuilder(env.delete_objective, { id: id }))
      .then((response) => {
        dispatch({
          type: educationalGoalsConstants.DELETE_EDUCATIONAL_OBJECTIVE_SUCCESS,
          payload: id,
        });
        if (
          response.status &&
          response.status === 200 &&
          response.data.status
        ) {
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response.data.msg}
            </span>
          );
        }
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.msg}
          </span>
        );
        dispatch({
          type: educationalGoalsConstants.DELETE_EDUCATIONAL_OBJECTIVE_REJECTED,
          payload: err.response.data.msg,
        });
      });
  };
};

const updateEducationObjectStatus = (id) => {
  return (dispatch) => {
    dispatch({
      type: educationalGoalsConstants.UPDATE_EDUCATIONAL_OBJECTIVE_STATUS_REQUEST,
    });

    connector
      .post(URLBuilder(env.object_status, { id: id }))
      .then((response) => {
        dispatch({
          type: educationalGoalsConstants.UPDATE_EDUCATIONAL_OBJECTIVE_STATUS_SUCCESS,
          payload: response.data.objective,
        });
        if (
          response.status &&
          response.status === 200 &&
          response.data.status
        ) {
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response.data.msg}
            </span>
          );
        }
      })
      .catch((err) => {
        dispatch({
          type: educationalGoalsConstants.UPDATE_EDUCATIONAL_OBJECTIVE_STATUS_REJECTED,
          payload: err.response.data.msg,
        });
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.msg}
          </span>
        );
      });
  };
};

export const educationalGoalsActions = {
  getAllObjectives,
  addEducationalObjective,
  editEducationalObjective,
  deleteEducationalObjective,
  updateEducationObjectStatus,
};
