import React, {createContext, useEffect, useState} from 'react';
import SkeletonCardOverlay from '../../Modules/Shared/Components/Spinner/SkeletonCardOverlay';
import connector from '../../Services/api/Handler';
import matchUrls from './SpinnerMatch';

const SpinnerContext = createContext();

function clearRequest(url, method, old) {
    old.splice(old.findIndex(el => el[0] == url && el[1] == method), 1)
    return old;
}
function SpinnerProvider(props) {
    const [showSpinner, setShowSpinner] = useState(false);
    const [requestsOnLoading, setRequestsOnLoading] = useState([])

    useEffect(() => {
        connector.interceptors.request.use(
            (value) => {
                if(matchUrls(value.url, value.method))
                    setRequestsOnLoading((old) => [...old, [value.url, value.method]])
                return value;
            },
            (err, e) => {
                return Promise.reject(err);
            }
        );


        connector.interceptors.response.use(
            (response) => {
                if(matchUrls(response.config.url, response.config.method))
                    setRequestsOnLoading(clearRequest(response.config.url, response.config.method, requestsOnLoading))
                return response;
            },
            (err, e) => {
                if(matchUrls(err.config.url, err.config.method))
                    setRequestsOnLoading(clearRequest(err.config.url, err.config.method, requestsOnLoading))
                return Promise.reject(err);
            }
        );
    }, [])

    return (
        <SpinnerContext.Provider value={{showSpinner, setShowSpinner}}>
            {
                showSpinner || requestsOnLoading.length > 0 ?
                    <SkeletonCardOverlay skeletonWidth="100"/>
                    // <div className="spinner_container">
                    //     <img src={loadingSpinner} />
                    // </div>
                    : null
            }
            { props.children }
        </SpinnerContext.Provider>
    );
}

export {SpinnerContext, SpinnerProvider};
