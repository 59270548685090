import { saveAs } from "file-saver";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import IconTooltips from "../../../../Components/tooltips/IconsTooltip";
import {
  addSponsor,
  getSponsorsData,
  getTraineeList,
  searchTrainee,
} from "../../../../Services/api/Financial/FinancialProvider";
import { Lang } from "../../../../utils";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";


function AddSponsor() {
  require("./Sponsor.css");
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [trainees, setTrainees] = useState([]);
  const [partners, setPartners] = useState([]);
  const [program, setProgram] = useState([]);
  const [course, setCourse] = useState([]);
  const [search, setSearch] = useState("");
  const [errorSearch, setErrorSearch] = useState("");
  const [traineeArray, setTraineeArray] = useState([]);
  const [traineeTable, setTraineeTable] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [QuestionCircle, setQuestionCircle] = useState(false);

  useEffect(() => {
    getSponsorsData()
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setPartners(res.data.response.partners);
          setProgram(res.data.response.programs);
          setCourse(res.data.response.courses);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        
      });
    getTraineeList()
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setTrainees(res.data.response);
        }
      })
      .catch((err) => {
        
      });
  }, []);

  const formatDate = (d) => {
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const type = [
    { key: "course", value: t("sponsor.typeCourse") },
    { key: "program", value: t("sponsor.typeProgram") },
  ];

  const searchTypeSelect = [
    { key: "name", value: t("sponsor.name") },
    { key: "email", value: t("sponsor.email") },
  ];

  const chooseTypeTrainee = [
    { key: 2, value: "بحث عن متدربين" },
    { key: 1, value: "رفع فايل اكسل بالمتدربين" },
  ];

  const searchHandle = async (searchType, type, course_id, program_id) => {
    if (search == "" || !type || !searchType) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("sponsor.searchError")} [{t("sponsor.type")},{" "}
          {t("sponsor.searchType")},{t("sponsor.course_name")} ]
        </span>
      );
      return;
    }

    const payload = {
      type,
      id: type == "course" ? course_id : program_id,
    };
    payload[`${searchType}`] = search;

    try {
      setLoadingTable(true);
      let response = await searchTrainee(payload);
      if (response.status === 200 && response.data.status) {
        if (response.data.response.trainee !== null) {
            let checkTrainee = false;
            if(traineeTable.length > 0){
                for( let i=0; i < traineeTable.length; i++ ){
                    if (traineeTable[i].id == response.data.response.trainee.id){
                        checkTrainee = true
                    }
                    if(traineeTable.length - 1 == i && !checkTrainee ){
                        setTraineeTable((prev) => [
                            ...prev,
                            response.data.response.trainee.id
                              ? response.data.response.trainee
                              : {
                                  name_ar: response.data.response.trainee,
                                  name_ar: response.data.response.trainee,
                                },
                          ]);
                    }
                }
            }else{
                setTraineeTable((prev) => [
                    ...prev,
                    response.data.response.trainee.id
                      ? response.data.response.trainee
                      : {
                          name_ar: response.data.response.trainee,
                          name_ar: response.data.response.trainee,
                        },
                  ]);
            }

        //   setTraineeTable((prev) => [
        //     ...prev,
        //     response.data.response.trainee.id
        //       ? response.data.response.trainee
        //       : {
        //           name_ar: response.data.response.trainee,
        //           name_ar: response.data.response.trainee,
        //         },
        //   ]);

          setTraineeArray((prev) => [
            ...prev,
            response.data.response.trainee.id
              ? response.data.response.trainee.id
              : response.data.response.trainee,
          ]);
          setSearch("");
          setLoadingTable(false);
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {t("sponsor.userNotfound")}
            </span>
          );
          setLoadingTable(false);
        }
      }
    } catch (error) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {error.response && error.response.data.msg
            ? error.response.data.msg
            : "Failure in service"}
        </span>
      );
      setLoadingTable(false);
    }
  };

  const deleteHandler = (name) => {
    const arr = traineeTable.filter((t) =>
      i18n.language === Lang.AR ? t.name_ar !== name : t.name_en !== name
    );
    setTraineeTable((prev) => [...arr]);
    const newArrayTrainee = arr.map((a) =>
      a.id ? a.id : Lang.AR ? a.name_ar : a.name_en
    );

    setTraineeArray(newArrayTrainee);
  };

  return (
    <>
      <div
        className="container-fluid"
        style={{
          marginBottom: "100px",
        }}
      >
        <div className="admin_flex">
          <div className="admin_label">
            <NavLink
              to={`${
                localStorage.getItem("type") == "accountant"
                  ? "/accountant"
                  : "/admin"
              }`}
            >
              {t("admin.label.admin_label")}
            </NavLink>
            <p style={{ padding: "0 5px" }}>/</p>
            <NavLink
              to={`${
                localStorage.getItem("type") == "accountant"
                  ? "/accountant/sponsor"
                  : "/admin/sponsor"
              }`}
            >
              {t("sponsor.sponsor")}
            </NavLink>
            <p style={{ padding: "0 5px" }}>/</p>
            {t("sponsor.addSponsor")}
          </div>
        </div>
        {isLoading == false ? (
          <>
            <div className={`table_bank_tran`}>
              <h3
                className="table_header"
                style={{
                  paddingBottom: "20px",
                }}
              >
                <div>{t("sponsor.sponsor")}</div>
              </h3>
              <Formik
                initialValues={{
                  user_id: "",
                  accreditation_at: "",
                  type: "",
                  course_id: "",
                  program_id: "",
                  trainee_count: "",
                  trainees: [],
                  offer: "",
                  searchType: "",
                  excel_file:"",
                  trainee_type: 2
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  try {
                    const dataPayload = {
                      ...values,
                    };
                    delete dataPayload.searchType;
                    dataPayload.accreditation_at = formatDate(
                      values.accreditation_at
                    );

                    if (!values.offer) {
                      dataPayload.offer = 0;
                    } else {
                      dataPayload.offer = +values.offer;
                    }
                    dataPayload.trainee_count = +values.trainee_count;
                    if (dataPayload.type === "course") {
                      delete dataPayload.program_id;
                    } else {
                      delete dataPayload.course_id;
                    }

                    dataPayload.trainees = [...traineeArray];

                    if (dataPayload.trainees.length == 0) {
                      toast.error(
                        <span style={{ fontSize: 13, fontWeight: "bold" }}>
                          {t("sponsor.traineeMust")}
                        </span>
                      );
                      return;
                    }
                    if (dataPayload.trainees.length != dataPayload.trainee_count) {
                      toast.error(
                        <span style={{ fontSize: 13, fontWeight: "bold" }}>
                          {t("sponsor.traineeCountError")}
                        </span>
                      );
                      return;
                    }
                    let response;
                    if(dataPayload.trainee_type == 2){
                        delete dataPayload.excel_file;
                        response = await addSponsor(dataPayload);
                    }else{
                        delete dataPayload.trainees;
                        let formData = new FormData();
                        Object.keys(dataPayload).forEach((field) => {
                            formData.append(field, dataPayload[field])
                        });
                        response = await addSponsor(formData);
                    }

                    // return false
                    // let response = await addSponsor(formData);
                    // let response = await addSponsor(dataPayload);
                    if (response.status === 201 && response.data.status) {
                      setIsLoading(true);
                      history.push(
                        `${
                          localStorage.getItem("type") == "accountant"
                            ? "/accountant"
                            : "/admin"
                        }/sponsor`
                      );
                      setIsLoading(false);
                    } else {
                      alert("Failure");
                    }
                    setSubmitting(false);
                  } catch (err) {
                    toast.error(
                      <span style={{ fontSize: 13, fontWeight: "bold" }}>
                        {t("sponsor.failedSend")}
                      </span>
                    );
                    setIsLoading(false);
                  }
                }}
                validateOnChange={false}
                validate={(values) => {
                  const errors = {};
                  if (!values.user_id) {
                    errors.user_id = t("crud.errors.required");
                  }
                  if (!values.accreditation_at) {
                    errors.accreditation_at = t("crud.errors.required");
                  }
                  if(values.trainee_type == 2){
                    if (!values.searchType) {
                        errors.searchType = t("crud.errors.required");
                      }
                  }
                  if(values.trainee_type == 1){

                    if (!values.excel_file) {
                        errors.excel_file = t("crud.errors.required");
                      }
                  }

                  if (!values.type) {
                    errors.type = t("crud.errors.required");
                  }
                  if (values.type === "course" && !values.course_id) {
                    errors.course_id = t("crud.errors.required");
                  }
                  if (values.type === "program" && !values.program_id) {
                    errors.program_id = t("crud.errors.required");
                  }
                  if (!values.trainee_count) {
                    errors.trainee_count = t("crud.errors.required");
                  }
                  return errors;
                }}
              >
                {({ values, handleSubmit, setFieldValue, errors }) => (
                  <form
                    onSubmit={handleSubmit}
                    style={{ width: "100%", padding: "0 50px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        width: "100%",
                        marginTop: 30,
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          marginRight: document.body.dir == "rtl" ? 10 : 0,
                          marginLeft: document.body.dir == "ltr" ? 10 : 0,
                        }}
                      >
                        <label>{t("sponsor.sponsorPartner")}</label>
                        <Select
                          name="user_id"
                          id="user_id"
                          options={partners}
                          getOptionLabel={(option) =>
                            i18n.language === Lang.AR
                              ? option.name_ar
                              : option.name_en
                          }
                          getOptionValue={(option) => option.id}
                          isClearable={true}
                          onChange={(status) => {
                            if (status) {
                              setFieldValue("user_id", status.id);
                            } else {
                              setFieldValue("user_id", null);
                            }
                          }}
                          placeholder={t("sponsor.sponsorPartner")}
                        />
                        <p className={"form-input-error-space"}>
                          {errors.user_id ? errors.user_id : null}
                        </p>
                      </div>
                      <div
                        style={{
                          flex: 1,
                          marginRight: document.body.dir == "rtl" ? 10 : 0,
                          marginLeft: document.body.dir == "ltr" ? 10 : 0,
                        }}
                      >
                        <label>{t("sponsor.dateApprovalCare")}</label>
                        <DatePicker
                          selected={values.accreditation_at}
                          onChange={(date) =>
                            setFieldValue("accreditation_at", date)
                          }
                          placeholderText={t("sponsor.dateApprovalCare")}
                          className="admin_filter_input"
                          dateFormat={"yyyy-MM-dd"}
                          minDate={new Date()}
                        />
                        <p className={"form-input-error-space"}>
                          {errors.accreditation_at
                            ? errors.accreditation_at
                            : null}
                        </p>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        width: "100%",
                        marginTop: 30,
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          marginRight: document.body.dir == "rtl" ? 10 : 0,
                          marginLeft: document.body.dir == "ltr" ? 10 : 0,
                        }}
                      >
                        <label>{t("sponsor.typeCare")}</label>
                        <Select
                          name="type"
                          id="type"
                          options={type}
                          defaultValue={values.type}
                          getOptionLabel={(option) => option.value}
                          getOptionValue={(option) => option.key}
                          isClearable={true}
                          onChange={(status) => {
                            if (status) {
                              setFieldValue("type", status.key);
                            } else {
                              setFieldValue("type", null);
                            }
                          }}
                          placeholder={t("sponsor.typeCare")}
                        />
                        <p className={"form-input-error-space"}>
                          {errors.type ? errors.type : null}
                        </p>
                      </div>
                      {values.type ? (
                        values.type === "course" ? (
                          <div
                            style={{
                              flex: 1,
                              marginRight: document.body.dir == "rtl" ? 10 : 0,
                              marginLeft: document.body.dir == "ltr" ? 10 : 0,
                            }}
                          >
                            <label>{t("sponsor.course_name")}</label>
                            <Select
                              name="course_id"
                              id="course_id"
                              options={course}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              isClearable={true}
                              onChange={(status) => {
                                if (status) {
                                  setFieldValue("course_id", status.id);
                                } else {
                                  setFieldValue("course_id", null);
                                }
                              }}
                              placeholder={t("sponsor.course_name")}
                            />
                            <p className={"form-input-error-space"}>
                              {errors.course_id ? errors.course_id : null}
                            </p>
                          </div>
                        ) : (
                          <div
                            style={{
                              flex: 1,
                              marginRight: document.body.dir == "rtl" ? 10 : 0,
                              marginLeft: document.body.dir == "ltr" ? 10 : 0,
                            }}
                          >
                            <label>{t("sponsor.course_name")}</label>
                            <Select
                              name="program_id"
                              id="program_id"
                              options={program}
                              getOptionLabel={(option) => option.title}
                              getOptionValue={(option) => option.id}
                              isClearable={true}
                              onChange={(status) => {
                                if (status) {
                                  setFieldValue("program_id", status.id);
                                } else {
                                  setFieldValue("program_id", null);
                                }
                              }}
                              placeholder={t("sponsor.course_name")}
                            />
                            <p className={"form-input-error-space"}>
                              {errors.program_id ? errors.program_id : null}
                            </p>
                          </div>
                        )
                      ) : (
                        <div
                          style={{
                            flex: 1,
                            marginRight: document.body.dir == "rtl" ? 10 : 0,
                            marginLeft: document.body.dir == "ltr" ? 10 : 0,
                          }}
                        ></div>
                      )}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        width: "100%",
                        marginTop: 30,
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          marginRight: document.body.dir == "rtl" ? 10 : 0,
                          marginLeft: document.body.dir == "ltr" ? 10 : 0,
                        }}
                      >
                        <label>{t("sponsor.numberTrainees")}</label>
                        <input
                          id="trainee_count"
                          type="number"
                          min="0"
                          max="100"
                          name="trainee_count"
                          className="admin_filter_input"
                          onChange={(e) => {
                            setFieldValue("trainee_count", e.target.value);
                          }}
                          placeholder={t("sponsor.numberTrainees")}
                          value={values.value}
                        />
                        <p className={"form-input-error-space"}>
                          {errors.trainee_count ? errors.trainee_count : null}
                        </p>
                      </div>
                      <div
                        style={{
                          flex: 1,
                          marginRight: document.body.dir == "rtl" ? 10 : 0,
                          marginLeft: document.body.dir == "ltr" ? 10 : 0,
                        }}
                      >
                        <label>{t("sponsor.offer")}</label>
                        <input
                          id="offer"
                          type="number"
                          min="0"
                          max="100"
                          name="offer"
                          className="admin_filter_input"
                          onChange={(e) => {
                            setFieldValue("offer", e.target.value);
                          }}
                          placeholder={t("sponsor.offer")}
                          value={values.value}
                        />
                      </div>
                    </div>
                    {/* choose */}
                    <div
                    style={{
                        flex: 1,
                        marginRight: document.body.dir == "rtl" ? 10 : 0,
                        marginLeft: document.body.dir == "ltr" ? 10 : 0,
                      }}>
                         <label>بحث / رفع ملف للمتدربين</label>
                         <Select
                            name="chooseTypeTrainee"
                            id="chooseTypeTrainee"
                            defaultValue={chooseTypeTrainee[0]}
                            options={chooseTypeTrainee}
                            getOptionLabel={(option) => option.value}
                            getOptionValue={(option) => option.key}
                            isClearable={true}
                            onChange={(status) => {
                              if (status) {
                               setFieldValue("trainee_type", status.key);
                              }
                            }}
                          />
                    </div>
                    {/* end */}
                    {
                        values.trainee_type == 2?
                            <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-around",
                                width: "100%",
                                marginTop: 30,
                            }}
                            >
                            <div
                                style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-around",
                                width: "100%",
                                marginTop: 30,
                                }}
                            >
                                <div
                                style={{
                                    flex: 1,
                                    marginRight: document.body.dir == "rtl" ? 10 : 0,
                                    marginLeft: document.body.dir == "ltr" ? 10 : 0,
                                }}
                                >
                                <label>{t("sponsor.searchType")}</label>
                                <Select
                                    name="searchType"
                                    id="searchType"
                                    options={searchTypeSelect}
                                    getOptionLabel={(option) => option.value}
                                    getOptionValue={(option) => option.key}
                                    isClearable={true}
                                    onChange={(status) => {
                                    if (status) {
                                        setFieldValue("searchType", status.key);
                                    } else {
                                        setFieldValue("searchType", null);
                                    }
                                    }}
                                    placeholder={t("sponsor.searchType")}
                                />
                                <p className={"form-input-error-space"}>
                                    {errors.searchType ? errors.searchType : null}
                                </p>
                                </div>
                            </div>
                            <div
                                style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-around",
                                width: "100%",
                                marginTop: 30,
                                }}
                            >
                                <div
                                style={{
                                    flex: 1,
                                    marginRight: document.body.dir == "rtl" ? 10 : 0,
                                    marginLeft: document.body.dir == "ltr" ? 10 : 0,
                                }}
                                >
                                <label>{t("sponsor.searchTrainee")}</label>
                                <input
                                    id="search"
                                    type="search"
                                    name="search"
                                    className="admin_filter_input"
                                    onChange={(e) => {
                                    setSearch(e.target.value);
                                    }}
                                    placeholder={t("sponsor.searchEmail")}
                                    value={search}
                                />
                                <p className={"form-input-error-space"}>
                                    {errorSearch ? errorSearch : null}
                                </p>
                                </div>

                                <div
                                style={{
                                    flex: 0.4,
                                    marginRight: document.body.dir == "rtl" ? 10 : 0,
                                    marginLeft: document.body.dir == "ltr" ? 10 : 0,
                                }}
                                >
                                <button
                                    style={{
                                    marginTop: "33px",
                                    padding: "6px 0",
                                    borderRadius: "5px",
                                    }}
                                    className="admin_add_button"
                                    onClick={(e) => {
                                    e.stopPropagation();
                                    searchHandle(
                                        values.searchType,
                                        values.type,
                                        values.course_id,
                                        values.program_id
                                    );
                                    }}
                                    type="button"
                                >
                                    {t("sponsor.search")}
                                </button>
                                </div>

                            </div>

                            </div>
                            :
                            // {/* download */}
                            <div className="col-md-12">
                                <div className='' style={{fontWeight:"500", width: "100%", padding:" 11px 0px",marginTop: "30px"}}>
                                        {t('upload_excel_file')}
                                        <div onClick={()=>{
                                            setQuestionCircle(!QuestionCircle)
                                        }} style={{ display: "inline-block", padding: "0 7px"}}>
                                            <i className='fa fa-question-circle' ></i>
                                        </div>
                                        {
                                            QuestionCircle?
                                                <div style={{display: 'inline-block',
                                                    background: '#e2e2e2cc',
                                                    padding: '8px 20px',
                                                    fontSize: '12px',
                                                    borderRadius: '1px',
                                                    height: '46px',
                                                    cursor: "pointer",
                                                    position: "absolute"}}>
                                                    <div onClick= { (e) => {
                                                        // const link = document.createElement("a");
                                                        // link.href = process.env.REACT_APP_SERVER_PATH + "static/media/" + 'exampleEmail.xlsx';
                                                        // link.setAttribute("target", "_blank");
                                                        // link.setAttribute("download", "exampleEmail" + "." + "xlsx");
                                                        // document.body.appendChild(link);
                                                        // link.click();
                                                        // link.parentNode.removeChild(link);

                                                        saveAs(
                                                            `${process.env.REACT_APP_SERVER_PATH}static/media/exampleEmail.xlsx`,
                                                            "exampleEmail.xlsx"
                                                        );

                                                        }}>{t('Download_suggested_file')}</div>
                                                </div>
                                            :
                                            null
                                        }


                                </div>
                                <input
                                    id="file_doc"
                                    name={`excel_file`}
                                    type="file"
                                    onChange={(event) => {
                                        let file = event.target.files[0];
                                        let reader = new FileReader();
                                        reader.readAsDataURL(file);
                                        reader.onload = () => {
                                            setFieldValue(`excel_file`, event.target.files[0]);
                                        };
                                        reader.onerror = function (error) {
                                            
                                        };
                                    }}
                                />
                                <p className={"form-input-error-space"}>
                                    {errors[`excel_file`] ? errors[`excel_file`] : null}
                                </p>
                            </div>
                            // {/* download list */}
                    }
                    {
                        values.trainee_type == 2?
                        <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                            width: "100%",
                            marginTop: 30,
                        }}
                        >

                        <div
                            style={{
                            flex: 1,
                            marginRight: document.body.dir == "rtl" ? 10 : 0,
                            marginLeft: document.body.dir == "ltr" ? 10 : 0,
                            }}
                        >
                            <label>{t("sponsor.listTrainees")}</label>

                            <div
                            className="table-responsive"
                            style={{
                                width: "100%",
                            }}
                            >
                            <table className="table table-bordered">
                                {loadingTable ? (
                                <div
                                    className="mt-5"
                                    style={{
                                    width: "100%",
                                    height: "20vh",
                                    }}
                                >
                                    <SkeletonCard />
                                </div>
                                ) : (
                                <>
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">{t("sponsor.user")}</th>
                                        <th scope="col">{t("sponsor.rels")}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {traineeTable.length === 0 ? (
                                        <tr>
                                        <td colSpan={4}>{t("no_record")}</td>
                                        </tr>
                                    ) : (
                                        traineeTable.map((trainee, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>
                                            {i18n.language === Lang.AR
                                                ? trainee.name_ar
                                                : trainee.name_ar}
                                            </td>
                                            <td>
                                            <div className="release_icon">
                                                <IconTooltips
                                                title={t(
                                                    "modal.coupon.buttons.delete"
                                                )}
                                                content={
                                                    <i
                                                    className="fal fa-trash-alt"
                                                    onClick={() =>
                                                        deleteHandler(
                                                        i18n.language === Lang.AR
                                                            ? trainee.name_ar
                                                            : trainee.name_ar
                                                        )
                                                    }
                                                    style={{
                                                        marginLeft: 5,
                                                        marginRight: 5,
                                                        backgroundColor: "white",
                                                        borderWidth: 0,
                                                        fontSize: 20,
                                                        color: "red",
                                                        cursor: "pointer",
                                                    }}
                                                    ></i>
                                                }
                                                />
                                            </div>
                                            </td>
                                        </tr>
                                        ))
                                    )}
                                    </tbody>
                                </>
                                )}
                            </table>
                            </div>
                            <p className={"form-input-error-space"}>
                            {errors.trainees ? errors.trainees : null}
                            </p>
                        </div>
                        </div>
                    :
                            null
                    }
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        width: "20%",
                        marginTop: 20,
                      }}
                    >
                      <button
                        style={{
                          width: "100%",
                        }}
                        type="submit"
                        className="admin_add_button"
                      >
                        {t("sponsor.save")}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </>
        ) : (
          <div
            className="mt-5"
            style={{
              height: "50vh",
            }}
          >
            <SkeletonCard />
          </div>
        )}
      </div>
    </>
  );
}

export default AddSponsor;
