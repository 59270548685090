import React from "react";

function SkeletonSpinnerOverlay(props) {
  return (
    <>
      {props.backdrop == undefined || props.backdrop == null ? (
        <div
          style={{
            position: "absolute",
            backgroundColor: "rgba(0,0,0,0.7)",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="skeleton-box skeleton-margin-top-bottom"
            style={{ width: "40%" }}
          ></div>
        </div>
      ) : (
        <div
          className="skeleton-box skeleton-margin-top-bottom"
          style={{
            width: `${props.skeletonWidth}%`,
            height: `${props.skeletonHight}%`,
          }}
        ></div>
      )}
    </>
  );
}

export default SkeletonSpinnerOverlay;
