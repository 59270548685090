import { ContactInformation, ContactUsForm } from "./";
import { Grid, Stack } from "@mui/material";
import { ContainerBox } from "../Shared/Components";

function ContactUs() {
	return (
		<Stack component={"section"}>
			<ContainerBox
				sx={{
					mt: "52px",
				}}
			>
				<Grid container spacing={"16px"}>
					<Grid item xs={12} md={5}>
						<ContactUsForm />
					</Grid>
					<Grid item xs={12} md={7}>
						<ContactInformation />
					</Grid>
				</Grid>
			</ContainerBox>
		</Stack>
	);
}

export default ContactUs;
