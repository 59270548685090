import React, { useEffect, useState } from "react";
import classes from "./SearchInput.module.css";
import { useTranslation } from "react-i18next";
import SearchIcon from "../../../../assets/icons/SearchHome.svg";
import ClearIcon from "../../../../assets/icons/delete-gray.svg";
import { useHistory } from "react-router-dom";
import { getSearchAutoComplete } from "../../../../Services/api/HomeCoursesProvider";
import SkeletonCard from "../Spinner/SkeletonCard";

export default function SearchInput() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [searchName, setSearchName] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [CoursesSearch, setAvailableCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const handleSearch = (e) => {
    setSearchName(e.target.value);
    if (e.target.value.length > 0) {
      setIsSearching(true);
    } else {
      setIsSearching(false);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchName === "") {
      return;
    }
    const queryParams = new URLSearchParams();
    queryParams.set("q", searchName);
    history.push({
      pathname: "/home-search",
      search: "?" + queryParams.toString(),
    });
  };
  const handleListClick = (name) => {
    if (searchName === "") {
      return;
    }
    const queryParams = new URLSearchParams();
    queryParams.set("q", name);
    history.push({
      pathname: "/home-search",
      search: "?" + queryParams.toString(),
    });
  };

  useEffect(() => {
    const apiCall = getSearchAutoComplete;
    apiCall()
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setAvailableCourses(res.data.data);
          setIsLoading(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      {isLoading ? (
        <div className={classes["search_container"]}>
          <form className={classes["search-box"]} onSubmit={handleSubmit}>
            <input
              type="search"
              placeholder={t("homeSearch")}
              name="search"
              value={searchName}
              onChange={handleSearch}
              style={{
                width: "100%",
                backgroundColor: "transparent",
              }}
              autoComplete="off"
            />
            {searchName && (
              <img
                src={ClearIcon}
                style={{ width: 16, height: 16 }}
                alt=""
                onClick={() => {
                  setSearchName("");

                  setIsSearching(false);
                }}
              />
            )}
            <button type="submit">
              <img src={SearchIcon} alt="" />
            </button>

            {isSearching && (
              <div
                className={classes["main__filter__search-field__auto-complete"]}
              >
                <ul>
                  {CoursesSearch.filter(
                    (course) =>
                      course.name
                        ?.toLowerCase()
                        .includes(searchName?.toLowerCase()) ||
                      course.description
                        ?.toLowerCase()
                        .includes(searchName.toLowerCase()) ||
                      course.tags?.some((tag) =>
                        tag?.toLowerCase().includes(searchName?.toLowerCase())
                      )
                  ).map((course) => (
                    <li
                      onClick={() => {
                        setSearchName(course.name);
                        handleListClick(course.name);
                        setIsSearching(false);
                      }}
                      key={course.id}
                    >
                      {course.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </form>
          {/* <CustomButton
        value={t("searchbtn")}
        colors={"#025299"}
        type={"button"}
        action={() => handleSubmit()}
        classes={classes.searchbtn}
      /> */}
        </div>
      ) : (
        <SkeletonCard />
      )}
    </>
  );
}
