import React from "react";
import classes from "./checkbox.module.css";
const Checkbox = ({
  name,
  onChange,
  label,
  checked,
  error,
  required,
  disabled = false,
  defaultValue,
}) => {
  return (
    <>
      <div className={classes["checkbox-field"]}>
        <input type="checkbox" name={name} id={name} onChange={onChange} checked={checked} disabled={disabled} defaultValue={defaultValue} />
        <label htmlFor={name}>
          {label} {required && <span style={{ color: "red" }}>*</span>}
        </label>
      </div>
      {<p className={`${classes["checkbox-field-error"]}`}>{error && error}</p>}
    </>
  );
};

export default Checkbox;
