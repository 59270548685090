import React from "react";
import classes from "./options.module.css";
import { Tooltip } from "@material-ui/core";
const Options = ({ options }) => {
  return (
    <ul className={classes["options"]}>
      {options?.length &&
        options.map((option) => (
          <li
            className={`${classes["options__item"]} ${
              option.disabled && classes["options__item--disabled"]
            }`}
            key={option.id}
            onClick={option.action}
          >
            <Tooltip title={option.label}>{option.icon}</Tooltip>
          </li>
        ))}
    </ul>
  );
};

export default Options;
