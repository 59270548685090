import {Redirect, Route} from "react-router-dom";

function RouteInterceptor({ component: Component, path: Path, show: Show, exact: Exact }) {
    return (
        <Route
            exact={Exact}
            path={Path}
            render={props =>
                Show ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    );
}

export default RouteInterceptor;