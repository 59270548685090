import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { updateCouponStatus } from "../../../../Redux/Actions/coupons/couponsList.action";
import DeleteIcon from "../../../../assets/icons/delete.svg";
import EyeIcon from "../../../../assets/icons/eye.svg";
import editIcon from "../../../../assets/icons/pen.svg";
import { utcToLocal, utcToLocalFullFormat } from "../../../../utils/utcToLocal";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import SwitchToggle from "../../../Shared/Components/SwitchToggle/SwitchToggle";
import TableActionsColumn from "../../../Shared/Components/TableActionsColumn/TableActionsColumn";
import classes from "./coupons.module.css";

const CouponsTable = ({ deleteHandler, type }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { couponsList, couponsListLoading } = useSelector(
    (state) => state.couponsListReducer
  );

  const displayMode = (parent_type) => {
    if (parent_type === 1) {
      return t("rate.label.course");
    } else {
      return t("general.program");
    }
  };

  const couponsActionsList = [
    ...(type !== "receivedCoupon"
      ? [
          {
            id: "view-details",
            icon: <img src={EyeIcon} alt="" />,
            action: (rowData) => {
              if (type === "sendCoupon") {
                history.push(`/private-profile/sent-coupons/${rowData.id}`);
              } else {
                history.push(
                  `/${
                    localStorage.getItem("type") == "accountant"
                      ? "accountant"
                      : "admin"
                  }/${type === "gift" ? "gift-coupon" : "coupon"}/${rowData.id}`
                );
              }
            },
            tooltip: t("general.view_details"),
          },
        ]
      : []),
    ...(type === "notGift"
      ? [
          {
            id: "delete",
            icon: <img src={DeleteIcon} alt="" />,
            action: (rowData) => {
              deleteHandler(rowData.id);
            },
            tooltip: t("general.delete"),
          },
          {
            id: "edit",
            icon: <img src={editIcon} alt="" />,
            action: (rowData) => {
              history.push(
                `/${
                  localStorage.getItem("type") == "accountant"
                    ? "accountant"
                    : "admin"
                }/coupon/edit/${rowData.id}`
              );
            },
            tooltip: t("general.edit"),
          },
        ]
      : []),
    ...(type === "receivedCoupon"
      ? [
          {
            id: "view-details",
            icon: <img src={EyeIcon} alt="" />,
            action: (rowData) => {
              history.push(
                `/private-profile/received-coupons/${
                  rowData?.parent_type == 1 ? "course" : "program"
                }/${
                  rowData?.parent_type == 1
                    ? rowData?.parent?.id
                    : rowData?.parent?.program?.id
                }/details`
              );

              // /private-profile/received-coupons/course/:id/details
            },
            disabled: (rowData) => {
              if (
                (!rowData.is_used &&
                  !moment().isBefore(utcToLocalFullFormat(rowData.end_date))) ||
                !moment().isBefore(
                  utcToLocalFullFormat(rowData.parent.end_date)
                )
              ) {
                return true;
              }
            },
            tooltip: t("general.view_details"),
          },
        ]
      : []),
  ];

  const updateCouponStatusHandler = (id) => {
    dispatch(
      updateCouponStatus({
        id,
      })
    );
  };

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => <>{rowIndex + 1}</>,
    },
    {
      id: "name",
      name: t("coupon.label.coupon_name"),
      key: "name",
      cell: ({ rowData }) => (
        <div className={classes["coupon-list__table-name"]}>
          {rowData?.name || "-"}
        </div>
      ),
    },
    {
      id: "id",
      name: t("general.id"),
      key: "id",
      cell: ({ rowData }) => <>{rowData?.id}</>,
      sortable: false,
    },
    ...(type !== "notGift"
      ? [
          {
            id: "sender_name",
            name: t("coupon.label.sender_name"),
            key: "sender_name",
            cell: ({ rowData }) => (
              <>
                {rowData?.sender[
                  i18n.language === "ar" ? "name_ar" : "name_en"
                ] || "-"}
              </>
            ),
            sortable: false,
          },
          {
            id: "sender_email",
            name: t("coupon.label.sender_email"),
            key: "sender_email",
            cell: ({ rowData }) => <>{rowData?.sender?.email || "-"}</>,
            sortable: false,
          },
        ]
      : []),
    {
      id: "code",
      name: t("coupon.label.coupon_code"),
      key: "code",
      cell: ({ rowData }) => <>{rowData?.code || "-"}</>,
    },
    ...(type === "notGift"
      ? [
          {
            id: "discount_type",
            name: t("coupon.label.discount_type"),
            key: "discount_type",
            cell: ({ rowData }) => (
              <>
                {rowData?.mechanism == 2
                  ? t("coupon.label.per_value")
                  : t("coupon.label.co_value")}
              </>
            ),
          },
        ]
      : []),
    {
      id: "coupon_dis",
      name: t("coupon.label.coupon_dis"),
      key: "coupon_dis",
      cell: ({ rowData }) => (
        <>
          {rowData?.mechanism == 1
            ? rowData?.value + t("general.sar")
            : rowData?.value + "%"}
        </>
      ),
    },
    {
      id: "is_used",
      name: t("coupon.label.is_used"),
      key: "is_used",
      cell: ({ rowData }) => <>{rowData?.is_used ? t("yes") : t("no")}</>,
    },
    ...(type !== "notGift"
      ? [
          {
            id: "include_tax",
            name: t("financial.label.include_tax"),
            key: "include_tax",
            cell: ({ rowData }) => (
              <>{rowData?.include_tax ? t("yes") : t("no")}</>
            ),
          },
        ]
      : []),
    {
      id: "parent_type",
      name: t("zoom.type"),
      key: "parent_type",
      cell: ({ rowData }) => (
        <>
          <span
            className="cell-font tw-whitespace-nowrap tw-rounded-lg tw-py-1 tw-px-2"
            style={{
              color: rowData?.parent_type == 1 ? "#8599B6" : "#12C689",
              backgroundColor:
                rowData?.parent_type == 1 ? "#C1CEE222" : "#C1E2C822",
            }}
          >
            {displayMode(rowData?.parent_type) || "-"}
          </span>
        </>
      ),
    },
    {
      id: "course_or_program",
      name: t("coupon.label.course_or_program"),
      key: "course_or_program",
      cell: ({ rowData }) => (
        <span
          style={{
            color: "#046c77",
          }}
        >
          {type !== "notGift"
            ? rowData?.parent_type == 1
              ? rowData?.parent?.name
              : `${rowData?.parent?.program?.name}` || "-"
            : rowData?.parent?.name || "-"}
        </span>
      ),
    },
    ...(type !== "notGift"
      ? [
          {
            id: "payment_number",
            name: t("financial.label.payment"),
            key: "payment_number",
            cell: ({ rowData }) => (
              <>
                {(rowData?.parent?.payment_number &&
                  `${t("general.payment")} ${
                    rowData?.parent?.payment_number
                  }`) ||
                  "-"}
              </>
            ),
          },
        ]
      : []),
    ...(type === "notGift"
      ? [
          {
            id: "note_for_user",
            name: t("note_for_user"),
            key: "note_for_user",
            cell: ({ rowData }) => (
              <>
                <div style={{ width: 300 }}>
                  <p className={classes["note_table"]}>
                    {rowData?.notes || "-"}
                  </p>
                </div>
              </>
            ),
          },
        ]
      : []),
    {
      id: "start_date",
      name: t("general.start_date"),
      key: "start_date",
      cell: ({ rowData }) => (
        <>
          {moment(rowData?.start_date).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData?.start_date)}
        </>
      ),
    },
    {
      id: "end_date",
      name: t("general.end_date"),
      key: "end_date",
      cell: ({ rowData }) => (
        <>
          {moment(rowData?.end_date).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData?.end_date)}
        </>
      ),
    },
    {
      id: "created_at",
      name:
        type === "notGift"
          ? t("general.added_date")
          : t("coupon.label.gift_date"),
      key: "created_at",
      cell: ({ rowData }) => (
        <>
          {moment(rowData?.created_at).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData?.created_at)}
        </>
      ),
    },
    ...(type !== "receivedCoupon"
      ? [
          {
            id: "max_use",
            name: t(
              `coupon.label.${
                type === "notGift" ? "max_times" : "gift_max_times"
              }`
            ),
            key: "max_use",
            cell: ({ rowData }) => <>{rowData?.times}</>,
          },
          {
            id: "use",
            name: t("coupon.label.used_times"),
            key: "use",
            cell: ({ rowData }) => (
              <>
                {Number(rowData?.approved_payments_count) +
                  Number(rowData?.waiting_payments_count)}
              </>
            ),
          },
          ...(type === "notGift"
            ? [
                {
                  id: "use_times",
                  name: t("coupon.label.use_times"),
                  key: "use_times",
                  cell: ({ rowData }) => (
                    <>
                      {rowData?.times_trainee === "0"
                        ? t("general.all_users")
                        : rowData?.times_trainee}
                    </>
                  ),
                },
              ]
            : []),
        ]
      : []),
    {
      id: "actions",
      name: t("general.actions"),
      key: "actions",
      cell: ({ rowData }) => (
        <TableActionsColumn actions={couponsActionsList} id={rowData} />
      ),
    },
    ...(type === "notGift" || type === "gift"
      ? [
          {
            id: "status",
            name: t("general.status"),
            key: "status",
            cell: ({ rowData }) => (
              <SwitchToggle
                status={rowData.status == "1"}
                id={rowData.id}
                onStatusChange={() => {
                  updateCouponStatusHandler(rowData.id);
                }}
              />
            ),
            sortable: false,
          },
        ]
      : []),
  ];

  return (
    <MainTable
      columns={columns}
      data={couponsList || []}
      isLoading={couponsListLoading}
    />
  );
};

export default CouponsTable;
