import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { Formik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Select from "react-select";
import { toast } from "react-toastify";
import { updateTaxStatus } from "../../../../../Redux/Actions/Tax/taxList.action";
import { toServerTime } from "../../../../../Services/Timezone";
import { getAllCourses } from "../../../../../Services/api/CoursesList";
import { getExhibitionList } from "../../../../../Services/api/Exhibition/ExhibitionProvider";
import { getCountries } from "../../../../../Services/api/profile/profileProvider";
import HomeIcon from "../../../../../assets/icons/home.svg";
import { formatDate, formatTime } from "../../../../../utils/formatDate";
import { getLocaleTime } from "../../../../../utils/utcToLocal";
import Breadcrumb from "../../../../Shared/Components/Breadcrumb/Breadcrumb";
import DataTable from "../../../../Shared/Components/DataTable/DataTable";
import NewDataTable from "../../../../Shared/Components/DataTable/NewDataTable";
import Input from "../../../../Shared/Components/Input/Input";
import MainBox from "../../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../../Shared/Components/MainBoxHead/MainBoxHead";
import MainDatePicker from "../../../../Shared/Components/MainDatePicker/MainDatePicker";
import SkeletonCardOverlay from "../../../../Shared/Components/Spinner/SkeletonCardOverlay";
import classes from "./Edit.module.css";
import ManageTaxAction from "./ManageTaxAction";

const ManageTax = ({ type, submitHandler, taxDetails }) => {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [fetching, setFetching] = useState(true);

  const [hoursDatePicker, setHoursDatePicker] = useState(null);
  const [minutesDatePicker, setMinutesDatePicker] = useState(null);
  const [hoursDatePickerEnd, setHoursDatePickerEnd] = useState(null);
  const [minutesDatePickerEnd, setMinutesDatePickerEnd] = useState(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [countries, setCountries] = useState([]);
  const [courses, setCourses] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [boothExhibition, setBoothExhibition] = useState([]);
  const [visitorExhibition, setVisitorExhibition] = useState([]);

  const dispatch = useDispatch();

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "tax-list",
      page: t("financial.tax.tax"),
      pagePath: `${
        localStorage.getItem("type") == "accountant" ? "/accountant" : "/admin"
      }/tax`,
    },
    {
      id: "create_exhibition",
      page: type === "add" ? t("financial.tax.add_tax") : taxDetails?.name,
      active: true,
    },
  ];
  const mechanismOptions = [
    {
      value: 2,
      label: t("coupon.label.per_value"),
    },
    // {
    //   value: 1,
    //   label: t("amount_tax"),
    // },
  ];

  const parentTypeOptions = [
    {
      label: t("general.course"),
      id: 1,
      value: 1,
    },
    {
      label: t("general.program"),
      id: 2,
      value: 2,
    },
    {
      label: t("general.exhibition_booth"),
      id: 3,
      value: 3,
    },
    {
      label: t("general.exhibition_visitor"),
      id: 4,
      value: 4,
    },
  ];

  const currentDate = (datePicker) => {
    let HoursDate = new Date().getHours();
    let MinutesDate = new Date().getMinutes();

    if (datePicker === "startDate") {
      setHoursDatePicker(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setMinutesDatePicker(MinutesDate >= 30 ? 0 : MinutesDate + 10);
    } else if (datePicker === "endDate") {
      setHoursDatePickerEnd(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setMinutesDatePickerEnd(MinutesDate >= 30 ? 0 : MinutesDate + 10);
    } else {
      setHoursDatePicker(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setMinutesDatePicker(MinutesDate >= 30 ? 0 : MinutesDate);
      setHoursDatePickerEnd(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
      setMinutesDatePickerEnd(MinutesDate >= 30 ? 0 : MinutesDate);
    }
  };

  const fetchData = async () => {
    try {
      const [countriesRes, coursesRes, programsRes, boothRes, visitorRes] =
        await Promise.all([
          getCountries(),
          getAllCourses({
            paginated: 0,
            not_in_program: 1,
            is_program: 0,
            mode: "Paid",
            perPage: 1000,
          }),
          getAllCourses({
            paginated: 0,
            is_program: 1,
            mode: "Paid",
            perPage: 1000,
          }),
          getExhibitionList({ perPage: 1000, mode: "Paid" }),
          getExhibitionList({ perPage: 1000, booth_mode: "Paid" }),
        ]);

      if (countriesRes.status === 200 && countriesRes.data.status) {
        setCountries(countriesRes.data.countries);
      }

      if (coursesRes.status === 200 && coursesRes.data.status) {
        setCourses(coursesRes.data.data.courses.data);
      }

      if (programsRes.status === 200 && programsRes.data.status) {
        setPrograms(programsRes.data.data.courses.data);
      }

      if (visitorRes.status === 200) {
        setVisitorExhibition(visitorRes.data.data.exhibitions.data);
      }

      if (boothRes.status === 200) {
        setBoothExhibition(boothRes.data.data.exhibitions.data);
      }
    } catch (error) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {error.response ? error.response.data.msg : t("Failure_in_service")}
        </span>
      );
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    currentDate();
    fetchData();
    setIsLoading(false);
  }, []);

  const updateCourseStatusHandler = (id) => {
    dispatch(
      updateTaxStatus({
        id,
      })
    );
  };

  const fields = (type) => {
    const createField = (
      id,
      name,
      valueFormatter = (value) => value || "-"
    ) => ({
      id,
      name: t(`general.${name}`),
      column: ({ rowData }) => <>{valueFormatter(rowData[id])}</>,
    });
    return [
      createField("id", "id"),
      createField("name", "name"),
      ...(type === "courses"
        ? [
            createField("code", "code"),
            createField("run_serial_number", "serial_number"),
            createField("start_date", "start_date", (value) =>
              value ? moment(value).format("YYYY-MM-DD HH:mm:ss") : "-"
            ),
          ]
        : []),
      ...(type === "exhibition"
        ? [
            createField("start_time", "start_date", (value) =>
              value ? moment(value).format("YYYY-MM-DD HH:mm:ss") : "-"
            ),
            createField("end_time", "end_date", (value) =>
              value ? moment(value).format("YYYY-MM-DD HH:mm:ss") : "-"
            ),
          ]
        : []),
    ];
  };

  const parentTypeMapping = {
    1: {
      label: t("coupon.label.select_course"),
      data: courses,
      fields: fields("courses"),
      searchableFields: ["id", "name", "code", "run_serial_number"],
    },
    2: {
      label: t("coupon.label.select_program"),
      data: programs,
      fields: fields("courses"),
      searchableFields: ["id", "name", "code", "run_serial_number"],
    },
    3: {
      label: t("crud.placeholders.select.exhibition"),
      data: boothExhibition,
      fields: fields("exhibition"),
      searchableFields: ["id", "name"],
    },
    4: {
      label: t("crud.placeholders.select.exhibition"),
      data: visitorExhibition,
      fields: fields("exhibition"),
      searchableFields: ["id", "name"],
    },
  };

  return (
    <div className={classes["create_tax"]}>
      <div className="container-fluid">
        <div className={classes["create_tax__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          {!isLoading && (
            <div className="row">
              <div className="col-12">
                <MainBox>
                  <Formik
                    initialValues={{
                      name: taxDetails?.name || "",
                      notes: taxDetails?.notes || "",
                      status: taxDetails?.status || false,
                      value: taxDetails?.value || "",
                      parentType: taxDetails?.parent_type || 1,
                      parent_id: taxDetails?.parent?.id || "",
                      mechanism: taxDetails?.mechanism || "0",
                      nationalities: taxDetails?.excluded_nationalities || [],
                      start_date: taxDetails?.start_date
                        ? new Date(
                            getLocaleTime(new Date(taxDetails?.start_date))
                          )
                        : setHours(
                            setMinutes(new Date(), minutesDatePicker),
                            hoursDatePicker
                          ),
                      end_date: taxDetails?.end_date
                        ? new Date(
                            getLocaleTime(new Date(taxDetails?.end_date))
                          )
                        : setHours(
                            setMinutes(new Date(), minutesDatePickerEnd),
                            hoursDatePickerEnd
                          ),
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                      try {
                        let dataToBeUploaded = {
                          ...values,
                        };

                        dataToBeUploaded.nationalities =
                          dataToBeUploaded.nationalities.map(
                            (country) => country.id
                          );

                        dataToBeUploaded.start_date = toServerTime(
                          formatDate(new Date(dataToBeUploaded.start_date)) +
                            " " +
                            formatTime(new Date(dataToBeUploaded.start_date))
                        );

                        dataToBeUploaded.end_date = toServerTime(
                          formatDate(new Date(dataToBeUploaded.end_date)) +
                            " " +
                            formatTime(new Date(dataToBeUploaded.end_date))
                        );

                        dataToBeUploaded.status = dataToBeUploaded.status
                          ? 1
                          : 0;

                        if (type === "limitedEdit") {
                          // delete dataToBeUploaded.notes;
                          // delete dataToBeUploaded.nationalities;
                          // delete dataToBeUploaded.parentType;
                          // delete dataToBeUploaded.parent_id;
                          // delete dataToBeUploaded.status;
                        }

                        await submitHandler(dataToBeUploaded);
                        setSubmitting(false);
                      } catch (err) {
                        toast.error(
                          <div>
                            {Object.keys(err.response.data.errors).map(
                              (key) => {
                                return (
                                  <span style={{ fontSize: 12 }}>
                                    {err.response.data.errors[key]}
                                  </span>
                                );
                              }
                            )}
                          </div>
                        );
                      }
                    }}
                    validateOnChange={hasSubmitted}
                    validate={(values) => {
                      setHasSubmitted(true);
                      const errors = {};
                      if (!values.name) {
                        errors.name = t("crud.errors.required");
                      }
                      // if (!values.notes) {
                      //   errors.notes = t("crud.errors.required");
                      // }

                      if (!values.start_date) {
                        errors.start_date = t("crud.errors.required");
                      }
                      if (!values.end_date) {
                        errors.end_date = t("crud.errors.required");
                      }
                      if (!values.value) {
                        errors.value = t("crud.errors.required");
                      }
                      if (!values.parentType) {
                        errors.parentType = t("crud.errors.required");
                      }
                      if (!values.parent_id) {
                        errors.parent_id = t("crud.errors.required");
                      }

                      return errors;
                    }}
                  >
                    {({
                      values,
                      errors,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      handleBlur,
                      setFieldValue,
                      validateForm,
                    }) => (
                      <form
                        onSubmit={handleSubmit}
                        className={classes["create_tax__form"]}
                      >
                        {type === "cannotEdit" && (
                          <div className={classes["tax__warning__note"]}>
                            {t("financial.tax.cannot_edit_note")}
                          </div>
                        )}
                        {type === "limitedEdit" && (
                          <div className={classes["tax__warning__note"]}>
                            {t("financial.tax.limited_edit_note")}
                          </div>
                        )}
                        <MainBoxHead
                          title={
                            type === "canEdit"
                              ? t("financial.tax.edit_tax")
                              : taxDetails?.name
                          }
                          actions={[
                            ...(type !== "add"
                              ? [
                                  {
                                    id: "status",
                                    type: "switch",
                                    value: true,
                                    active: t("trainer.course.active"),
                                    inactive: t("trainer.course.inactive"),
                                    checked: values.status,
                                    disabled: false,

                                    onChange: (event) => {
                                      updateCourseStatusHandler(taxDetails?.id);
                                      setFieldValue(
                                        "status",
                                        event.target.checked ? true : false
                                      );
                                    },
                                  },
                                ]
                              : []),
                          ]}
                        />
                        <div
                          className={`row ${classes["create_tax__form__fields"]}`}
                        >
                          <div
                            className={`${classes["create_tax__form__field"]} col-12`}
                          >
                            <Input
                              label={t("financial.tax.name")}
                              type="text"
                              name="name"
                              placeholder={t("financial.tax.name")}
                              onChange={handleChange}
                              error={errors.name}
                              value={values.name}
                              disabled={type === "cannotEdit"}
                            />
                          </div>
                          <div
                            className={`${classes["create_tax__form__field"]} col-12`}
                          >
                            <Input
                              label={t("note_for_user")}
                              type="text"
                              name="notes"
                              placeholder={t("note_for_user")}
                              onChange={handleChange}
                              error={errors.notes}
                              value={values.notes}
                              disabled={
                                type === "cannotEdit" || type === "limitedEdit"
                              }
                            />
                          </div>
                          <div
                            className={`${classes["create_tax__form__field"]} col-12 col-md-6`}
                          >
                            <label htmlFor="mechanism">
                              {t("machinsim_tax")}
                            </label>
                            <Select
                              name="mechanism"
                              id="mechanism"
                              options={mechanismOptions}
                              value={mechanismOptions.find(
                                (option) => option.value == values.mechanism
                              )}
                              getOptionLabel={(option) => option.label}
                              getOptionValue={(option) => option.value}
                              onChange={(item) => {
                                setFieldValue("mechanism", item.value);
                              }}
                              placeholder={t("machinsim_tax")}
                              isDisabled={
                                type === "cannotEdit" || type === "limitedEdit"
                              }
                            />
                            <p className={"form-input-error-space"}>
                              {errors.mechanism ? errors.mechanism : null}
                            </p>
                          </div>
                          <div
                            className={`${classes["tax_coupon__form__field"]} col-12 col-md-6`}
                          >
                            <Input
                              label={t("financial.tax.value_tax")}
                              type="number"
                              name="value"
                              placeholder={t("financial.tax.value_tax")}
                              onChange={handleChange}
                              error={errors.value}
                              min={0.01}
                              value={values.value}
                              disabled={
                                type === "cannotEdit" || type === "limitedEdit"
                              }
                            />
                          </div>

                          <div
                            className={`${classes["create_tax__form__field"]} col-12 col-md-6`}
                          >
                            <MainDatePicker
                              label={t("general.start_date_time")}
                              name="start_date"
                              error={errors.start_date}
                              value={values.start_date}
                              onChange={(date) => {
                                setFieldValue("start_date", date);

                                let CurrentDate = new Date();
                                let CurrentDateFormate =
                                  CurrentDate.toISOString().split("T")[0];
                                let DataPickerValue = date
                                  .toISOString()
                                  .split("T")[0];
                                if (
                                  new Date(DataPickerValue).getTime() >
                                  new Date(CurrentDateFormate).getTime()
                                ) {
                                  setHoursDatePicker(0);
                                  setMinutesDatePicker(0);
                                } else {
                                  currentDate("startDate");
                                }
                              }}
                              minDate={new Date()}
                              minTime={setHours(
                                setMinutes(new Date(), minutesDatePicker),
                                hoursDatePicker
                              )}
                              maxTime={setHours(setMinutes(new Date(), 30), 23)}
                              readOnly={
                                type === "cannotEdit" || type === "limitedEdit"
                              }
                            />
                            <p className={"form-input-error-space"}>
                              {errors.start_date ? errors.start_date : null}
                            </p>
                          </div>
                          <div
                            className={`${classes["create_tax__form__field"]} col-12 col-md-6`}
                          >
                            <MainDatePicker
                              label={t("general.end_date_time")}
                              name="end_date"
                              error={errors.end_date}
                              value={values.end_date}
                              onChange={(date) => {
                                setFieldValue("end_date", date);

                                let CurrentDate = new Date();
                                let CurrentDateFormate =
                                  CurrentDate.toISOString().split("T")[0];
                                let DataPickerValue = date
                                  .toISOString()
                                  .split("T")[0];
                                if (
                                  new Date(DataPickerValue).getTime() >
                                  new Date(CurrentDateFormate).getTime()
                                ) {
                                  setHoursDatePickerEnd(0);
                                  setMinutesDatePickerEnd(0);
                                } else {
                                  currentDate("endDate");
                                }
                              }}
                              minDate={new Date()}
                              minTime={setHours(
                                setMinutes(new Date(), minutesDatePickerEnd),
                                hoursDatePickerEnd
                              )}
                              maxTime={setHours(setMinutes(new Date(), 30), 23)}
                              readOnly={
                                type === "cannotEdit" || type === "limitedEdit"
                              }
                            />
                            <p className={"form-input-error-space"}>
                              {errors.end_date ? errors.end_date : null}
                            </p>
                          </div>

                          <div
                            className={`${classes["create_tax__form__field"]} col-12`}
                          >
                            <div className={classes["select-field"]}>
                              <label htmlFor={"nationalities"}>
                                {t("nationality_expcted")}
                              </label>

                              <Select
                                isMulti
                                name={"nationalities"}
                                onChange={(value) => {
                                  setFieldValue("nationalities", value);
                                }}
                                className={classes["select"]}
                                options={countries.map((country) => {
                                  return {
                                    id: country.id,
                                    value: country.id,
                                    label: `${country.nationality} - ${country.name}`,
                                  };
                                })}
                                getOptionValue={(country) => country.id}
                                placeholder={t("nationality_expcted")}
                                defaultValue={values.nationalities.map(
                                  (country) => {
                                    return {
                                      id: country.id,
                                      value: country.id,
                                      label:
                                        i18n.language === "ar"
                                          ? `${country.nationality_ar} - ${country.name_ar}`
                                          : `${country.nationality_en} - ${country.name_en}`,
                                    };
                                  }
                                )}
                                isDisabled={type === "cannotEdit"}
                              />
                              {
                                <p
                                  className={`form-input-error-space ${classes["select-field-error"]}`}
                                >
                                  {errors.nationalities && errors.nationalities}
                                </p>
                              }
                            </div>
                          </div>
                          <div
                            className={`${classes["create_tax__form__field"]} col-12`}
                          >
                            <label htmlFor="course_program">
                              {t("coupon.label.select_course_or_program")}
                            </label>
                            <Select
                              name="course_program"
                              id="course_program"
                              options={parentTypeOptions}
                              value={parentTypeOptions.find(
                                (option) => option.id == values.parentType
                              )}
                              getOptionLabel={(option) => option.label}
                              getOptionValue={(option) => option.id}
                              onChange={(item) => {
                                setFieldValue("parentType", item.id);
                                setFieldValue("parent_id", "");
                              }}
                              placeholder={t(
                                "coupon.label.select_course_or_program"
                              )}
                              isDisabled={
                                type === "cannotEdit" ||
                                type === "limitedEdit" ||
                                type === "canEdit"
                              }
                            />
                            <p className={"form-input-error-space"}>
                              {errors.parentType ? errors.parentType : null}
                            </p>
                          </div>
                          <div
                            className={`${classes["create_coupon__form__field"]} col-12`}
                          >
                            <NewDataTable
                              label={parentTypeMapping[values.parentType].label}
                              selectedItems={[values.parent_id]}
                              isLoading={fetching}
                              data={parentTypeMapping[values.parentType].data}
                              fields={
                                parentTypeMapping[values.parentType].fields
                              }
                              isMultiple={false}
                              searchableFields={
                                parentTypeMapping[values.parentType]
                                  .searchableFields
                              }
                              selectedHandler={(item) => {
                                setFieldValue("parent_id", item?.id);
                              }}
                              disabled={
                                type === "cannotEdit" ||
                                type === "limitedEdit" ||
                                type === "canEdit"
                              }
                              error={errors.parent_id}
                              required
                            />
                          </div>
                          <ManageTaxAction
                            type={type}
                            submitAction={handleSubmit}
                            isSubmitting={isSubmitting}
                          />
                        </div>
                        {isSubmitting && <SkeletonCardOverlay />}
                      </form>
                    )}
                  </Formik>
                </MainBox>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageTax;
