export const faqConstant = {
  // loading
  START_LOADING: "START_LOADING",
  STOP_LOADING: "STOP_LOADING",

  GET_FAQ_GENERAL_CATEGORIES_LIST: "GET_FAQ_GENERAL_CATEGORIES_LIST",

  // faq category
  GET_FAQ_CATEGORY_LIST: "GET_FAQ_CATEGORY_LIST",
  GET_FAQ_CATEGORY_ADD: "GET_FAQ_CATEGORY_ADD",
  GET_FAQ_CATEGORY_DELETE: "GET_FAQ_CATEGORY_DELETE",
  GET_FAQ_CATEGORY_EDIT: "GET_FAQ_CATEGORY_EDIT",
  GET_FAQ_CATEGORY_SHOW: "GET_FAQ_CATEGORY_SHOW",
  GET_FAQ_CATEGORY_STATUS_CHANGE: "GET_FAQ_CATEGORY_STATUS_CHANGE",
  GET_FAQ_CATEGORY_RE_ORDER: "GET_FAQ_CATEGORY_RE_ORDER",

  // faq questions
  GET_FAQ_QUESTION_LIST: "GET_FAQ_QUESTION_LIST",
  GET_FAQ_QUESTION_ADD: "GET_FAQ_QUESTION_ADD",
  GET_FAQ_QUESTION_RESET: "GET_FAQ_QUESTION_RESET",
  GET_FAQ_QUESTION_DELETE: "GET_FAQ_QUESTION_DELETE",
  GET_FAQ_QUESTION_EDIT: "GET_FAQ_QUESTION_EDIT",
  GET_FAQ_QUESTION_SHOW: "GET_FAQ_QUESTION_SHOW",
  GET_FAQ_QUESTION_STATUS_CHANGE: "GET_FAQ_QUESTION_STATUS_CHANGE",
  GET_FAQ_QUESTION_RE_ORDER: "GET_FAQ_CATEGORY_RE_ORDER",
};
