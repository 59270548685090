import { Rating } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import AddRateCourseModal from "../../../../Modals/rate/AddRateCourseModal";
import SurveysModal from "../../../../Modals/surveyModal/SurveysModal";
import { certificateList } from "../../../../Services/api/certificate/CertificateProvider";
import { getCourseRate, getCourseTree } from "../../../../Services/api/courses/courseProvider";
import { getSurveysForLessonOrCourse } from "../../../../Services/api/survey/SurveyProvider";
import { TrainingCourseProfileContext } from "../../../../Services/api/toutorials/TrainingCourseProfileContext";
import HomeIcon from "../../../../assets/icons/home.svg";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import { BasicModal } from "../../../Shared/Components/Modal/BasicModal";
import SkeletonSpinnerOverlay from "../../../Shared/Components/Spinner/SkeletonSpinnerOverlay";
import TrainingCourseHeaderCard from "../../TrainingCourseHeaderCard/TrainingCourseHeaderCard";
import TrainingCourseProfileHeaderDetails from "./TrainingCourseProfileHeaderDetails";

function TrainingCourseHeader(props) {
  require("./TrainingCourseProfileHeader.css");
  const { t } = useTranslation();
  const addRateCourseModalRef = useRef();

  const [survies, setSurvies] = useState([]);
  const { id } = useParams();
  const trainingCourseProfileContext = useContext(TrainingCourseProfileContext);
  const [isCertificate, setIsCertificate] = useState("");
  const [ratings, setRatings] = useState("");
  const [openRate, setOpenRate] = useState(false);

  useEffect(() => {
    if (id) {
      getCourseTree(id)
        .then((res) => {
          if (res.status && res.status === 200 && res.data.status) {
            trainingCourseProfileContext &&
              trainingCourseProfileContext.setUserPoints(res.data.response.UserPoints, getCourseRateFunc());
            trainingCourseProfileContext.setSyllabus(res.data.response.chapters);
            trainingCourseProfileContext.setMasterTrainer(res.data.response.masterTrainer);
            trainingCourseProfileContext.setCourseInfo(res.data.response.courseInfo);
            trainingCourseProfileContext.setProgress(res.data.response.progress);
            trainingCourseProfileContext.setProgressQuiz(res.data.response.quiz_progress);
            trainingCourseProfileContext.setIsLoading(false);
          }
        })
        .catch((err) => {
          
        });
    }
    checkCertificate();
  }, [id]);

  // const checkCourse = (arr) => {
  //   arr.courses.some(function (el) {
  //     if (el.id == id) {
  //       return el.id;
  //     }
  //   });
  // };

  const checkCertificate = async () => {
    const res = await certificateList();
    if (Object.keys(res.data.response).length > 0) {
      let array = res.data.response.courses;
      for (let i = 0; i < array.length; i++) {
        if (array[i].id === Number(id)) {
          setIsCertificate(array[i].id);
          break;
        }
      }
    }
  };

  const getSurveysForCourse = (courseId) => {
    setSurvies([]);
    getSurveysForLessonOrCourse(courseId, null)
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setSurvies(res.data.surveys);
        }
      })
      .catch((err) => {
        
        
      });
  };

  const getCourseRateFunc = () => {
    getCourseRate(props.courseID)
      .then((res) => {
        if (res.status && res.status === 201 && res.data.status) {
          setRatings(res.data.ratings);
        }
      })
      .catch((err) => {
        
      });
  };

  const handleOpenSurvies = () => {
    getSurveysForCourse(props.courseID);
    addRateCourseModalRef.current.showModal();
    setOpenRate(false);
  };

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "courses",
      page: t("Hometraining"),
      pagePath: "/training-courses",
    },
    {
      id: "course-name",
      page: trainingCourseProfileContext.courseInfo.name,
      active: true,
    },
  ];

  return (
    <>
      <div>
        <div className="container-fluid training_wrapper">
          <Breadcrumb list={breadcrumbList} />
          <div className="training_details">
            {/* <TrainingCourseHeaderCard ratings={ratings} courseContext={trainingCourseProfileContext} /> */}
            <TrainingCourseProfileHeaderDetails
              isCertificate={isCertificate}
              id={id}
              handleOpenSurvies={handleOpenSurvies}
            />
          </div>
        </div>
      </div>
      <>
        <BasicModal ref={addRateCourseModalRef}>
          {openRate === false ? (
            <SurveysModal
              label={t("survey.surviesLabel")}
              title={t("survey.surviesLabel")}
              body={survies}
              courseId={props.courseID}
              surveysModalRef={addRateCourseModalRef}
              accept={() => {
                addRateCourseModalRef.current.dismissModal();
              }}
            />
          ) : (
            <AddRateCourseModal
              label={t("rate.label.addRate")}
              title={t("rate.label.addRate")}
              courseId={props.courseID}
              addRateCourseModalRef={addRateCourseModalRef}
              accept={() => {
                addRateCourseModalRef.current.dismissModal();
              }}
            />
          )}
        </BasicModal>
      </>
    </>
  );
}

export default TrainingCourseHeader;
