import React, {createContext, useState} from 'react';

const TrainingCourseProfileContext = createContext();

function TrainingCourseProfileProvider(props) {
    const [tab, setTab] = useState(0);
    const [comments, setComments] = useState([1,2,3]);
    const [syllabus, setSyllabus] = useState([]);
    const [courseID, setCourseID] = useState(null);
    const [userPoints , setUserPoints] = useState();
    const [masterTrainer , setMasterTrainer] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [courseInfo, setCourseInfo] = useState("")
    const [survies, setSurvies] = useState([])
    const [openRate, setOpenRate] = useState(false)
    const [showSurvies,setShowSurvies] = useState(false)
    const [progress,setProgress] = useState("")
    const [progressQuiz,setProgressQuiz] = useState("")

    return (
        <TrainingCourseProfileContext.Provider value={{
            tab,
            setTab,
            comments,
            setComments,
            syllabus,
            setSyllabus,
            courseID,
            setCourseID,
            userPoints,
            setUserPoints,
            masterTrainer,
            setMasterTrainer,
            isLoading,
            setIsLoading,
            courseInfo,
            setCourseInfo,
            survies,
            setSurvies,
            openRate,
            setOpenRate,
            showSurvies,
            setShowSurvies,
            progress,
            setProgress,
            progressQuiz,
            setProgressQuiz,
        }}>
            { props.children }
        </TrainingCourseProfileContext.Provider>
    );
}

export {TrainingCourseProfileContext, TrainingCourseProfileProvider};
