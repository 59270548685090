import { toast } from "react-toastify";
import env from "../Environment";
import connector from "../Handler";
import URLBuilder from "../UrlBuilder";

export function getOrder(data) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.order, {
          params: data,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function exportOrder(data, export_type) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.order, {
          responseType: "blob",
          params: {
            ...data,
            export: export_type,
          },
        })
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "order" + "." + export_type); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getOffers(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.offers, {
          params: payload,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function deleteOffers(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .delete(URLBuilder(env.delete_offer, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function addOffer(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.get_offers, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function putOffer(id, payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .put(URLBuilder(env.get_offers_by_id, { id: id }), payload, {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getOfferById(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_offer_by_id, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function updateOffer(payload, id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.update_offer, { id: id }), payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function exportOffer(type, params) {
  let extension = "";
  type == "pdf" ? (extension = "pdf") : (extension = "xlsx");
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.offers, {
          responseType: "blob",
          params: {
            ...params,
            export: extension,
          },
        })
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "offers" + "." + extension);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function addPaymentRequest(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.add_payment_request, payload)
        .then((res) => {
          resolve(res);
          if (res?.status === 201 && res?.data?.status) {
            toast.success(
              <span style={{ fontSize: 13, fontWeight: "bold" }}>
                {res.data.msg}
              </span>
            );
          }
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getCoursesForUser(role, payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.courses_list, { role: role }), {
          params: payload,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function getTax(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.tax, {
          params: payload,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function deleteTax(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .delete(URLBuilder(env.delete_tax, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function addTax(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.add_tax, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getTaxById(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_tax_by_id, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function updateTax(payload, id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .put(URLBuilder(env.update_tax, { id: id }), payload, {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function exportTax(type, params) {
  let extension = "";
  type == "pdf" ? (extension = "pdf") : (extension = "xlsx");
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.tax, {
          responseType: "blob",
          params: {
            ...params,
            export: extension,
          },
        })
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "tax" + "." + extension);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function refundOrder(id, payload, type) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.refund_order, { id: id, type: type }), payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function finanicalDashboard() {
  let payload = {
    coupon_status: 1,
  };
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.finanicalDashboard, {
          params: payload,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getRefunds({ page, perPage, filter }) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_refunds_request, {
          params: {
            page,
            perPage,
            ...filter,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function getStatistics(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.userWalletStatistics)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getProgramPaymentDate(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.programPaymentDate, {
          params: payload,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function exportRefund(type, params) {
  let extension = "";
  type == "pdf" ? (extension = "pdf") : (extension = "xlsx");
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.refunds, {
          responseType: "blob",
          params: {
            ...params,
            export: extension,
          },
        })
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "refund" + "." + extension);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function exportRefundRequset(type, params) {
  let extension = "";
  type == "pdf" ? (extension = "pdf") : (extension = "xlsx");
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_refunds_request, {
          responseType: "blob",
          params: {
            ...params,
            export: extension,
          },
        })
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "refund" + "." + extension);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function RefundAction({ id, payload }) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.refund_action, { id: id }), payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function refundSetting() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.refund_setting)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function refundSettingUpdate(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.refund_setting_update, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function updateUserWallet(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.update_user_wallet, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function readUserWallet(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.read_user_wallet, {
          params: payload,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function userWallet() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.user_wallet)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getSponsors() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_sponsors)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getSponsorsData() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_sponsors_data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getTraineeList() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.list_trainees_coupon)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function addSponsor(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.create_sponsor, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function searchTrainee(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.search_trainee, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getTraineesForSponsors(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_trainees_for_sponsor, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function unAssignTrainee(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.un_assign_trainee, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function assignTrainees(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.assign_trainees, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function refundSettingList() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.refund_setting_list)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function deleteRefundSetting(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .delete(URLBuilder(env.delete_refund_setting, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function AddRefundSetting(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.add_refund_setting, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getRefundSetting(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_refund_setting, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function updateRefundSetting(id, payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.update_refund_setting, { id: id }), payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
