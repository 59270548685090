import React, { useRef, useState } from "react";
import AddIcon from "../../../../assets/icons/plus.svg";
import RemoveRedIcon from "../../../../assets/icons/remove-red.svg";
import Input from "../Input/Input";
import classes from "./links.module.css";

const Links = ({
  label,
  name,
  placeholder,
  namePlaceholder,
  linkPlaceholder,
  setFieldValue,
  value,
  error,
  required,
}) => {
  const [links, setLinks] = useState(value?.length ? value : []);

  const handleAdd = () => {
    setLinks([...links, { name: "", link: "" }]);
  };

  const handleRemove = (index) => {
    const newLinks = [...links];
    newLinks.splice(index, 1);
    setLinks(newLinks);
    setFieldValue(name, newLinks);
  };

  const handleChange = (index, filed, event) => {
    const newLinks = [...links];
    newLinks[index][filed] = event.target.value;
    setLinks(newLinks);
    setFieldValue(name, newLinks);
  };

  return (
    <div className={classes["links"]}>
      <label htmlFor={name} className={classes["links-label"]}>
        {label}
      </label>
      <div className={classes["links__container__content"]}>
        <div className={classes["links__container"]}>
          <input type="text" name={name} id={name} placeholder={placeholder} disabled={true} />
          <button className={classes["links__container__field-btn"]} onClick={handleAdd} type="button">
            <img src={AddIcon} alt="" />
          </button>
        </div>
        {links && !!links.length && (
          <div className={classes["links__previews"]}>
            {links?.map((link, index) => (
              <div className={classes["links__previews__item"]} key={index}>
                <Input
                  type="text"
                  value={link?.name}
                  name={name + "name"}
                  placeholder={namePlaceholder}
                  onChange={(event) => handleChange(index, "name", event)}
                  label={namePlaceholder}
                  error={error ? [index]?.name : ""}
                  required={required}
                />
                <Input
                  type="text"
                  name={name + "link"}
                  value={link?.link}
                  placeholder={linkPlaceholder}
                  onChange={(event) => handleChange(index, "link", event)}
                  label={linkPlaceholder}
                  error={error ? [index]?.link : ""}
                  required={required}
                />
                <div
                  className={classes["input-file__container__previews__item-remove"]}
                  onClick={() => handleRemove(index)}
                >
                  <img src={RemoveRedIcon} alt="" />
                </div>
              </div>
            ))}
          </div>
        )}

        {error && (
          <p className={`form-input-error-space ${classes["input-file-error"]}`} style={{ paddingTop: "10px " }}>
            {error}
          </p>
        )}
      </div>
    </div>
  );
};

export default Links;
