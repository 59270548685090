import CheckIcon from '@mui/icons-material/Check';
import React from "react";

function Stepper(props) {
    require("./Stepper.css")
    return (
      <>
          <div className={"tajah-stepper"}>
              {
                  props.items.map((label, index) => {
                      return (
                          <React.Fragment key={index}>
                              {
                                  index > 0 ?
                                      <div className={"tajah-stepper-line-separator "+(index <= props.activePanel ? "active":"")}/> : null
                              }
                              <div className={
                                  "tajah-step-item " +
                                  (index <= props.activePanel ? "active cursor-allowed":"") +
                                  (index == props.activePanel + 1 && props.nextTabAllowed ? " cursor-allowed":"")}>
                                  <div className={"tajah-step-item-indicator "+(index <= props.activePanel ? "active":"")}>
                                      {
                                          index < props.activePanel?
                                              <>
                                                  <CheckIcon fontSize="small"/>
                                              </> : <span>{index}</span>
                                      }
                                  </div>
                                  <span className={"tajah-label-span"}>{label}</span>
                              </div>
                          </React.Fragment>
                      )
                  })
              }
          </div>
      </>
    );
}

export default Stepper;
