import React from "react";

function CourseOutlineList(props) {
  return (
    <div className="course_outline_list">
      <div className="course_outline_list_label">المساعدة</div>
      <ul>
        <li>تحرير المحتوي بالنقر على تحرير المحتوي </li>
        <li>يمكنك إضافة مناقشة حول الدرس</li>
        <li>يمكنك إضافة سؤال جديد او استيراد الأسئلة من بنك الأسئلة </li>
      </ul>
    </div>
  );
}

export default CourseOutlineList;
