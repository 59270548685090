import { Box, CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import classes from "./add.module.css";

const AddAction = ({ submitAction, isSubmitting }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className={`${classes["create_coupon__form__field-actions"]} col-12`}>
      <button
        className={classes["create_coupon__form__field-actions-submit"]}
        type="submit"
        disabled={isSubmitting}
        onClick={submitAction}
      >
        {isSubmitting ? (
          <Box sx={{ display: "flex" }}>
            <CircularProgress color="white" size={30} />
          </Box>
        ) : (
          t("Exhibition.save")
        )}
      </button>
    </div>
  );
};

export default AddAction;
