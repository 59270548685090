import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import {
  editguideLinesAdmin,
  guideLines,
} from "../../../Services/api/toutorials/termsAndConditionsProvider";
import { SunEditorText } from "../../Shared/Components";
import SkeletonCard from "../../Shared/Components/Spinner/SkeletonCard";

function GuideLinesEdit(props) {
  require("./GuideLinesEdit.css");
  const { t } = useTranslation();
  const history = useHistory();

  const [guideLinesAr, setGuidelinesAr] = useState("");
  const [guideLinesEn, setGuidelinesEn] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    guideLines()
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setGuidelinesAr(res.data.data.content_ar);
          setGuidelinesEn(res.data.data.content_en);
          setIsLoading(false);
        }
      })
      .catch((err) => {});
  }, []);

  const editGuidelines = () => {
    setIsLoading(true);
    const dataValue = {
      content_ar: guideLinesAr,
      content_en: guideLinesEn,
    };

    editguideLinesAdmin(dataValue)
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          history.push("/admin");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {typeof err === "string" ? (
              <span
                style={{ fontSize: 12, fontWeight: "500", display: "block" }}
              >
                {err}
              </span>
            ) : err.data ? (
              <>
                <span
                  style={{ fontSize: 13, fontWeight: "bold", display: "block" }}
                >
                  {err.data.msg}
                </span>
                <ul>
                  {Object.keys(err.data.errors).map((key) => {
                    return (
                      <li>
                        <span style={{ fontSize: 11 }}>
                          {err.data.errors[key]}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </>
            ) : (
              t("Failure_in_service")
            )}
          </span>
        );
      });
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="admin_label">
            <NavLink to="/admin">{t("admin.label.admin_label")}</NavLink>
            <p style={{ padding: "0 5px" }}>/</p>
            {t("sidebar.label.Guidelines")}
          </div>
          <div className="admin_card">
            <>
              {isLoading ? (
                <div
                  style={{
                    height: "34.5vh",
                  }}
                >
                  <SkeletonCard />
                </div>
              ) : (
                <Stack
                  sx={{
                    width: "90%",
                    mt: "32px",
                    mb: "12px",
                    "& .se-tooltip": {
                      gap: "10px",
                    },
                  }}
                >
                  <label htmlFor="">{t("GuidelinesAr")}:</label>
                  <SunEditorText
                    value={guideLinesAr}
                    setValue={setGuidelinesAr}
                    dirEditor={"rtl"}
                  />
                </Stack>
              )}

              {isLoading ? (
                <div
                  className="my-5"
                  style={{
                    height: "34.5vh",
                  }}
                >
                  <SkeletonCard />
                </div>
              ) : (
                <Stack
                  sx={{
                    width: "90%",
                    mt: "32px",
                    mb: "12px",
                    "& .se-tooltip": {
                      gap: "10px",
                    },
                  }}
                >
                  <label
                    htmlFor=""
                    style={{
                      marginTop: "20px",
                    }}
                  >
                    {t("GuidelinesEn")}:
                  </label>
                  <SunEditorText
                    value={guideLinesEn}
                    setValue={setGuidelinesEn}
                    dirEditor={"ltr"}
                  />
                </Stack>
              )}
            </>

            {isLoading ? (
              <div
                style={{
                  height: "5vh",
                  width: "200px",
                }}
              >
                <SkeletonCard />
              </div>
            ) : (
              <div className="overview_button_div" style={{ width: "90%" }}>
                <button
                  className={"overview_button"}
                  onClick={(e) => {
                    e.stopPropagation();
                    editGuidelines();
                  }}
                >
                  {t("save")}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GuideLinesEdit;
