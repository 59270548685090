import { Formik } from "formik";
import moment from "moment";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptPeymentRequestStatus,
  rejectPeymentRequest,
} from "../../../../Redux/Actions/peymentRequestAdmin/peymentRequestAdmin.action";
import deleteIcon from "../../../../assets/icons/delete.svg";
import userCheck from "../../../../assets/image/user-check.svg";
import userX from "../../../../assets/image/user-x.svg";
import { utcToLocal } from "../../../../utils/utcToLocal";
import CustomButton from "../../../Shared/Components/CustomButton/CustomButton";
import Input from "../../../Shared/Components/Input/Input";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import { BasicModal } from "../../../Shared/Components/Modal/BasicModal";
import ModelWithInput from "../../../Shared/Components/ModelWithInput/ModelWithInput";
import TableActionsColumn from "../../../Shared/Components/TableActionsColumn/TableActionsColumn";
import Tabs from "../../../Shared/Components/Tabs/Tabs";
import Textarea from "../../../Shared/Components/Textarea/Textarea";
import Title from "../../../Shared/Components/Title/Title";
import classes from "./PaymentRequests.module.css";

const PaymentRequestsCoursesTable = ({
  activeTab,
  setActiveTab,
  paymentDetailsHandler,
  setFilterData,
  paymentRequests,
  peymentRequestsLoading,
  isProgram,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const acceptModalRef = useRef();
  const [showAcceptModel, setShowAcceptModel] = useState(false);
  const [showRejectModel, setShowRejectModel] = useState(false);
  const [peymentRequestId, setPeymentRequestId] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);

  const peymentRequestsTableActionsList = [
    {
      id: "accept",
      icon: <img src={userCheck} alt="" />,
      action: (rowData) => {
        acceptHandler(rowData);
      },
      tooltip: t("general.accept_model_title"),
    },

    {
      id: "reject",
      icon: <img src={userX} alt="" />,
      action: (rowData) => {
        rejectHandler(rowData);
      },
      tooltip: t("general.reject_model_title"),
    },
  ];

  const acceptHandler = (request) => {
    setShowAcceptModel(true);
    acceptModalRef.current.showModal();
    setPeymentRequestId(request.id);
    setTotalAmount(request.dues?.find((due) => due.trainer_id === request.user_id)?.value);
  };

  const closeAcceptModal = () => {
    setShowAcceptModel(false);
    acceptModalRef.current.dismissModal();
    setPeymentRequestId(null);
    setTotalAmount(null);
  };

  const ConfirmAcceptHandler = ({ accept_reason, amount }) => {
    if (!peymentRequestId) return;
    dispatch(acceptPeymentRequestStatus({ id: peymentRequestId, accept_reason, amount }));
    closeAcceptModal();
  };
  const rejectHandler = (request) => {
    setShowRejectModel(true);
    setPeymentRequestId(request.id);
  };

  const ConfirmRejectHandler = (reject_reason) => {
    if (!peymentRequestId) return;
    dispatch(rejectPeymentRequest({ id: peymentRequestId, reject_reason }));
    setShowRejectModel(false);
  };

  const tabs = [
    {
      id: "waiting_requests",
      key: 1,
      label: t("financial.payments_request.waiting_req"),
    },

    {
      id: "requests_approved",
      key: 2,
      label: t("financial.payments_request.approved_req"),
    },
    {
      id: "requests_rejected",
      key: 3,
      label: t("financial.payments_request.rejected_req"),
    },
  ];

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => <>{rowIndex + 1}</>,
    },
    {
      id: "photo",
      name: t("general.photo"),
      key: "photo",
      cell: ({ rowData }) => (
        <div className={classes["departments-trainers__table-photo"]}>
          {rowData?.user?.img ? (
            <img src={rowData?.user?.img} alt="" />
          ) : (
            <i className="fa fa-user" aria-hidden="true"></i>
          )}
        </div>
      ),
      sortable: false,
    },
    {
      id: "id",
      name: t("general.id"),
      key: "id",
      cell: ({ rowData }) => <>{rowData?.id}</>,
      sortable: false,
    },
    {
      id: "username",
      name: t("financial.payments_request.username"),
      key: "username",
      cell: ({ rowData }) => <>{i18n.language === "ar" ? rowData?.user?.name_ar : rowData?.user?.name_en}</>,
    },
    {
      id: "email",
      name: t("general.email"),
      key: "email",
      cell: ({ rowData }) => (
        <a className={classes["departments-trainees__table-email"]} href={`mailto: ${rowData?.user?.email}`}>
          {rowData?.user?.email}
        </a>
      ),
    },
    //////////////////////////////
    {
      id: "user_role",
      name: t("financial.payments_request.user_role"),
      key: "user_role",
      cell: ({ rowData }) => (
        <>
          {rowData.user.roles.map((role) =>
            i18n.language === "ar" ? (
              <>
                {role.label}
                <br />
              </>
            ) : (
              <>
                {role.name}
                <br />
              </>
            )
          )}
        </>
      ),
    },
    /////////////////////////////////////
    {
      id: "notes",
      name: t("financial.payments_request.notes"),
      key: "notes",
      cell: ({ rowData }) => (
        <div style={{ width: 300 }}>
          <p className={classes["note_table"]}>{rowData?.note || "-"}</p>
        </div>
      ),
    },

    {
      id: "course_title",
      name: t("financial.payments_request.course_title"),
      key: "course_title",
      cell: ({ rowData }) => (
        <>
          <span
            style={{
              color: "#046c77",
            }}
          >
            {rowData?.course?.name}
          </span>
        </>
      ),
    },

    {
      id: "course_code",
      name: t("financial.payments_request.course_code"),
      key: "course_code",
      cell: ({ rowData }) => (
        <>
          <span
            style={{
              color: "#046c77",
            }}
          >
            {rowData?.course?.code}
          </span>
        </>
      ),
    },

    {
      id: "course_run_serial_number",
      name: t("financial.payments_request.course_run_serial_number"),
      key: "course_run_serial_number",
      cell: ({ rowData }) => (
        <>
          <span
            style={{
              color: "#046c77",
            }}
          >
            {rowData?.course?.run_serial_number}
          </span>
        </>
      ),
    },
    {
      id: "number_hours",
      name: t("num_hours"),
      key: "number_hours",
      cell: ({ rowData }) => <>{rowData?.course?.number_hours || "-"}</>,
      sortable: false,
    },
    /////////////////
    {
      id: "trainer_total_amount",
      name: t("financial.payments_request.trainer_total_amount"),
      key: "trainer_total_amount",
      cell: ({ rowData }) => (
        <>
          {rowData?.dues.length !== 0
            ? rowData?.dues.map((due) =>
                due.trainer_id == rowData?.user_id ? (due.value ? `${due.value} ${t("rs")}` : "-") : ""
              )
            : "-"}
        </>
      ),
    },
    //////////////////
    ...(activeTab === 2
      ? [
          {
            id: "added_amount_when_accepted",
            name: t("financial.payments_request.added_amount_when_accepted"),
            key: "added_amount_when_accepted",
            cell: ({ rowData }) => <>{`${rowData?.amount} ${t("rs")}`}</>,
          },
        ]
      : []),
    {
      id: "trainer_received_amounts",
      name: t("financial.payments_request.trainer_received_amounts"),
      key: "trainer_received_amounts",
      cell: ({ rowData }) => <>{`${rowData?.totalPaid} ${t("rs")}`}</>,
    },

    ...(activeTab === 3
      ? [
          {
            id: "rejected_reason",
            name: t("financial.payments_request.rejected_reason"),
            key: "rejected_reason",
            cell: ({ rowData }) => (
              <div style={{ width: 300 }}>
                <p className={classes["note_table"]}>{rowData?.reject_reason || "-"}</p>
              </div>
            ),
          },
        ]
      : []),
    ...(activeTab === 2
      ? [
          {
            id: "accept_reason",
            name: t("financial.payments_request.accept_note"),
            key: "accept_reason",
            cell: ({ rowData }) => (
              <div style={{ width: 300 }}>
                <p className={classes["note_table"]}>{rowData?.accept_reason || "-"}</p>
              </div>
            ),
          },
        ]
      : []),
    {
      id: "request_date",
      name: t("financial.payments_request.request_date"),
      key: "request_date",
      cell: ({ rowData }) => (
        <>
          {rowData?.created_at ? (
            <>
              {moment(rowData?.created_at).format("DD/MM/YYYY")}
              <br />
              {utcToLocal(rowData?.created_at)}
            </>
          ) : (
            "-"
          )}
        </>
      ),
    },
    ...(activeTab === 1
      ? [
          {
            id: "actions",
            name: t("general.actions"),
            key: "actions",
            cell: ({ rowData }) => <TableActionsColumn actions={peymentRequestsTableActionsList} id={rowData} />,
          },
        ]
      : []),
    ...(activeTab === 2 || activeTab === 3
      ? [
          {
            id: "action_date",
            name: t("financial.payments_request.action_date"),
            key: "action_date",
            cell: ({ rowData }) => (
              <>
                {rowData?.processed_at ? (
                  <>
                    {moment(rowData?.processed_at).format("DD/MM/YYYY")}
                    <br />
                    {utcToLocal(rowData?.processed_at)}
                  </>
                ) : (
                  "-"
                )}
              </>
            ),
          },
        ]
      : []),
    ...(activeTab === 2 || activeTab === 3
      ? [
          {
            id: "action_by",
            name: t("financial.payments_request.action_by"),
            key: "action_by",
            cell: ({ rowData }) => (
              <>{i18n.language === "ar" ? rowData?.processor?.name_ar : rowData?.processor?.name_en}</>
            ),
          },
        ]
      : []),
  ];

  return (
    <div className={classes["departments-trainees__tables"]}>
      <Tabs
        tabs={tabs}
        disabled={peymentRequestsLoading}
        activeTab={activeTab}
        onSelectTab={(key) => {
          setFilterData({
            username: "",
            email: "",
            user_role: "",
            course_title: "",
            course_code: "",
            course_serial_number: "",
            action_by: "",
            ...(isProgram ? { program_code: "", program_title: "" } : {}),
          });
          setActiveTab(key);
        }}
      />
      <MainTable columns={columns} data={paymentRequests || []} isLoading={peymentRequestsLoading} />
      <BasicModal ref={acceptModalRef}>
        {showAcceptModel && (
          <MainBox className="border-8 w-50 m-50">
            <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
              <Title title={t("general.accept_model_title")} />
              <img src={deleteIcon} alt="close" onClick={closeAcceptModal} className="pointer" />
            </div>
            <Formik
              initialValues={{
                accept_reason: "",
                amount: "",
              }}
              onSubmit={async (values, { setSubmitting }) => {
                await ConfirmAcceptHandler(values);
                setSubmitting(false);
              }}
              validate={(values) => {
                const errors = {};

                if (!values.amount) {
                  errors.amount = t("Exhibition.errors.required");
                } else if (Number(values.amount) > Number(totalAmount || 0)) {
                  errors.amount = t("financial.payments_request.amount_error");
                }

                if (!values.accept_reason) {
                  errors.accept_reason = t("Exhibition.errors.required");
                }

                return errors;
              }}
              validateOnChange={false}
            >
              {({ values, errors, handleChange, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <div className="tw-flex tw-flex-col tw-space-y-4">
                    <Input
                      label={t("financial.payments_request.added_amount_when_accepted")}
                      type="number"
                      name="amount"
                      value={values.amount}
                      onChange={handleChange}
                      error={errors.amount}
                      note={`${t("financial.payments_request.trainer_total_amount")} ${totalAmount || 0} ${t("rs")}`}
                      required
                    />
                    <Textarea
                      label={t("financial.payments_request.accept_note")}
                      name="accept_reason"
                      value={values.accept_reason}
                      onChange={handleChange}
                      error={errors.accept_reason}
                      required
                    />
                    <div className="tw-flex tw-justify-end tw-items-center tw-mt-4">
                      <CustomButton
                        type="submit"
                        colors="#036c77"
                        value={t("Exhibition.save")}
                        disable={false}
                        classes="tw-py-2 tw-px-4 tw-flex tw-items-center tw-justify-center tw-w-56"
                      />
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </MainBox>
        )}
      </BasicModal>
      {showRejectModel && (
        <ModelWithInput
          openModal={showRejectModel}
          setOpenModal={setShowRejectModel}
          modalTitle={t("general.reject_model_title")}
          buttonText={t("general.submit")}
          type={"textarea"}
          handleSubmit={ConfirmRejectHandler}
          inputLabel={t("financial.payments_request.rejected_reason")}
        />
      )}
    </div>
  );
};

export default PaymentRequestsCoursesTable;
