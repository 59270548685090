import React, { useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { Formik } from "formik";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router";
import { NavLink, useLocation } from "react-router-dom";
import { EditTypeFile } from "../../../../Services/api/TypeFile/TypeFileProvider";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import HomeIcon from "../../../../assets/icons/home.svg";

export default function Edit() {
  require("./Edit.css");

  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [dataById, setDataById] = useState([]);

  useEffect(() => {
    if (location.state) {
      setDataById(location.state);
    }
    setIsLoading(false);
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "." || event.key === "-") {
      event.preventDefault();
    }
  };

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "TypeFile_label",
      page: t("admin.label.admin_label"),
      pagePath: "/admin",
    },
    {
      id: "TypeFile",
      page: t("TypeFile.title"),
      active: true,
    },
  ];

  return (
    <div>
      <div className="container-fluid">
        {/* <div className="admin_label">
                    <NavLink to="/admin">{t("admin.label.admin_label")}</NavLink>
                    <p style={{ padding: "0 5px" }}>/</p>
                    <NavLink to="/admin/typefile/list">{t('TypeFile.title')}</NavLink>
                    <p style={{ padding: "0 5px" }}>/</p>
                    {t('TypeFile.edit')}
                </div> */}
        <div className="pt-5 pb-4">
          <Breadcrumb list={breadcrumbList} />
        </div>
      </div>
      <div className="container-fluid">
        <div className={isLoading ? null : "table_bank_tran faq_box_white"}>
          {isLoading ? (
            <div
              style={{
                height: "65vh",
                width: "100%",
              }}
            >
              <SkeletonCard />
            </div>
          ) : (
            <>
              <div className="container-fluid">
                <h3 className="table_header">{t("TypeFile.edit_file_type")}</h3>
                <Formik
                  initialValues={{
                    type: dataById.type,
                    max_size: dataById.max_size,
                  }}
                  onSubmit={async (values, { setSubmitting }) => {
                    try {
                      setIsLoading(true);
                      let dataToBeUploaded = {
                        ...values,
                      };

                      const response = await EditTypeFile(id, dataToBeUploaded);
                      if (response.status === 201 && response.data.status) {
                        toast.success(
                          <span style={{ fontSize: 13, fontWeight: "bold" }}>
                            {response.data.msg}
                          </span>,
                          {
                            onClose: () => history.push(`/admin/typefile/list`),
                            autoClose: 1000,
                          }
                        );
                      } else {
                        toast.error(
                          <span style={{ fontSize: 13, fontWeight: "bold" }}>
                            {response.data.msg
                              ? response.data.msg
                              : "Failure in service"}
                          </span>
                        );
                        setIsLoading(false);
                      }
                    } catch (err) {
                      setIsLoading(false);
                      toast.error(
                        <span style={{ fontSize: 13, fontWeight: "bold" }}>
                          {err.response.data && err.response.data.msg ? (
                            <>
                              {err.response.data.msg}
                              <br />
                              <span style={{ fontSize: 12, fontWeight: "500" }}>
                                - {err.response.data.errors.type}
                              </span>
                            </>
                          ) : (
                            "Failure in service"
                          )}
                        </span>
                      );
                    }
                  }}
                  validateOnChange={hasSubmitted}
                  validate={(values) => {
                    setHasSubmitted(true);
                    const errors = {};
                    if (!values.type) {
                      errors.type = t("crud.errors.required");
                    }
                    if (!values.max_size) {
                      errors.max_size = t("crud.errors.required");
                    }
                    return errors;
                  }}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    handleBlur,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit} className="admin_add_form">
                      <div className="row">
                        <div className="col-md-12">
                          <label htmlFor="title" className="admin_add_label">
                            {t("TypeFile.type")} :
                          </label>
                          <input
                            className="admin_add_input_file"
                            name="type"
                            id="type"
                            defaultValue={dataById.type}
                            onChange={handleChange}
                          />

                          <p className={"form-input-error-space"}>
                            {errors.type ? errors.type : null}
                          </p>
                        </div>
                        <div className="col-md-12">
                          <label htmlFor="title" className="admin_add_label">
                            {t("TypeFile.size")} :
                          </label>
                          <input
                            className="admin_add_input_file"
                            name="max_size"
                            id="max_size"
                            min="0"
                            type="number"
                            defaultValue={dataById.max_size}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                          />

                          <p className={"form-input-error-space"}>
                            {errors.max_size ? errors.max_size : null}
                          </p>
                        </div>

                        <div className="col-md-12">
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="admin_add_button"
                            style={{ backgroundColor: "#006d77" }}
                          >
                            {t("zoom.addition")}
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
