import { default as URLBuilder } from "../../Services/api/UrlBuilder";
import env from "./Environment";
import connector from "./Handler";

export function exportCoursePartnerList(export_type, id, isProgram) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_course_partners, { id: id }), {
          responseType: "blob",
          params: {
            export: export_type,
          },
        })
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              isProgram ? `Program Partners.${export_type}` : `Course Partners.${export_type}`
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
