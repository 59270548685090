import { useTranslation } from "react-i18next";
import CategoryIcon from "../../../../assets/icons/categoryiCON.svg";
import SharedSlider from "../../../Shared/SharedSlider/SharedSlider";
import CategoryCard from "./CategoryCard/CategoryCard";
import "./categories.css";
import classes from "./categories.module.css";
import { useEffect, useState } from "react";
import { getCategoryHome } from "../../../../Services/api/toutorials/categoryProvider";
import SkeletonSpinnerOverlay from "../../../Shared/Components/Spinner/SkeletonSpinnerOverlay";
import i18n from "../../../../i18n/i18n";

const Categories = () => {
  const { t } = useTranslation();
  const [categories, setcategories] = useState([]);
  const [loading, setloading] = useState(false);

  const [width, setWidth] = useState(0);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWidth]);

  useEffect(() => {
    const apiCall = getCategoryHome;
    const status = 1;
    apiCall(status)
      .then((res) => {
        if (res.status && res.status === 200) {
          if (i18n.language === "ar") {
            setcategories(
              res.data.data.specialties.data.slice(0, 12).toReversed()
            );
          } else {
            setcategories(res.data.data.specialties.data.slice(0, 12));
          }
        }
        setloading(true);
      })
      .catch((err) => {
        console.log(err);
        setloading(true);
      });
  }, []);

  return (
    <>
      {loading ? (
        <div className={classes.trainersSectionCont}>
          <div className={classes.categories}>
            <h2 className={classes.sectionTitle}>
              {t("home.all")}&nbsp;
              <span>{t("home.categories")}</span>
            </h2>

            {categories.length <= 3 && width > 1200 ? (
              <div className={classes.staticCont}>
                {categories.map((category, index) => (
                  <CategoryCard
                    key={index}
                    category={category}
                    img={CategoryIcon}
                    loading={loading}
                    index={index}
                    totalCards={categories.length}
                  />
                ))}
              </div>
            ) : categories.length === 2 && width >= 600 ? (
              <>
                <div className={classes.staticCont}>
                  {categories.map((category, index) => (
                    <CategoryCard
                      key={index}
                      category={category}
                      img={CategoryIcon}
                      loading={loading}
                      index={index}
                      totalCards={categories.length}
                    />
                  ))}
                </div>
              </>
            ) : categories.length === 1 ? (
              <>
                <div className={classes.staticCont}>
                  {categories.map((category, index) => (
                    <CategoryCard
                      key={index}
                      category={category}
                      img={CategoryIcon}
                      loading={loading}
                      index={index}
                      totalCards={categories.length}
                    />
                  ))}
                </div>
              </>
            ) : (
              <SharedSlider
                slidesToShow={3}
                slidesToScroll={3}
                dots={true}
                noArrows={true}
                classes={`${classes.slider} categories-slider`}
                responsive={[
                  {
                    breakpoint: 2000,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 3,
                    },
                  },
                  {
                    breakpoint: 1920,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 3,
                    },
                  },
                  {
                    breakpoint: 1600,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 3,
                    },
                  },
                  {
                    breakpoint: 1400,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 3,
                    },
                  },
                  {
                    breakpoint: 1200,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2,
                    },
                  },
                  {
                    breakpoint: 960,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2,
                    },
                  },
                  {
                    breakpoint: 600,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                    },
                  },
                  {
                    breakpoint: 380,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                    },
                  },
                ]}
              >
                {width <= 600
                  ? categories
                      .slice(0, 4)
                      .map((category, index) => (
                        <CategoryCard
                          key={index}
                          category={category}
                          img={CategoryIcon}
                          loading={loading}
                          index={index}
                          totalCards={categories.length}
                        />
                      ))
                  : categories.map((category, index) => (
                      <CategoryCard
                        key={index}
                        category={category}
                        img={CategoryIcon}
                        loading={loading}
                        index={index}
                        totalCards={categories.length}
                      />
                    ))}
              </SharedSlider>
            )}
          </div>
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <div
              style={{
                height: "400px",
                width: "90%",
              }}
            >
              <SkeletonSpinnerOverlay
                backdrop={true}
                skeletonWidth="100"
                skeletonHight="100"
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Categories;
