import parse from "html-react-parser";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { NavLink, useLocation } from "react-router-dom";
import AddRateModal from "../../../../../Modals/rate/AddRateModal";
import { getCourseDetails } from "../../../../../Services/api/courses/courseProvider";
import { TrainingCourseProfileContext } from "../../../../../Services/api/toutorials/TrainingCourseProfileContext";
import { Lang } from "../../../../../utils";
import { BasicModal } from "../../../../Shared/Components/Modal/BasicModal";
import SkeletonCard from "../../../../Shared/Components/Spinner/SkeletonCard";
import TrainingCourseSyllabusContent from "../TrainingCourseSyllabusContent/TrainingCourseSyllabusContent";
import TrainingCourseSyllabusLoadingContent from "../TrainingCourseSyllabusLoadingContent/TrainingCourseSyllabusLoadingContent";
import "./TrainingCourseSyllabusContainer.css";

function TrainingCourseSyllabusContainer(props) {
  require("./TrainingCourseSyllabusContainer.css");
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const addRateModalRef = useRef();
  const location = useLocation();
  const listSurvies = [
    { id: 1, name: "test1" },
    { id: 2, name: "test2" },
    { id: 3, name: "test3" },
    { id: 4, name: "test4" },
  ];

  const trainingCourseProfileContext = useContext(TrainingCourseProfileContext);
  const [showGiftSection, setShowGiftSection] = useState(false);
  const [freeCourse, setFreeCourse] = useState(false);
  const [reservationNumber, setReservationNumber] = useState("");

  useEffect(() => {
    if (trainingCourseProfileContext.courseID) {
      getCourseDetails(trainingCourseProfileContext.courseID).then((res) => {
        if (!res.data.course.subscribed) history.push("/private-profile");
        else {
          if (
            res.data.course.program_id != null &&
            location.state &&
            location.state.user != "trainer"
          ) {
            history.push(
              `/training-courses/${trainingCourseProfileContext.courseID}/profile`
            );
          } else if (
            res.status &&
            res.status === 200 &&
            res.data.status &&
            res.data.course &&
            res.data.course.mode &&
            res.data.course.mode !== "Free"
          ) {
            if (res.data.course && !res.data.course.subscribed) {
              history.push(
                `/${
                  res.data.course.is_program
                    ? "training-programs"
                    : "training-courses"
                }/${trainingCourseProfileContext.courseID}/details`
              );
            }
            setShowGiftSection(true);
            setReservationNumber(res.data.course.reservation);
          }
          if (res.data.course.mode === "Free") setFreeCourse(true);
        }
      });
    }
  }, [trainingCourseProfileContext.courseID]);

  useEffect(() => {
    trainingCourseProfileContext.setSurvies(listSurvies);
  }, []);

  const handleAddRateModal = () => {
    addRateModalRef.current.showModal();
  };

  return (
    <>
      <BasicModal ref={addRateModalRef}>
        <AddRateModal
          label={t("rate.label.addRate")}
          title={t("rate.label.addRate")}
          trainerId={trainingCourseProfileContext.masterTrainer.id}
          courseId={trainingCourseProfileContext.courseID}
          addRateModalRef={addRateModalRef}
          handleAddRateModal={handleAddRateModal}
        />
      </BasicModal>
      <div className="training-course-syllabus-container">
        <div className="training-course-syllabus-section">
          <TrainingCourseSyllabusContent
            courseId={trainingCourseProfileContext.courseID}
          />
          <TrainingCourseSyllabusLoadingContent />
        </div>
        <div className="training-course-about-section">
          {trainingCourseProfileContext.isLoading ? (
            <div
              style={{
                height: "30vh",
              }}
            >
              <SkeletonCard />
            </div>
          ) : (
            <div className="training_info" style={{ width: "unset" }}>
              {showGiftSection ? (
                <button
                  style={{ backgroundColor: "transparent", border: 0 }}
                  disabled={reservationNumber >= 100 ? true : false}
                  className="gift_course"
                  onClick={() =>
                    history.push(
                      `/gift-course/${trainingCourseProfileContext.courseID}`
                    )
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <div>
                      <i
                        className="fas fa-gift"
                        style={{
                          color: "#F1C40F",
                          margin: "0 10px",
                          fontSize: "18px",
                        }}
                      ></i>
                      {t("gift_course")}
                    </div>
                    {reservationNumber >= 100 ? (
                      <div
                        style={{ marginTop: "3%", color: "red", fontSize: 14 }}
                      >
                        {t("completeReservationCourse")}
                      </div>
                    ) : null}
                  </div>
                </button>
              ) : freeCourse ? (
                <></>
              ) : (
                <div
                  style={{
                    height: "50px",
                  }}
                >
                  <SkeletonCard />
                </div>
              )}
              <div className="training_info_label">{t("expert_trainer")}</div>
              <div className="training_info_img_name">
                <img
                  src={
                    trainingCourseProfileContext.masterTrainer &&
                    trainingCourseProfileContext.masterTrainer.img
                  }
                  alt=""
                  className="trainers_dimage"
                />
                <div
                  className=""
                  style={{ marginLeft: "5%", marginRight: "5%" }}
                >
                  <div className="training_info_name">
                    {i18n.language === Lang.AR
                      ? trainingCourseProfileContext.masterTrainer &&
                        trainingCourseProfileContext.masterTrainer.name_ar
                      : trainingCourseProfileContext.masterTrainer &&
                        trainingCourseProfileContext.masterTrainer.name_en}
                  </div>
                  <div className="training_info_major">
                    {i18n.language === Lang.AR
                      ? trainingCourseProfileContext.masterTrainer &&
                        trainingCourseProfileContext.masterTrainer.job_title_ar
                      : trainingCourseProfileContext.masterTrainer &&
                        trainingCourseProfileContext.masterTrainer.job_title_en}
                  </div>
                </div>
              </div>
              <div className="training_info_text">
                {i18n.language === Lang.AR
                  ? trainingCourseProfileContext.masterTrainer &&
                    parse(
                      `${trainingCourseProfileContext.masterTrainer.description_ar}`
                    )
                  : trainingCourseProfileContext.masterTrainer &&
                    parse(
                      `${trainingCourseProfileContext.masterTrainer.description_en}`
                    )}
              </div>
              <NavLink
                to={`/trainers/trainer-details/${trainingCourseProfileContext.masterTrainer.id}`}
                className="training_info_read_more"
              >
                {t("read_more_about_trainer")}
              </NavLink>
              <span
                style={{ backgroundColor: "transparent", cursor: "pointer" }}
                className="training_info_read_more"
                onClick={() =>
                  history.push({
                    pathname: `/training-courses/${trainingCourseProfileContext.courseID}/details`,
                    state: { user: "trainer" },
                  })
                }
              >
                {t("read_more_about_course")}
              </span>
              <button
                onClick={() => handleAddRateModal()}
                style={{
                  backgroundColor: "#006d77",
                  borderRadius: 5,
                  width: "50%",
                  padding: 10,
                  fontSize: 15,
                  color: "white",
                  fontWeight: "bold",
                  borderWidth: 0,
                  marginTop: "5%",
                }}
              >
                <span>
                  {t("rate.label.addRate")}
                  {"     "}+
                </span>
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default TrainingCourseSyllabusContainer;
