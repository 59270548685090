import { useRef, useState } from "react";

import { Formik } from "formik";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { RefundAction } from "../../../../Services/api/Financial/FinancialProvider";
import EyeIcon from "../../../../assets/icons/eye.svg";
import exit from "../../../../assets/icons/remove-red.svg";
import WalletIcon from "../../../../assets/icons/walletSmall.svg";
import userCheck from "../../../../assets/image/user-check.svg";
import userX from "../../../../assets/image/user-x.svg";
import { utcToLocal } from "../../../../utils/utcToLocal";
import CustomButton from "../../../Shared/Components/CustomButton/CustomButton";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import { BasicModal } from "../../../Shared/Components/Modal/BasicModal";
import SharedModal from "../../../Shared/Components/SharedModal/SharedModal";
import TableActionsColumn from "../../../Shared/Components/TableActionsColumn/TableActionsColumn";
import Tabs from "../../../Shared/Components/Tabs/Tabs";
import classes from "./Refund.module.css";

const RefundTable = ({
  refunds,
  isLoading,
  setIsLoading,
  getRefunds,
  resetFilterHandler,
  activeTab,
  setActiveTab,
  setIsUpdating,
  type,
}) => {
  const { t, i18n } = useTranslation();
  const approveRefundModalRef = useRef(null);
  const rejectRefundModalRef = useRef(null);
  const history = useHistory();
  const [showApproveRefundModal, setShowApproveRefundModal] = useState(false);
  const [showRejectRefundModal, setShowRejectRefundModal] = useState(false);
  const [refundActionData, setRefundActionData] = useState(null);

  const tabs = [
    {
      id: "waiting_requests",
      key: 1,
      label: t("financial.payments_request.waiting_req"),
    },

    {
      id: "requests_approved",
      key: 2,
      label: t("financial.payments_request.approved_req"),
    },
    {
      id: "requests_rejected",
      key: 3,
      label: t("financial.payments_request.rejected_req"),
    },
  ];

  const RefundActionsList = [
    // {
    //   id: "view-details",
    //   icon: <img src={EyeIcon} alt="" />,
    //   action: (id) => {},
    //   tooltip: t("general.view_details"),
    // },
    {
      id: "accept",
      icon: <img src={userCheck} alt="" />,
      action: (id) => {
        openApproveRefundModal(id);
      },
      tooltip: t("general.accept_model_title"),
    },
    {
      id: "reject",
      icon: <img src={userX} alt="" />,
      action: (id) => {
        openRejectRefundModal(id);
      },
      tooltip: t("general.reject_model_title"),
    },
    // {
    //   id: "wallet",
    //   icon: <img src={WalletIcon} alt="" />,
    //   action: (id) => {},
    //   tooltip: t("financial.refund.refundWallet"),
    // },
  ];

  const openApproveRefundModal = (id) => {
    setShowApproveRefundModal(true);
    setRefundActionData({ id: id, status: "Approved" });
    approveRefundModalRef.current.showModal();
  };

  const closeApproveRefundModal = () => {
    approveRefundModalRef.current.dismissModal();
    setShowApproveRefundModal(false);
    setRefundActionData(null);
  };

  const openRejectRefundModal = (id) => {
    setShowRejectRefundModal(true);
    setRefundActionData({ id: id, status: "Refused" });
    rejectRefundModalRef.current.showModal();
  };

  const closeRejectRefundModal = () => {
    rejectRefundModalRef.current.dismissModal();
    setShowRejectRefundModal(false);
    setRefundActionData(null);
  };

  const approveRefundHandler = () => {
    setIsUpdating(true);
    RefundAction({
      id: refundActionData.id,
      payload: {
        status: refundActionData.status,
      },
    })
      .then((res) => {
        toast.success(res.data.msg);
        getRefunds();
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.msg}
          </span>
        );
      })
      .finally(() => {
        closeApproveRefundModal();
        setIsUpdating(false);
      });
  };

  const displayRefundableType = {
    course: t("trainer.course.label"),
    program_payment: t("trainer.program.label"),
    booth: t("booth.label"),
    exhibition_visit: t("Exhibition.label"),
    wallet: t("general.wallet"),
  };

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => <>{rowIndex + 1}</>,
      sortable: false,
    },
    {
      id: "user",
      name: t("financial.refund.account_holder_name"),
      key: "user",
      cell: ({ rowData }) => (
        <div className={classes["refund-list__table-name"]}>
          {rowData?.user || "-"}
        </div>
      ),
      sortable: false,
    },
    {
      id: "id",
      name: t("general.id"),
      key: "id",
      cell: ({ rowData }) => <>{rowData?.id}</>,
      sortable: false,
    },
    {
      id: "email",
      name: t("general.email"),
      key: "email",
      cell: ({ rowData }) =>
        rowData?.email ? (
          <a
            className={classes["departments-trainees__table-email"]}
            href={`mailto: ${rowData?.email}`}
          >
            {rowData?.email || "-"}
          </a>
        ) : (
          "-"
        ),
      sortable: false,
    },
    {
      id: "refund_settings_title",
      name: t("financial.refund.title"),
      key: "refund_settings_title",
      cell: ({ rowData }) => <>{rowData?.refund_settings?.title || "-"}</>,
      sortable: false,
    },

    {
      id: "refund_value",
      name: t("financial.refund.amount"),
      key: "rrefund_value",
      cell: ({ rowData }) => (
        <>
          {rowData?.refund_settings
            ? `${Number(rowData?.refund_settings?.amount)} ${
                Number(rowData?.refund_settings?.mechanism) === 1
                  ? t("general.sar")
                  : "%"
              }`
            : "-"}
        </>
      ),
      sortable: false,
    },
    {
      id: "refund_amount",
      name: t("financial.refund.refund_amount"),
      key: "refund_settings_title",
      cell: ({ rowData }) => (
        <>
          {Number(rowData?.refund_settings?.mechanism) === 1
            ? rowData?.refund_settings?.amount
            : rowData?.refund_settings?.include_tax
            ? (rowData?.refund_settings?.amount / 100) *
                (rowData?.refundable.price
                  ? rowData?.refundable.price
                  : rowData?.refundable.entrance_cost) +
              (rowData?.refund_settings?.amount / 100) *
                (rowData?.refundable.price
                  ? rowData?.refundable.price
                  : rowData?.refundable.entrance_cost) *
                0.15
            : (rowData?.refund_settings?.amount / 100) *
              (rowData?.refundable.price
                ? rowData?.refundable.price
                : rowData?.refundable.entrance_cost)}
          {t("general.sar")}
        </>
      ),
      sortable: false,
    },
    {
      id: "refund_mechanism",
      name: t("financial.label.discount_type"),
      key: "refund_mechanism",
      cell: ({ rowData }) => (
        <>
          {rowData?.refund_settings
            ? `${
                Number(rowData?.refund_settings?.mechanism) === 1
                  ? t("amount_tax")
                  : t("financial.label.percentage")
              }`
            : "-"}
        </>
      ),
      sortable: false,
    },
    ...(type === "trainee"
      ? [
          {
            id: "refundable_type",
            name: t("financial.refund.refundable_type"),
            key: "refundable_type",
            cell: ({ rowData }) => (
              <>
                {displayRefundableType[
                  rowData?.refund_settings?.refundable_type
                ] || "-"}
              </>
            ),
            sortable: false,
          },
        ]
      : []),
    {
      id: "refundable_name",
      name: t("financial.refund.refundable_name"),
      key: "refundable_name",
      cell: ({ rowData }) => <>{rowData?.refundable?.name || "-"}</>,
      sortable: false,
    },
    {
      id: "refundable_reason",
      name: t("financial.refund.reason"),
      key: "refundable_reason",
      cell: ({ rowData }) => (
        <>
          <div style={{ minWidth: 300, maxWidth: 400 }}>
            <p className={classes["note_table"]}>{rowData?.reason || "-"}</p>
          </div>
        </>
      ),
      sortable: false,
    },
    ...(activeTab === 3
      ? [
          {
            id: "cancel_reason",
            name: t("financial.refund.cancel_reason"),
            key: "cancel_reason",
            cell: ({ rowData }) => (
              <div
                style={{ minWidth: 300, maxWidth: 400, whiteSpace: "pre-wrap" }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: rowData?.cancel_reason || "-",
                  }}
                ></div>
              </div>
            ),
            sortable: false,
          },
        ]
      : []),
    {
      id: "refundable_date",
      name: t("financial.refund.refundable_date"),
      key: "refundable_date",
      cell: ({ rowData }) => (
        <>
          {moment(rowData?.created_at).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData?.created_at)}
        </>
      ),
      sortable: false,
    },
    // {
    //   id: "payment_method",
    //   name: t("financial.refund.payment_method"),
    //   key: "payment_method",
    //   cell: ({ rowData }) => <>{rowData?.order_id?.payment_method || "-"}</>,
    // },
    // {
    //   id: "bank_name",
    //   name: t("financial.refund.bank_name"),
    //   key: "bank_name",
    //   cell: ({ rowData }) => <>{rowData?.order_id?.bank_name || "-"}</>,
    // },
    // {
    //   id: "iban_number",
    //   name: t("financial.refund.iban_number"),
    //   key: "iban_number",
    //   cell: ({ rowData }) => <>{rowData?.iban_number}</>,
    // },
    // {
    //   id: "revision_date",
    //   name: t("financial.payments_request.request_date"),
    //   key: "payment_date",
    //   cell: ({ rowData }) => (
    //     <>
    //       {rowData?.revision_date ? (
    //         <>
    //           {moment(rowData?.revision_date).format("DD/MM/YYYY")}
    //           <br />
    //           {utcToLocal(rowData?.revision_date)}
    //         </>
    //       ) : (
    //         "-"
    //       )}
    //     </>
    //   ),
    //   sortable: false,
    // },

    // {
    //   id: "order_status",
    //   name: t("financial.refund.order_status"),
    //   key: "order_status",
    //   cell: ({ rowData }) => (
    //     <>
    //       {rowData?.status == "Waiting"
    //         ? t("financial.refund.waiting")
    //         : rowData?.status == "Approved"
    //         ? t("financial.refund.approved")
    //         : rowData?.status == "Refused"
    //         ? t("financial.refund.refused")
    //         : null}
    //     </>
    //   ),
    // },
    // {
    //   id: "image",
    //   name: t("financial.refund.image"),
    //   key: "image",
    //   cell: ({ rowData }) => <>{rowData?.order_id?.payment_proof_img_name}</>,
    // },
    ...(activeTab === 1
      ? [
          {
            id: "actions",
            name: t("general.actions"),
            key: "actions",
            cell: ({ rowData }) => (
              <TableActionsColumn actions={RefundActionsList} id={rowData.id} />
            ),
          },
        ]
      : []),
  ];

  return (
    <div className={classes["departments-trainees__tables"]}>
      <Tabs
        tabs={tabs}
        disabled={isLoading}
        activeTab={activeTab}
        onSelectTab={(key) => {
          resetFilterHandler();
          setActiveTab(key);
        }}
      />
      <MainTable columns={columns} data={refunds || []} isLoading={isLoading} />
      <BasicModal ref={approveRefundModalRef}>
        {showApproveRefundModal && (
          <SharedModal
            title={t("refunds.approve")}
            cancelText={t("general.cancel")}
            confirmMsg={t("refunds.approve_msg")}
            confirmText={t("general.approve")}
            onConfirm={approveRefundHandler}
            onCancel={closeApproveRefundModal}
          />
        )}
      </BasicModal>
      <BasicModal ref={rejectRefundModalRef}>
        {showRejectRefundModal && (
          <MainBox className="p-0 rounded">
            <div className={classes["reject-payment-modal"]}>
              <div className={classes["reject-payment-modal__header"]}>
                <h3>{t("general.reject")}</h3>
                <img src={exit} alt="" onClick={closeRejectRefundModal} />
              </div>
              <div className={classes["reject-payment-modal__body"]}>
                <Formik
                  initialValues={{
                    rejection_reason: "",
                  }}
                  onSubmit={(values) => {
                    setIsUpdating(true);
                    const data = {
                      status: refundActionData.status,
                      cancel_refund_reason: values.rejection_reason,
                    };
                    RefundAction({ id: refundActionData.id, payload: data })
                      .then((res) => {
                        toast.success(res.data.msg);
                        getRefunds();
                      })
                      .catch((err) => {
                        toast.error(t("general.error"));
                      })
                      .finally(() => {
                        closeRejectRefundModal();
                        setIsUpdating(false);
                      });
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.rejection_reason) {
                      errors.rejection_reason = t("crud.errors.required");
                    }
                    return errors;
                  }}
                  validateOnChange={true}
                >
                  {({
                    values,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    errors,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="rejection_reason">
                          {t("financial.payments_request.rejected_reason")}
                        </label>
                        <textarea
                          id="rejection_reason"
                          name="rejection_reason"
                          style={{
                            border: "1px solid rgb(201, 200, 200)",
                            display: "block",
                            width: "500px",
                            borderRadius: "6px",
                            padding: "15px",
                          }}
                          maxLength={3000}
                          value={values.rejection_reason}
                          onChange={handleChange}
                          placeholder={t(
                            "financial.payments_request.rejected_reason"
                          )}
                          defaultValue={values.rejection_reason}
                        />
                        <p className={"form-input-error-space"}>
                          {errors.rejection_reason
                            ? errors.rejection_reason
                            : ""}
                        </p>
                      </div>
                      <div className={classes["reject-payment-modal__submit"]}>
                        <CustomButton
                          value={t("submit")}
                          type="submit"
                          action={handleSubmit}
                          colors="#036c77"
                          disabled={false}
                          classes={classes["reject-payment-modal__submit-btn"]}
                        />
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </MainBox>
        )}
      </BasicModal>
    </div>
  );
};

export default RefundTable;
