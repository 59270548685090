import {useTranslation} from "react-i18next";
import Select from "react-select";
import React, {useContext, useEffect, useState} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {LearningAnalysisProviderContext} from "../../../../Providers/LearningAnalysisProvider";
import {getLearningAnalysisRegistered} from "../../../../Services/api/LearningAnalysis/LearningAnalysisService";
import {toast} from "react-toastify";
import {useHistory} from "react-router";

function LearningAnalysisRegistered(props) {
    require("./Registered.css");
    const { t } = useTranslation();
    const learningAnalysisContext = useContext(LearningAnalysisProviderContext);

    const [registeredBodyHeight, setRegisteredBodyHeight] = useState(0);
    const [countriesCount, setCountriesCount] = useState(0);
    const [countries, setCountries] = useState([])
    const [courseId, setCourseId] = useState("")
    const [isLoadingContent, setIsLoadingContent] = useState(true);
    const [itemColors, setItemColors] = useState([
        '#006D77',
        '#FF4141',
        '#FFC162',
        '#5E78E2',
        '#FFA177',
    ]);

    const history = useHistory();

    const fetchRegistered = async (id = null) => {
        try {
            let registered = await getLearningAnalysisRegistered(id);
            let totalRegisteredCount = 0;
            registered.data.response.countries.forEach(item => {
                totalRegisteredCount += parseInt(item.users_count)
            })
            setCountriesCount(totalRegisteredCount);
            setCountries(registered.data.response.countries.slice(0,5))
            setIsLoadingContent(false);
        } catch (err) {
            toast.error(
                <span style={{fontSize: 13, fontWeight: "bold"}}>
                    {t("learning_analysis.failure.loading_registered")}
                </span>
            );
        }
    }

    useEffect(() => {
        setRegisteredBodyHeight(document.getElementById('registered_panel').offsetHeight -
            document.getElementById('registered_panel_header').offsetHeight);
        fetchRegistered();
    }, []);

    return (
        <>
            <div className={"registered_panel_wrapper"}>
                <div className={"registered_panel"} id={"registered_panel"}>
                    <div className={"registered_panel_header"} id={"registered_panel_header"}>
                        <div className={"registered_panel_header_label"}>
                            <p style={{fontSize: 14, marginBottom: 0}}>{t("learning_analysis.registered.number_of_registered_based_on_country")}</p>
                            <p
                                className={"registered_panel_header_label_report"}
                                onClick={() => {
                                    history.push("/admin/learning-analysis/registered/"+courseId);
                                }}
                            >{t("learning_analysis.full_report")}</p>
                        </div>
                        <div className={"registered_panel_header_action"}>
                            <Select
                                isDisabled={isLoadingContent || learningAnalysisContext.isLoading}
                                name="courses"
                                id="courses"
                                isClearable={true}
                                options={learningAnalysisContext.courses}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                placeholder={t("learning_analysis.registered.all_courses")}
                                onChange={(item) => {
                                    setIsLoadingContent(true)
                                    if (item) {
                                        setCourseId(item.id)
                                        fetchRegistered(item.id);
                                    } else {
                                        setCourseId("")
                                        fetchRegistered();
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className={"registered_panel_body"} style={isLoadingContent ? {
                        justifyContent: "center",
                        alignItems: "center",
                        height: registeredBodyHeight
                    }: {}}>
                        {
                            isLoadingContent?
                            <CircularProgress />
                            :
                            <>
                                <div className={"registered_panel_body_list"}>
                                    {
                                        countries.map((item, index) => {
                                            return (
                                                <div className={"registered_panel_body_list_item"} id={item.id}>
                                                    <div className={"registered_panel_body_list_item_data"}>
                                                        <div className={"registered_panel_body_list_item_data_label"}>
                                                            <span style={{fontSize: 14}}>{item.name}</span>
                                                        </div>
                                                        <div className={"registered_panel_body_list_item_data_count"}>
                                                            <span style={{fontSize: 12}}>{item.users_count}</span>
                                                        </div>
                                                    </div>
                                                    <div className={"registered_panel_body_list_item_progress"}>
                                                        <div className={"registered_panel_body_list_item_progress_bar"}></div>
                                                        <div className={"registered_panel_body_list_item_progress_bar_filling"} style={{
                                                            width: ((item.users_count / countriesCount * 100) + '%'),
                                                            backgroundColor: itemColors[index]
                                                        }}></div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                    <div className={"registered_panel_body_list_summary"} style={{marginTop: 25 + ((5 - countries.length) * 50)}}>
                                        <p style={{fontSize: 16}}>{t('learning_analysis.registered.number_of_registered')}</p>
                                        <p className={"registered_panel_body_list_summary_count"} style={{color: '#18B1B9', fontSize:18}}>{countriesCount}</p>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default LearningAnalysisRegistered;
