import React from "react";
import {useTranslation} from "react-i18next";

function DropDownSelectedAnswers(props) {
    const {t} = useTranslation();

    const checkSelectionCorrectness = (answer) => {
        let isSelected = false;
        props.selectedAnswers.forEach(item => {
            if (item == answer) {
                isSelected = true;
            }
        })

        return isSelected;
    }

    return(
        <>
            <table className="table table-bordered">
                <thead>
                <tr>
                    <th scope="col">{t("trainer.quiz.crud.question_selected_option")}</th>
                    <th scope="col">{t("trainer.quiz.crud.right_answer")}</th>
                    <th scope="col">{t("trainer.quiz.crud.selected_answer")}</th>
                </tr>
                </thead>
                <tbody>
                    {
                        props.answers.map((answer, index) => {
                            return (
                                <tr key={index}>
                                    <td>{answer.name}</td>
                                    <td>{answer.is_correct ? t("yes") : t("no")}</td>
                                    <td>
                                        {checkSelectionCorrectness(answer.name) ? t("yes") : t("no")}
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </>
    )
}

export default DropDownSelectedAnswers;
