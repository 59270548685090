import i18n from "../../../i18n/i18n";
import {useTranslation} from "react-i18next";
import logo from "../../../assets/image/logo.png";
import icon from "../../../assets/image/Icon.png";

function CompletedCourses() {
  document.body.dir = i18n.dir();
  const { t } = useTranslation();

  return (
    <>
      <div className="head_box">
        <div className="head_lable">{t("completecourse")}</div>

        <div className="head_view">
          <div className="">{t("ViewAll")}</div>
          <div className="arrow_seeall">
            <img src={icon} alt="" className="arrow_icon" />
          </div>
        </div>
      </div>

      <div className="courses_card_grid">
        <div className="courses_card_courses">
          <div className="courses_card_first_courses">
            <div className="courses_name_course">
              <div className="create_courses_name">
                <div className="create_couses_edit">
                  <img src={logo} alt="" className="logo_hover_width" />
                </div>
                <div className="">{t("basicCourse")}</div>
              </div>
              <div className="create_couses_edit">
                <div className="">{t("department")}</div>
                <div className="">{t("modification")}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="courses_card_courses">
          <div className="courses_card_first_courses">
            <div className="courses_name_course">
              <div className="create_courses_name">
                <div className="create_couses_edit">
                  <img src={logo} alt="" className="logo_hover_width" />
                </div>
                <div className="">{t("basicCourse")}</div>
              </div>
              <div className="create_couses_edit">
                <div className="">{t("department")}</div>
                <div className="">{t("modification")}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="courses_card_courses">
          <div className="courses_card_first_courses">
            <div className="courses_name_course">
              <div className="create_courses_name">
                <div className="create_couses_edit">
                  <img src={logo} alt="" className="logo_hover_width" />
                </div>
                <div className="">{t("basicCourse")}</div>
              </div>
              <div className="create_couses_edit">
                <div className="">{t("department")}</div>
                <div className="">{t("modification")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompletedCourses;
