import axios from "axios";
import { toast } from "react-toastify";
import i18n from "../../i18n/i18n";

const connector = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL, // baseURL: "http://localhost:3001/api",
  headers: {
    "Access-Control-Allow-Origin": "*",
    // "Content-Type": "application/json",
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Methods": "DELETE, POST, GET, PUT, OPTIONS",
    "Accept-Language": i18n.language === "en" ? "en" : "ar",
    // "X-Timezone": get the time zone from the user
    "X-Timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
});

connector.interceptors.request.use(
  (config) => {
    const urlSearchParams = new URLSearchParams(
      window.location.search
        .replaceAll("%3D", "=")
        .replaceAll("%26", "&")
        .replaceAll(("%7C", "|"))
    );
    const token =
      localStorage.getItem("token") ??
      localStorage.getItem("guest_token") ??
      urlSearchParams.get("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      delete connector.defaults.headers.common.Authorization;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

connector.interceptors.response.use(
  (response) => {
    if (response.status && response.status === 205) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {i18n.language === "en"
            ? "Sorry, you are not authorized to access this page"
            : "عفوا ليس لديك صلاحية لعرض هذة الصفحة"}
        </span>
      );
      setTimeout(() => {
        // window.location.href = process.env.REACT_APP_FRONT_BASE_URL;
        window.location.href = "/";
      }, 500);
    }

    if (
      window.location.pathname === "/send-requests" &&
      response.status === 450
    ) {
      if (
        response.request.responseURL ===
          process.env.REACT_APP_BASE_URL + "/messages" ||
        response.request.responseURL ===
          process.env.REACT_APP_BASE_URL + "/settings" ||
        response.request.responseURL ===
          process.env.REACT_APP_BASE_URL + "/typefile/list" ||
        response.request.responseURL ===
          process.env.REACT_APP_BASE_URL + "/about-us"
      ) {
        // window.location.href = process.env.REACT_APP_FRONT_BASE_URL;
        window.location.href = "/";
      }
    }
    return response;
  },
  (err, e) => {
    // console.log(err);

    if (err.response && err.response.status && err.response.status === 413) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {i18n.language === "en"
            ? "The file size is too large"
            : "حجم الملف كبير جدا"}
        </span>
      );
    }

    if (err.response && err.response.status && err.response.status === 401) {
      logout();
      setTimeout(() => {
        // window.location.href = process.env.REACT_APP_FRONT_BASE_URL;
        window.location.href = "/login";
      }, 500);
    }
    if (err.response && err.response.status === 450) {
      const data = err.response.data;
      const path =
        data.email || data.username
          ? `/send-requests?ip=${data.ip}&email=${encodeURIComponent(
              data.email
            )}&username=${data.username}&id=${data.block.id}&name=${
              data.name
            }&allow_unblock=${data.block.allow_unblock}&reason_block=${
              data.block.reason_block
            }&method_block=${data.block.method}&lesson=${
              data.block.lesson_name
            }&course=${data.block.course_name}&lesson_id=${
              data.block.lesson
            }&course_id=${data.block.course}&isRequest=${data.isRequest}`
          : "/login";

      if (
        window.location.pathname !== "/send-requests" &&
        window.location.pathname !== "/login"
      ) {
        if (err.response.data.block.method === "site") {
          // window.location.href = process.env.REACT_APP_FRONT_BASE_URL + path;
          window.location.href = path;
        } else {
          if (
            !(
              err.response.request.responseURL ===
                process.env.REACT_APP_BASE_URL + "/messages" ||
              err.response.request.responseURL ===
                process.env.REACT_APP_BASE_URL + "/settings" ||
              err.response.request.responseURL ===
                process.env.REACT_APP_BASE_URL + "/typefile/list" ||
              err.response.request.responseURL ===
                process.env.REACT_APP_BASE_URL + "/about-us"
            )
          ) {
            // window.location.href = process.env.REACT_APP_FRONT_BASE_URL + path;
            window.location.href = path;
          }
        }
      } else if (window.location.pathname === "/send-requests") {
        const isRequest = new URLSearchParams(window.location.search).get(
          "isRequest"
        );
        if (Number(isRequest) !== Number(data.isRequest)) {
          window.location.href = path;
        }
      }
    }
    return Promise.reject(err);
  }
);

const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("tokenChat");
  localStorage.removeItem("name");
  localStorage.removeItem("email");
  localStorage.removeItem("is_admin");
  localStorage.removeItem("roles");
  localStorage.removeItem("name_ar");
  localStorage.removeItem("name_en");
  localStorage.removeItem("img");
  localStorage.removeItem("phone");
  localStorage.removeItem("type_login");
  localStorage.removeItem("chatId");
  localStorage.removeItem("userId");
  localStorage.removeItem("chat_username");
  localStorage.removeItem("chat_email");
  localStorage.removeItem("type");
  localStorage.removeItem("completed_profile");
};

/**
 *  Check the size of the file type
 *  This feature is currently off
 */
// const bytesToSize = (bytes) => {
//     let bytesVal = parseFloat((bytes / (1024 ** 2) ).toFixed(1))
//     return bytesVal // return MB for size.
//   }
// connector.interceptors.request.use((config) => {
//     let formData = config.data;

//     if(formData && config.url !== '/tickets'){
//         if(config.data.toString() === '[object FormData]'){
//             for (let [key, val] of formData.entries()) {

//                 let type = val.type;
//                 let SizeOfFileUploaded = val.size;
//                 let systemFilesTypes = JSON.parse(localStorage.getItem("system_files_types"));

//                 if(type){
//                     let extentions = val.type.split('/')[1].toLocaleLowerCase();
//                     let statusUploaded;

//                     for(let i=0; i < systemFilesTypes.length; i++){
//                         if(systemFilesTypes[i].type === extentions){
//                             if(systemFilesTypes[i].max_size >= bytesToSize(SizeOfFileUploaded)){
//                                 statusUploaded = true;
//                                 break;
//                             }else{
//                                 statusUploaded = false;
//                                 break;
//                             }
//                         }
//                     }
//                     if(statusUploaded){
//                         return config;
//                     }else{
//                         return Promise.reject(`${extentions} ${i18n.language === "en" ? " file larger than normal size" : " الملف أكبر من الحجم العادي "}`);
//                     }
//                 }
//             }
//             return config; // return when not upload file.
//         }else{
//             return config;
//         }
//     }else{
//         return config;
//     }
//   })
export default connector;
