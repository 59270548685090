import env from "../Environment";
import connector from "../Handler";
import URLBuilder from "../UrlBuilder";

export function getSponsors() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_sponsors)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getSponsorsData() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_sponsors_data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getMySponsors() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_my_sponsors)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getTraineesForSponsors(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_trainees_for_sponsor, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function searchTrainee(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.search_trainee, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function addSponsor(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.create_sponsor, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function unAssignTrainee(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.un_assign_trainee, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function assignTrainees(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.assign_trainees, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getInvoices() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.invoice)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}