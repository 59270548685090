import { Formik } from "formik";
import { useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { NavLink, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  DOCUMENT_PDF,
  acceptAudios,
  acceptDocuments,
  acceptImages,
  validateAudio,
  validateDocument,
  validateImage,
} from "../../Services/Validator";
import {
  Updatebooth,
  getBooth,
  getExhibitionPositions,
} from "../../Services/api/Exhibition/ExhibitionProvider";
import image from "../../assets/image/GroupImg.png";
import SkeletonCard from "../Shared/Components/Spinner/SkeletonCard";

export default function EditBooth() {
  require("./exhibition.css");
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const [filesDoc, setFilesDoc] = useState([0]);
  const [Tabs, setTabs] = useState(1);
  const [position, setposition] = useState(null);
  const [ExhibitionData, setExhibitionData] = useState(null);
  const [ExhibitionPositions, setExhibitionPositions] = useState([]);
  const [Banner, setBanner] = useState([0, 1, 2]);
  const [BoothData, setBoothData] = useState(null);

  useEffect(() => {
    _getBooth(id);
    if (location.state) {
      setExhibitionData(location.state.data);
      _ExhibitionPositions(location.state.data.id);
    }
  }, []);

  const _position = (id) => {
    setposition(id);
  };
  const _ExhibitionPositions = async (id) => {
    let res = await getExhibitionPositions(id);
    if (res.status == 200) {
      setExhibitionPositions(res.data.data.positions);
    }
  };
  const _getBooth = async (id) => {
    const res = await getBooth(id);
    if (res.status == 200) {
      setIsLoading(false);
      setBoothData(res.data.booth);

      let Banners_count = res.data.booth.banners.length;
      let Products_count = res.data.booth.products.length;
      let ProductArr = [...Array(Products_count).keys()];

      if (Banners_count == 3) {
        setBanner([0, 1, 2]);
      } else if (Banners_count == 4) {
        setBanner([0, 1, 2, 3]);
      } else if (Banners_count == 6) {
        setBanner([0, 1, 2, 3, 4, 5]);
      }
      //set Product Data
      setFilesDoc(ProductArr);

      // Set Position
      setposition(res.data.booth.position);
    }
  };

  const getProducts = (documents) => {
    var files = {};
    documents.forEach((i, k) => {
      files = {
        ...files,
        [`id_${k}`]: i.id,
        [`name_${k}`]: i.name,
        [`image_${k}`]: {
          name: i.name,
          src: i.image,
        },
        [`description_${k}`]: i.description,
        [`url_title_${k}`]: i.url_title,
        [`url_${k}`]: i.url,
        [`video_${k}`]: i.video,
      };
    });
    return files;
  };
  const getBanners = (documents) => {
    var files = {};
    documents.forEach((i, k) => {
      files = {
        ...files,
        [`banners_id_${k}`]: i.id,
        [`banners_position_${k}`]: i.position,
        [`banners_image_${k}`]: {
          src: i.image,
        },
      };
    });
    return files;
  };
  return (
    <div style={{ background: "#fff" }}>
      <div
        className="container-fluid profile_wrapper"
        style={{ paddingTop: "60px" }}
      >
        <div className="admin_label" style={{ margin: 0 }}>
          <p style={{ padding: "0 5px", margin: 0 }}>/</p>
          <NavLink to="/">{t("Home")}</NavLink>
          <p style={{ padding: "0 5px", margin: 0 }}>/</p>
          {t("booth.edit")}
        </div>
      </div>
      <div className="container">
        <div className={`row tabs  active--${Tabs}`}>
          <div className={`col-md-3 tab `}>
            <span className="content-span">
              1
              <br />
              {t("booth.Basic_information")}
            </span>
          </div>
          <div className={`col-md-3 tab `}>
            <span className="content-span">
              2
              <br />
              {t("booth.Booth_design")}
            </span>
          </div>
          <div className={`col-md-3 tab `}>
            <span className="content-span">
              3
              <br />
              {t("booth.Add_products")}
            </span>
          </div>
          <div className={`col-md-3 tab `}>
            <span className="content-span">
              4
              <br />
              {t("booth.Record_construction")}
            </span>
          </div>
        </div>
      </div>
      <div className="container-fluid profile_wrapper">
        <div className={isLoading ? null : "table_bank_tran"}>
          {isLoading ? (
            <div
              style={{
                height: "65vh",
                width: "100%",
              }}
            >
              <SkeletonCard />
            </div>
          ) : BoothData ? (
            <div className="admin_input_sponsor">
              <Formik
                initialValues={{
                  name: BoothData.name,
                  description: BoothData.description,
                  audio: {
                    src: BoothData.audio,
                  },
                  logo: {
                    src: BoothData.logo,
                  },
                  document: {
                    src: BoothData.document,
                  },
                  participation_type: BoothData.participation_type,
                  position: BoothData.position,
                  size: BoothData.size,
                  ...getProducts(BoothData.products),
                  ...getBanners(BoothData.banners),
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  try {
                    let dataToBeUploaded = {
                      ...values,
                      exhibition_id: ExhibitionData.id,
                      size: BoothData.size,
                    };
                    setIsLoading(true);

                    if (
                      dataToBeUploaded.audio == "" ||
                      dataToBeUploaded.audio?.src
                    ) {
                      delete dataToBeUploaded.audio;
                    }
                    if (
                      dataToBeUploaded.logo == "" ||
                      dataToBeUploaded.logo?.src
                    ) {
                      delete dataToBeUploaded.logo;
                    }
                    if (
                      dataToBeUploaded.document == "" ||
                      dataToBeUploaded.document?.src
                    ) {
                      delete dataToBeUploaded.document;
                    }

                    let formData = new FormData();
                    Object.keys(dataToBeUploaded).forEach((field) => {
                      if (field.slice(0, 8) == "file_doc") {
                        // nothing
                      } else {
                        formData.append(field, dataToBeUploaded[field]);
                      }
                    });

                    filesDoc.map((doc, key) => {
                      formData.append(
                        `products[${key}][name]`,
                        dataToBeUploaded[`name_${doc}`]
                      );
                      if (
                        dataToBeUploaded[`image_${doc}`] &&
                        dataToBeUploaded[`image_${doc}`] != "" &&
                        !dataToBeUploaded[`image_${doc}`].src
                      ) {
                        formData.append(
                          `products[${key}][image]`,
                          dataToBeUploaded[`image_${doc}`]
                        );
                      }
                      formData.append(
                        `products[${key}][url_title]`,
                        dataToBeUploaded[`url_title_${doc}`]
                      );
                      formData.append(
                        `products[${key}][url]`,
                        dataToBeUploaded[`url_${doc}`]
                      );
                      formData.append(
                        `products[${key}][video]`,
                        dataToBeUploaded[`video_${doc}`]
                      );
                      formData.append(
                        `products[${key}][description]`,
                        dataToBeUploaded[`description_${doc}`]
                      );
                      if (dataToBeUploaded[`id_${key}`]) {
                        formData.append(
                          `products[${key}][id]`,
                          dataToBeUploaded[`id_${doc}`]
                        );
                      }
                    });
                    filesDoc.map((doc, key) => {
                      formData.delete(`name_${doc}`);
                      formData.delete(`image_${doc}`);
                      formData.delete(`url_title_${doc}`);
                      formData.delete(`url_${doc}`);
                      formData.delete(`video_${doc}`);
                      formData.delete(`description_${doc}`);
                      formData.delete(`id_${doc}`);
                    });

                    Banner.map((doc, key) => {
                      formData.append(
                        `banners[${key}][position]`,
                        dataToBeUploaded[`banners_position_${key}`]
                      );
                      if (
                        dataToBeUploaded[`banners_image_${key}`] &&
                        dataToBeUploaded[`banners_image_${key}`] != "" &&
                        !dataToBeUploaded[`banners_image_${key}`].src
                      ) {
                        formData.append(
                          `banners[${key}][image]`,
                          dataToBeUploaded[`banners_image_${key}`]
                        );
                      }
                      if (
                        dataToBeUploaded[`banners_id_${key}`] &&
                        dataToBeUploaded[`banners_id_${key}`] != ""
                      ) {
                        formData.append(
                          `banners[${key}][id]`,
                          dataToBeUploaded[`banners_id_${key}`]
                        );
                      }
                      formData.delete(`banners_image_${key}`);
                      formData.delete(`banners_position_${key}`);
                      formData.delete(`banners_id_${key}`);
                    });
                    Banner.map((doc, key) => {
                      formData.delete(`banners_position_${key}`);
                    });

                    for (var pair of formData.entries()) {
                    }

                    // return false
                    const response = await Updatebooth(id, formData);

                    if (response.status === 200 && response.data.status) {
                      setIsLoading(false);
                      toast.success(
                        <span style={{ fontSize: 13, fontWeight: "bold" }}>
                          {response.data.msg ? response.data.msg : "success"}
                        </span>,
                        {
                          onClose: () => history.push(`/Exhibition`),
                          autoClose: 1000,
                        }
                      );
                    } else {
                      toast.error(
                        <span style={{ fontSize: 13, fontWeight: "bold" }}>
                          {response.data.msg
                            ? response.data.msg
                            : "Failure in service"}
                        </span>
                      );
                      setIsLoading(false);
                    }
                  } catch (err) {
                    setIsLoading(false);
                    
                    if (err.response?.data)
                      toast.error(
                        <>
                          <span
                            style={{
                              fontSize: 13,
                              fontWeight: "bold",
                              display: "block",
                              paddingBottom: "10px",
                            }}
                          >
                            {err.response.data.msg}
                          </span>
                          <ul>
                            {Object.keys(err.response.data.errors).map(
                              (key) => {
                                return (
                                  <li>
                                    <span style={{ fontSize: 11 }}>
                                      {err.response.data.errors[key]}
                                    </span>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </>
                      );
                  }
                }}
                validateOnChange={true}
                validate={(values) => {
                  const errors = {};
                  for (let i = 0; i < Banner.length; i++) {
                    values[`banners_position_${i}`] = BoothData.banners[i]
                      ? BoothData.banners[i].position
                      : i + 1;
                    if (BoothData.banners[i]) {
                      values[`banners_id_${i}`] = BoothData.banners[i].id;
                    }
                  }

                  if (position) {
                    values.position = position;
                  }

                  if (!values.name) {
                    errors.name = t("crud.errors.required");
                    setTabs(1);
                  }

                  if (!values.description) {
                    errors.description = t("crud.errors.required");
                    setTabs(1);
                  }

                  if (!values.logo) {
                    errors.logo = t("crud.errors.required");
                    setTabs(1);
                  }

                  if (
                    values.logo &&
                    !values.logo.src &&
                    !validateImage(values.logo)
                  ) {
                    errors.logo = t("booth.Note_image");
                    setTabs(1);
                  }

                  if (
                    values.document &&
                    !values.document.src &&
                    !validateDocument(values.document)
                  ) {
                    errors.document = t(
                      "validation.document_type_or_size_incorrect"
                    );
                    setTabs(1);
                  }

                  if (!values.audio) {
                    errors.audio = t("crud.errors.required");
                    setTabs(1);
                  }

                  if (
                    values.audio &&
                    !values.audio.src &&
                    !validateAudio(values.audio)
                  ) {
                    errors.audio = t("Exhibition.validation_MP3");
                    setTabs(1);
                  }

                  if (!values.participation_type) {
                    errors.participation_type = t("crud.errors.required");
                    setTabs(1);
                  }

                  if (Tabs > 1) {
                    for (let i = 0; i < Banner.length; i++) {
                      if (!values[`banners_position_${i}`]) {
                        errors[`banners_position_${i}`] = t(
                          "crud.errors.required"
                        );
                        if (Tabs != 2) {
                          setTabs(2);
                        }
                      }
                      if (
                        values[`banners_image_${i}`] &&
                        !values[`banners_image_${i}`].src &&
                        !validateImage(values[`banners_image_${i}`])
                      ) {
                        errors[`banners_image_${i}`] = t("booth.Note_image");
                        if (Tabs != 2) {
                          setTabs(2);
                        }
                      }
                    }

                    if (!values.position) {
                      errors.position = t("crud.errors.required");
                      if (Tabs != 1) {
                        setTabs(2);
                      }
                    }
                  }

                  if (Tabs > 2) {
                    for (let i = 0; i < filesDoc.length; i++) {
                      if (!values[`name_${filesDoc[i]}`]) {
                        errors[`name_${filesDoc[i]}`] = t(
                          "crud.errors.required"
                        );
                        if (Tabs != 1 && Tabs != 2) {
                          setTabs(3);
                        }
                      }
                      if (!values[`image_${filesDoc[i]}`]) {
                        errors[`image_${filesDoc[i]}`] = t(
                          "crud.errors.required"
                        );
                        if (Tabs != 1 && Tabs != 2) {
                          setTabs(3);
                        }
                      }
                      if (!values[`url_title_${filesDoc[i]}`]) {
                        errors[`url_title_${filesDoc[i]}`] = t(
                          "crud.errors.required"
                        );
                        if (Tabs != 1 && Tabs != 2) {
                          setTabs(3);
                        }
                      }
                      if (!values[`url_${filesDoc[i]}`]) {
                        errors[`url_${filesDoc[i]}`] = t(
                          "crud.errors.required"
                        );
                        if (Tabs != 1 && Tabs != 2) {
                          setTabs(3);
                        }
                      }
                      if (!values[`description_${filesDoc[i]}`]) {
                        errors[`description_${filesDoc[i]}`] = t(
                          "crud.errors.required"
                        );
                        if (Tabs != 1 && Tabs != 2) {
                          setTabs(3);
                        }
                      }
                      if (
                        values[`image_${filesDoc[i]}`] &&
                        !values[`image_${filesDoc[i]}`].src &&
                        !validateImage(values[`image_${filesDoc[i]}`])
                      ) {
                        errors[`image_${filesDoc[i]}`] = t("booth.Note_image");
                        if (Tabs != 1 && Tabs != 2) {
                          setTabs(3);
                        }
                      }
                    }
                  }
                  return errors;
                }}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  handleBlur,
                  setFieldValue,
                  validateForm,
                }) => (
                  <form onSubmit={handleSubmit} className="admin_add_form">
                    <div className="row">
                      <div
                        className={`col-md-12 p-0 row ${
                          Tabs != 1 ? " hide" : " "
                        }`}
                      >
                        <div
                          className="col-md-12"
                          style={{ paddingBottom: "20px" }}
                        >
                          <h3 className="table_header">{t("booth.edit")}</h3>
                          <p>{t("booth.booth_Parag")}</p>
                          <img src={image} alt="loading .." />
                        </div>
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-4">
                              <label htmlFor="name" className="admin_add_label">
                                {t("booth.name")}
                              </label>
                              <input
                                id="name"
                                type="text"
                                name="name"
                                className="admin_add_input"
                                defaultValue={BoothData.name}
                                onChange={handleChange}
                              />
                              <p className={"form-input-error-space"}>
                                {errors.name ? errors.name : null}
                              </p>
                            </div>
                            <div className="col-md-8">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  flexWrap: "wrap",
                                }}
                              >
                                <label
                                  htmlFor="logo"
                                  className="admin_add_label"
                                >
                                  {t("booth.Upload_Image")}
                                </label>

                                {values.logo?.src ? (
                                  <a
                                    href={values.logo.src}
                                    target={"_blank"}
                                    style={{ margin: "0px 8px 8px 8px" }}
                                  >
                                    {" "}
                                    {t("image_preview")}{" "}
                                  </a>
                                ) : null}
                              </div>
                              <div style={{ position: "relative" }}>
                                <div>
                                  <div className="add-audio">
                                    {values.logo ? (
                                      values.logo.src ? (
                                        <img
                                          src={values.logo.src}
                                          style={{
                                            height: "80px",
                                          }}
                                        />
                                      ) : (
                                        values.logo.name
                                      )
                                    ) : (
                                      t("booth.Pull_file")
                                    )}

                                    <i className="fa fa-image"></i>
                                  </div>
                                  <p
                                    style={{ display: "inline-block" }}
                                    className="msg-audio"
                                  >
                                    {t("booth.drag_Image")}
                                    <br />
                                    {t("booth.Note_image")}
                                  </p>
                                </div>

                                <input
                                  id="logo"
                                  name="logo"
                                  type="file"
                                  accept={acceptImages()}
                                  className="input-upload-audio"
                                  onChange={(event) => {
                                    setFieldValue(
                                      "logo",
                                      event.target.files[0]
                                    );
                                  }}
                                />
                              </div>
                              <p
                                className={"form-input-error-space"}
                                style={{ paddingTop: "10px " }}
                              >
                                {errors.logo ? errors.logo : null}
                              </p>
                            </div>
                            <div className="col-md-12">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  flexWrap: "wrap",
                                }}
                              >
                                <label
                                  htmlFor="document"
                                  className="admin_add_label"
                                >
                                  {t("booth.download_PDF")}
                                </label>

                                {values.document?.src ? (
                                  <a
                                    href={values.document?.src}
                                    target={"_blank"}
                                    style={{ margin: "0px 8px 8px 8px" }}
                                  >
                                    {t("file_preview")}
                                  </a>
                                ) : null}
                              </div>

                              <div style={{ position: "relative" }}>
                                <div>
                                  <div className="add-audio">
                                    {values.document?.name
                                      ? values.document.name
                                      : t("booth.Pull_file")}
                                    <i className="fa fa-image"></i>
                                  </div>
                                  <p
                                    style={{ display: "inline-block" }}
                                    className="msg-audio"
                                  >
                                    {t("booth.drag_download_PDF")}
                                    <br />
                                    {t("booth.download_PDF_note")}
                                  </p>
                                </div>

                                <input
                                  id="document"
                                  name="document"
                                  type="file"
                                  accept={acceptDocuments([DOCUMENT_PDF])}
                                  className="input-upload-audio"
                                  onChange={(event) => {
                                    setFieldValue(
                                      "document",
                                      event.target.files[0]
                                    );
                                  }}
                                />
                              </div>
                              <p
                                className={"form-input-error-space"}
                                style={{ paddingTop: "10px " }}
                              >
                                {errors.document ? errors.document : null}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <label htmlFor="description">
                            {t("booth.Description")}
                          </label>
                          {/* <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={BoothData.description}
                                                                    onReady={(editor) => {}}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        setFieldValue(`description`, data);
                                                                    }}
                                                                    onBlur={(event, editor) => {}}
                                                                    onFocus={(event, editor) => {}}
                                                                /> */}
                          <textarea
                            id="description"
                            name="description"
                            rows="7"
                            className="tajah-bordered-text-area"
                            onChange={handleChange}
                            placeholder={t("crud.placeholders.description")}
                            defaultValue={BoothData.description}
                          />
                          <p className={"form-input-error-space"}>
                            {errors.description ? errors.description : null}
                          </p>
                        </div>
                        <div className="col-md-4">
                          <label
                            htmlFor="participation_type"
                            className="admin_add_label"
                          >
                            : {t("booth.Adjective")}
                          </label>
                          <input
                            id="participation_type"
                            type="text"
                            name="participation_type"
                            className="admin_add_input"
                            defaultValue={BoothData.participation_type}
                            onChange={handleChange}
                          />
                          <p className={"form-input-error-space"}>
                            {errors.participation_type
                              ? errors.participation_type
                              : null}
                          </p>
                        </div>
                        <div className="col-md-8">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexWrap: "wrap",
                            }}
                          >
                            <label htmlFor="audio" className="admin_add_label">
                              {t("Exhibition.Upload_audio")}
                            </label>

                            {values.audio?.src ? (
                              <audio
                                controls
                                style={{
                                  margin: "8px 0",
                                  padding: "8px",
                                }}
                              >
                                <source
                                  src={values.audio.src}
                                  type="audio/mpeg"
                                ></source>
                              </audio>
                            ) : null}
                          </div>

                          <div style={{ position: "relative" }}>
                            <div>
                              <div className="add-audio">
                                {values.audio?.name
                                  ? values.audio.name
                                  : t("booth.Pull_file")}

                                <i className="fa fa-microphone"></i>
                              </div>
                              <p
                                style={{ display: "inline-block" }}
                                className="msg-audio"
                              >
                                {t("Exhibition.drag_audio")}
                                <br />
                                {t("Exhibition.Note_mp3")}
                              </p>
                            </div>

                            <input
                              id="audio"
                              name="audio"
                              type="file"
                              accept={acceptAudios()}
                              className="input-upload-audio"
                              onChange={(event) => {
                                setFieldValue("audio", event.target.files[0]);
                              }}
                            />
                          </div>
                          <p
                            className={"form-input-error-space"}
                            style={{ paddingTop: "10px " }}
                          >
                            {errors.audio ? errors.audio : null}
                          </p>
                        </div>
                      </div>

                      <div
                        className={`col-md-12 p-0 ${Tabs != 2 ? " hide" : " "}`}
                      >
                        <div className="col-md-12">
                          <label htmlFor="name" className="admin_add_label">
                            {t("booth.size")}
                          </label>
                          <select
                            name="size"
                            id="size"
                            style={{ maxWidth: "120px" }}
                            className="admin_add_input"
                            defaultValue={BoothData.size}
                            // onChange={handleChange}
                            onChange={(event) => {
                              setBoothData({
                                ...BoothData,
                                size: event.target.value,
                              });
                              if (event.target.value == "small") {
                                setBanner([0, 0, 0]);
                              } else if (event.target.value == "medium") {
                                setBanner([0, 0, 0, 0]);
                              } else if (event.target.value == "larg") {
                                setBanner([0, 0, 0, 0, 0, 0]);
                              }
                            }}
                          >
                            <option value={"small"}>
                              {" "}
                              {t("booth.small")}{" "}
                            </option>
                            <option value={"medium"}>
                              {" "}
                              {t("booth.medium")}{" "}
                            </option>
                            <option value={"larg"}> {t("booth.larg")} </option>
                          </select>
                          <p className={"form-input-error-space"}>
                            {errors.size ? errors.size : null}
                          </p>
                        </div>
                        {/* Banner */}
                        {Banner.map((data, key) => {
                          return (
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-4 hide">
                                  <label
                                    htmlFor="name"
                                    className="admin_add_label"
                                  >
                                    {t("booth.Banner_Position") + (key + 1)}
                                  </label>
                                  <input
                                    id={`banners_position_${key}`}
                                    type="number"
                                    name={`banners_position_${key}`}
                                    className="admin_add_input"
                                    onChange={handleChange}
                                    defaultValue={
                                      BoothData.banners[key]
                                        ? BoothData.banners[key].position
                                        : key + 1
                                    }
                                  />
                                  <p className={"form-input-error-space"}>
                                    {errors[`banners_position_${key}`]
                                      ? errors[`banners_position_${key}`]
                                      : null}
                                  </p>
                                </div>
                                <div className="col-md-8">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    <label
                                      htmlFor="logo"
                                      className="admin_add_label"
                                    >
                                      {t("booth.Banner_Image") + (key + 1)}
                                    </label>
                                    {values[`banners_image_${key}`]?.src ? (
                                      <a
                                        href={
                                          values[`banners_image_${key}`].src
                                        }
                                        target={"_blank"}
                                        style={{ margin: "0px 8px 8px 8px" }}
                                      >
                                        {" "}
                                        {t("image_preview")}{" "}
                                      </a>
                                    ) : null}
                                  </div>

                                  <div style={{ position: "relative" }}>
                                    <div>
                                      <div className="add-audio">
                                        {values[`banners_image_${key}`] ? (
                                          values[`banners_image_${key}`].src ? (
                                            <img
                                              src={
                                                values[`banners_image_${key}`]
                                                  .src
                                              }
                                              style={{
                                                height: "80px",
                                              }}
                                            />
                                          ) : (
                                            values[`banners_image_${key}`].name
                                          )
                                        ) : (
                                          t("booth.Pull_file")
                                        )}

                                        <i className="fa fa-image"></i>
                                      </div>
                                      <p
                                        style={{ display: "inline-block" }}
                                        className="msg-audio"
                                      >
                                        {t("booth.drag_Image")}
                                        <br />
                                        {t("booth.Note_image")}
                                      </p>
                                    </div>

                                    <input
                                      id={`banners_image_${key}`}
                                      name={`banners_image_${key}`}
                                      type="file"
                                      accept={acceptImages()}
                                      className="input-upload-audio"
                                      onChange={(event) => {
                                        setFieldValue(
                                          `banners_image_${key}`,
                                          event.target.files[0]
                                        );
                                      }}
                                    />
                                  </div>
                                  <p
                                    className={"form-input-error-space"}
                                    style={{ paddingTop: "10px " }}
                                  >
                                    {errors[`banners_image_${key}`]
                                      ? errors[`banners_image_${key}`]
                                      : null}
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        {/* End Banner */}
                        <div className="col-md-12">
                          <label htmlFor="name" className="admin_add_label">
                            {t("booth.count_booth")}
                          </label>
                          {ExhibitionData ? (
                            <div className="row position-box">
                              {ExhibitionData.booths_no > 12 ? (
                                <div className="col-md-12">
                                  <div className="row">
                                    <div className="col-md-2 position-ui position-opacity">
                                      <div className="position-content"></div>
                                    </div>
                                    <div className="col-md-2 position-ui">
                                      {ExhibitionPositions.length > 0 ? (
                                        ExhibitionPositions.indexOf(13) !=
                                        -1 ? (
                                          <div
                                            className={`position-content Position-not`}
                                          >
                                            13
                                          </div>
                                        ) : (
                                          <div
                                            className={`position-content ${
                                              position == 13
                                                ? " active-position"
                                                : ""
                                            }`}
                                            onClick={() => _position(13)}
                                          >
                                            13
                                          </div>
                                        )
                                      ) : (
                                        <div
                                          className={`position-content ${
                                            position == 13
                                              ? " active-position"
                                              : ""
                                          }`}
                                          onClick={() => _position(13)}
                                        >
                                          13
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-md-2 position-ui">
                                      {ExhibitionPositions.length > 0 ? (
                                        ExhibitionPositions.indexOf(14) !=
                                        -1 ? (
                                          <div
                                            className={`position-content Position-not`}
                                          >
                                            14
                                          </div>
                                        ) : (
                                          <div
                                            className={`position-content ${
                                              position == 14
                                                ? " active-position"
                                                : ""
                                            }`}
                                            onClick={() => _position(14)}
                                          >
                                            14
                                          </div>
                                        )
                                      ) : (
                                        <div
                                          className={`position-content ${
                                            position == 14
                                              ? " active-position"
                                              : ""
                                          }`}
                                          onClick={() => _position(14)}
                                        >
                                          14
                                        </div>
                                      )}
                                    </div>

                                    <div className="col-md-2 position-ui">
                                      {ExhibitionPositions.length > 0 ? (
                                        ExhibitionPositions.indexOf(15) !=
                                        -1 ? (
                                          <div
                                            className={`position-content Position-not`}
                                          >
                                            15
                                          </div>
                                        ) : (
                                          <div
                                            className={`position-content ${
                                              position == 15
                                                ? " active-position"
                                                : ""
                                            }`}
                                            onClick={() => _position(15)}
                                          >
                                            15
                                          </div>
                                        )
                                      ) : (
                                        <div
                                          className={`position-content ${
                                            position == 15
                                              ? " active-position"
                                              : ""
                                          }`}
                                          onClick={() => _position(15)}
                                        >
                                          15
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-md-2 position-ui ">
                                      {ExhibitionPositions.length > 0 ? (
                                        ExhibitionPositions.indexOf(16) !=
                                        -1 ? (
                                          <div
                                            className={`position-content Position-not`}
                                          >
                                            16
                                          </div>
                                        ) : (
                                          <div
                                            className={`position-content ${
                                              position == 16
                                                ? " active-position"
                                                : ""
                                            }`}
                                            onClick={() => _position(16)}
                                          >
                                            16
                                          </div>
                                        )
                                      ) : (
                                        <div
                                          className={`position-content ${
                                            position == 16
                                              ? " active-position"
                                              : ""
                                          }`}
                                          onClick={() => _position(16)}
                                        >
                                          16
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-md-2 position-ui position-opacity">
                                      <div className="position-content"></div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              <div className="col-md-12">
                                <div className="row">
                                  {/* position-opacity */}
                                  {ExhibitionData.booths_no > 16 ? (
                                    <div className="col-md-2 position-ui">
                                      {ExhibitionPositions.length > 0 ? (
                                        ExhibitionPositions.indexOf(19) !=
                                        -1 ? (
                                          <div
                                            className={`position-content Position-not`}
                                          >
                                            19
                                          </div>
                                        ) : (
                                          <div
                                            className={`position-content ${
                                              position == 19
                                                ? " active-position"
                                                : ""
                                            }`}
                                            onClick={() => _position(19)}
                                          >
                                            19
                                          </div>
                                        )
                                      ) : (
                                        <div
                                          className={`position-content ${
                                            position == 19
                                              ? " active-position"
                                              : ""
                                          }`}
                                          onClick={() => _position(19)}
                                        >
                                          19
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="col-md-2 position-ui position-opacity"></div>
                                  )}

                                  {ExhibitionData.booths_no >= 12 ? (
                                    <>
                                      <div className="col-md-2 position-ui">
                                        {ExhibitionPositions.length > 0 ? (
                                          ExhibitionPositions.indexOf(9) !=
                                          -1 ? (
                                            <div
                                              className={`position-content Position-not`}
                                            >
                                              9
                                            </div>
                                          ) : (
                                            <div
                                              className={`position-content ${
                                                position == 9
                                                  ? " active-position"
                                                  : ""
                                              }`}
                                              onClick={() => _position(9)}
                                            >
                                              9
                                            </div>
                                          )
                                        ) : (
                                          <div
                                            className={`position-content ${
                                              position == 9
                                                ? " active-position"
                                                : ""
                                            }`}
                                            onClick={() => _position(9)}
                                          >
                                            9
                                          </div>
                                        )}
                                      </div>
                                      <div className="col-md-2 position-ui">
                                        {ExhibitionPositions.length > 0 ? (
                                          ExhibitionPositions.indexOf(10) !=
                                          -1 ? (
                                            <div
                                              className={`position-content Position-not`}
                                            >
                                              10
                                            </div>
                                          ) : (
                                            <div
                                              className={`position-content ${
                                                position == 10
                                                  ? " active-position"
                                                  : ""
                                              }`}
                                              onClick={() => _position(10)}
                                            >
                                              10
                                            </div>
                                          )
                                        ) : (
                                          <div
                                            className={`position-content ${
                                              position == 10
                                                ? " active-position"
                                                : ""
                                            }`}
                                            onClick={() => _position(10)}
                                          >
                                            10
                                          </div>
                                        )}
                                      </div>

                                      <div className="col-md-2 position-ui">
                                        {ExhibitionPositions.length > 0 ? (
                                          ExhibitionPositions.indexOf(11) !=
                                          -1 ? (
                                            <div
                                              className={`position-content Position-not`}
                                            >
                                              11
                                            </div>
                                          ) : (
                                            <div
                                              className={`position-content ${
                                                position == 11
                                                  ? " active-position"
                                                  : ""
                                              }`}
                                              onClick={() => _position(11)}
                                            >
                                              11
                                            </div>
                                          )
                                        ) : (
                                          <div
                                            className={`position-content ${
                                              position == 11
                                                ? " active-position"
                                                : ""
                                            }`}
                                            onClick={() => _position(11)}
                                          >
                                            11
                                          </div>
                                        )}
                                      </div>
                                      <div className="col-md-2 position-ui ">
                                        {ExhibitionPositions.length > 0 ? (
                                          ExhibitionPositions.indexOf(12) !=
                                          -1 ? (
                                            <div
                                              className={`position-content Position-not`}
                                            >
                                              12
                                            </div>
                                          ) : (
                                            <div
                                              className={`position-content ${
                                                position == 12
                                                  ? " active-position"
                                                  : ""
                                              }`}
                                              onClick={() => _position(12)}
                                            >
                                              12
                                            </div>
                                          )
                                        ) : (
                                          <div
                                            className={`position-content ${
                                              position == 12
                                                ? " active-position"
                                                : ""
                                            }`}
                                            onClick={() => _position(12)}
                                          >
                                            12
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  ) : null}

                                  {ExhibitionData.booths_no > 16 ? (
                                    <div className="col-md-2 position-ui">
                                      {ExhibitionPositions.length > 0 ? (
                                        ExhibitionPositions.indexOf(20) !=
                                        -1 ? (
                                          <div
                                            className={`position-content Position-not`}
                                          >
                                            20
                                          </div>
                                        ) : (
                                          <div
                                            className={`position-content ${
                                              position == 20
                                                ? " active-position"
                                                : ""
                                            }`}
                                            onClick={() => _position(20)}
                                          >
                                            20
                                          </div>
                                        )
                                      ) : (
                                        <div
                                          className={`position-content ${
                                            position == 20
                                              ? " active-position"
                                              : ""
                                          }`}
                                          onClick={() => _position(20)}
                                        >
                                          20
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="col-md-2 position-ui position-opacity"></div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="row">
                                  {ExhibitionData.booths_no > 16 ? (
                                    <div className="col-md-2 position-ui">
                                      {ExhibitionPositions.length > 0 ? (
                                        ExhibitionPositions.indexOf(17) !=
                                        -1 ? (
                                          <div
                                            className={`position-content Position-not`}
                                          >
                                            17
                                          </div>
                                        ) : (
                                          <div
                                            className={`position-content ${
                                              position == 17
                                                ? " active-position"
                                                : ""
                                            }`}
                                            onClick={() => _position(17)}
                                          >
                                            17
                                          </div>
                                        )
                                      ) : (
                                        <div
                                          className={`position-content ${
                                            position == 17
                                              ? " active-position"
                                              : ""
                                          }`}
                                          onClick={() => _position(17)}
                                        >
                                          17
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="col-md-2 position-ui position-opacity"></div>
                                  )}

                                  <div className="col-md-2 position-ui">
                                    {ExhibitionPositions.length > 0 ? (
                                      ExhibitionPositions.indexOf(5) != -1 ? (
                                        <div
                                          className={`position-content Position-not`}
                                        >
                                          5
                                        </div>
                                      ) : (
                                        <div
                                          className={`position-content ${
                                            position == 5
                                              ? " active-position"
                                              : ""
                                          }`}
                                          onClick={() => _position(5)}
                                        >
                                          5
                                        </div>
                                      )
                                    ) : (
                                      <div
                                        className={`position-content ${
                                          position == 5
                                            ? " active-position"
                                            : ""
                                        }`}
                                        onClick={() => _position(5)}
                                      >
                                        5
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-md-2 position-ui">
                                    {ExhibitionPositions.length > 0 ? (
                                      ExhibitionPositions.indexOf(6) != -1 ? (
                                        <div
                                          className={`position-content Position-not`}
                                        >
                                          6
                                        </div>
                                      ) : (
                                        <div
                                          className={`position-content ${
                                            position == 6
                                              ? " active-position"
                                              : ""
                                          }`}
                                          onClick={() => _position(6)}
                                        >
                                          6
                                        </div>
                                      )
                                    ) : (
                                      <div
                                        className={`position-content ${
                                          position == 6
                                            ? " active-position"
                                            : ""
                                        }`}
                                        onClick={() => _position(6)}
                                      >
                                        6
                                      </div>
                                    )}
                                  </div>

                                  <div className="col-md-2 position-ui">
                                    {ExhibitionPositions.length > 0 ? (
                                      ExhibitionPositions.indexOf(7) != -1 ? (
                                        <div
                                          className={`position-content Position-not`}
                                        >
                                          7
                                        </div>
                                      ) : (
                                        <div
                                          className={`position-content ${
                                            position == 7
                                              ? " active-position"
                                              : ""
                                          }`}
                                          onClick={() => _position(7)}
                                        >
                                          7
                                        </div>
                                      )
                                    ) : (
                                      <div
                                        className={`position-content ${
                                          position == 7
                                            ? " active-position"
                                            : ""
                                        }`}
                                        onClick={() => _position(7)}
                                      >
                                        7
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-md-2 position-ui ">
                                    {ExhibitionPositions.length > 0 ? (
                                      ExhibitionPositions.indexOf(8) != -1 ? (
                                        <div
                                          className={`position-content Position-not`}
                                        >
                                          8
                                        </div>
                                      ) : (
                                        <div
                                          className={`position-content ${
                                            position == 8
                                              ? " active-position"
                                              : ""
                                          }`}
                                          onClick={() => _position(8)}
                                        >
                                          8
                                        </div>
                                      )
                                    ) : (
                                      <div
                                        className={`position-content ${
                                          position == 8
                                            ? " active-position"
                                            : ""
                                        }`}
                                        onClick={() => _position(8)}
                                      >
                                        8
                                      </div>
                                    )}
                                  </div>
                                  {ExhibitionData.booths_no > 16 ? (
                                    <div className="col-md-2 position-ui">
                                      {ExhibitionPositions.length > 0 ? (
                                        ExhibitionPositions.indexOf(18) !=
                                        -1 ? (
                                          <div
                                            className={`position-content Position-not`}
                                          >
                                            18
                                          </div>
                                        ) : (
                                          <div
                                            className={`position-content ${
                                              position == 18
                                                ? " active-position"
                                                : ""
                                            }`}
                                            onClick={() => _position(18)}
                                          >
                                            18
                                          </div>
                                        )
                                      ) : (
                                        <div
                                          className={`position-content ${
                                            position == 18
                                              ? " active-position"
                                              : ""
                                          }`}
                                          onClick={() => _position(18)}
                                        >
                                          18
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="col-md-2 position-ui position-opacity"></div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="row">
                                  <div className="col-md-2 position-ui position-opacity">
                                    <div className="position-content"></div>
                                  </div>
                                  <div className="col-md-2 position-ui">
                                    {ExhibitionPositions.length > 0 ? (
                                      ExhibitionPositions.indexOf(1) != -1 ? (
                                        <div
                                          className={`position-content Position-not`}
                                        >
                                          1
                                        </div>
                                      ) : (
                                        <div
                                          className={`position-content ${
                                            position == 1
                                              ? " active-position"
                                              : ""
                                          }`}
                                          onClick={() => _position(1)}
                                        >
                                          1
                                        </div>
                                      )
                                    ) : (
                                      <div
                                        className={`position-content ${
                                          position == 1
                                            ? " active-position"
                                            : ""
                                        }`}
                                        onClick={() => _position(1)}
                                      >
                                        1
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-md-2 position-ui">
                                    {ExhibitionPositions.length > 0 ? (
                                      ExhibitionPositions.indexOf(2) != -1 ? (
                                        <div
                                          className={`position-content Position-not`}
                                        >
                                          2
                                        </div>
                                      ) : (
                                        <div
                                          className={`position-content ${
                                            position == 2
                                              ? " active-position"
                                              : ""
                                          }`}
                                          onClick={() => _position(2)}
                                        >
                                          2
                                        </div>
                                      )
                                    ) : (
                                      <div
                                        className={`position-content ${
                                          position == 2
                                            ? " active-position"
                                            : ""
                                        }`}
                                        onClick={() => _position(2)}
                                      >
                                        2
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-md-2 position-ui">
                                    {ExhibitionPositions.length > 0 ? (
                                      ExhibitionPositions.indexOf(3) != -1 ? (
                                        <div
                                          className={`position-content Position-not`}
                                        >
                                          3
                                        </div>
                                      ) : (
                                        <div
                                          className={`position-content ${
                                            position == 3
                                              ? " active-position"
                                              : ""
                                          }`}
                                          onClick={() => _position(3)}
                                        >
                                          3
                                        </div>
                                      )
                                    ) : (
                                      <div
                                        className={`position-content ${
                                          position == 3
                                            ? " active-position"
                                            : ""
                                        }`}
                                        onClick={() => _position(3)}
                                      >
                                        3
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-md-2 position-ui">
                                    {ExhibitionPositions.length > 0 ? (
                                      ExhibitionPositions.indexOf(4) != -1 ? (
                                        <div
                                          className={`position-content Position-not`}
                                        >
                                          4
                                        </div>
                                      ) : (
                                        <div
                                          className={`position-content ${
                                            position == 4
                                              ? " active-position"
                                              : ""
                                          }`}
                                          onClick={() => _position(4)}
                                        >
                                          4
                                        </div>
                                      )
                                    ) : (
                                      <div
                                        className={`position-content ${
                                          position == 4
                                            ? " active-position"
                                            : ""
                                        }`}
                                        onClick={() => _position(4)}
                                      >
                                        4
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-md-2 position-ui position-opacity">
                                    <div className="position-content"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className="position-nots">
                            <span className="available">
                              {t("booth.Available")}
                            </span>
                            <span className="unavailable">
                              {t("booth.unAvailable")}
                            </span>
                          </div>

                          <p className={"form-input-error-space"}>
                            {errors.position ? errors.position : null}
                          </p>
                        </div>
                      </div>

                      <div
                        className={`col-md-12 p-0 ${Tabs != 3 ? " hide" : " "}`}
                      >
                        {filesDoc.length > 0 &&
                          filesDoc.map((doc) => {
                            return (
                              <div
                                key={doc}
                                style={{ padding: "20px 0 0 0" }}
                                className="col-md-12"
                              >
                                <div>
                                  <div className="row">
                                    <div className="col-md-4">
                                      <label
                                        htmlFor="name"
                                        className="admin_add_label"
                                      >
                                        {t("booth.product_name")}
                                      </label>
                                      <input
                                        id={`name_${doc}`}
                                        type="text"
                                        name={`name_${doc}`}
                                        className="admin_add_input"
                                        onChange={handleChange}
                                        defaultValue={
                                          BoothData.products[doc]
                                            ? BoothData.products[doc].name
                                            : null
                                        }
                                      />
                                      <p className={"form-input-error-space"}>
                                        {errors[`name_${doc}`]
                                          ? errors[`name_${doc}`]
                                          : null}
                                      </p>
                                    </div>
                                    <div className="col-md-4">
                                      <div
                                        className="add-audio"
                                        style={{
                                          fontWeight: "500",
                                          width: "100%",
                                          padding: " 11px 15px",
                                          marginTop: "30px",
                                        }}
                                      >
                                        {values[`image_${doc}`] ? (
                                          values[`image_${doc}`].src ? (
                                            <img
                                              src={values[`image_${doc}`].src}
                                              style={{
                                                height: "80px",
                                              }}
                                            />
                                          ) : (
                                            values[`image_${doc}`].name
                                          )
                                        ) : (
                                          t("booth.Product_Upload")
                                        )}
                                        {/* <img alt='Image_banner' style={{width: "85px",borderRadius: "10px",margin:" 0 10px"}} src={`${BoothData.products[data].image}`}/>*/}
                                      </div>

                                      <input
                                        id={`image_${doc}`}
                                        name={`image_${doc}`}
                                        type="file"
                                        accept={acceptImages()}
                                        className="input-upload-audio"
                                        onChange={(event) => {
                                          setFieldValue(
                                            `image_${doc}`,
                                            event.target.files[0]
                                          );
                                        }}
                                      />
                                      <p className={"form-input-error-space"}>
                                        {errors[`image_${doc}`]
                                          ? errors[`image_${doc}`]
                                          : null}
                                      </p>
                                    </div>
                                    <div className="col-md-4">
                                      <button
                                        className="delete-upload-file"
                                        type="button"
                                        onClick={() => {
                                          setFilesDoc((old) => {
                                            const index = old.findIndex(
                                              (i) => i == doc
                                            );
                                            if (index >= 0)
                                              old.splice(index, 1);
                                            return [...old];
                                          });
                                        }}
                                      >
                                        <i className="fa fa-trash"></i>
                                        {t("Exhibition.delete")}
                                      </button>

                                      <div>
                                        {values[`image_${doc}`]?.src ? (
                                          <a
                                            href={values[`image_${doc}`]?.src}
                                            target={"_blank"}
                                          >
                                            {t("image_preview")}
                                          </a>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <label
                                        htmlFor="name"
                                        className="admin_add_label"
                                      >
                                        {t("booth.title_name")}
                                      </label>
                                      <input
                                        id={`url_title_${doc}`}
                                        type="text"
                                        name={`url_title_${doc}`}
                                        className="admin_add_input"
                                        defaultValue={
                                          BoothData.products[doc]
                                            ? BoothData.products[doc].url_title
                                            : null
                                        }
                                        onChange={handleChange}
                                      />
                                      <p className={"form-input-error-space"}>
                                        {errors[`url_title_${doc}`]
                                          ? errors[`url_title_${doc}`]
                                          : null}
                                      </p>
                                    </div>
                                    <div className="col-md-4">
                                      <label
                                        htmlFor="name"
                                        className="admin_add_label"
                                      >
                                        {t("booth.url_page")}
                                      </label>
                                      <input
                                        id={`url_${doc}`}
                                        type="text"
                                        name={`url_${doc}`}
                                        className="admin_add_input"
                                        defaultValue={
                                          BoothData.products[doc]
                                            ? BoothData.products[doc].url
                                            : null
                                        }
                                        onChange={handleChange}
                                      />
                                      <p className={"form-input-error-space"}>
                                        {errors[`url_${doc}`]
                                          ? errors[`url_${doc}`]
                                          : null}
                                      </p>
                                    </div>
                                    <div className="col-md-4">
                                      <label
                                        htmlFor="name"
                                        className="admin_add_label"
                                      >
                                        {t("booth.url_page")}
                                      </label>
                                      <input
                                        id={`video_${doc}`}
                                        type="text"
                                        name={`video_${doc}`}
                                        defaultValue={
                                          BoothData.products[doc]
                                            ? BoothData.products[doc].video
                                            : null
                                        }
                                        className="admin_add_input"
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <label htmlFor="description">
                                    {t("booth.desc_product")}
                                  </label>
                                  {/* <CKEditor
                                                                                        editor={ClassicEditor}
                                                                                        onReady={(editor) => {}}
                                                                                        data = { BoothData.products[doc]? BoothData.products[doc].description: null }
                                                                                        onChange={(event, editor) => {
                                                                                            const data = editor.getData();
                                                                                            setFieldValue(`description_${doc}`, data);
                                                                                        }}
                                                                                        onBlur={(event, editor) => {}}
                                                                                        onFocus={(event, editor) => {}}
                                                                                    /> */}
                                  <textarea
                                    rows="3"
                                    className="tajah-bordered-text-area"
                                    onChange={(val) =>
                                      setFieldValue(
                                        `description_${doc}`,
                                        val.target.value
                                      )
                                    }
                                    placeholder={t(
                                      "crud.placeholders.description"
                                    )}
                                    defaultValue={
                                      BoothData.products[doc]
                                        ? BoothData.products[doc].description
                                        : null
                                    }
                                  />
                                  <p className={"form-input-error-space"}>
                                    {errors[`description_${doc}`]
                                      ? errors[`description_${doc}`]
                                      : null}
                                  </p>
                                </div>
                              </div>
                            );
                          })}

                        <div className="col-md-12">
                          <button
                            style={{
                              color: "#126e77",
                              background: "transparent",
                              border: "none",
                            }}
                            type="button"
                            onClick={() =>
                              setFilesDoc([
                                ...filesDoc,
                                `${filesDoc.length}-${Math.floor(
                                  Math.random() * 10000000
                                )}`,
                              ])
                            }
                          >
                            {t("booth.add_pro")}
                          </button>
                        </div>
                      </div>

                      <div
                        className={`col-md-12 p-0 ${Tabs != 4 ? " hide" : " "}`}
                      >
                        <h2> مراجعه بيانات الركن </h2>
                        <img src={image} alt="loading .." />
                        <ul
                          style={{ padding: "20px", overflowWrap: "anywhere" }}
                        >
                          <li style={{ padding: "10px 0px" }}>
                            <span style={{ fontweight: 600 }}>
                              {t("booth.name")} :
                            </span>
                            {values.name}
                          </li>
                          <li style={{ padding: "10px 0px" }}>
                            <span style={{ fontweight: 600 }}>
                              {t("booth.Upload_Image")} :
                            </span>
                            {values.logo.name}
                          </li>
                          <li style={{ padding: "10px 0px" }}>
                            <span style={{ fontweight: 600 }}>
                              {t("booth.Description")} :
                            </span>
                            <div
                              style={{ display: "block" }}
                              dangerouslySetInnerHTML={{
                                __html: values.description,
                              }}
                            ></div>
                          </li>
                          <li style={{ padding: "10px 0px" }}>
                            <span style={{ fontweight: 600 }}>
                              {t("booth.Adjective")} :
                            </span>
                            {values.participation_type}
                          </li>
                          <li style={{ padding: "10px 0px" }}>
                            <span style={{ fontweight: 600 }}>
                              {t("Exhibition.Upload_audio")} :
                            </span>
                            {values.audio.name}
                          </li>
                          <li style={{ padding: "10px 0px" }}>
                            <span style={{ fontweight: 600 }}>
                              {t("booth.size")} :
                            </span>
                            {BoothData.size}
                          </li>
                          <h5>{t("booth.Products")}</h5>
                          {filesDoc.map((data, key) => {
                            return (
                              <>
                                <li style={{ padding: "10px 0px" }}>
                                  <span style={{ fontweight: 600 }}>
                                    {t("booth.product_name") + "_" + (key + 1)}{" "}
                                    :
                                  </span>
                                  {values[`name_${data}`]
                                    ? values[`name_${data}`]
                                    : BoothData.products[data]
                                    ? BoothData.products[data].name
                                    : null}
                                </li>
                                <li style={{ padding: "10px 0px" }}>
                                  <span style={{ fontweight: 600 }}>
                                    {t("booth.title_name") + "_" + (key + 1)} :
                                  </span>
                                  {values[`url_title_${data}`]
                                    ? values[`url_title_${data}`]
                                    : BoothData.products[data]
                                    ? BoothData.products[data].url_title
                                    : null}
                                </li>
                                <li style={{ padding: "10px 0px" }}>
                                  <span style={{ fontweight: 600 }}>
                                    {t("booth.url_page") + "_" + (key + 1)} :
                                  </span>
                                  {values[`url_${data}`]
                                    ? values[`url_${data}`]
                                    : BoothData.products[data]
                                    ? BoothData.products[data].url
                                    : null}
                                </li>
                                <li style={{ padding: "10px 0px" }}>
                                  <span style={{ fontweight: 600 }}>
                                    {t("booth.desc_product") + "_" + (key + 1)}{" "}
                                    :
                                  </span>

                                  <div
                                    style={{ display: "block" }}
                                    dangerouslySetInnerHTML={{
                                      __html: values[`description_${data}`]
                                        ? values[`description_${data}`]
                                        : BoothData.products[data]
                                        ? BoothData.products[data].description
                                        : null,
                                    }}
                                  ></div>
                                </li>
                              </>
                            );
                          })}
                        </ul>
                      </div>

                      <div className="col-md-12">
                        {Tabs == 4 ? (
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            style={{
                              padding: "4px",
                              borderRadius: "6px",
                              display: "inline-block",
                            }}
                            className="admin_add_button btn_zoom"
                          >
                            {t("booth.send")}
                          </button>
                        ) : null}
                        {Tabs != 4 ? (
                          <button
                            type="button"
                            style={{
                              padding: "4px",
                              borderRadius: "6px",
                              display: "inline-block",
                            }}
                            className="admin_add_button btn_zoom"
                            onClick={async () => {
                              validateForm(values).then((errors) => {
                                if (Object.keys(errors).length) {
                                  var el = document.getElementById(
                                    Object.keys(errors)[0]
                                  );
                                  if (el)
                                    el.scrollIntoView({
                                      behavior: "smooth",
                                      block: "center",
                                    });
                                  else
                                    window.scrollTo({
                                      top: 0,
                                      behavior: "smooth",
                                    });
                                } else {
                                  setTabs(Tabs + 1);
                                }
                              });
                            }}
                          >
                            {t("booth.next")}
                          </button>
                        ) : null}
                        {Tabs != 1 ? (
                          <button
                            style={{
                              color: "#333",
                              background: "transparent",
                              border: "none",
                              padding: "10px 40px",
                              display: "inline-block",
                            }}
                            type="button"
                            onClick={() => {
                              setTabs(Tabs - 1);
                            }}
                          >
                            {t("booth.back")}
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
