import React, {createContext, useState} from "react";

const TrainerContext = createContext();

function TrainerProvider(props) {
  const [trainers, setTrainers] = useState([]);
  const [trainersCategory, setTrainersCategory] = useState([]);
  const [trainerInfo, setTrainerInfo] = useState({});
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const [initialSpinnersCount, setInitialSpinnersCount] = useState(5);
  const [initialSpinnersCarousel, setInitialSpinnersCarousel] = useState(4);
  const [isLoading, setIsLoading] = useState(true);

  return (
    <TrainerContext.Provider
      value={{
        trainers,
        setTrainers,
        trainersCategory,
        setTrainersCategory,
        trainerInfo,
        setTrainerInfo,
        isContentLoaded,
        setIsContentLoaded,
        initialSpinnersCount,
        setInitialSpinnersCount,
        initialSpinnersCarousel,
        setInitialSpinnersCarousel,
        isLoading,
        setIsLoading,
      }}
    >
      {props.children}
    </TrainerContext.Provider>
  );
}

export { TrainerContext, TrainerProvider };
