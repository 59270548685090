import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useHistory, useParams } from "react-router";
import HomeIcon from "../../../assets/icons/home.svg";
import Breadcrumb from "../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../Shared/Components/MainBoxHead/MainBoxHead";
import classes from "./SponserExhibition.module.css";

import { Pagination } from "@mui/material";
import moment from "moment";

import {
  exhibitionsList,
  exportExhibition,
} from "../../../Services/api/Exhibition/ExhibitionProvider";

import FilterBox from "../../Shared/Components/FilterBox/FilterBox";
import MainTable from "../../Shared/Components/MainTable/MainTable";

import SkeletonCardOverlay from "../../Shared/Components/Spinner/SkeletonCardOverlay";
import StarsRating from "../../Shared/Components/StarsRating/StarsRating";

import { filterActions } from "./SponserExhibitionActions";

const SponserExhibition = () => {
  const history = useHistory();
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const [showFilter, setShowFilter] = useState(false);
  const [exhibitionsListData, setExhibitionsListData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [dataToExport, setDataToExport] = useState([]);
  const [filterData, setFilterData] = useState({
    name: "",
    numberOfBooths: "",
    boothName: "",
    rating: "",
    sponsorName: "",
    startDate: "",
    endDate: "",
    status: "",
  });

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
  };

  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };

  const sortActionHandler = async (columnKey) => {
    if (sortColumn == columnKey) {
      setSortOrder((prev) => (prev == "asc" ? "desc" : "asc"));
    } else {
      setSortOrder("asc");
    }
    setSortColumn(columnKey);
  };

  const getFilterData = () => {
    const filter = { user_id: id };
    filterData.name && (filter.name = filterData.name);
    filterData.numberOfBooths && (filter.booths_no = filterData.numberOfBooths);
    filterData.startDate &&
      (filter.start_time = moment(filterData.startDate).format("YYYY-MM-DD"));
    filterData.endDate &&
      (filter.end_time = moment(filterData.endDate).format("YYYY-MM-DD"));
    filterData.status && (filter.status = filterData.status);
    filterData.rating && (filter.rating = filterData.rating);
    sortColumn && (filter.sort_column = sortColumn);
    sortOrder && (filter.sort_order = sortOrder);

    return filter;
  };

  const getExhibitionList = async () => {
    setIsLoading(true);
    const filter = getFilterData();
    setDataToExport(filter);
    try {
      let res = await exhibitionsList({
        perPage: 10,
        page: currentPage,
        filter,
      });
      if (res.status == 200) {
        setExhibitionsListData(res.data.data.exhibitions.data);
        setIsLoading(false);
        setTotalPages(res.data.data.exhibitions.meta.totalPages);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  const resetFilterHandler = () => {
    setFilterData({
      name: "",
      numberOfBooths: "",
      boothName: "",
      rating: "",
      sponsorName: "",
      startDate: "",
      endDate: "",
      status: "",
    });
    setSortColumn("");
    setSortOrder("");
    setIsResetApplied(true);
  };

  useEffect(() => {
    getExhibitionList();
    setIsResetApplied(false);
  }, [currentPage, isResetApplied]);

  useEffect(() => {
    getExhibitionList();
  }, [sortColumn, sortOrder]);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "sponser",
      page: t("sidebar.label.sponsers"),
      pagePath: "/admin/sponsers",
    },
    {
      id: "exhibition",
      page: t("sponser_exhibitions"),
      active: true,
    },
  ];

  const utcToLocal = (utcDate) => {
    return moment.utc(utcDate).local().format("hh:mm A");
  };

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData }) => <>{rowData.id}</>,
    },
    {
      id: "name",
      name: t("Exhibition.name"),
      key: "name",
      cell: ({ rowData }) => (
        <div className={classes["exhibition-list__table-name"]}>
          {rowData.name}
        </div>
      ),
      sortable: false,
    },
    {
      id: "ratings_count",
      name: t("Exhibition.rating"),
      key: "ratings_count",
      cell: ({ rowData }) => <StarsRating rating={rowData.rate_avg || 0} />,
      sortable: false,
    },
    {
      id: "exhibition_visits_count",
      name: t("Exhibition.number_of_participants"),
      key: "exhibition_visits_count",
      cell: ({ rowData }) => <>{rowData.visits_count || 0}</>,
      sortable: true,
    },
    {
      id: "booths_no",
      name: t("Exhibition.number_of_booths"),
      key: "booths_no",
      cell: ({ rowData }) => <>{rowData.booths_no || 0}</>,
      sortable: true,
    },
    {
      id: "start_time",
      name: t("Exhibition.start_exh_date"),
      key: "start_time",
      cell: ({ rowData }) => (
        <>
          {moment(rowData.start_time).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData.start_time)}
        </>
      ),
      sortable: true,
    },
    {
      id: "end_time",
      name: t("Exhibition.end_exh_date"),
      key: "end_time",
      cell: ({ rowData }) => (
        <>
          {moment(rowData.end_time).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData.end_time)}
        </>
      ),
      sortable: true,
    },

    {
      id: "status",
      name: t("Exhibition.status_exh"),
      key: "status",
      cell: ({ rowData }) =>
        rowData?.status == 1
          ? t("system_users.active")
          : t("system_users.inactive"),
      sortable: false,
    },
  ];

  const filterFields = [
    {
      id: "name",
      component: "input",
      label: t("Exhibition.name"),
      type: "text",
      placeholder: t("Exhibition.name"),
      name: "name",
      value: filterData.name,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "numberOfBooths",
      label: t("Exhibition.number_of_booths"),
      component: "select",
      placeholder: t("Exhibition.number_of_booths"),
      initialValue: t("Exhibition.number_of_booths"),
      name: "numberOfBooths",
      value: filterData.numberOfBooths,
      classes: "col-12 col-md-6 col-xl-2",
      onchange: changeHandler,
      options: [
        {
          id: "8",
          value: "8",
          label: "8",
        },
        {
          id: "12",
          value: "12",
          label: "12",
        },
        {
          id: "16",
          value: "16",
          label: "16",
        },
        {
          id: "18",
          value: "18",
          label: "18",
        },
        {
          id: "24",
          value: "24",
          label: "24",
        },
      ],
    },
    {
      id: "startDate",
      label: t("Exhibition.search_start_date"),
      component: "datePicker",
      placeholder: t("Exhibition.search_start_date"),
      name: "startDate",
      value: filterData.startDate,
      classes: "col-12 col-md-6 col-xl-2",
      onchange: changeHandler,
    },
    {
      id: "endDate",
      label: t("Exhibition.search_end_date"),
      component: "datePicker",
      placeholder: t("Exhibition.search_end_date"),
      name: "endDate",
      value: filterData.endDate,
      classes: "col-12 col-md-6 col-xl-2",
      onchange: changeHandler,
    },
    {
      id: "status",
      label: t("Exhibition.status_exh"),
      component: "select",
      placeholder: t("Exhibition.status_exh"),
      initialValue: t("Exhibition.status_exh"),
      name: "status",
      value: filterData.status,
      classes: "col-12 col-md-6 col-xl-2",
      onchange: changeHandler,
      options: [
        {
          id: "active",
          value: "1",
          label: t("Exhibition.active"),
        },
        {
          id: "inactive",
          value: "0",
          label: t("Exhibition.inactive"),
        },
      ],
    },
    {
      id: "rating",
      label: t("Exhibition.rating"),
      component: "select",
      placeholder: t("Exhibition.rating"),
      initialValue: t("Exhibition.rating"),
      name: "rating",
      value: filterData.rating,
      classes: "col-12 col-md-6 col-xl-2",
      onchange: changeHandler,
      options: [
        {
          id: "0",
          value: "0",
          label: 0,
        },
        {
          id: "1",
          value: "1",
          label: 1,
        },
        {
          id: "2",
          value: "2",
          label: 2,
        },
        {
          id: "3",
          value: "3",
          label: 3,
        },
        {
          id: "4",
          value: "4",
          label: 4,
        },
        {
          id: "5",
          value: "5",
          label: 5,
        },
      ],
    },
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("Exhibition.reset"),
      classes: "col-12 col-md-6 col-xl-2   border-button",
      action: () => {
        resetFilterHandler();
      },
      backgroundColor: "transparent",
      color: "#046c77",
      borderColor: "#046c77",
    },
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("Exhibition.search"),
      classes: "col-12 col-md-6  col-xl-2",
      action: () => {
        if (currentPage != 1) {
          setCurrentPage(1);
        } else {
          getExhibitionList();
        }
      },
    },
  ];

  const showFilterHandler = () => {
    setShowFilter((prev) => !prev);
  };

  const exportHandler = async (type) => {
    setIsExportLoading(true);
    await exportExhibition(type, dataToExport)
      .then((res) => {
        if (res.status && res.status === 200) {
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {t("export_success")}
            </span>
          );
        } else {
          throw res;
        }
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {t("export_faild")}
          </span>
        );
      })
      .finally(() => {
        setIsExportLoading(false);
      });
  };

  return (
    <div className={classes["exhibition_list"]}>
      {isExportLoading && <SkeletonCardOverlay skeletonWidth="100" />}
      <div className="container-fluid">
        <div className={classes["exhibition_list__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <MainBox>
                <MainBoxHead
                  title={t("Exhibition.Exhibition_list")}
                  actions={filterActions({
                    showFilter: showFilterHandler,
                    exportPdf: () => {
                      exportHandler("pdf");
                    },
                    exportExcel: () => {
                      exportHandler("xlsx");
                    },
                  })}
                />
                {showFilter && <FilterBox fields={filterFields} />}
                <MainTable
                  columns={columns}
                  data={exhibitionsListData}
                  isLoading={isLoading}
                  sortAction={sortActionHandler}
                />
                <Pagination
                  count={totalPages}
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  className="main-pagination"
                  page={currentPage}
                  onChange={(e, page) => {
                    setCurrentPage(page);
                  }}
                />
              </MainBox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SponserExhibition;
