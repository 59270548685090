import React from "react";
import {AboutUsProvider} from "../../../Services/api/toutorials/aboutusContext";
import AboutUsAdmin from "./AboutUsAdmin";

function AboutUs(props) {
  return (
    <AboutUsProvider>
      <AboutUsAdmin />
    </AboutUsProvider>
  );
}

export default AboutUs;
