import React from "react";
import browsePhoto from "../../../../assets/image/photo.png";
import {useTranslation} from "react-i18next";

function PersonalInfoForm() {
  require("./personalInfoForm.css");
  const { t } = useTranslation();

  return (
    <div className="personal_info_card_st">
      <div className="personal_info_card">
        <div className="perosnal_text_img">{t("photopro")}</div>
        <div className="personal_img">
          <div className="">
            <div className="personal_dimage">
              <div className="personal_browse">
                <img src={browsePhoto} alt="" className="personal_browse_img" />
              </div>
            </div>
          </div>
          <div className="personal_img_text">{t("choosepng")}</div>
        </div>
        <button className="personal_delete_img">{t("deletepro")}</button>

        <div className="personal_form">
          <form id="contact" className="form">
            <div className="row">
              <div className="col-lg-12 form-group">
                <div className="personal_input">
                  <label className="personal_label">{t("fullname")} : </label>
                  <input
                    className="personal_input_in"
                    id="name"
                    name="name"
                    placeholder=""
                    type="text"
                  />
                </div>
              </div>

              <div className="col-lg-6 form-group">
                <div className="personal_input">
                  <label className="personal_label">{t("country")} : </label>
                  <select className="form-personal_input_in personal_select_box">
                    <option>Default select</option>
                    <option>Default select</option>
                    <option>Default select</option>
                    <option>Default select</option>
                  </select>
                </div>
              </div>

              <div className="col-lg-6 form-group">
                <div className="personal_input">
                  <label className="personal_label">{t("city")} : </label>
                  <select className="form-personal_input_in personal_select_box">
                    <option>Default select</option>
                    <option>Default select</option>
                    <option>Default select</option>
                    <option>Default select</option>
                  </select>
                </div>
              </div>

              <div className="personal_info_number col-12">
                <div className="col-md-2 col-sm-1 form-group">
                  <div className="personal_input">
                    <label className="personal_label"> + </label>
                    <select className="form-personal_input_in personal_select_box">
                      <option>Default select</option>
                      <option>Default select</option>
                      <option>Default select</option>
                      <option>Default select</option>
                    </select>
                  </div>
                </div>

                <div className="col-md-4 col-sm-6 form-group">
                  <label className="personal_label">{t("numphone")}</label>

                  <div className="personal_input">
                    <input
                      className="personal_input_in"
                      id="name"
                      name="name"
                      placeholder=""
                      type="text"
                    />
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="personal_phone_info">{t("numcorrectly")}</div>
              </div>

              <div className="col-lg-12 form-group">
                <div className="personal_input">
                  <label className="personal_label">{t("birth")} : </label>
                  <input
                    className="personal_input_in"
                    id="name"
                    name="name"
                    placeholder=""
                    type="date"
                  />
                </div>
              </div>

              <div className="col-lg-12 form-group">
                <label className="personal_label">{t("gender")} : </label>
                <div className="personal_radio">
                  <div className="form-check form-check-inline ">
                    <input
                      className="form-check-input "
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio1"
                      value="option1"
                    />
                    <label className="form-check-label" htmlFor="inlineRadio1">
                      {t("male")}
                    </label>
                  </div>
                  <div className="form-check form-check-inline second_radio">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio2"
                      value="option2"
                    />
                    <label className="form-check-label" htmlFor="inlineRadio2">
                      {t("female")}
                    </label>
                  </div>
                </div>
              </div>

              <div className=" col-lg-12 form-group personal_checks">
                <label className="personal_label personal_label_top">
                  {t("areas")}
                </label>

                <label className="personal_label">{t("choose")}</label>
                <div className="row personal_check">
                  <div className="col-5 form-check checkbox">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="Check1"
                    />
                    <label className="form-check-label" htmlFor="Check1">
                      {t("Dentists")}
                    </label>
                  </div>
                  <div className="col-5 form-check checkbox">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="Check2"
                    />
                    <label className="form-check-label" htmlFor="Check2">
                      {t("Dentists")}
                    </label>
                  </div>

                  <div className="col-5 form-check checkbox">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="Check3"
                    />
                    <label className="form-check-label" htmlFor="Check3">
                      {t("Dentists")}
                    </label>
                  </div>
                  <div className="col-5 form-check checkbox">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="Check4"
                    />
                    <label className="form-check-label" htmlFor="Check4">
                      {t("Dentists")}
                    </label>
                  </div>

                  <div className="col-5 form-check checkbox">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="Check5"
                    />
                    <label className="form-check-label" htmlFor="Check5">
                      {t("Dentists")}
                    </label>
                  </div>
                  <div className="col-5 form-check checkbox">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="Check6"
                    />
                    <label className="form-check-label" htmlFor="Check6">
                      {t("Dentists")}
                    </label>
                  </div>
                  <div className="col-5 form-check checkbox">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="Check7"
                    />
                    <label className="form-check-label" htmlFor="Check7">
                      {t("Dentists")}
                    </label>
                  </div>
                  <div className="col-5 form-check checkbox">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="Check8"
                    />
                    <label className="form-check-label" htmlFor="Check8">
                      {t("Dentists")}
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-md-12 form-group">
                  <input
                    className="personal_button"
                    type="submit"
                    value={t("save")}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PersonalInfoForm;
