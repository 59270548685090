import React from "react";
import classes from "./ContentView.module.css";
import { downloadFileFromLesson } from "../../../../../Services/api/courses/courseProvider";
import DownloadIcon from "../../../../../assets/icons/download3.svg";
import { useTranslation } from "react-i18next";

export default function FileView({ question }) {
  const { t } = useTranslation();
  const handleDownload = () => {
    const type = "contnet";
    downloadFileFromLesson(
      question?.title,
      question?.extension,
      question?.id,
      type
    );
  };
  return (
    <div>
      {/* <div style={{ paddingBottom: 15 }}>
        <div
          dangerouslySetInnerHTML={{ __html: question.content }}
          className={classes["course-slider-item-content-inner-body"]}
        />
      </div> */}
      <div className={classes["downloadFile"]}>
        <button
          className={classes["file-download"]}
          type="button"
          onClick={() => handleDownload()}
        >
          <img
            src={DownloadIcon}
            alt="Download Files"
            className={classes["downloadicon"]}
          />
          <span>{t("download_files")}</span>
        </button>
      </div>
    </div>
  );
}
