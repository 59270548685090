import React, {createContext, useState} from "react";

const AboutUsContext = createContext();

function AboutUsProvider(props) {
  const [aboutus, setAboutus] = useState({});

  return (
    <AboutUsContext.Provider value={{ aboutus, setAboutus }}>
      {props.children}
    </AboutUsContext.Provider>
  );
}

export { AboutUsContext, AboutUsProvider };
