import env from "../Environment";
import connector from "../Handler";
import URLBuilder from "../UrlBuilder";

export function getLessonContent(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.edit_lesson_content, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function setWatchingProgress(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
      .post(env.save_watching_progress, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getLessonPlans(id, params) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_lesson_plans, { id: id }), { params })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getLessonActivities(lesson_id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_lesson_activities, {
          params: {
            lesson_id: lesson_id,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function sendActivityResponse(data) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.send_activity_response, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getExam(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.trainee_get_exam, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getQuestion(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.trainee_get_question, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          resolve(err);
        });
    } catch (error) {
      resolve(error);
    }
  });

  return myPromise;
}

export function joinExam(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.trainee_join_exam, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function answerExam(data) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.trainee_answer, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getExamResult(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.trainee_get_exam_result, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
