import React from "react";

const BoothDetailsFile = ({
  label,
  name,
  file,
  fileLabel,
  icon,
  filePull,
  fileDrag,
  fileNote,
  accept,
  setFieldValue,
  error,
}) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <label htmlFor={name} className="admin_add_label">
          {label}
        </label>
        {file.src ? (
          <a
            href={file.src}
            target={"_blank"}
            style={{ margin: "0px 8px 8px 8px" }}
          >
            {fileLabel}
          </a>
        ) : null}
      </div>
      <div style={{ position: "relative" }}>
        <div className="booth-details__file">
          <div className="add-audio">
            {file ? (
              file.src ? (
                <img
                  src={file.src}
                  style={{
                    height: "80px",
                  }}
                />
              ) : (
                file.name
              )
            ) : (
              { filePull }
            )}
            <div className="add-audio-icon">{icon}</div>
          </div>
          <p style={{ display: "inline-block" }} className="msg-audio">
            {fileDrag}
            <br />
            {fileNote}
          </p>
        </div>

        <input
          id={name}
          name={name}
          type="file"
          accept={accept}
          className="input-upload-audio"
          onChange={(event) => {
            setFieldValue(name, event.target.files[0]);
          }}
        />
      </div>
      <p className={"form-input-error-space"} style={{ paddingTop: "10px " }}>
        {error ? error : null}
      </p>
    </>
  );
};

export default BoothDetailsFile;
