import env from "./Environment";
import connector from "./Handler";
import URLBuilder from "./UrlBuilder";

export function getPublicCoursesList({ type, status }) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_public_courses, { type: type }), {
          params: {
            status: status,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
