import React, { useEffect, useRef, useState } from "react";
import HomeIcon from "../../assets/icons/home.svg";
import Breadcrumb from "../Shared/Components/Breadcrumb/Breadcrumb";

import "./index.css";
import { useTranslation } from "react-i18next";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import AddBoxIcon from "@mui/icons-material/AddBox";
import GridViewIcon from "@mui/icons-material/GridView";
import AddIcon from "@mui/icons-material/Add";
import SharedModal from "../Shared/Components/SharedModal/SharedModal";
import DeleteIcon from "../../assets/image/alert-octagon.png";
import AddSetNewQuestions from "../../Modals/AddSetNewQuestions/AddSetNewQuestions";
import { useDispatch, useSelector } from "react-redux";
import {
  changeStatusFaqCategory,
  changeStatusFaqQuestion,
  deleteFaqCategory,
  deleteFaqQuestion,
  getFaqCategoryList,
  getFaqCategoryListWithoutLoading,
  reOrderFaqCategory,
  reOrderFaqQuestion,
  showFaqCategory,
  showFaqQuestion,
} from "../../Redux/Actions/faq/faq.action";
import SkeletonCard from "../Shared/Components/Spinner/SkeletonCard";
import SwitchToggle from "../Shared/Components/SwitchToggle/SwitchToggle";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import classes from "./FAQ.module.css";
import plus from "../../assets/icons/plus.svg";
import NoResult from "../Shared/Components/NoResult/NoResult";

const initialSwitches = [
  { id: 1, checked: false },
  { id: 2, checked: true },
];

const FAQ = () => {
  const dispatch = useDispatch();
  const {
    faqList,
    loading,
    deleteResponse,
    changeStatusResponse,
    changeStatusQuestionResponse,
    deleteQuestionResponse,
    showResponse,
    showQuestionResponse,
  } = useSelector((state) => state.faqReducer);

  const { t } = useTranslation();
  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "FQA",
      page: t("FAQ.common_questions"),
      active: true,
    },
  ];

  const [faqIdDeleteCategory, setfaqIdDeleteCategory] = useState(null);
  const [faqIdDeleteQuestion, setfaqIdDeleteQuestion] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [iseDeleteLoading, setIsDeleteLoading] = useState(false);
  const [selectedQes, setSelectedQes] = useState(null);
  const [showAddSetOfQues, setShowAddSetOfQues] = useState(false);
  const [showEditSetOfQues, setShowEditSetOfQues] = useState(false);
  const [showAddQues, setShowAddQues] = useState(false);
  const [showEditQues, setShowEditQues] = useState(false);
  const [activeIndexes, setActiveIndexes] = useState([]);
  const [catActiveIndexes, setCatActiveIndexes] = useState([]);
  const [categoryId, setCategoryId] = useState(null);
  const [showCategoryById, setShowCategoryById] = useState({});
  const [showQuestionById, setShowQuestionById] = useState({});
  const [categotyData, setCategotyData] = useState([]);
  const [questionData, setQuestionData] = useState([]);
  const [statusChangeQuestionId, setStatusChangeQuestionId] = useState();
  const [statusChangeCategoryId, setStatusCategoryId] = useState();

  const [checkedStates, setCheckedStates] = useState(
    initialSwitches.map((switchObj) => switchObj.checked)
  );

  useEffect(() => {
    if (deleteResponse.status === 200 && deleteResponse.data.status) {
      removeObjectById(faqIdDeleteCategory, setCategotyData);
      setfaqIdDeleteCategory(null);
    }
  }, [deleteResponse]);

  useEffect(() => {
    if (
      deleteQuestionResponse.status === 200 &&
      deleteQuestionResponse.data.status
    ) {
      removeObjectById(faqIdDeleteQuestion, setQuestionData, true);
      setfaqIdDeleteQuestion(null);
    }
  }, [deleteQuestionResponse]);

  useEffect(() => {
    if (
      changeStatusResponse.status === 200 &&
      changeStatusResponse.data.status
    ) {
      // dispatch(getFaqCategoryListWithoutLoading());
      updateObjectById(
        statusChangeCategoryId,
        changeStatusResponse.data.data,
        setCategotyData
      );
    }
  }, [changeStatusResponse]);

  useEffect(() => {
    if (
      changeStatusQuestionResponse.status === 200 &&
      changeStatusQuestionResponse.data.status
    ) {
      // dispatch(getFaqCategoryListWithoutLoading());
      updateObjectById(
        statusChangeQuestionId,
        changeStatusQuestionResponse.data.data,
        setQuestionData
      );
    }
  }, [changeStatusQuestionResponse]);

  useEffect(() => {
    dispatch(getFaqCategoryList());
  }, []);

  const updateObjectById = (id, newObject, setData) => {
    setData((prevObjects) =>
      prevObjects.map((obj) => (obj.id === id ? newObject : obj))
    );
  };

  const updateArrayByID = (data, id, newArray) => {
    const newData = data.map((item) => {
      if (item.id === id) {
        return { ...item, faqs: newArray };
      }
      return item;
    });
    setCategotyData(newData);
  };

  const handleAddNewQuestionData = (quesData) => {
    setQuestionData((e) => {
      const data = [quesData, ...e];

      updateArrayByID(categotyData, categoryId, data);

      return data;
    });
  };

  const handleAddNewCategoryData = (catData) => {
    catData.faqs = [];
    setCatActiveIndexes(null);
    setCategotyData((e) => [catData, ...e]);
  };

  const toggleAccordion = (index) => {
    setActiveIndexes(activeIndexes === index ? null : index);
  };

  const toggleCatAccordion = (index, fromAdd) => {
    setCatActiveIndexes((prevIndex) =>
      prevIndex === index ? (fromAdd ? index : null) : index
    );
  };

  const removeObjectById = (idToRemove, setArray, question = false) => {
    setArray((prevObjects) => {
      const filteredArray = prevObjects.filter((obj) => obj.id !== idToRemove);

      if (question) updateArrayByID(categotyData, categoryId, filteredArray);

      return filteredArray;
    });
  };

  const deleteHandler = (id, title, question = false) => {
    if (question) {
      setfaqIdDeleteQuestion(id);
    } else {
      setfaqIdDeleteCategory(id);
    }
    setSelectedQes({ name: title });
    setShowDeleteModal(true);
  };

  const deleteExhibitionHandler = async () => {
    if (faqIdDeleteQuestion) {
      await dispatch(deleteFaqQuestion(faqIdDeleteQuestion));
    } else {
      await dispatch(deleteFaqCategory(faqIdDeleteCategory));
      setCatActiveIndexes(false);
    }
    setShowDeleteModal(false);
    // setfaqIdDeleteQuestion(faqIdDeleteQuestion);
  };

  const statusHandler = async (id, question = false) => {
    try {
      if (question) {
        await dispatch(changeStatusFaqQuestion(id));
      } else {
        await dispatch(changeStatusFaqCategory(id));
      }
    } catch (err) {}
  };

  const editHandler = async (id, question = false) => {
    if (question) {
      await dispatch(showFaqQuestion(id));
    } else {
      await dispatch(showFaqCategory(id));
    }
  };

  useEffect(() => {
    if (showResponse.status === 200 && showResponse.data.status) {
      setShowEditSetOfQues(true);
      setShowCategoryById(showResponse.data.data);
    }
  }, [showResponse, showCategoryById]);

  useEffect(() => {
    if (
      showQuestionResponse.status === 200 &&
      showQuestionResponse.data.status
    ) {
      setShowEditQues(true);
      setShowQuestionById(showQuestionResponse.data.data);
    }
  }, [showQuestionResponse, showQuestionById]);

  function compareArrays(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      const obj1 = arr1[i];
      const obj2 = arr2[i];

      if (
        obj1.answer !== obj2.answer ||
        obj1.id !== obj2.id ||
        obj1.order !== obj2.order ||
        obj1.question !== obj2.question ||
        obj1.status !== obj2.status
      ) {
        return false;
      }
    }

    return true;
  }

  const handleQuestionData = (id) => {
    const arr = categotyData.filter((f) => f.id === id);
    const arraysAreEqual = compareArrays(arr[0].faqs, questionData);

    if (!arraysAreEqual) {
      dispatch(getFaqCategoryListWithoutLoading());
    }

    setQuestionData(arr[0].faqs);
    updateArrayByID(categotyData, id, arr[0].faqs);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setCatActiveIndexes(false);
    const updatedDivs = [...categotyData];
    const [movedDiv] = updatedDivs.splice(oldIndex, 1);
    updatedDivs.splice(newIndex, 0, movedDiv);
    setCategotyData(updatedDivs);

    const movedDivId = movedDiv.id;
    const movedToIndex = newIndex;

    dispatch(reOrderFaqCategory(movedDivId, movedToIndex + 1));
  };

  const onSortEndQues = ({ oldIndex, newIndex }) => {
    setActiveIndexes(false);
    const updatedDivs = [...questionData];
    const [movedDiv] = updatedDivs.splice(oldIndex, 1);
    updatedDivs.splice(newIndex, 0, movedDiv);
    setQuestionData(updatedDivs);
    updateArrayByID(categotyData, categoryId, updatedDivs);

    const movedDivId = movedDiv.id;
    const movedToIndex = newIndex;

    dispatch(reOrderFaqQuestion(movedDivId, movedToIndex + 1));
  };

  useEffect(() => {
    setCategotyData(faqList);
  }, [faqList]);

  const DragHandle = SortableHandle(({ iconcolor }) => {
    return (
      <GridViewIcon
        color="#fff"
        style={{
          fontSize: 20,
          marginInlineEnd: 8,
          color: !iconcolor ? "#9c9c9c" : "#FFF",
        }}
      />
    );
  });

  const QuestionContent = ({ ques, i }) => {
    return (
      <div className="collapsible-content">
        <div key={i}>
          <>
            <div
              className={`faq-item ${
                activeIndexes === ques.id ? "active_faq" : ""
              }`}
              key={ques.id}
            >
              <div className="faq-question">
                <div
                  onClick={() => toggleAccordion(ques.id)}
                  style={{ width: "100%" }}
                >
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    className="j-center"
                  >
                    <DragHandle iconcolor={activeIndexes === ques.id} />
                    <p>{ques.question}</p>
                  </div>
                </div>

                <div className="d-flex-icons-faq">
                  <BorderColorOutlinedIcon
                    style={{
                      fontSize: 24,
                      color: activeIndexes !== ques.id && "#D1D626",
                    }}
                    onClick={() => {
                      editHandler(ques.id, true);
                      setCategoryId(categoryId);
                    }}
                  />
                  <HighlightOffIcon
                    onClick={() => deleteHandler(ques.id, ques.question, true)}
                    style={{
                      fontSize: 24,
                      marginInline: 7,
                      color: activeIndexes !== ques.id && "#E62626",
                    }}
                  />

                  <SwitchToggle
                    status={ques.status == "1" ? true : false}
                    id={ques.id}
                    onStatusChange={() => {
                      statusHandler(ques.id, true);
                      setStatusChangeQuestionId(ques.id);
                    }}
                  />
                  <div
                    onClick={() => toggleAccordion(ques.id)}
                    style={{ width: "100%" }}
                  >
                    {activeIndexes === ques.id ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </div>
                </div>
              </div>
              {activeIndexes === ques.id && (
                <div className="faq-answer">
                  <p dangerouslySetInnerHTML={{ __html: ques.answer }} />
                </div>
              )}
            </div>
          </>
        </div>
      </div>
    );
  };

  const QuestionSortableItem = SortableElement(({ ques, i }) => (
    <div className="sortable-item">
      <QuestionContent ques={ques} i={i} />
    </div>
  ));

  const QuestionSortableList = SortableContainer(({ items }) => (
    <div className="sortable-list">
      {items?.map((ques, i) => (
        <QuestionSortableItem key={`item-${i}`} i={i} ques={ques} index={i} />
      ))}
    </div>
  ));

  const CategoryContent = ({ faq, i }) => {
    return (
      <>
        <div key={i} className={``}>
          <div className={classes["header-faq-q"]}>
            <div
              className={classes["header-faq-q-title"]}
              onClick={() => {
                toggleCatAccordion(i);
                handleQuestionData(faq.id);
                setCategoryId(faq.id);
              }}
            >
              <DragHandle />
              <h5>{faq.title}</h5>
              {faq.faqs.length > 0 && (
                <div
                  style={{
                    color: "#2e6a75",
                  }}
                >
                  {catActiveIndexes === i ? (
                    <KeyboardArrowUpIcon color="#2e6a75" />
                  ) : (
                    <KeyboardArrowDownIcon color="#2e6a75" />
                  )}
                </div>
              )}
            </div>
            <div className={classes["icons"]}>
              <ClearOutlinedIcon
                className={classes["delete-icon"]}
                onClick={() => deleteHandler(faq.id, faq.title)}
              />
              <BorderColorOutlinedIcon
                className={classes["edit-icon"]}
                onClick={() => editHandler(faq.id)}
              />

              <button
                type="button"
                onClick={() => {
                  statusHandler(faq.id);
                  setStatusCategoryId(faq.id);
                }}
                className={`tw-relative tw-w-24 tw-py-2 tw-px-1 tw-rounded-full tw-flex tw-items-center tw-justify-between tw-transition-all ${
                  faq.status == "1"
                    ? "tw-bg-teal-800 tw-text-white tw-pr-7"
                    : "tw-bg-gray-200 !tw-text-gray-700 tw-pl-7"
                }`}
              >
                <div
                  className={`tw-text-xs tw-white-space-nowrap tw-grow tw-text-center ${classes["mb-4"]}`}
                >
                  {faq.status == "1"
                    ? t("Exhibition.active")
                    : t("Exhibition.inactive")}
                </div>
                <div
                  className={`tw-absolute tw-top-1/2 -tw-translate-y-1/2 ${
                    faq.status == "0" ? "tw-left-2" : "tw-right-2"
                  } tw-h-5 tw-w-5 tw-bg-white tw-shadow tw-rounded-full`}
                ></div>
              </button>
            </div>
          </div>

          {catActiveIndexes === i && (
            <>
              <QuestionSortableList
                items={questionData}
                onSortEnd={onSortEndQues}
                useDragHandle
              />
            </>
          )}
        </div>
        <div
          className={`${classes["add-question"]} d-flex justify-content-between`}
          onClick={() => {
            toggleCatAccordion(i, true);
            handleQuestionData(faq.id);
            setCategoryId(faq.id);
          }}
        >
          <div className={classes["dashes-line"]}></div>
          <button
            style={{
              color: "#2e6a75",
              background: "transparent",
              border: "none",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            type="button"
            className="add-question-btn"
            onClick={() => {
              setShowAddQues(true);
              setCategoryId(faq.id);
            }}
          >
            <AddIcon
              sx={{
                color: "#2e6a75",
                border: "2px solid #2e6a75",
                borderRadius: "4px",
                marginInlineEnd: 1.5,
                fontSize: 20,
              }}
            />
            <span>{t("FAQ.addQuestion")}</span>
          </button>
          <div className={classes["dashes-line"]}></div>
        </div>
        <div className="dashes-lines"></div>
      </>
    );
  };

  const CategorySortableItem = SortableElement(({ faq, i }) => (
    <div className="sortable-item">
      <CategoryContent faq={faq} i={i} />
    </div>
  ));

  const CategorySortableList = SortableContainer(({ items }) => (
    <div className="sortable-list">
      {items.map((faq, i) => (
        <CategorySortableItem key={`item-${i}`} i={i} faq={faq} index={i} />
      ))}
    </div>
  ));

  return (
    <div className="container-fluid">
      <div className={classes["breadcrumbs-my-30"]}>
        <Breadcrumb list={breadcrumbList} />
      </div>

      <div className={classes["body-faq"]}>
        <div className={classes["header-faq"]}>
          <h3>{t("FAQ.frequently_asked_questions_groups")}</h3>
          <img
            src={plus}
            className={classes["header-faq-icon"]}
            alt="icon"
            onClick={() => setShowAddSetOfQues(true)}
          />
        </div>

        {loading ? (
          <div
            style={{
              height: "34.5vh",
            }}
          >
            <SkeletonCard />
          </div>
        ) : (
          <>
            {categotyData.length > 0 ? (
              <CategorySortableList
                items={categotyData}
                onSortEnd={onSortEnd}
                useDragHandle
              />
            ) : (
              <NoResult value={t("no_record")} classes={classes.notfound} />
            )}
          </>
        )}
      </div>

      {showAddSetOfQues && (
        <AddSetNewQuestions
          typeModal={showAddSetOfQues}
          title={t("FAQ.add_a_new_set_of_questions")}
          setShowModal={setShowAddSetOfQues}
          setList={handleAddNewCategoryData}
        />
      )}

      {showEditSetOfQues && (
        <AddSetNewQuestions
          typeModal={showEditSetOfQues}
          title={t("FAQ.edit_a_new_set_of_questions")}
          edit={true}
          editID={showCategoryById.id}
          titleAR={showCategoryById.title_ar}
          titleEN={showCategoryById.title_en}
          setShowModal={setShowEditSetOfQues}
          setList={setCategotyData}
        />
      )}

      {showAddQues && (
        <AddSetNewQuestions
          title={t("FAQ.add_new_question")}
          categoryId={categoryId}
          setShowModal={setShowAddQues}
          setList={handleAddNewQuestionData}
        />
      )}

      {showEditQues && (
        <AddSetNewQuestions
          title={t("FAQ.edit_new_question")}
          categoryId={categoryId}
          edit={true}
          editID={showQuestionById.id}
          questionEN={showQuestionById.question_en}
          questionAR={showQuestionById.question_ar}
          answerEN={showQuestionById.answer_en}
          answerAR={showQuestionById.answer_ar}
          setShowModal={setShowEditQues}
          setList={setQuestionData}
        />
      )}

      {showDeleteModal && (
        <SharedModal
          icon={DeleteIcon}
          title={
            faqIdDeleteQuestion
              ? t("FAQ.when_deleting_question")
              : t("FAQ.when_deleting_group")
          }
          name={selectedQes.name}
          confirmMsg={
            faqIdDeleteQuestion
              ? t("FAQ.delete_msg_question")
              : t("FAQ.delete_msg_group")
          }
          confirmText={t("Exhibition.confirm")}
          cancelText={t("Exhibition.cancel")}
          onCancel={() => {
            setShowDeleteModal(false);
            setfaqIdDeleteQuestion(null);
          }}
          onConfirm={() => {
            deleteExhibitionHandler();
          }}
          isLoading={iseDeleteLoading}
        />
      )}
    </div>
  );
};

export default FAQ;
