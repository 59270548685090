export const peymentRequestsConstants = {
  // GET ALL PEYMENTREQUESTS LIST
  GET_PEYMENTREQUESTS_LIST_REQUEST: "GET_PEYMENTREQUESTS_LIST_REQUEST",
  GET_PEYMENTREQUESTS_LIST_SUCCESS: "GET_PEYMENTREQUESTS_LIST_SUCCESS",
  GET_PEYMENTREQUESTS_LIST_REJECTED: "GET_PEYMENTREQUESTS_LIST_REJECTED",

  // DELETE PEYMENTREQUEST
  DELETE_PEYMENTREQUEST_REQUEST: "DELETE_PEYMENTREQUEST_REQUEST",
  DELETE_PEYMENTREQUEST_SUCCESS: "DELETE_PEYMENTREQUEST_SUCCESS",
  DELETE_PEYMENTREQUEST_REJECTED: "DELETE_PEYMENTREQUEST_REJECTED",

  // UPDATE PEYMENTREQUEST STATUS
  UPDATE_PEYMENTREQUEST_STATUS_REQUEST: "UPDATE_PEYMENTREQUEST_STATUS_REQUEST",
  UPDATE_PEYMENTREQUEST_STATUS_SUCCESS: "UPDATE_PEYMENTREQUEST_STATUS_SUCCESS",
  UPDATE_PEYMENTREQUEST_STATUS_REJECTED:
    "UPDATE_PEYMENTREQUEST_STATUS_REJECTED",
};
