import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import i18n from "../../../../../i18n/i18n";
import classes from "./categoryCard.module.css";
import {
  motion,
  useInView,
  useAnimation,
} from "framer-motion/dist/framer-motion";

const CategoryCard = ({ category, img, loading, index, totalCards }) => {
  const history = useHistory();
  const [isHovering, setIsHovering] = useState(false);
  function handleMouseOver() {
    setIsHovering(true);
  }
  function handleMouseOut() {
    setIsHovering(false);
  }

  const ref = useRef(null);
  const isInView = useInView(ref);
  const mainControls = useAnimation();

  // console.log(category.name_en, 0.3 * (index % 3));

  useEffect(() => {
    // console.log(isInView);
    if (isInView && loading) {
      mainControls.start("visible");
      // setisvisible(true);
    }
  }, [isInView, loading]);
  return (
    <motion.div
      ref={ref}
      variants={{
        hidden: { opacity: 0, y: 100 },
        visible: { opacity: 1, y: 0 },
      }}
      onClick={() =>
        history.push({
          pathname: "/training-courses",
          state: { specialty_id: category.id },
        })
      }
      initial="hidden"
      animate={mainControls}
      transition={{
        duration: 0.6,
        delay:
          i18n.language === "ar"
            ? 0.3 * ((totalCards - index - 1) % 3)
            : 0.3 * (index % 3),
        ease: "easeIn",
      }}
      className={`${classes["category-card"]} ${
        isHovering && classes.hovering
      }`}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <div className={classes.texts}>
        <div className={classes["card-top"]}>
          <h3 className={classes["card-title"]}>
            {i18n.language === "ar" ? category.name_ar : category.name_en}
          </h3>
        </div>
        <div className={classes["card-description"]}>
          <p
            dangerouslySetInnerHTML={{
              __html: `${
                category?.description_en?.length > 73
                  ? i18n.language === "ar"
                    ? category.description_ar.slice(0, 73) + " ..."
                    : category.description_en.slice(0, 73) + " ..."
                  : i18n.language === "ar"
                  ? category.description_ar
                  : category.description_en
              }`,
            }}
          ></p>
        </div>
      </div>
      <div className={classes["card-img"]}>
        <img src={category.image || img} alt={category.title} />
      </div>
    </motion.div>
  );
};

export default CategoryCard;
