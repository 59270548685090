import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { toast } from "react-toastify";
import CustomButton from "../../../Modules/Shared/Components/CustomButton/CustomButton";
import MainTable from "../../../Modules/Shared/Components/MainTable/MainTable";
import SkeletonCardOverlay from "../../../Modules/Shared/Components/Spinner/SkeletonCardOverlay";
import SkeletonSpinnerOverlay from "../../../Modules/Shared/Components/Spinner/SkeletonSpinnerOverlay";
import { RestrictionProviderContext } from "../../../Providers/RestrictionProvider";
import { addRestrictionLesson, getRestrictions } from "../../../Services/api/courses/courseProvider";
import DeleteIcon from "../../../assets/icons/delete.svg";
import { DateRestriction } from "../DateRestriction/DateRestriction";
import { Prerequisites } from "../Prerequisites/Prerequisites";
import { UserRestriction } from "../UserRestriction/UserRestriction";

function RestrictionModal({ dismissModal, lessonId, lessonOptions, editLessonLocal }) {
  require("./Modal.css");
  const { t } = useTranslation();

  const restrictionContextProvider = useContext(RestrictionProviderContext);

  const [types, setTypes] = useState([
    { id: 1, type: "pre-request lessons", typeAr: "دروس معتمدة" },
    { id: 2, type: "user", typeAr: "متدرب" },
    { id: 3, type: "date", typeAr: "تاريخ" },
  ]);
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [selectedType, setSelectedType] = useState(null);
  const [authorizedUsers, setAuthorizedUsers] = useState([]);
  const [preRequestCourses, setPreRequestCourses] = useState([]);
  const [lessonStartDate, setLessonStartDate] = useState("");
  const [lessonEndDate, setLessonEndDate] = useState("");

  const userRestrictionRef = useRef();
  const prerequisitesRestrictionRef = useRef();
  const dateRestrictionRef = useRef();

  const fetchRestrictionData = () => {
    const myPromise = new Promise((resolve, reject) => {
      getRestrictions(lessonId).then(
        (res) => {
          res.data.restrictions.forEach((restrictionItem) => {
            if (restrictionItem.type == "user") {
              setAuthorizedUsers(restrictionItem.data);
            } else if (restrictionItem.type == "date") {
              if (restrictionItem.data.start_date) {
                setLessonStartDate(restrictionItem.data.start_date);
              }

              if (restrictionItem.data.end_date) {
                setLessonEndDate(restrictionItem.data.end_date);
              }
            } else if (restrictionItem.type == "requirement") {
              setPreRequestCourses(restrictionItem.data);
            }
          });

          resolve();
        },
        (error) => {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
          reject();
        }
      );
    });

    return myPromise;
  };

  useEffect(() => {
    restrictionContextProvider.setLessonId(lessonId);
    async function fetchData() {
      try {
        await fetchRestrictionData();
        setIsFetchingData(false);
      } catch (error) {}
    }

    fetchData();
  }, []);

  const getPayloadBasedOnType = () => {
    if (selectedType == 1) {
      return prerequisitesRestrictionRef.current.getPrerequisites();
    } else if (selectedType == 2) {
      return userRestrictionRef.current.getSelectedUsers();
    } else if (selectedType == 3) {
      return dateRestrictionRef.current.getDateRestrictionPayload();
    }
  };

  const generateSuccessMessage = () => {
    if (selectedType == 1) {
      toast.success(t("trainer.course.lesson.restriction.success.lesson"));
    } else if (selectedType == 2) {
      toast.success(t("trainer.course.lesson.restriction.success.user"));
    } else if (selectedType == 3) {
      toast.success(t("trainer.course.lesson.restriction.success.date"));
    }
  };

  const columns = [
    {
      id: "permittedUsers",
      name: t("trainer.course.lesson.restriction.permittedUsers"),
      key: "permittedUsers",
      cell: ({ rowData }) => (
        <ul style={{ marginTop: 10 }}>
          {rowData.authorizedUsers.map((item) => (
            <li key={item.id}>{item.name}</li>
          ))}
        </ul>
      ),
    },
    {
      id: "prerequisites",
      name: t("trainer.course.lesson.restriction.prerequisites"),
      key: "prerequisites",
      cell: ({ rowData }) => (
        <ul style={{ marginTop: 10 }}>
          {rowData.preRequestCourses.map((item) => (
            <li key={item.id}>{item.name}</li>
          ))}
        </ul>
      ),
    },
  ];

  return (
    <>
      <div
        className={"restrictions-modal"}
        style={{ position: "relative" }}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        {restrictionContextProvider.isPerformingBackgroundOperation ? <SkeletonCardOverlay borderRadius={15} /> : null}
        <div className={"tajah-basic-modal-header"}>
          <h4>{t("trainer.course.lesson.restriction.title")}</h4>
          <div onClick={dismissModal} className={"tajah-basic-modal-close"}>
            <img src={DeleteIcon} alt="close" />
          </div>
        </div>

        <div className={"tajah-basic-modal-body-restriction"}>
          <Select
            name="type_id"
            id="type_id"
            options={types}
            getOptionLabel={(option) => (document.body.dir == "rtl" ? option.typeAr : option.type)}
            getOptionValue={(option) => option.id}
            onChange={(item) => {
              setSelectedType(item.id);
              if (item.id == 1 || item.id == 2) {
                restrictionContextProvider.setIsPerformingBackgroundOperation(true);
              }
            }}
            placeholder={t("crud.placeholders.select.type")}
          />

          {selectedType == 1 ? (
            <Prerequisites ref={prerequisitesRestrictionRef} selectedPrerequisites={preRequestCourses} />
          ) : selectedType == 2 ? (
            <UserRestriction ref={userRestrictionRef} selectedUsers={authorizedUsers} />
          ) : selectedType == 3 ? (
            <DateRestriction ref={dateRestrictionRef} startDate={lessonStartDate} endDate={lessonEndDate} />
          ) : null}

          {isFetchingData ? (
            <SkeletonSpinnerOverlay backdrop={true} skeletonWidth="100" />
          ) : (
            // <>
            //   <div className={"tajah-restriction-modal-data-section"}>
            //     <div className={"tajah-restriction-modal-data-section-user-partition"}>
            //       <div>
            //         <span>{t("trainer.course.lesson.restriction.permittedUsers")} :</span>
            //       </div>
            //       <ul style={{ marginTop: 10 }}>
            //         {authorizedUsers.map((item) => (
            //           <li key={item.id}>{item.name}</li>
            //         ))}
            //       </ul>
            //     </div>
            //     <div className={"tajah-restriction-modal-data-section-prerequisties-partition"}>
            //       <div>
            //         <span>{t("trainer.course.lesson.restriction.prerequisites")} :</span>
            //       </div>
            //       <ul style={{ marginTop: 10 }}>
            //         {preRequestCourses.map((item) => (
            //           <li key={item.id}>{item.name}</li>
            //         ))}
            //       </ul>
            //     </div>
            //   </div>
            //   <div className={"tajah-restriction-modal-data-section"} style={{ marginTop: 0 }}>
            //     <div
            //       className={"tajah-restriction-modal-data-section-user-partition"}
            //       style={{ paddingTop: 20, paddingBottom: 20 }}
            //     >
            //       <div>
            //         <span>{t("trainer.course.lesson.restriction.startDateRestricted")} :</span>
            //       </div>
            //       {lessonStartDate ? (
            //         <ul style={{ marginTop: 10 }}>
            //           <li>{lessonStartDate}</li>
            //         </ul>
            //       ) : null}
            //     </div>
            //     <div
            //       className={"tajah-restriction-modal-data-section-prerequisties-partition"}
            //       style={{ paddingTop: 20, paddingBottom: 20 }}
            //     >
            //       <div>
            //         <span>{t("trainer.course.lesson.restriction.endDateRestricted")} :</span>
            //       </div>
            //       {lessonEndDate ? (
            //         <ul style={{ marginTop: 10 }}>
            //           <li>{lessonEndDate}</li>
            //         </ul>
            //       ) : null}
            //     </div>
            //   </div>
            // </>
            <div style={{ marginTop: 16 }}>
              <MainTable
                columns={columns}
                data={[
                  {
                    authorizedUsers: authorizedUsers,
                    preRequestCourses: preRequestCourses,
                  },
                ]}
                isLoading={false}
              />
            </div>
          )}
        </div>
        <div className={"restrictions-modal-footer"}>
          {/* <button
            style={{
              marginLeft: document.body.dir == "rtl" ? 10 : 0,
              marginRight: document.body.dir == "ltr" ? 10 : 0,
            }}
            className={"btn btn-success"}
            onClick={() => {
              const payload = getPayloadBasedOnType();
              if (payload != null) {
                restrictionContextProvider.setIsPerformingBackgroundOperation(true);
                addRestrictionLesson(payload, lessonId).then(
                  async (res) => {
                    if (res.status || res.status == 200 || res.errNum == 201 || res.data.status) {
                      await fetchRestrictionData();
                      generateSuccessMessage();
                      dismissModal();
                      restrictionContextProvider.setIsPerformingBackgroundOperation(false);
                    } else {
                      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
                      restrictionContextProvider.setIsPerformingBackgroundOperation(false);
                    }
                  },
                  (error) => {
                    toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
                    restrictionContextProvider.setIsPerformingBackgroundOperation(false);
                  }
                );
              }
            }}
          >
            {t("modal.common.confirm")}
          </button>
          <button type={"button"} onClick={dismissModal} className={"btn"}>
            {t("modal.common.cancel")}
          </button> */}
          <CustomButton
            value={t("modal.common.confirm")}
            type={"button"}
            variant={"filled"}
            colors={"#036c77"}
            action={() => {
              const payload = getPayloadBasedOnType();
              if (payload != null) {
                restrictionContextProvider.setIsPerformingBackgroundOperation(true);
                addRestrictionLesson(payload, lessonId).then(
                  async (res) => {
                    if (res.status || res.status == 200 || res.errNum == 201 || res.data.status) {
                      await fetchRestrictionData();
                      generateSuccessMessage();
                      dismissModal();
                      restrictionContextProvider.setIsPerformingBackgroundOperation(false);
                    } else {
                      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
                      restrictionContextProvider.setIsPerformingBackgroundOperation(false);
                    }
                  },
                  (error) => {
                    toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
                    restrictionContextProvider.setIsPerformingBackgroundOperation(false);
                  }
                );
              }
            }}
            classes={"restrictions-modal-footer-btn"}
          />
          <CustomButton
            value={t("modal.common.cancel")}
            type={"button"}
            variant={"outlined"}
            colors={"#036c77"}
            action={dismissModal}
            classes={"restrictions-modal-footer-btn"}
          />
        </div>
      </div>
    </>
  );
}

export default RestrictionModal;
