import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import classes from "./sponsorsSlider.module.css";
import "./sponsorsSlider.css";
const SponsorsSlider = ({ sponsorsList }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    arrows: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings} className="sponsors-list">
      {sponsorsList.map((sponsor, index) => (
        <div className={classes["sponsors-slider-item"]} key={index}>
          <div className={classes["sponsors-slider-item-image"]}>
            <img src={sponsor.user.image} alt="" />
          </div>
          <div className={classes["sponsors-slider-item-name"]}>
            {sponsor.user.name}
          </div>
          <div className={classes["sponsors-slider-item-booth"]}>
            {sponsor.booth_name}
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default SponsorsSlider;
