import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getlivechatSchedule } from "../../../Redux/Actions/livechat/livechat.action";
import HomeIcon from "../../../assets/icons/home.svg";
import i18n from "../../../i18n/i18n";
import { Lang } from "../../../utils";
import Breadcrumb from "../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../Shared/Components/MainBoxHead/MainBoxHead";
import MainTable from "../../Shared/Components/MainTable/MainTable";
import classes from "./schedule.module.css";
import { utcToLocal } from "../../../utils/utcToLocal";

const Schedule = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { livechat_schedule } = useSelector((state) => state.livechatReducer);
  const [schedule, setSchedule] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "chat",
      page: t("chat.title"),
      pagePath: "/livechat",
    },
    {
      id: "schedule",
      page: t("chat.schedule"),
      active: true,
    },
  ];

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData }) => <>#</>,
    },
    {
      id: "days",
      name: t("chat.workingdays"),
      key: "img",
      cell: ({ rowData }) => (
        <div>
          {" "}
          {i18n.language === Lang.AR ? rowData.label_ar : rowData.label_en}
        </div>
      ),
      sortable: false,
    },
    {
      id: "start",
      name: t("chat.start"),
      key: "img",
      cell: ({ rowData }) => <div>{utcToLocal(rowData.start)}</div>,
      sortable: false,
    },
    {
      id: "end",
      name: t("chat.end"),
      key: "end",
      cell: ({ rowData }) => <div>{utcToLocal(rowData.end)}</div>,
      sortable: false,
    },
  ];

  useEffect(() => {
    dispatch(getlivechatSchedule(id));
  }, []);

  useEffect(() => {
    if (livechat_schedule.status === 200) {
      setSchedule(livechat_schedule.data.user.working_plan);
      setIsLoading(false);
    }
  }, [livechat_schedule]);

  return (
    <div className={`${classes["chat_list"]} ${classes.mb30px} `}>
      <div className="container-fluid">
        <div className={classes["chat_list__container"]}>
          <div className={`row ${classes.breadcrumb}`}>
            <Breadcrumb list={breadcrumbList} />
          </div>
          <div className="row">
            <div className="col-12">
              <MainBox>
                <MainBoxHead title={t("chat.schedule")} />
                <MainTable
                  columns={columns}
                  data={schedule}
                  isLoading={isLoading}
                />
              </MainBox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Schedule;
