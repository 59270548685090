export default function Title({ title, titleColor = "#066d77", fontSize }) {
  return (
    <h5
      style={{
        color: titleColor,
        fontWeight: "bold",
        fontSize: `${fontSize}px`,
      }}
    >
      {title}
    </h5>
  );
}
