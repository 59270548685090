import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../../../../Services/api/auth/AuthContext";
import logoVision2030 from "../../../../assets/image/Saudi_Vision_2030_logo.svg.png";
import DynamicHeaderWithLogin from "./DynamicHeaderWithLogin";
import DynamicHeaderWithOutLogin from "./DynamicHeaderWithoutLogin/DynamicHeaderWithOutLogin";
import Arrow from "../../../../assets/icons/ArrowDownGreen.svg";
import HeaderLogo from "../../../../assets/icons/newHeader2024.svg";
// import HeaderLogo from "../../../../assets/image/Header.png";
import { useLocation } from "react-router-dom";
import adminDoc from "../../../../assets/image/admin-doc.png";
import notification from "../../../../assets/image/notification.png";
import { Lang } from "../../../../utils";

import { useSelector } from "react-redux";
import BurgerMenu from "../../../../assets/icons/burger-menu.svg";
import MainDrawer from "./MainDrawer/MainDrawer";
import SearchInput from "./SearchInput";
import BurgerMenuIcon from "../../../../assets/icons/BurgerMenu.svg";
import classes from "./Links.module.css";

function Links(props) {
  require("./header.css");
  const { t, i18n } = useTranslation();
  const type_login = localStorage.getItem("type_login");
  const { livechat_setting } = useSelector((state) => state.livechatReducer);
  const authContext = useContext(AuthContext);
  document.body.dir = i18n.dir();
  const [chatAllowed, setChatAllowed] = useState();
  const [showDrawer, setShowDrawer] = useState(false);
  const [addAnimationSide, setAddAnimationSide] = useState(false);

  const [width, setWidth] = useState(0);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWidth]);
  const location = useLocation();

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("tokenChat");
    localStorage.removeItem("name");
    localStorage.removeItem("email");
    localStorage.removeItem("is_admin");
    localStorage.removeItem("roles");
    localStorage.removeItem("name_er");
    localStorage.removeItem("name_en");
    localStorage.removeItem("img");
    localStorage.removeItem("type");
    localStorage.removeItem("phone");
    localStorage.removeItem("completed_profile");
    localStorage.removeItem("type_login");
    localStorage.removeItem("userId");
    localStorage.removeItem("chatId");
    localStorage.removeItem("chat_username");
    localStorage.removeItem("chat_email");

    authContext.setAuth({});
    authContext.setRoles([]);
    props.history.push("/");
  };

  const toggleDrawerHandler = (event) => {
    event.preventDefault();
    setShowDrawer((prev) => !prev);
  };

  const onConfirm = () => {
    setAddAnimationSide(true);
    setTimeout(() => {
      setShowDrawer(false);
    }, 300);
  };

  useEffect(() => {
    if (showDrawer) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [showDrawer]);

  useEffect(() => {
    if (livechat_setting.status === 200) {
      setChatAllowed(livechat_setting.data.setting.chat_allowed);
    }
  }, [livechat_setting]);

  return (
    <div className={classes["header_shadow"]}>
      {showDrawer && (
        <>
          <MainDrawer
            addAnimationSide={addAnimationSide}
            setAddAnimationSide={setAddAnimationSide}
            dismissSideBar={onConfirm}
            roles={authContext.roles}
            chatAllowed={props.chatAllowed}
          />
          <div className={classes.backdrop_main} onClick={onConfirm} />
        </>
      )}
      <div className={`container-fluid`}>
        <div className="row">
          <>
            {location.pathname === "/" && width <= 1050 && (
              <div className={classes.imageContMobile}>
                <NavLink exact to="/">
                  <img
                    src={HeaderLogo}
                    className={classes.image_mobile}
                    alt="..."
                  />
                </NavLink>
              </div>
            )}
          </>
        </div>
        <div className="row">
          <div className="col-12">
            <nav className={classes.mainNav}>
              <div className={classes.navbar_logo_main}>
                {width <= 1200 && (
                  <div
                    className={classes["hamburger-pointer"]}
                    onClick={toggleDrawerHandler}
                  >
                    <img
                      src={BurgerMenuIcon}
                      alt=""
                      style={{
                        transform: `${
                          i18n.language === "ar" && "rotateY(180deg)"
                        }`,
                        width: 30,
                        minWidth: 30,
                        height: 40,
                        minHeight: 40,
                      }}
                    />
                  </div>
                )}
                {width > 1050 && (
                  <NavLink exact to="/">
                    <img
                      src={HeaderLogo}
                      className={classes.image_logo_new}
                      alt="..."
                    />
                  </NavLink>
                )}
              </div>
              <div className={classes.flex_menu}>
                {location.pathname === "/" && width > 710 && (
                  <>
                    <SearchInput />
                  </>
                )}
                {!authContext.auth.token ? (
                  <DynamicHeaderWithLogin />
                ) : (
                  <DynamicHeaderWithOutLogin
                    NotAdmin={true}
                    chatAllowed={props.chatAllowed}
                  />
                )}
              </div>
            </nav>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {location.pathname === "/" && width <= 710 && (
              <div
                style={{
                  width: "100%",
                  marginBottom: "15px",
                  marginTop: "0px",
                }}
              >
                <SearchInput />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Links;

//  <div className="userProfile">
//    {authContext.auth.token ? (
//      <>
//        <div className="dropdown">
//          <button
//            type="button"
//            className="btn dropdown-toggle header-dropdown header_nav_link "
//            data-toggle="dropdown"
//            style={{
//              color: "#fff",
//              display: "flex",
//              alignItems: "center",
//              gap: 3,
//            }}
//          >
//            {localStorage.getItem("img") == "null" ? (
//              <img src={adminDoc} alt="" className="admin_img" />
//            ) : (
//              <img
//                src={localStorage.getItem("img")}
//                alt="..."
//                className="admin_img"
//              />
//            )}
//            <p style={{ color: "#006d77" }}>
//              {i18n.language === Lang.AR
//                ? localStorage.getItem("name_ar") != null
//                  ? localStorage.getItem("name_ar").split(" ")[0]
//                  : null
//                : localStorage.getItem("name_en") != null
//                ? localStorage.getItem("name_en").split(" ")[0]
//                : null}
//            </p>
//            <img
//              src={Arrow}
//              alt=""
//              style={{ width: 8, height: 8, marginTop: 4 }}
//            />
//          </button>
//          <div className="dropdown-menu">
//            {/* todo: */}
//            {authContext.roles.includes("admin") ? (
//              <NavLink className="dropdown-item" to="/admin">
//                {t("admin.label.dashboard")}
//              </NavLink>
//            ) : authContext.roles.includes("accountant") ? (
//              <NavLink className="dropdown-item" to="/accountant">
//                {t("admin.label.dashboard")}
//              </NavLink>
//            ) : null}
//            <NavLink className="dropdown-item" to="/edit-profile">
//              {t("edit_personal_profile")}
//            </NavLink>
//            <NavLink className="dropdown-item" to="/private-profile">
//              {t("account_info")}
//            </NavLink>
//            {type_login === "normal" ? (
//              <NavLink className="dropdown-item" to="/update-password">
//                {t("accountde")}
//              </NavLink>
//            ) : null}
//            <NavLink
//              className="dropdown-item"
//              to={
//                localStorage.getItem("type") === "admin"
//                  ? "/admin/tickets"
//                  : "/tickets"
//              }
//            >
//              {t("admin.label.list_tickets")}
//            </NavLink>
//            <button className="dropdown-item" type="submit" onClick={logout}>
//              {t("logout")}
//            </button>
//          </div>
//        </div>
//      </>
//    ) : null}
//  </div>;

// <div className="collapse navbar-collapse" id="navbarSupportedContent">
//   <ul className="navbar-nav">
//     <li
//       className="nav-item"
//       data-toggle="collapse"
//       data-target="#navbarSupportedContent"
//     >
//       <NavLink to="/" className="nav-item nav-link header_nav_links ">
//         {t("Main")}
//       </NavLink>
//     </li>
//     <li
//       className="nav-item"
//       data-toggle="collapse"
//       data-target="#navbarSupportedContent"
//     >
//       <NavLink
//         to="/about-us"
//         className="nav-item nav-link header_nav_links "
//       >
//         {t("AboutUs")}
//       </NavLink>
//     </li>

//     <li
//       className="nav-item"
//       data-toggle="collapse"
//       data-target="#navbarSupportedContent"
//     >
//       <NavLink
//         to="/training-courses"
//         className="nav-item nav-link header_nav_links "
//       >
//         {t("courses")}
//       </NavLink>
//     </li>
//     <li
//       className="nav-item"
//       data-toggle="collapse"
//       data-target="#navbarSupportedContent"
//     >
//       <NavLink
//         to="/training-programs"
//         className="nav-item nav-link header_nav_links "
//       >
//         {t("program")}
//       </NavLink>
//     </li>
//     <li
//       className="nav-item"
//       data-toggle="collapse"
//       data-target="#navbarSupportedContent"
//     >
//       <NavLink
//         to="/trainers"
//         className="nav-item nav-link header_nav_links "
//       >
//         {t("ProfessionalTrainers")}
//       </NavLink>
//     </li>
//     <li
//       className="nav-item"
//       data-toggle="collapse"
//       data-target="#navbarSupportedContent"
//     >
//       <NavLink
//         to="/Exhibition"
//         className="nav-item nav-link header_nav_links "
//       >
//         {t("Exhibition.Exhibition")}
//       </NavLink>
//     </li>
//     <li
//       className="nav-item"
//       data-toggle="collapse"
//       data-target="#navbarSupportedContent"
//     >
//       <NavLink
//         to="/general-surveys"
//         className="nav-item nav-link header_nav_links "
//       >
//         {t("sidebar.label.generalSurveys")}
//       </NavLink>
//     </li>
//     {authContext.roles.includes("trainer") ||
//     authContext.roles.includes("trainee") ||
//     authContext.roles.includes("sponser") ||
//     (authContext.roles.includes("accountant") &&
//       JSON.parse(
//         localStorage.getItem("system_preferences")
//           ? localStorage.getItem("system_preferences")
//           : "{}"
//       ).support_tickets_service) ? (
//       <li
//         className="nav-item"
//         data-toggle="collapse"
//         data-target="#navbarSupportedContent"
//       >
//         <NavLink
//           to="/tickets"
//           className="nav-item nav-link header_nav_links "
//         >
//           {t("sidebar.label.support_tickets")}
//         </NavLink>
//       </li>
//     ) : null}

//     <li
//       className="nav-item"
//       data-toggle="collapse"
//       data-target="#navbarSupportedContent"
//     >
//       <NavLink
//         to="/contactUs"
//         className="nav-item nav-link header_nav_links"
//       >
//         {t("ConnectUs")}
//       </NavLink>
//     </li>
//     <li
//       className="nav-item"
//       data-toggle="collapse"
//       data-target="#navbarSupportedContent"
//     >
//       <NavLink
//         to="/terms-and-conditions"
//         className="nav-item nav-link header_nav_links"
//       >
//         {t("TermsAndConditions")}
//       </NavLink>
//     </li>
//     {chatAllowed && (
//       <li
//         className="nav-item"
//         data-toggle="collapse"
//         data-target="#navbarSupportedContent"
//       >
//         <NavLink
//           to="/livechat"
//           className="nav-item nav-link header_nav_links"
//         >
//           {t("sidebar.label.live_chat")}
//         </NavLink>
//       </li>
//     )}
//     <li>
//       <div>
//         {!authContext.auth.token ? (
//           <DynamicHeaderWithLogin />
//         ) : (
//           <DynamicHeaderWithOutLogin />
//         )}
//       </div>
//     </li>
//   </ul>
// </div>;
