import connector from "../../../Services/api/Handler";
import { fundConstant } from "../../Constants/fund/fund.constant";
import env from "../../../Services/api/Environment";
import { toast } from "react-toastify";

export function getFundList(filter) {
  return (dispatch) => {
    dispatch({
      type: fundConstant.START_LOADING,
    });
    connector
      .get(env.payment_statistics, {
        params: {
          ...filter,
        },
      })
      .then((res) => {
        dispatch({
          type: fundConstant.GET_FUND,
          payload: res,
        });
        dispatch({
          type: fundConstant.STOP_LOADING,
        });
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.message}
          </span>
        );
        dispatch({
          type: fundConstant.STOP_LOADING,
        });
      });
  };
}
