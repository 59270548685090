const QuestionTypeEnum = {
  text: 5,
  radio_button: 1,
  checkbox: 2,
  ordering: 4,
  hotspot: 6,
  h5p: 7,
  html: 8,
  drag_and_drop: 3,
  scorm: 10,
  xapi: 11,
  essay_question: 9,
  video: 12,
  pdf: 13,
  audio: 14,
  text_1: 15,
  file: 16,
};

export const QuestionTypeWithOptions = [
  QuestionTypeEnum.checkbox,
  QuestionTypeEnum.radio_button,
  QuestionTypeEnum.ordering,
  QuestionTypeEnum.drag_and_drop,
];
export const QuestionTypeWithFile = [
  QuestionTypeEnum.scorm,
  QuestionTypeEnum.h5p,
  QuestionTypeEnum.xapi,
  QuestionTypeEnum.html,
];

export default QuestionTypeEnum;
