import { useTranslation } from "react-i18next";
import FilterIcon from "../../../../assets/icons/filter.svg";
import PdfIcon from "../../../../assets/icons/pdf.svg";
import XlsIcon from "../../../../assets/icons/xls.svg";

export const FilterActionsRefund = ({ showFilter, exportPdf, exportExcel, refundSetting }) => {
  const { t } = useTranslation();
  const actionsList = [
    ...(refundSetting
      ? [
          {
            id: "type",
            type: "button",
            label: t("sidebar.label.refund-setting"),
            color: "#8599B6",
            action: refundSetting,
          },
        ]
      : []),
    {
      id: "filter",
      icon: <img src={FilterIcon} alt="" />,
      action: showFilter,
    },
    {
      id: "pdf",
      icon: <img src={PdfIcon} alt="" />,
      theme: "pdf",
      action: exportPdf,
    },
    {
      id: "xls",
      icon: <img src={XlsIcon} alt="" />,
      theme: "xls",
      action: exportExcel,
    },
  ];
  return actionsList;
};
