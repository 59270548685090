export const trainerProfileConstant = {
  // loading
  START_LOADING: "START_LOADING",
  STOP_LOADING: "STOP_LOADING",

  GET_CLASSIFICATION: "GET_CLASSIFICATION",
  GET_TRAINER: "GET_TRAINER",
  GET_TRAINER_DETAILS: "GET_TRAINER_DETAILS",
  GET_CATEGORY: "GET_CATEGORY",
  GET_TRAINER_COURSE: "GET_TRAINER_COURSE",
  RESET_TRAINER: "RESET_TRAINER",
};
