import React from "react";
import classes from "./progressBar.module.css";

export default function ProgressBar({ progress, title, color }) {
  return (
    <div className={classes["progress-bar-container"]}>
      <div className="tw-whitespace-nowrap text-sm">{title}</div>
      <div className={classes["progress-bar"]}>
        <div className={classes["progress-bar-progress"] + " progress"}>
          <div
            className="progress-bar"
            role="progressbar"
            style={{
              width: Number(progress) + "%",
              backgroundColor: color,
            }}
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        <div className="tw-whitespace-nowrap text-sm">{Number(progress) + "%"}</div>
      </div>
    </div>
  );
}
