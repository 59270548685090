import { Pagination } from "@mui/material";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IconsTooltip } from "../../../../Components";
import toLocalTimezone from "../../../../Services/Timezone";
import {
  exportParticipants,
  exportSurvies,
  getParticipantsSurvey,
} from "../../../../Services/api/survey/SurveyProvider";
import ViewExhDetailsIcon from "../../../../assets/icons/eye.svg";
import FilterIcon from "../../../../assets/icons/filter.svg";
import HomeIcon from "../../../../assets/icons/home.svg";
import PdfIcon from "../../../../assets/icons/pdf.svg";
import XlsIcon from "../../../../assets/icons/xls.svg";
import { utcToLocal } from "../../../../utils/utcToLocal";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import FilterBox from "../../../Shared/Components/FilterBox/FilterBox";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import { BasicModal } from "../../../Shared/Components/Modal/BasicModal";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import classes from "./exhibitionList.module.css";

function SurveyParticipants({ role, isTrainer, fromTrainer = false, isAll }) {
  require("./list.css");

  const { t } = useTranslation();
  const { id, targetCourseId, targetLessonId } = useParams();

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [meta, setMeta] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);

  const [participants, setParticipants] = useState([]);
  const [surveyFilter, setSurveyFilter] = useState({});

  const [survey, setSurvey] = useState({});

  const [sortColumns, setSortColumns] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFilter, setIsFilter] = useState(false);
  const [fetchFilterData, setFetchFilterDate] = useState(false);

  const filterChangeHandler = (e) => {
    const { name, value } = e.target;
    setSurveyFilter({ ...surveyFilter, [name]: value });
  };

  const actionsList = [
    {
      id: "filter",
      icon: <img src={FilterIcon} alt="" />,
      action: () => {
        setFetchFilterDate(true);
        if (!fetchFilterData) {
          fetchDataForFilter();
        }
        setIsFilter((val) => !val);
      },
    },
    {
      id: "pdf",
      icon: <img src={PdfIcon} alt="" />,
      theme: "pdf",
      action: () => {
        setIsUpdating(true);
        exportParticipants("pdf", id, surveyFilter)
          .then((res) => {
            if (res.status && res.status === 200) {
              setIsUpdating(false);
              toast.success(
                <span style={{ fontSize: 13, fontWeight: "bold" }}>
                  {t("export_success")}
                </span>
              );
            } else {
              throw res;
            }
          })
          .catch((err) => {
            setIsUpdating(false);
            toast.error(
              <span style={{ fontSize: 13, fontWeight: "bold" }}>
                {t("export_faild")}
              </span>
            );
          });
      },
    },
    {
      id: "xls",
      icon: <img src={XlsIcon} alt="" />,
      theme: "xls",
      action: () => {
        setIsUpdating(true);
        exportParticipants("xlsx", id, surveyFilter)
          .then((res) => {
            if (res.status && res.status === 200) {
              setIsUpdating(false);
              toast.success(
                <span style={{ fontSize: 13, fontWeight: "bold" }}>
                  {t("export_success")}
                </span>
              );
            } else {
              throw res;
            }
          })
          .catch((err) => {
            setIsUpdating(false);
            toast.error(
              <span style={{ fontSize: 13, fontWeight: "bold" }}>
                {t("export_faild")}
              </span>
            );
          });
      },
    },
  ];

  const participantActionsList = [
    {
      id: "view-details",
      icon: <img src={ViewExhDetailsIcon} alt="" />,
      title: t("answers"),
      action: (rowId) => {
        history.push(
          `/${role}/survey${
            fromTrainer ? "/trainer" : ""
          }/${id}/participants/${rowId}`
        );
      },
    },
  ];

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => (
        <>{rowIndex + 1 + (meta.currentPage - 1) * 10}</>
      ),
    },
    {
      id: "image",
      name: t("Exhibition.photo"),
      key: "img",
      cell: ({ rowData }) =>
        rowData.user.img ? (
          <div className=" tw-h-16 tw-w-16 tw-shrink-0 tw-mx-auto">
            <img
              src={rowData.user.img}
              className="tw-rounded-full tw-h-full tw-aspect-square"
            />
          </div>
        ) : (
          <></>
        ),
    },
    {
      id: "name",
      name: t("username"),
      key: "name",
      sortable: true,
      cell: ({ rowData }) => (
        <span className="cell-font tw-w-36 text2lines">
          {rowData.name ?? rowData.user.name}
        </span>
      ),
    },
    {
      id: "email",
      name: t("email"),
      key: "email",
      cell: ({ rowData }) => (
        <span className="cell-font">{rowData.email ?? rowData.user.email}</span>
      ),
    },
    {
      id: "phone",
      name: t("phone"),
      key: "phone",
      cell: ({ rowData }) => (
        <span className="cell-font">{rowData.phone ?? rowData.user.phone}</span>
      ),
    },
    {
      id: "roles",
      name: t("user_type"),
      key: "roles",
      cell: ({ rowData }) => (
        <div
          style={{
            color: "#066d77",
          }}
        >
          {rowData?.user?.roles.length
            ? rowData.user.roles.map((role, index) => {
                if (index < rowData.user.roles.length - 1) {
                  return t(`selection.role.${role.name}`) + " | ";
                } else {
                  return t(`selection.role.${role.name}`);
                }
              })
            : t("selection.role.guest")}
        </div>
      ),
    },
    {
      id: "createdAt",
      name: t("response_date"),
      key: "createdAt",
      sortable: true,
      cell: ({ rowData }) =>
        rowData.created_at ? (
          <>
            {moment(rowData?.created_at).format("DD/MM/YYYY")}
            <br />
            {utcToLocal(rowData?.created_at)}
          </>
        ) : (
          <></>
        ),
    },
    {
      id: "actions",
      name: t("Exhibition.actions"),
      key: "actions",
      cell: ({ rowData, rowIndex }) => (
        <div
          className={`${classes["exhibition_list__actions"]} tw-flex tw-items-center tw-justify-center`}
        >
          {participantActionsList.map((action, index) => (
            <IconsTooltip
              title={action.title}
              content={
                <button
                  key={action.id}
                  className={classes["exhibition_list__actions-btn"]}
                  onClick={() => {
                    action.action(rowData.id, rowIndex);
                  }}
                >
                  {action.icon}
                </button>
              }
            />
          ))}
        </div>
      ),
    },
  ];

  const filterFields = [
    // User type
    {
      id: "user_type",
      component: "select",
      placeholder: t("crud.placeholders.select.type"),
      label: t("crud.placeholders.select.type"),
      initialValue: t("crud.placeholders.select.type"),
      name: "user_type",
      value: surveyFilter.user_type ?? "",
      classes: "col-12 col-md-2",
      onchange: filterChangeHandler,
      options: [
        {
          id: "guest",
          value: "guest",
          label: t("selection.role.guest"),
        },
        {
          id: "trainer",
          value: "trainer",
          label: t("selection.role.trainer"),
        },
        {
          id: "trainee",
          value: "trainee",
          label: t("selection.role.trainee"),
        },
        {
          id: "sponser",
          value: "sponser",
          label: t("selection.role.sponser"),
        },
      ],
    },
    // search
    {
      id: "search",
      component: "input",
      type: "search",
      placeholder: t("search_by_name_or_email"),
      label: t("search_by_name_or_email"),
      name: "search",
      value: surveyFilter.search ?? "",
      classes: "col-12 col-md-6",
      onReset: () =>
        filterChangeHandler({ target: { value: "", name: "search" } }),
      onchange: filterChangeHandler,
    },
    // reset
    {
      id: "reset",
      component: "button",
      type: "button",
      name: "action",
      value: t(`Exhibition.reset`),
      classes: "col-12 col-md-2",
      backgroundColor: "white",
      color: "rgb(4, 108, 119)",
      borderColor: "rgb(4, 108, 119)",
      action: () => {
        setSurveyFilter({});
        if (currentPage == 1) {
          refetchUsers({});
        } else {
          setCurrentPage(1);
        }
      },
    },
    // submit
    {
      id: "button",
      component: "button",
      type: "button",
      name: "action",
      value: t("Exhibition.search"),
      classes: "col-12 col-md-2",
      action: () => {
        if (currentPage == 1) {
          refetchUsers();
        } else {
          setCurrentPage(1);
        }
      },
    },
  ];

  function fetchDataForFilter() {}

  const fetchUsers = (params = surveyFilter) => {
    // if (isTrainer) {
    //   if (targetCourseId) {
    //     params = { ...params, course_id: targetCourseId };
    //   }
    //   if (targetLessonId) {
    //     params = { ...params, lesson_id: targetLessonId };
    //   }
    // }

    params = { ...params, perPage: 10 };
    setIsUpdating(true);
    const myPromise = new Promise(async (resolve, reject) => {
      try {
        let response = await getParticipantsSurvey(id, params);
        if (response.status == 200) {
          setParticipants(response.data.data.participants);
          setMeta(response.data.data.meta);
          setSurvey(response.data.data.survey);
          setIsLoading(false);
          setIsUpdating(false);
        }
        resolve();
      } catch (e) {
        reject(e);
      }
    });

    return myPromise;
  };

  const sortActionHandler = async (columnKey) => {
    let columns = [...sortColumns];
    let key = columnKey;
    // Map the local key to the backend key
    switch (columnKey) {
      case "name":
        key = "name";
        break;
      case "createdAt":
        key = "created_at";
        break;
    }

    const col = columns.find((c) => c.key == key);
    if (col) {
      columns = [
        {
          key,
          orderBy: col.orderBy == "ASC" ? "DESC" : "ASC",
        },
      ];
    } else {
      columns = [
        {
          key,
          orderBy: "ASC",
        },
      ];
    }
    setSortColumns(columns);
  };

  const refetchUsers = async (params = surveyFilter) => {
    try {
      let customFilter = {
        ...params,
        sortColumns,
        page: currentPage,
      };
      await fetchUsers(customFilter);
    } catch (e) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };

  useEffect(() => {
    refetchUsers();
  }, [currentPage, sortColumns]);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },

    {
      id: t("survey.title"),
      page: t("survey.title"),
      pagePath: isAll
        ? `/${role}/survey/list`
        : `/${role}/course/${survey.course_id}/survey/list`,
    },
    ...(isAll
      ? []
      : [
          {
            id: id,
            page: survey?.name,
            pagePath: `/${role}/course/${survey.course_id}/survey/preview/${id}`,
          },
        ]),
    {
      id: 1,
      page: t("participants"),
      active: true,
    },
  ];

  return (
    <>
      <div className="container-fluid" id={"content-section"}>
        <div className="row">
          <div className="col-12 sm:tw-px-2 tw-py-8">
            {/*TODO*/}
            <Breadcrumb list={breadcrumbList} />
          </div>
        </div>
        {isUpdating ? <SkeletonCardOverlay skeletonWidth="100" /> : <div></div>}
        {!isLoading ? (
          <MainBox>
            <MainBoxHead title={t("Participants_menu")} actions={actionsList} />
            {isFilter && <FilterBox fields={filterFields} />}
            <MainTable
              columns={columns}
              data={participants}
              sortAction={sortActionHandler}
            />
            <Pagination
              count={meta.totalPages}
              page={currentPage}
              showFirstButton
              showLastButton
              variant="outlined"
              shape="rounded"
              className="main-pagination"
              onChange={(e, page) => {
                setCurrentPage(page);
              }}
            />
          </MainBox>
        ) : (
          <div
            className="mt-5"
            style={{
              height: "65vh",
            }}
          >
            <SkeletonCard />
          </div>
        )}
      </div>
    </>
  );
}

export default SurveyParticipants;
