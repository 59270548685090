import { Redirect, Route, Switch } from "react-router-dom";
import routesManager from "../../../../routes/managers/RoutesManagers";

function Manager(props) {

  return (
    <>
      <Switch>
        {routesManager.map((route, index) => (
          <Route
            exact={route.exact}
            path={`${props.match.path + route.path}`}
            component={route.component}
          />
        ))}
        <Redirect to="/page-not-found" />
      </Switch>
    </>
  );
}

export default Manager;
