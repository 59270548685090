import React, { useEffect, useRef, useState } from "react";
import classes from "./LatestCoursesCard.module.css";
import Title from "../../../../Shared/Components/Title/Title";
import CalenderIcon from "../../../../../assets/icons/calenderHome.svg";
import SeatsIcon from "../../../../../assets/icons/seatHome.svg";
import CashIcon from "../../../../../assets/icons/Cash.svg";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { utcToLocal } from "../../../../../utils/utcToLocal";
import {
  motion,
  useInView,
  useAnimation,
} from "framer-motion/dist/framer-motion";

export default function LatestCoursesCard({
  img,
  name,
  seats,
  description,
  start_date,
  price,
  id,
  loading,
  key,
  delay,
  index,
  totalCards,
}) {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [isHovering, setIsHovering] = useState(false);

  function handleMouseOver() {
    setIsHovering(true);
  }
  function handleMouseOut() {
    setIsHovering(false);
  }
  const ref = useRef(null);
  const isInView = useInView(ref);
  const mainControls = useAnimation();

  useEffect(() => {
    // console.log(isInView);
    if (isInView && loading) {
      mainControls.start("visible");
      // setisvisible(true);
    }
  }, [isInView, loading]);
  return (
    <motion.div
      ref={ref}
      variants={{
        hidden: { opacity: 0, y: 100 },
        visible: { opacity: 1, y: 0 },
      }}
      initial="hidden"
      animate={mainControls}
      transition={{
        duration: 0.6,
        delay:
          i18n.language === "ar"
            ? 0.3 * ((totalCards - index - 1) % 3)
            : 0.3 * (index % 3),
        ease: "easeIn",
      }}
      className={`${classes.content} ${isHovering && classes.hovering}`}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <div
        className={classes.imgCont}
        onClick={() => history.push(`/training-courses/${id}/details`)}
      >
        <img src={img} alt={name} className={classes.img} />
      </div>
      <div className={classes.cardContantNew}>
        <div>
          <div className={classes["name"]}>
            <Title
              title={name.length > 51 ? `${name.slice(0, 51)} ...` : name}
              fontSize={15}
              titleColor={"#488B85"}
            />
          </div>
          <div className={classes.sapretor}></div>
          <div className={classes.job_title}>
            <p>
              {description.length > 85
                ? `${description.slice(0, 85)} ...`
                : description}
            </p>
          </div>
        </div>
        <div>
          <div className={classes.num_courses}>
            <div className={classes.numContainer}>
              <img
                src={CalenderIcon}
                alt=""
                style={{ width: 18, height: 18 }}
              />
              {moment(start_date).format("DD-MM-YYYY")}
            </div>
            <span>|</span>
            <div className={classes.numContainer}>
              <img src={SeatsIcon} alt="" style={{ width: 18, height: 18 }} />
              {seats}&nbsp;
              {t("seats")}
            </div>
            <span>|</span>
            <div className={classes.numContainer}>
              <img src={CashIcon} alt="" style={{ width: 18, height: 18 }} />
              {price}&nbsp;
              {t("rs")}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
