import React from "react";
import {useTranslation} from "react-i18next";

function OrderingSelectedAnswers(props) {
    const {t} = useTranslation();

    return(
        <>
            <table className="table table-bordered">
                <thead>
                <tr>
                    <th scope="col">{t("trainer.quiz.crud.question_answer_in_order")}</th>
                </tr>
                </thead>
                <tbody>
                    {
                        props.answers.map((answer, index) => {
                            return (
                                <tr key={index}>
                                    <td>{answer}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </>
    )
}

export default OrderingSelectedAnswers;
