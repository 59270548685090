import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { sendNotificationToGiftSender } from "../../../../Redux/Actions/courses/coursesList.action";
import LockIcon from "../../../../assets/icons/lock.png";
import { getLocaleTime } from "../../../../utils/utcToLocal";
import CourseDetailsHeader from "../../../Shared/Components/CourseDetailsHeader/CourseDetailsHeader";
import courseRowAction from "./courseRowAction";
import classes from "./trainingCourseContent.module.css";

export default function CourseRow({ id, course, program }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [haveAccess, setHaveAccess] = useState(false);
  const userId = localStorage.getItem("userId");

  const badge = {
    text: course?.payment?.is_payment_due
      ? `${t("closed_payment_due")} ${t("general.until")} ${moment(new Date(course?.payment?.end_date)).format(
          "DD/MM/YYYY"
        )} ${t("general.value")} ${course?.payment?.amount} ${t("rs")}`
      : course?.payment?.is_payment_overdue
      ? t("closed_payment_overdue")
      : `${t("closed_payment_not_due")} ${t("general.from")} ${moment(new Date(course?.payment?.payment_date)).format(
          "DD/MM/YYYY"
        )} ${t("general.value")} ${course?.payment?.amount} ${t("rs")}`,
    icon: LockIcon,
    backgroundColor: "#DF4B43",
    textColor: "#fff",
  };

  useEffect(() => {
    if (
      (course?.payment?.is_paid &&
        course?.payment?.payment_status === "Approved" &&
        course?.payment?.user_payments?.length > 0 &&
        course?.payment?.user_payments.filter(
          (payment) => payment.gifted_to === null || (payment.gifted_to === userId && payment.status === "Approved")
        ).length > 0) ||
      Number(program?.price) === 0 ||
      program?.mode === "Free" ||
      Number(program?.my_enrolment?.register_by) === 1
    ) {
      setHaveAccess(true);
    }
  }, [course, program]);

  return (
    <div className={classes[`chapter-row`]}>
      <CourseDetailsHeader
        title={course.name}
        actions={courseRowAction({
          ...(haveAccess &&
            moment(new Date()).isBefore(getLocaleTime(new Date(course.start_date))) && {
              courseNotStarted: () => {},
            }),
          // ...(haveAccess &&
          //   moment(new Date()).isBetween(
          //     getLocaleTime(new Date(course.start_date)),
          //     getLocaleTime(new Date(course.end_date))
          //   ) && {
          //     completeCourse: () =>
          //       history.push(
          //         `/training-courses/${id}/course/${course.id}/content`
          //       ),
          //   }),
          ...(haveAccess &&
            moment(new Date()).isAfter(getLocaleTime(new Date(course.start_date))) && {
              completeCourse: () => history.push(`/training-courses/${id}/course/${course.id}/content`),
            }),
          // ...(haveAccess &&
          //   moment(new Date()).isAfter(
          //     getLocaleTime(new Date(course.end_date))
          //   ) && {
          //     courseEnded: () => {},
          //   }),

          ...(!haveAccess &&
            !program.enrolled_by_gift &&
            course?.payment?.is_payment_due &&
            course?.payment?.payment_status !== "Waiting" &&
            course?.payment?.payment_status !== "Rejected" && {
              completePayment: () => history.push(`/program/${id}/payment`),
            }),
          ...(!haveAccess &&
            course?.payment?.payment_status === "Waiting" && {
              paymentWaiting: () => {},
            }),
          ...(!haveAccess &&
            !program.enrolled_by_gift &&
            course?.payment?.payment_status === "Rejected" && {
              paymentRejected: () => history.push(`/program/${id}/payment`),
            }),
          ...(!haveAccess &&
            program.enrolled_by_gift &&
            course?.payment?.payment_status === "Rejected" && {
              sendNotificationToGiftSenderAfterPaymentRejected: () =>
                dispatch(
                  sendNotificationToGiftSender({
                    id: program?.my_enrolment?.gift_id,
                  })
                ),
            }),
          ...(!haveAccess &&
            program.enrolled_by_gift &&
            course?.payment?.is_payment_due &&
            course?.payment?.payment_status !== "Waiting" &&
            course?.payment?.payment_status !== "Rejected" && {
              sendNotificationToGiftSender: () =>
                dispatch(
                  sendNotificationToGiftSender({
                    id: program?.my_enrolment?.gift_id,
                  })
                ),
            }),
          // ...(!haveAccess &&
          //   program.enrolled_by_gift &&
          //   course?.payment?.payment_status === "Waiting" && {
          //     paymentWaiting: () => {},
          //   }),
        })}
        badge={!haveAccess && badge}
      />
    </div>
  );
}
