import React, { useEffect, useState } from "react";
import LoadingSpinner from "../../Shared/Components/LoadingSpinner/LoadingSpinner";
import classes from "./Statistics.module.css";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale, // y
  Tooltip,
  Legend,
} from "chart.js";
import Title from "../../Shared/Components/Title/Title";
ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale, // y
  Tooltip,
  Legend
);

export default function StatisticsChart({
  chartsData,
  isLoading,
  ticketsData,
  ticketTypes,
  ticketsDataSet,
}) {
  const { t, i18n } = useTranslation();
  const getlabels = (labels) => {
    const years = [];
    labels.forEach((label) => {
      chartsData[label]?.forEach((element) => {
        if (!years.includes(element.year)) {
          years.push(element.year);
        }
      });
    });

    return years.sort();
  };

  const getData = (label, labels) => {
    const years = getlabels(labels);
    // console.log(years);
    const data = [];

    if (years.length > chartsData[label].length) {
      // vist all years year by year
      years.forEach((year) => {
        // check if data has count for the current year
        if (year !== null) {
          const item = chartsData[label].find(
            (element) => element.year === year
          );
          if (item) {
            data.push(item.count);
          } else {
            data.push(0);
          }
        }
      });
    } else {
      chartsData[label]?.forEach((element) => {
        if (element.year !== null) {
          data.push(element.count);
        }
      });
    }
    return data;
  };
  const getlabelsTickets = (labels) => {
    const years = [];
    labels.forEach((label) => {
      ticketsData[label]?.forEach((element) => {
        if (!years.includes(element.year)) {
          years.push(element.year);
        }
      });
    });

    return years.sort();
  };

  // useEffect(() => {

  // }, [chartsData, isLoading]);

  return (
    <>
      {isLoading ? (
        <div className={classes["loading__container"]}>
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <div className="row">
            <div className="col-12">
              <Bar
                data={{
                  labels: getlabels(["courses", "programs"]),
                  datasets: [
                    {
                      label: t("chart.courses"),
                      data: getData("courses", ["courses", "programs"]),
                      backgroundColor: "#24b3b9",
                      borderColor: "#fff",
                      borderWidth: 1,
                      ...(getlabels(["courses", "programs"]).length > 3
                        ? {}
                        : { barThickness: 40 }),
                    },
                    {
                      label: t("chart.programs"),
                      data: getData("programs", ["courses", "programs"]),
                      backgroundColor: "#8599b6",
                      borderColor: "#fff",
                      borderWidth: 1,
                      ...(getlabels(["courses", "programs"]).length > 3
                        ? {}
                        : { barThickness: 40 }),
                    },
                  ],
                }}
              />
              <div className={classes.chartTitle}>
                <Title
                  title={t("chartTitle_1")}
                  fontSize={16}
                  titleColor={"rgb(147 146 146)"}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Bar
                data={{
                  labels: getlabels(["trainers", "trainees"]),
                  datasets: [
                    {
                      label: t("chart.trainers"),
                      data: getData("trainers", ["trainers", "trainees"]),
                      backgroundColor: "#24b3b9",
                      borderColor: "#fff",
                      borderWidth: 1,
                      ...(getlabels(["trainers", "trainees"]).length > 3
                        ? {}
                        : { barThickness: 40 }),
                    },
                    {
                      label: t("chart.trainees"),
                      data: getData("trainees", ["trainers", "trainees"]),
                      backgroundColor: "#8599b6",
                      borderColor: "#fff",
                      borderWidth: 1,
                      ...(getlabels(["trainers", "trainees"]).length > 3
                        ? {}
                        : { barThickness: 40 }),
                    },
                  ],
                }}
              />
              <div className={classes.chartTitle}>
                <Title
                  title={t("chartTitle_1_2")}
                  fontSize={16}
                  titleColor={"rgb(147 146 146)"}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Bar
                data={{
                  labels: getlabels([
                    "registred_trainers",
                    "registred_trainees",
                  ]),
                  datasets: [
                    {
                      label: t("chart.registred_trainers"),
                      data: getData("registred_trainers", [
                        "registred_trainers",
                        "registred_trainees",
                      ]),
                      backgroundColor: "#24b3b9",
                      borderColor: "#fff",
                      borderWidth: 1,
                      ...(getlabels([
                        "registred_trainers",
                        "registred_trainees",
                      ]).length > 3
                        ? {}
                        : { barThickness: 40 }),
                    },
                    {
                      label: t("chart.registred_trainees"),
                      data: getData("registred_trainees", [
                        "registred_trainers",
                        "registred_trainees",
                      ]),
                      backgroundColor: "#8599b6",
                      borderColor: "#fff",
                      borderWidth: 1,
                      ...(getlabels([
                        "registred_trainers",
                        "registred_trainees",
                      ]).length > 3
                        ? {}
                        : { barThickness: 40 }),
                    },
                  ],
                }}
              />
              <div className={classes.chartTitle}>
                <Title
                  title={t("chartTitle_1_3")}
                  fontSize={16}
                  titleColor={"rgb(147 146 146)"}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <Bar
                data={{
                  labels: getlabels(["certificates"]),
                  datasets: [
                    {
                      label: t("chart.certificates"),
                      data: getData("certificates", ["certificates"]),
                      backgroundColor: "#24b3b9",
                      borderColor: "#fff",
                      borderWidth: 1,
                      ...(getlabels(["certificates"]).length > 3
                        ? {}
                        : { barThickness: 40 }),
                    },
                  ],
                }}
              />
              <div className={classes.chartTitle}>
                <Title
                  title={t("chartTitle_1_4")}
                  fontSize={16}
                  titleColor={"rgb(147 146 146)"}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Bar
                data={{
                  labels: getlabels([
                    "contents",
                    "content_watches",
                    // "content_achievements",
                  ]),
                  datasets: [
                    {
                      label: t("chart.contents"),
                      data: getData("contents", [
                        "contents",
                        "content_watches",
                        // "content_achievements",
                      ]),
                      backgroundColor: "#24b3b9",
                      borderColor: "#fff",
                      borderWidth: 1,
                      ...(getlabels([
                        "contents",
                        "content_watches",
                        // "content_achievements",
                      ]).length > 3
                        ? {}
                        : { barThickness: 40 }),
                    },
                    {
                      label: t("chart.watches"),
                      data: getData("content_watches", [
                        "contents",
                        "content_watches",
                        // "content_achievements",
                      ]),
                      backgroundColor: "#8599b6",
                      borderColor: "#fff",
                      borderWidth: 1,
                      ...(getlabels([
                        "contents",
                        "content_watches",
                        // "content_achievements",
                      ]).length > 3
                        ? {}
                        : { barThickness: 40 }),
                    },
                  ],
                }}
              />
              <div className={classes.chartTitle}>
                <Title
                  title={t("chartTitle_2")}
                  fontSize={16}
                  titleColor={"rgb(147 146 146)"}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Bar
                data={{
                  labels: getlabels([
                    "activities",
                    "activity_watches",
                    "activity_achievements",
                  ]),
                  datasets: [
                    {
                      label: t("chart.activities"),
                      data: getData("activities", [
                        "activities",
                        "activity_watches",
                        "activity_achievements",
                      ]),
                      backgroundColor: "#24b3b9",
                      borderColor: "#fff",
                      borderWidth: 1,
                      ...(getlabels([
                        "activities",
                        "activity_watches",
                        "activity_achievements",
                      ]).length > 3
                        ? {}
                        : { barThickness: 40 }),
                    },
                    {
                      label: t("chart.watches"),
                      data: getData("activity_watches", [
                        "activities",
                        "activity_watches",
                        "activity_achievements",
                      ]),
                      backgroundColor: "#8599b6",
                      borderColor: "#fff",
                      borderWidth: 1,
                      ...(getlabels([
                        "activities",
                        "activity_watches",
                        "activity_achievements",
                      ]).length > 3
                        ? {}
                        : { barThickness: 40 }),
                    },
                    {
                      label: t("chart.achievements"),
                      data: getData("activity_achievements", [
                        "activities",
                        "activity_watches",
                        "activity_achievements",
                      ]),
                      backgroundColor: "#2e3e5f",
                      borderColor: "#fff",
                      borderWidth: 1,
                      ...(getlabels([
                        "activities",
                        "activity_watches",
                        "activity_achievements",
                      ]).length > 3
                        ? {}
                        : { barThickness: 40 }),
                    },
                  ],
                }}
              />
              <div className={classes.chartTitle}>
                <Title
                  title={t("chartTitle_3")}
                  fontSize={16}
                  titleColor={"rgb(147 146 146)"}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Bar
                data={{
                  labels: getlabels([
                    "interactiveContents",
                    "interactive_content_watches",
                    // "interactive_content_achievements",
                  ]),
                  datasets: [
                    {
                      label: t("chart.interactiveContents"),
                      data: getData("interactiveContents", [
                        "interactiveContents",
                        "interactive_content_watches",
                        // "interactive_content_achievements",
                      ]),
                      backgroundColor: "#24b3b9",
                      borderColor: "#fff",
                      borderWidth: 1,
                      ...(getlabels([
                        "interactiveContents",
                        "interactive_content_watches",
                        // "interactive_content_achievements",
                      ]).length > 3
                        ? {}
                        : { barThickness: 40 }),
                    },
                    {
                      label: t("chart.watches"),
                      data: getData("interactive_content_watches", [
                        "interactiveContents",
                        "interactive_content_watches",
                        // "interactive_content_achievements",
                      ]),
                      backgroundColor: "#8599b6",
                      borderColor: "#fff",
                      borderWidth: 1,
                      ...(getlabels([
                        "interactiveContents",
                        "interactive_content_watches",
                        // "interactive_content_achievements",
                      ]).length > 3
                        ? {}
                        : { barThickness: 40 }),
                    },
                  ],
                }}
              />
              <div className={classes.chartTitle}>
                <Title
                  title={t("chartTitle_4")}
                  fontSize={16}
                  titleColor={"rgb(147 146 146)"}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Bar
                data={{
                  labels: getlabels([
                    "meetings",
                    "meeting_watches",
                    "meeting_achievements",
                  ]),
                  datasets: [
                    {
                      label: t("chart.meetings"),
                      data: getData("meetings", [
                        "meetings",
                        "meeting_watches",
                        "meeting_achievements",
                      ]),
                      backgroundColor: "#24b3b9",
                      borderColor: "#fff",
                      borderWidth: 1,
                      ...(getlabels([
                        "meetings",
                        "meeting_watches",
                        "meeting_achievements",
                      ]).length > 3
                        ? {}
                        : { barThickness: 40 }),
                    },
                    {
                      label: t("chart.watches"),
                      data: getData("meeting_watches", [
                        "meetings",
                        "meeting_watches",
                        "meeting_achievements",
                      ]),
                      backgroundColor: "#8599b6",
                      borderColor: "#fff",
                      borderWidth: 1,
                      ...(getlabels([
                        "meetings",
                        "meeting_watches",
                        "meeting_achievements",
                      ]).length > 3
                        ? {}
                        : { barThickness: 40 }),
                    },
                    {
                      label: t("chart.achievements"),
                      data: getData("meeting_achievements", [
                        "meetings",
                        "meeting_watches",
                        "meeting_achievements",
                      ]),
                      backgroundColor: "#2e3e5f",
                      borderColor: "#fff",
                      borderWidth: 1,
                      ...(getlabels([
                        "meetings",
                        "meeting_watches",
                        "meeting_achievements",
                      ]).length > 3
                        ? {}
                        : { barThickness: 40 }),
                    },
                  ],
                }}
              />
              <div className={classes.chartTitle}>
                <Title
                  title={t("chartTitle_5")}
                  fontSize={16}
                  titleColor={"rgb(147 146 146)"}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <Bar
                data={{
                  labels: getlabels([
                    "quizes",
                    "quiz_watches",
                    "quiz_achievements",
                  ]),
                  datasets: [
                    {
                      label: t("chart.quizes"),
                      data: getData("quizes", [
                        "quizes",
                        "quiz_watches",
                        "quiz_achievements",
                      ]),
                      backgroundColor: "#24b3b9",
                      borderColor: "#fff",
                      borderWidth: 1,
                      ...(getlabels([
                        "quizes",
                        "quiz_watches",
                        "quiz_achievements",
                      ]).length > 3
                        ? {}
                        : { barThickness: 40 }),
                    },
                    {
                      label: t("chart.watches"),
                      data: getData("quiz_watches", [
                        "quizes",
                        "quiz_watches",
                        "quiz_achievements",
                      ]),
                      backgroundColor: "#8599b6",
                      borderColor: "#fff",
                      borderWidth: 1,
                      ...(getlabels([
                        "quizes",
                        "quiz_watches",
                        "quiz_achievements",
                      ]).length > 3
                        ? {}
                        : { barThickness: 40 }),
                    },
                    {
                      label: t("chart.achievements"),
                      data: getData("quiz_achievements", [
                        "quizes",
                        "quiz_watches",
                        "quiz_achievements",
                      ]),
                      backgroundColor: "#2e3e5f",
                      borderColor: "#fff",
                      borderWidth: 1,
                      ...(getlabels([
                        "quizes",
                        "quiz_watches",
                        "quiz_achievements",
                      ]).length > 3
                        ? {}
                        : { barThickness: 40 }),
                    },
                  ],
                }}
              />
              <div className={classes.chartTitle}>
                <Title
                  title={t("chartTitle_6")}
                  fontSize={16}
                  titleColor={"rgb(147 146 146)"}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Bar
                data={{
                  labels: getlabels([
                    "surveys",
                    "survey_watches",
                    "survey_achievements",
                  ]),
                  datasets: [
                    {
                      label: t("chart.surveys"),
                      data: getData("surveys", [
                        "surveys",
                        "survey_watches",
                        "survey_achievements",
                      ]),
                      backgroundColor: "#24b3b9",
                      borderColor: "#fff",
                      borderWidth: 1,
                      ...(getlabels([
                        "surveys",
                        "survey_watches",
                        "survey_achievements",
                      ]).length > 3
                        ? {}
                        : { barThickness: 40 }),
                    },
                    {
                      label: t("chart.watches"),
                      data: getData("survey_watches", [
                        "surveys",
                        "survey_watches",
                        "survey_achievements",
                      ]),
                      backgroundColor: "#8599b6",
                      borderColor: "#fff",
                      borderWidth: 1,
                      ...(getlabels([
                        "surveys",
                        "survey_watches",
                        "survey_achievements",
                      ]).length > 3
                        ? {}
                        : { barThickness: 40 }),
                    },
                    {
                      label: t("chart.achievements"),
                      data: getData("survey_achievements", [
                        "surveys",
                        "survey_watches",
                        "survey_achievements",
                      ]),
                      backgroundColor: "#2e3e5f",
                      borderColor: "#fff",
                      borderWidth: 1,
                      ...(getlabels([
                        "surveys",
                        "survey_watches",
                        "survey_achievements",
                      ]).length > 3
                        ? {}
                        : { barThickness: 40 }),
                    },
                  ],
                }}
              />
              <div className={classes.chartTitle}>
                <Title
                  title={t("chartTitle_7")}
                  fontSize={16}
                  titleColor={"rgb(147 146 146)"}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Bar
                data={{
                  labels: getlabels(["all_tickets", "offers", "coupons"]),
                  datasets: [
                    {
                      label: t("chart.tickets"),
                      data: getData("all_tickets", [
                        "all_tickets",
                        "offers",
                        "coupons",
                      ]),
                      backgroundColor: "#24b3b9",
                      borderColor: "#fff",
                      borderWidth: 1,
                      ...(getlabels(["all_tickets", "offers", "coupons"])
                        .length > 3
                        ? {}
                        : { barThickness: 40 }),
                    },
                    {
                      label: t("chart.offers"),
                      data: getData("offers", [
                        "all_tickets",
                        "offers",
                        "coupons",
                      ]),
                      backgroundColor: "#8599b6",
                      borderColor: "#fff",
                      borderWidth: 1,
                      ...(getlabels(["all_tickets", "offers", "coupons"])
                        .length > 3
                        ? {}
                        : { barThickness: 40 }),
                    },
                    {
                      label: t("chart.coupons"),
                      data: getData("coupons", [
                        "all_tickets",
                        "offers",
                        "coupons",
                      ]),
                      backgroundColor: "#2e3e5f",
                      borderColor: "#fff",
                      borderWidth: 1,
                      ...(getlabels(["all_tickets", "offers", "coupons"])
                        .length > 3
                        ? {}
                        : { barThickness: 40 }),
                    },
                  ],
                }}
              />
              <div className={classes.chartTitle}>
                <Title
                  title={t("chartTitle_8")}
                  fontSize={16}
                  titleColor={"rgb(147 146 146)"}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Bar
                data={{
                  labels: getlabelsTickets(ticketTypes),
                  datasets: [...ticketsDataSet],
                }}
              />
              <div className={classes.chartTitle}>
                <Title
                  title={t("chartTitle_11")}
                  fontSize={16}
                  titleColor={"rgb(147 146 146)"}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Bar
                options={{
                  scales: {
                    xAxes: [
                      {
                        barThickness: 4,
                      },
                    ],
                  },
                }}
                data={{
                  labels: getlabels(["exhibitions", "exhibitionsVisits"]),
                  datasets: [
                    {
                      label: t("chart.exhibitions"),
                      data: getData("exhibitions", [
                        "exhibitions",
                        "exhibitionsVisits",
                      ]),
                      backgroundColor: "#24b3b9",
                      borderColor: "#fff",
                      borderWidth: 1,
                      ...(getlabels(["exhibitions", "exhibitionsVisits"])
                        .length > 3
                        ? {}
                        : { barThickness: 40 }),
                    },
                    {
                      label: t("chart.exhibitionsVisits"),
                      data: getData("exhibitionsVisits", [
                        "exhibitions",
                        "exhibitionsVisits",
                      ]),
                      backgroundColor: "#8599b6",
                      borderColor: "#fff",
                      borderWidth: 1,
                      ...(getlabels(["exhibitions", "exhibitionsVisits"])
                        .length > 3
                        ? {}
                        : { barThickness: 40 }),
                    },
                  ],
                }}
              />
              <div className={classes.chartTitle}>
                <Title
                  title={t("chartTitle_9")}
                  fontSize={16}
                  titleColor={"rgb(147 146 146)"}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
