import React from "react";
import "./index.css";

const MainCarousel = ({ imagesList, type }) => {
	return (
		<div id="carouselExampleControls" className="carousel slide " data-ride="carousel">
			<div className="carousel-inner">
				{imagesList.map((image, index) => (
					<div className={`carousel-item  ${index === 0 && "active"} `} key={index}>
						<div className="embed-responsive embed-responsive-16by9">
							<img
								className="d-block embed-responsive-item"
								src={
									type === "review"
										? image?.file?.preview
											? image?.file?.preview
											: `${process.env.REACT_APP_SERVER_PATH}/storage/exhibitions/${image?.file}`
										: image?.file
								}
								alt="First slide"
							/>
						</div>
					</div>
				))}
			</div>
			<a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
				<span className="carousel-control-prev-icon" aria-hidden="true"></span>
				<span className="sr-only">Previous</span>
			</a>
			<a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
				<span className="carousel-control-next-icon" aria-hidden="true"></span>
				<span className="sr-only">Next</span>
			</a>
		</div>
	);
};

export default MainCarousel;
