import env from "./Environment";
import connector from "./Handler";
import URLBuilder from "./UrlBuilder";

export function exportPeyment(data, export_type, role) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(
          env.get_payments,
          // { role: role },
          {
            /////////////////
            responseType: "blob",
            params: {
              export: export_type,
              ...data,
            },
          }
        )
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Payments" + "." + export_type); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

// exportProgramPaymentsReport
export function exportProgramPaymentsReport({ type, filter, id, paymentId }) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(
          URLBuilder(paymentId ? env.get_program_payments_report_payment : env.get_program_payments_report, {
            id: id,
            ...(paymentId && { paymentId: paymentId }),
          }),
          {
            responseType: "blob",
            params: {
              ...filter,
              export: type,
            },
          }
        )
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Program Payments Report" + "." + type); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
