import env from "../Environment";
import connector from "../Handler";

export async function getAllDepartment() {
  try {
    const response = connector.get(env.get_all_department);
    return response;
  } catch (error) {}
}
export function getCategoryHome(status) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.all_catgeory, { params: { status: status } })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
