import React from "react";
import MainCarousel from "../../../Shared/Components/MainCarousel/MainCarousel";
import classes from "./exhibitionDetails.module.css";

const ExhibitionDetailsCarousel = ({ title, photos }) => {
	return (
		<div className={classes["exhibition-details__carousel"]}>
			<div className={classes["exhibition-details__carousel-title"]}>{title}</div>
			{!!photos.length && <MainCarousel imagesList={photos} />}
		</div>
	);
};

export default ExhibitionDetailsCarousel;
