import React from "react";
import { useTranslation } from "react-i18next";
import { IconsTooltip } from "../../../../Components";
import ArrowWithContainer from "../../../../assets/icons/arrow-with-container.svg";
import classes from "./courseDetailsHeader.module.css";

export default function CourseDetailsHeader({
  title,
  actions,
  isLabelEditable,
  onChange,
  value,
  disabled,
  setOpened,
  opened,
  badge,
  id = "",
  progress = {},
}) {
  const { t } = useTranslation();

  return (
    <div className={classes[`course-details-header${opened ? "-opened" : ""}`]} id={id}>
      <div className={classes[`course-details-header__title${opened ? "-opened" : ""}`]}>
        {isLabelEditable ? (
          <input
            type="text"
            className={classes["course-details-header__title__input"]}
            value={value}
            onChange={onChange}
            disabled={disabled}
          />
        ) : (
          <span>{title}</span>
        )}
        {badge && (
          <div
            className={classes["course-details-header__title__badge"]}
            style={{
              backgroundColor: badge?.backgroundColor,
              color: badge?.textColor,
            }}
          >
            {badge?.icon && (
              <div className={classes["course-details-header__title__badge__icon"]}>
                <img src={badge?.icon} alt="" />
              </div>
            )}
            <div className={classes["course-details-header__title__badge__text"]}>{badge?.text}</div>
          </div>
        )}
      </div>
      <div className={classes["course-details-header__details"]}>
        {progress && Object.keys(progress).length ? (
          <div className={classes["course-details-header__progress"]}>
            <div
              className={`${classes["course-details-header__progress-bar"]} ${classes["course-details-header__progress-bar-watch"]}`}
              style={{ "--watch_percentage": `${Number(progress.watch_percentage) || 0}%` }}
              title={t("watchingProgress")}
            >
              <div className={classes["course-details-header__progress-bar-tooltip"]}>
                <span>{`${Math.round(progress.watch_percentage) || 0}%`}</span>
              </div>
            </div>
            <div
              className={`${classes["course-details-header__progress-bar"]} ${classes["course-details-header__progress-bar-achievement"]}`}
              style={{ "--progress_percentage": `${Number(progress.progress_percentage) || 0}%` }}
              title={t("progress")}
            >
              <div className={classes["course-details-header__progress-bar-tooltip"]}>
                <span>{`${Math.round(progress.progress_percentage) || 0}%`}</span>
              </div>
            </div>
          </div>
        ) : null}
        <div className={classes["course-details-header__actions-container"]}>
          {actions && !!actions.length && (
            <div className={classes["course-details-header__actions"]}>
              {actions.map((action, index) =>
                action.type === "text" ? (
                  <div
                    key={index}
                    className={classes["course-details-header__actions__action"]}
                    onClick={action?.action}
                    style={{
                      backgroundColor: action?.backgroundColor,
                      color: action?.textColor,
                      padding: "0.6rem 1rem",
                      borderRadius: 6,
                      ...action?.style,
                    }}
                  >
                    <div className={classes["course-details-header__actions__action__text"]}>{action?.text}</div>
                  </div>
                ) : (
                  <IconsTooltip
                    title={action?.toolTip}
                    content={
                      <div
                        key={index}
                        className={classes["course-details-header__actions__action"]}
                        onClick={action?.action}
                      >
                        <div className={classes["course-details-header__actions__action__icon"]}>{action?.icon}</div>
                      </div>
                    }
                  />
                )
              )}
            </div>
          )}
          {setOpened && (
            <div onClick={() => setOpened && setOpened(!opened)} className={classes["course-details-header__arrow"]}>
              <img
                src={ArrowWithContainer}
                alt=""
                style={{
                  transform: opened ? "rotate(180deg)" : "rotate(0deg)",
                  marginRight: document.body.dir === "rtl" ? 0 : 10,
                  marginLeft: document.body.dir === "ltr" ? 0 : 10,
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
