import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";

import { toServerTime } from "../../../../Services/Timezone";
import {
  getCourseGiftPaymentDetails,
  getCoursePaymentDetails,
  payCourseOrProgram,
  payProgramPayments,
  payProgramPaymentsGift,
} from "../../../../Services/api/courses/courseProvider";
import ArrowIcon from "../../../../assets/icons/arrow.png";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import CoursePaymentDetails from "./CoursePaymentDetails";
import CoursePaymentForm from "./CoursePaymentForm";
import classes from "./trainingCoursePayment.module.css";
import GiftProgramPaymentDetails from "./GiftProgramPaymentDetails";

export default function TrainingCoursePayment({
  isProgram,
  isGift,
  isGiftPayment,
}) {
  const { t } = useTranslation();
  const { id, giftId } = useParams();
  const history = useHistory();
  const roles = JSON.parse(localStorage.getItem("roles"));

  const [courseDetails, setCourseDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [validCoupon, setValidCoupon] = useState({});
  const [firstPayment, setFirstPayment] = useState(false);
  const [paymentId, setPaymentId] = useState(null);
  const [traineeOptions, setTraineeOptions] = useState([]);
  const [transferDateHours, setTransferDateHours] = useState(0);
  const [transferDateMinutes, setTransferDateMinutes] = useState(0);
  const [dashboard, setDashboard] = useState({});

  const getPaymentDetails = () => {
    getCoursePaymentDetails(id, isProgram ? "program" : "course")
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setCourseDetails(res.data[isProgram ? "program" : "course"]);
          setDashboard(res.data.dashboard);
          setFirstPayment(
            isProgram
              ? res.data.program.payments.find((payment, index) => {
                  if (
                    (index === 0 && !payment.is_paid) ||
                    (index === 0 && payment.is_paid && !payment.is_self_paid)
                  ) {
                    return true;
                  }
                  return false;
                })
              : true
          );
          setPaymentId(
            isProgram
              ? res.data.program.payments.find((payment, index) => {
                  if (
                    index > 0 &&
                    !payment.is_paid &&
                    payment.is_payment_due &&
                    !payment.is_self_paid
                  ) {
                    return payment.id;
                  }
                  return false;
                })?.id
              : null
          );
          setIsLoading(false);
        }
      })
      .catch((err) => {});
  };

  const getGiftPaymentDetails = () => {
    getCourseGiftPaymentDetails(id, isProgram ? "program" : "course")
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setCourseDetails(isProgram ? res.data.gift : res.data.gift);
          setDashboard(res.data.dashboard);
          setTraineeOptions(res.data.trainees);
          setFirstPayment(
            isProgram
              ? res.data.gift.payments.filter(
                  (payment) => payment.is_payment_due
                ).length > 0
                ? true
                : false
              : true
          );
          setPaymentId(
            isProgram
              ? res.data.gift.payments.find((payment, index) => {
                  if (index > 0 && !payment.is_paid && payment.is_payment_due) {
                    return payment.id;
                  }
                  return false;
                })?.id
              : null
          );
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const currentDate = () => {
    let HoursDate = new Date().getHours();
    let MinutesDate = new Date().getMinutes();

    setTransferDateHours(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
    setTransferDateMinutes(MinutesDate >= 30 ? 0 : MinutesDate + 10);
  };

  const formatDate = (d) => {
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const formatTime = (t) => {
    let hours = "" + t.getHours(),
      minutes = "" + t.getMinutes(),
      second = "00";

    hours = hours === "0" ? "00" : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return [hours, minutes, second].join(":");
  };

  useEffect(() => {
    currentDate();

    if (roles.includes("trainee") && roles.includes("admin")) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("registration_not_allowed")}
        </span>
      );
      history.push(
        `/${isProgram ? "training-programs" : "training-courses"}/${id}/details`
      );
    }
    if (isGift) {
      getGiftPaymentDetails();
    } else {
      getPaymentDetails();
    }
  }, []);

  const [selectedBox, setSelectedBox] = useState({
    bankSelected: true,
    walletSelected: false,
  });

  const handleSelectPaymentClick = (key) => {
    if (key === "bank" && !selectedBox.bankSelected) {
      setSelectedBox({
        bankSelected: true,
        walletSelected: false,
      });
    }
    if (key === "wallet" && !selectedBox.walletSelected) {
      setSelectedBox({
        bankSelected: false,
        walletSelected: true,
      });
    }
  };

  return (
    <div className="container-fluid" style={{ marginTop: "2rem" }}>
      {isLoading ? (
        <SkeletonCardOverlay />
      ) : (
        <MainBox className="border-8">
          <div className={classes["payment-container"]}>
            <div
              className={classes["payment-back"]}
              onClick={() =>
                history.push(
                  `/${isProgram ? "training-programs" : "training-courses"}/${
                    courseDetails.id
                  }/details`
                )
              }
            >
              <div className={classes["payment-back-icon"]}>
                <img src={ArrowIcon} alt="back" />
              </div>
              <div className={classes["payment-back-text"]}>
                {courseDetails.name}
              </div>
            </div>
            <div className={classes["course_payment_notes_container"]}>
              {courseDetails.offers.length > 0 &&
              courseDetails.offers[0].notes &&
              courseDetails.offers[0].user_can_use_now ? (
                <div className={classes["course_payment_note"]}>
                  {courseDetails.offers[0].notes}
                </div>
              ) : null}
              {courseDetails.taxes.length > 0 && courseDetails.taxes[0].notes
                ? courseDetails.taxes.map((tax) =>
                    tax.user_can_use_now ? (
                      <div className={classes["course_payment_note"]}>
                        {tax.notes}
                      </div>
                    ) : null
                  )
                : null}
              {courseDetails.coupons.length > 0 &&
              courseDetails.coupons[0].notes
                ? courseDetails.coupons.map((coupon) => (
                    <div className={classes["course_payment_note"]}>
                      {coupon.notes}
                    </div>
                  ))
                : null}
            </div>
            <div>
              <Formik
                initialValues={{
                  name: "",
                  phone_number: "",
                  phone_code: "",
                  bank: "",
                  iban: "",
                  payment_proof: "",
                  transfer_date: setHours(
                    setMinutes(new Date(), transferDateMinutes),
                    transferDateHours
                  ),
                  ...(isGift ? { is_gift: 1, trainee_id: "" } : {}),
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  setIsLoading(true);
                  const formData = new FormData();
                  formData.append("course_id", id);
                  if (isGift) {
                    formData.append("is_gift", values.is_gift);
                    formData.append("email", values.email);
                  }

                  if (selectedBox.bankSelected) {
                    formData.append("payment_method", "Bank");
                    formData.append("name", values.name);
                    formData.append(
                      "mobile",
                      values.phone_code + values.phone_number
                    );
                    formData.append("bank", values.bank);
                    formData.append("iban", values.iban);
                    formData.append("payment_proof", values.payment_proof.file);

                    const transferDate = toServerTime(
                      formatDate(values.transfer_date) +
                        " " +
                        formatTime(values.transfer_date)
                    );
                    formData.append(
                      "transfer_date",
                      transferDate.split(" ")[0]
                    );
                  } else {
                    formData.append("payment_method", "Wallet");
                  }

                  if (Object.keys(validCoupon).length > 0) {
                    formData.append("coupon_code", validCoupon.code);
                  }

                  if (firstPayment) {
                    payCourseOrProgram(
                      isProgram ? "program" : "course",
                      formData
                    )
                      .then((res) => {
                        if (
                          res.status &&
                          res.status === 200 &&
                          res.data.status
                        ) {
                          toast.success(
                            <span style={{ fontSize: 13, fontWeight: "bold" }}>
                              {res.data.msg}
                            </span>
                          );
                          history.push(
                            `/${
                              isProgram
                                ? "training-programs"
                                : "training-courses"
                            }/${id}/details`
                          );
                        }
                      })
                      .catch((err) => {
                        toast.error(
                          <span style={{ fontSize: 13, fontWeight: "bold" }}>
                            {typeof err === "string" ? (
                              <span
                                style={{
                                  fontSize: 12,
                                  fontWeight: "500",
                                  display: "block",
                                }}
                              >
                                {err}
                              </span>
                            ) : err.response.data ? (
                              <>
                                <span
                                  style={{
                                    fontSize: 13,
                                    fontWeight: "bold",
                                    display: "block",
                                  }}
                                >
                                  {err.response.data.msg}
                                </span>
                                <ul>
                                  {err.response.data.errors
                                    ? Object.keys(err.response.data.errors).map(
                                        (key) => {
                                          return (
                                            <li>
                                              <span style={{ fontSize: 11 }}>
                                                {err.response.data.errors[key]}
                                              </span>
                                            </li>
                                          );
                                        }
                                      )
                                    : null}
                                </ul>
                              </>
                            ) : (
                              " Error"
                            )}
                          </span>
                        );
                      })
                      .finally(() => {
                        setIsLoading(false);
                      });
                  } else if (paymentId) {
                    if (isGiftPayment) {
                      payProgramPaymentsGift(formData, giftId, paymentId)
                        .then((res) => {
                          if (
                            res.status &&
                            res.status === 200 &&
                            res.data.status
                          ) {
                            toast.success(
                              <span
                                style={{ fontSize: 13, fontWeight: "bold" }}
                              >
                                {res.data.msg}
                              </span>
                            );
                            history.push(
                              `/private-profile/sent-gift/payments-due`
                            );
                          }
                        })
                        .catch((err) => {
                          toast.error(
                            <span style={{ fontSize: 13, fontWeight: "bold" }}>
                              {typeof err === "string" ? (
                                <span
                                  style={{
                                    fontSize: 12,
                                    fontWeight: "500",
                                    display: "block",
                                  }}
                                >
                                  {err}
                                </span>
                              ) : err?.response?.data ? (
                                <>
                                  <span
                                    style={{
                                      fontSize: 13,
                                      fontWeight: "bold",
                                      display: "block",
                                    }}
                                  >
                                    {err.response.data.msg}
                                  </span>
                                  <ul>
                                    {err.response.data.errors
                                      ? Object.keys(
                                          err.response.data.errors
                                        ).map((key) => {
                                          return (
                                            <li>
                                              <span style={{ fontSize: 11 }}>
                                                {err.response.data.errors[key]}
                                              </span>
                                            </li>
                                          );
                                        })
                                      : null}
                                  </ul>
                                </>
                              ) : (
                                <span
                                  style={{ fontSize: 13, fontWeight: "bold" }}
                                >
                                  {err?.data?.msg}
                                </span>
                              )}
                            </span>
                          );
                        })
                        .finally(() => {
                          setIsLoading(false);
                        });
                    } else {
                      formData.append("payment_id", paymentId);
                      payProgramPayments(formData)
                        .then((res) => {
                          if (
                            res.status &&
                            res.status === 200 &&
                            res.data.status
                          ) {
                            toast.success(
                              <span
                                style={{ fontSize: 13, fontWeight: "bold" }}
                              >
                                {res.data.msg}
                              </span>
                            );
                            history.push(`/training-courses/${id}/content`);
                          }
                        })
                        .catch((err) => {
                          toast.error(
                            <span style={{ fontSize: 13, fontWeight: "bold" }}>
                              {typeof err === "string" ? (
                                <span
                                  style={{
                                    fontSize: 12,
                                    fontWeight: "500",
                                    display: "block",
                                  }}
                                >
                                  {err}
                                </span>
                              ) : err?.response?.data ? (
                                <>
                                  <span
                                    style={{
                                      fontSize: 13,
                                      fontWeight: "bold",
                                      display: "block",
                                    }}
                                  >
                                    {err.response.data.msg}
                                  </span>
                                  <ul>
                                    {err.response.data.errors
                                      ? Object.keys(
                                          err.response.data.errors
                                        ).map((key) => {
                                          return (
                                            <li>
                                              <span style={{ fontSize: 11 }}>
                                                {err.response.data.errors[key]}
                                              </span>
                                            </li>
                                          );
                                        })
                                      : null}
                                  </ul>
                                </>
                              ) : (
                                <span
                                  style={{ fontSize: 13, fontWeight: "bold" }}
                                >
                                  {err?.data?.msg}
                                </span>
                              )}
                            </span>
                          );
                        })
                        .finally(() => {
                          setIsLoading(false);
                        });
                    }
                  } else {
                    toast.error(
                      <span style={{ fontSize: 13, fontWeight: "bold" }}>
                        {t("payments.no_payments_due")}
                      </span>
                    );
                    setIsLoading(false);
                  }
                }}
                validate={(values) => {
                  const errors = {};

                  if (selectedBox.bankSelected) {
                    if (!values.name) {
                      errors.name = t("crud.errors.required");
                    }
                    if (!values.phone_number || !values.phone_code) {
                      errors.mobile = t("crud.errors.required");
                    } else if (values.phone_number.length <= 7) {
                      errors.mobile = t("payments.invalid_mobile");
                    } else if (values.phone_number.length >= 15) {
                      errors.mobile = t("payments.invalid_mobile");
                    }
                    if (!values.bank) {
                      errors.bank = t("crud.errors.required");
                    }
                    if (!values.iban) {
                      errors.iban = t("crud.errors.required");
                    } else if (
                      values.iban.length < 20 ||
                      values.iban.length > 34
                    ) {
                      errors.iban = t("payments.invalid_iban");
                    }
                    if (!values.payment_proof) {
                      errors.payment_proof = t("crud.errors.required");
                    }
                  }
                  if (isGift) {
                    if (!values.email) {
                      errors.email = t("crud.errors.required");
                    }
                    if (values.email) {
                      const emailRegex =
                        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                      if (!emailRegex.test(values.email)) {
                        errors.email = t("crud.errors.invalidEmail");
                      }
                    }
                  }

                  return errors;
                }}
                validateOnChange={true}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <form className={classes["course_payment_container"]}>
                    <div className={classes["course_payment_form_container"]}>
                      <CoursePaymentForm
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        selectedBox={selectedBox}
                        handleSelectPaymentClick={handleSelectPaymentClick}
                        values={values}
                        errors={errors}
                        setTransferDateHours={setTransferDateHours}
                        setTransferDateMinutes={setTransferDateMinutes}
                        currentDate={currentDate}
                        dashboard={dashboard}
                        validCoupon={validCoupon}
                      />
                    </div>
                    <div className={classes["course_payment_form_separator"]} />
                    <div
                      className={classes["course_payment_details_container"]}
                      >
                      {isGift && isProgram ? (
                        <GiftProgramPaymentDetails 
                          handleChange={handleChange}
                          courseDetails={courseDetails}
                          handleSubmit={handleSubmit}
                          validCoupon={validCoupon}
                          setValidCoupon={setValidCoupon}
                          handleSelectPaymentClick={handleSelectPaymentClick}
                          values={values}
                          errors={errors}
                          setFieldValue={setFieldValue}
                          trainees={traineeOptions}
                        />
                      ) : (
                      <CoursePaymentDetails
                        handleChange={handleChange}
                        courseDetails={courseDetails}
                        handleSubmit={handleSubmit}
                        validCoupon={validCoupon}
                        setValidCoupon={setValidCoupon}
                        handleSelectPaymentClick={handleSelectPaymentClick}
                        values={values}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        trainees={traineeOptions}
                        isGift={isGift}
                      />
                      )}
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </MainBox>
      )}
    </div>
  );
}
