import FilterIcon from "../../../../assets/icons/filter.svg";
import AddIcon from "../../../../assets/icons/plus.svg";
import PdfIcon from "../../../../assets/icons/pdf.svg";
import XlsIcon from "../../../../assets/icons/xls.svg";

export const filterActions = ({
  showFilter,
  addExhibition,
  exportPdf,
  exportExcel,
}) => {
  const actionsList = [
    {
      id: "filter",
      icon: <img src={FilterIcon} alt="" />,
      action: showFilter,
    },
    {
      id: "pdf",
      icon: <img src={PdfIcon} alt="" />,
      theme: "pdf",
      action: exportPdf,
    },
    {
      id: "xls",
      icon: <img src={XlsIcon} alt="" />,
      theme: "xls",
      action: exportExcel,
    },
  ];
  return actionsList;
};
