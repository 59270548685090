import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import deleteIcon from "../../../assets/icons/delete.svg";
import dragIcon from "../../../assets/icons/drag_reorder.svg";
import CustomButton from "../../Shared/Components/CustomButton/CustomButton";
import MainBox from "../../Shared/Components/MainBox/MainBox";
import classes from "./reorderProgramCoursesModal.module.css";

const ReorderProgramCoursesModal = ({ closeModal, onConfirm, programId }) => {
  const { t } = useTranslation();
  const { coursesList } = useSelector((state) => state.coursesListReducer);

  const [newCourses, setNewCourses] = useState(coursesList);

  const onDragStart = (e, index) => {
    e.dataTransfer.setData("index", index);
  };

  const onDragEnter = (e) => {
    e.target.classList.add(classes["dragged-over"]);
  };

  const onDragLeave = (e) => {
    e.target.classList.remove(classes["dragged-over"]);
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDrop = (e, index, setFieldValue) => {
    const draggedIndex = e.dataTransfer.getData("index");
    const newCoursesCopy = [...newCourses];
    const newOrder = [...newCourses.map((course) => ({ id: course.id }))];
    const draggedCourse = newCoursesCopy.splice(draggedIndex, 1)[0];
    const draggedCourseOrder = newOrder.splice(draggedIndex, 1)[0];
    newCoursesCopy.splice(index, 0, draggedCourse);
    newOrder.splice(index, 0, draggedCourseOrder);
    setFieldValue("courses", newOrder);
    setNewCourses(newCoursesCopy);
    document.querySelectorAll(`.${classes["dragged-over"]}`).forEach((el) => {
      el.classList.remove(classes["dragged-over"]);
    });
  };

  return (
    <MainBox className={classes["reorder-program-courses-modal"]}>
      <div className={classes["reorder-program-courses-header"]}>
        <h2>{t("reorder_courses")}</h2>
        <img src={deleteIcon} alt="close" onClick={closeModal} />
      </div>
      <div className={classes["reorder-program-courses-content"]}>
        <Formik
          initialValues={{
            courses: [
              ...coursesList.map((course) => ({
                id: course.id,
              })),
            ],
          }}
          onSubmit={(values) => {
            const payload = {
              program_id: programId,
              courses: values.courses,
            };
            onConfirm(payload);
          }}
          validateOnChange={true}
          validate={(values) => {
            const errors = {};
            if (
              values.courses.length !== coursesList.length ||
              coursesList.some((course) => !values.courses.find((c) => c.id === course.id))
            ) {
              errors.courses = t("reorder_courses_error");
            }
            return errors;
          }}
        >
          {({ values, setFieldValue, errors, handleSubmit }) => (
            <form className={classes["reorder-program-courses-form"]} onSubmit={handleSubmit}>
              <div className={classes["reorder-program-courses-form-content"]}>
                {values.courses.map((course, index) => (
                  <div
                    key={course.id}
                    className={classes["reorder-program-courses-form-content-item"]}
                    draggable
                    onDragStart={(e) => onDragStart(e, index)}
                    onDragEnter={onDragEnter}
                    onDragLeave={onDragLeave}
                    onDragOver={onDragOver}
                    onDrop={(e) => onDrop(e, index, setFieldValue)}
                  >
                    <img src={dragIcon} alt="drag" />
                    <span>{coursesList.find((c) => c.id === course.id).name}</span>
                  </div>
                ))}
                {errors.courses && (
                  <div className={classes["reorder-program-courses-form-content-error"]}>{errors.courses}</div>
                )}
              </div>
              <div className={classes["reorder-program-courses-footer"]}>
                <CustomButton
                  type="button"
                  colors="#036c77"
                  variant="outlined"
                  value={t("cancel")}
                  classes={classes["reorder-program-courses-cancel"]}
                  action={closeModal}
                />
                <CustomButton
                  type="submit"
                  colors="#036c77"
                  value={t("save")}
                  classes={classes["reorder-program-courses-save"]}
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </MainBox>
  );
};

export default ReorderProgramCoursesModal;
