import { Pagination } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { myCertificates } from "../../../Services/api/certificate/CertificateProvider";
import { exportCertificate } from "../../../Services/api/courses/courseProvider";
import MainBox from "../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../Shared/Components/MainBoxHead/MainBoxHead";
import { BasicModal } from "../../Shared/Components/Modal/BasicModal";
import SkeletonCardOverlay from "../../Shared/Components/Spinner/SkeletonCardOverlay";
import CertificateModal from "../../TrainingCourses/TrainingCourseDetails/TrainingCourseContent/CertificateModal";
import CertificatesTable from "./CertificatesTable";
import classes from "./certificates.module.css";

const Certificates = () => {
  const { t, i18n } = useTranslation();

  const openExportCertificateModalRef = useRef();
  const [showExportCertificateModal, setShowExportCertificateModal] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [certificates, setCertificates] = useState([]);
  const [meta, setMeta] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [isExportLoading, setIsExportLoading] = useState(false);

  const fetchCertificates = async () => {
    setIsLoading(true);
    try {
      const response = await myCertificates({ page: currentPage, perPage: 10 });
      setCertificates(response.data.data.certificates);
      setMeta(response.data.data.meta);
    } catch (error) {
      
    } finally {
      setIsLoading(false);
    }
  };

  const exportCertificateApi = async ({ lang }) => {
    setIsExportLoading(true);
    try {
      await exportCertificate(selectedCourse, lang);
    } catch (e) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {e.response.data.msg ? e.response.data.msg : t("export_certificate_failed")}
        </span>
      );
    } finally {
      setIsExportLoading(false);
      handleCloseExportCertificateModal();
    }
  };

  const handleOpenExportCertificateModal = (id) => {
    setSelectedCourse(id);
    setShowExportCertificateModal(true);
    openExportCertificateModalRef.current.showModal();
  };

  const handleCloseExportCertificateModal = () => {
    setSelectedCourse(null);
    setShowExportCertificateModal(false);
    openExportCertificateModalRef.current.dismissModal();
  };

  useEffect(() => {
    fetchCertificates();
  }, [currentPage]);

  return (
    <div className={classes.certificates}>
      {isExportLoading && <SkeletonCardOverlay skeletonWidth="100" />}
      <div className="container-fluid">
        <div className={classes["certificates__container"]}>
          <div className="row">
            <div className="col-12">
              <MainBox>
                <MainBoxHead title={t("general.certificates")} />
                <CertificatesTable
                  certificates={certificates}
                  isLoading={isLoading}
                  handleOpenExportCertificateModal={handleOpenExportCertificateModal}
                />
                <Pagination
                  count={meta?.totalPages || 0}
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  className="main-pagination"
                  onChange={(e, page) => {
                    setCurrentPage(page);
                  }}
                />
              </MainBox>
            </div>
          </div>
        </div>
      </div>
      <BasicModal ref={openExportCertificateModalRef}>
        {showExportCertificateModal && (
          <CertificateModal
            selectedCourse={selectedCourse}
            handleCancel={handleCloseExportCertificateModal}
            handleExport={exportCertificateApi}
          />
        )}
      </BasicModal>
    </div>
  );
};

export default Certificates;
