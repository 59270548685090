import React from "react";
import DatePicker from "react-datepicker";
import "./mainDatePicker.css";
import classes from "./mainDatePicker.module.css";

const MainDatePicker = ({
  name,
  label,
  error,
  value,
  onChange,
  minDate,
  minTime,
  maxTime,
  maxDate,
  readOnly,
  required,
  dayMonthYear = false,
  note,
}) => {
  return (
    <div className={classes["datepicker-field"]}>
      <label htmlFor={name}>
        {label}
        {required && <span style={{ color: "red" }}>*</span>}
      </label>
      <DatePicker
        showIcon
        selected={value}
        onChange={onChange}
        className={`${classes["datepicker-field-input"]} ${readOnly ? classes["datepicker-field-input-readonly"] : ""}`}
        dateFormat={dayMonthYear ? "dd/MM/yyyy" : "yyyy-MM-dd h:mm aa"}
        showTimeSelect={!dayMonthYear}
        showYearDropdown={dayMonthYear}
        name="start_time"
        minDate={minDate}
        minTime={minTime}
        maxTime={maxTime}
        maxDate={maxDate}
        readOnly={readOnly}
      />
      <p className={`form-input-error-space ${classes["datepicker-field-error"]}`}>{error && error}</p>
      {note && <div className={classes["datepicker-field-note"]}>{note}</div>}
    </div>
  );
};

export default MainDatePicker;
