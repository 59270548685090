import React from 'react';
import {useHistory} from "react-router";

function CardStatistics(props) {
    const history = useHistory();
    require("./Inputs.css")

    return (
        <div className='card-counter'>
            <img src={props.image} alt="" className='card-image' />
            <div className="card-label-survey">
                {props.label}
            </div>
            <div className="card-number-survey">
                {props.number}
            </div>
        </div>
    );
}

export default CardStatistics;
