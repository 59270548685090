import env from "../Environment";
import connector from "../Handler";

export function getLogs(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.logs, {
          params: {
            ...payload,
            perPage: 50,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function exportLogs(type, params) {
  let extension = "";
  type == "pdf" ? (extension = "pdf") : (extension = "xlsx");
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.logs, {
          responseType: "blob",
          params: {
            ...params,
            export: extension,
            perPage: 50,
            page: params.page ? params.page : 1,
          },
        })
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "logs" + "." + extension);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
