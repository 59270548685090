import {createTheme, jssPreset, MuiThemeProvider, StylesProvider,} from "@material-ui/core/styles";
import rtl from "jss-rtl";
import {create} from "jss";
import i18n from "../i18n/i18n";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

function withRoot(Component) {
  function WithRoot(props) {
    return (
      <StylesProvider jss={jss}>
        <MuiThemeProvider theme={createTheme({
          direction: i18n.dir(),
        })}
        >
          <Component {...props} />
        </MuiThemeProvider>
      </StylesProvider>
    );
  }
  return WithRoot;
}

export default withRoot;
