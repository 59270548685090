import moment from "moment";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import toLocalTimezone from "../../../../../Services/Timezone";
import { ReactComponent as SessionIcon } from "../../../../../assets/icons/SessionIcon.svg";
import LessonItem from "./LessonItem";

export default function LessonSessionItem({
  courseId,
  chapterId,
  unitId,
  lessonId,
  session,
  onOpen,
  isCourseClosed = { isCourseClosed },
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const startDate = toLocalTimezone(session.start_time);
  const endDate = moment(toLocalTimezone(session.start_time)).add(session.duration, "m").format("YYYY-MM-DD HH:mm:ss");
  const params = {
    icon: <SessionIcon className="tw-h-5" />,
    name: t("trainer.course.lesson.sessions"),
    title: session.title,
    buttons: [
      {
        label: t("join"),
        disabled: new Date(startDate) > new Date() || new Date(endDate) < new Date() || isCourseClosed,
        onClick: () => {
          onOpen();
          var url = new URL(session.start_url);
          var win = window.open(url, "_blank");
          win.focus();
        },
      },
    ],
    footer: {
      flags: {
        include_view: session.include_view_per,
        include_achivement: session.include_achivement_per,
        finished: new Date(endDate) < new Date(),
        is_watched: session.is_watched,
        is_achieved: session.is_achieved,
      },
      columns: [
        {
          label: t("zoom.Course_name"),
          color: "orange",
          value:
            session.meeting_type == 1
              ? t("trainer.course.lesson.zoom")
              : session.meeting_type == 2
              ? t("trainer.course.lesson.webex")
              : session.meeting_type == 3
              ? t("trainer.course.lesson.microsoft_teams")
              : t("unknown"),
        },
        {
          label: t("zoom.session_duration"),
          value: `${session.duration} ${t("min")}`,
        },
        {
          label: t("avialible_from"),
          value: moment(startDate).format("YYYY-MM-DD hh:mm A"),
          rows: [
            {
              label: t("To"),
              value: moment(endDate).format("YYYY-MM-DD hh:mm A"),
            },
          ],
        },
      ],
    },
  };
  return <LessonItem {...params} />;
}
