import env from "./Environment";
import connector from "./Handler";
import { default as URLBuilder } from "../../Services/api/UrlBuilder";

export function exportProgramPaymentsList(export_type, id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.program_payment_list, { id: id }), {
          responseType: "blob",
          params: {
            export: export_type,
          },
        })
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              "Program_Payement_list" + "." + export_type
            ); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
