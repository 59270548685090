import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { NavLink } from "react-router-dom";
import noResult from "../../../assets/image/Noresult.png";
import UserCard from "../../Shared/Components/UserCard/UserCard";
import classes from "./trainersCard.module.css";
import i18n from "../../../i18n/i18n";

function TrainersCard({ trainers }) {
  require("./trainersCard.css");
  const { t } = useTranslation();
  const { id } = useParams;

  return (
    <div className={trainers.length !== 0 ? classes["trainers-grid"] : ""}>
      {trainers.length !== 0 &&
        trainers?.map((trainer, index) => (
          <UserCard
            key={index}
            index={index}
            img={trainer.img}
            name={i18n.language === "ar" ? trainer.name_ar : trainer.name_en}
            rate={trainer.rating}
            trainer={trainer}
          />
        ))}
    </div>
  );
}

export default TrainersCard;

//  <NavLink
//    to={`/trainers/trainer-details/${trainer.id}`}
//    key={index}
//    className={classes.flexcard}
//  ></NavLink>;

{
  /* <UserCard
    name={trainer.name_ar}
    rate={trainer.rating}
    postionTitle={trainer.job_title_en}
    description={trainer.description_ar}
    img={trainer.img}
    countCourses={trainer.countCourses}
    countUsers={trainer.countUsers}
  /> */
}
