import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import { editExhibition, getExhibition } from "../../../Services/api/Exhibition/ExhibitionProvider";
import SkeletonCard from "../../Shared/Components/Spinner/SkeletonCard";
import CreateExhibition from "./CreateExhibition/CreateExhibition";

const EditExhibitionPage = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const history = useHistory();

  const [getExhibitionLoading, setGetExhibitionLoading] = useState(false);
  const [exhibitionDetails, setExhibitionDetails] = useState(null);

  const getExhibitionDetailsHandler = async () => {
    if (!id) return;
    setGetExhibitionLoading(true);
    await getExhibition(id)
      .then((response) => {
        if (response.data.status) {
          setExhibitionDetails(response.data.data.exhibition);
          setGetExhibitionLoading(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          history.push("/admin/page-not-found");
        }
        setGetExhibitionLoading(false);
      });
  };
  useEffect(() => {
    getExhibitionDetailsHandler();
  }, []);

  const submitHandler = async (formData) => {
    try {
      const response = await editExhibition(id, formData);
      if (response.status === 200 && response.data.status) {
        history.push(`/admin/exhibitions-list`);
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>{response.data.msg ? response.data.msg : "success"}</span>,
          {
            autoClose: 1000,
          }
        );
      } else {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {response.data.msg ? response.data.msg : "Failure in service"}
          </span>
        );
      }
    } catch (err) {
      toast.error(
        <>
          <span
            style={{
              fontSize: 13,
              fontWeight: "bold",
              display: "block",
              paddingBottom: "10px",
            }}
          >
            {err.response.data.msg}
          </span>
          <ul>
            {Object.keys(err.response.data.errors).map((key) => {
              return (
                <li>
                  <span style={{ fontSize: 11 }}>{err.response.data.errors[key]}</span>
                </li>
              );
            })}
          </ul>
        </>
      );
    }
  };

  return (
    <>
      {getExhibitionLoading ? (
        <div
          style={{
            height: "65vh",
            width: "100%",
          }}
        >
          <SkeletonCard />
        </div>
      ) : (
        <CreateExhibition submitHandler={submitHandler} type="edit" exhibitionEditData={exhibitionDetails} />
      )}
    </>
  );
};

export default EditExhibitionPage;
