import { faEye } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import BankImg from "../../../../assets/image/BoothPage/bank.png";
import Logo_user from "../../../../assets/image/BoothPage/logo_user.png";
import EyeImg from "../../../../assets/image/eye.png";
import PaymentDetailsModal from "../../../Shared/Components/PaymentDetailsModal/PaymentDetailsModal";
import classes from "./bootDetails.module.css";

const BootPayment_Response = ({
  userName,
  userImage,
  payment,
  paymentDetailsHandler,
  paymentMethod,
  status,
  type,
  isFree,
}) => {
  const { t, i18n } = useTranslation();

  const paymentDisplay = {
    Bank: t("Exhibition.popuppaymentbanktran"),
    Wallet: t("Exhibition.popuppaymentwallet"),
    Visa: t("Exhibition.visa"),
  };

  const displayStatus = {
    Waiting: t("financial.label.waiting"),
    Approved: t("financial.label.approved"),
    Rejected: t("financial.label.rejected"),
  };

  return (
    <div className={classes["boot_payment_response"]}>
      <div className={classes["full_card"]}>
        <h4 style={{ color: "#066d77", marginBottom: "1rem" }}>{t("booth.sponser")}</h4>
        <div className={classes["card_response"]}>
          <img src={userImage ? userImage : Logo_user} alt="" />
          <p>{userName}</p>
        </div>
      </div>

      {type !== "draftBooth" && (
        <div className={classes["full_card"]}>
          <h4 style={{ color: "#066d77", marginBottom: "1rem" }}>{t("booth.payment_method")}</h4>
          <div className={classes["card_payment"]}>
            {status ? (
              <>
                <div className={classes["d-flex-"]}>
                  <img src={BankImg} alt="" />
                  <p>{isFree ? t("free") : paymentDisplay[paymentMethod]}</p>
                  {paymentMethod === "Bank" && (
                    <button
                      className={classes["card_payment-details"]}
                      onClick={() => {
                        paymentDetailsHandler(payment);
                      }}
                      title={t("Exhibition.view_details")}
                    >
                      <FontAwesomeIcon icon={faEye} color="#24b3b9" />
                    </button>
                  )}
                </div>

                <div className={classes["card_payment-label"]}>
                  {isFree ? t("booth.free") : <span className={classes[status]}>{displayStatus[status]}</span>}
                </div>
              </>
            ) : (
              <div className={classes["booth-details_no-data"]}>{t("booth.no-payment")}</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default BootPayment_Response;
