import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAllBlocks } from "../../../../Redux/Actions/block/block.actions";
import { BlockedProvider } from "../../../../Services/api/ipBlocked/ipBlockedContext";
import { allBlock } from "../../../../Services/api/ipBlocked/ipBlockedProvider";
import HomeIcon from "../../../../assets/icons/home.svg";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import IpBlockedList from "../IpBlockedList/IpBlockedList";

function IpBlocked() {
  require("./IpBlocked.css");

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);
  const [isResetApplied, setIsResetApplied] = useState(false);

  const { allBlocksLoading, allBlocks, allBlocksMeta, allBlocksError, allBlocksErrorMessage } = useSelector(
    (state) => state.blockReducer
  );

  const [filterData, setFilterData] = useState({
    name: "",
    customizeBan: "",
    range: "",
    ip: "",
    start_ip: "",
    end_ip: "",
    country: "",
    desired_action: "",
    allow_unblock: "",
    date: "",
  });

  const getFilterData = () => {
    const filter = {};
    filterData.name && (filter.name = filterData.name);
    filterData.customizeBan && (filter.method = filterData.customizeBan);
    filterData.range && (filter.range = filterData.range);
    filterData.ip && (filter.ip = filterData.ip);
    filterData.start_ip && (filter.start_ip = filterData.start_ip); // TODO: check if this is correct
    filterData.end_ip && (filter.end_ip = filterData.end_ip); // TODO: check if this is correct
    filterData.country && (filter.country = filterData.country);
    filterData.desired_action && (filter.action = filterData.desired_action);
    filterData.allow_unblock && (filter.allow_unblock = filterData.allow_unblock); // TODO: check if this is correct
    filterData.date && (filter.date = filterData.date); // TODO: check if this is correct

    return filter;
  };

  const getBlockListHandler = async () => {
    const filter = getFilterData();

    dispatch(
      getAllBlocks({
        perPage: 10,
        page: currentPage,
        filter,
      })
    );
  };

  const resetFilterHandler = () => {
    setFilterData({
      name: "",
      customizeBan: "",
      range: "",
      ip: "",
      country: "",
      desired_action: "",
    });
    setIsResetApplied(true);
  };
  useEffect(() => {
    getBlockListHandler();
    setIsResetApplied(false);
  }, [currentPage, isResetApplied]);

  const applyFilterHandler = async () => {
    getBlockListHandler();
  };

  // useEffect(() => {
  //   if (!allBlocksError) return;
  //   toast.error(<span style={{ fontSize: 15 }}>{allBlocksErrorMessage}</span>);
  // }, [allBlocksError, allBlocksErrorMessage]);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "blockListAdmin",
      page: t("admin.label.admin_label"),
      pagePath: "/admin",
    },
    {
      id: "blockList",
      page: t("block.label.block_list"),
      active: true,
    },
  ];
  return (
    <BlockedProvider>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="pt-5 pb-4">
              <Breadcrumb list={breadcrumbList} />
            </div>

            <IpBlockedList
              setCurrentPage={setCurrentPage}
              setFilterData={setFilterData}
              filterData={filterData}
              applyFilterHandler={applyFilterHandler}
              resetFilterHandler={resetFilterHandler}
            />
          </div>
        </div>
      </div>
    </BlockedProvider>
  );
}

export default IpBlocked;
