import { useTranslation } from "react-i18next";
import DeleteIcon from "../../../../../../../assets/icons/delete.svg";
import PreviewIcon from "../../../../../../../assets/icons/eye.svg";
import EditIcon from "../../../../../../../assets/icons/pen.svg";

export const ActivitiesActions = ({ editAction, deleteAction, viewAction }) => {
  const { t } = useTranslation();
  const actionsList = [
    {
      id: "view",
      icon: <img src={PreviewIcon} alt="" />,
      action: viewAction,
      toolTip: t("preview_content"),
    },
    ...(!editAction
      ? []
      : [
          {
            id: "edit",
            icon: <img src={EditIcon} alt="" />,
            action: editAction,
            toolTip: t("edit"),
          },
        ]),
    ...(!deleteAction
      ? []
      : [
          {
            id: "delete",
            icon: <img src={DeleteIcon} alt="" />,
            action: deleteAction,
            toolTip: t("delete"),
          },
        ]),
  ];
  return actionsList;
};
