import React, { useEffect, useRef, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import IconTooltips from '../../Components/tooltips/IconsTooltip';
import BoothRatesModal from "../../Modals/exhibition/BoothRatesModal";
import { getBoothRatings, getStatisticsBooth } from "../../Services/api/Exhibition/ExhibitionProvider";
import { BasicModal } from "../Shared/Components/Modal/BasicModal";
import SkeletonCard from "../Shared/Components/Spinner/SkeletonCard";


export default function StatisticsBooth() {
    require("./exhibition.css");
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [ratings, setRatings] = useState([]);
    const boothRateRef = useRef();

    const [StatisticsBooth,setStatisticsBooth] = useState('')

    useEffect(()=>{
        _getStatisticsBooth(id)
    },[]);

    const _getStatisticsBooth = async(id) => {
        try{
            const res = await getStatisticsBooth(id);
            
            if(res.status == 200){
                setStatisticsBooth(res.data.statistics)
                setIsLoading(false);
            }
            setIsLoading(false);
        }catch(err){
            
        }
    }

    const showRatingsBooth= async(id)=>{
        try{
            const res = await getBoothRatings(id);
            if(res.status == 201){
                setRatings(res.data.ratings)
                boothRateRef.current.showModal();
            }
        }catch(err){
            
        }
    }
    return (
        <div>
             <BasicModal ref={boothRateRef}>
                <BoothRatesModal
                    label={t("booth.rates")}
                    title={t("booth.rates")}
                    body={ratings}
                    boothId={id}
                    boothRateModalRef={boothRateRef}
                    showBoothRates={showRatingsBooth}
                />
            </BasicModal>

            <div className="container-fluid profile_wrapper" style={{paddingTop: '40px'}}>
                <div className="admin_label">
                    <p style={{ padding: "0 5px" }}>/</p>
                    <NavLink to="/">{t("Home")}</NavLink>
                    <p style={{ padding: "0 5px" }}>/</p>
                    {t('Exhibition.Exhibition')}
                </div>
            </div>
            <div className="container-fluid profile_wrapper">
                <div className={isLoading? null: 'table_bank_tran'}>
                        <h3 className="table_header">
                        {t('Exhibition.Exhibition')}
                        </h3>
                    {isLoading ?
                        <div style={{
                            height: "65vh",
                            width: '100%'
                        }}>
                            <SkeletonCard />
                        </div>
                        :
                        <div className="admin_input_sponsor row" >
                            {
                                StatisticsBooth == '' ? (
                                    <p style={{textAlign:'center'}}>{t('booth.notFoundRates')}</p>
                                ):(
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr style={{textAlign:'center'}}>
                                                <th scope="col">#</th>
                                                <th scope="col">{t("Exhibition.visits_count")}</th>
                                                <th scope="col"> {t("Exhibition.avg_rating")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr style={{textAlign:'center'}}>
                                                {/* eslint-disable-next-line jsx-a11y/scope */}
                                                <td scope="row">1</td>
                                                <td>{StatisticsBooth.visits_count}</td>
                                                <td>{StatisticsBooth.avg_rating == null ? '-' : StatisticsBooth.avg_rating}</td>
                                                <td>
                                                    <button
                                                            style={{
                                                                backgroundColor:'#e7e7e7',
                                                                borderWidth:0,
                                                                display: "inline-block",padding:"9px 10px",borderRadius:"10px"
                                                            }}
                                                        >
                                                            <IconTooltips
                                                                title={t('Exhibition.statistics')}
                                                                content={<i   style={{
                                                                    fontSize: 20,
                                                                    color:'black'
                                                                }} className="fal fa-poll"
                                                            onClick={() => showRatingsBooth(id)}
                                                            ></i>}
                                                            />
                                                        </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                            )}
                            {/* {
                                dataComing.map((data,key)=>{
                                    return(
                                        <div key={key} style={{padding:"0 10px", marginBottom:'20px'}} className='col-md-4'>
                                            <img src={image} alt='' style={{maxWidth: "350px", width: "100%"}}/>
                                            <p style={{paddingTop:"10px"}}>{data.name}</p>

                                            {
                                                data.is_active || data.is_full || data.user_booths.length > 0 ?
                                                        <button className='admin_button_green' style={{cursor: "not-allowed",
                                                            background: "#ddd",border: "none",color: "#9a9a9a",display: "inline-block"
                                                        }} >{t('booth.Reserve_corner')}</button>
                                                :
                                                    <NavLink
                                                        to={{
                                                            pathname: `/exhibition-booth/${data.id}`,
                                                            state: {data: data},
                                                        }}
                                                        style={{ border: "none", }}
                                                        className='admin_button_green'
                                                         >{t('booth.Reserve_corner')}</NavLink>
                                            }
                                            {
                                                data.is_active?
                                                    <NavLink
                                                        to={{
                                                            pathname: `/show-exhibition/unity-iframe`,
                                                        }}
                                                        style={{ border: "none", margin:"0 10px", background:"#000", color: "#fff", padding: "9px 19px", borderRadius: "5px",
                                                        display: "inline-block", marginBottom: "10px" }}
                                                        className='admin_button_green'
                                                        >{t('booth.show')}</NavLink>
                                            :
                                                null
                                            }
                                            {
                                                data.user_booths.length > 0 ?
                                                    <>
                                                        <NavLink
                                                            to={{
                                                                pathname: `/edit-booth/${data.user_booths[0].id}`,
                                                                state: {data: data},
                                                            }}
                                                            className='admin_button_green'
                                                            style={{margin:"0 10px", padding: "9px 19px", borderRadius: "5px" }}
                                                            >{t('booth.edit')}</NavLink>
                                                        <button
                                                            style={{
                                                                backgroundColor:'#e7e7e7',
                                                                borderWidth:0,
                                                                display: "inline-block",padding:"9px 10px",borderRadius:"10px"
                                                            }}
                                                        >
                                                            <IconTooltips
                                                                title={t('Exhibition.statistics')}
                                                                content={<i   style={{
                                                                    fontSize: 20,
                                                                    color:'black'
                                                                }} className="fal fa-poll"
                                                            onClick={() => showStatisticsBooth(data.user_booths[0].id)}
                                                            ></i>}
                                                            />
                                                        </button>
                                                    </>
                                                :
                                                    null
                                            }

                                        </div>
                                    )
                                })
                            } */}


                        </div>
                    }
                </div>
            </div>
        </div>

    )
}

