import React, { useState } from "react";
import { useTranslation } from "react-i18next";

function SetDegreeForAnswerToQuestionModal(props) {
  require("./SetDegreeForAnswerToQuestionModal.css");
  const { t } = useTranslation();

  const [degree, setDegree] = useState("");
  const [feedback, setFeedback] = useState("");
  

  return (
    <>
      <div
        className={"tajah-basic-modal-md"}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <div className={"tajah-basic-modal-header"}>
          <h4>{props.title}</h4>
          <div>
            <i
              className="fas fa-times tajah-modal-dismiss"
              onClick={() => {
                props.modalRef.current.dismissModal();
              }}
            ></i>
          </div>
        </div>

        <div className={"tajah-basic-modal-body"}>
          <div className={"tajah-form-row"}>
            <div className={"tajah-form-row-element-v2"}>
              <div
                className={"tajah-form-row-element-holder"}
                style={{ width: "100%" }}
              >
                <label htmlFor="grade" className="admin_add_label">
                  {t("trainer.quiz.crud.grade")}
                </label>
                <input
                  id="grade"
                  type="number"
                  value={degree}
                  className="admin_filter_input"
                  onChange={(elem) => {
                    const degree = parseInt(props.question.degree);
                    let value = elem.target.value;
                    if (value < 0) value = 0;
                    else if (value > degree) value = degree;
                    setDegree(value);
                  }}
                />
              </div>
              <div
                className={"tajah-form-row-element-holder"}
                style={{ width: "100%" }}
              >
                <label htmlFor="feedback" className="admin_add_label">
                  {t("trainer.quiz.crud.feedback")}
                </label>
                <textarea
                  id="feedback"
                  value={feedback}
                  className="admin_filter_input"
                  onChange={(elem) => setFeedback(elem.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={"tajah-basic-modal-footer"}>
          <button
            style={{
              marginLeft: document.body.dir == "rtl" ? 10 : 0,
              marginRight: document.body.dir == "ltr" ? 10 : 0,
            }}
            className={"btn btn-success"}
            onClick={() => {
              props.accept(degree, feedback);
              props.modalRef.current.dismissModal();
            }}
          >
            {props.label}
          </button>
          <button
            type={"button"}
            onClick={() => {
              props.modalRef.current.dismissModal();
            }}
            className={"btn"}
          >
            {t("modal.common.cancel")}
          </button>
        </div>
      </div>
    </>
  );
}

export default SetDegreeForAnswerToQuestionModal;
