import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { getExhibition } from "../../../../Services/api/Exhibition/ExhibitionProvider";
import HomeIcon from "../../../../assets/icons/home.svg";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import ExhibitionDetailsAudios from "./ExhibitionDetailsAudios";
import ExhibitionDetailsBoothsTable from "./ExhibitionDetailsBoothsTable";
import ExhibitionDetailsCarousel from "./ExhibitionDetailsCarousel";
import ExhibitionDetailsDocuments from "./ExhibitionDetailsDocuments";
import ExhibitionDetailsHead from "./ExhibitionDetailsHead";
import ExhibitionDetailsLinks from "./ExhibitionDetailsLinks";
import ExhibitionDetailsPref from "./ExhibitionDetailsPref";
import ExhibitionDetailsPrices from "./ExhibitionDetailsPrices";
import ExhibitionDetailsSlider from "./ExhibitionDetailsSlider";
import ExhibitionDetailsUsersTable from "./ExhibitionDetailsUsersTable";
import classes from "./exhibitionDetails.module.css";

const ExhibitionDetails = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const [exhibitionDetails, setExhibitionDetails] = useState(null);
  const [getExhibitionLoading, setGetExhibitionLoading] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "exhibition",
      page: t("Exhibition.Exhibition"),
      pagePath: "/admin/exhibitions-list",
    },
    {
      id: "exhibition-details",
      page: exhibitionDetails?.name || "",
      active: true,
    },
  ];

  const getExhibitionDetailsHandler = async () => {
    if (!id) return;
    setGetExhibitionLoading(true);
    await getExhibition(id)
      .then((response) => {
        if (response.data.status) {
          setExhibitionDetails(response.data.data.exhibition);
          setGetExhibitionLoading(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          history.push("/admin/page-not-found");
        }
        setGetExhibitionLoading(false);
      });
  };

  useEffect(() => {
    getExhibitionDetailsHandler();
  }, []);

  useEffect(() => {
    if (!exhibitionDetails) return;
    const locationState = history?.location?.state;
    if (!locationState) return;
    const targetId = locationState?.targetId;
    if (!targetId) return;
    const element = document.getElementById(targetId);
    if (element) {
      element.scrollIntoView(
        {
          behavior: "smooth",
          block: "center",
          inline: "center",
        },
        1000
      );
    }
  }, [exhibitionDetails, getExhibitionLoading]);

  const scrollToUserTableHandler = () => {
    const element = document.getElementById("users-table");
    if (element) {
      element.scrollIntoView(
        {
          behavior: "smooth",
          block: "center",
          inline: "center",
        },
        1000
      );
    }
  };

  return (
    <div className={classes["exhibition-details"]}>
      {isExportLoading && <SkeletonCardOverlay skeletonWidth="100" />}
      <div className="container-fluid">
        <div className={classes["exhibition-details__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {getExhibitionLoading ? (
                <div
                  style={{
                    height: "65vh",
                    width: "100%",
                  }}
                >
                  <SkeletonCard />
                </div>
              ) : (
                <MainBox>
                  {exhibitionDetails && (
                    <>
                      <ExhibitionDetailsHead
                        image={exhibitionDetails.logo}
                        name={exhibitionDetails.name}
                        startDate={moment(exhibitionDetails.start_time).format("DD/MM")}
                        endDate={moment(exhibitionDetails.end_time).format("DD/MM")}
                        boothsNumber={exhibitionDetails.booths_no}
                        rating={exhibitionDetails.rate_avg}
                        price={exhibitionDetails.entrance_cost}
                        userStatus={exhibitionDetails?.status == 1}
                        canEdit={new Date(exhibitionDetails.end_time) > Date.now()}
                        editAction={() => {
                          history.push(`/admin/edit-exhibition/${exhibitionDetails?.id}`);
                        }}
                        // userAction={() => {
                        //   scrollToUserTableHandler();
                        // }}
                        reportAction={() => {
                          history.push(`/admin/exhibition-report/${exhibitionDetails?.id}`);
                        }}
                      />
                      <ExhibitionDetailsPref
                        title={t("Exhibition.summary")}
                        description={exhibitionDetails.description}
                      />
                      <ExhibitionDetailsAudios
                        mainAudio={exhibitionDetails.audio}
                        backgroundAudio={exhibitionDetails.background_audio}
                      />
                      <ExhibitionDetailsPrices
                        prices={[
                          {
                            label: t("Exhibition.boothSmPrice"),
                            price: exhibitionDetails.small_price,
                            backgroundColor: "#046C77",
                            type: exhibitionDetails.small_price !== ".00" ? "paid" : "free",
                          },
                          {
                            label: t("Exhibition.boothMdPrice"),
                            price: exhibitionDetails.medium_price,
                            backgroundColor: "#046C77",
                            type: exhibitionDetails.medium_price !== ".00" ? "paid" : "free",
                          },
                          {
                            label: t("Exhibition.boothLgPrice"),
                            price: exhibitionDetails.large_price,
                            backgroundColor: "#046C77",
                            type: exhibitionDetails.large_price !== ".00" ? "paid" : "free",
                          },
                        ]}
                      />
                      <div className={classes["exhibition-details__files"]}>
                        {!!exhibitionDetails.documents.length && (
                          <ExhibitionDetailsDocuments documents={exhibitionDetails.documents} />
                        )}
                        {!!exhibitionDetails.webinars.length && (
                          <ExhibitionDetailsLinks links={exhibitionDetails.webinars} />
                        )}
                      </div>
                      <ExhibitionDetailsCarousel
                        photos={exhibitionDetails.photos}
                        title={t("Exhibition.photo_gallery")}
                      />
                      <div id="booths-list">
                        <ExhibitionDetailsBoothsTable
                          id={id}
                          mode={exhibitionDetails?.mode}
                          setIsExportLoading={setIsExportLoading}
                        />
                      </div>
                      <div id="sponsors-list">
                        <ExhibitionDetailsSlider id={id} title={t("sponsor.sponsor")} />
                      </div>
                      <div id="users-table">
                        <ExhibitionDetailsUsersTable
                          id={id}
                          mode={exhibitionDetails?.mode}
                          setIsExportLoading={setIsExportLoading}
                        />
                      </div>
                    </>
                  )}
                </MainBox>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExhibitionDetails;
