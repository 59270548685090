function clearEmptyProp (props){
    let toBeReturnedItems = {};
    for (const [key, value] of Object.entries(props)) {
        if(!(value === undefined || value === null || value === '')){
            toBeReturnedItems[key] = value;
        }
    }

    return toBeReturnedItems;
}

export default clearEmptyProp;
