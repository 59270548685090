import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function PopUp({
	children,
	open,
	setOpen,
	width,
	ariaDescribedby,
	bgColor,
	borderRadius,
	px,
	py,
	sx,
	maxWidth,
	minHeight,
}) {
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Dialog
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleClose}
			aria-describedby={ariaDescribedby}
			fullWidth={width ? true : false}
			maxWidth={width}
			sx={{
				"& .MuiPaper-root.MuiPaper-elevation": {
					bgcolor: bgColor || "white",
					borderRadius: borderRadius || "8px",
					px: px || "16px",
					py: py || "32px",
				},
				"& .MuiPaper-root": {
					width: "100% !important",
					maxWidth: maxWidth,
					minHeight: minHeight,
				},
				...sx,
			}}
		>
			{children}
		</Dialog>
	);
}
