export const couponsListConstants = {
  // GET ALL COUPONS LIST
  GET_COUPONS_LIST_REQUEST: "GET_COUPONS_LIST_REQUEST",
  GET_COUPONS_LIST_SUCCESS: "GET_COUPONS_LIST_SUCCESS",
  GET_COUPONS_LIST_REJECTED: "GET_COUPONS_LIST_REJECTED",

  // DELETE COUPON
  DELETE_COUPON_REQUEST: "DELETE_COUPON_REQUEST",
  DELETE_COUPON_SUCCESS: "DELETE_COUPON_SUCCESS",
  DELETE_COUPON_REJECTED: "DELETE_COUPON_REJECTED",

  // UPDATE COUPON STATUS
  UPDATE_COUPON_STATUS_REQUEST: "UPDATE_COUPON_STATUS_REQUEST",
  UPDATE_COUPON_STATUS_SUCCESS: "UPDATE_COUPON_STATUS_SUCCESS",
  UPDATE_COUPON_STATUS_REJECTED: "UPDATE_COUPON_STATUS_REJECTED",
}