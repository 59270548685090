"use client";

import { Button } from "@mui/material";

const MainBtn = ({
	sx,
	color,
	width,
	borderRadius,
	height,
	children,
	type,
	variant,
	onClick,
	disableRipple,
}) => {
	return (
		<Button
			disableRipple={disableRipple}
			type={type}
			color={color}
			variant={variant || "contained"}
			sx={{
				width: width || "100%",
				borderRadius: borderRadius || "6px",
				height: height,
				minWidth: "unset",
				...sx,
			}}
			onClick={onClick}
		>
			{children}
		</Button>
	);
};

export default MainBtn;
