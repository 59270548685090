import { ReactComponent as ActivityIcon } from "../../../../../assets/icons/ActivityIcon.svg";

import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import RatingModal from "../../../../../Modals/RatingModal/RatingModal";
import { addActivityRate } from "../../../../../Services/api/ActivityRating";
import { BasicModal } from "../../../../Shared/Components/Modal/BasicModal";
import SkeletonCardOverlay from "../../../../Shared/Components/Spinner/SkeletonCardOverlay";
import StarsRating from "../../../../Shared/Components/StarsRating/StarsRating";
import LessonItem from "./LessonItem";

export default function LessonActivityItem({
  courseId,
  chapterId,
  unitId,
  lessonId,
  activity,
  onOpen,
  fetchData,
}) {
  const { t } = useTranslation();
  const history = useHistory();

  const openRatingModalRef = useRef();
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [isRatingLoading, setIsRatingLoading] = useState(false);

  const handleOpenRatingModal = () => {
    setShowRatingModal(true);
    openRatingModalRef.current.showModal();
  };

  const handleCloseRatingModal = () => {
    setShowRatingModal(false);
    openRatingModalRef.current.dismissModal();
  };

  const submitRating = async (data) => {
    try {
      setIsRatingLoading(true);

      const dataToBeUploaded = {
        rateable_id: activity.id,
        description: data.description,
        stars: data.stars,
      };

      let response = await addActivityRate(dataToBeUploaded);

      if (response.status === 201 && response.data.status) {
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {t("rate.label.success")}
          </span>
        );
        setIsRatingLoading(false);
      } else {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {response.data.msg ? response.data.msg : "Failure in service"}
          </span>
        );
        setIsRatingLoading(false);
      }
    } catch (err) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {err.response.data.msg ? err.response.data.msg : "Failure in service"}
        </span>
      );
      setIsRatingLoading(false);
    }
    handleCloseRatingModal();
    fetchData();
  };

  const params = {
    icon: <ActivityIcon className="tw-h-5" />,
    name: t("trainer.course.lesson.activities"),
    title: activity.title,
    columns: [
      {
        label: "عدد المحاولات",
        value: `${activity.my_answered_count}/${activity.answer_attempts}`,
      },
      ...(activity.type !== "hotspot"
        ? [
            {
              label: t("passPercent"),
              value: `${
                Math.round(parseFloat(activity.success_mark) * 100) / 100
              }%`,
            },
          ]
        : []),
      {
        label: t("activityGrade"),
        value: `${Math.round(parseFloat(activity.activity_mark) * 100) / 100}`,
      },
    ],
    buttons: [
      {
        label: t("participate"),
        onClick: () => {
          onOpen();
          history.push(
            `/training-courses/${courseId}/content/${chapterId}/${unitId}/lesson/${lessonId}/activity/${activity.id}`
          );
        },
      },
      {
        label: t("rate_now"),
        onClick: handleOpenRatingModal,
        secondary: true,
      },
      //   {
      //     label: "النتائج",
      //     onClick: () => {},
      //     secondary: true,
      //   },
    ],
    footer: {
      flags: {
        include_view: activity.include_view_per,
        include_achivement: activity.include_achivement_per,
        finished: activity.my_answered_count >= activity.answer_attempts,
        is_watched: activity.is_watched,
        is_achieved: activity.is_achieved,
      },
      stars: () => (
        <StarsRating
          rating={activity.ratings_avg_stars || 0}
          cutStyle={{ width: 20, height: 20 }}
        />
      ),
    },
  };
  return (
    <>
      {isRatingLoading && <SkeletonCardOverlay skeletonWidth="100" />}
      <LessonItem {...params} />
      <BasicModal ref={openRatingModalRef}>
        {showRatingModal && (
          <RatingModal
            handleSubmit={submitRating}
            handleCancel={handleCloseRatingModal}
          />
        )}
      </BasicModal>
    </>
  );
}
