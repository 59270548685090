import AssignmentIcon from "../../../../assets/icons/assignment.png";
import surveyIcon from "../../../../assets/icons/survey.svg";
import i18n from "../../../../i18n/i18n";
import classes from "./trainingCourseContentLessons.module.css";

export const LessonActions = ({
  assignmentAction,
  surveyAction,
  lessonQuiz,
  lesson
}) => {
  const actionsList = [
    ...(lessonQuiz?.length > 0 && assignmentAction
      ? [
          {
            id: "assignment",
            icon: (
              <img
                style={{
                  width: "100%",
                  height: "100%",
                }}
                src={AssignmentIcon}
                alt=""
              />
            ),
            label: `${i18n.language === "ar" ? "الاختبارات" : "Assignments"} (${lesson?.available_quizzes_count})`,
            action: () => assignmentAction(lessonQuiz),
          },
        ]
      : []),
    ...(surveyAction
      ? [
          {
            id: "survey",
            icon: (
              <img
                style={{
                  width: "100%",
                  height: "100%",
                }}
                src={surveyIcon}
                alt=""
              />
            ),
            label: i18n.language === "ar" ? "الأستبيانات" : "Survey",
            action: () => surveyAction(),
          },
        ]
      : []),
  ];
  return (
    <div className={classes["main-box__head__actions"]}>
      {actionsList.map((action, index) => (
        <button
          className="tw-px-4 tw-text-white tw-rounded"
          style={{
            backgroundColor: action.color,
            color: action.textColor,
            border: "1px solid #24b3b9",
            borderRadius: 6,
            width: 275,
            height: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 10,
          }}
          onClick={action.action}
          key={index}
        >
          <div className={classes["main-box__head__action__icon"]}>
            {action.icon}
          </div>
          <span style={{ color: "#24b3b9" }}>{action.label}</span>
        </button>
      ))}
    </div>
  );
};
