import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { aboutUS } from "../../Services/api/toutorials/aboutUsProvider";
import { Lang } from "../../utils";
import { BoxCard, ContainerBox } from "../Shared/Components";
// import { Lang } from "./../../utils";

function About(props) {
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"));
  const [aboutUs, setAboutUs] = useState({});
  // require("./About.css");
  const { t, i18n } = useTranslation();

  const [isLoading, setIsloading] = useState(true);

  useEffect(() => {
    aboutUS()
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setAboutUs(res.data.aboutUS);
          setIsloading(false);
        }
      })
      .catch((err) => {
        
      });
  }, []);
  return (
    <Stack component={"section"}>
      <ContainerBox>
        <BoxCard sx={{ mt: "52px" }}>
          <Box
            position={"relative"}
            sx={{
              "& .typoAbout": {
                color: "grey.100",
                fontSize: theme.typography.h5,
                textAlign: "justify",
                mb: "22px",
                "&:last-child": {
                  mb: 0,
                },
              },
            }}
          >
            <Typography
              component={"h1"}
              variant="h2"
              color={"primary.main"}
              fontWeight={500}
              mb={"32px"}
              fontFamily="dinNextRegular"
            >
              {t("AboutUs")}
            </Typography>
            <Box
              sx={{
                "& p": {
                  textAlign: "justify",
                },
                "& .se-image-container:first-of-type img": {
                  width: 500,
                  maxHeight: 500,
                  shapeOutside: "url(assets/images/about-img.webp)",
                  shapeMargin: upMd ? "45px" : "0",
                  marginRight:
                    upMd && i18n.language == Lang.AR ? "45px" : upMd && i18n.language == Lang.EN ? "0" : "auto",
                  marginLeft:
                    upMd && i18n.language == Lang.AR ? "0" : upMd && i18n.language == Lang.EN ? "45px" : "auto",
                  marginBottom: upMd ? "10px" : "45px",
                  float:
                    upMd && i18n.language == Lang.AR ? "left" : upMd && i18n.language == Lang.EN ? "right" : "none",
                  borderRadius: "27px",
                  maxWidth: "100%",
                },
              }}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: aboutUs[`description_${i18n.language}`],
                }}
                className="text-muted me-2 fs-7 fw-semibold"
              ></span>
            </Box>
          </Box>
        </BoxCard>
      </ContainerBox>
    </Stack>
  );
}

export default About;
