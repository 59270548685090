import { useTranslation } from "react-i18next";
import ReportIcon from "../../../../../assets/icons/analyze.svg";
import DeleteIcon from "../../../../../assets/icons/delete.svg";
import EditIcon from "../../../../../assets/icons/pen.svg";
import SendReminder from "../../../../../assets/icons/send-reminder.png";
import LinkIcon from "../../../../../assets/icons/url.png";

export const ZoomSessionActions = ({ editAction, deleteAction, linkAction, reportAction, sendReminder }) => {
  const { t } = useTranslation();

  const actionsList = [
    ...(sendReminder
      ? [
          {
            id: "sendReminder",
            icon: <img src={SendReminder} alt="" />,
            action: sendReminder,
            toolTip: t("send_reminder"),
          },
        ]
      : []),
    ...(reportAction
      ? [
          {
            id: "report",
            icon: <img src={ReportIcon} alt="" />,
            action: reportAction,
            toolTip: t("reports"),
          },
        ]
      : []),
    ...(linkAction
      ? [
          {
            id: "link",
            icon: <img src={LinkIcon} alt="" />,
            action: linkAction,
            toolTip: t("meeting_link"),
          },
        ]
      : []),
    ...(editAction
      ? [
          {
            id: "edit",
            icon: <img src={EditIcon} alt="" />,
            action: editAction,
            toolTip: t("edit"),
          },
        ]
      : []),
    ...(deleteAction
      ? [
          {
            id: "delete",
            icon: <img src={DeleteIcon} alt="" />,
            action: deleteAction,
            toolTip: t("delete"),
          },
        ]
      : []),
  ];
  return actionsList;
};
