import React, { useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { boothActions } from "../../../Redux/Actions/Booth/booth.actions";
import { cancelBoothForSponsor } from "../../../Services/api/Exhibition/ExhibitionProvider";
import HomeIcon from "../../../assets/icons/home.svg";
import BootDetailsAudios from "../../Admin/Exhibition/BoothDetails/BootDetailsAudios";
import BootDetailsCarousel from "../../Admin/Exhibition/BoothDetails/BootDetailsCarousel";
import BootDetailsCarouselVertical from "../../Admin/Exhibition/BoothDetails/BootDetailsCarouselVertical";
import BootDetailsPref from "../../Admin/Exhibition/BoothDetails/BootDetailsPref";
import BootDetailsTable from "../../Admin/Exhibition/BoothDetails/BootDetailsTable";
import BootDetailsVisitsTable from "../../Admin/Exhibition/BoothDetails/BootDetailsVisitsTable";
import BootPayment_Response from "../../Admin/Exhibition/BoothDetails/BootPayment_Response";
import BoothStatus from "../../Admin/Exhibition/BoothDetails/BoothStatus";
import ImageContainer from "../../Admin/Exhibition/BoothDetails/ImageContainer";
import Breadcrumb from "../../Shared/Components/Breadcrumb/Breadcrumb";
import ModelWithInput from "../../Shared/Components/ModelWithInput/ModelWithInput";
import PaymentDetailsModal from "../../Shared/Components/PaymentDetailsModal/PaymentDetailsModal";
import SkeletonCard from "../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../Shared/Components/Spinner/SkeletonCardOverlay";
import classes from "./bootDetails.module.css";

const BoothDetails = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { booth, refundSettings, boothVisitors, boothVisitorsLoading, boothLoading } = useSelector(
    (state) => state.boothReducer
  );
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [payment, setPayment] = useState(null);
  const [boothId, setBoothId] = useState();

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "exhibition",
      page: booth?.name,
      active: true,
    },
  ];

  const getBoothsDetails = async () => {
    dispatch(boothActions.getBooth(id));
  };

  const getBoothVisitors = async () => {
    dispatch(boothActions.getBoothVisitors({ id: id, perPage: 10, page: 1 }));
  };

  useEffect(() => {
    getBoothsDetails();
    getBoothVisitors();
  }, []);

  const paymentDetailsHandler = (payment) => {
    setShowPaymentModal(true);
    setPayment(payment);
  };

  const openCancelModal = (id) => {
    setBoothId(id);
    setShowCancelModal(true);
  };

  const cancelBooth = async (reason) => {
    setSubmitting(true);
    try {
      const payload = {
        booth_id: boothId,
        cancel_reason: reason,
      };
      const res = await cancelBoothForSponsor(payload);
      if (res.status === 201 && res.data.status) {
        toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{res.data.msg}</span>);
        history.push("/exhibition");
      } else {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{res.data.msg}</span>);
      }
    } catch (error) {
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{error.data.msg}</span>);
    } finally {
      setShowCancelModal(false);
      setSubmitting(false);
    }
  };

  return (
    <div className="container-fluid">
      {(isExportLoading || submitting) && <SkeletonCardOverlay skeletonWidth="100" />}

      <div className="row">
        <div className="col-12">
          <div className={classes["breadcrumbs-my-30"]}>
            <Breadcrumb list={breadcrumbList} />
          </div>
          <div className="row">
            <div className="col-12">
              {boothLoading ? (
                <div
                  style={{
                    height: "65vh",
                    width: "100%",
                  }}
                >
                  <SkeletonCard />
                </div>
              ) : (
                <div className="py-30">
                  <ImageContainer
                    booth={booth}
                    exhibition={booth?.exhibition}
                    type="sponsor"
                    isRefundActive={
                      refundSettings?.status &&
                      refundSettings?.is_started &&
                      Number(booth?.sponsor_status) === 2 &&
                      Number(booth?.payment_status) === 2
                    }
                    openCancelModal={openCancelModal}
                  />
                  <BoothStatus
                    sponserStatus={Number(booth?.sponsor_status)}
                    paymentStatus={Number(booth?.payment_status)}
                    rejectReason={booth?.reject_reason || ""}
                    editStatus={Number(booth?.edit_status)}
                  />

                  <BootDetailsPref title={t("Exhibition.summary_Booth")} description={booth.description} />

                  <BootPayment_Response
                    userName={booth?.sponsor?.name || ""}
                    userImage={booth?.sponsor?.image || ""}
                    paymentMethod={booth?.payment?.payment_method || ""}
                    status={booth?.payment?.status}
                    payment={booth?.payment}
                    paymentDetailsHandler={paymentDetailsHandler}
                    isFree={booth?.payment?.amount === ".00"}
                  />

                  <BootDetailsAudios mainAudio={booth?.audio} backgroundAudio={booth?.background_audio} />

                  {!!booth?.banners?.length && <BootDetailsCarousel photos={booth?.banners} />}

                  <div id="booths-list">
                    <BootDetailsTable
                      id={id}
                      tableData={booth?.products || []}
                      type=""
                      setIsExportLoading={setIsExportLoading}
                    />
                  </div>

                  <div className={classes["visits-table"]}>
                    <BootDetailsVisitsTable
                      id={id}
                      tableData={boothVisitors || []}
                      loading={boothVisitorsLoading}
                      setIsExportLoading={setIsExportLoading}
                    />
                  </div>

                  <BootDetailsCarouselVertical webinars={booth?.webinars || []} />
                </div>
              )}
            </div>
          </div>
          {showPaymentModal && (
            <PaymentDetailsModal
              closeModal={() => {
                setShowPaymentModal(false);
                setPayment(null);
              }}
              payment={payment}
            />
          )}
          {showCancelModal && (
            <ModelWithInput
              openModal={showCancelModal}
              setOpenModal={setShowCancelModal}
              modalTitle={t("booth.cancel_booth")}
              buttonText={t("general.submit")}
              type={"textarea"}
              inputLabel={t("booth.cancel_reason")}
              handleSubmit={cancelBooth}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default BoothDetails;
