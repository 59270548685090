import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import { getTaxById, updateTax } from "../../../../../Services/api/Financial/FinancialProvider";
import { getLocaleTime } from "../../../../../utils/utcToLocal";
import SkeletonCard from "../../../../Shared/Components/Spinner/SkeletonCard";

import ManageTax from "./ManageTax";

const EditTax = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [taxDetails, setTaxDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getTaxHandler = async () => {
    await getTaxById(id)
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setTaxDetails(res?.data?.response?.tax);
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          return history.push("/admin/page-not-found");
        }
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {error?.response?.data?.msg ? error?.response?.data?.msg : "Failure in service"}
          </span>
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getTaxHandler();
  }, [id]);

  const submitHandler = async (values) => {
    let response = await updateTax(values, id);
    if (response.status === 200 && response.data.status) {
      history.push(`${localStorage.getItem("type") == "accountant" ? "/accountant" : "/admin"}/tax`);
      toast.success(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {response.data.msg ? response.data.msg : t("coupon.messages.update_success")}
        </span>,
        {
          onClose: () => {},
          autoClose: 1000,
        }
      );
    } else {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {response.data.msg ? response.data.msg : t("coupon.messages.update_failed")}
        </span>
      );
    }
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            height: "65vh",
            width: "100%",
          }}
        >
          <SkeletonCard />
        </div>
      ) : (
        <ManageTax
          type={
            !taxDetails?.is_valid
              ? moment(new Date()).isBefore(getLocaleTime(new Date(taxDetails?.end_date)))
                ? "limitedEdit"
                : "cannotEdit"
              : "canEdit"
          }
          submitHandler={submitHandler}
          taxDetails={taxDetails}
        />
      )}
    </>
  );
};

export default EditTax;
