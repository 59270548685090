export const offerConstant = {
  // loading
  START_LOADING: "START_LOADING",
  STOP_LOADING: "STOP_LOADING",
  RESET: "RESET",

  GET_OFFER: "GET_OFFER",
  POST_OFFER_STATUS: "POST_OFFER_STATUS",
  DELETE_OFFER: "DELETE_OFFER",
  GET_OFFER_BY_ID: "GET_OFFER_BY_ID",
};
