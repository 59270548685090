import React, { useEffect, useState } from "react";
import classes from "./AdDetials.module.css";
import { getAdDetails } from "../../../../Services/api/HomeCoursesProvider";
import { useHistory, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Defualt_img from "../../../../assets/image/defualt_img.png";
import Title from "../../../Shared/Components/Title/Title";
import CustomButton from "../../../Shared/Components/CustomButton/CustomButton";
import ArrowIcon from "../../../../assets/icons/right-arrow-white.svg";

export default function AdDetials() {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [adLoading, setAdLoading] = useState(false);
  const [adDetails, setAdDetails] = useState([]);
  const history = useHistory();
  useEffect(() => {
    // setTrainersLoading(true);
    const apiCall = getAdDetails;

    apiCall(id)
      .then((res) => {
        if (res.status && res.status === 200) {
          setAdDetails(res.data.data);

          setAdLoading(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setAdLoading(true);
      });
  }, []);
  return (
    <>
      <div className={classes.headercontainer}>
        <div
          className={classes["training-course-back"]}
          onClick={() => history.goBack()}
        >
          <div className={classes["training-course-back-icon"]}>
            <img src={ArrowIcon} alt="back" />
          </div>
          <div className={classes["training-course-back-text"]}>
            {t("back")}
          </div>
        </div>
        <div className="container-fluid">
          <div className={classes.headerContent}>
            <div className={classes.headerImage}>
              <img
                src={adDetails.image || Defualt_img}
                alt=""
                className={classes.image}
              />
            </div>
            <div className={classes.headerDetials}>
              <div className={classes.title_date}>
                <h3>{adDetails.title}</h3>
                <span>{moment(adDetails.from_date).format("YYYY/MM/DD")}</span>
              </div>
              {adDetails?.has_button && (
                <div className={classes["search_container"]}>
                  <CustomButton
                    value={adDetails?.button_text}
                    colors={"#fff"}
                    classes={classes.btnRegister}
                    action={() => window.open(adDetails?.url, "_blank")}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`container-fluid ${classes.training_course_content_info}`}
        style={{ paddingTop: 20 }}
      >
        <Title title={t("Over_view")} fontSize={24} />
        <div className={classes["description-content"]}>
          <p>{adDetails.description}</p>
        </div>
      </div>
    </>
  );
}
