import React, {useState} from "react";

import {useTranslation} from "react-i18next";

import i18n from "../../i18n/i18n";
import {Lang} from "../../utils";

function QuestionDetailModal(props) {
  require("./QuestionModal.css");
  
  const {t} = useTranslation();
  

  return (
      <>
          <div
              className={"tajah-basic-modal-mui"}
              onClick={(event) => {
                  event.stopPropagation();
              }}
          >
              <div className={"tajah-basic-modal-header"}>
                {props.type == "trainees"?(
                    <h4>{props.title}</h4>
                ):(
                    <h6 style={{color:'red'}}>{props.title}</h6>
                )}     
              </div>
              <div className={"tajah-basic-modal-body"}>

              </div>
          </div>
      </>
  );
}

export default QuestionDetailModal;
