import React from "react";
import browsePhoto from "../../../assets/image/photo.png";
import ear from "../../../assets/image/eardoc.png";
import {useTranslation} from "react-i18next";

function ModifyTheCourse() {
  const { t } = useTranslation();

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="training_first_label">{t("homemodify")}</div>
          <div className="personal_info_label">
            <div className="personal_info_top">{t("modify")}</div>
            <div className="personal_info_bottom">{t("pleaseaddall")}</div>
          </div>
          <div className="personal_info_card_st">
            <div className="personal_info_card">
              <div className="perosnal_text_img">{t("coursephoto")}</div>
              <div className="course_img">
                <div className="">
                  <div className="">
                    <div className="">
                      <img src={ear} alt="" className="course_dimage" />
                    </div>
                    <div className="course_browse">
                      <img
                        src={browsePhoto}
                        alt=""
                        className="personal_browse_img"
                      />
                    </div>
                  </div>
                </div>
                <div className="personal_img_text">{t("choosepng")}</div>
              </div>

              <div className="personal_form">
                <form id="contact" className="form">
                  <div className="row">
                    <div className="col-lg-12 form-group">
                      <div className="personal_input">
                        <label className="personal_label">
                          {t("coursename")} :
                        </label>
                        <input
                          className="personal_input_in"
                          id="name"
                          name="name"
                          placeholder=""
                          type="text"
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 form-group">
                      <div className="personal_input">
                        <label className="personal_label">
                          {t("coursecode")} :
                        </label>
                        <input
                          className="personal_input_in"
                          id="name"
                          name="name"
                          placeholder=""
                          type="text"
                        />
                      </div>
                    </div>

                    <div className="col-12 col-sm-12 form-group">
                      <div className="personal_input">
                        <label className="personal_label">
                          {t("coursedivision")}
                        </label>

                        <select className="form-personal_input_in personal_select_box">
                          <option>Default select</option>
                          <option>Default select</option>
                          <option>Default select</option>
                          <option>Default select</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-12 col-sm-12 form-group">
                      <div className="personal_input">
                        <label className="personal_label">
                          {t("selectselection")}
                        </label>

                        <select className="form-personal_input_in personal_select_box">
                          <option>Default select</option>
                          <option>Default select</option>
                          <option>Default select</option>
                          <option>Default select</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-12 col-sm-12 form-group">
                      <div className="personal_input">
                        <label className="personal_label">
                          {t("classification")}
                        </label>

                        <select className="form-personal_input_in personal_select_box">
                          <option>Default select</option>
                          <option>Default select</option>
                          <option>Default select</option>
                          <option>Default select</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-lg-12 form-group">
                      <div className="personal_input">
                        <label className="personal_label">{t("brief")} :</label>
                        <input
                          className="personal_input_in"
                          id="name"
                          name="name"
                          placeholder=""
                          type="text"
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 form-group">
                      <div className="personal_input">
                        <label className="personal_label">
                          {t("description")}
                        </label>
                        <input
                          className="personal_input_in"
                          id="name"
                          name="name"
                          placeholder=""
                          type="text"
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 form-group">
                      <div className="personal_input">
                        <label className="personal_label">{t("linkto")}</label>
                        <input
                          className="personal_input_in"
                          id="name"
                          name="name"
                          placeholder=""
                          type="text"
                        />
                      </div>
                    </div>

                    <div className="col-12 col-sm-12 form-group">
                      <div className="personal_input">
                        <label className="personal_label">
                          {t("courselanguage")}
                        </label>

                        <select className="form-personal_input_in personal_select_box">
                          <option>Default select</option>
                          <option>Default select</option>
                          <option>Default select</option>
                          <option>Default select</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-lg-12 form-group">
                      <div className="personal_input">
                        <label className="personal_label">{t("maximum")}</label>
                        <input
                          className="personal_input_in"
                          id="name"
                          name="name"
                          placeholder=""
                          type="text"
                        />
                      </div>
                    </div>

                    <div className="col-12 col-sm-12 form-group">
                      <div className="personal_input">
                        <label className="personal_label">
                          {t("prerequsiite")}
                        </label>

                        <select className="form-personal_input_in personal_select_box">
                          <option>Default select</option>
                          <option>Default select</option>
                          <option>Default select</option>
                          <option>Default select</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-lg-12 form-group">
                      <div className="personal_input">
                        <label className="personal_label">
                          {t("coursefees")}
                        </label>
                        <input
                          className="personal_input_in"
                          id="name"
                          name="name"
                          placeholder=""
                          type="text"
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 form-group">
                      <label className="personal_label">
                        {t("coursestatus")}
                      </label>
                      <div className="personal_radio">
                        <div className="form-check form-check-inline ">
                          <input
                            className="form-check-input "
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio1"
                            value="option1"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio1"
                          >
                            {t("apparent")}
                          </label>
                        </div>
                        <div className="form-check form-check-inline second_radio">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio2"
                            value="option2"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio2"
                          >
                            {t("hidden")}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xs-12 col-md-12 form-group">
                        <input
                          className="personal_button"
                          type="submit"
                          value={t("addcourse")}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModifyTheCourse;
