import Badge from "../Badge/Badge";
import style from "./imageWithLabel.module.css";

export default function ImageWithLabel({ classes, imgUrl, label, labelColor }) {
  return (
    <div className={`${classes} ${style["wrapper-div"]}`}>
      <img src={imgUrl} alt={label} className={style["header-img"]} />
      <Badge
        label={label}
        labelColor={labelColor}
        classes={style["badge-postion"]}
      />
    </div>
  );
}
