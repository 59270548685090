import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Lang } from "../../../utils";
import { utcToLocal } from "../../../utils/utcToLocal";
import MainTable from "../../Shared/Components/MainTable/MainTable";
import classes from "./invoice.module.css";

const InvoiceTable = ({ users, isLoading, countries }) => {
  const { t, i18n } = useTranslation();

  const columns = [
    {
      id: "order",
      name: "#",
      key: "order",
      cell: ({ rowData, rowIndex }) => <>{rowIndex + 1}</>,
      sortable: false,
    },
    {
      id: "name",
      name: t("general.name"),
      key: "name",
      cell: ({ rowData }) => (
        <div className={classes[`invoice-user-name`]}>
          {rowData?.user?.name}
        </div>
      ),
    },
    {
      id: "email",
      name: t("general.email"),
      key: "email",
      cell: ({ rowData }) => (
        <div className={classes[`invoice-user-email`]}>
          {rowData?.user?.email}
        </div>
      ),
    },
    {
      id: "first_join",
      name: t("zoom.invoice.Entry_time"),
      key: "first_join",
      cell: ({ rowData }) => (
        <div className={classes[`invoice-user-first_join`]}>
          {/* {utcToLocal(rowData?.first_join, i18n.language, "DD/MM/YYYY HH:mm")} */}
          <>
            {moment(rowData?.first_join).format("DD/MM/YYYY")}
            <br />
            {utcToLocal(rowData?.first_join)}
          </>
        </div>
      ),
    },
    {
      id: "last_join",
      name: t("zoom.invoice.time_go_out"),
      key: "last_join",
      cell: ({ rowData }) => (
        <div className={classes[`invoice-user-last_join`]}>
          {/* {utcToLocal(rowData?.last_leave, i18n.language, "DD/MM/YYYY HH:mm")} */}
          <>
            {moment(rowData?.last_leave).format("DD/MM/YYYY")}
            <br />
            {utcToLocal(rowData?.last_leave)}
          </>
        </div>
      ),
    },
    {
      id: "total_duration",
      name: t("zoom.invoice.Total_minutes"),
      key: "total_duration",
      cell: ({ rowData }) => (
        <div className={classes[`invoice-user-total_duration`]}>
          {rowData?.total_duration}
        </div>
      ),
    },
    {
      id: "country",
      name: t("country"),
      key: "country",
      cell: ({ rowData }) => {
        const country = countries?.find(
          (country) => country.id === Number(rowData?.user?.nationality_id)
        );
        return country ? (
          <div className={classes[`invoice-user-country`]}>{country.name}</div>
        ) : (
          "-"
        );
      },
    },
    // {
    //   id: "verified",
    //   name: t("general.verified"),
    //   key: "verified",
    //   cell: ({ rowData }) => (
    //     <div
    //       className={`${classes[`invoice-user-verified`]} ${
    //         classes[`invoice-user-verified-${rowData?.user.verified ? "yes" : "no"}`]
    //       }`}
    //     >
    //       {rowData?.user.verified ? t("yes") : t("no")}
    //     </div>
    //   ),
    // },
  ];

  return (
    <MainTable columns={columns} data={users || []} isLoading={isLoading} />
  );
};

export default InvoiceTable;
