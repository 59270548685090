import { Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import SkeletonCard from "../../Shared/Components/Spinner/SkeletonCard";
import { searchTrainee } from "./../../../Services/api/sponsor/SponsorProvider";
import { AccountantContext } from "./../../Shared/Components/accountant/AccountantContext";
import ModeSponsor from "./../ModeSponsor";

import "./addTrainee.css";

export default function AddTraineeByName(props) {
  const sponsorsContext = useContext(AccountantContext);
  const { t } = useTranslation();
  const history = useHistory();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [addModal, setAddModal] = useState(false);
  const [trainees, setTrainees] = useState([]);
  const [trainee, setTrainee] = useState("");
  setTimeout(() => {
    setIsLoading(false);
  }, 500);
  useEffect(() => {
    sponsorsContext.setSponsor(sponsorsContext.sponsor);
    sponsorsContext.setFormSponsors(sponsorsContext.formSponsors);
  });
  const addTraineeToSponsor = () => {
    let trainees = [];
    trainees.push(trainee);
    trainees = trainees.concat(
      JSON.parse(localStorage.getItem("trainees") || "[]")
    );
    localStorage.setItem("trainees", JSON.stringify(trainees));
    history.push({
      pathname: "/accountant/sponsor/addsponsor",
    });
    sponsorsContext.setSponsor(sponsorsContext.sponsor);
    sponsorsContext.setFormSponsors(sponsorsContext.formSponsors);
    toast.success(
      <span style={{ fontSize: 14 }}>{t("sponsor.messages.addTrainee")}</span>
    );
  };
  return (
    <div>
      {addModal && (
        <>
          <div
            className="backdrop"
            style={{
              position: "fixed",
            }}
            onClick={() => {
              setAddModal(false);
            }}
          />
          <ModeSponsor />
        </>
      )}
      <div className="header_profile">
        <div className="container-fluid profile_wrapper">
          <div className="profile_grid">
            <div className="profile_dhead">
              <h3>
                {t("sponsor.addTrainee.Add_trainee_name")}
                {/* OR */}
                {/* {t("sponsor.addTrainee.Add_trainee_email")} */}
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid profile_wrapper">
        <div className="admin_label">
          <NavLink to="/admin">{t("Home")}</NavLink>
          <p style={{ padding: "0 5px" }}>/</p>
          {t("sponsor.addTrainee.Add_trainee_name")}
        </div>
      </div>
      <div className="container-fluid profile_wrapper">
        <div className="table_bank_tran">
          <h3 className="table_header">
            {t("sponsor.addTrainee.Add_trainee_name")}
          </h3>
          {isLoading ? (
            <div
              style={{
                height: "65vh",
              }}
            >
              <SkeletonCard />
            </div>
          ) : (
            <>
              <div className="row admin_input_sponsor pb-0">
                <div className="col-md-12">
                  <Formik
                    initialValues={{
                      name: "",
                      type:
                        sponsorsContext.formSponsors.type == "course"
                          ? "course"
                          : sponsorsContext.formSponsors.type == "program"
                          ? "program"
                          : "partner",
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                      try {
                        if (sponsorsContext.formSponsors.type == "course") {
                          values.id = sponsorsContext.formSponsors.course_id;
                        } else if (
                          sponsorsContext.formSponsors.type == "program"
                        ) {
                          values.id = sponsorsContext.formSponsors.program_id;
                        } else {
                          values.id = sponsorsContext.sponsor.id;
                        }
                        let response = await searchTrainee(values);
                        setTrainee(response.data.response.trainee);
                      } catch (err) {
                        if (
                          err == "Error: Request failed with status code 422"
                        ) {
                          toast.error(
                            <span style={{ fontSize: 14 }}>
                              {t("sponsor.messages.errorMessage")}
                            </span>
                          );
                        }
                      }
                    }}
                    validateOnChange={hasSubmitted}
                    validate={(values) => {
                      setHasSubmitted(true);
                      const errors = {};
                      if (!values.name) {
                        errors.name = t("crud.errors.required");
                      }
                      return errors;
                    }}
                  >
                    {({
                      values,
                      errors,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      handleBlur,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit} className="admin_add_form">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-7">
                                <label
                                  htmlFor="name"
                                  className="admin_add_label"
                                >
                                  {t("sponsor.addTrainee.Add_trainee_name")}
                                </label>
                                <input
                                  id="name"
                                  type="text"
                                  name="name"
                                  className="admin_add_input"
                                  onChange={handleChange}
                                  value={values.name}
                                />
                                <p className={"form-input-error-space"}>
                                  {errors.name ? errors.name : null}
                                </p>
                              </div>
                              <div className="col-md-5">
                                <button
                                  type="submit"
                                  className={
                                    "btn btn-success add_search_trainee"
                                  }
                                  style={{
                                    backgroundColor: "#006d77",
                                    borderColor: "#006d77",
                                  }}
                                  // onClick={() => {
                                  // }}
                                >
                                  {t("sponsor.addTrainee.search")}
                                </button>
                                <button
                                  className={"btn btn-success add_trainee_mail"}
                                  style={{
                                    backgroundColor: "#006d77",
                                    borderColor: "#006d77",
                                  }}
                                  onClick={() => {
                                    sponsorsContext.setSponsor(
                                      sponsorsContext.sponsor
                                    );
                                    sponsorsContext.setFormSponsors(
                                      sponsorsContext.formSponsors
                                    );
                                    history.push({
                                      pathname:
                                        "/accountant/sponsor/addtraineebyemail",
                                      // state: {
                                      //   type: history.location.state.type,
                                      //   programId:
                                      //     history.location.state.programId,
                                      //   partnerId:
                                      //     history.location.state.partnerId,
                                      // },
                                    });
                                  }}
                                >
                                  {t("sponsor.addTrainee.Add_by_email")}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
              <div className="row admin_input_sponsor pt-0">
                <div className="col-md-12">
                  <Formik
                    initialValues={{
                      name: "",
                      email: "",
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                      try {
                        
                      } catch (err) {
                        
                      }
                    }}
                    validateOnChange={hasSubmitted}
                    validate={(values) => {
                      setHasSubmitted(true);
                      const errors = {};
                      // Validation
                      // return errors;
                    }}
                  >
                    {({
                      values,
                      errors,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      handleBlur,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit} className="admin_add_form">
                        <div className="row">
                          <div className="col-md-12 list_of_trainer">
                            <div className="row">
                              <div className="col-md-6">
                                <h6>
                                  {t("sponsor.addTrainee.total_results")} 3
                                </h6>
                              </div>
                            </div>
                          </div>
                          <div
                            className="table-responsive"
                            style={{
                              width: "100%",
                            }}
                          >
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">{t("sponsor.add.name")}</th>
                                  <th scope="col">{t("sponsor.add.E_Mail")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {trainee == null ? (
                                  <p></p>
                                ) : (
                                  <tr>
                                    <td>
                                      <p></p>
                                    </td>
                                    <td>
                                      {trainee.name_en == null ? (
                                        <p></p>
                                      ) : (
                                        <span>{trainee.name_en}</span>
                                      )}
                                    </td>
                                    <td>
                                      {trainee.email == null ? (
                                        <p></p>
                                      ) : (
                                        <span>{trainee.email}</span>
                                      )}
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                          <div>
                            <button
                              type="submit"
                              disabled={trainee == ""}
                              className="admin_add_button"
                              onClick={addTraineeToSponsor}
                            >
                              {t("sponsor.addTrainee.addition")}
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
