import { makeStyles } from "@material-ui/core/styles";
import { Button, Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Input from "../../../Shared/Components/Input/Input";
import styles from "./ModelWithInput.module.css";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: "block",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    overflowY: "auto",
    // height: "500px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[24],
    padding: theme.spacing(4),
    "&::-webkit-scrollbar": {
      width: 10,
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: 10,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#DADADA",
      borderRadius: 10,
    },
  },
  alertOctagon: {
    marginBottom: 38,
    width: 80,
    height: 80,
  },
  confirmPaymentTxt: {
    textAlign: "center",
    fontSize: 24,
    color: "#555555",
    fontWeight: 400,
  },
  buttonsRow: {
    display: "flex",
    flexDirection: "row",
    gap: 16,
    justifyContent: "space-between",
  },
  yesBtn: {
    backgroundColor: "#24B3B9",
    color: "#ffffff",
    borderRadius: 6,
    borderColor: "#EBEBEB",
    width: 150,
    height: 50,
  },
  noBtn: {
    backgroundColor: "#CFCFCF",
    color: "#858585",
    borderRadius: 6,
    borderColor: "#EBEBEB",
    width: 150,
    height: 50,
  },
  btnBack: {
    webkitAppearance: "button",
    backgroundColor: "transparent",
    backgroundImage: "none",
  },
  customButton: {
    width: "180px",
    height: "50px",
    background: "#046C77",
    borderRadius: "6px",
    opacity: 1,
    fontSize: "18px",
    fontFamily: "dinNextRegular, sans-serif",
    textAlign: "center",
  },
  uploadBtn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 26,
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
    },
  },
}));

const ModelWithInput = ({
  openModal,
  setOpenModal,
  modalTitle,
  buttonText,
  handleSubmit,
  inputLabel,
  type,
  note = "",
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [inputValue, setinputValue] = useState("");

  const changeHandler = (e) => {
    setinputValue(e.target.value);
  };

  const submitHandler = () => {
    if (type === "numberInput" && inputValue < 0) {
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("crud.errors.accept_number")}</span>);
    }
    if (inputValue) {
      handleSubmit(inputValue);
    } else {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {type == "numberInput"
            ? t("crud.errors.accept_number")
            : type == "textarea"
            ? t("crud.errors.reject_reason_erorr")
            : t("crud.errors.required")}
        </span>
      );
    }
  };

  function CloseIcon({ className }) {
    return (
      <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
        <g id="x-circle_2_" data-name="x-circle (2)" transform="translate(-1 -1)">
          <circle
            id="Ellipse_23"
            data-name="Ellipse 23"
            cx="10"
            cy="10"
            r="10"
            transform="translate(2 2)"
            fill="none"
            stroke="#e62626"
            strokeLinecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <line
            id="Line_35"
            data-name="Line 35"
            x1="6"
            y2="6"
            transform="translate(9 9)"
            fill="none"
            stroke="#e62626"
            strokeLinecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <line
            id="Line_36"
            data-name="Line 36"
            x2="6"
            y2="6"
            transform="translate(9 9)"
            fill="none"
            stroke="#e62626"
            strokeLinecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
        </g>
      </svg>
    );
  }

  return (
    <Modal
      open={openModal}
      onClose={() => setOpenModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={openModal}>
        <Box className={styles.modalContainer}>
          <div className={styles.modal_container}>
            <div className={styles.modal_header_row}>
              <Typography
                id="modal-modal-title"
                variant="h3"
                style={{
                  //   font: "dinNextRegular, sans-serif",
                  color: "#046C77",
                  //   fontWeight: "bold",
                }}
              >
                {modalTitle}
              </Typography>
              <button type="button" onClick={() => setOpenModal(false)}>
                <CloseIcon className="tw-h-6 tw-w-6" />
              </button>
            </div>
            {/* <Divider className={styles.modal_header_divider} /> */}
            {type === "numberInput" ? (
              <div className={styles.input_number_container}>
                <Input
                  label={t("financial.payments_request.added_amount_when_accepted")}
                  type="number"
                  name="value"
                  placeholder={t("financial.payments_request.added_amount_when_accepted")}
                  onChange={changeHandler}
                  min={0.01}
                  value={inputValue}
                />
              </div>
            ) : type === "textarea" ? (
              <div className={styles.m_40}>
                <div className={styles.d_flex_input}>
                  <label className={styles.input_label}>{inputLabel}</label>
                </div>

                <textarea
                  name="note"
                  placeholder={inputLabel}
                  value={inputValue}
                  onChange={changeHandler}
                  rows={5}
                  cols={30}
                  maxLength={3000}
                  className={styles.textarea}
                />
              </div>
            ) : (
              <></>
            )}
            <div className={styles.done_btn_container}>
              <p className={styles.note}>{note}</p>
              <Button variant="contained" className={classes.customButton} onClick={submitHandler}>
                {buttonText}
              </Button>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ModelWithInput;
