import env from "../Environment";
import connector from "../Handler";
import URLBuilder from "../UrlBuilder";

export function getProfile() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.user_profile)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getProfileInfo() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.profile_info)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getCountries(params) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.countries, { params })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getCities(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.cities, { params: { country: id } })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function updateProfile(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.update_profile, payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function updateUser(payload, id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.update_user_by_admin, { id: id }), payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function updateEmail(email) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.change_email, { email: email })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function alternativeEmail(email) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.add_alternative_email, { email: email })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function changePassword(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.change_password, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getMedicals() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_medical_fields)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getSpecialties(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_specialties, { params: { status: 1 } })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function verifyEmail(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.verify_email, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
