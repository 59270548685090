import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { getPayments } from "../../../../Services/api/toutorials/PaymentsProvaider";
import HomeIcon from "../../../../assets/icons/home.svg";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import InvoicesTable from "./InvoicesTable";
import classes from "./invoice.module.css";

const Invoices = () => {
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [activeTab, setActiveTab] = useState("course");
  const [invoices, setInvoices] = useState([]);
  const [invoicesMeta, setInvoicesMeta] = useState({});
  const [loading, setLoading] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "content_development",
      page: t("editpro"),
      pagePath: "#",
    },
    {
      id: `content_development_bank`,
      page: t("sponsor.invoice.invoices_list"),
      active: true,
    },
  ];

  const getFilterData = () => {
    const filter = { status: "approved" };
    filter.user_id = localStorage.getItem("userId");
    filter.payable_type = activeTab;
    return filter;
  };

  const getInvoices = () => {
    setLoading(true);
    const filter = getFilterData();
    getPayments({ page: currentPage, perPage: 10, filter })
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setInvoices(res.data.data.payments);
          setInvoicesMeta(res.data.data.meta);
          setLoading(false);
        }
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.msg}</span>);
        setLoading(false);
      });
  };

  useEffect(() => {
    getInvoices();
    if (isResetApplied) {
      setIsResetApplied(false);
    }
  }, [currentPage, isResetApplied, activeTab]);

  return (
    <div className={classes["departments-trainees"]}>
      {isExportLoading && <SkeletonCardOverlay skeletonWidth="100" />}
      <div className="container-fluid">
        <div className={classes["departments-trainees__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <MainBox>
                <MainBoxHead title={t("sponsor.invoice.invoices_list")} />

                <InvoicesTable
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                  invoices={invoices}
                  loading={loading}
                  setIsExportLoading={setIsExportLoading}
                />

                <Pagination
                  count={invoicesMeta.totalPages || 0}
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  className="main-pagination"
                  page={currentPage}
                  onChange={(e, page) => {
                    setCurrentPage(page);
                  }}
                />
              </MainBox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoices;
