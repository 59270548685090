import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatUrl } from "../../../../utils/formatUrl";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import classes from "./bootDetails.module.css";

const BootDetailsBodyTable = ({ type, tableData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const { t, i18n } = useTranslation();

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData }) => <>{rowData.id}</>,
    },
    {
      id: "photo",
      name: t("Exhibition.photo"),
      key: "photo",
      cell: ({ rowData }) => (
        <div className={classes["boot-details__table-photo"]}>
          <img src={rowData.image} alt="" />
        </div>
      ),
    },
    {
      id: "name",
      name: t("booth.product_name"),
      key: "name",
      cell: ({ rowData }) => <div className={classes["boot-details__table-name"]}>{rowData.name}</div>,
      sortable: false,
    },
    {
      id: "description",
      name: t("booth.product_description"),
      key: "description",
      cell: ({ rowData }) => (
        <div className={classes["boot-details__table-description"]}>
          <div dangerouslySetInnerHTML={{ __html: rowData.description_html }} />
        </div>
      ),
      sortable: false,
    },
    {
      id: "linkTitle",
      name: t("booth.link_title"),
      key: "linkTitle",
      cell: ({ rowData }) => <div className={classes["boot-details__table-name"]}>{rowData.url_title}</div>,
      sortable: false,
    },
    {
      id: "link1",
      name: t("booth.page_link"),
      key: "link1",
      cell: ({ rowData }) => (
        <div className={classes["boot-details__table-description"]} style={{ textAlign: "center" }}>
          <a
            href={formatUrl(rowData?.url || "")}
            target="_blank"
            rel="noopener noreferrer"
            className={classes["boot-details__table-link"]}
          >
            {rowData.url}
          </a>
        </div>
      ),
      sortable: false,
    },
  ];

  useEffect(() => {
    const ITEMS_PER_PAGE = 5;
    const totalCount = tableData.length;
    const pageCount = Math.ceil(totalCount / ITEMS_PER_PAGE);
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    setCurrentData(tableData.slice(startIndex, endIndex));
  }, [currentPage, tableData]);

  return (
    <div>
      <MainTable columns={columns} data={currentData} isLoading={false} type={type} />
      <Pagination
        count={Math.ceil(tableData.length / 5)}
        showFirstButton
        showLastButton
        variant="outlined"
        shape="rounded"
        className="main-pagination"
        page={currentPage}
        onChange={(e, page) => {
          setCurrentPage(page);
        }}
      />
    </div>
  );
};

export default BootDetailsBodyTable;
