import React from "react";
import Footer from "../../../Shared/Components/footer/footer";
import {CoursesStd, Degrees, HeaderProfile} from "./../";
import {useTranslation} from "react-i18next";

function AllProfile() {
  require("./allProfile.css");
  const { t } = useTranslation();

  return (
    <div>
      <HeaderProfile />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 profiles">
            <div className="training_first_label ">{t("hometraineee")}</div>

            <Degrees />
            <CoursesStd />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AllProfile;
