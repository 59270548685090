import { Box, CircularProgress } from "@material-ui/core";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import Checkbox from "../../Modules/Shared/Components/Checkbox/Checkbox";
import Input from "../../Modules/Shared/Components/Input/Input";
import InputFile from "../../Modules/Shared/Components/InputFile/InputFile";
import MainDatePicker from "../../Modules/Shared/Components/MainDatePicker/MainDatePicker";
import Select from "../../Modules/Shared/Components/Select/Select";
import SkeletonCardOverlay from "../../Modules/Shared/Components/Spinner/SkeletonCardOverlay";
import { LessonContentContext } from "../../Providers/LessonContentProvider";
import {
  fetchActivityCommentsList,
  fetchChapterCommentsList,
} from "../../Redux/Actions/activityComment/activityComment.action";
import { toServerTime } from "../../Services/Timezone";
import { DOC_PDF, validateDocument } from "../../Services/Validator";
import { getListType } from "../../Services/api/TypeFile/TypeFileProvider";
import { editActivityToLesson } from "../../Services/api/courses/courseProvider";
import { getTeams } from "../../Services/api/teams/TeamsProvider";
import DeleteIcon from "../../assets/icons/delete.svg";
import FileIcon from "../../assets/icons/file.svg";
import { getLocaleTime } from "../../utils/utcToLocal";
import classes from "./AddActivity.module.css";

function AddActivityToLessonModal(props) {
  require("./AddActivityToLessonModal.css");
  const lessonContentContext = useContext(LessonContentContext);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { courseId, id } = useParams();

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isLocalSubmitting, setIsLocalSubmitting] = useState(false);
  const [listTypeFile, setListTypeFile] = useState([]);
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hoursDatepicker, setHoursDatepicker] = useState(null);
  const [minutesDatepicker, setMinutesDatepicker] = useState(null);
  const [hoursDatepickerEnd, setHoursDatepickerEnd] = useState(null);
  const [minutesDatepickerEnd, setMinutesDatepickerEnd] = useState(null);
  const [timeLoading, setTimeLoading] = useState(true);

  const getCommentsListHandler = () => {
    props.isChapter
      ? dispatch(
          fetchChapterCommentsList({
            id: props.chapterId,
            courseId: props.courseId,
            order: props.sortable ? props.sortable.value : null,
          })
        )
      : dispatch(
          fetchActivityCommentsList({
            id: props.id,
            courseId: id,
            order: props.sortable ? props.sortable.value : null,
          })
        );
  };
  const currentDateEnd = () => {
    let HoursDate = new Date().getHours();
    let MinutesDate = new Date().getMinutes();
    setHoursDatepickerEnd(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
    setMinutesDatepickerEnd(MinutesDate >= 30 ? 0 : MinutesDate);
  };
  const currentDate = () => {
    let HoursDate = new Date().getHours();
    let MinutesDate = new Date().getMinutes();
    setHoursDatepicker(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
    setMinutesDatepicker(MinutesDate >= 30 ? 0 : MinutesDate + 10);
  };

  const getListTypeFile = () => {
    const myPromise = new Promise((resolve, reject) => {
      getListType().then(
        (res) => {
          setListTypeFile(res?.data?.data);
          setLoading(false);
          resolve();
        },
        (error) => {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("programs.failed_loading")}</span>);
          reject();
        }
      );
    });

    return myPromise;
  };

  useEffect(() => {
    getListTypeFile();
    currentDate();
    currentDateEnd();
    fetchTeams();
    setTimeLoading(false);
  }, []);

  const getTypeFile = (searchType) => {
    return listTypeFile.filter((file) => file.type === searchType);
  };
  async function fetchTeams() {
    const res = await getTeams(courseId, {
      status: "active",
      type: "interactive_content",
      perPage: 1000,
    });
    if (res.status == 200) {
      setTeams(
        res.data.data?.map((team) => ({
          id: team.id,
          value: team.id,
          label: team.name,
        }))
      );
    }
  }

  const validateFile = (file) => {
    if (!file) return false;
    return validateDocument(file, getTypeFile(DOC_PDF)[0]?.max_size);
  };

  return (
    <div className="background_black_add_activity">
      {isLocalSubmitting || loading || timeLoading ? (
        <SkeletonCardOverlay />
      ) : (
        <div
          className="add_activity_modal"
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div className="add_activity_modal_header">
            <h2 className="add_activity_modal_title">{props.title}</h2>
            <div className="add_activity_modal_close" onClick={props.dismissModal}>
              <img src={DeleteIcon} alt="close" />
            </div>
          </div>
          <Formik
            initialValues={{
              name: props?.activity?.name || "",
              desc: props?.activity?.desc || "",
              team_id: props?.activity?.team?.id || "",
              ...(props?.activity
                ? {}
                : props.chapterId
                ? { discussable_id: props.chapterId }
                : props.courseId
                ? { discussable_id: props.courseId }
                : { discussable_id: id }),
              include_view_per: props?.activity?.include_view_per ? props?.activity?.include_view_per : false,
              include_achivement_per: props?.activity?.include_achivement_per
                ? props?.activity?.include_achivement_per
                : false,
              file_size: props?.activity?.file_size || "",
              interactions_num: props?.activity?.interactions_num || "",
              total_degree: props?.activity?.total_degree || "",
              success_degree: props?.activity?.success_degree || "",
              interactions_agree:
                props?.activity?.interactions_agree !== null || props?.activity?.interactions_agree !== undefined
                  ? props?.activity?.interactions_agree == true
                    ? "1"
                    : "0"
                  : "",
              file: Object.keys(props?.activity?.attachment || {}).length > 0 ? props?.activity?.attachment : null,
              start_time: props?.activity?.start_time
                ? new Date(getLocaleTime(new Date(props?.activity?.start_time)))
                : setHours(setMinutes(new Date(), minutesDatepicker), hoursDatepicker),
              end_time: props?.activity?.end_time
                ? new Date(getLocaleTime(new Date(props?.activity?.end_time)))
                : setHours(setMinutes(new Date(), minutesDatepicker), hoursDatepicker),
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              setIsLocalSubmitting(true);
              let formData = new FormData();
              Object.keys(values).forEach((field) => {
                if (values[field] !== "" && values[field] !== null) {
                  if (field === "file" && values[field]) {
                    formData.append(field, values[field].file);
                  } else if (field === "start_time" || field === "end_time") {
                    formData.append(field, toServerTime(values[field]));
                  } else if (field === "team_id") {
                    if (values[field] != 0 && !lessonContentContext.lessonInfo.team)
                      formData.append(field, values[field]);
                  } else {
                    formData.append(field, values[field]);
                  }
                }
              });
              if (props.activity) {
                if (
                  JSON.stringify(values["file"]) === JSON.stringify(props?.activity?.attachment) ||
                  (values["file"] === null && Object.keys(props?.activity?.attachment).length === 0)
                ) {
                  formData.delete("file");
                } else if (values["file"] === null) {
                  formData.append("file", "");
                  formData.append("delete_file", "1");
                } else {
                  formData.append("file", values["file"].file);
                }
              }
              formData.append("include_view_per", values.include_view_per ? 1 : 0);
              formData.append("include_achivement_per", values.include_achivement_per ? 1 : 0);

              // console.log(formData);
              try {
                if (props.activity) {
                  if (props.isChapter) {
                    await editActivityToLesson(formData, props.id);

                    getCommentsListHandler();
                  } else {
                    await lessonContentContext.editActivity(formData, props.id ? props.id : props.activity.id, id);
                    getCommentsListHandler();
                  }
                } else if (props.chapterId) {
                  await lessonContentContext.addActivityChapter("chapter", formData);
                  getCommentsListHandler();
                } else if (props.courseId) {
                  await lessonContentContext.addActivityChapter("course", formData);
                  getCommentsListHandler();
                } else {
                  await lessonContentContext.addActivity(formData);
                  await lessonContentContext.fetchLessonContent(id);
                }
                setIsLocalSubmitting(false);
                setSubmitting(false);
                props.dismissModal();
              } catch (error) {
                console.error(error);
                props.dismissModal();
                setIsLocalSubmitting(false);
                setSubmitting(false);
                return;
              }
            }}
            validate={(values) => {
              setHasSubmitted(true);
              const errors = {};
              if (!values.name) {
                errors.name = t("crud.errors.required");
              }

              if (values.require_approval === "") {
                errors.require_approval = t("crud.errors.required");
              }

              if (!values.file_size) {
                errors.file_size = t("crud.errors.required");
              }

              if (!values.interactions_num) {
                errors.interactions_num = t("crud.errors.required");
              }

              if (!values.success_degree) {
                errors.success_degree = t("crud.errors.required");
              }

              if (!values.total_degree) {
                errors.total_degree = t("crud.errors.required");
              }

              return errors;
            }}
            validateOnChange={hasSubmitted}
          >
            {({ setFieldValue, values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit} className="add_activity_modal_form">
                <div className="add_activity_modal_form_fields">
                  <Input
                    type="text"
                    placeholder={t("trainer.course.lesson.activity.crud.name")}
                    label={t("trainer.course.lesson.activity.name")}
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    error={errors.name}
                    required
                  />
                  <div style={{ display: "flex", gap: "20px" }}>
                    <Checkbox
                      name="include_view_per"
                      label={t("include_view_per")}
                      onChange={(event) => {
                        setFieldValue("include_view_per", event.target.checked ? 1 : 0);
                      }}
                      checked={values.include_view_per === true || values.include_view_per === 1}
                      error={errors.include_view_per}
                      value={values.include_view_per === true || values.include_view_per === 1}
                    />
                    <Checkbox
                      name="include_achivement_per"
                      label={t("include_achivement_per")}
                      onChange={(event) => {
                        setFieldValue("include_achivement_per", event.target.checked ? 1 : 0);
                      }}
                      checked={values.include_achivement_per === true || values.include_achivement_per === 1}
                      error={errors.include_achivement_per}
                      value={values.include_achivement_per === true || values.include_achivement_per === 1}
                    />
                    {/* <span>{values.include_view_per}</span> */}
                  </div>

                  <div>
                    <label htmlFor="desc">{t("trainer.course.lesson.activity.description")}</label>
                    <SunEditor
                      name="desc"
                      onChange={(desc) => {
                        setFieldValue("desc", desc);
                      }}
                      defaultValue={values.desc}
                      setDefaultStyle="font-family: 'Montserrat', sans-serif; font-size: 16px;"
                      value={values.desc}
                      setOptions={{
                        height: 150,
                        rtl: i18n.language === "ar" ? true : false,
                        buttonList: [
                          ["undo", "redo"],
                          ["fontSize", "formatBlock"],
                          ["bold", "underline", "italic", "strike", "subscript", "superscript"],

                          ["fontColor", "hiliteColor", "outdent", "indent"],
                          ["align", "horizontalRule", "list", "table"],
                          ["link", "image", "video"],
                          ["codeView"],
                        ],
                      }}
                    />
                    <div>
                      {errors && errors.desc ? (
                        <span className={"attachment_panel_input_error"}>{errors.desc}</span>
                      ) : null}
                    </div>
                  </div>

                  <Input
                    type="number"
                    placeholder={t("trainer.course.lesson.activity.crud.rpu")}
                    label={t("trainer.course.lesson.activity.rpu")}
                    name="interactions_num"
                    onChange={handleChange}
                    value={values.interactions_num}
                    error={errors.interactions_num}
                    min={1}
                    required
                  />

                  <Input
                    type="number"
                    placeholder={t("trainer.course.lesson.activity.crud.max_size")}
                    label={t("trainer.course.lesson.activity.max_size")}
                    name="file_size"
                    onChange={handleChange}
                    value={values.file_size}
                    error={errors.file_size}
                    required
                  />
                  <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4">
                    <Input
                      type="number"
                      placeholder={t("trainee.quiz.total_grade")}
                      label={t("trainee.quiz.total_grade")}
                      name="total_degree"
                      onChange={handleChange}
                      value={values.total_degree}
                      error={errors.total_degree}
                      min={1}
                      required
                    />

                    <Input
                      type="number"
                      placeholder={t("trainer.course.lesson.pass_mark")}
                      label={t("trainer.course.lesson.pass_mark")}
                      name="success_degree"
                      onChange={handleChange}
                      value={values.success_degree}
                      error={errors.success_degree}
                      min={1}
                      required
                    />
                  </div>
                  {lessonContentContext.lessonInfo.team ? (
                    <></>
                  ) : (
                    <Select
                      name="team_id"
                      label={t("coworking_space")}
                      placeholder={t("coworking_space")}
                      onchange={handleChange}
                      options={[{ label: t("block.label.all"), value: 0, id: 0 }, ...teams]}
                      error={errors.team_id}
                      value={values.team_id}
                      initialValue={t("coworking_space")}
                    />
                  )}
                  <Select
                    name="interactions_agree"
                    label={t("trainer.course.lesson.activity.require_approval")}
                    onchange={handleChange}
                    options={[
                      { label: t("no"), value: 0, id: 0 },
                      { label: t("yes"), value: 1, id: 1 },
                    ]}
                    error={errors.interactions_agree}
                    value={values.interactions_agree}
                    initialValue={t("trainer.course.lesson.activity.require_approval")}
                    required
                  />
                  <InputFile
                    id="file"
                    name="file"
                    type="document"
                    // removeHandler={() => setFieldValue("file", null)}
                    placeholder={props.isChapter ? t("trainer.course.lesson.attachment") : t("Exhibition.drag_file")}
                    setFieldValue={setFieldValue}
                    fileIcon={FileIcon}
                    accept={
                      "application/pdf,.doc,.docx,application/msword,application/vnd.ms-excel,image/*,video/*,audio/*"
                    }
                    label={props.isChapter ? t("trainer.course.lesson.attachment") : t("Exhibition.drag_file")}
                    // note={`${t("Exhibition.pdf_note")} ${fromKbToMb(getTypeFile(DOC_PDF)[0]?.max_size)} ${t(
                    //   "booth.megabyte"
                    // )}`}
                    error={errors.file}
                    value={values.file}
                    documentWithoutTitle={true}
                  />
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div className={`${classes["create_tax__form__field"]} col-12 col-md-6`}>
                      <MainDatePicker
                        label={t("general.start_date_time")}
                        name="start_time"
                        error={errors.start_time}
                        value={values.start_time}
                        onChange={(date) => {
                          setFieldValue("start_time", date);

                          let CurrentDate = new Date();
                          let CurrentDateFormate = CurrentDate.toISOString().split("T")[0];
                          let DataPickerValue = date.toISOString().split("T")[0];
                          if (new Date(DataPickerValue).getTime() > new Date(CurrentDateFormate).getTime()) {
                            setHoursDatepicker(0);
                            setMinutesDatepicker(0);
                          } else {
                            currentDate();
                          }
                        }}
                        minDate={new Date()}
                        minTime={setHours(setMinutes(new Date(), minutesDatepicker), hoursDatepicker)}
                        maxTime={setHours(setMinutes(new Date(), 30), 23)}
                      />
                      <p className={"form-input-error-space"}>{errors.start_time ? errors.start_time : null}</p>
                    </div>
                    <div className={`${classes["create_tax__form__field"]} col-12 col-md-6`}>
                      <MainDatePicker
                        label={t("general.end_date_time")}
                        name="end_time"
                        error={errors.end_time}
                        value={values.end_time}
                        onChange={(date) => {
                          setFieldValue("end_time", date);

                          let CurrentDate = new Date();
                          let CurrentDateFormate = CurrentDate.toISOString().split("T")[0];
                          let DataPickerValue = date.toISOString().split("T")[0];
                          if (new Date(DataPickerValue).getTime() > new Date(CurrentDateFormate).getTime()) {
                            setHoursDatepickerEnd(0);
                            setMinutesDatepickerEnd(0);
                          } else {
                            currentDateEnd();
                          }
                        }}
                        minDate={new Date()}
                        minTime={setHours(setMinutes(new Date(), minutesDatepicker), hoursDatepicker)}
                        maxTime={setHours(setMinutes(new Date(), 30), 23)}
                      />
                      <p className={"form-input-error-space"}>{errors.end_time ? errors.end_time : null}</p>
                    </div>
                  </div>
                  <div className="add_activity_actions">
                    <div className={`${classes["create_tax__form__field-actions"]} col-12`}>
                      <button
                        className={classes["create_tax__form__field-actions-submit"]}
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <Box sx={{ display: "flex" }}>
                            <CircularProgress color="white" size={30} />
                          </Box>
                        ) : (
                          t("crud.placeholders.submit")
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
}

export default AddActivityToLessonModal;
