import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";
import i18n from "./../../../../i18n/i18n";
import {Lang} from "./../../../../utils";
import {NavLink, useParams} from "react-router-dom";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import {Rating} from "@mui/material";

function RatesDetails(props) {
  require("./trainer.css");
  const { t } = useTranslation();
  const history = useHistory()
  const [questions,setQuestions] = useState([])
  const [courses,setCourses] = useState([])
  const [isLoading,setIsLoading] = useState(true)
  const {id , courseId} = useParams();
  const ratings =  history.location.state.ratings

  useEffect(()=>{
    let newCourses = []
    for (let i = 0; i < ratings.length; i++) {
          if(ratings[i].course_id == courseId){
             newCourses.push(ratings[i])
          }
    }
    setCourses(newCourses)
    setIsLoading(false)
  },[ratings])

    return (
        <>
              <div className="container-fluid" id={"content-section"}>
                <div className="row" id="content-header-bar">
                    <div className="col-12">
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                        }}>
                            <div className="admin_label">
                                <NavLink to="/admin">{t("admin.label.admin_label")}</NavLink>
                                <p style={{padding: "0 5px"}}>/</p>
                                {/* <NavLink to="/admin/trainer-rate/list">{t("rate.label.rates")}</NavLink> */}
                                {/* <p style={{padding: "0 5px"}}>/</p> */}
                                {t("rate.label.rate")}
                            </div>

                            <div style={{
                                flex: 1,
                                flexDirection: 'row',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end'
                            }}>
                            </div>
                        </div>
                    </div>
                </div>
                {isLoading == false ? (
                  <div className="table_bank_tran">
                            <div className="tajah_card_title_container_questions">
                                <div>{t("rate.label.rate")}</div>
                <div className="table-responsive" style={{
                    width: '100%',marginTop:'5%'
                  }}>
                  {courses.length == 0 ? (
                    <p style={{textAlign:'center'}}>{t('rate.label.notFound')}</p>
                  ):(
                    <table className="table table-bordered">
                      <thead>
                      <tr style={{textAlign:'center'}}>
                        <th scope="col">#</th>
                        <th scope="col">{t("rate.label.description")}</th>
                        <th scope="col">{t("rate.label.rate")}</th>
                      </tr>
                      </thead>
                      <tbody>
                      {courses.map((course,index) => {
                        return (
                            <tr style={{textAlign:'center'}} key={course.id}>
                              <td scope="row">{index+1}</td>
                              <td>{i18n.language == Lang.AR ? course.description : course.description}</td>
                              <td>
                                  <Rating
                                  value={course.stars == NaN ? 0 : course.stars}
                                  readOnly
                                  />
                              </td>
                            </tr>
                        );
                      })}
                      </tbody>
                    </table>
                   )}
                  </div>
                  </div>
                  </div>
                ):(
                  <div className="mt-5" style={{
                      height: "65vh"
                  }}>
                      <SkeletonCard/>
                  </div>
              )}
                  </div>
        </>
    );
}

export default RatesDetails;
