import React from "react";
import classes from "./createExhibition.module.css";
import ViewIcon from "../../../../assets/icons/eye-white.svg";
import { useTranslation } from "react-i18next";
import { Box, CircularProgress } from "@material-ui/core";

const CreateExhibitionAction = ({ submitAction, isSubmitting }) => {
  const { t, i18n } = useTranslation();

  return (
    <div
      className={`${classes["create_exhibition__form__field-actions"]} col-12`}
    >
      <button
        className={classes["create_exhibition__form__field-actions-submit"]}
        type="submit"
        disabled={isSubmitting}
        onClick={submitAction}
      >
        {isSubmitting ? (
          <Box sx={{ display: "flex" }}>
            <CircularProgress color="white" size={30} />
          </Box>
        ) : (
          t("Exhibition.save")
        )}
      </button>
    </div>
  );
};

export default CreateExhibitionAction;
