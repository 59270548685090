import React, {useContext, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import {AccountantContext} from "./../../Modules/Shared/Components/accountant/AccountantContext";

export default function ModeSponsor(props) {
  const sponsorsContext = useContext(AccountantContext);
  const { t } = useTranslation();
  const history = useHistory();
  useEffect(() => {
    sponsorsContext.setFormSponsors(sponsorsContext.formSponsors);
    sponsorsContext.setSponsor(sponsorsContext.sponsor);

  });
  return (
    <div className="modal_flex_card">
      <div className="modal_card_admin sponsor_model">
        <div
          style={{
            fontSize: "25px",
          }}
        >
          {t("sponsor.add.trainee_model")}
        </div>
        <div
          style={{
            width: "100%",
          }}
        >
          <p className="text-center">{t("sponsor.add.Paragraph_model")}</p>
        </div>
        <div
          className=""
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <button
            className="admin_add_button"
            onClick={() => {
              sponsorsContext.setFormSponsors(sponsorsContext.formSponsors);
              sponsorsContext.setSponsor(sponsorsContext.sponsor);
              history.push({
                pathname: "/accountant/sponsor/addtraineebyname",
              });
            }}
          >
            {t("sponsor.add.btn_name_model")}
          </button>
          <button
            className="admin_add_button"
            onClick={() => {
              sponsorsContext.setFormSponsors(sponsorsContext.formSponsors);
              sponsorsContext.setSponsor(sponsorsContext.sponsor);
              history.push({
                pathname: "/accountant/sponsor/addtraineebyemail",
              });
            }}
          >
            {t("sponsor.add.btn_email_model")}
          </button>
        </div>
      </div>
    </div>
  );
}
