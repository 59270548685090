import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import {
  editCategory,
  getCategories,
} from "../../../../Services/api/courses/courseProvider";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import ManageSpecialties from "./ManageSpecialties";

const EditSpecialties = () => {
  const { id } = useParams();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [specialtyDetails, setSpecialtyDetails] = useState(null);
  const [getSpecialtyLoading, setGetSpecialtyLoading] = useState(false);
  const getSpecialtyHandler = async () => {
    setGetSpecialtyLoading(true);
    getCategories(id)
      .then((response) => {
        if (response.status && response.status == 200 && response.data.status) {
          setSpecialtyDetails(response.data.specialty);
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          return history.push("/admin/page-not-found");
        }
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {error?.response?.data?.msg
              ? error?.response?.data?.msg
              : "Failure in service"}
          </span>
        );
      })
      .finally(() => {
        setGetSpecialtyLoading(false);
      });
  };

  useEffect(() => {
    if (!id) return;
    getSpecialtyHandler();
  }, []);

  const submitHandler = async (values) => {
    let response = await editCategory(id, values);
    if (response.status === 200 && response.data.status) {
      history.push("/admin/category");
      toast.success(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {response.data.msg
            ? response.data.msg
            : t("specialties_manage.specialty_updated_successfully")}
        </span>,
        {
          onClose: () => {},
          autoClose: 1000,
        }
      );
    } else {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("specialties_manage.failed_creating_specialty")}
        </span>
      );
    }
  };

  return (
    <>
      {getSpecialtyLoading ? (
        <div
          style={{
            height: "65vh",
            width: "100%",
          }}
        >
          <SkeletonCard />
        </div>
      ) : (
        <ManageSpecialties
          type={"edit"}
          specialtyDetails={specialtyDetails}
          submitHandler={submitHandler}
        />
      )}
    </>
  );
};

export default EditSpecialties;
