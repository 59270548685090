import { faqSupportTicketsConstant } from "../../Constants/faq/faqSupportTickets.constant";

const initialState = {
  faqListResponse: {},
  faqList: [],
  loading: false,
  msg: null,
  deleteStatus: null,
  faqCategoryListST: [],
  faqCategory: [],
  typeList: [],
  typeListRes: null,

  addStatus: null,
  addResponseST: {},
  deleteResponseST: {},
  editResponse: {},
  showResponse: {},
  changeStatusResponseST: {},
  reOrderResponseST: {},

  // question
  deleteQuestionStatus: null,
  addQuestionStatusST: null,
  editQuestionStatusST: null,
  addQuestionResponse: {},
  deleteQuestionResponseST: {},
  editQuestionResponse: {},
  showQuestionResponseST: {},
  changeStatusQuestionResponseST: {},
  reOrderQuestionResponseST: {},

  faqGeneralListST: null,
  faqGeneralST: null,
};

const faqSupportTicketsReducer = (state = initialState, action) => {
  switch (action.type) {
    case faqSupportTicketsConstant.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case faqSupportTicketsConstant.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };

    case faqSupportTicketsConstant.GET_FAQ_GENERAL_LIST_ST:
      return {
        ...state,
        faqGeneralListST: action.payload.response,
        faqGeneralST: action.payload.data,
      };

    // CATEGORY
    case faqSupportTicketsConstant.GET_FAQ_CATEGORIES_LIST_ST:
      return {
        ...state,
        faqCategoryListST: action.payload.response,
        faqCategory: action.payload.data,
      };

    case faqSupportTicketsConstant.GET_FAQ_CATEGORIES_TYPE_LIST_ST:
      return {
        ...state,
        typeList: action.payload.data,
        typeListRes: action.payload.response,
      };

    case faqSupportTicketsConstant.GET_FAQ_CATEGORY_ADD_ST:
      return {
        ...state,
        addResponseST: action.payload,
      };
    case faqSupportTicketsConstant.GET_FAQ_CATEGORY_DELETE_ST:
      return {
        ...state,
        deleteResponseST: action.payload,
      };
    case faqSupportTicketsConstant.GET_FAQ_CATEGORY_STATUS_CHANGE_ST:
      return {
        ...state,
        changeStatusResponseST: action.payload,
      };

    case faqSupportTicketsConstant.GET_FAQ_CATEGORY_RE_ORDER_ST:
      return {
        ...state,
        reOrderResponseST: action.payload,
      };

    // QUESTION
    case faqSupportTicketsConstant.GET_FAQ_QUESTION_ADD_ST:
      return {
        ...state,
        addQuestionStatusST: action.payload,
      };
    case faqSupportTicketsConstant.GET_FAQ_QUESTION_DELETE_ST:
      return {
        ...state,
        deleteQuestionResponseST: action.payload,
      };
    case faqSupportTicketsConstant.GET_FAQ_QUESTION_STATUS_CHANGE_ST:
      return {
        ...state,
        changeStatusQuestionResponseST: action.payload,
      };

    case faqSupportTicketsConstant.GET_FAQ_QUESTION_EDIT_ST:
      return {
        ...state,
        editQuestionStatusST: action.payload,
      };

    case faqSupportTicketsConstant.GET_FAQ_QUESTION_RESET_ST:
      return {
        ...state,
        addQuestionStatusST: null,
        editQuestionStatusST: null,
        addResponseST: {},
        editResponse: {},
      };

    case faqSupportTicketsConstant.GET_FAQ_QUESTION_SHOW_ST:
      return {
        ...state,
        showQuestionResponseST: action.payload,
      };

    case faqSupportTicketsConstant.GET_FAQ_QUESTION_RE_ORDER_ST:
      return {
        ...state,
        reOrderQuestionResponseST: action.payload,
      };

    default:
      return state;
  }
};

export default faqSupportTicketsReducer;
