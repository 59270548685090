import { useTranslation } from 'react-i18next';
import DeleteIcon from "../../../../assets/icons/delete.svg";
import classes from './searchContainer.module.css';

const SearchContainer = ({
  label,
  onChange,
  onClick,
  buttonText,
  value,
  resetSearch
}) => {
  const { t, i18n } = useTranslation();

  return (
    <div className={classes["search__container"]}>
      <div className={classes["search__container-field"]}>
        <input
          type="text"
          name="search"
          placeholder={label}
          onChange={onChange}
          value={value}
        />
        {value && (
          <div className={classes["search__container-field-icon"]}
            onClick={resetSearch}
          >
            <img src={DeleteIcon} alt="" />
          </div>
        )}
      </div>
      <button className={classes["search__container-button"]}
        onClick={onClick}
      >{buttonText}</button>
    </div>
  )
}

export default SearchContainer