import connector from "../../../Services/api/Handler";
import URLBuilder from "../../../Services/api/UrlBuilder";
import { faqSupportTicketsConstant } from "../../Constants/faq/faqSupportTickets.constant";
import env from "../../../Services/api/Environment";
import { toast } from "react-toastify";

export function getFaqGeneralListST() {
  return (dispatch) => {
    dispatch({
      type: faqSupportTicketsConstant.START_LOADING,
    });
    connector
      .get(env.faqs_list_ST)
      .then((res) => {
        dispatch({
          type: faqSupportTicketsConstant.GET_FAQ_GENERAL_LIST_ST,
          payload: { response: res, data: res.data.data },
        });
        dispatch({
          type: faqSupportTicketsConstant.STOP_LOADING,
        });
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.message}
          </span>
        );
        dispatch({
          type: faqSupportTicketsConstant.STOP_LOADING,
        });
      });
  };
}

export function getFaqCategoryListST() {
  return (dispatch) => {
    dispatch({
      type: faqSupportTicketsConstant.START_LOADING,
    });
    connector
      .get(env.faq_category_ST)
      .then((res) => {
        dispatch({
          type: faqSupportTicketsConstant.GET_FAQ_CATEGORIES_LIST_ST,
          payload: { response: res, data: res.data.data },
        });
        dispatch({
          type: faqSupportTicketsConstant.STOP_LOADING,
        });
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.message}
          </span>
        );
        dispatch({
          type: faqSupportTicketsConstant.STOP_LOADING,
        });
      });
  };
}

export function getFaqCategoryListSTWithoutLoading() {
  return (dispatch) => {
    connector
      .get(env.faq_category_ST)
      .then((res) => {
        dispatch({
          type: faqSupportTicketsConstant.GET_FAQ_CATEGORIES_LIST_ST,
          payload: { response: res, data: res.data.data },
        });
        dispatch({
          type: faqSupportTicketsConstant.STOP_LOADING,
        });
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.message}
          </span>
        );
      });
  };
}

export function getFaqCategoryTypeListST() {
  return (dispatch) => {
    connector
      .get(env.faq_category_ST_type_list)
      .then((res) => {
        dispatch({
          type: faqSupportTicketsConstant.GET_FAQ_CATEGORIES_TYPE_LIST_ST,
          payload: { response: res, data: res.data.data },
        });
        dispatch({
          type: faqSupportTicketsConstant.STOP_LOADING,
        });
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.message}
          </span>
        );
      });
  };
}

export function deleteFaqCategoryST(id) {
  return (dispatch) => {
    dispatch({
      type: faqSupportTicketsConstant.START_LOADING,
    });

    connector
      .delete(URLBuilder(env.faq_category_ST_id, { id: id }))
      .then((res) => {
        dispatch({
          type: faqSupportTicketsConstant.GET_FAQ_CATEGORY_DELETE_ST,
          payload: res,
        });
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {res.data.msg ? res.data.msg : "success"}
          </span>,
          {
            onClose: () => {},
            autoClose: 1000,
          }
        );
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.message || err.response.data.msg}
          </span>
        );
        dispatch({
          type: faqSupportTicketsConstant.STOP_LOADING,
        });
      });
    dispatch({
      type: faqSupportTicketsConstant.STOP_LOADING,
    });
  };
}

export const addFaqCategoryST = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: faqSupportTicketsConstant.START_LOADING,
    });

    await connector
      .post(env.faq_category_ST, payload)
      .then((res) => {
        dispatch({
          type: faqSupportTicketsConstant.GET_FAQ_CATEGORY_ADD_ST,
          payload: res,
        });
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {res.data.msg ? res.data.msg : "success"}
          </span>,
          {
            onClose: () => {},
            autoClose: 1000,
          }
        );
      })
      .catch((err) => {
        toast.error(
          <>
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {err.response.data.message || err.response.data.msg}
            </span>
            {err.response.data.errors && (
              <ul>
                {Object.keys(err.response.data.errors).map((key) => {
                  return (
                    <li>
                      <span style={{ fontSize: 11 }}>
                        {err.response.data.errors[key]}
                      </span>
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        );
        dispatch({
          type: faqSupportTicketsConstant.STOP_LOADING,
        });
      })
      .finally(() =>
        dispatch({
          type: faqSupportTicketsConstant.STOP_LOADING,
        })
      );
  };
};

export const changeStatusFaqCategoryST = (id) => {
  return async (dispatch) => {
    await connector
      .post(URLBuilder(env.faq_category_change_status_ST, { id: id }))
      .then((res) => {
        dispatch({
          type: faqSupportTicketsConstant.GET_FAQ_CATEGORY_STATUS_CHANGE_ST,
          payload: res,
        });
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {res.data.msg ? res.data.msg : "success"}
          </span>,
          {
            onClose: () => {},
            autoClose: 1000,
          }
        );
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.message || err.response.data.msg}
          </span>
        );
        dispatch({
          type: faqSupportTicketsConstant.STOP_LOADING,
        });
      });
  };
};

export const reOrderFaqCategoryST = (id, to) => {
  return async (dispatch) => {
    await connector
      .post(URLBuilder(env.faq_category_ST_re_order, { id: id }), {
        order: to,
      })
      .then((res) => {
        dispatch({
          type: faqSupportTicketsConstant.GET_FAQ_CATEGORY_RE_ORDER_ST,
          payload: res,
        });
      });
  };
};

// QUESTIONS
export const addFaqQuestionsST = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: faqSupportTicketsConstant.START_LOADING,
    });

    await connector
      .post(env.faq_add_question_ST, payload)
      .then((res) => {
        dispatch({
          type: faqSupportTicketsConstant.GET_FAQ_QUESTION_ADD_ST,
          payload: res,
        });
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {res.data.msg ? res.data.msg : "success"}
          </span>,
          {
            onClose: () => {},
            autoClose: 1000,
          }
        );
      })
      .catch((err) => {
        toast.error(
          <>
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {err.response.data.message || err.response.data.msg}
            </span>
            {err.response.data.errors && (
              <ul>
                {Object.keys(err.response.data.errors).map((key) => {
                  return (
                    <li>
                      <span style={{ fontSize: 11 }}>
                        {err.response.data.errors[key]}
                      </span>
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        );
        dispatch({
          type: faqSupportTicketsConstant.STOP_LOADING,
        });
      });
    dispatch({
      type: faqSupportTicketsConstant.STOP_LOADING,
    });
  };
};

export function deleteFaqQuestionST(id) {
  return (dispatch) => {
    dispatch({
      type: faqSupportTicketsConstant.START_LOADING,
    });

    connector
      .post(URLBuilder(env.faq_add_question_ST_id, { id: id }), {
        _method: "DELETE",
      })
      .then((res) => {
        dispatch({
          type: faqSupportTicketsConstant.GET_FAQ_QUESTION_DELETE_ST,
          payload: res,
        });
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {res.data.msg ? res.data.msg : "success"}
          </span>,
          {
            onClose: () => {},
            autoClose: 1000,
          }
        );
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.message || err.response.data.msg}
          </span>
        );
        dispatch({
          type: faqSupportTicketsConstant.STOP_LOADING,
        });
      });
    dispatch({
      type: faqSupportTicketsConstant.STOP_LOADING,
    });
  };
}

export const changeStatusFaqQuestionST = (id) => {
  return async (dispatch) => {
    await connector
      .post(URLBuilder(env.faq_question_change_status_ST, { id: id }))
      .then((res) => {
        dispatch({
          type: faqSupportTicketsConstant.GET_FAQ_QUESTION_STATUS_CHANGE_ST,
          payload: res,
        });
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {res.data.msg ? res.data.msg : "success"}
          </span>,
          {
            onClose: () => {},
            autoClose: 1000,
          }
        );
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.message || err.response.data.msg}
          </span>
        );
      });
  };
};

export function editFaqQuestionST(id, payload) {
  return (dispatch) => {
    dispatch({
      type: faqSupportTicketsConstant.START_LOADING,
    });

    connector
      .put(URLBuilder(env.faq_add_question_ST_id, { id: id }), payload, {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        dispatch({
          type: faqSupportTicketsConstant.GET_FAQ_QUESTION_EDIT_ST,
          payload: res,
        });
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {res.data.msg ? res.data.msg : "success"}
          </span>,
          {
            onClose: () => {},
            autoClose: 1000,
          }
        );
      })
      .catch((err) => {
        toast.error(
          <>
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {err.response.data.message || err.response.data.msg}
            </span>
            {err.response.data.errors && (
              <ul>
                {Object.keys(err.response.data.errors).map((key) => {
                  return (
                    <li>
                      <span style={{ fontSize: 11 }}>
                        {err.response.data.errors[key]}
                      </span>
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        );
        dispatch({
          type: faqSupportTicketsConstant.STOP_LOADING,
        });
      });
    dispatch({
      type: faqSupportTicketsConstant.STOP_LOADING,
    });
  };
}

export function showFaqQuestionST(id) {
  return (dispatch) => {
    connector
      .get(URLBuilder(env.faq_add_question_ST_id, { id: id }))
      .then((res) => {
        dispatch({
          type: faqSupportTicketsConstant.GET_FAQ_QUESTION_SHOW_ST,
          payload: res,
        });
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.message || err.response.data.msg}
          </span>
        );
        dispatch({
          type: faqSupportTicketsConstant.STOP_LOADING,
        });
      });
  };
}

export const reOrderFaqQuestionST = (id, to) => {
  return async (dispatch) => {
    await connector
      .post(URLBuilder(env.faq_question_ST_re_order, { id: id }), {
        order: to,
      })
      .then((res) => {
        dispatch({
          type: faqSupportTicketsConstant.GET_FAQ_QUESTION_RE_ORDER_ST,
          payload: res,
        });
      });
  };
};
