import React from "react";
import i18n from "../../../../i18n/i18n";
import adminDoc from "../../../../assets/image/admin-doc.png";
import {Lang} from "../../../../utils";

function UserDetail(props) {
  return (
    <div className="dropdown">
      {localStorage.getItem("img") == "null" ? (
        <img src={adminDoc} alt="" className="admin_img" />
      ) : (
        <img
          src={localStorage.getItem("img")}
          alt="..."
          className="admin_img"
        />
      )}
      {i18n.language === Lang.AR
        ? localStorage.getItem("name_ar") != null
          ? localStorage.getItem("name_ar").split(" ")[0]
          : null
        : localStorage.getItem("name_en") != null
        ? localStorage.getItem("name_en").split(" ")[0]
        : null}
  </div>
  );
}

export default UserDetail;
