import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import UploadModal from "../../../../../Modals/uploadModal/UploadModal";
import { CourseProfileContext } from "../../../../../Services/api/courses/Trainer/CourseProfileContext";
import { exportLessonContent, getCourseTree, getIntroVideo } from "../../../../../Services/api/courses/courseProvider";
import playIcon from "../../../../../assets/icons/play-circle.svg";
import i18n from "../../../../../i18n/i18n";
import IntroVideoModal from "../../../../Shared/Components/IntroViedoModal/IntroVideoModal";
import { BasicModal } from "../../../../Shared/Components/Modal/BasicModal";
import SkeletonCard from "../../../../Shared/Components/Spinner/SkeletonCard";

function CourseVideo(props) {
  require("./courseVideo.css");
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [fetchIntroVideoLoading, setFetchIntroVideoLoading] = useState(true);
  const [introVideo, setIntroVideo] = useState(null);
  const [type, setType] = useState("");
  const [openIntroVideoModal, setOpenIntroVideoModal] = useState(false);
  const [place, setPlace] = useState("");

  const handleShowIntroVideoModalState = (value) => {
    setOpenIntroVideoModal(value);
  };
  const courseProfileContext = useContext(CourseProfileContext);

  const fetchCourseIntroVideo = async () => {
    await getIntroVideo()
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setIntroVideo(res.data.data.file);
          setFetchIntroVideoLoading(false);
        }
      })
      .catch((err) => {
        
        
      });
  };

  let youtubeURL = courseProfileContext.courseInfo.video == null ? "" : courseProfileContext.courseInfo.video;
  let VideoKey = youtubeURL.substr(youtubeURL.lastIndexOf("v=") + 2, 11);

  const downloadFile = (place) => {
    if (place == "course") {
      exportLessonContent("course", courseProfileContext.courseInfo.name, "zip", props.courseID, courseProfileContext);
    } else {
      exportLessonContent(
        "chapters",
        courseProfileContext.courseInfo.name,
        "zip",
        props.courseID,
        courseProfileContext
      );
    }
    toast.success(
      <span style={{ fontSize: 13, fontWeight: "bold" }}>
        {t("modal.trainer.course.chapter.messages.titleDownload")}
      </span>
    );
  };

  return (
    <div className="training-course-about-section">
      {isLoading ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "30vh",
            }}
          >
            <SkeletonCard />
          </div>
        </div>
      ) : (
        <div className="add_training_course_video_container">
          <img
            src={playIcon}
            alt="play"
            style={{
              width: 86,
              height: 86,
              cursor: "pointer",
            }}
            onClick={async () => {
              await fetchCourseIntroVideo();
              setOpenIntroVideoModal(true);
            }}
          />
          <span className="add_training_course_video_text">{t("explain_course")}</span>
          {openIntroVideoModal && (
            <IntroVideoModal
              openModal={openIntroVideoModal}
              setOpenModal={handleShowIntroVideoModalState}
              modalTitle={i18n.language === "ar" ? "فيديو تعريفي" : "Introductory video"}
              viewIntroVideo
              loadingVideo={fetchIntroVideoLoading}
              video={introVideo}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default CourseVideo;
