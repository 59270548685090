import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useEffect, useState } from "react";
import Input from "../../Modules/Shared/Components/Input/Input";

function ValidatorInput(props) {
  const [icons, setIcons] = useState(false);
  const [content, setContent] = useState("");
  const [validationParams, setValidationParams] = useState([]);

  const validator = {
    email: (context) => {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(context)) {
        return false;
      }
      return true;
    },
  };

  useEffect(() => {
    let validationParamsString = props.validationParams;
    setValidationParams((prevState) => {
      return validationParamsString.split("|");
    });
  }, []);

  useEffect(() => {
    let validationResult = true;
    validationParams.forEach((validationContext) => {
      validationResult = validationResult && validator[validationContext](content);
      if (!validationResult) {
        return;
      }
    });
    setIcons((prevState) => {
      return validationResult;
    });
  }, [content]);

  const contentHandleChange = (event) => {
    setContent(event.target.value);
    props.setContent(event.target.value);
  };

  return (
    <div className="validator_input_component_container">
      <div>
        {/* <input
          type={props.type ? props.type : "text"}
          value={content}
          onChange={contentHandleChange}
          className={`component_input ${props.classes} `}
          required={typeof props.setRequired === "undefined" ? false : props.setRequired}
          placeholder={props.placeholder ? props.placeholder : ""}
        /> */}
        <Input
          type={props.type ? props.type : "text"}
          value={content}
          onChange={contentHandleChange}
          className={`component_input ${props.classes} `}
          required={typeof props.setRequired === "undefined" ? false : props.setRequired}
          placeholder={props.placeholder ? props.placeholder : ""}
          label={props.label ? props.label : ""}
          direction="ltr"
        />
      </div>
      <div className="validator_input_icon_container" style={{ top: "45%" }}>
        {icons ? <CheckCircleIcon style={{ color: "green" }} /> : <CheckCircleIcon color="action" />}
      </div>
    </div>
  );
}

export default ValidatorInput;
