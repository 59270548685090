import { Pagination, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import { useDispatch, useSelector } from "react-redux";
// import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router";
import EvaluationApi from "../../../../Services/api/evaluation";
import HomeIcon from "../../../../assets/icons/home.svg";
import AddIcon from "../../../../assets/icons/plus.svg";
import { MainBtn } from "../../../Shared/Components";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import EvaluationListTable from "./EvaluationListTable";
import EvaluationPopUp from "./EvaluationPopUp";
import classes from "./systemUsersList.module.css";
import { getCourseDetails } from "../../../../Services/api/courses/courseProvider";

const EvaluationList = ({ quizable, role }) => {
  const { t } = useTranslation();
  // const dispatch = useDispatch();
  // const { systemUsersListMeta } = useSelector(
  // 	(state) => state.systemUsersListReducer
  // );
  const [targetCourse, setTargetCourse] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [addCriteria, setAddCriteria] = useState(false);
  const [tableData, setTableData] = useState({});
  const handleOpenAddCriteria = () => {
    setAddCriteria(true);
  };
  const handleCloseAddCriteria = () => {
    setAddCriteria(false);
  };

  const sortActionHandler = async (columnKey) => {
    if (sortColumn == columnKey) {
      setSortOrder((prev) => (prev == "asc" ? "desc" : "asc"));
    } else {
      setSortOrder("asc");
    }
    setSortColumn(columnKey);
  };

  const getEvaluationListHandler = async (params) => {
    setIsExportLoading(true);
    const data = await EvaluationApi.list(currentPage, 10, params);
    setIsExportLoading(false);
    // dispatch(
    // 	fetchSystemUsersList({
    // 		page: currentPage,
    // 		perPage: 10,
    // 	})
    // );
    setTableData(data.data.data);
  };
  const { id, courseId, quizableId } = useParams();

  useEffect(() => {
    getEvaluationListHandler({ course_id: courseId });
    if (isResetApplied) {
      setIsResetApplied(false);
    }
  }, [currentPage, isResetApplied, sortColumn, sortOrder]);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "control_panel",
      page: t("controlPanel"),
      pagePath: "/admin",
    },
    ...(quizable
      ? [
          // {
          //   id: t("sidebar.label.courses"),
          //   page: t("sidebar.label.courses"),
          //   pagePath: `/${role}/courses`,
          // },
          {
            id: t("Courseoutline"),
            page: t("Courseoutline"),
            pagePath: `/${role}${
              targetCourse?.program_id
                ? `/program/${targetCourse.program_id}`
                : ``
            }/course/${courseId}`,
          },
          {
            id: t("trainer.quiz.exams"),
            page: t("trainer.quiz.exams"),
            pagePath: `/${role}/course/${courseId}/${quizable}/${quizableId}/assessments`,
          },
        ]
      : []),
    ...(!quizable
      ? [
          {
            id: t("Courseoutline"),
            page: t("Courseoutline"),
            pagePath: `/${role}${
              targetCourse?.program_id
                ? `/program/${targetCourse.program_id}`
                : ``
            }/course/${courseId}`,
          },
        ]
      : []),

    {
      id: "evaluation",
      page: t("answerCriteria.table_title"),
      active: true,
    },
  ];

  const getCourseDetailsAPI = () => {
    getCourseDetails(courseId)
      .then((res) => {
        if (res.status === 200 && res.data.status) {
          setTargetCourse(res.data.course);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (courseId) {
      getCourseDetailsAPI();
    }
  }, [courseId]);

  return (
    <>
      <div className={classes["system-users-list"]}>
        {/* {isExportLoading && <SkeletonCardOverlay skeletonWidth="100" />} */}
        <div className="container-fluid">
          <div className={classes["system-users-list__container"]}>
            <div className="row">
              <div className="col-12">
                <Breadcrumb list={breadcrumbList} />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <MainBox>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    gap={{ xs: "30px", sm: "0" }}
                    justifyContent={{ sm: "space-between" }}
                    alignItems={{ sm: "center" }}
                  >
                    <MainBoxHead title={t("answerCriteria.table_title")} />
                    {role !== "partner" ? (
                      <MainBtn
                        width={"50px"}
                        height={"50px"}
                        onClick={handleOpenAddCriteria}
                      >
                        <img src={AddIcon} alt="add-icon" />
                      </MainBtn>
                    ) : null}
                  </Stack>
                  <EvaluationListTable
                    setTableData={setTableData}
                    rows={tableData.data || []}
                    sortAction={sortActionHandler}
                    courseId={courseId}
                    isExportLoading={isExportLoading}
                    role={role}
                  />
                  <Pagination
                    count={
                      (tableData &&
                        tableData.meta &&
                        tableData.meta.totalPages) ||
                      0
                    }
                    showFirstButton
                    showLastButton
                    variant="outlined"
                    shape="rounded"
                    className="main-pagination"
                    onChange={(e, page) => {
                      setCurrentPage(page);
                    }}
                  />
                </MainBox>
              </div>
            </div>
          </div>
        </div>
      </div>
      {addCriteria && (
        <EvaluationPopUp
          courseId={courseId}
          setTableData={setTableData}
          addCriteria={addCriteria}
          handleCloseAddCriteria={handleCloseAddCriteria}
        />
      )}
    </>
  );
};

export default EvaluationList;
