import React from 'react';
import {ProgramProvider} from '../../../Services/api/program/programProvider';
import Programs from '../../home/program/programs';

function program() {
  return (
    <ProgramProvider>
      {/* <AllPrograms /> */}
      <Programs/>
    </ProgramProvider>
  );
}

export default program;
