export const refundssListConstants = {
  GET_REFUNDS_RESET: "GET_REFUNDS_RESET",
  // GET ALL REFUNDS LIST
  GET_REFUNDS_LIST_REQUEST: "GET_REFUNDS_LIST_REQUEST",
  GET_REFUNDS_LIST_SUCCESS: "GET_REFUNDS_LIST_SUCCESS",
  GET_REFUNDS_LIST_REJECTED: "GET_REFUNDS_LIST_REJECTED",
  GET_REFUND_BY_ID: "GET_REFUND_BY_ID",

  // EDIT REFUND
  EDIT_REFUND_REQUEST: "EDIT_REFUND_REQUEST",
  EDIT_REFUND_SUCCESS: "EDIT_REFUND_SUCCESS",
  EDIT_REFUND_REJECTED: "EDIT_REFUND_REJECTED",

  // ADD REFUND
  ADD_REFUND_REQUEST: "ADD_REFUND_REQUEST",
  ADD_REFUND_SUCCESS: "ADD_REFUND_SUCCESS",
  ADD_REFUND_REJECTED: "ADD_REFUND_REJECTED",

  // DELETE REFUND
  DELETE_REFUND_REQUEST: "DELETE_REFUND_REQUEST",
  DELETE_REFUND_SUCCESS: "DELETE_REFUND_SUCCESS",
  DELETE_REFUND_REJECTED: "DELETE_REFUND_REJECTED",

  // UPDATE REFUND STATUS
  UPDATE_REFUND_STATUS_REQUEST: "UPDATE_REFUND_STATUS_REQUEST",
  UPDATE_REFUND_STATUS_SUCCESS: "UPDATE_REFUND_STATUS_SUCCESS",
  UPDATE_REFUND_STATUS_REJECTED: "UPDATE_REFUND_STATUS_REJECTED",
};
