import { useTranslation } from "react-i18next";
import FilterBox from "../../../Shared/Components/FilterBox/FilterBox";

const SpecialtiesListFilter = ({filterData,changeHandler,resetHandler,resetFilterHandler,currentPage,setCurrentPage,filterHandler}) => {
  const { t, i18n } = useTranslation();

  const filterFields = [
		{
			id: "name",
			component: "input",
			label: t("specialties_list.name"),
			type: "text",
			placeholder: t("specialties_list.name"),
			name: "name",
			value: filterData.name,
			classes: "col-12 col-md-6 col-xl-4",
			onchange: changeHandler,
			onReset: resetHandler,
		},
		{
			id: "description",
			component: "input",
			label: t("specialties_list.description"),
			type: "text",
			placeholder: t("specialties_list.description"),
			name: "description",
			value: filterData.description,
			classes: "col-12 col-md-6 col-xl-4",
			onchange: changeHandler,
			onReset: resetHandler,
		},

	
		{
			id: "created_at",
			label: t("general.created_at"),
			component: "datePicker",
			placeholder: t("general.created_at"),
			name: "created_at",
			value: filterData.created_at,
			classes: "col-12 col-md-6 col-xl-4",
			onchange: changeHandler,
		},
	

		{
			id: "action",
			component: "button",
			type: "button",
			name: "action",
			value: t("general.reset"),
			classes: "col-12 col-md-6 col-xl-2 border-button",
			action: () => {
				resetFilterHandler();
			},
			backgroundColor: "transparent",
			color: "#046c77",
			borderColor: "#046c77",
		},
		{
			id: "action",
			component: "button",
			type: "button",
			name: "action",
			value: t("general.search"),
			classes: "col-12 col-md-6  col-xl-2",
			action: () => {
				if (currentPage != 1) {
					setCurrentPage(1);
				} else {
					filterHandler();
				}
			},
		},
	];

  return (
    <FilterBox fields={filterFields} />
  )
}

export default SpecialtiesListFilter