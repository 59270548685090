import { programPaymentListConstant } from "../../Constants/ProgramPaymentList/programPaymentList.constant";

const initialState = {
  programPaymentList: [],
  programPaymentListMeta: {},
  programPaymentListLoading: false,
  programPaymentListError: null,
  programPaymentListSuccess: false,
};

const programPaymentListReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET SYSTEM USERS LIST
    case programPaymentListConstant.GET_FINANCIAL_PROGRAM_PAYMENT_LIST_REQUEST:
      return {
        ...state,
        programPaymentListLoading: true,
      };
    case programPaymentListConstant.GET_FINANCIAL_PROGRAM_PAYMENT_LIST_SUCCESS:
      return {
        ...state,
        programPaymentListLoading: false,
        programPaymentListSuccess: true,
        programPaymentList: action.payload.programPaymentList.payments,
        programPaymentListMeta: action.payload.programPaymentList.meta,
      };
    case programPaymentListConstant.GET_FINANCIAL_PROGRAM_PAYMENT_LIST_REJECTED:
      return {
        ...state,
        programPaymentListLoading: false,
        programPaymentListError: action.payload,
        programPaymentListSuccess: false,
      };

    default:
      return state;
  }
};

export default programPaymentListReducer;
