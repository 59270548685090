import React from "react";
import tras from "../../../../../assets/image/transfer(1).png";
import file from "../../../../../assets/image/filechapter.png";
import online from "../../../../../assets/image/online-business.png";
import surface from "../../../../../assets/image/surface1.png";
import Question from "../../../../../assets/image/Question bank.png";
import conversation from "../../../../../assets/image/conversation.png";
import icon2 from "../../../../../assets/image/Icon2.png";
import edit from "../../../../../assets/image/edit(1).png";
import order from "../../../../../assets/image/Order.png";
import trash from "../../../../../assets/image/trash.png";
import gear from "../../../../../assets/image/gear.png";
import example from "../../../../../assets/image/christina-wocintechchat-com-34GZCgaVksk-unsplash.png";
import ContentExplanation from "../../contentExplanation/contentExplanation";
import CourseOutlineList from "../../courseOutlineList/courseOutlineList";
import {NavLink} from "react-router-dom";

function LessonContent() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="training_first_label">الفصل الأول / مقدمة</div>
          <div className="course_outline_content">
            <div className="chapter_flex">
              <div className="new_chapter">
                <div className="new_chapter_season">
                  <div className="new_chapter_select_season">
                    <img
                      src={icon2}
                      alt=""
                      className="new_chapter_select_arrow"
                    />
                    <select name="" id="" className="new_chapter_select">
                      <option value="">الدرس الأول</option>
                    </select>
                    <button className="edit_select_chapter">
                      <img
                        src={edit}
                        alt=""
                        className="edit_select_chapter_icon"
                      />
                    </button>
                  </div>
                  <div className="new_season_icons">
                    <img src={gear} alt="" className="new_season_icon" />
                    <img src={trash} alt="" className="new_season_icon" />
                    <img src={order} alt="" className="new_season_icon" />
                  </div>
                </div>
                <div className="card card_width">
                  <div className="new_chapter_season">
                    <div className="new_chapter_select_season">محتوي الدرس</div>
                    <div className="new_season_icons_2">
                      <button className="edit_select_chapter_2">
                        <img
                          src={edit}
                          alt=""
                          className="edit_select_chapter_icon"
                        />
                        <div className="icon_text_new_season">
                          تحرير المحتوي
                        </div>
                      </button>

                      <img src={file} alt="" className="new_season_icon" />
                      <img src={tras} alt="" className="new_season_icon" />
                      <img src={gear} alt="" className="new_season_icon" />
                      <img src={order} alt="" className="new_season_icon" />
                    </div>
                  </div>

                  <div className="lesson_content">
                    <div className="lesson_title_content">
                      <div className="lesson_title_content_label">
                        عنوان رئيسي بحجم الخط
                      </div>
                      <div className="lesson_content_text">
                        هنا يتم كتابة محتوي الدرس يستبدل بالنص الأصلي بالنقر على
                        تحرير المحتوي بالأعلي يمكنك إضافة الصور والفيديو والنصوص
                        من المحرر بالضغط على تحرير المحتوي بالأعلي يمكن إضافة
                        الأسئلة والمناقشات في الخطوة التالية شكرا للمعاينة
                      </div>
                    </div>
                    <div className="lesson_title_content">
                      <div className="lesson_example_content">
                        مثال عنوان فرعي بحجم الخط
                      </div>
                      <div className="lesson_content_text">
                        يمكنك إضافة الصور والفيديو والنصوص من المحرر بالضغط على
                        تحرير المحتوي بالأعلي يمكن إضافة الأسئلة والمناقشات في
                        الخطوة التالية شكرا للمعاينة
                      </div>
                    </div>
                    <div className="lesson_title_content">
                      <div className="lesson_example_content">
                        مثال طريقة عرض الصور داخل الدرس
                      </div>
                      <img src={example} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="add_ingredient">
                <div className="">أضف مكون جديد</div>
                <div className="add_ingredient_cards">
                  <div className="add_ingredient_card actives">
                    <img src={online} alt="" />
                    <div className="">أضف مناقشة</div>
                  </div>
                  <NavLink
                    to="/course-outline/add-new-lesson/add-question"
                    className="add_ingredient_card add_new_chapter"
                  >
                    <img src={conversation} alt="" />
                    <div className="">أضف سؤال</div>
                  </NavLink>
                  <div className="add_ingredient_card">
                    <img src={surface} alt="" />
                    <div className="">أضف إختبار</div>
                  </div>
                  <div className="add_ingredient_card">
                    <img src={Question} alt="" />
                    <div className="">بنك الأسئلة</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="explain_content">
              <ContentExplanation />
              <CourseOutlineList />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LessonContent;
