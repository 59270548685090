import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CommentIcon from "@mui/icons-material/Comment";
import ScaleIcon from "@mui/icons-material/Scale";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import AddActivityToLessonModal from "../../../../../../../Modals/AddActivityToLessonModal/AddActivityToLessonModal";
import EditActivityToLessonModal from "../../../../../../../Modals/EditActivityToLessonModal/EditActivityToLessonModal";
import { LessonContentContext } from "../../../../../../../Providers/LessonContentProvider";
import DeleteIconModal from "../../../../../../../assets/icons/delete.svg";
import CourseDetailsHeader from "../../../../../../Shared/Components/CourseDetailsHeader/CourseDetailsHeader";
import SharedModal from "../../../../../../Shared/Components/SharedModal/SharedModal";
import { ActivitiesActions } from "./activitiesActions";

export default function PlanItem({
  id,
  title,
  onView,
  onEdit,
  onDelete,
  content,
}) {
  require("./List.css");
  const [opened, setOpened] = useState(false);
  const lessonContentContext = useContext(LessonContentContext);
  const { id: lessonId } = useParams();
  const { t } = useTranslation();

  const handleCloseModal = () => lessonContentContext.setIsModalOpen(false);

  const onDeleteFile = async (targetId) => {
    try {
      // lessonContentContext.deleteQuiz(targetId);
      await onDelete(targetId);
      lessonContentContext.fetchLessonContent(lessonId);
      lessonContentContext.setIsModalOpen(false);
    } catch (error) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("trainer.course.lesson.failed_deleting_content")}
        </span>
      );
    }
  };

  const handleOpenDeleteModal = (event, targetId) => {
    event.stopPropagation();
    event.preventDefault();
    lessonContentContext.setTargetModalContent(deleteModal(targetId));
    lessonContentContext.setIsModalOpen(true);
  };

  const deleteModal = (targetId) => {
    return (
      <SharedModal
        icon={DeleteIconModal}
        title={t("modal.trainer.course.lesson.material.delete.title")}
        confirmMsg={t("modal.trainer.course.lesson.material.delete.body")}
        confirmText={t("general.confirm")}
        cancelText={t("general.cancel")}
        onCancel={handleCloseModal}
        onConfirm={() => onDeleteFile(targetId)}
        isLoading={false}
      />
    );
  };

  return (
    <div className={"tajah-lesson-activity-list-canvas"}>
      <div
        key={id}
        className={`tajah-lesson-activity-list-item ${opened ? "opened" : ""}`}
      >
        <CourseDetailsHeader
          title={title}
          actions={ActivitiesActions({
            viewAction: onView,
            ...(onEdit && {
              editAction: onEdit,
            }),
            ...(onDelete && {
              deleteAction: (e) => {
                handleOpenDeleteModal(e, id);
              },
            }),
          })}
          setOpened={setOpened}
          opened={opened}
        />
        {opened && content && (
          <div className={"tajah-activity-details-canvas"}>{content}</div>
        )}
      </div>
    </div>
  );
}
