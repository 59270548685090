import { Stack } from "@mui/material";
import { BoxCard } from "../../Shared/Components";
import { ContactMail, ContactPhone } from "../../Shared/icons";
import ContactInfoBox from "./ContactInfoBox";

function ContactInformation() {
  return (
    <Stack spacing={"20px"}>
      <BoxCard sx={{ p: "0px !important" }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3573.9500890739055!2d50.19039861503384!3d26.39280008335436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e49ef85c961edaf%3A0x7b2db98f2941c78c!2z2KzYp9mF2LnYqSDYp9mE2KXZhdin2YUg2LnYqNiv2KfZhNix2K3ZhdmGINio2YYg2YHZiti12YQg2KfZhNmF2K_ZitmG2Kkg2KfZhNis2KfZhdi52YrYqSDYp9mE2KzYr9mK2K_YqQ!5e0!3m2!1sen!2ssa!4v1631710068008!5m2!1sen!2ssa"
          title="Contact us map"
          width="100%"
          height="767px"
          style={{ border: 0, borderRadius: "6px" }}
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </BoxCard>
      <ContactInfoBox
        icon={<ContactMail />}
        title={"emailus"}
        subTitle={"askqus"}
        btnText={"leaveMessage"}
        href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
      />
      <ContactInfoBox
        icon={<ContactPhone />}
        title={"lovehear"}
        subTitle={"wearehappytores"}
        btnText={process.env.REACT_APP_SUPPORT_PHONE}
        href={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_SUPPORT_PHONE?.replace(/[^0-9]/g, "")}`}
      />
    </Stack>
  );
}

export default ContactInformation;
