import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Lang } from "../../../utils";
import Title from "../../Shared/Components/Title/Title";
import classes from "./trainersTitle.module.css";
import Select from "../../Shared/Components/Select/Select";

function TrainersHeader({
  specialties,
  selectedClassification,
  handleChange,
  departments,
  handleDepChange,
  selectedDepartment,
}) {
  require("./trainersTitle.css");
  const { t, i18n } = useTranslation();
  const { id } = useParams;

  return (
    <>
      <Title title={t("general.trainer")} fontSize={24} />
      <div
        style={{
          display: "flex",
          gap: "10px",
        }}
      >
        <Select
          label={t("general.specialty")}
          name={"specialty_id"}
          onchange={handleChange}
          options={specialties.map((specialty) => {
            return {
              id: specialty.id,
              value: specialty.id,
              label:
                i18n.language === Lang.AR
                  ? specialty.name_ar
                  : specialty.name_en,
            };
          })}
          value={selectedClassification}
          initialValue={t("general.specialty")}
        />
        <Select
          label={t("general.department")}
          name={"department_id"}
          onchange={handleDepChange}
          options={departments.map((department) => {
            return {
              id: department.id,
              value: department.id,
              label: department.name,
            };
          })}
          value={selectedDepartment}
          initialValue={t("general.department")}
        />
      </div>
    </>
  );
}

export default TrainersHeader;
