import {useHistory, withRouter} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";

function EmailVerification(props) {
  require("./emailVerification.css");
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (props.location.state == undefined) {
      history.push("/");
    }
  }, []);

  return props.location.state ? (
    <>
      <div className="auth-email-verification-container">
        <h1 className="auth-email-verification-title alert-success">
          {t("EmailVerificationTitle")}
        </h1>
        <p className="auth-email-verification-body">
          {t("EmailVerificationBody", { email: props.location.state.email })}
        </p>
      </div>
    </>
  ) : null;
}

export default withRouter(EmailVerification);
