import env from "../Environment";
import connector from "../Handler";
import URLBuilder from "../UrlBuilder";

export function allBlock({ perPage = 10, page = 1, filter = {} }) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.all_block, {
          params: {
            page,
            perPage,
            ...filter,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getBlockedInfo(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.info_block, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getUnblockList(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.unblock_list, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function deleteBlocked(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.delete_block, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function createBlocked(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.create_block, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function updateBlocked(id, payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.update_block, { id: id }), payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function courseBlock(params) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.course_block, { params })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function lessonBlock(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.lesson_block, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function blockRequest({ perPage = 10, page = 0, filter }) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.block_request, {
          params: {
            page,
            perPage,
            ...filter,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function blockAllowedIgnored({ perPage = 10, page = 0, filter }) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.block_allowed_ignored, {
          params: {
            page,
            perPage,
            ...filter,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function userBlockRequest(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.user_block_request, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function unblockRequest(payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.unblock_ip, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
  return myPromise;
}

export function blockIgnore(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.block_ignore, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function blockCountries() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.block_countries)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
