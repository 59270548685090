import env from "../Environment";
import { exportApi } from "../Essential";
import connector from "../Handler";
import URLBuilder from "../UrlBuilder";

export function getCourseTrainees(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_course_trainees, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getTeamTypes() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_question_types)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getTeamParticipants(quizId, params) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(
          URLBuilder(env.get_quiz_participants, {
            quiz_id: quizId,
          }),
          {
            params: params,
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function exportTeamParticipants(type, quizId, params) {
  return exportApi(
    type,
    URLBuilder(env.get_quiz_participants, {
      quiz_id: quizId,
    }),
    params,
    "participants"
  );
}

export function getTeams(courseId, params) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.teams_route, { courseId }), {
          params,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getTeamById(courseId, id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.team_by_id, { courseId, id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getCreateTeam(courseId) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.create_team, { courseId }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getEditTeam(courseId, id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.edit_team, { courseId, id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function openExternalLinkApi() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.open_external_link)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function exportTeams(type, courseId, filter) {
  return exportApi(
    type,
    URLBuilder(env.teams_route, { courseId }),
    filter,
    "teams"
  );
}

export function toggleTeamActivity(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .put(URLBuilder(env.team_status_toggle, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function deleteTeam(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .delete(URLBuilder(env.team_by_id, { id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function addTeam(courseId, payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.teams_route, { courseId }), payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function editTeam(courseId, id, payload) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.team_by_id, { courseId, id }), {
          _method: "put",
          ...payload,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
