export const exhibitionConstant = {
  // get exhibition booths list
  GET_EXHIBITION_BOOTHS_LIST_REQUEST: "GET_EXHIBITION_BOOTHS_LIST_REQUEST",
  GET_EXHIBITION_BOOTHS_LIST_SUCCESS: "GET_EXHIBITION_BOOTHS_LIST_SUCCESS",
  GET_EXHIBITION_BOOTHS_LIST_REJECTED: "GET_EXHIBITION_BOOTHS_LIST_REJECTED",

  // get exhibition sponsors
  GET_EXHIBITION_SPONSORS_REQUEST: "GET_EXHIBITION_SPONSORS_REQUEST",
  GET_EXHIBITION_SPONSORS_SUCCESS: "GET_EXHIBITION_SPONSORS_SUCCESS",
  GET_EXHIBITION_SPONSORS_REJECTED: "GET_EXHIBITION_SPONSORS_REJECTED",

  // get exhibition visitors
  GET_EXHIBITION_VISITORS_REQUEST: "GET_EXHIBITION_VISITORS_REQUEST",
  GET_EXHIBITION_VISITORS_SUCCESS: "GET_EXHIBITION_VISITORS_SUCCESS",
  GET_EXHIBITION_VISITORS_REJECTED: "GET_EXHIBITION_VISITORS_REJECTED",

  // approve exhibition by visitor
  APPROVE_EXHIBITION_BY_VISITOR_REQUEST: "APPROVE_EXHIBITION_BY_VISITOR_REQUEST",
  APPROVE_EXHIBITION_BY_VISITOR_SUCCESS: "APPROVE_EXHIBITION_BY_VISITOR_SUCCESS",
  APPROVE_EXHIBITION_BY_VISITOR_REJECTED: "APPROVE_EXHIBITION_BY_VISITOR_REJECTED",

  // reject exhibition by visitor
  REJECT_EXHIBITION_BY_VISITOR_REQUEST: "REJECT_EXHIBITION_BY_VISITOR_REQUEST",
  REJECT_EXHIBITION_BY_VISITOR_SUCCESS: "REJECT_EXHIBITION_BY_VISITOR_SUCCESS",
  REJECT_EXHIBITION_BY_VISITOR_REJECTED: "REJECT_EXHIBITION_BY_VISITOR_REJECTED",

  //  approve exhibition payment by booth
  APPROVE_EXHIBITION_PAYMENT_BY_BOOTH_REQUEST: "APPROVE_EXHIBITION_PAYMENT_BY_BOOTH_REQUEST",
  APPROVE_EXHIBITION_PAYMENT_BY_BOOTH_SUCCESS: "APPROVE_EXHIBITION_PAYMENT_BY_BOOTH_SUCCESS",
  APPROVE_EXHIBITION_PAYMENT_BY_BOOTH_REJECTED: "APPROVE_EXHIBITION_PAYMENT_BY_BOOTH_REJECTED",

  //  reject exhibition payment by booth
  REJECT_EXHIBITION_PAYMENT_BY_BOOTH_REQUEST: "REJECT_EXHIBITION_PAYMENT_BY_BOOTH_REQUEST",
  REJECT_EXHIBITION_PAYMENT_BY_BOOTH_SUCCESS: "REJECT_EXHIBITION_PAYMENT_BY_BOOTH_SUCCESS",
  REJECT_EXHIBITION_PAYMENT_BY_BOOTH_REJECTED: "REJECT_EXHIBITION_PAYMENT_BY_BOOTH_REJECTED",

  //  approve exhibition payment by visitor
  APPROVE_EXHIBITION_PAYMENT_BY_VISITOR_REQUEST: "APPROVE_EXHIBITION_PAYMENT_BY_VISITOR_REQUEST",
  APPROVE_EXHIBITION_PAYMENT_BY_VISITOR_SUCCESS: "APPROVE_EXHIBITION_PAYMENT_BY_VISITOR_SUCCESS",
  APPROVE_EXHIBITION_PAYMENT_BY_VISITOR_REJECTED: "APPROVE_EXHIBITION_PAYMENT_BY_VISITOR_REJECTED",

  //  REJECT exhibition payment by visitor
  REJECT_EXHIBITION_PAYMENT_BY_VISITOR_REQUEST: "REJECT_EXHIBITION_PAYMENT_BY_VISITOR_REQUEST",
  REJECT_EXHIBITION_PAYMENT_BY_VISITOR_SUCCESS: "REJECT_EXHIBITION_PAYMENT_BY_VISITOR_SUCCESS",
  REJECT_EXHIBITION_PAYMENT_BY_VISITOR_REJECTED: "REJECT_EXHIBITION_PAYMENT_BY_VISITOR_REJECTED",

  // LIST exhibition
  GET_EXHIBITION_VISIT_REQUEST: "GET_EXHIBITION_VISIT_REQUEST",
  GET_EXHIBITION_VISIT_SUCCESS: "GET_EXHIBITION_VISIT_SUCCESS",
  GET_EXHIBITION_VISIT_TO_CART_REQUEST: "GET_EXHIBITION_VISIT_TO_CART_REQUEST",
  GET_EXHIBITION_VISIT_TO_CART_SUCCESS: "GET_EXHIBITION_VISIT_TO_CART_SUCCESS",
  PAY_ADD_BOOTH_WITH_BANK_TRANSFER_REQUEST: "PAY_ADD_BOOTH_WITH_BANK_TRANSFER_REQUEST",
  PAY_ADD_BOOTH_WITH_BANK_TRANSFER_SUCCESS: "PAY_ADD_BOOTH_WITH_BANK_TRANSFER_SUCCESS",
  PAY_ADD_BOOTH_WITH_BANK_TRANSFER_REJECTED: "PAY_ADD_BOOTH_WITH_BANK_TRANSFER_REJECTED",
  GET_PHONE_CODE: "GET_PHONE_CODE",

  // payExhibitionVisiter
  PAY_EXHIBITION_VISITER_REQUEST: "PAY_EXHIBITION_VISITER_REQUEST",
  PAY_EXHIBITION_VISITER_SUCCESS: "PAY_EXHIBITION_VISITER_SUCCESS",
  PAY_EXHIBITION_VISITER_REJECTED: "PAY_EXHIBITION_VISITER_REJECTED",

  PAY_EXHIBITION_BOOTH_REQUEST: "PAY_EXHIBITION_BOOTH_REQUEST",
  PAY_EXHIBITION_BOOTH_SUCCESS: "PAY_EXHIBITION_BOOTH_SUCCESS",
  PAY_EXHIBITION_BOOTH_REJECTED: "PAY_EXHIBITION_BOOTH_REJECTED",

  // UPDATE BOOTH STATUS
  UPDATE_BOOTH_STATUS_REQUEST: "UPDATE_BOOTH_STATUS_REQUEST",
  UPDATE_BOOTH_STATUS_SUCCESS: "UPDATE_BOOTH_STATUS_SUCCESS",
  UPDATE_BOOTH_STATUS_REJECTED: "UPDATE_BOOTH_STATUS_REJECTED",

  // GET EXHIBITION BY ID
  GET_EXHIBITION_BY_ID_REQUEST: "GET_EXHIBITION_BY_ID_REQUEST",
  GET_EXHIBITION_BY_ID_SUCCESS: "GET_EXHIBITION_BY_ID_SUCCESS",
  GET_EXHIBITION_BY_ID_REJECTED: "GET_EXHIBITION_BY_ID_REJECTED",

  // RATE EXHIBITION
  RATE_EXHIBITION_REQUEST: "RATE_EXHIBITION_REQUEST",
  RATE_EXHIBITION_SUCCESS: "RATE_EXHIBITION_SUCCESS",
  RATE_EXHIBITION_REJECTED: "RATE_EXHIBITION_REJECTED",

  // UPDATE EXHIBITION USER AFTER PAYMENT
  UPDATE_EXHIBITION_USER_AFTER_PAYMENT_REQUEST: "UPDATE_EXHIBITION_USER_AFTER_PAYMENT_REQUEST",
};
