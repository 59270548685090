export default function CircularProgressBar({ title, percent, color, style }) {
  return (
    <div
      className={`tw-flex tw-items-center tw-justify-center tw-h-10 tw-w-10 tw-rounded-full tw-text-sm`}
      style={{
        ...style,
        background: `radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(${color} ${
          Number(percent) || 0
        }%, #e9ecef 0)`,
      }}
      title={title}
    >
      <div>{`${Math.round(percent) || 0}`}</div>
    </div>
  );
}

export function CircularPercentBar({ title, percent, color, style }) {
  return (
    <div
      className={`tw-flex tw-items-center tw-justify-center tw-h-14 tw-w-14 tw-rounded-full tw-text-sm`}
      style={{
        ...style,
        background: `radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(${color} ${
          Number(percent) || 0
        }%, #e9ecef 0)`,
      }}
      title={title}
    >
      <div>{`${Math.round(percent) || 0}%`}</div>
    </div>
  );
}
