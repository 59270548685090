import React, { useEffect, useRef } from "react";
import classes from "./confirmationModal.module.css";
import { useTranslation } from "react-i18next";
import Textarea from "../Textarea/Textarea";
import CloseIcon from "../../../../assets/icons/remove-red.svg";
import { Box, CircularProgress } from "@material-ui/core";

const ConfirmationModal = ({
  closeModal,
  action,
  type,
  onChange,
  isLoading,
  error,
}) => {
  const { t, i18n } = useTranslation();
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  return (
    <div className={classes["confirmation-modal"]}>
      <div className={classes["confirmation-modal__container"]} ref={modalRef}>
        <div className={classes["confirmation-modal__head"]}>
          <div className={classes["confirmation-modal__head-title"]}>
            {t("financial.label.enter_reject_reason")}
          </div>
        </div>
        <div
          className={classes["confirmation-modal__close"]}
          onClick={() => {
            closeModal();
          }}
        >
          <img src={CloseIcon} alt="" />
        </div>
        <div className={classes["confirmation-modal__content"]}>
          <Textarea
            placeholder={t("financial.label.reject_reason")}
            name="reject_reason"
            onChange={(e) => {
              onChange(e);
            }}
            error={error}
          />
        </div>
        <div className={classes["confirmation-modal__actions"]}>
          <button
            className={`${classes["confirmation-modal__actions-btn"]} ${
              classes[`confirmation-modal__actions-btn-${type}`]
            }`}
            onClick={() => {
              action();
            }}
            disabled={isLoading}
          >
            {isLoading ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress color="white" size={30} />
              </Box>
            ) : (
              t("financial.refund.reject")
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
