import { useState } from "react";
import AddIcon from "../../../../assets/icons/plus.svg";
import RemoveRedIcon from "../../../../assets/icons/remove-red.svg";
import Input from "../Input/Input";
import classes from "./multiInput.module.css";

const MultiInput = ({ label, name, placeholder, itemPlaceholder, setFieldValue, value, error, required }) => {
  const [items, setItems] = useState(value?.length ? value : []);

  const handleAdd = () => {
    setItems([...items, ""]);
  };

  const handleRemove = (index) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
    setFieldValue(name, newItems);
  };

  const handleChange = (index, event) => {
    const newItems = [...items];
    newItems[index] = event.target.value;
    setItems(newItems);
    setFieldValue(name, newItems);
  };

  return (
    <div className={classes["items"]}>
      <label htmlFor={name} className={classes["items-label"]}>
        {label}
      </label>
      <div className={classes["items__container__content"]}>
        <div className={classes["items__container"]}>
          <input type="text" name={name} id={name} placeholder={placeholder} disabled={true} />
          <button className={classes["items__container__field-btn"]} onClick={handleAdd} type="button">
            <img src={AddIcon} alt="" />
          </button>
        </div>
        {items && !!items.length && (
          <div className={classes["items__previews"]}>
            {items?.map((link, index) => (
              <div className={classes["items__previews__item"]} key={index}>
                <Input
                  type="text"
                  value={link}
                  name={name}
                  placeholder={itemPlaceholder}
                  onChange={(event) => handleChange(index, event)}
                  label={itemPlaceholder}
                  error={error && Array.isArray(error) ? error[index] : null}
                  required={required}
                />
                <div
                  className={classes["input-file__container__previews__item-remove"]}
                  onClick={() => handleRemove(index)}
                >
                  <img src={RemoveRedIcon} alt="" />
                </div>
              </div>
            ))}
          </div>
        )}

        {error && typeof error === "string" ? (
          <p className={`form-input-error-space ${classes["input-file-error"]}`} style={{ paddingTop: "10px " }}>
            {error}
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default MultiInput;
