import { searchConstants } from "../Constants/search.constants";

const initialState = {
	search: {},
	searchTabs: [],
	searchLoading: false,
	searchError: null,
	sendSearchLoading: false,
	sendSearchError: null,
	sendSearchSuccess: false,
	searchList: [],
	searchColumns: [],
	searchMeta: {},
	savedSearches: [],
	savedSearchesMeta: {},
	savedSearchesLoading: false,
	savedSearchesError: null,
	savedSearchesSuccess: false,
	savedSearchDetailsList: [],
	savedSearchDetailsColumns: [],
	savedSearchDetailsMeta: {},
	savedSearchDetailsLoading: false,
	savedSearchDetailsError: null,
	savedSearchDetailsSuccess: false,
	savedSearchDetailsFinished: false,

	deleteSavedSearchLoading: false,
	deleteSavedSearchError: null,
	deleteSavedSearchSuccess: false,

	updateSavedSearchLoading: false,
	updateSavedSearchError: null,
	updateSavedSearchSuccess: false,

	freeSearchList: [],
	freeSearchMeta: {},
	freeSearchStatistics: {},
	freeSearchLoading: false,
	freeSearchError: null,
	freeSearchSuccess: false,
};

const searchReducer = (state = initialState, action) => {
	switch (action.type) {
		case searchConstants.GET_SEARCH_DATA_REQUEST:
			return {
				...state,
				searchLoading: true,
			};
		case searchConstants.GET_SEARCH_DATA_SUCCESS:
			return {
				...state,
				searchLoading: false,
				search: {
					...state.search,
					...action.payload.search,
				},
			};
		case searchConstants.GET_SEARCH_Tabs_SUCCESS:
			return {
				...state,
				searchLoading: false,
				searchTabs: Object?.values(action.payload.searchTabs)?.map((item) => {
					return {
						value: item?.name,
						text: item?.label,
					};
				}),

			};
		case searchConstants.GET_SEARCH_DATA_FAILURE:
			return {
				...state,
				searchLoading: false,
				searchError: action.payload,
			};
		// Send Search
		case searchConstants.SEND_SEARCH_REQUEST:
			return {
				...state,
				sendSearchLoading: true,
			};
		case searchConstants.SEND_SEARCH_SUCCESS:
			return {
				...state,
				sendSearchLoading: false,
				sendSearchSuccess: true,
				searchList: action.payload.list,
				searchColumns: action.payload.columns,
				searchMeta: action.payload.meta,
			};
		case searchConstants.SEND_SEARCH_FAILURE:
			return {
				...state,
				sendSearchLoading: false,
				sendSearchError: action.payload,
			};

		// Get Saved Searches
		case searchConstants.GET_SAVED_SEARCHES_REQUEST:
			return {
				...state,
				savedSearchesLoading: true,
			};
		case searchConstants.GET_SAVED_SEARCHES_SUCCESS:
			return {
				...state,
				savedSearchesLoading: false,
				savedSearches: action.payload.savedSearches,
				savedSearchesMeta: action.payload.meta,
			};
		case searchConstants.GET_SAVED_SEARCHES_FAILURE:
			return {
				...state,
				savedSearchesLoading: false,
				savedSearchesError: action.payload,
			};
		

		// Get Saved Search Details
		case searchConstants.GET_SAVED_SEARCH_DETAILS_REQUEST:
			return {
				...state,
				savedSearchDetailsLoading: true,
				savedSearchDetailsFinished: false
			};
		case searchConstants.GET_SAVED_SEARCH_DETAILS_SUCCESS:
			return {
				...state,
				savedSearchDetailsLoading: false,
				savedSearchDetailsList: action.payload.list,
				savedSearchDetailsColumns: action.payload.columns,
				savedSearchDetailsMeta: action.payload.meta,
				savedSearchDetailsSuccess: true,
				savedSearchDetailsFinished: true
			};
		case searchConstants.GET_SAVED_SEARCH_DETAILS_FAILURE:
			return {
				...state,
				savedSearchDetailsLoading: false,
				savedSearchDetailsError: action.payload,
				savedSearchDetailsSuccess: false,
				savedSearchDetailsFinished: true
			};

		// Delete Saved Search
		case searchConstants.DELETE_SAVED_SEARCH_REQUEST:
			return {
				...state,
				deleteSavedSearchLoading: true,
			};
		case searchConstants.DELETE_SAVED_SEARCH_SUCCESS:
			return {
				...state,
				deleteSavedSearchLoading: false,
				deleteSavedSearchSuccess: true,
				savedSearches: state.savedSearches.filter((item) => item.id !== action.payload),
			};
			
		case searchConstants.DELETE_SAVED_SEARCH_FAILURE:
			return {
				...state,
				deleteSavedSearchLoading: false,
				deleteSavedSearchError: action.payload,
			};
			
		// UPDATE SAVED SEARCH
		case searchConstants.UPDATE_SAVED_SEARCH_REQUEST:
			return {
				...state,
				updateSavedSearchLoading: true,
			};
		case searchConstants.UPDATE_SAVED_SEARCH_SUCCESS:
			return {
				...state,
				updateSavedSearchLoading: false,
				updateSavedSearchSuccess: true,
				savedSearches: state.savedSearches.map((item) => {
					if (item.id === action.payload.id) {
						return {
							...item,
							name: action.payload.name,
							report: action.payload.report,
						}
					}
					return item;
				}),
			};
		case searchConstants.UPDATE_SAVED_SEARCH_FAILURE:
			return {
				...state,
				updateSavedSearchLoading: false,
				updateSavedSearchError: action.payload,
			};

		// Reset Search
		case searchConstants.RESET_SEARCH:
			return {
				...state,
				searchList: [],
				searchColumns: [],
				searchMeta: {},
				sendSearchSuccess: false,
			};

		// GET FREE SEARCH
		case searchConstants.GET_FREE_SEARCH_REQUEST:
			return {
				...state,
				freeSearchLoading: true,
			};
		case searchConstants.GET_FREE_SEARCH_SUCCESS:
			return {
				...state,
				freeSearchLoading: false,
				freeSearchList: action.payload.list,
				freeSearchMeta: action.payload.meta,
				freeSearchStatistics: action.payload.statistics,
				freeSearchSuccess: true,
			};
		case searchConstants.GET_FREE_SEARCH_FAILURE:
			return {
				...state,
				freeSearchLoading: false,
				freeSearchError: action.payload,
				freeSearchSuccess: false,
			};


		// Reset Free Search
		case searchConstants.RESET_FREE_SEARCH:
			return {
				...state,
				freeSearchList: [],
				freeSearchMeta: {},
				freeSearchStatistics: {},
				freeSearchSuccess: false,
			};
		default:
			return state;
	}
};

export default searchReducer;
