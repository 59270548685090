import moment from "moment";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { ReactComponent as ArrowLeftIcon } from "../../../../../assets/icons/Btn.svg";
import { ReactComponent as LockIcon } from "../../../../../assets/icons/backgrond-lock.svg";
import { getLocaleTime } from "../../../../../utils/utcToLocal";
import CircularProgressBar from "../../TrainingCourseContent/CircularProgressBar";

export default function UnitLessonItem({
  index,
  courseId,
  chapterId,
  unitId,
  lesson,
}) {
  const { t } = useTranslation();
  const history = useHistory();

  const isLocked =
    // (index !== 0 && lesson.is_previous_completed === false) ||
    lesson.is_previous_completed === false ||
    lesson.is_on_team === false ||
    (lesson.start_date
      ? moment(getLocaleTime(new Date(lesson.start_date))).isAfter(new Date())
      : false) ||
    (lesson.end_date
      ? moment(getLocaleTime(new Date(lesson.end_date))).isBefore(new Date())
      : false);

  return (
    <button
      type="button"
      disabled={isLocked}
      onClick={() =>
        history.push(
          `/training-courses/${courseId}/chapter/${chapterId}/unit/${unitId}/lesson/${lesson.id}`,
          { index }
        )
      }
      className="tw-rounded-lg tw-text-start tw-w-full tw-border tw-border-gray-300 tw-p-4 tw-flex tw-flex-col tw-space-y-4 sm:tw-flex-row sm:tw-space-y-0 tw-items-stretch sm:tw-items-center tw-justify-between tw-space-s-4"
    >
      <div className="tw-flex tw-items-center tw-space-s-6">
        <div className="tw-bg-[#006e78] tw-rounded-lg tw-text-2xl tw-text-white tw-h-12 tw-aspect-square tw-flex tw-items-center tw-justify-center tw-font-sans">
          {index + 1}
        </div>

        <div className="tw-space-y-2">
          <div className="tw-text-lg tw-font-bold">{lesson.title}</div>
        </div>
      </div>
      <div className="tw-flex tw-items-center tw-justify-end tw-space-s-6">
        {isLocked ? (
          <div className="tw-text-sm tw-text-gray-400">
            {/* {index !== 0 && lesson.is_previous_completed === false ? ( */}
            {lesson.is_previous_completed === false ? (
              <>
                {`${t("trainer.course.lesson.by_previous")} `}
                <span className="tw-font-bold tw-text-gray-600">
                  {lesson?.previous_lesson?.title}
                </span>
              </>
            ) : lesson.is_on_team === false ? (
              <>
                {`${t("space_to")} `}
                <span className="tw-font-bold tw-text-gray-600">
                  {lesson?.team?.name}
                </span>
              </>
            ) : (
              `${t("trainer.course.lesson.start")} ${
                moment(getLocaleTime(new Date(lesson?.start_date))).format(
                  "YYYY-MM-DD hh:mm A"
                ) +
                " " +
                t("trainer.course.lesson.end")
              } ${moment(getLocaleTime(new Date(lesson?.end_date))).format(
                "YYYY-MM-DD hh:mm A"
              )}`
            )}
          </div>
        ) : (
          <></>
        )}

        <div className="tw-flex tw-items-center tw-space-s-3">
          <CircularProgressBar
            title={t("progress")}
            percent={lesson.progress_percentage}
            color="#24b3b9"
          />
          <CircularProgressBar
            title={t("watchingProgress")}
            percent={lesson.watch_percentage}
            color="#f57a30"
          />
        </div>
        {isLocked ? (
          <LockIcon className="tw-h-8 tw-w-8 tw-shrink-0" />
        ) : (
          <ArrowLeftIcon className="tw-h-8 tw-w-8 tw-shrink-0" />
        )}
      </div>
    </button>
  );
}
