import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { TrainingCourseProfileContext } from "../../../../Services/api/toutorials/TrainingCourseProfileContext";
import SkeletonSpinnerOverlay from "../../../Shared/Components/Spinner/SkeletonSpinnerOverlay";
import { exportCourseTraineesQuiz } from "../../../../Services/api/exams/ExamsProvider";
import { toast } from "react-toastify";
import { IconsTooltip } from "../../../../Components";
import { ReactComponent as PDFIcon } from "../../../../assets/icons/file-pdf.svg";
import { ReactComponent as XLSIcon } from "../../../../assets/icons/file-XLS.svg";

export default function TrainingCourseProfileHeaderDetails({
  isCertificate,
  id,
  handleOpenSurvies,
}) {
  const trainingCourseProfileContext = useContext(TrainingCourseProfileContext);
  const { t } = useTranslation();
  const history = useHistory();

  const exportHandler = async (type) => {
    try {
      await exportCourseTraineesQuiz(type, id);
    } catch (e) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };

  return (
    <div>
      {trainingCourseProfileContext.isLoading ? (
        <SkeletonSpinnerOverlay backdrop={true} skeletonWidth="80" />
      ) : (
        <div className="course-complete-progress" style={{ display: "flex" }}>
          <div className="training_head">
            <div className="training_text">{t("yourCompleteCourse")}</div>
            <div className="training_text training_text_small">
              {
                // isCertificate?
                //   100 +'/'+ 100
                // :
                !trainingCourseProfileContext.progress.total &&
                !trainingCourseProfileContext.progressQuiz.total
                  ? "100%"
                  : Math.round(
                      ((trainingCourseProfileContext.progress.progress +
                        trainingCourseProfileContext.progressQuiz.progress) /
                        (trainingCourseProfileContext.progress.total +
                          trainingCourseProfileContext.progressQuiz.total)) *
                        100
                    ) + "%"
              }
            </div>
            {/* <div className="training_text training_text_small">
                {t("points")}
                (
                    {
                        isCertificate?
                            100
                        :
                        trainingCourseProfileContext &&
                        trainingCourseProfileContext.userPoints
                    }
                )
                </div> */}
          </div>
          <div className="training_head training_up_level certi_progress">
            <div className="progress">
              {isCertificate ? (
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    width:
                      !trainingCourseProfileContext.progress.total &&
                      !trainingCourseProfileContext.progressQuiz.total
                        ? "100%"
                        : Math.round(
                            ((trainingCourseProfileContext.progress.progress +
                              trainingCourseProfileContext.progressQuiz
                                .progress) /
                              (trainingCourseProfileContext.progress.total +
                                trainingCourseProfileContext.progressQuiz
                                  .total)) *
                              100
                          ) + "%",
                  }}
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              ) : (
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    width: `${
                      !trainingCourseProfileContext.progress.total &&
                      !trainingCourseProfileContext.progressQuiz.total
                        ? 100
                        : Math.round(
                            ((trainingCourseProfileContext.progress.progress +
                              trainingCourseProfileContext.progressQuiz
                                .progress) /
                              (trainingCourseProfileContext.progress.total +
                                trainingCourseProfileContext.progressQuiz
                                  .total)) *
                              100
                          )
                    }%`,
                  }}
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              )}
            </div>
          </div>
        </div>
      )}
      <div
        className="col-12 col-lg-6 col-md-4"
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "space-around",
          gap: 10,
        }}
      >
        <button
          onClick={() => handleOpenSurvies()}
          style={{
            backgroundColor: "#006d77",
            margin: "8px 0px",
            borderRadius: 5,
            padding: "10px 20px 10px 20px",
            fontSize: 15,
            color: "white",
            fontWeight: "bold",
            borderWidth: 0,
          }}
        >
          <span>{t("trainer.course.surveyTitle")}</span>
        </button>
        {isCertificate ? (
          <button
            onClick={() => history.push(`/certificate/${id}`)}
            style={{
              backgroundColor: "#006d77",
              margin: "8px 0px",
              borderRadius: 5,
              padding: "10px 20px 10px 20px",
              fontSize: 15,
              color: "white",
              fontWeight: "bold",
              borderWidth: 0,
            }}
          >
            <span>{t("certificate.view_certificate")}</span>
          </button>
        ) : // <NavLink
        // to={{
        //     pathname: `/certificate/${id}`,
        // }}
        // style={{ border: "none", margin:"8px 8px", fontSize: "14px"}}
        // className='admin_button_green'> {t('certificate.view_certificate')} </NavLink>
        null}
        <IconsTooltip
          title={`${t("course_quiz_report")} PDF`}
          content={
            <button
              onClick={() => exportHandler("pdf")}
              className="tw-border-2 tw-border-red-500 tw-p-4 tw-text-white tw-rounded"
            >
              <PDFIcon className="tw-w-5 tw-h-5 tw-stroke-white" />
            </button>
          }
        />
        <IconsTooltip
          title={`${t("course_quiz_report")} Excel`}
          content={
            <button
              onClick={() => exportHandler("xlsx")}
              className="tw-border-2 tw-border-green-500 tw-p-4 tw-text-white tw-rounded"
            >
              <XLSIcon className="tw-w-5 tw-h-5 tw-stroke-white" />
            </button>
          }
        />
      </div>
    </div>
  );
}
