import { useEffect, useRef, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import HomeIcon from "../../../../../../assets/icons/home.svg";

import { ErrorMessage, Field, FieldArray, Formik } from "formik";
import Select from "react-select";
import toLocalTimezone, { toServerTime } from "../../../../../../Services/Timezone";
import { addVote, deleteVote, editVote, getVote } from "../../../../../../Services/api/votes/VotesProvider";
import { ReactComponent as CalendarIcon } from "../../../../../../assets/icons/calendar.svg";
import { ReactComponent as CloseIcon } from "../../../../../../assets/icons/crose.svg";
import DeleteIconModal from "../../../../../../assets/icons/delete.svg";
import { ReactComponent as PlusIcon } from "../../../../../../assets/icons/plus-square.svg";
import Breadcrumb from "../../../../../Shared/Components/Breadcrumb/Breadcrumb";
import SkeletonCard from "../../../../../Shared/Components/Spinner/SkeletonCard";

import SunEditor from "suneditor-react";
import i18n from "../../../../../../i18n/i18n";
import { hasExtension } from "../../../../../../utils";
import FilesDragAndDropInput from "../../../../../Shared/Components/FilesDragAndDropInput/FilesDragAndDropInput";
import SharedModal from "../../../../../Shared/Components/SharedModal/SharedModal";
import SkeletonCardOverlay from "../../../../../Shared/Components/Spinner/SkeletonCardOverlay";

export default function VoteForm({ role }) {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [errs, setErrors] = useState({});

  const [vote, setVote] = useState({});

  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);

  const history = useHistory();
  const { t } = useTranslation();

  const { id, courseId, discussionId } = useParams();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const formikRef = useRef();

  const [viewResults, setViewResults] = useState([
    {
      value: 1,
      label: i18n.language == "en" ? "View Result Direct" : "عرض النتائج مباشرة",
    },
    {
      value: 2,
      label: i18n.language == "en" ? "View Result After Vote" : "عرض النتائج بعد التصويت",
    },
    {
      value: 3,
      label: i18n.language == "en" ? "View Result Never" : "عدم عرض النتائج",
    },
  ]);

  useEffect(() => {
    if (id != null && id != undefined) {
      setIsLoading(true);
      getVote(id)
        .then((res) => {
          if (res.status && res.status == 200 && res.data.status) {
            const vote = res.data.data;
            setVote({
              ...vote,
              // users_answered_count: parseInt(quiz.users_answered_count),
            });
            setIsLoading(false);
          } else {
            throw res;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setIsLoading(false);
    }
  }, [id]);

  function getQuestionInitialValues(question = {}) {
    return {
      id: question?.id ?? "",
      answer: question?.answer ?? "",
      image: question.attachment ? question.attachment : "",
    };
  }

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleOpenDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const deleteVotes = () => {
    const myPromise = new Promise((resolve, reject) => {
      deleteVote(vote.id)
        .then(async (result) => {
          if (result.status) {
            toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("successful_deleting_vote")}</span>);
            resolve();
          }
          handleCloseDeleteModal();
          history.goBack();
        })
        .catch((err) => {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_deleting_vote")}</span>);

          reject();
        });
    });

    return myPromise;
  };

  function breadcrumbList() {
    let breadcrumbList = [];
    if (role === "admin") {
      breadcrumbList = [
        {
          id: t("admin.label.admin_label"),
          page: t("admin.label.admin_label"),
          pagePath: "/admin",
        },
      ];
    } else {
      breadcrumbList = [
        {
          id: "home",
          page: <img src={HomeIcon} alt="" />,
          pagePath: "/",
        },
      ];
    }
    breadcrumbList = [
      ...breadcrumbList,
      {
        id: t("sidebar.label.courses"),
        page: t("sidebar.label.courses"),
        pagePath: `/${role}/courses`,
      },
      {
        id: t("Courseoutline"),
        page: t("Courseoutline"),
        pagePath: `/${role}/course/${courseId}`,
      },
      // {
      //   id: t("discussions"),
      //   page: t("discussions"),
      //   pagePath: `/${role}/course/${courseId}/discussion-board`,
      // },
    ];
    if (id) {
      breadcrumbList.push({
        id: t("add_vote"),
        page: t("edit_vote"),
        active: true,
      });
    } else {
      breadcrumbList.push({
        id: t("add_vote"),
        page: t("add_vote"),
        active: true,
      });
    }
    return breadcrumbList;
  }

  return (
    <>
      <div className="container-fluid">
        {isUpdating ? <SkeletonCardOverlay skeletonWidth="100" /> : <div></div>}
        {isLoading || (id && !vote.id) ? (
          <div
            style={{
              height: "65vh",
              width: "100%",
            }}
          >
            <SkeletonCard />
          </div>
        ) : (
          <>
            {isDeleteModalOpen && (
              <SharedModal
                icon={DeleteIconModal}
                title={t("vote_delete_msg")}
                confirmMsg={t("confirm_vote_delete_msg")}
                confirmText={t("general.confirm")}
                cancelText={t("general.cancel")}
                onCancel={handleCloseDeleteModal}
                onConfirm={() => deleteVotes()}
                isLoading={false}
              />
            )}
            <div className="row">
              <div className="col-12 sm:tw-px-2 tw-py-8">
                {/*TODO*/}
                <Breadcrumb list={breadcrumbList()} />
              </div>
            </div>
            <div className={`tw-mb-4`}>
              <Formik
                ref={formikRef}
                initialValues={{
                  votable_id: discussionId,
                  question: vote.question ?? "",
                  image: vote.attachment ?? "",
                  view_result: vote.view_result ?? "",
                  start_date: vote.start_date ? toLocalTimezone(vote.start_date) : toLocalTimezone(new Date()),
                  end_date: vote.end_date ? toLocalTimezone(vote.end_date) : toLocalTimezone(new Date()),
                  answers: vote.answers ? vote.answers.map((item) => getQuestionInitialValues(item)) : [],
                }}
                onSubmit={async (values, { setErrors }) => {
                  // payload preperation section
                  setIsUpdating(true);
                  var data = {};
                  if (!vote.users_answered_count) {
                    data.question = values.question;
                    data.image = values.image?.url ? null : values.image;
                    data.view_result = values.view_result;

                    data.start_date = toServerTime(values.start_date);

                    data.answers = values.answers.map((ans) => ({
                      ...ans,
                      id: ans.id ? ans.id : null,
                      image: ans.image?.url ? null : ans.image,
                    }));
                  }

                  data.end_date = toServerTime(values.end_date);

                  try {
                    var response;
                    data.votable_id = discussionId;
                    if (!id) {
                      response = await addVote(data);
                      setIsUpdating(false);
                      if (response.status === 201 && response.data.status) {
                        setErrors({});
                        toast.success(
                          <span style={{ fontSize: 13, fontWeight: "bold" }}>
                            {t("trainer.quiz.added_successfully")}
                          </span>,
                          {
                            autoClose: 3000,
                          }
                        );
                      } else {
                        throw response;
                      }
                    } else {
                      response = await editVote(data, id);
                      setIsUpdating(false);
                      if (response.data.status) {
                        setErrors({});
                        toast.success(
                          <span style={{ fontSize: 13, fontWeight: "bold" }}>
                            {t("trainer.quiz.edited_successfully")}
                          </span>,
                          {
                            autoClose: 3000,
                          }
                        );
                      } else {
                        throw response;
                      }
                    }
                    if (history.length !== 1) history.goBack();
                    else history.push(`/${role}/course/${courseId}/discussion-board/${discussionId}`);
                  } catch (err) {
                    setIsUpdating(false);
                    if (err.data?.errors) {
                      function mapError(err) {
                        if (err[0] && typeof err[0] === "string") return err[0];
                        else {
                          Object.keys(err).forEach(function (key, index) {
                            err[key] = mapError(err[key]);
                          });
                          return err;
                        }
                      }
                      const errors = mapError(err.data.errors);
                      setErrors(errors);
                    }
                    toast.error(
                      <span style={{ fontSize: 13, fontWeight: "bold" }}>{err.data?.msg ?? "Failure in service"}</span>
                    );
                  }
                }}
                validate={(values) => {
                  setHasSubmitted(true);
                  const errors = {};
                  let checkDates = true;

                  if (!vote.users_answered_count) {
                    if (!values.start_date) {
                      checkDates = false;
                      errors.start_date = t("crud.errors.required");
                    } else if (!id && new Date(values.start_date) <= new Date()) {
                      errors.start_date = t("crud.errors.The_start_date_must_be_a_later_date");
                    }

                    if (!values.question) {
                      errors.question = t("crud.errors.required");
                    }

                    if (!values.view_result) {
                      errors.view_result = t("crud.errors.required");
                    }

                    if (values.image && !values.image?.id && values.image?.name) {
                      if (!hasExtension(values.image.name, [".png", ".jpg", ".jpeg", ".gif"])) {
                        values.image = `${t("Invalid image type, please select an image type")} (PNG, JPG, JPEG)`;
                      } else if (values.image.size > 5242880)
                        values.image = `${t("The image size must not exceed")} 5MG`;
                    }
                    if (values.answers.length && !values.monitored) {
                      let answersErrors = [];
                      values.answers.forEach((answer, index) => {
                        let quesErrors = {};
                        if (!answer.answer) {
                          quesErrors.answer = t("crud.errors.required");
                        }

                        if (answer.image && !answer.image?.id && answer.image?.name) {
                          if (!hasExtension(answer.image.name, [".png", ".jpg", ".jpeg", ".gif"])) {
                            answer.image = `${t("Invalid image type, please select an image type")} (PNG, JPG, JPEG)`;
                          } else if (answer.image.size > 5242880)
                            answer.image = `${t("The image size must not exceed")} 5MG`;
                        }
                        if (Object.keys(quesErrors).length) answersErrors[index] = quesErrors;
                      });
                      if (answersErrors.length) {
                        errors.answers = answersErrors;
                      }
                    }
                  }

                  if (!values.end_date) {
                    checkDates = false;
                    errors.end_date = t("crud.errors.required");
                  }

                  if (checkDates && new Date(values.start_date) >= new Date(values.end_date)) {
                    errors.end_date = t("crud.errors.start_date_is_smaller_than_or_equal_end_date");
                  }

                  if (!values.answers.length) {
                    errors.answers = t("crud.errors.answers_required");
                  }

                  return errors;
                }}
                validateOnChange={hasSubmitted}
              >
                {({
                  setFieldValue,
                  setValues,
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  resetForm,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <>
                      <div className="tw-bg-white tw-shadow tw-rounded tw-p-8 tw-space-y-8">
                        <div className="tw-flex tw-items-center tw-justify-between">
                          <div className="tw-text-teal-500 tw-text-lg sm:tw-text-2xl">
                            {id ? t("edit_vote") : t("add_vote")}
                          </div>
                        </div>
                        <div className="tw-space-y-4">
                          {/* question field */}
                          <div>
                            <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("survey.questions.title")}</div>
                            <SunEditor
                              placeholder={t("survey.questions.title")}
                              value={values.question}
                              defaultValue={values.question}
                              onChange={(e) => {
                                setFieldValue("question", e);
                              }}
                              setOptions={{
                                height: 150,
                                rtl: i18n.language === "ar" ? true : false,
                                buttonList: [
                                  [
                                    "bold",
                                    "underline",
                                    "italic",
                                    "strike",
                                    "align",
                                    "horizontalRule",
                                    "list",
                                    "lineHeight",
                                    "codeView",
                                  ],
                                ],
                              }}
                              disabled={vote.users_answered_count}
                              setDefaultStyle="font-family: 'Montserrat', sans-serif; font-size: 16px;"
                            />

                            <ErrorMessage
                              name={`question`}
                              component="div"
                              className="tw-text-xs tw-text-red-700 tw-h-4"
                            />
                          </div>

                          <FilesDragAndDropInput
                            id={"image"}
                            label={t("crud.list.image")}
                            placeholder={t("crud.list.image")}
                            buttonTitle={t("add_image")}
                            accept={".png,.jpg,.jpeg,.gif"}
                            name={"image"}
                            error={errors.image}
                            value={values.image}
                            disabled={vote.responses_count}
                            onChange={(files) => files[0] && setFieldValue("image", files[0], true)}
                          />

                          <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-4">
                            <div>
                              <label className="tw-block">
                                <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("start_date")}</div>

                                <div className="tw-relative">
                                  <DatePicker
                                    popperProps={{
                                      strategy: "fixed",
                                    }}
                                    disabled={vote.users_answered_count}
                                    selected={Date.parse(values.start_date)}
                                    defaultValue={Date.parse(values.start_date)}
                                    onChange={(date) => {
                                      setFieldValue("start_date", date);
                                      if (date > values.end_date) setFieldValue("end_date", "");
                                    }}
                                    showTimeSelect
                                    placeholderText={t("start_date")}
                                    className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                                    showTime={{ use12Hours: true }}
                                    dateFormat={"yyyy-MM-dd hh:mm:ss a"}
                                    minDate={new Date()}
                                    minTime={
                                      values.start_date &&
                                      new Date(new Date(values.start_date).toDateString()) >
                                        new Date(new Date().toDateString())
                                        ? undefined
                                        : Date.now()
                                    }
                                    maxTime={
                                      new Date(new Date(values.start_date).toDateString()) >
                                      new Date(new Date().toDateString())
                                        ? undefined
                                        : new Date().setHours(23, 59, 0, 0)
                                    }
                                  />
                                  <CalendarIcon className="tw-h-4 tw-w-4 tw-absolute tw-top-1/2 -tw-translate-y-1/2 tw-end-3" />
                                </div>
                              </label>

                              <ErrorMessage
                                name={`start_date`}
                                component="div"
                                className="tw-text-xs tw-text-red-700 tw-h-4"
                              />
                            </div>

                            <div>
                              <label className="tw-block">
                                <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("end_date")}</div>

                                <div className="tw-relative">
                                  <DatePicker
                                    popperProps={{
                                      strategy: "fixed",
                                    }}
                                    selected={Date.parse(values.end_date)}
                                    defaultValue={Date.parse(values.end_date)}
                                    onChange={(date) => setFieldValue("end_date", date)}
                                    showTimeSelect
                                    placeholderText={t("end_date")}
                                    className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                                    showTime={{ use12Hours: true }}
                                    dateFormat={"yyyy-MM-dd hh:mm:ss a"}
                                    minDate={values.start_date ? Date.parse(values.start_date) : new Date()}
                                    minTime={
                                      values.start_date
                                        ? values.end_date &&
                                          new Date(new Date(values.end_date).toDateString()) >
                                            new Date(new Date(values.start_date).toDateString())
                                          ? undefined
                                          : Date.parse(values.start_date)
                                        : values.end_date &&
                                          new Date(new Date(values.end_date).toDateString()) >
                                            new Date(new Date().toDateString())
                                        ? undefined
                                        : Date.now()
                                    }
                                    maxTime={
                                      (values.start_date &&
                                        values.end_date &&
                                        new Date(new Date(values.end_date).toDateString()) >
                                          new Date(new Date(values.start_date).toDateString())) ||
                                      (!values.start_date &&
                                        values.end_date &&
                                        new Date(new Date(values.end_date).toDateString()) >
                                          new Date(new Date().toDateString()))
                                        ? undefined
                                        : new Date().setHours(23, 59, 0, 0)
                                    }
                                  />
                                  <CalendarIcon className="tw-h-4 tw-w-4 tw-absolute tw-top-1/2 -tw-translate-y-1/2 tw-end-3" />
                                </div>
                              </label>

                              <ErrorMessage
                                name={`end_date`}
                                component="div"
                                className="tw-text-xs tw-text-red-700 tw-h-4"
                              />
                            </div>
                          </div>
                          <div>
                            <label className="tw-block">
                              <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                                {t("trainer.quiz.crud.show_mark_condition")}
                              </div>
                              <Select
                                id="view_result"
                                isClearable
                                isDisabled={vote.users_answered_count}
                                defaultValue={viewResults.find((item) => item.value == values.view_result)}
                                options={viewResults}
                                onChange={(item) => {
                                  if (item) {
                                    setFieldValue("view_result", item.value);
                                  }
                                }}
                                placeholder={t("show_grade_trianee")}
                              />
                            </label>

                            <ErrorMessage
                              name={`view_result`}
                              component="div"
                              className="tw-text-xs tw-text-red-700 tw-h-4"
                            />
                          </div>
                        </div>
                        <div>
                          <div className="tw-text-teal-600 tw-text-xl tw-mb-4">{t("answers")}</div>

                          <div>
                            <QuestionOptions
                              values={values}
                              errors={errors}
                              disabled={vote.users_answered_count}
                              setFieldValue={setFieldValue}
                              setValues={setValues}
                            />
                          </div>
                          {errors.answers?.length && typeof errors.answers != "string" ? (
                            <div className="tw-text-xs tw-text-red-700 tw-h-4">{t("not_all_questions_are_ready")}</div>
                          ) : (
                            <ErrorMessage
                              name="answers"
                              component="div"
                              className="tw-text-xs tw-text-red-700 tw-h-4"
                            />
                          )}
                        </div>

                        <div className="tw-flex tw-items-stretch tw-justify-end tw-space-s-4">
                          {vote?.id && (
                            <button
                              type="button"
                              disabled={isSubmitting}
                              onClick={() => handleOpenDeleteModal()}
                              className="tw-border-2 tw-border-red-600 tw-py-2 tw-px-16 tw-rounded tw-text-red-600 disabled:tw-border-red-600/50 disabled:tw-text-red-600/50"
                            >
                              {t("delete")}
                            </button>
                          )}
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="tw-bg-teal-700 tw-py-2 tw-px-16 tw-rounded tw-text-white disabled:tw-bg-teal-700/50"
                          >
                            {t("survey.buttons.save")}
                          </button>
                        </div>
                      </div>
                    </>
                  </form>
                )}
              </Formik>
            </div>
          </>
        )}
      </div>
    </>
  );
}

function QuestionOptions({ values, errors, setFieldValue, setValues, disabled = false }) {
  const { t } = useTranslation();

  useEffect(() => {
    if (!values.answers.length) {
      setFieldValue(`answers`, [
        { answer: "", image: "", id: "" },
        { answer: "", image: "", id: "" },
        { answer: "", image: "", id: "" },
      ]);
    }
  }, []);

  return (
    <div>
      <FieldArray name={`answers`}>
        {({ insert, remove, push }) => (
          <>
            <div className="tw-space-y-4">
              {values.answers?.length &&
                values.answers.map((option, index) => (
                  <div key={index} className="tw-flex tw-items-center tw-space-s-4">
                    <div className="tw-grow tw-space-y-4">
                      <div>
                        <Field
                          name={`answers.${index}.answer`}
                          disabled={disabled}
                          className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                          placeholder={t("add_title")}
                          type="text"
                        />
                        <ErrorMessage
                          name={`answers.${index}.answer`}
                          component="div"
                          className="tw-text-xs tw-text-red-700 tw-h-4"
                        />
                      </div>

                      <FilesDragAndDropInput
                        id={`answers.${index}.image`}
                        // label={t("crud.list.image")}
                        placeholder={t("crud.list.image")}
                        buttonTitle={t("add_image")}
                        accept={".png,.jpg,.jpeg,.gif"}
                        name={`answers.${index}.image`}
                        error={errors[`answers.${index}.image`]}
                        value={values.answers[index].image}
                        // disabled={vote.responses_count}
                        onChange={(files) => files[0] && setFieldValue(`answers.${index}.image`, files[0], true)}
                      />
                    </div>
                    {disabled ? (
                      <></>
                    ) : (
                      <button
                        type="button"
                        disabled={values.answers.length <= 1}
                        onClick={async () => {
                          try {
                            remove(index);
                          } catch (e) {
                            toast.error(
                              <span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_deleting")}</span>
                            );
                          }
                        }}
                      >
                        <CloseIcon className="tw-w-5 tw-h-5" />
                      </button>
                    )}
                  </div>
                ))}
            </div>
            {disabled ? (
              <></>
            ) : (
              <button type="button" onClick={() => push({ answer: "", image: "", id: "" })} className="tw-mt-3">
                <PlusIcon className="tw-h-6 tw-w-6 tw-stroke-[#046c77]" />
              </button>
            )}
          </>
        )}
      </FieldArray>
      {errors.answers && typeof errors.answers == "string" ? (
        <div className="tw-text-xs tw-text-red-700 tw-h-4">{errors.answers}</div>
      ) : (
        <></>
      )}
    </div>
  );
}
