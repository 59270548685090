import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { updateCourseStatus } from "../../../../Redux/Actions/specialties/specialtiesList.action";
import DeleteIcon from "../../../../assets/icons/delete.svg";
import EyeIcon from "../../../../assets/icons/eye.svg";
import editIcon from "../../../../assets/icons/pen.svg";
import { Lang } from "../../../../utils";
import { utcToLocal } from "../../../../utils/utcToLocal";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import SwitchToggle from "../../../Shared/Components/SwitchToggle/SwitchToggle";
import TableActionsColumn from "../../../Shared/Components/TableActionsColumn/TableActionsColumn";
import classes from "./specialtiesList.module.css";
const SpecialtiesListTable = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { specialtiesList, specialtiesListLoading } = useSelector(
    (state) => state.specialtiesListReducer
  );

  const updateSpecialtyStatusHandler = (id, status) => {
    dispatch(
      updateCourseStatus({
        id,
        status: status ? 0 : 1,
      })
    );
  };

  const specialtiesListActionsList = [
    {
      id: "view-details",
      icon: <img src={EyeIcon} alt="" />,
      action: (id, name) => {
        history.push({
          pathname: `/admin/category/${id}`,
          state: { name: name },
        });
      },
      tooltip: t("general.view_details"),
    },
    {
      id: "edit",
      icon: <img src={editIcon} alt="" />,
      action: (id) => {
        history.push(`/admin/category/edit/${id}`);
      },
      tooltip: t("general.edit"),
    },

    // {
    //   id: "delete",
    //   icon: <img src={DeleteIcon} alt="" />,
    //   action: (id) => {},
    //   tooltip: t("general.delete"),
    // },
  ];

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => <>{rowIndex + 1}</>,
    },
    {
      id: "name",
      name: t("specialties_list.name"),
      key: "name",
      cell: ({ rowData }) => (
        <div className={classes["specialties-list__table-name"]}>
          {i18n.language == Lang.AR
            ? rowData?.name_ar || "-"
            : rowData?.name_en}
        </div>
      ),
      sortable: false,
    },

    {
      id: "description",
      name: t("specialties_list.description"),
      key: "description",
      cell: ({ rowData }) => (
        <div
          className={classes["specialties-list__table-desc"]}
          dangerouslySetInnerHTML={{
            __html:
              i18n.language == Lang.AR
                ? rowData?.description_ar || "-"
                : rowData?.description_en,
          }}
        ></div>
      ),
      sortable: false,
      align: "start",
    },
    {
      id: "created_at",
      name: t("general.created_at"),
      key: "created_at",
      cell: ({ rowData }) => (
        <>
          {moment(rowData.created_at).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData.created_at)}
        </>
      ),
      sortable: false,
    },
    {
      id: "actions",
      name: t("general.actions"),
      key: "actions",
      cell: ({ rowData }) => (
        <TableActionsColumn
          actions={specialtiesListActionsList}
          // cantEdit={new Date(rowData.end_time) <= Date.now()}
          id={rowData.id}
          name={i18n.language == Lang.AR ? rowData?.name_ar : rowData?.name_en}
        />
      ),
      sortable: false,
    },

    {
      id: "status",
      name: t("general.status"),
      key: "status",
      cell: ({ rowData }) => (
        <SwitchToggle
          status={rowData.status}
          id={rowData.id}
          onStatusChange={() => {
            updateSpecialtyStatusHandler(rowData.id, rowData.status);
          }}
        />
      ),
      sortable: false,
    },
  ];

  return (
    <MainTable
      columns={columns}
      data={specialtiesList || []}
      isLoading={specialtiesListLoading}
    />
  );
};

export default SpecialtiesListTable;
