import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { NavLink, useLocation } from "react-router-dom";
import {
  getLessonPlans,
  setWatchingProgress,
} from "../../../../../Services/api/Trainee/TraineeProvider";
import SkeletonCard from "../../../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../../../Shared/Components/Spinner/SkeletonCardOverlay";
import CircularProgressBar from "../../TrainingCourseContent/CircularProgressBar";
import LessonActivityItem from "./LessonActivityItem";
import LessonContentItem from "./LessonContentItem";
import LessonDiscussionItem from "./LessonDiscussionItem";
import LessonQuizItem from "./LessonQuizItem";
import LessonSessionItem from "./LessonSessionItem";
import LessonSurveyItem from "./LessonSurveyItem";
import LessonExhibitionItem from "./LessonExhibitionItem";

export default function LessonContentView({}) {
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [plans, setPlans] = useState([]);
  const [lesson, setLesson] = useState({});
  const [isCourseClosed, setIsCourseClosed] = useState(true);
  const { courseId, chapterId, unitId, lessonId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const { index: pageIndex = 0 } = location.state;
  const { t } = useTranslation();

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    getLessonPlans(lessonId, { perPage: 100 }).then((res) => {
      if (res.status == 200 && res.data.status) {
        setPlans(res.data.plans);
        setLesson(res.data.lesson);
        setIsCourseClosed(
          res.data.lesson.course_status === "Closed" ||
            res.data.lesson.course_status === "مغلقة"
        );
        setIsLoading(false);
      }
    });
  }

  return (
    <>
      {isUpdating ? (
        <div
          style={{
            height: "60vh",
          }}
        >
          <SkeletonCardOverlay />
        </div>
      ) : (
        <></>
      )}

      <div className="tw-container tw-mx-auto tw-py-8">
        {isLoading ? (
          <div
            style={{
              height: "60vh",
            }}
          >
            <SkeletonCard />
          </div>
        ) : (
          <div className="tw-rounded-xl tw-bg-white">
            <div className="tw-space-y-4 tw-p-8">
              <div className="tw-flex tw-items-stretch tw-justify-between">
                <div className="tw-text-xl">{lesson.chapter?.name}</div>
                <button
                  type="button"
                  onClick={() => history.goBack()}
                  className="tw-flex tw-items-center tw-justify-center tw-px-8 tw-rounded-lg tw-bg-gray-200"
                >
                  <div className="tw-text-gray-500 tw-py-2">{t("back")}</div>
                </button>
              </div>
              <div className="tw-rounded-lg tw-border tw-border-gray-300 tw-p-3 tw-flex tw-flex-col tw-space-y-4 sm:tw-flex-row tw-items-center sm:tw-space-s-4 sm:tw-space-y-0">
                <img
                  src={lesson.course?.img}
                  alt={lesson.course?.name}
                  className="tw-rounded-lg tw-h-40"
                />
                <div className="tw-space-y-4">
                  <div className="tw-text-xl tw-font-bold">
                    {lesson.course?.name}
                  </div>
                  <div
                    className="tw-text-gray-400"
                    dangerouslySetInnerHTML={{
                      __html: lesson.course?.description,
                    }}
                  ></div>
                </div>
              </div>
              <div className="tw-rounded-lg tw-border tw-border-gray-300 tw-p-4 tw-flex tw-flex-col tw-space-y-4 sm:tw-flex-row sm:tw-space-y-0 tw-items-center tw-justify-between tw-space-s-4">
                <div className="tw-flex tw-items-center tw-space-s-6">
                  <div className="tw-bg-teal-600 tw-rounded-lg tw-text-2xl tw-text-white tw-h-12 tw-aspect-square tw-flex tw-items-center tw-justify-center tw-font-sans">
                    {pageIndex + 1}
                  </div>

                  <div className="tw-space-y-2">
                    <div className="tw-text-lg tw-font-bold">
                      {lesson.title}
                    </div>
                  </div>
                </div>
                <div className="tw-flex tw-items-center tw-space-s-4">
                  <CircularProgressBar
                    title={t("progress")}
                    percent={lesson.progress_percentage}
                    color="#24b3b9"
                  />
                  <CircularProgressBar
                    title={t("watchingProgress")}
                    percent={lesson.watch_percentage}
                    color="#f57a30"
                  />
                </div>
              </div>
              {plans?.length ? (
                plans.map((plan) => (
                  <LessonPlan
                    key={plan.id}
                    courseId={courseId}
                    chapterId={chapterId}
                    unitId={unitId}
                    lessonId={lessonId}
                    plan={plan}
                    isCourseClosed={isCourseClosed}
                    fetchData={fetchData}
                  />
                ))
              ) : (
                <></>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

function LessonPlan({
  courseId,
  chapterId,
  unitId,
  lessonId,
  plan,
  isCourseClosed,
  fetchData,
}) {
  const { t } = useTranslation();
  const [count, setCount] = useState(0);
  const [openId, setOpenId] = useState(null);

  const saveWatchingProgressAPI = async ({ watchable, watchable_type }) => {
    try {
      if (!watchable.include_view_per || watchable.is_watched) {
        return;
      }
      const payload = {
        course_id: courseId,
        lesson_id: lessonId,
        watchable_id: watchable.id,
        watchable_type,
      };
      setWatchingProgress(payload).then((res) => {
        fetchData();
      });
    } catch (err) {}
  };

  const lessonsTypes = [
    {
      id: 0,
      title: t("trainer.course.lesson.content"),
      type: "content",
      component: ({ id, planable }) => (
        <LessonContentItem
          courseId={courseId}
          chapterId={chapterId}
          unitId={unitId}
          content={planable}
          lessonId={lessonId}
          // onClose={() => setOpenId(null)}
          isCourseClosed={isCourseClosed}
          onOpen={() => {
            setOpenId(id);
            !isCourseClosed &&
              saveWatchingProgressAPI({
                watchable_type: "content",
                watchable: planable,
              });
          }}
          isOpen={openId === id}
        />
      ),
      hide: false,
    },
    {
      title: t("trainer.course.lesson.activities"),
      type: "activity",
      component: ({ id, planable }) => (
        <LessonActivityItem
          courseId={courseId}
          chapterId={chapterId}
          unitId={unitId}
          lessonId={lessonId}
          activity={planable}
          fetchData={fetchData}
          isCourseClosed={isCourseClosed}
          onOpen={() => {
            setOpenId(id);
            !isCourseClosed &&
              saveWatchingProgressAPI({
                watchable_type: "activity",
                watchable: planable,
              });
          }}
          isOpen={openId === id}
        />
      ),
      hide: false,
    },
    {
      title: t("trainer.course.lesson.sessions"),
      type: "meeting",
      component: ({ id, planable }) => (
        <LessonSessionItem
          courseId={courseId}
          chapterId={chapterId}
          unitId={unitId}
          session={planable}
          isCourseClosed={isCourseClosed}
          onOpen={() => {
            setOpenId(id);
            !isCourseClosed &&
              saveWatchingProgressAPI({
                watchable_type: "session",
                watchable: planable,
              });
          }}
          isOpen={openId === id}
        />
      ),
      hide: false,
    },
    {
      title: t("trainer.course.lesson.discussions"),
      type: "discussions",
      component: ({ id, planable }) => (
        <LessonDiscussionItem
          courseId={courseId}
          chapterId={chapterId}
          unitId={unitId}
          discussion={planable}
          isCourseClosed={isCourseClosed}
          onClose={() => setOpenId(null)}
          onOpen={() => {
            setOpenId(id);
            !isCourseClosed &&
              saveWatchingProgressAPI({
                watchable_type: "discussion",
                watchable: planable,
              });
          }}
          isOpen={openId === id}
        />
      ),
      hide: false,
    },
    {
      title: t("trainer.course.lesson.quiz"),
      type: "quiz",
      component: ({ id, planable }) => (
        <LessonQuizItem
          courseId={courseId}
          chapterId={chapterId}
          unitId={unitId}
          quiz={planable}
          isCourseClosed={isCourseClosed}
          onOpen={() => {
            setOpenId(id);
            !isCourseClosed &&
              saveWatchingProgressAPI({
                watchable_type: "quiz",
                watchable: planable,
              });
          }}
          isOpen={openId === id}
        />
      ),
      hide: false,
    },
    {
      title: t("trainer.course.lesson.survey"),
      type: "survey",
      component: ({ id, planable }) => (
        <LessonSurveyItem
          courseId={courseId}
          chapterId={chapterId}
          unitId={unitId}
          survey={planable}
          isCourseClosed={isCourseClosed}
          onOpen={() => {
            setOpenId(id);
            !isCourseClosed &&
              saveWatchingProgressAPI({
                watchable_type: "survey",
                watchable: planable,
              });
          }}
          isOpen={openId === id}
        />
      ),
      hide: false,
    },
    {
      title: t("admin.label.virtual_galleries"),
      type: "exhibition",
      component: ({ id, planable }) => (
        <LessonExhibitionItem
          courseId={courseId}
          chapterId={chapterId}
          unitId={unitId}
          exhibition={planable}
          onOpen={() => {
            setOpenId(id);
          }}
          isOpen={openId === id}
        />
      ),
      hide: isCourseClosed,
    },
  ];
  const type = lessonsTypes.find((item) => item.type == plan.type);

  return (
    <>
      {type && !type.hide && (
        <type.component key={plan.id} id={plan.id} planable={plan.planable} />
      )}
    </>
  );
}
