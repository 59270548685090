import React from "react";
import {SurveyProvider} from "../../../Services/api/survey/SurveyContext";
import Add from "./add/Add";
import SurveyManager from "./add/SurveyManager";


function Survey(props) {
  return (
    <SurveyProvider>
      <SurveyManager {...props}/>
    </SurveyProvider>
  );
}

export default Survey;
