import React from "react";
import { CoursePaymentProvider } from "../../../../../Services/api/courses/coursePaymentContext";
import TrainingCoursePaymentMethod from "../TrainingCoursePaymentMethod/TrainingCoursePaymentMethod";

function TrainingCoursePaymentModal(props) {
  require("./TrainingCoursePaymentModal.css");
  return (
    <>
      <CoursePaymentProvider>
        <TrainingCoursePaymentMethod
            paymentModalRef={props.paymentModalRef}
            courseDetailsContext={props.courseDetailsContext}
            giftDataPayload={props.giftDataPayload}
            price= {props.price}
            discountPrice={props.discountPrice}
        />
      </CoursePaymentProvider>
    </>
  );
}

export default TrainingCoursePaymentModal;
