import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { updateCourseStatus } from "../../../../Redux/Actions/departments/departmentList.action";
import DeleteIcon from "../../../../assets/icons/delete.svg";
import editIcon from "../../../../assets/icons/pen.svg";
import { Lang } from "../../../../utils";
import { utcToLocal } from "../../../../utils/utcToLocal";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import SwitchToggle from "../../../Shared/Components/SwitchToggle/SwitchToggle";
import TableActionsColumn from "../../../Shared/Components/TableActionsColumn/TableActionsColumn";
import classes from "./departmentsList.module.css";

const DepartmentsListTable = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { departmentList, departmentListLoading } = useSelector(
    (state) => state.departmentListReducer
  );

  const updateDepartmentStatusHandler = (id, status) => {
    dispatch(
      updateCourseStatus({
        id,
        status: status ? 0 : 1,
      })
    );
  };

  const departmentsDetailsActionsList = [
    {
      id: "edit",
      icon: <img src={editIcon} alt="" />,
      action: (id) => {
        history.push(`/admin/department/edit/${id}`);
      },
      tooltip: t("general.edit"),
    },
  ];

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => <>{rowIndex + 1}</>,
    },
    {
      id: "name",
      name: t("department_list.name"),
      key: "name",
      cell: ({ rowData }) => (
        <div className={classes["departments-list__table-name"]}>
          {rowData?.name || "-"}
        </div>
      ),
      sortable: false,
    },
    {
      id: "description",
      name: t("department_list.description"),
      key: "description",
      cell: ({ rowData }) => (
        <div
          className={classes["departments-list__table-desc"]}
          dangerouslySetInnerHTML={{
            __html: rowData?.description,
          }}
        ></div>
      ),
      sortable: false,
      align: "start",
    },
    {
      id: "specialty",
      name: t("department_list.specialty"),
      key: "specialty",
      cell: ({ rowData }) => (
        <>
          {i18n.language == Lang.AR
            ? rowData?.specialty?.name_ar || "-"
            : rowData?.specialty?.name_en || "-"}
        </>
      ),
      sortable: false,
    },
    {
      id: "created_at",
      name: t("general.created_at"),
      key: "created_at",
      cell: ({ rowData }) => (
        <>
          {moment(rowData.created_at).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData.created_at)}
        </>
      ),
      sortable: false,
    },
    {
      id: "actions",
      name: t("general.actions"),
      key: "actions",
      cell: ({ rowData }) => (
        <TableActionsColumn
          actions={departmentsDetailsActionsList}
          // cantEdit={new Date(rowData.end_time) <= Date.now()}
          id={rowData.id}
        />
      ),
      sortable: false,
    },

    {
      id: "status",
      name: t("general.status"),
      key: "status",
      cell: ({ rowData }) => (
        <SwitchToggle
          status={rowData.status}
          id={rowData.id}
          onStatusChange={() => {
            updateDepartmentStatusHandler(rowData.id, rowData.status);
          }}
        />
      ),
      sortable: false,
    },
  ];

  return (
    <MainTable
      columns={columns}
      data={departmentList || []}
      isLoading={departmentListLoading}
    />
  );
};

export default DepartmentsListTable;
