import env from "./Environment";
import connector from "./Handler";
import URLBuilder from "./UrlBuilder";

export function exportActivitiesMarkList(data, export_type, id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_lesson_activity_answers, { id: id }), {
          responseType: "blob",
          params: {
            ...data,
            export: export_type,
          },
        })
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              "Activity Participants" + "." + export_type
            ); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
