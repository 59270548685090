import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { exportProgramPaymentsReport } from "../../../Services/api/Payments";
import { getProgramPaymentsReport } from "../../../Services/api/toutorials/PaymentsProvaider";
import HomeIcon from "../../../assets/icons/home.svg";
import Breadcrumb from "../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../Shared/Components/MainBoxHead/MainBoxHead";
import SkeletonCardOverlay from "../../Shared/Components/Spinner/SkeletonCardOverlay";
import { filterActions } from "./ProgramPaymentsReportActions";
import ProgramPaymentsReportFilter from "./ProgramPaymentsReportFilter";
import ProgramPaymentsReportTable from "./ProgramPaymentsReportTable";
import classes from "./programPaymentsReport.module.css";

const ProgramPaymentsReport = ({ role }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { id, paymentId } = useParams();

  const [showFilter, setShowFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentFilter, setCurrentFilter] = useState({});
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [programPaymentsReport, setProgramPaymentsReport] = useState([]);
  const [programPaymentsReportMeta, setProgramPaymentsReportMeta] = useState({});
  const [programPaymentsStatus, setProgramPaymentsStatus] = useState([]);

  const [filterData, setFilterData] = useState({
    trainee_search: "",
    payment_status: "",
    is_paid: "",
    is_payment_due: "",
    is_payment_overdue: "",
  });

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "payments",
      page: t("payments.trainees_payments"),
      pagePath: `/${role}/program/${id}/trainees-payments`,
    },
    {
      id: "program_payments_report",
      page: t("payments.program_payments_report"),
      active: true,
    },
  ];

  const showFilterHandler = () => {
    setShowFilter((prev) => !prev);
  };
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
  };

  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };
  const resetFilterHandler = () => {
    setFilterData({
      trainee_search: "",
      payment_status: "",
      is_paid: "",
      is_payment_due: "",
      is_payment_overdue: "",
    });
    setIsResetApplied(true);
  };

  const getFilterData = () => {
    const filter = {};
    filterData.trainee_search && (filter.trainee_search = filterData.trainee_search);
    filterData.payment_status && (filter.payment_status = filterData.payment_status);
    filterData.is_paid && (filter.is_paid = filterData.is_paid);
    filterData.is_payment_due && (filter.is_payment_due = filterData.is_payment_due);
    filterData.is_payment_overdue && (filter.is_payment_overdue = filterData.is_payment_overdue);

    return filter;
  };

  const fetchAllProgramPaymentsReport = () => {
    setIsLoading(true);
    const filter = getFilterData();
    setCurrentFilter(filter);

    getProgramPaymentsReport({
      page: currentPage,
      perPage: 10,
      filter,
      id,
      ...(paymentId && { paymentId }),
    })
      .then((res) => {
        setProgramPaymentsReport(res.data.response.payments);
        setProgramPaymentsReportMeta(res.data.response.meta);
        setProgramPaymentsStatus(res.data.response.payment_status);
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.msg}</span>);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchAllProgramPaymentsReport();
    if (isResetApplied) {
      setIsResetApplied(false);
    }
  }, [currentPage, isResetApplied]);

  const exportHandler = async (type) => {
    setIsExportLoading(true);
    try {
      await exportProgramPaymentsReport({
        type,
        filter: currentFilter,
        id,
        paymentId,
      });
      setIsExportLoading(false);
    } catch (err) {
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("failed_fetching")}</span>);
      setIsExportLoading(false);
    }
  };

  return (
    <div className={classes["departments-trainees"]}>
      <div className="container-fluid">
        {isExportLoading && <SkeletonCardOverlay skeletonWidth="100" />}
        <div className={classes["departments-trainees__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <MainBox>
                <MainBoxHead
                  title={t("payments.program_payments_report")}
                  actions={filterActions({
                    showFilter: showFilterHandler,
                    exportPdf: () => {
                      exportHandler("pdf");
                    },
                    exportExcel: () => {
                      exportHandler("xlsx");
                    },
                  })}
                />
                {showFilter && (
                  <ProgramPaymentsReportFilter
                    filterData={filterData}
                    changeHandler={changeHandler}
                    resetHandler={resetHandler}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    filterHandler={fetchAllProgramPaymentsReport}
                    resetFilterHandler={resetFilterHandler}
                    programPaymentsStatus={programPaymentsStatus}
                  />
                )}

                <ProgramPaymentsReportTable programPaymentsReport={programPaymentsReport} loading={isLoading} />
                <Pagination
                  count={programPaymentsReportMeta.totalPages || 0}
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  className="main-pagination"
                  page={currentPage}
                  onChange={(e, page) => {
                    setCurrentPage(page);
                  }}
                />
              </MainBox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramPaymentsReport;
