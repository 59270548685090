import moment from "moment";
import toLocalTimezone from "../Services/Timezone";

function getServerTimezone() {
  return process.env.REACT_APP_BACKEND_TIMEZONE || "UTC";
}

export const utcToLocal = (date) => {
  const serverTimezone = getServerTimezone();
  return moment.tz(date, serverTimezone).local().format("hh:mm A");
};
export const utcToLocalFullFormat = (date) => {
  const serverTimezone = getServerTimezone();
  return moment.tz(date, serverTimezone).local().format("YYYY-MM-DD hh:mm A");
};

export const getLocaleTime = (date) => {
  // const offset = new Date().getTimezoneOffset();
  // const localeTime = date.getTime() - offset * 60 * 1000;
  // return new Date(localeTime);

  return toLocalTimezone(moment(date).format("YYYY-MM-DD HH:mm:ss"));
};
