import { toast } from "react-toastify";
import env from "../../../Services/api/Environment";
import connector from "../../../Services/api/Handler";
import { default as URLBuilder } from "../../../Services/api/UrlBuilder";
import { certificatesTemplateConstants } from "../../Constants/certificates/certificates-template.constants";

// GET CERTIFICATES TEMPLATES LIST
export const fetchCertificatesTemplatesList = ({ page, perPage, filter }) => {
  return async (dispatch) => {
    dispatch({
      type: certificatesTemplateConstants.GET_CERTIFICATES_TEMPLATES_LIST_REQUEST,
    });

    await connector
      .get(env.certificate_templates, {
        params: {
          page,
          perPage,
          ...filter,
        },
      })
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: certificatesTemplateConstants.GET_CERTIFICATES_TEMPLATES_LIST_SUCCESS,
            payload: {
              list: response?.data?.data?.templates,
              meta: response?.data?.data?.meta,
              selectedCertificate: response?.data?.data?.selected_template,
            },
          });
        } else {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{response?.data?.msg}</span>);
          dispatch({
            type: certificatesTemplateConstants.GET_CERTIFICATES_TEMPLATES_LIST_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err?.response?.data?.message}</span>);
        dispatch({
          type: certificatesTemplateConstants.GET_CERTIFICATES_TEMPLATES_LIST_REJECTED,
          payload: err,
        });
      });
  };
};

// UPDATE CERTIFICATES TEMPLATES STATUS
export const updateCertificatesTemplateStatus = ({ id }) => {
  return async (dispatch) => {
    dispatch({
      type: certificatesTemplateConstants.UPDATE_CERTIFICATES_TEMPLATES_STATUS_REQUEST,
    });

    await connector
      .post(
        URLBuilder(env.update_certificate_templates_status, {
          id,
        })
      )
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: certificatesTemplateConstants.UPDATE_CERTIFICATES_TEMPLATES_STATUS_SUCCESS,
            payload: {
              id,
            },
          });
        } else {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{response?.msg}</span>);
          dispatch({
            type: certificatesTemplateConstants.UPDATE_CERTIFICATES_TEMPLATES_STATUS_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err?.response?.data?.msg}</span>);
        dispatch({
          type: certificatesTemplateConstants.UPDATE_CERTIFICATES_TEMPLATES_STATUS_REJECTED,
          payload: err,
        });
      });
  };
};

// Delete Certificate Template
export const deleteCertificateTemplate = (id) => {
  return async (dispatch) => {
    dispatch({
      type: certificatesTemplateConstants.DELETE_CERTIFICATES_TEMPLATE_REQUEST,
    });
    await connector
      .delete(URLBuilder(env.certificate_templates_id, { id: id }))
      .then((response) => {
        if (response.status && response.status === 200 && response.data.status) {
          dispatch({
            type: certificatesTemplateConstants.DELETE_CERTIFICATES_TEMPLATE_SUCCESS,
            payload: { id },
          });
          toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{response.data.msg}</span>);
        } else {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{response?.data?.msg}</span>);
          dispatch({
            type: certificatesTemplateConstants.DELETE_CERTIFICATES_TEMPLATE_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })

      .catch((err) => {
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err?.response.data?.msg}</span>);
        dispatch({
          type: certificatesTemplateConstants.DELETE_CERTIFICATES_TEMPLATE_REJECTED,
          payload: err.response.data.msg,
        });
      });
  };
};

// ASSIGN CERTIFICATE Template
export const assignCertificateTemplate = ({ id, certificateId }) => {
  return async (dispatch) => {
    dispatch({
      type: certificatesTemplateConstants.ASSIGN_CERTIFICATES_TEMPLATE_REQUEST,
    });

    await connector
      .post(env.assign_certificate, {
        id,
        certificate_template_id: certificateId,
      })

      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: certificatesTemplateConstants.ASSIGN_CERTIFICATES_TEMPLATE_SUCCESS,
            payload: {
              id: certificateId,
            },
          });
        } else {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{response?.data?.msg}</span>);
          dispatch({
            type: certificatesTemplateConstants.ASSIGN_CERTIFICATES_TEMPLATE_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err?.response?.data?.message}</span>);
        dispatch({
          type: certificatesTemplateConstants.ASSIGN_CERTIFICATES_TEMPLATE_REJECTED,
          payload: err,
        });
      });
  };
};

// UNASSIGN CERTIFICATE Template
export const unassignCertificateTemplate = ({ id, certificateId }) => {
  return async (dispatch) => {
    dispatch({
      type: certificatesTemplateConstants.UNASSIGN_CERTIFICATES_TEMPLATE_REQUEST,
    });

    await connector
      .post(env.unassign_certificate, {
        id,
      })

      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: certificatesTemplateConstants.UNASSIGN_CERTIFICATES_TEMPLATE_SUCCESS,
            payload: {
              id: certificateId,
            },
          });
        } else {
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{response?.data?.msg}</span>);
          dispatch({
            type: certificatesTemplateConstants.UNASSIGN_CERTIFICATES_TEMPLATE_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        
        toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err?.response?.data?.message}</span>);
        dispatch({
          type: certificatesTemplateConstants.UNASSIGN_CERTIFICATES_TEMPLATE_REJECTED,
          payload: err,
        });
      });
  };
};
