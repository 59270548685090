import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import DevelopmentIcon from "../../../../../assets/icons/userCardIcon2.svg";
import UsersIcon from "../../../../../assets/icons/userCardIcon1.svg";
import StarsRating from "../../../../Shared/Components/StarsRating/StarsRating";
import Title from "../../../../Shared/Components/Title/Title";
import classes from "./userCard.module.css";
import CustomButton from "../../../../Shared/Components/CustomButton/CustomButton";
import ArrowOrange from "../../../../../assets/icons/ArrowOrange.svg";
import { useHistory } from "react-router-dom";
import {
  motion,
  useInView,
  useAnimation,
} from "framer-motion/dist/framer-motion";
const UserCard = ({
  img,
  name,
  rate,
  postionTitle,
  description,
  countCourses,
  countUsers,
  trainer,
  delay,
  loading,
  index,
  totalCards,
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [isHovering, setIsHovering] = useState(false);
  function handleMouseOver() {
    setIsHovering(true);
  }
  function handleMouseOut() {
    setIsHovering(false);
  }

  const ref = useRef(null);
  const isInView = useInView(ref);
  const mainControls = useAnimation();

  useEffect(() => {
    // console.log(isInView);
    if (isInView && loading) {
      mainControls.start("visible");
      // setisvisible(true);
    }
  }, [isInView, loading]);
  return (
    <motion.div
      ref={ref}
      variants={{
        hidden: { opacity: 0, y: 100 },
        visible: { opacity: 1, y: 0 },
      }}
      initial="hidden"
      animate={mainControls}
      transition={{
        duration: 0.6,
        delay:
          i18n.language === "ar"
            ? 0.3 * ((totalCards - index - 1) % 4)
            : 0.3 * (index % 4),
        ease: "easeIn",
      }}
      className={`${classes["user-card"]} ${isHovering && classes.hovering}`}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <div className={classes["card-content"]}>
        <div className={classes.content}>
          <div className={classes.imgCont}>
            <img src={img} alt={name} className={classes.img} />
          </div>
          <div className={classes.cardContantNew}>
            <div className={classes["flip-star"]}>
              <StarsRating
                rating={rate || 0}
                cutStyle={classes["star-font-size"]}
              />
            </div>
            <div className={classes["name"]}>
              <Title
                title={name?.length > 21 ? `${name?.slice(0, 21)} ...` : name}
                fontSize={18}
                titleColor={"#012327"}
              />
            </div>

            <div className={classes.num_courses}>
              <div className={classes.numContainer}>
                {`${
                  i18n.language == "ar"
                    ? trainer?.job_title_ar?.slice(0, 19)
                    : trainer?.job_title_en?.slice(0, 19)
                }`}
              </div>
              <span>|</span>
              <div className={classes.numContainer}>
                <img src={UsersIcon} alt="" style={{ width: 20, height: 20 }} />
                {trainer.countCourses}
              </div>
              <span>|</span>
              <div className={classes.numContainer}>
                <img
                  src={DevelopmentIcon}
                  alt=""
                  style={{ width: 20, height: 20 }}
                />
                {trainer.countUsers}
              </div>
            </div>
            <div className={classes.sapretor}></div>
            <div className={classes.btnCont}>
              <CustomButton
                value={t("see__")}
                icon={ArrowOrange}
                iconClass={classes.iconClass}
                colors="#133535"
                classes={classes.aboutButton}
                action={() =>
                  history.push(`/trainers/trainer-details/${trainer.id}`)
                }
              />
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default UserCard;
