import { ErrorMessage, Formik } from "formik";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import "suneditor/dist/css/suneditor.min.css";
import SkeletonCardOverlay from "../../Modules/Shared/Components/Spinner/SkeletonCardOverlay";
import { LessonContentContext } from "../../Providers/LessonContentProvider";
import DeleteIcon from "../../assets/icons/delete.svg";
import AsyncSelect from "react-select/async";
import {
  addExhibitionPlan,
  getExhibitionList,
} from "../../Services/api/Exhibition/ExhibitionProvider";
import { useHistory } from "react-router-dom";

export default function AddExhibitionPlanToLessonModal(props) {
  require("./AddActivityToLessonModal.css");
  const lessonContentContext = useContext(LessonContentContext);
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isLocalSubmitting, setIsLocalSubmitting] = useState(false);

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      getExhibitionList({ name: inputValue, except_lesson_id: id, active: true }).then(
        (res) => {
          resolve(res.data.data.exhibitions.data);
        }
      );
    });

  return (
    <div className="background_black_add_activity">
      {isLocalSubmitting ? (
        <SkeletonCardOverlay />
      ) : (
        <div
          className="add_activity_modal"
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div className="add_activity_modal_header">
            <h2 className="add_activity_modal_title">{props.title}</h2>
            <div
              className="add_activity_modal_close"
              onClick={props.dismissModal}
            >
              <img src={DeleteIcon} alt="close" />
            </div>
          </div>
          <Formik
            initialValues={{
              exhibition_id: null,
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              setIsLocalSubmitting(true);

              try {
                await addExhibitionPlan(
                  lessonContentContext.lessonInfo.id,
                  values
                );
                await lessonContentContext.fetchLessonContent(id);
                props.dismissModal();
              } catch (error) {
                setIsLocalSubmitting(false);
                setSubmitting(false);
                return;
              }
            }}
            validate={(values) => {
              setHasSubmitted(true);
              const errors = {};
              if (!values.exhibition_id) {
                errors.exhibition_id = t("crud.errors.required");
              }
              return errors;
            }}
            validateOnChange={hasSubmitted}
          >
            {({
              setFieldValue,
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="tw-flex tw-flex-col tw-justify-between tw-space-y-4 tw-min-h-[40vh]">
                  <div>
                    <div className="">
                      <label className="tw-block">
                        <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                          {t("crud.placeholders.select.exhibition")}
                        </div>
                        <AsyncSelect
                          placeholder={t("crud.placeholders.select.exhibition")}
                          cacheOptions
                          defaultOptions
                          loadOptions={promiseOptions}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          onChange={(item) => {
                            setFieldValue("exhibition_id", item.id);
                          }}
                        />
                      </label>

                      <ErrorMessage
                        name={`exhibition_id`}
                        component="div"
                        className="tw-text-xs tw-text-red-700 tw-h-4"
                      />
                    </div>
                  </div>
                  <div className="tw-flex tw-flex-col sm:tw-flex-row sm:tw-items-stretch sm:tw-justify-end tw-space-y-4 sm:tw-space-s-4 sm:tw-space-y-0">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="tw-bg-teal-700 tw-py-2 tw-px-16 tw-rounded tw-text-white disabled:tw-bg-teal-700/50"
                    >
                      {t("survey.buttons.save")}
                    </button>
                    {/* {props.role == "admin" ? (
                      <button
                        type="button"
                        onClick={() => {
                          if (lessonContentContext.lessonInfo.id)
                            history.push(
                              `/${props.role}/course/${lessonContentContext.lessonInfo.course.id}/lesson/${lessonContentContext.lessonInfo.id}/exhibition/add`
                            );
                        }}
                        className="tw-border-2 tw-border-teal-700 tw-py-2 tw-px-16 tw-rounded tw-text-teal-700 disabled:tw-border-teal-700/50 disabled:tw-bg-gray-100"
                      >
                        {t("Exhibition.add_exhibition")}
                      </button>
                    ) : (
                      <></>
                    )} */}
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
}
