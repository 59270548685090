import { makeStyles } from "@material-ui/core/styles";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import DoorBackOutlinedIcon from "@mui/icons-material/DoorBackOutlined";
import { Button, Popover, Rating } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import SurveysModal from "../../Modals/surveyModal/SurveysModal";
import { RateExhibition, getExhibitionById } from "../../Redux/Actions/exhibition/exhibition.action";
import { getSurveysForExhibition, getSurveysPublic } from "../../Services/api/survey/SurveyProvider";
import ExitIcon from "../../assets/icons/exit.svg";
import warning from "../../assets/icons/warning-green.svg";
import webinarIcon from "../../assets/icons/webinar.png";
import ballotCheck from "../../assets/image/ballot-check@2x.png";
import { Lang } from "../../utils";
import { BasicModal } from "../Shared/Components/Modal/BasicModal";
import SkeletonCard from "../Shared/Components/Spinner/SkeletonCard";
import WarningModal from "../Shared/Components/WarningModal/WarningModal";

import { refundOrder } from "../../Services/api/Financial/FinancialProvider";
import ModelWithInput from "../Shared/Components/ModelWithInput/ModelWithInput";
import SkeletonCardOverlay from "../Shared/Components/Spinner/SkeletonCardOverlay";
import "./unityIframe.css";

const useStyles = makeStyles((theme) => ({
  paper: {
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "2rem",
    width: 350,
    padding: "15px 15px",
  },
  customRating: {
    "& .MuiRating-icon": {
      width: "30px",
      height: "30px",
      fontSize: "30px", // Adjust font size if using Font Icons
    },
  },
  rateStyleArabic: {
    transform: "rotate(180deg) !important",
  },
  ratingError: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },

  ratingErrorSpan: {
    color: "red",
    fontSize: "12px",
  },
}));

export default function UnityIframe() {
  require("./exhibition.css");
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [ratingValue, setRatingValue] = useState(null);
  const [ratingData, setRatingData] = useState({});
  const [openSurvey, setOpenSurvey] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [allowToEnter, setAllowToEnter] = useState(false);
  const { exhibition, refundSettings, exhibitionLoading, rateExhibitionError } = useSelector(
    (state) => state.exhibitionReducer
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [survies, setSurvies] = useState([]);
  const history = useHistory();
  const [showIframeMobile, setShowIframeMobile] = useState(false);
  const [ratingError, setRatingError] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const surveypopupRef = useRef();
  const loninpopupRef = useRef();

  const is_logged_in = localStorage.getItem("token") === null ? false : true;
  const roles = localStorage.getItem("roles") === null ? [] : JSON.parse(localStorage.getItem("roles"));
  const guest_token = localStorage.getItem("guest_token") === null ? false : true;

  const canEnterExhibition = () => {
    const { mode, sponsor_status, user_status, payment_status } = exhibition;

    if (guest_token && mode === "Free") return true;
    if (is_logged_in) {
      if (
        mode === "Free" ||
        (mode === "Paid" &&
          (roles.includes("admin") ||
            (roles.includes("sponser") && sponsor_status === 2 && payment_status === 2) ||
            user_status === 2))
      )
        return true;
    }

    return false;
  };

  useEffect(() => {
    if (Object.keys(exhibition).length > 0 && !exhibitionLoading && !loading) {
      const canEnter = canEnterExhibition();
      if (canEnter) {
        setAllowToEnter(true);
      } else {
        history.push("/exhibition");
      }
    }
  }, [exhibition]);

  useEffect(() => {
    if (id) {
      dispatch(getExhibitionById(id));
      SetDomain();
      setLoading(false);
    }
  }, [id]);

  const SetDomain = () => {
    const myDomain = {
      domain: process.env.REACT_APP_EXHIBITION_BACKEND_URL || "https://tebxback.iau.edu.sa/",
    };
    localStorage.setItem("app_config", JSON.stringify(myDomain));
  };

  useEffect(() => {
    if (rateExhibitionError === true) {
      setRatingValue(0);
    }
  }, [rateExhibitionError, ratingValue]);

  /**
   * @TODO: formate date
   * @param dateString: comes from the api response
   * @return {string}
   * @Author: Hamdi Z.
   */
  const DateFormatter = (dateString) => {
    // Create a Date object from the input string
    const date = new Date(dateString);

    // Get the month and date
    const month = date.getMonth() + 1; // Months are zero-based in JavaScript
    const day = date.getDate();

    // Format month and day to have leading zeros if needed
    const formattedMonth = month < 10 ? "0" + month : month;
    const formattedDay = day < 10 ? "0" + day : day;

    // Combine the formatted month and day
    return `${formattedMonth}/${formattedDay}`;
  };

  const popoverHandleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchSurveys = () => {
    setSurvies([]);
    (is_logged_in ? getSurveysForExhibition(id) : getSurveysPublic({ exhibition_id: id }))
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setSurvies(res.data.surveys);
        } else {
          throw res;
        }
      })
      .catch((err) => {});
  };

  const openLoginModal = () => {
    setOpenLogin(false);
    loninpopupRef.current.dismissModal();
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? "simple-popover" : undefined;

  window.onpopstate = function (event) {
    localStorage.removeItem("guest_token");
    localStorage.removeItem("guest_email");
  };

  const handleOpenRefundModal = (id = 0) => {
    setShowRefundModal(true);
  };

  const submitRefund = async (reason) => {
    try {
      setIsUpdating(true);
      const dataToBeUploaded = {
        reason,
        payment_method: "Wallet",
      };
      let response = await refundOrder(exhibition.id, dataToBeUploaded, "exhibition-visitor");
      if (response.status === 201 && response.data.status) {
        toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{response.data.msg}</span>);
        setIsUpdating(false);
      } else {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {response.data.msg ? response.data.msg : "Failure in service"}
          </span>
        );
        setIsUpdating(false);
      }
    } catch (err) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>{err.data.msg ? err.data.msg : "Failure in service"}</span>
      );
      setIsUpdating(false);
    }
    setShowRefundModal(false);
  };

  return (
    <div>
      {isUpdating && <SkeletonCardOverlay />}
      {!is_logged_in && 0 ? (
        <div className="unity-booth-survey-for-guest">
          <Button
            variant="contained"
            className="unity-booth-survey-btn"
            onClick={() => {
              surveypopupRef.current.showModal();
              fetchSurveys();
            }}
            style={{
              width: 260,
              height: 75,
              color: "#ffff",
              backgroundColor: "#046C77",
              fontFamily: "inherit",
            }}
          >
            <div className="unity-booth-survey-btn-items-collection" style={{ display: "flex" }}>
              <img className="unity-booth-survey-ballot-check" src={ballotCheck} alt="ballotCheck" />
              <span className="unity-booth-survey-txt">{t("Exhibition.survey")}</span>
            </div>
          </Button>
        </div>
      ) : (
        <div>
          {loading || exhibitionLoading || !allowToEnter ? (
            <div
              style={{
                height: "65vh",
                width: "100%",
              }}
            >
              <SkeletonCard />
            </div>
          ) : (
            <>
              <div className="container-fluid profile_wrapper" style={{ paddingTop: "40px" }}>
                <div className="admin_label">
                  <p style={{ padding: "0 5px" }}>/</p>
                  <NavLink to="/">{t("Home")}</NavLink>
                  <p style={{ padding: "0 5px" }}>/</p>
                  <NavLink to="/Exhibition">{t("Exhibition.Exhibition")}</NavLink>
                  <p style={{ padding: "0 5px" }}>/</p>
                  {exhibition?.name}
                </div>
              </div>
              <div
                className="container-fluid container-wrapper"
                style={{ paddingTop: "40px", paddingBottom: "40px", marginBottom: "40px" }}
              >
                <div className="unity-booth-info">
                  <div className="unity-booth-info-public">
                    <img src={exhibition?.logo} className="unity-booth-info-public-booth-img" alt="booth-logo" />
                    <div className="unity-booth-info-public-data">
                      <h3 className="unity-booth-info-public-data-title">{exhibition?.name}</h3>
                      <div className="unity-booth-info-public-data-numbers">
                        <CalendarTodayOutlinedIcon style={{ color: "#046C77" }} />
                        <span className="unity-booth-info-public-data-numbers-date">
                          {DateFormatter(exhibition?.start_time)}
                        </span>
                        <span className="unity-booth-info-public-data-numbers-date">{t("Exhibition.to")}</span>
                        <span className="unity-booth-info-public-data-numbers-date">
                          {DateFormatter(exhibition?.end_time)}
                        </span>
                        <DoorBackOutlinedIcon style={{ color: "#046C77" }} />
                        <span className="unity-booth-info-public-data-numbers-date">
                          {exhibition?.booths_no} {t("Exhibition.booths")}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="unity-booth-rating">
                    <h3 className="unity-booth-rating-typography">
                      {t("Exhibition.exhibition_rate")} ({exhibition?.ratings_count})
                    </h3>
                    <div className="unity-rating-container">
                      <Rating
                        className={`${i18n.language === Lang.AR ? classes.rateStyleArabic : ""} ${
                          classes.customRating
                        }`}
                        name="read-only"
                        value={exhibition?.rate_avg}
                        readOnly
                        size="large"
                        style={{ color: "#046C77" }}
                      />
                      <button
                        type={"button"}
                        className="rating-now-bts"
                        aria-describedby={popoverId}
                        onClick={popoverHandleClick}
                      >
                        {t("Exhibition.rate_now")}
                      </button>
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        PaperProps={{
                          className: classes.paper,
                        }}
                      >
                        <div>
                          <Rating
                            className={`${i18n.language === Lang.AR ? classes.rateStyleArabic : ""} ${
                              classes.customRating
                            }`}
                            name={"stars"}
                            value={ratingValue}
                            onChange={(event, newValue) => {
                              setRatingValue(newValue);
                              setRatingData({
                                ...ratingData,
                                rateable_id: id,
                                stars: newValue,
                                guest_email: localStorage.getItem("guest_email"),
                              });
                              setRatingError(false);
                            }}
                            size="large"
                            style={{ color: "#046C77" }}
                          />
                          <div className={classes.ratingError}>
                            {ratingError && (
                              <span className={classes.ratingErrorSpan}>{t("crud.errors.required")}</span>
                            )}
                          </div>
                        </div>
                        <div className="unity-booth-rating-comment">
                          <textarea
                            className="unity-booth-rating-comment-textarea"
                            placeholder={t("crud.placeholders.comment")}
                            onChange={(e) => {
                              setRatingData({
                                ...ratingData,
                                description: e.target.value,
                              });
                            }}
                            rows="4"
                          ></textarea>
                        </div>
                        <div className="unity-booth-rating-comment-btn-container">
                          <button
                            className="unity-booth-rating-comment-btn"
                            onClick={() => {
                              if (ratingValue === null || ratingValue === 0) {
                                setRatingError(true);
                                return;
                              }
                              dispatch(RateExhibition(ratingData));
                              handleClose();
                            }}
                            style={{ color: "#ffff", backgroundColor: "#046C77" }}
                          >
                            {t("submit")}
                          </button>
                        </div>
                      </Popover>
                    </div>
                  </div>
                </div>
                <div
                  className="unity-booth-survey"
                  style={{
                    marginBottom: exhibition?.webinars.length > 0 ? "0" : "2rem",
                    justifyContent: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <button
                    className="unity-booth-back-btn"
                    type="button"
                    style={{
                      height: 50,
                      padding: "0 10px",
                      borderRadius: "6px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "2px solid #24B3B9",
                    }}
                    onClick={() => {
                      localStorage.removeItem("guest_token");
                      localStorage.removeItem("guest_email");
                      history.goBack();
                    }}
                  >
                    <img src={ExitIcon} alt="exit" style={{ width: "32px", height: "32px" }} />
                  </button>
                  {refundSettings?.status && refundSettings?.is_started && exhibition?.user_status === 2 ? (
                    <button
                      className="unity-booth-refund-btn"
                      type="button"
                      style={{
                        width: 200,
                        height: 50,
                        color: "#ffff",
                        backgroundColor: "#046C77",
                        fontFamily: "inherit",
                        borderRadius: "15px",
                      }}
                      onClick={handleOpenRefundModal}
                    >
                      {t("general.refund_request")}
                    </button>
                  ) : null}
                  <Button
                    variant="contained"
                    className="unity-booth-survey-btn"
                    onClick={() => {
                      surveypopupRef.current.showModal();
                      setOpenSurvey(true);
                      fetchSurveys();
                    }}
                    style={{
                      width: 200,
                      height: 50,
                      color: "#ffff",
                      backgroundColor: "#046C77",
                      fontFamily: "inherit",
                      borderRadius: "15px",
                    }}
                  >
                    <div className="unity-booth-survey-btn-items-collection" style={{ display: "flex" }}>
                      <img className="unity-booth-survey-ballot-check" src={ballotCheck} alt="ballotCheck" />
                      <span className="unity-booth-survey-txt">{t("Exhibition.survey")}</span>
                    </div>
                  </Button>
                  <Button
                    variant="contained"
                    className="unity-booth-tickets-btn"
                    onClick={() => {
                      if (!is_logged_in) {
                        setOpenLogin(true);
                        loninpopupRef.current.showModal();
                      } else {
                        history.push(`/tickets`);
                      }
                    }}
                    style={{
                      width: 200,
                      height: 50,
                      color: "#ffff",
                      backgroundColor: "#046C77",
                      fontFamily: "inherit",
                      borderRadius: "15px",
                    }}
                  >
                    <div className="unity-booth-survey-btn-items-collection" style={{ display: "flex" }}>
                      <span className="unity-booth-survey-txt">{t("Exhibition.tickets_label")}</span>
                    </div>
                  </Button>
                  <Button
                    variant="contained"
                    className="unity-booth-move-btn unity-booth-move-btn-hidden"
                    onClick={() => {
                      const iframe = document.querySelector(".iframe");
                      if (iframe) {
                        iframe.scrollIntoView({ behavior: "smooth", block: "center" });
                      }
                    }}
                    style={{
                      width: 200,
                      height: 50,
                      color: "#ffff",
                      backgroundColor: "#046C77",
                      fontFamily: "inherit",
                      borderRadius: "15px",
                    }}
                  >
                    <div className="unity-booth-survey-btn-items-collection" style={{ display: "flex" }}>
                      <span className="unity-booth-survey-txt">{t("Exhibition.move_to_exhibition")}</span>
                    </div>
                  </Button>
                  <Button
                    variant="contained"
                    className="unity-booth-move-phone-btn"
                    onClick={() => {
                      setShowIframeMobile((prev) => !prev);
                      if (!showIframeMobile) {
                        toast.error(
                          <span style={{ fontSize: 13, fontWeight: "bold" }}>
                            {t("Exhibition.exhibition_not_supported")}
                          </span>
                        );
                      }
                    }}
                    style={{
                      width: 200,
                      height: 50,
                      color: "#ffff",
                      backgroundColor: "#046C77",
                      fontFamily: "inherit",
                      borderRadius: "15px",
                    }}
                  >
                    <div className="unity-booth-survey-btn-items-collection" style={{ display: "flex" }}>
                      <span className="unity-booth-survey-txt">
                        {showIframeMobile
                          ? t("Exhibition.exit_from_the_exhibition")
                          : t("Exhibition.entry_to_the_exhibition")}
                      </span>
                    </div>
                  </Button>
                </div>
                {exhibition?.webinars.length > 0 && (
                  <div className="unity-booth-webinars-container" style={{ marginTop: "0" }}>
                    <h5 style={{ color: "#046c77", fontSize: 20, fontWeight: "600" }}>{t("Exhibition.webinars")}</h5>
                    {exhibition?.webinars.map((webinar) => (
                      <a className="unity-booth-info-webinar-link" href={webinar.link} target="_blank" rel="noreferrer">
                        <img src={webinarIcon} alt="webinar" className="unity-booth-info-webinar-link-img" />
                        <div key={webinar.id} className="unity-booth-info-webinar-link-container">
                          {webinar.name}
                        </div>
                      </a>
                    ))}
                  </div>
                )}
                <div className={`unity-booth-frame ${!showIframeMobile ? "unity-booth-move-btn-hidden " : ""}`}>
                  <iframe
                    width="895"
                    height="593"
                    className="iframe"
                    src={process.env.REACT_APP_EXHIBITION_BUILD_URL || "https://tebx-ve-test.tajahdev.dev"}
                    allowFullScreen="true"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    title="Exhibition"
                    frameBorder="0"
                    allowTransparency="true"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  ></iframe>
                </div>
              </div>
              <BasicModal ref={surveypopupRef}>
                {openSurvey && (
                  <SurveysModal
                    label={t("survey.surviesLabel")}
                    title={t("survey.surviesLabel")}
                    body={survies}
                    exhibitionId={id}
                    surveysModalRef={surveypopupRef}
                    accept={() => setOpenSurvey(false)}
                  />
                )}
              </BasicModal>
              <BasicModal ref={loninpopupRef}>
                {openLogin && (
                  <WarningModal
                    img={warning}
                    label={t("Exhibition.popupwarninglabel")}
                    description={t("Exhibition.popupdescription_2")}
                    dismissText={t("Exhibition.popupdismiss")}
                    dismissColor="#DF4B43"
                    successText={t("Exhibition.popupsuccess")}
                    successColor="#26b3b9"
                    action={() => {
                      openLoginModal();
                      history.push("/login");
                    }}
                    dismiss={openLoginModal}
                  />
                )}
              </BasicModal>
              {showRefundModal && (
                <ModelWithInput
                  openModal={showRefundModal}
                  setOpenModal={setShowRefundModal}
                  modalTitle={t("general.refund_request")}
                  buttonText={t("general.submit")}
                  type={"textarea"}
                  inputLabel={t("general.refund_reason")}
                  handleSubmit={submitRefund}
                  note={`${t("financial.refund.refund_note")} ${exhibition?.refund_amount} ${t("rs")}`}
                />
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}
