import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "../../Shared/Components/CustomButton/CustomButton";
import Input from "../../Shared/Components/Input/Input";
import classes from "./exhibitionPayment.module.css";

export default function ExhibitionPaymentDetails({
  handleSubmit,
  exhibitionDetails,
  exhibitionTaxs,
  type,
}) {
  const { t, i18n } = useTranslation();
  const [totalPrice, setTotalPrice] = useState(0);
  const [opened, setOpened] = useState(true);

  useEffect(() => {
    // let price = Number(exhibitionDetails.entrance_cost);
    let price =
      type === "visitor"
        ? Number(exhibitionDetails.entrance_cost)
        : exhibitionDetails.user_booths
        ? Number(
            exhibitionDetails.user_booths[
              exhibitionDetails?.user_booths.length - 1
            ]?.price
          )
        : 0;
    if (exhibitionTaxs[type]) {
      if (exhibitionTaxs[type].mechanism === "1") {
        price += Number(exhibitionTaxs[type].value);
      } else {
        price += (Number(exhibitionTaxs[type].value) / 100) * price;
      }
    }
    setTotalPrice(Number(price).toFixed(2));
  }, [exhibitionDetails]);

  const calculateValue = (value, mechanism, price) => {
    if (mechanism === "1") {
      return value;
    } else {
      return Number((value / 100) * price).toFixed(2);
    }
  };
  console.log(type);

  let pricesList = [
    {
      id: "main_price",
      className: classes["payment-details-price-item"],
      title: t("payments.main_price"),
      // value: exhibitionDetails.entrance_cost,
      value:
        type === "visitor"
          ? exhibitionDetails.entrance_cost
          : exhibitionDetails.user_booths
          ? exhibitionDetails.user_booths[
              exhibitionDetails?.user_booths.length - 1
            ]?.price
          : 0,
    },
    ...(exhibitionTaxs[type]
      ? [
          {
            id: exhibitionTaxs[type].id,
            className: classes["payment-details-price-item-plus"],
            title: exhibitionTaxs[type].name,
            percentage:
              exhibitionTaxs[type].mechanism === "2"
                ? `(${exhibitionTaxs[type].value}%)`
                : null,
            // value: calculateValue(exhibitionTaxs[type].value, exhibitionTaxs[type].mechanism, exhibitionDetails.entrance_cost),
            value: calculateValue(
              exhibitionTaxs[type].value,
              exhibitionTaxs[type].mechanism,
              type === "visitor"
                ? exhibitionDetails.entrance_cost
                : exhibitionDetails.user_booths
                ? exhibitionDetails.user_booths[
                    exhibitionDetails?.user_booths.length - 1
                  ]?.price
                : 0
            ),
          },
        ]
      : []),
  ];

  return (
    <div className={classes["payment-details"]}>
      <div className={classes["payment-details-container"]}>
        {/* <div className={classes["payment-details-title"]}>{t("payments.summaryOne")}</div> */}
        <div className={classes["payment-details-price-container"]}>
          {pricesList.map((price) => (
            <div className={price.className}>
              <div className={classes["payment-details-price"]}>
                <div className={classes["payment-details-price-title"]}>
                  {price.title}
                </div>
                {price.percentage && (
                  <div className={classes["payment-details-price-percentage"]}>
                    {price.percentage}
                  </div>
                )}
              </div>
              <div className={classes["payment-details-price-value"]}>
                {price.value} {t("rs")}
              </div>
            </div>
          ))}
          <div className={classes["payment-details-total-price-item"]}>
            <div className={classes["payment-details-total-price-title"]}>
              {t("payments.total_price")}
              <span
                className={classes["payment-details-total-price-title-span"]}
              >
                {" "}
                ({t("payments.include_tax")})
              </span>
            </div>
            <div className={classes["payment-details-total-price-value"]}>
              {totalPrice} {t("rs")}
            </div>
          </div>
        </div>
        <CustomButton
          action={handleSubmit}
          disable={false}
          colors="#036c77"
          value={t("payments.pay")}
          classes={classes["payment-details-pay-button"]}
        />
      </div>
    </div>
  );
}
