import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classes from "./AddSetNewQuestions.module.css";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Formik } from "formik";
import Input from "../../Modules/Shared/Components/Input/Input";
import CustomButton from "../../Modules/Shared/Components/CustomButton/CustomButton";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  addFaqCategory,
  addFaqQuestions,
  editFaqCategory,
  editFaqQuestion,
} from "../../Redux/Actions/faq/faq.action";
import { faqConstant } from "../../Redux/Constants/faq/faq.constant";

const AddSetNewQuestions = ({
  setShowModal,
  typeModal,
  title,
  edit,
  titleEN,
  titleAR,
  editID,
  categoryId,
  questionEN,
  questionAR,
  answerEN,
  answerAR,
  setList,
}) => {
  const { t } = useTranslation();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const dispatch = useDispatch();
  const { addResponse, editResponse, addQuestionStatus, editQuestionStatus } =
    useSelector((state) => state.faqReducer);

  const modalRef = React.useRef(null);

  const updateObjectById = (id, newObject) => {
    setList((prevObjects) =>
      prevObjects.map((obj) => (obj.id === id ? newObject : obj))
    );
  };

  useEffect(() => {
    if (addResponse.status === 200 && addResponse.data.status) {
      setShowModal(false);
      setList(addResponse.data.data);
      dispatch({
        type: faqConstant.GET_FAQ_QUESTION_RESET,
      });
    }
  }, [addResponse]);

  useEffect(() => {
    if (editResponse.status === 200 && editResponse.data.status) {
      setShowModal(false);
      updateObjectById(editID, editResponse.data.data);
      dispatch({
        type: faqConstant.GET_FAQ_QUESTION_RESET,
      });
    }
  }, [editResponse]);

  useEffect(() => {
    if (addQuestionStatus?.status === 200 && addQuestionStatus?.data?.status) {
      setShowModal(false);
      setList(addQuestionStatus.data.data);
      dispatch({
        type: faqConstant.GET_FAQ_QUESTION_RESET,
      });
    }
  }, [addQuestionStatus]);

  useEffect(() => {
    if (
      editQuestionStatus?.status === 200 &&
      editQuestionStatus?.data?.status
    ) {
      setShowModal(false);
      updateObjectById(editID, editQuestionStatus.data.data);
      dispatch({
        type: faqConstant.GET_FAQ_QUESTION_RESET,
      });
    }
  }, [editQuestionStatus]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowModal(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  return (
    <>
      <div className={classes["modal-overlay"]}>
        <div
          className={typeModal ? classes["modal-two-input"] : classes["modal"]}
          ref={modalRef}
        >
          <div className={classes["modal-header"]}>
            <h3>{title}</h3>
            <button
              className="close-button"
              onClick={setShowModal.bind(null, false)}
            >
              <HighlightOffIcon
                style={{ fontSize: 36, marginInline: 7, color: "#E62626" }}
              />
            </button>
          </div>

          <div>
            {typeModal ? (
              <Formik
                initialValues={{
                  title_en: edit ? titleEN : "",
                  title_ar: edit ? titleAR : "",
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  setSubmitting(true);
                  try {
                    if (edit) {
                      await dispatch(editFaqCategory(editID, values));
                    } else {
                      await dispatch(addFaqCategory(values));
                    }
                    setSubmitting(false);
                  } catch (err) {
                    setSubmitting(false);
                    toast.error(
                      <>
                        <span
                          style={{
                            fontSize: 13,
                            fontWeight: "bold",
                            display: "block",
                            paddingBottom: "10px",
                          }}
                        >
                          {err.response.data.msg}
                        </span>
                        <ul>
                          {Object.keys(err.response.data.errors).map((key) => {
                            return (
                              <li>
                                <span style={{ fontSize: 11 }}>
                                  {err.response.data.errors[key]}
                                </span>
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    );
                  }
                }}
                validate={(values) => {
                  setHasSubmitted(true);
                  const errors = {};

                  if (!values.title_ar) {
                    errors.title_ar = t("Exhibition.errors.required");
                  }

                  if (!values.title_en) {
                    errors.title_en = t("Exhibition.errors.required");
                  }

                  if (values.title_ar.length >= 190) {
                    errors.title_ar = t("FAQ.errors.text190");
                  }

                  if (values.title_en.length >= 190) {
                    errors.title_en = t("FAQ.errors.text190");
                  }

                  if (Object.keys(errors).length === 0) {
                    setHasSubmitted(true);
                  } else {
                    setHasSubmitted(false);
                  }
                  return errors;
                }}
                validateOnChange={hasSubmitted}
              >
                {({ errors, handleChange, handleSubmit, values }) => (
                  <form onSubmit={handleSubmit}>
                    <div className={classes["m-20"]}>
                      <Input
                        label={t("FAQ.the_title_in_arabic")}
                        type="text"
                        name="title_ar"
                        value={values.title_ar}
                        placeholder={t("FAQ.the_title_in_arabic")}
                        onChange={handleChange}
                        error={errors.title_ar}
                      />
                    </div>

                    <div className={classes["m-20"]}>
                      <Input
                        label={t("FAQ.the_title_in_english")}
                        type="text"
                        name="title_en"
                        value={values.title_en}
                        placeholder={t("FAQ.the_title_in_english")}
                        onChange={handleChange}
                        error={errors.title_en}
                      />
                    </div>

                    <div
                      className={`${classes["modal-button"]} modal-buttons tw-mt-5`}
                    >
                      <CustomButton
                        value={t("FAQ.done")}
                        colors={"#036c77"}
                        type={"button"}
                        iconClass={"mt-2"}
                        action={handleSubmit}
                        classes={"button-width"}
                        loading={hasSubmitted}
                        disable={hasSubmitted}
                      />
                    </div>
                  </form>
                )}
              </Formik>
            ) : (
              <Formik
                initialValues={{
                  category_id: categoryId,
                  question_en: edit ? questionEN : "",
                  question_ar: edit ? questionAR : "",
                  answer_en: edit ? answerEN : "",
                  answer_ar: edit ? answerAR : "",
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  setSubmitting(true);
                  try {
                    if (edit) {
                      dispatch(editFaqQuestion(editID, values));
                    } else {
                      dispatch(addFaqQuestions(values));
                    }
                  } catch (err) {
                    toast.error(
                      <>
                        <span
                          style={{
                            fontSize: 13,
                            fontWeight: "bold",
                            display: "block",
                            paddingBottom: "10px",
                          }}
                        >
                          {err.response.data.msg}
                        </span>
                        <ul>
                          {Object.keys(err.response.data.errors).map((key) => {
                            return (
                              <li>
                                <span style={{ fontSize: 11 }}>
                                  {err.response.data.errors[key]}
                                </span>
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    );
                  }
                  setSubmitting(false);
                }}
                validate={(values) => {
                  setHasSubmitted(true);
                  const errors = {};

                  if (!values.question_en) {
                    errors.question_en = t("Exhibition.errors.required");
                  }

                  if (!values.question_ar) {
                    errors.question_ar = t("Exhibition.errors.required");
                  }

                  if (values.question_en.length >= 190) {
                    errors.question_en = t("FAQ.errors.text190");
                  }

                  if (values.question_ar.length >= 190) {
                    errors.question_ar = t("FAQ.errors.text190");
                  }

                  if (values.answer_en.length >= 2500) {
                    errors.answer_en = t("FAQ.errors.text2500");
                  }

                  if (values.answer_ar.length >= 2500) {
                    errors.answer_ar = t("FAQ.errors.text2500");
                  }

                  if (!values.answer_ar) {
                    errors.answer_ar = t("Exhibition.errors.required");
                  }

                  if (!values.answer_en) {
                    errors.answer_en = t("Exhibition.errors.required");
                  }

                  if (Object.keys(errors).length === 0) {
                    setHasSubmitted(true);
                  } else {
                    setHasSubmitted(false);
                  }

                  return errors;
                }}
                validateOnChange={hasSubmitted}
              >
                {({
                  errors,
                  handleChange,
                  handleSubmit,
                  values,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className={classes["m-20"]}>
                      <Input
                        label={t("FAQ.question_title_in_arabic")}
                        type="text"
                        name="question_ar"
                        value={values.question_ar}
                        placeholder={t("FAQ.question_title_in_arabic")}
                        onChange={handleChange}
                        error={errors.question_ar}
                      />
                    </div>

                    <div className={classes["m-20"]}>
                      <Input
                        label={t("FAQ.question_title_in_english")}
                        type="text"
                        name="question_en"
                        value={values.question_en}
                        placeholder={t("FAQ.question_title_in_english")}
                        onChange={handleChange}
                        error={errors.question_en}
                      />
                    </div>

                    <div className={classes["m-20"]}>
                      <div className={classes["d-flex-input"]}>
                        <label className={classes["input-label"]}>
                          {t("FAQ.the_text_of_the_answer_arabic")}
                        </label>
                      </div>
                      <CKEditor
                        editor={ClassicEditor}
                        data={values.answer_ar}
                        onReady={(editor) => {}}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setFieldValue("answer_ar", data);
                        }}
                        config={{
                          toolbar: ["bold", "italic"],
                        }}
                      />
                      {errors.answer_ar && (
                        <p
                          className={`form-input-error-space ${classes["input-field-error"]}`}
                        >
                          {errors.answer_ar}
                        </p>
                      )}
                    </div>

                    <div className={classes["m-20"]}>
                      <div className={classes["d-flex-input"]}>
                        <label className={classes["input-label"]}>
                          {t("FAQ.the_text_of_the_answer_english")}
                        </label>
                      </div>
                      <CKEditor
                        editor={ClassicEditor}
                        data={values.answer_en}
                        onReady={(editor) => {}}
                        placeholder="test test"
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setFieldValue("answer_en", data);
                        }}
                        config={{
                          toolbar: ["bold", "italic"],
                        }}
                      />
                      {errors.answer_en && (
                        <p
                          className={`form-input-error-space ${classes["input-field-error"]}`}
                        >
                          {errors.answer_en}
                        </p>
                      )}
                    </div>

                    <div
                      className={`${classes["modal-button"]} modal-buttons tw-mt-5`}
                    >
                      <CustomButton
                        value={t("FAQ.done")}
                        colors={"#036c77"}
                        type={"button"}
                        iconClass={"mt-2"}
                        action={handleSubmit}
                        classes={"button-width"}
                        loading={hasSubmitted}
                        disable={hasSubmitted}
                      />
                    </div>
                  </form>
                )}
              </Formik>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSetNewQuestions;
