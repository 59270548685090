import { toast } from "react-toastify";
import env from "../../../Services/api/Environment";
import connector from "../../../Services/api/Handler";
import { default as URLBuilder } from "../../../Services/api/UrlBuilder";
import { specialtiesDetailsConstants } from "../../Constants/specialties/specialtiesDetails.constants";

// GET SPECIALTIES LIST
export const fetchSpecialty = ({ id, page, perPage, filter, history }) => {
  return async (dispatch) => {
    dispatch({
      type: specialtiesDetailsConstants.GET_SPECIALTY_REQUEST,
    });

    await connector
      .get(env.get_category_new, {
        params: {
          page,
          perPage,
          specialty_id: id,
          ...filter,
        },
      })
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: specialtiesDetailsConstants.GET_SPECIALTY_SUCCESS,
            payload: {
              specialty: response?.data?.data?.departments.data,
              meta: response?.data?.data?.departments.meta,
            },
          });
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: specialtiesDetailsConstants.GET_SPECIALTY_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: specialtiesDetailsConstants.GET_SPECIALTY_REJECTED,
          payload: err,
        });
      });
  };
};

export const updateSpecialtyStatus = ({ id, status }) => {
  return async (dispatch) => {
    dispatch({
      type: specialtiesDetailsConstants.UPDATE_SPECIALTY_STATUS_REQUEST,
    });
    await connector
      .post(
        URLBuilder(env.department_status_update, {
          id,
        }),
        {
          status: status,
        }
      )
      .then((response) => {
        if (response?.status === 200 && response?.data?.status) {
          dispatch({
            type: specialtiesDetailsConstants.UPDATE_SPECIALTY_STATUS_SUCCESS,
            payload: {
              id,
            },
          });
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {response?.data?.msg}
            </span>
          );
          dispatch({
            type: specialtiesDetailsConstants.UPDATE_SPECIALTY_STATUS_REJECTED,
            payload: response?.data?.msg,
          });
        }
      })
      .catch((err) => {
        
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err?.response?.data?.message}
          </span>
        );
        dispatch({
          type: specialtiesDetailsConstants.UPDATE_SPECIALTY_STATUS_REJECTED,
          payload: err,
        });
      });
  };
};
