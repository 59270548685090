import React from "react";
import { useTranslation } from "react-i18next";
import classes from "./pricePreviewBox.module.css";
const PricePreviewBox = ({ backgroundColor = "#046C77", color = "#FFFFFF", label, price, type }) => {
  const { t, i18n } = useTranslation();

  return (
    <div
      className={classes["price-box"]}
      style={{
        backgroundColor: backgroundColor,
        color: color,
      }}
    >
      {label && <div className={classes["price-box-label"]}>{label}</div>}
      {type !== "free" ? (
        <div className={classes["price-box-value"]}>
          {price}
          <sub>{t("Exhibition.sar_currency")}</sub>
        </div>
      ) : (
        <div className={classes["price-box-value"]}>{t("free")}</div>
      )}
    </div>
  );
};

export default PricePreviewBox;
