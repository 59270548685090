import env from "../Environment";
import connector from "../Handler";
import URLBuilder from "../UrlBuilder";
const EvaluationApi = {
	list: async (page, perPage, params = {}) => {
		const data = await connector.get(env.evaluation_get, {
			params: {
				...params,
				page,
				perPage,
			},
		});
		return data;
	},
	changeStatus: async (id) => {
		const data = await connector.post(
			URLBuilder(env.evaluation_change_status, { id: id })
		);
		return data;
	},
	update: async (id, values) => {
		const data = await connector.post(`${env.evaluation_actions}/${id}`, {...values, _method: 'put'});
		return data;
	},
	store: async (values) => {
		const data = await connector.post(env.evaluation_actions, values);
		return data;
	},
	show: async (id) => {
		const data = await connector.get(`${env.evaluation_actions}/${id}`);
		return data;
	},
	destroy: async (id) => {
		const data = await connector.delete(`${env.evaluation_actions}/${id}`);
		return data;
	},
	totalWeights: async () => {
		const data = await connector.get(`${env.evaluation_total_weights}`);
		return data;
	},
};

export default EvaluationApi;
