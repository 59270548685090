import moment from "moment";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import { LessonContentContext } from "../../../../../Providers/LessonContentProvider";
import {
  changeStatus,
  generateReport,
  sendReminderZoom,
  sessionDelete,
} from "../../../../../Services/api/Zoom/ZoomProvider";
import DeleteIconModal from "../../../../../assets/icons/delete.svg";
import { getLocaleTime } from "../../../../../utils/utcToLocal";
import CourseDetailsHeader from "../../../../Shared/Components/CourseDetailsHeader/CourseDetailsHeader";
import CustomButton from "../../../../Shared/Components/CustomButton/CustomButton";
import SharedModal from "../../../../Shared/Components/SharedModal/SharedModal";
import SkeletonCardOverlay from "../../../../Shared/Components/Spinner/SkeletonCardOverlay";
import { ZoomSessionActions } from "./zoomSessionActions";

export default function ZoomSessionItem({
  meeting,
  typesZoom,
  openSessionCreationModal,
  isAdmin,
  isTrainer,
  isManager,
  isPartner,
}) {
  require("./addzoomsession.css");
  const [opened, setOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [targetId, setTargetId] = useState(null);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const lessonContentContext = useContext(LessonContentContext);

  const onDeleteFile = async (targetId) => {
    try {
      setIsLoading(true);
      setIsDeleteModalOpen(false);
      await sessionDelete(targetId);
      await lessonContentContext.fetchLessonContent(id);
    } catch (error) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>{t("trainer.course.lesson.failed_deleting_content")}</span>
      );
    } finally {
      setIsLoading(false);
    }
  };
  const handleCloseModal = () => setIsDeleteModalOpen(false);
  const handleOpenModal = (event, id) => {
    setTargetId(id);
    setIsDeleteModalOpen(true);
  };

  const sendReminderZoomApi = async (id) => {
    try {
      setIsLoading(true);
      const response = await sendReminderZoom(id);
      if (response.status === 200) {
        toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{response.data.msg}</span>);
      }
    } catch (error) {
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{error.response.data.msg}</span>);
    } finally {
      setIsLoading(false);
    }
  };

  const generateReportApi = async (meetingId) => {
    try {
      setIsLoading(true);
      const response = await generateReport(meetingId);
      if (response.status === 200) {
        toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("zoom.generate_report_success")}</span>);
        history.push(`/lesson/${id}/zoom/zoomInvoice/${meetingId}`);
      }
    } catch (error) {
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{error.response.data.msg}</span>);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeStatus = async () => {
    try {
      setIsLoading(true);
      const response = await changeStatus(meeting.id);
      if (response.status === 200) {
        toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{response.data.msg}</span>);
        await lessonContentContext.fetchLessonContent(id);
      }
    } catch (error) {
      toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{error.response.data.msg}</span>);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div key={meeting.id} className={`tajah-lesson-zoomSession-list-item ${opened ? "opened" : ""}`}>
      {isLoading && <SkeletonCardOverlay skeletonWidth="100" />}
      <CourseDetailsHeader
        title={meeting.meeting_agenda}
        setOpened={setOpened}
        opened={opened}
        actions={ZoomSessionActions({
          ...(moment(getLocaleTime(new Date(meeting.start_time)))
            .add(meeting.duration, "minutes")
            .isBefore(new Date())
            ? {}
            : {
                sendReminder: () => {
                  sendReminderZoomApi(meeting.id);
                },
              }),
          ...(moment(getLocaleTime(new Date(meeting.start_time)))
            .add(meeting.duration, "minutes")
            .isBefore(new Date()) && Number(meeting.meeting_type) !== 3
            ? {
                reportAction: () => {
                  history.push(`/lesson/${id}/zoom/zoomInvoice/${meeting.id}`);
                },
              }
            : {}),
          ...(!isPartner
            ? {
                linkAction: () => {
                  window.open(meeting.start_url);
                },
              }
            : {}),
          ...(!isPartner
            ? {
                editAction: () => {
                  openSessionCreationModal({ data: meeting, title: t("zoom.edit_session") });
                },
              }
            : {}),
          ...((moment(getLocaleTime(new Date(meeting.start_time))).isBefore(new Date()) &&
            moment(getLocaleTime(new Date(meeting.start_time)))
              .add(meeting.duration, "minutes")
              .isAfter(new Date())) ||
          isPartner
            ? {}
            : {
                deleteAction: (event) => {
                  handleOpenModal(event, meeting.id);
                },
              }),
        })}
      />
      {isDeleteModalOpen && (
        <SharedModal
          icon={DeleteIconModal}
          title={t("meeting_delete_title")}
          confirmMsg={t("meeting_delete_message")}
          confirmText={t("general.confirm")}
          cancelText={t("general.cancel")}
          onCancel={handleCloseModal}
          onConfirm={() => onDeleteFile(targetId)}
          isLoading={false}
        />
      )}
      {opened && (
        <div className={"tajah-activity-details-item"}>
          <div className={"tajah-activity-details-item-box"}>
            <div className={"tajah-activity-details-item-box-one"}>
              <div className={"tajah-activity-details-item-box-one-title"}>{t("zoom.Course_name")}</div>
              <div className={"tajah-activity-details-item-box-one-value"}>
                {typesZoom.length > 0 &&
                  typesZoom.map((type) => {
                    return Number(type.id) === Number(meeting.meeting_type)
                      ? i18n.language === "en"
                        ? type.name.en
                        : type.name.ar
                      : null;
                  })}
              </div>
            </div>
            <div className={"tajah-activity-details-item-box-two"}>
              <div className={"tajah-activity-details-item-box-two-row"}>
                <div className={"tajah-activity-details-item-box-two-title"}>{t("zoom.starting_date")}</div>
                <div className={"tajah-activity-details-item-box-two-value"}>
                  {moment(getLocaleTime(new Date(meeting.start_time))).format("DD-MM-YYYY hh:mm A")}
                </div>
              </div>
              <div className={"tajah-activity-details-item-box-two-row"}>
                <div className={"tajah-activity-details-item-box-two-title"}>{t("zoom.session_duration")}</div>
                <div className={"tajah-activity-details-item-box-two-value"}>
                  {meeting.duration} {t("zoom.minute")}
                </div>
              </div>
            </div>
          </div>

          {Number(meeting.meeting_type) !== 3 && (
            <div className={"tajah-activity-details-item-box"}>
              {/* <div className={"tajah-activity-details-item-box-one"}>
              <div className={"tajah-activity-details-item-box-one-title"}>{t("zoom.Number_registered")}</div>
              <div className={"tajah-activity-details-item-box-one-value"}>{meeting.max_registrants}</div>
            </div> */}
              <div className={"tajah-activity-details-item-box-one"}>
                <div className={"tajah-activity-details-item-box-one-title"}>{t("zoom.achivement_percentage")}</div>
                <div className={"tajah-activity-details-item-box-one-value"}>{meeting.percentage}%</div>
              </div>
            </div>
          )}
          {!isPartner && (
            <div className={"tajah-activity-details-item-actions"}>
              {moment(getLocaleTime(new Date(meeting.start_time)))
                .add(meeting.duration, "minutes")
                .isBefore(new Date()) && Number(meeting.meeting_type) !== 3 ? (
                <CustomButton
                  value={t("zoom.generate_report")}
                  action={() => generateReportApi(meeting.id)}
                  colors="#00BFA6"
                  variant="outlined"
                  classes="tajah-activity-details-item-actions-btn"
                  type="button"
                />
              ) : null}

              <CustomButton
                value={meeting.status ? t("zoom.disable_session") : t("zoom.enable_session")}
                action={() => handleChangeStatus()}
                colors="#00BFA6"
                variant="outlined"
                classes="tajah-activity-details-item-actions-btn"
                type="button"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
