import React, { useState } from "react";
import classes from "./notification.module.css";
import i18n from "../../../i18n/i18n";
import toLocalTimezone from "../../../Services/Timezone";

export default function NotificationCard({
  notification,
  notifications,
  readNotification,
  NotificationIcon,
  getTimeFromNow,
}) {
  const [isHovering, setIsHovering] = useState(false);
  function handleMouseOver() {
    setIsHovering(true);
  }
  function handleMouseOut() {
    setIsHovering(false);
  }

  return (
    <>
      <div
        key={notification.id}
        className={`${classes["notification"]} ${
          !notification.is_read ? classes.new : null
        } ${isHovering && classes.hovering}`}
        onMouseLeave={() => readNotification(notification)}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        <div className={classes["notification-content"]}>
          <div className={classes["notification-img"]}>
            <img src={NotificationIcon} alt="" />
          </div>
          <p>
            {i18n.language === "en"
              ? notification.msg
                ? notification.msg
                : notification.msg_ar
              : notification.msg_ar
              ? notification.msg_ar
              : notification.msg}
          </p>
        </div>
        <span className={classes["notification-time"]}>
          {getTimeFromNow(toLocalTimezone(notification.created_at))}
        </span>
      </div>
      {/* {notifications.indexOf(notification) !== notifications.length - 1 ? (
        <div className={classes["notification-divider"]}></div>
      ) : null} */}
    </>
  );
}
