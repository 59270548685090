import { paymentConstant } from "../../Constants/payment/payment.constant";

const initialState = {
  orderDetails: null,
  orderDetailsLoading: false,
  orderDetailsError: null,
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case paymentConstant.GET_ORDER_DETAILS_REQUEST:
      return {
        ...state,
        orderDetailsLoading: true,
      };
    case paymentConstant.GET_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        orderDetailsLoading: false,
        orderDetails: action.payload,
      };
    case paymentConstant.GET_ORDER_DETAILS_REJECTED:
      return {
        ...state,
        orderDetailsLoading: false,
        orderDetailsError: action.payload,
      };
    case paymentConstant.RESET_ORDER_DETAILS:
      return {
        ...state,
        orderDetails: null,
        orderDetailsLoading: false,
        orderDetailsError: null,
      };
    default:
      return state;
  }
};

export default paymentReducer;
