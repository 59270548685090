import { Stack, useTheme } from "@mui/material";

const BoxCard = ({ children, boxShadow, px, py, bgcolor, sx }) => {
	const theme = useTheme();

	return (
		<Stack
			sx={{
				backgroundColor: bgcolor || "white",
				borderRadius: "6px",
				boxShadow: boxShadow || theme.shadows[1],
				px: px || { xs: "16px", md: "47px" },
				py: py || { xs: "16px", md: "53px" },
				...sx,
			}}
		>
			{children}
		</Stack>
	);
};

export default BoxCard;
