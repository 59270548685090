import env from "../Environment";
import connector from "../Handler";
import URLBuilder from "../UrlBuilder";

export function getListType() {
	const myPromise = new Promise((resolve, reject) => {
		try {
			connector
				.post(env.type_file_list)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		} catch (error) {
			reject(error);
		}
	});

	return myPromise;
}

export function deleteTypeFile(id) {
	const myPromise = new Promise((resolve, reject) => {
		try {
			connector
				.post(URLBuilder(env.type_file_Delete, { id: id }))
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		} catch (error) {
			reject(error);
		}
	});

	return myPromise;
}
export function EditTypeFile(id, payload) {
	const myPromise = new Promise((resolve, reject) => {
		try {
			connector
				.post(URLBuilder(env.type_file_update, { id: id }), payload)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		} catch (error) {
			reject(error);
		}
	});

	return myPromise;
}
export function addTypeFile(id, payload) {
	const myPromise = new Promise((resolve, reject) => {
		try {
			connector
				.post(env.type_file_add, payload)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		} catch (error) {
			reject(error);
		}
	});

	return myPromise;
}
